import React from "react";

import { getAppSubscriptions } from "../../api/core/controlPlane";
import { useUser } from "../../AuthProvider";

import useAppSubscriptions from "./hooks/useAppSubscriptions";
import {
  AppSubscription,
  MarketplaceContextProps,
} from "./Marketplace.context.types";

export const MarketplaceContext = React.createContext<MarketplaceContextProps>({
  addNewAppSubscription: () => new Promise((resolve) => resolve({})),
  appSubscriptions: [],
  appSubscriptionsActionError: "",
  appSubscriptionsActionLoading: true,
  appSubscriptionsError: "",
  appSubscriptionsLoading: true,
  clearAppSubscriptionsActionError: () => null,
  loadAppSubscriptions: () => new Promise((resolve) => resolve([])),
  setAppSubscriptions: () => null,
  setAppSubscriptionsError: () => null,
  setAppSubscriptionsLoading: () => null,
});

const MarketplaceProvider = ({ children }: { children: React.ReactNode }) => {
  const [{ id: accountId }] = useUser();

  const {
    appSubscriptions,
    appSubscriptionsActionError,
    appSubscriptionsActionLoading,
    appSubscriptionsError,
    appSubscriptionsLoading,
    clearAppSubscriptionsActionError,
    addNewAppSubscription,
    setAppSubscriptions,
    setAppSubscriptionsError,
    setAppSubscriptionsLoading,
  } = useAppSubscriptions();

  const loadAppSubscriptions = React.useCallback(async () => {
    try {
      const appSubscriptionsRes = await getAppSubscriptions(accountId || "")();
      const appSubscriptions = appSubscriptionsRes as AppSubscription[];

      setAppSubscriptions(appSubscriptions);
      setAppSubscriptionsLoading(false);
      return appSubscriptions;
    } catch (e: any) {
      console.error(e);
      setAppSubscriptionsError(e.toString());
      setAppSubscriptionsLoading(false);
    }
  }, [
    accountId,
    setAppSubscriptions,
    setAppSubscriptionsError,
    setAppSubscriptionsLoading,
  ]);

  const value: MarketplaceContextProps = {
    addNewAppSubscription,
    appSubscriptions,
    appSubscriptionsActionError,
    appSubscriptionsActionLoading,
    appSubscriptionsError,
    appSubscriptionsLoading,
    clearAppSubscriptionsActionError,
    loadAppSubscriptions,
    setAppSubscriptions,
    setAppSubscriptionsError,
    setAppSubscriptionsLoading,
  };

  return (
    <MarketplaceContext.Provider value={value}>
      {children}
    </MarketplaceContext.Provider>
  );
};

export const useMarketplaceCollection = () =>
  React.useContext(MarketplaceContext);

export default MarketplaceProvider;
