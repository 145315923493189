import React from "react";
import { Link } from "react-router-dom";
import { Text } from "@console/dsc";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../../analytics";
import { TrackEvents } from "../../../analytics/functions";

type AuthFlowCalloutProps = {
  content: string;
  linkLabel: string;
  linkTo: string;
  linkTrackEventCategory?: "AuthFlow";
  linkTrackEventProperties?: TrackEvents["AuthFlow"];
  testId?: string;
};

const AuthFlowCallout = ({
  content,
  linkTrackEventCategory,
  linkTrackEventProperties,
  linkLabel,
  linkTo,
  testId,
}: AuthFlowCalloutProps) => {
  const theme = useTheme();

  return (
    <Text
      {...{ testId }}
      mt={[5, 5, 6]}
      styleName="body-2"
      hasLink
      styles={{ color: theme.color.gray600 }}
    >
      {content}{" "}
      <Link
        to={linkTo}
        {...(linkTrackEventCategory &&
          linkTrackEventProperties && {
            onClick: () =>
              trackEvent(linkTrackEventCategory, linkTrackEventProperties),
          })}
      >
        <Text as="strong" styleName="body-2-bold">
          {linkLabel}
        </Text>
      </Link>
    </Text>
  );
};

export default AuthFlowCallout;
