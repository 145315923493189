import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Box, Button2, Flex, Input, Notification, Text } from "@console/dsc";
import { useTheme } from "@emotion/react";

import HeaderPublic from "../../../../components/HeaderPublic";
import { useMetaTitle } from "../../../../components/Layout";
import PasswordRules from "../../../../components/PasswordRules";
import {
  AUTH_FLOW_BUTTON_WIDTH,
  AUTH_FLOW_CONTENT_MAX_WIDTH,
} from "../../../../config/public";
import useAuthFlow from "../../../../hooks/useAuthFlow";
import {
  handleInputChange,
  handleOnFocusStateBlur,
} from "../../../../utils/inputHelpers";
import { LoginPageProps } from "../../LogIn.types";

const pageTitle = "Reset Password";

const PasswordReset = ({ username, verificationCode }: LoginPageProps) => {
  const [, setMetaTitle] = useMetaTitle();
  const theme = useTheme();

  const {
    canResetPassword,
    error,
    errorExpiredCode,
    hasResetPassword,
    isPasswordFocused,
    isPasswordConfirmFocused,
    isProcessing,
    password,
    passwordConfirm,
    resetPassword,
    setIsPasswordFocused,
    setIsPasswordConfirmFocused,
    setPassword,
    setPasswordConfirm,
  } = useAuthFlow();

  // page display
  useEffect(() => {
    setMetaTitle(pageTitle);
  }, [setMetaTitle]);

  useEffect(() => {
    if (hasResetPassword && password && passwordConfirm) {
      setPassword("");
      setPasswordConfirm("");
    }
  }, [
    hasResetPassword,
    password,
    passwordConfirm,
    setPassword,
    setPasswordConfirm,
  ]);

  const handleResetPasswordFormSubmit = async (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    e.preventDefault();
    e.stopPropagation();

    // in this case the username is a token, not the email address
    await resetPassword(username, verificationCode);
  };

  const isActionButtonDisabled = !canResetPassword();

  return (
    <>
      <HeaderPublic
        configPageTitle={{
          label: "Reset your password",
        }}
        subTitle="Enter a new password below."
      />

      <Box mt={3} width="100%" maxWidth={AUTH_FLOW_CONTENT_MAX_WIDTH}>
        {hasResetPassword && (
          <Notification
            mb={2}
            type="success"
            message={
              <Text styleName="body-2" hasLinkBlack>
                Your password has been&nbsp;reset.{" "}
                <Link to="/login/identify">Log in</Link> with your
                new&nbsp;password.
              </Text>
            }
          />
        )}

        <form onSubmit={handleResetPasswordFormSubmit}>
          <Input
            mt={1}
            isRequired
            testId="login-reset-password-input"
            htmlType="password"
            size="large"
            placeholder="Password"
            value={password}
            onFocus={() => setIsPasswordFocused(true)}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
              handleOnFocusStateBlur(e, setIsPasswordFocused, false)
            }
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e, setPassword)
            }
          />
          <Input
            mt={1}
            isRequired
            testId="login-reset-password-confirm-input"
            htmlType="password"
            size="large"
            placeholder="Re-type password"
            value={passwordConfirm}
            onFocus={() => setIsPasswordConfirmFocused(true)}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
              handleOnFocusStateBlur(e, setIsPasswordConfirmFocused, false)
            }
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e, setPasswordConfirm)
            }
          />

          <PasswordRules
            password={password}
            passwordConfirm={passwordConfirm}
            isPasswordFocused={isPasswordFocused}
            isPasswordConfirmFocused={isPasswordConfirmFocused}
          />

          {error && <Notification mt={2} type="error" message={error} />}
          {errorExpiredCode && (
            <Notification mt={2} type="error" message={errorExpiredCode} />
          )}

          <Flex mt={2}>
            <Button2
              isLoading={isProcessing}
              size="large"
              label="Reset password"
              styles={{
                minWidth: AUTH_FLOW_BUTTON_WIDTH,
                ...(!isActionButtonDisabled && {
                  backgroundColor: theme.color.sky600,
                  "&:hover, &:focus, &:active": {
                    backgroundColor: theme.color.sky700,
                  },
                  "&:focus-visible": {
                    backgroundColor: theme.color.sky700,
                    outlineWidth: theme.border.widthMediumShadowOnly,
                    outlineStyle: "solid",
                    outlineColor: theme.color.sky800,
                  },
                }),
              }}
              {...(errorExpiredCode && {
                testId: "link-to-password-forgot-page",
                label: "Request new reset password link",
                to: "/login/password/forgot",
              })}
              {...(!errorExpiredCode && {
                testId: "reset-password-submit-button",
                label: "Reset password",
                htmlType: "submit",
                isDisabled: isActionButtonDisabled,
              })}
            />
          </Flex>
        </form>
      </Box>
    </>
  );
};

export default PasswordReset;
