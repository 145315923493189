import { useState } from "react";

import { AcceptanceTestMetric } from "../../../../../api/core/controlPlane.types";
import { emptyAcceptanceMetric } from "../../../components/utils";
import { MetricCompareOption } from "../../../Experiments.types";

const useNewAcceptanceTest = () => {
  const [pendingAcceptanceTestMetrics, setPendingAcceptanceTestMetrics] =
    useState<AcceptanceTestMetric[]>([emptyAcceptanceMetric]);

  const handleMetricInputChange = (
    { target: { value } }: { target: { value: string } },
    modifiedIndex: number
  ) => {
    const newMetric = { ...pendingAcceptanceTestMetrics[modifiedIndex] };
    newMetric.field = value.trim();
    const acceptanceMetricsModified = pendingAcceptanceTestMetrics.map(
      (metric, index) => {
        if (modifiedIndex === index) {
          return newMetric;
        } else {
          return metric;
        }
      }
    );
    setPendingAcceptanceTestMetrics(acceptanceMetricsModified);
  };

  const handleMetricSelectCompare = (
    selection: MetricCompareOption,
    modifiedIndex: number
  ) => {
    const newMetric = { ...pendingAcceptanceTestMetrics[modifiedIndex] };
    newMetric.params = {
      ...newMetric.params,
      operator: selection.value,
    };
    const acceptanceMetricsModified = pendingAcceptanceTestMetrics.map(
      (metric, index) => {
        if (modifiedIndex === index) {
          return newMetric;
        } else {
          return metric;
        }
      }
    );
    setPendingAcceptanceTestMetrics(acceptanceMetricsModified);
  };

  const addEmptyMetric = (e: any) => {
    e.preventDefault();
    setPendingAcceptanceTestMetrics([
      ...pendingAcceptanceTestMetrics,
      emptyAcceptanceMetric,
    ]);
  };

  const removeMetric = (e: any, removedIndex: number) => {
    e.preventDefault();
    const acceptanceMetricsModified = pendingAcceptanceTestMetrics.filter(
      (_, index) => {
        return index !== removedIndex;
      }
    );
    setPendingAcceptanceTestMetrics(acceptanceMetricsModified);
  };

  return {
    pendingAcceptanceTestMetrics,
    setPendingAcceptanceTestMetrics,
    handleMetricInputChange,
    handleMetricSelectCompare,
    addEmptyMetric,
    removeMetric,
  };
};

export default useNewAcceptanceTest;
