import React from "react";

import Layout from "../../components/Layout";

import CustomPlanPage from "./CustomPlan.page";

const CustomPlan = () => {
  return (
    <Layout>
      <CustomPlanPage />
    </Layout>
  );
};

export default CustomPlan;
