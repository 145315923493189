import React from "react";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";

import { useUser } from "../../AuthProvider";
import { userHasAccessToView } from "../../utils/rbac_utils";
import AccessDenied from "../AccessDenied";

import Apps from "./subpages/Apps";
import CustomApps from "./subpages/Custom";
import NewApp from "./subpages/NewApp";
import SubscribedApps from "./subpages/Subscribed";

const AppsRoutes = () => {
  const [{ roles }] = useUser();
  const { path } = useRouteMatch();
  const location = useLocation();

  const userCanView = userHasAccessToView(roles, path, location.pathname, {});

  if (!userCanView) {
    return <AccessDenied />;
  }

  return (
    <Switch>
      <Route exact path={`${path}/new`}>
        <NewApp appType="custom" />
      </Route>

      <Route exact path={path}>
        <Apps />
      </Route>

      <Route exact path={`${path}/subscription/new`}>
        <NewApp appType="subscription" />
      </Route>

      <Route exact path={`${path}/custom`}>
        <CustomApps />
      </Route>

      <Route exact path={`${path}/subscription`}>
        <SubscribedApps />
      </Route>
      <Route exact path={`${path}/subscribed`}>
        <Redirect to={`${path}/subscription`} />
      </Route>
      <Route exact path={`${path}/subscribed/new`}>
        <Redirect to={`${path}/subscription/new`} />
      </Route>

      <Redirect to={path} />
    </Switch>
  );
};

export default AppsRoutes;
