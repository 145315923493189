import { useEffect, useState } from "react";

import { getToken } from "../api/utils/cognito";
import { useUser } from "../AuthProvider";

type UseStreamlitTokenRefreshProps = {
  streamlitSubdomain: string | null;
};

const useStreamlitTokenRefresh = ({
  streamlitSubdomain,
}: UseStreamlitTokenRefreshProps): string | null => {
  const [token, setToken] = useState<string | null>(null);
  const [{ features = {} }] = useUser();

  useEffect(() => {
    if (streamlitSubdomain && features.streamlit && !token) {
      // If the token is not set yet, get it
      const fetchToken = async () => {
        const token = await getToken();
        if (!token) {
          console.error("Failed to get token");
        }
        setToken(token);
      };
      fetchToken();
    }
  });

  useEffect(() => {
    const handleMessage = async (event: MessageEvent) => {
      // Check if the event is from the expected source
      if (
        event.origin === "http://localhost:8501" ||
        event.origin === `https://${streamlitSubdomain}.streamlit.app`
      ) {
        // Check if the message is a token refresh message
        if (event.data.type === "NEXTMV_TOKEN_REFRESH") {
          // Get the new token
          const token = await getToken();
          if (!token) {
            console.error("Failed to get token");
          }

          setToken(token);
        }
      }
    };

    // Add event listener for the 'message' event
    window.addEventListener("message", handleMessage);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("message", handleMessage);
    };
  }, [streamlitSubdomain]);

  return token;
};

export default useStreamlitTokenRefresh;
