import { useState } from "react";

const useRunsFilter = () => {
  const [queryStart, setQueryStart] = useState("");
  const [queryEnd, setQueryEnd] = useState("");
  const [isActiveDatePicker, setIsActiveDatePicker] = useState(false);
  const [isDateQueriedRuns, setIsDateQueriedRuns] = useState(false);

  const confirmSelectDateRange = () => {
    setIsActiveDatePicker(false);
    return;
  };
  const clearSelectDateRange = () => {
    setQueryStart("");
    setQueryEnd("");
    return;
  };

  return {
    clearSelectDateRange,
    confirmSelectDateRange,
    isActiveDatePicker,
    isDateQueriedRuns,
    queryEnd,
    queryStart,
    setIsActiveDatePicker,
    setIsDateQueriedRuns,
    setQueryEnd,
    setQueryStart,
  };
};

export default useRunsFilter;
