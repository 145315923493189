import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { Button2, Table2 } from "@console/dsc";
import { startCase } from "lodash";

import { RunsData } from "../../../api/core/controlPlane.types";
import {
  HeaderObj,
  Table2HeaderObj,
} from "../../App/subpages/RunHistory/RunHistory.types";
import { getRunHistoryItems } from "../../App/subpages/RunHistory/utils/getRunHistoryItems";
import { getTableLayoutSchemaExperimentRunHistory } from "../../App/subpages/RunHistory/utils/tableLayoutSchemas";

import ExperimentDetailsSection from "./ExperimentDetailsSection";

type ExperimentRunsProps = {
  kind: "batch" | "shadow" | "switchback";
  experimentRuns: RunsData;
  runHistoryHeaders: Table2HeaderObj[];
  statisticsHeaders: HeaderObj[];
  displayLoadMore?: boolean;
  isLoading?: boolean;
  loadMoreHandler?: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  scenarioConfigOptionsHeaders?: HeaderObj[];
};
const ExperimentRuns = ({
  kind,
  experimentRuns,
  runHistoryHeaders,
  scenarioConfigOptionsHeaders,
  statisticsHeaders,
  displayLoadMore = false,
  isLoading,
  loadMoreHandler,
}: ExperimentRunsProps) => {
  const params = useParams() as { id: string };
  const isBatch = kind === "batch";

  const experimentRunsData = useMemo(() => {
    return getRunHistoryItems({
      hasName: false,
      hasStatistics: true,
      runHistory: experimentRuns,
      scenarioConfigOptionsHeaders,
      statsHeaders: statisticsHeaders,
      showPlanUnitIndex: kind === "switchback",
    });
  }, [experimentRuns, kind, scenarioConfigOptionsHeaders, statisticsHeaders]);

  return (
    <ExperimentDetailsSection
      title={`${isBatch ? "Experiment" : `${startCase(kind)} Test`} Runs`}
    >
      <Table2
        isWide
        canFilter
        canSort
        useMinWidth
        isStickyColumn
        headers={runHistoryHeaders}
        data={experimentRunsData || []}
        layoutSchema={getTableLayoutSchemaExperimentRunHistory(experimentRuns)}
        emptyMessage="No runs available"
        fileNameCSV={`${isBatch ? "experiment" : kind}_runs_${params.id}`}
        showCSVLink
      />
      {displayLoadMore && loadMoreHandler && (
        <Button2
          mt={2}
          label="Load more"
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
            loadMoreHandler(e)
          }
          isLoading={!!isLoading}
        />
      )}
    </ExperimentDetailsSection>
  );
};

export default ExperimentRuns;
