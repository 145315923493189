import React from "react";
import { Loading as LoadingComponent } from "@console/dsc";
import { useTheme } from "@emotion/react";

export default function Loading() {
  const theme = useTheme();

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        minHeight: "100vh",
        justifyContent: "center",
      }}
      data-testid="loading-spinner"
    >
      <LoadingComponent type="full-screen" dotColor={theme.color.orange500} />
    </div>
  );
}
