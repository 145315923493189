import { Box, Button2, Flex, IconX, Input, Tooltip } from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";

import { scenarioTooltips } from "../../pages/Experiments/data/microcopy";

import { AddConfigOptionsProps } from "./AddConfigOptions.types";

const AddConfigOptions = ({
  actionButtonLabel,
  addEmptyConfigOption,
  handleConfigOptionChange,
  pendingConfigOptions,
  removeConfigOption,
  size = "default",
  isRunning,
}: AddConfigOptionsProps) => {
  const theme = useTheme();

  return (
    <>
      <Box minHeight={size === "small" ? theme.spacing.s7 : theme.spacing.s8}>
        {pendingConfigOptions.map((pendingConfigOption, index) => {
          return (
            <Flex
              key={`config-option-${index}`}
              mt={index === 0 ? 0 : 1}
              alignItems={["flex-start", "center"]}
              pr={8}
            >
              <Flex
                width="100%"
                maxWidth={[rem(408), rem(516)]}
                alignItems={["flex-start", "center"]}
                flexDirection={["column", "row"]}
              >
                <Box
                  width={["100%", "50%"]}
                  maxWidth={[rem(408), rem(256)]}
                  flexShrink={0}
                >
                  <Input
                    {...(size === "small" && {
                      size,
                    })}
                    isDisabled={isRunning}
                    htmlType="text"
                    name={`config-option-${index}-option`}
                    placeholder="Config option"
                    data-testid={`new-instance-config-option-${index}-option`}
                    value={pendingConfigOption.option}
                    onChange={(e: {
                      target: { name: string; value: string };
                    }) =>
                      handleConfigOptionChange({
                        e,
                        index,
                        pendingConfigOptions,
                        type: "option",
                      })
                    }
                  />
                </Box>
                <Box
                  width={["100%", "50%"]}
                  maxWidth={[rem(408), rem(256)]}
                  mt={[1, 0]}
                  ml={[0, 1]}
                  flexShrink={0}
                >
                  <Input
                    {...(size === "small" && {
                      size,
                    })}
                    isDisabled={isRunning}
                    htmlType="text"
                    name={`config-option-${index}-value`}
                    placeholder="Config value"
                    data-testid={`new-instance-config-option-${index}-value`}
                    value={pendingConfigOption.value}
                    onChange={(e: any) =>
                      handleConfigOptionChange({
                        e,
                        index,
                        pendingConfigOptions,
                        type: "value",
                      })
                    }
                  />
                </Box>
              </Flex>
              {pendingConfigOptions.length > 1 && (
                <Button2
                  mt={[1, 0]}
                  htmlType="button"
                  type="text-quiet"
                  icon={
                    <IconX
                      iconColor={theme.color.gray500}
                      iconColorHover={theme.color.gray600}
                    />
                  }
                  styles={{
                    flexShrink: 0,
                    marginRight: theme.spacing["-s8"],
                    transform: `translateX(${theme.spacing.s1})`,
                  }}
                  onClick={(
                    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                  ) => removeConfigOption(e, index)}
                />
              )}
            </Flex>
          );
        })}
      </Box>

      <Flex mt={2} alignItems="center">
        <Button2
          htmlType="button"
          type="outline-quiet"
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
            addEmptyConfigOption(e)
          }
          label={actionButtonLabel || "Add additional config option"}
        />
        <Tooltip ml={2}>
          {scenarioTooltips.scenarioConfigOptions.content}
        </Tooltip>
      </Flex>
    </>
  );
};

export default AddConfigOptions;
