import { useState } from "react";

import { PlanResponse } from "../../../api/payment/controlPlane.types";
import { PlanCustomError } from "../Plans.context.types";

const usePlanCustom = () => {
  const [planCustom, setPlanCustom] = useState<PlanResponse | undefined>();
  const [planCustomLoading, setPlanCustomLoading] = useState<boolean>(true);
  const [planCustomError, setPlanCustomError] = useState<PlanCustomError>("");

  return {
    planCustom,
    planCustomError,
    planCustomLoading,
    setPlanCustom,
    setPlanCustomError,
    setPlanCustomLoading,
  };
};

export default usePlanCustom;
