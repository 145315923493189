import React, { useEffect } from "react";

import AppsProvider from "./contexts/apps/Apps.context";
import { clearOPFS } from "./utils/fileHandling/clearOPFS";
import { AuthProvider } from "./AuthProvider";
import { AuthenticatedRoutes, PublicRoutes } from "./routes";
import { useIsNewUser } from "./utils";

function App() {
  const [, setIsNewUser] = useIsNewUser();

  // clear OPFS files if present (security concern)
  useEffect(() => {
    clearOPFS();
  });

  return (
    <AuthProvider
      LoggedOutRoutes={PublicRoutes}
      onSignOut={() => setIsNewUser(false)}
    >
      <AppsProvider>
        <AuthenticatedRoutes />
      </AppsProvider>
    </AuthProvider>
  );
}

export default App;
