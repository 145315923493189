import React from "react";
import { Text } from "@console/dsc";
import {
  formatNumber,
  formatSecondsToMinsSecs,
} from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";
import { DateTime } from "luxon";

import { RunOutputVehicleStop } from "../../../../../../../../components/Map/Map.types";

export type RoutePartProps = {
  part: RunOutputVehicleStop;
  index: number;
  partTotal: number;
};

// renders text summary for route when Details is expanded for vehicle
export default function RoutePart({ part, index, partTotal }: RoutePartProps) {
  const theme = useTheme();
  const actionLabel =
    index === 0
      ? "Starts at"
      : index === partTotal - 1
      ? "Ends at"
      : "Proceeds to";

  const {
    eta,
    etd,
    ets,
    travel_duration,
    cumulative_travel_duration,
    travel_distance,
    cumulative_travel_distance,
  } = part;

  type FormatStopDateParams = {
    date: string;
    id: string;
  };
  const formatStopDate = ({ date, id }: FormatStopDateParams) => {
    return (
      <Text
        data-testid={id}
        as="span"
        styleName="body-3-bold"
        styles={{ color: theme.color.gray800 }}
      >
        {DateTime.fromISO(date).toFormat("h:mm:ss a").toLowerCase()}
        <Text
          as="span"
          ml={1}
          styleName="body-3"
          styles={{ color: theme.color.gray800 }}
        >
          {DateTime.fromISO(date).toFormat("ZZZZ")}
        </Text>

        <Text
          as="span"
          ml={1}
          styleName="meta-2"
          styles={{
            textTransform: "uppercase",
            letterSpacing: "0.01em",
            color: theme.color.gray600,
          }}
        >
          {DateTime.fromISO(date).toFormat("yyyy-MM-dd")}
        </Text>
      </Text>
    );
  };

  type RenderRoutePartItemParams = {
    label: string;
    value: React.ReactNode;
  };
  const renderRoutePartItem = ({ label, value }: RenderRoutePartItemParams) => {
    return (
      <Text
        styleName="code"
        styles={{
          fontSize: theme.ui2Typography.fontSizeMeta1,
          lineHeight: theme.ui2Typography.lineHeightBody3,
          color: theme.color.gray600,
        }}
      >
        <span dangerouslySetInnerHTML={{ __html: label }} />
        {value}
      </Text>
    );
  };

  return (
    <div className="route-part" key={part.id}>
      <Text mt={2} styleName="body-2">
        {actionLabel}
        <Text
          as="strong"
          styleName="body-2-bold"
          styles={{ color: theme.color.gray800 }}
        >
          {" "}
          {part.id}{" "}
        </Text>
      </Text>
      {eta &&
        renderRoutePartItem({
          label: "Arrival&nbsp;&nbsp;",
          value: formatStopDate({ date: eta, id: `${part.id}-arrival-time` }),
        })}
      {ets &&
        renderRoutePartItem({
          label: "Start&nbsp;&nbsp;&nbsp;&nbsp;",
          value: formatStopDate({ date: ets, id: `${part.id}-start-time` }),
        })}
      {etd &&
        renderRoutePartItem({
          label: "End&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;",
          value: formatStopDate({ date: etd, id: `${part.id}-end-time` }),
        })}
      {travel_duration &&
        renderRoutePartItem({
          label: "Duration&nbsp;",
          value: (
            <Text
              data-testid={`${part.id}-duration`}
              as="span"
              styleName="body-3-bold"
              styles={{ color: theme.color.gray800 }}
            >
              {formatSecondsToMinsSecs(travel_duration)}{" "}
              <Text ml={1} as="span" styleName="body-3">
                {cumulative_travel_duration && (
                  <>
                    (cumulative:{" "}
                    {formatSecondsToMinsSecs(cumulative_travel_duration)})
                  </>
                )}
              </Text>
            </Text>
          ),
        })}
      {travel_distance &&
        renderRoutePartItem({
          label: "Distance&nbsp;",
          value: (
            <Text
              data-testid={`${part.id}-distance`}
              as="span"
              styleName="body-3-bold"
              styles={{ color: theme.color.gray800 }}
            >
              {formatNumber(travel_distance, true)}{" "}
              <Text ml={1} as="span" styleName="body-3">
                {cumulative_travel_distance && (
                  <>
                    (cumulative:{" "}
                    {formatNumber(cumulative_travel_distance, true)})
                  </>
                )}
              </Text>
            </Text>
          ),
        })}
    </div>
  );
}
