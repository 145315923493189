import { Box } from "@console/dsc";
import styled from "@emotion/styled";

export const StyledNewsItemLink = styled.a`
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    h4 {
      color: ${({ theme }) => theme.color.black};
    }
    time {
      color: ${({ theme }) => theme.color.gray700};
    }
  }
`;

export const StyledCommunityList = styled.ul`
  margin-top: ${({ theme }) => theme.spacing.s2};
  list-style: none;
`;
export const StyledCommunityListItem = styled.li`
  margin-top: ${({ theme }) => theme.spacing.s1};
`;
export const StyledCommunityListLink = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    span {
      color: ${({ theme }) => theme.color.gray800};
    }
    .social-link-github-username {
      color: ${({ theme }) => theme.color.gray700};
    }
  }
`;

export const StyledCalloutLink = styled.a`
  display: block;
  width: 100%;
  padding: ${({ theme }) => theme.spacing.s6};
  padding-right: ${({ theme }) => theme.spacing.s4};
  text-decoration: none;
  border-width: ${({ theme }) => theme.border.width};
  border-style: ${({ theme }) => theme.border.style};
  border-color: ${({ theme }) => theme.color.gray200};
  border-radius: ${({ theme }) => theme.border.radius};
  background-color: ${({ theme }) => theme.color.white};

  &:hover,
  &:active,
  &:focus {
    border-color: ${({ theme }) => theme.color.gray300};
  }

  &:focus-visible {
    outline: none;
    border-color: ${({ theme }) => theme.color.gray400};
    box-shadow: 0 0 0 ${({ theme }) => theme.border.widthThick}
      ${({ theme }) => theme.color.gray400};
  }
`;

export const StyledAppSelectionBox = styled(Box)`
  width: 100%;
  border-radius: ${({ theme }) => theme.border.radius};
  background-color: ${({ theme }) => theme.color.yellow100};
`;
