import { User } from "../api/core/dataPlane.types";
import {
  PLAN_TYPE_INNOVATOR,
  PLAN_TYPE_LEGACY_BASIC,
  PLAN_TYPE_LEGACY_ENTERPRISE,
  PLAN_TYPE_LEGACY_PREMIUM,
  PLAN_TYPE_PREMIUM,
  PLAN_TYPE_SCALE_UP,
} from "../config/plans";

export const getIsUserPaid = (user: User): boolean => {
  return !!(getIsUserPaidPremium(user) || getIsUserPaidNonPremium(user));
};

const getIsUserPaidNonPremium = (user: User): boolean => {
  return !!(
    user.planInfo?.planType === PLAN_TYPE_SCALE_UP ||
    user.planInfo?.planType === PLAN_TYPE_INNOVATOR ||
    user.planInfo?.planType === PLAN_TYPE_LEGACY_BASIC
  );
};

export const getIsUserPaidPremium = (user: User): boolean =>
  // PLAN_TYPE_LEGACY_PREMIUM is for Nextmv internal users
  // and customers with plans managed via feature flags
  user.planInfo?.planType === PLAN_TYPE_LEGACY_PREMIUM ||
  user.planInfo?.planType === PLAN_TYPE_LEGACY_ENTERPRISE ||
  user.planInfo?.planType === PLAN_TYPE_PREMIUM;

export const isActiveFreeTrial = (user: User): boolean => {
  return !!(
    user.planInfo?.trialStatus === "in-progress" && !getIsUserPaid(user)
  );
};

// @legacy-plans (unused)
export const getIsUserFreeTrialComplete = (user: User): boolean => {
  return !!(
    user.planInfo?.trialStatus === "completed" && !getIsUserPaidPremium(user)
  );
};

// @legacy-plans (unused)
export const getIsUserEligibleForFreeTrial = (user: User): boolean => {
  return !!(
    !getIsUserPaidPremium(user) && user.planInfo?.trialStatus === "available"
  );
};

export const hasRolesRestrictions = (user: User): boolean => {
  return !getIsUserPaidPremium(user) && !isActiveFreeTrial(user);
};

export const teamMemberLimits = (user: User): number | undefined => {
  if (!hasRolesRestrictions(user)) {
    return undefined;
  }
  const planType = user.planInfo?.planType;
  if (planType === PLAN_TYPE_SCALE_UP) return 5;
  return 2;
};

export const userCanAccessIntegrationAndConfigPages = (user: User) => {
  return !!(
    user.features?.onfleet ||
    user.features?.streets ||
    user.features?.here
  );
};

export const checkHasTeamsAccess = (user: User): boolean => {
  return getIsUserPaid(user) || isActiveFreeTrial(user);
};
