import * as React from "react";
import { components } from "react-select";
import { useTheme } from "@emotion/react";

import Flex from "../../Flex";
import Text from "../../Text";

import { StyledExecutionClassSpan } from "./SingleValueExecutionClass";

// get Option base component from library
const { Option } = components;

const CustomOptionExecutionClass = (props: any) => {
  const theme = useTheme();

  // get data to build option, this is the object from
  // the data array for this particular option
  const { data } = props;

  const renderExecutionClassOption = () => {
    return (
      <Flex width="100%" flexDirection="column">
        <Flex alignItems="baseline">
          <StyledExecutionClassSpan isPremium={data.isPremium}>
            {data.id}
          </StyledExecutionClassSpan>

          {data?.isDefault && (
            <Text
              ml={1}
              styleName="body-3"
              styles={{ color: theme.color.gray600 }}
            >
              (default)
            </Text>
          )}
        </Flex>

        <Text mt={1} styleName="meta-1">
          {data.description}
        </Text>
      </Flex>
    );
  };

  return <Option {...props}>{renderExecutionClassOption()}</Option>;
};

export default CustomOptionExecutionClass;
