import React from "react";
import { Link } from "react-router-dom";
import { useTheme } from "@emotion/react";

import Loading from "../Loading";

import MultiSelectButtonContents from "./components/MultiSelectButtonContents";
import { getButtonLoadingDotColor } from "./utils/getButtonStyles";
import {
  StyledButtonOutline,
  StyledButtonPrimary,
  StyledButtonText,
  StyledLabelSpacer,
} from "./Button2.styled";
import { Button2Props, RenderButtonContentsParams } from "./Button2.types";

const renderButtonContents = ({
  icon,
  icon2,
  isDisabled,
  isLoading,
  label,
  loadingColor,
  theme,
  type,
}: RenderButtonContentsParams) => {
  if (isLoading) {
    return (
      <Loading
        type="default"
        dotColor={
          loadingColor || getButtonLoadingDotColor({ isDisabled, theme, type })
        }
      />
    );
  }

  return (
    <>
      {icon}
      {label && (
        <StyledLabelSpacer {...{ icon, icon2 }}>{label}</StyledLabelSpacer>
      )}
      {icon2}
    </>
  );
};

const Button2 = ({
  href,
  htmlTitle,
  htmlType,
  icon,
  icon2,
  innerRef,
  isDisabled,
  isLoading,
  isUrlExternal,
  label,
  loadingColor,
  multiSelectOptions,
  onClick,
  size,
  styles,
  testId,
  to,
  type,
  m,
  mx,
  my,
  mt,
  mr,
  mb,
  ml,
  ...rest
}: Button2Props) => {
  const theme = useTheme();

  const isMultiSelect = !!multiSelectOptions;
  const ariaDisabled =
    isMultiSelect && !isDisabled ? undefined : isDisabled ? "true" : undefined;
  const as = isMultiSelect ? "div" : to ? Link : href ? "a" : "button";
  const role = to || href ? "link" : "button";
  const styledType = type || "primary";

  const buttonProps = {
    hasIcon: !!icon,
    hasIcon2: !!icon2,
    isDisabled,
    label,
    size,
    styles,
    m,
    mx,
    my,
    mt,
    mr,
    mb,
    ml,
    ...(testId && {
      "data-testid": testId,
    }),
    ...(!isDisabled && {
      onClick,
      href,
      to,
    }),
    ...rest,
  };

  switch (type) {
    case "outline":
    case "outline-quiet":
      return (
        <StyledButtonOutline
          ref={innerRef}
          {...buttonProps}
          {...{ as, isMultiSelect, role, styledType }}
          aria-disabled={ariaDisabled}
          disabled={isDisabled}
          {...(isUrlExternal &&
            !isDisabled && {
              target: "_blank",
              rel: "noreferrer",
            })}
          {...(htmlType && {
            type: htmlType,
          })}
          {...(htmlTitle && {
            title: htmlTitle,
          })}
        >
          {isMultiSelect ? (
            <MultiSelectButtonContents
              buttonProps={buttonProps}
              {...{
                isLoading,
                multiSelectOptions,
                size,
              }}
              type={styledType}
            />
          ) : (
            renderButtonContents({
              icon,
              icon2,
              isDisabled,
              isLoading,
              label,
              loadingColor,
              theme,
              type: styledType,
            })
          )}
        </StyledButtonOutline>
      );
    case "text":
    case "text-quiet":
      return (
        <StyledButtonText
          ref={innerRef}
          {...buttonProps}
          {...{ as, isMultiSelect, role, styledType }}
          aria-disabled={ariaDisabled}
          disabled={isDisabled}
          {...(isUrlExternal &&
            !isDisabled && {
              target: "_blank",
              rel: "noreferrer",
            })}
          {...(htmlType && {
            type: htmlType,
          })}
          {...(htmlTitle && {
            title: htmlTitle,
          })}
        >
          {renderButtonContents({
            icon,
            icon2,
            isDisabled,
            isLoading,
            label,
            loadingColor,
            theme,
            type: styledType,
          })}
        </StyledButtonText>
      );
    default:
      return (
        <StyledButtonPrimary
          ref={innerRef}
          {...buttonProps}
          {...{ as, isMultiSelect, role, styledType }}
          aria-disabled={ariaDisabled}
          disabled={isDisabled}
          {...(isUrlExternal &&
            !isDisabled && {
              target: "_blank",
              rel: "noreferrer",
            })}
          {...(htmlType && {
            type: htmlType,
          })}
          {...(htmlTitle && {
            title: htmlTitle,
          })}
        >
          {isMultiSelect ? (
            <MultiSelectButtonContents
              buttonProps={buttonProps}
              {...{
                isLoading,
                multiSelectOptions,
                size,
              }}
              type={styledType}
            />
          ) : (
            renderButtonContents({
              icon,
              icon2,
              isDisabled,
              isLoading,
              label,
              loadingColor,
              theme,
              type: styledType,
            })
          )}
        </StyledButtonPrimary>
      );
  }
};

export default Button2;
