import React, { useEffect, useState } from "react";
import { Box, Button2, Notification, Text } from "@console/dsc";
import { useTheme } from "@emotion/react";

import { EntityErrorMessage } from "../../api/core/controlPlane.types";
import HeaderPublic from "../../components/HeaderPublic";
import { useMetaTitle } from "../../components/Layout";
import LayoutPublic from "../../components/LayoutPublic";
import { AUTH_FLOW_CONTENT_MAX_WIDTH } from "../../config/public";
import useAuthFlow from "../../hooks/useAuthFlow";
import AuthFlowCallout from "../LogIn/components/AuthFlowCallout";
import GoogleAuthenticate from "../LogIn/components/GoogleAuthenticate";

import SignUpForm from "./components/SignUpForm";
import TermsAgreement from "./components/TermsAgreement";

const pageTitle = "Sign Up";

type SignUpContentProps = {
  username?: string | null;
};

const SignUpContent = ({ username }: SignUpContentProps) => {
  const theme = useTheme();
  const [, setMetaTitle] = useMetaTitle();

  const [isEmailSet, setIsEmailSet] = useState(false);
  const [isPage2, setIsPage2] = useState(false);
  const [emailInputError, setEmailInputError] =
    useState<EntityErrorMessage>(null);

  useEffect(() => {
    setMetaTitle(pageTitle);
  }, [setMetaTitle]);

  const {
    company,
    email,
    error,
    password,
    hasSignedUp,
    isPasswordFocused,
    isProcessing,
    isSubscribedNewsletter,
    nameFirst,
    nameLast,
    setCompany,
    setEmail,
    setIsPasswordFocused,
    setIsSubscribedNewsletter,
    setNameFirst,
    setNameLast,
    setPassword,
    signUpEmailPassword,
    signUpGoogle,
    sendVerificationEmail,
  } = useAuthFlow();

  // pre-fill email field if data exists
  useEffect(() => {
    if (username && !isEmailSet) {
      setEmail(username);
      setIsEmailSet(true);
    }
  }, [isEmailSet, setEmail, username]);

  const handleResendVerificationEmail = async (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    e.preventDefault();
    e.stopPropagation();

    await sendVerificationEmail(email);
  };

  const handleSignUpGoBack = (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    e.preventDefault();
    e.stopPropagation();

    setIsPage2(false);
    return;
  };

  return (
    <>
      <HeaderPublic
        configPageTitle={{
          label: hasSignedUp ? "Verify account" : "Sign up for a free account",
        }}
        {...(isPage2 &&
          !hasSignedUp && {
            configMetaLink: {
              onClick: handleSignUpGoBack,
              label: "Back",
            },
          })}
      />

      <Box mt={6} maxWidth={AUTH_FLOW_CONTENT_MAX_WIDTH}>
        {hasSignedUp ? (
          <>
            <Notification
              testId="sign-up-success-notification"
              type="success"
              message="A verification link was sent to your email. Click on the button in the email to complete your signup."
            />

            <Box mt={4} pb={4}>
              <Text styleName="body-2" styles={{ color: theme.color.gray600 }}>
                Didn&#8217;t receive an email?
              </Text>
              <Button2
                mt={2}
                type="outline-quiet"
                label="Resend email"
                isLoading={isProcessing}
                onClick={handleResendVerificationEmail}
              />
            </Box>
          </>
        ) : (
          <>
            <SignUpForm
              company={company}
              email={email}
              emailInputError={emailInputError}
              error={error}
              password={password}
              isPage2={isPage2}
              isPasswordFocused={isPasswordFocused}
              isProcessing={isProcessing}
              isSubscribedNewsletter={isSubscribedNewsletter}
              nameFirst={nameFirst}
              nameLast={nameLast}
              setCompany={setCompany}
              setEmail={setEmail}
              setEmailInputError={setEmailInputError}
              setIsPage2={setIsPage2}
              setIsPasswordFocused={setIsPasswordFocused}
              setIsSubscribedNewsletter={setIsSubscribedNewsletter}
              setNameFirst={setNameFirst}
              setNameLast={setNameLast}
              setPassword={setPassword}
              signUpEmailPassword={signUpEmailPassword}
            />

            {!isPage2 && (
              <>
                <AuthFlowCallout
                  content="Already have an account?"
                  linkLabel="Log in"
                  linkTo={`/login/identify${
                    email ? `?username=${encodeURIComponent(email)}` : ""
                  }`}
                  linkTrackEventCategory="AuthFlow"
                  linkTrackEventProperties={{
                    view: "Sign Up",
                    action: "Log In Link Clicked",
                    meta: {
                      from: "signup",
                    },
                  }}
                  testId="log-in-link"
                />

                <GoogleAuthenticate
                  buttonLabel="Continue with Google"
                  buttonName="google-signup-button"
                  buttonOnClick={signUpGoogle}
                />
              </>
            )}

            {isPage2 && <TermsAgreement />}
          </>
        )}
      </Box>
    </>
  );
};

const SignUp = ({ username }: SignUpContentProps) => {
  return (
    <LayoutPublic>
      <SignUpContent {...{ username }} />
    </LayoutPublic>
  );
};

export default SignUp;
