import * as React from "react";
import { useTheme } from "@emotion/react";
import {
  CartesianGrid,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { formatTime, toK } from "../../../lib/tools";
import CustomLabel from "../custom/Label";
import CustomPoint from "../custom/Point";
import CustomTooltip from "../custom/Tooltip";
import { getTickLabelStyle } from "../utils/chartStyles";

// FIXME: TypeScript gives error:
// Cannot use namespace 'Theme' as a type.
// when used below with theme: Theme
// import Theme from '../../../style/emotion.d'

const ChartSolutionValueOverTime = ({
  data,
  xAxisLabel,
  yAxisLabel,
  yAxisWidth,
}: {
  data: any[];
  xAxisLabel?: string;
  yAxisLabel?: string;
  yAxisWidth: number;
}) => {
  const theme = useTheme();
  const tickLabelStyle = getTickLabelStyle(theme);

  // note that sibling component order corresponds
  // to z-index in the rendered chart (with first in
  // component order being the bottom in z-index)

  return (
    <ResponsiveContainer width="100%" height={340}>
      <ScatterChart margin={{ bottom: 24, left: 24 }}>
        <CartesianGrid
          stroke={theme.color.gray200T}
          vertical={false}
          style={{
            backgroundColor: theme.color.gray100,
          }}
        />

        {/*<Line
          type="monotone"
          dataKey="value"
          stroke={theme.color.green}
        />*/}
        <Scatter
          data={data}
          line={{
            stroke: theme.color.green,
            strokeWidth: 3,
          }}
          lineType="joint"
          shape={({ cx, cy }) => <CustomPoint {...{ cx, cy }} />}
        />

        <Tooltip
          content={<CustomTooltip noTotal />}
          isAnimationActive={false}
          cursor={{ stroke: theme.color.gray300, strokeWidth: 1 }}
        />

        <XAxis
          dataKey="timeElapsed"
          label={<CustomLabel>{xAxisLabel}</CustomLabel>}
          padding={{ left: 24, right: 24 }}
          stroke={theme.color.gray300}
          style={tickLabelStyle}
          tickMargin={8}
          tickSize={0}
          tickFormatter={formatTime}
          type="number"
        />

        <YAxis
          dataKey="value"
          domain={["dataMin - 1000", "auto"]}
          label={<CustomLabel isYAxis>{yAxisLabel}</CustomLabel>}
          padding={{ top: 16 }}
          interval="preserveStart"
          stroke={theme.color.gray300}
          style={tickLabelStyle}
          // tickCount={5}
          tickMargin={4}
          tickSize={0}
          tickFormatter={(value) => toK(value)}
          width={yAxisWidth}
          // unit="k"
        />
      </ScatterChart>
    </ResponsiveContainer>
  );
};

export default ChartSolutionValueOverTime;
