import * as React from "react";
import { components } from "react-select";
import { useTheme } from "@emotion/react";

import { rem } from "../../../lib/tools";
import Box from "../../Box";
import Flex from "../../Flex";
import Text from "../../Text";

// get SingleValue base component from library
const { SingleValue } = components;

const CustomSingleValueMeta = (props: any) => {
  const theme = useTheme();

  // get data to build option, this is the object from
  // the data array for this particular option
  const { data } = props;
  const { avatar, count, countLabel, id, name } = data;

  const singleValueName = name || id;

  const renderMetaName = () => {
    return (
      <Flex alignItems="center">
        {avatar && (
          <Box mt={rem(6)} mr={2}>
            {avatar}
          </Box>
        )}
        <Flex alignItems="baseline" justifyContent="flex-start">
          <Text styleName="body-2" styles={{ color: theme.color.gray800 }}>
            {singleValueName}
          </Text>

          {count && (
            <Text
              as="span"
              ml={2}
              styleName="meta-1"
              styles={{ color: theme.color.gray600 }}
            >
              {count} {countLabel}
            </Text>
          )}
        </Flex>
      </Flex>
    );
  };

  return <SingleValue {...props}>{renderMetaName()}</SingleValue>;
};

export default CustomSingleValueMeta;
