import { useMemo } from "react";
import { Box, Flex, Text, Tooltip } from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";
import { startCase } from "lodash";

import { TEXT_MEASURE_MAX_WIDTH } from "../../../../../utils/constants";
import { StyledRunViewContainer } from "../RunDetails.styled";
import {
  getCustomSeriesData,
  getValueSeriesData,
} from "../utils/getSeriesData";

import ChartCustomSeries from "./ChartCustomSeries";

export type RunDetailsAnalysisProps = {
  isExpanded: boolean;
  seriesData: any;
  hasHeaderSpace?: boolean;
};

const renderEmptySeriesDataMessage = () => {
  return (
    <Box
      pt={4}
      maxWidth={TEXT_MEASURE_MAX_WIDTH}
      data-testid="run-analysis-empty-message"
    >
      <Text styleName="body-1" hasLink>
        The Analysis view renders the information returned in the{" "}
        <Text as="code" styleName="code">
          series_data
        </Text>{" "}
        block of the run output&#8217;s{" "}
        <Text as="code" styleName="code">
          statistics
        </Text>{" "}
        section. For more information on enabling analysis data for your runs,
        see the{" "}
        <Text as="code" styleName="code">
          series_data
        </Text>{" "}
        subsection of the{" "}
        <a
          href="https://www.nextmv.io/docs/reference/experiments/statistics-convention#keys"
          target="_blank"
          rel="noreferrer"
        >
          keys section in the Statistics Convention page
        </a>{" "}
        in Docs.{" "}
      </Text>
      <Text mt={3} styleName="body-1">
        (Note that series data is not available for all apps.)
      </Text>

      <Text mt={3} styleName="body-1">
        If you were expecting to see series data displayed here, check to make
        sure that the{" "}
        <Text as="code" styleName="code">
          format.disable.progression
        </Text>{" "}
        option is not set to{" "}
        <Text as="code" styleName="code">
          true
        </Text>
        .
      </Text>
    </Box>
  );
};

const RunDetailsAnalysis = ({
  hasHeaderSpace,
  isExpanded,
  seriesData,
}: RunDetailsAnalysisProps) => {
  const theme = useTheme();

  const valueSeries = useMemo(
    () => getValueSeriesData(seriesData),
    [seriesData]
  );
  const customSeries = useMemo(
    () => getCustomSeriesData(seriesData),
    [seriesData]
  );

  return (
    <StyledRunViewContainer {...{ isExpanded }}>
      {!valueSeries && !customSeries && renderEmptySeriesDataMessage()}

      {valueSeries && (
        <Box data-testid="run-analysis-chart-value">
          <Flex pt={5} alignItems="center">
            <Text
              as="h3"
              styleName="body-2-bold"
              styles={{ color: theme.color.gray800 }}
            >
              Solution Value Over Time
            </Text>
            <Tooltip ml={rem(4)} mt={rem(2)}>
              Value function name: {valueSeries?.name}
            </Tooltip>
          </Flex>

          <Box mt={6} mr={[4, 0, 0]}>
            <ChartCustomSeries
              data={valueSeries.data}
              xDataKey={valueSeries.xDataKey}
              yDataKey={valueSeries.yDataKey}
              xAxisLabel="Time elapsed"
              yAxisLabel="Solution value"
              chartColor={theme.color.green500}
              offsetYMin={1000}
            />
          </Box>
        </Box>
      )}

      {customSeries &&
        customSeries.map((customSet, index) => (
          <Box
            mt={5}
            key={`${customSet.name}-${index}`}
            data-testid={`run-analysis-chart-custom-${customSet.name}-${index}`}
          >
            <Text
              as="h3"
              styleName="body-2-bold"
              styles={{ color: theme.color.gray800 }}
            >
              {startCase(customSet.name)}
            </Text>

            <Box mt={6} mr={[4, 0, 0]}>
              <ChartCustomSeries
                data={customSet.data}
                xDataKey={customSet.xDataKey}
                yDataKey={customSet.yDataKey}
              />
            </Box>
          </Box>
        ))}
    </StyledRunViewContainer>
  );
};

export default RunDetailsAnalysis;
