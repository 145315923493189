import React, { useState } from "react";

import { getAcceptanceTests } from "../../../api/core/controlPlane";
import { AcceptanceTests } from "../../../api/core/controlPlane.types";
import { useUser } from "../../../AuthProvider";

const useAcceptanceTests = () => {
  const [{ id: accountId }] = useUser();
  const [acceptanceTests, setAcceptanceTests] = useState<AcceptanceTests>(null);
  const [acceptanceTestsError, setAcceptanceTestsError] = useState<string>("");

  const loadAcceptanceTests = React.useCallback(
    async (applicationId: string, limit?: number) => {
      try {
        const acceptanceTests = (await getAcceptanceTests(accountId || "")(
          applicationId,
          limit
        )) as AcceptanceTests;

        setAcceptanceTests(acceptanceTests);
        setAcceptanceTestsError("");
        return acceptanceTests;
      } catch (e: any) {
        console.error(e);
        setAcceptanceTestsError(e.toString());
      }
    },
    [accountId]
  );

  return {
    acceptanceTests,
    acceptanceTestsError,
    loadAcceptanceTests,
    setAcceptanceTests,
    setAcceptanceTestsError,
  };
};

export default useAcceptanceTests;
