import { DetailsConditionalStylesParams } from "../Details.types";

export const getSummaryPaddingRight = ({
  controlPosition,
  size,
  theme,
}: DetailsConditionalStylesParams) => {
  if (controlPosition === "right") {
    return size === "large" ? theme.spacing.s7 : theme.spacing.s4;
  }
  return 0;
};

export const getSummaryPaddingLeft = ({
  controlPosition,
  size,
  theme,
}: DetailsConditionalStylesParams) => {
  if (controlPosition === "left") {
    return size === "large" ? theme.spacing.s7 : theme.spacing.s4;
  }
  return 0;
};

export const getControlIconDimension = ({
  size,
  theme,
}: DetailsConditionalStylesParams) => {
  if (size === "large") return theme.spacing.s6;
  return theme.spacing.s4;
};

export const getControlIconPositionLeft = ({
  controlPosition,
}: DetailsConditionalStylesParams) => {
  if (controlPosition === "left") return 0;
  return "auto";
};

export const getControlIconPositionRight = ({
  controlPosition,
}: DetailsConditionalStylesParams) => {
  if (controlPosition === "right") return 0;
  return "auto";
};

export const getControlIcon = ({
  controlPosition,
  theme,
}: DetailsConditionalStylesParams) => {
  if (controlPosition === "right") {
    return `url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath d='M10 4.26667L10 11.7333L5 8L10 4.26667Z' fill='${theme.color.gray800.replace(
      "#",
      "%23"
    )}'/%3e %3c/svg%3e")`;
  }
  return `url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath d='M6.4 11.7333L6.4 4.26667L11.2 8L6.4 11.7333Z' fill='${theme.color.gray800.replace(
    "#",
    "%23"
  )}'/%3e %3c/svg%3e")`;
};

export const getControlIconExpanded = ({
  theme,
}: DetailsConditionalStylesParams) => {
  return `url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath d='M4.26667 6.4H11.7333L8 11.2L4.26667 6.4Z' fill='${theme.color.gray800.replace(
    "#",
    "%23"
  )}'/%3e %3c/svg%3e")`;
};
