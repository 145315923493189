import {
  AcceptanceTestMetric,
  AcceptanceTestMetricOperator,
} from "../../../api/core/controlPlane.types";
import {
  EXP_ACC_METRIC_TYPE,
  EXP_ACC_SENSE_EQ,
  EXP_ACC_SENSE_GE,
  EXP_ACC_SENSE_GT,
  EXP_ACC_SENSE_LE,
  EXP_ACC_SENSE_LT,
  EXP_ACC_SENSE_NE,
  EXP_ACC_STATISTIC,
} from "../data/constants";

type ExcludesFalse = <T>(x: T | null) => x is T;

export const supportedAcceptanceTestOperators: (keyof AcceptanceTestMetricOperator)[] =
  ["ge", "gt", "lt", "le", "eq", "ne"];

export const getSenseLabel = (operator: string) => {
  const comparisonWordObj: {
    [key in keyof AcceptanceTestMetricOperator]: string;
  } = {
    lt: EXP_ACC_SENSE_LT,
    le: EXP_ACC_SENSE_LE,
    gt: EXP_ACC_SENSE_GT,
    ge: EXP_ACC_SENSE_GE,
    eq: EXP_ACC_SENSE_EQ,
    ne: EXP_ACC_SENSE_NE,
  };
  return (
    comparisonWordObj[operator as keyof AcceptanceTestMetricOperator] || ""
  );
};

export const getMetricOperatorSymbol = (operator: string) => {
  const comparisonWordObj: {
    [key in keyof AcceptanceTestMetricOperator]: string;
  } = {
    lt: "<",
    le: "≤",
    gt: ">",
    ge: "≥",
    eq: "=",
    ne: "≠",
  };
  return (
    comparisonWordObj[operator as keyof AcceptanceTestMetricOperator] || ""
  );
};

export const parseMetricsToString = (
  metrics: AcceptanceTestMetric[]
): string => {
  return metrics
    .map((metric) => {
      if (!metric.field || !metric.params.operator) return null;
      return `${metric.field}: ${metric.params.operator}`;
    })
    .filter(Boolean as any as ExcludesFalse)
    .join("\n");
};

// TODO: the type handling is weird here
const checkMetricOperator = (
  operator: string
): keyof AcceptanceTestMetricOperator | undefined => {
  if (
    supportedAcceptanceTestOperators.indexOf(
      operator as keyof AcceptanceTestMetricOperator
    ) > -1
  ) {
    return operator as keyof AcceptanceTestMetricOperator;
  }
  return undefined;
};

export const parseStringToMetrics = (
  string: string
): AcceptanceTestMetric[] => {
  return string
    .split("\n")
    .map((metricString) => {
      const metricArray = metricString.split(":");
      if (metricArray.length !== 2) return null;

      const metricField = metricArray[0].trim();
      const metricOperator = checkMetricOperator(metricArray[1].trim());
      if (!metricField || !metricOperator) return null;

      return {
        field: metricField,
        statistic: EXP_ACC_STATISTIC,
        metric_type: EXP_ACC_METRIC_TYPE,
        params: {
          operator: metricOperator,
        },
      };
    })
    .filter(Boolean as any as ExcludesFalse);
};
