import React from "react";

import useManageEntities from "../../hooks/useManageEntities";

import useAcceptanceTests from "./hooks/useAcceptanceTests";
import useInputSets from "./hooks/useInputSets";
import useShadowTests from "./hooks/useShadowTests";
import useSwitchbackTests from "./hooks/useSwitchbackTests";
import { ExperimentsContextProps } from "./Experiments.context.types";

export const ExperimentsContext = React.createContext<ExperimentsContextProps>({
  acceptanceTests: null,
  acceptanceTestsError: "",
  batchExperiments: null,
  batchExperimentsError: null,
  inputSets: null,
  inputSetsError: "",
  loadAcceptanceTests: (applicationId) => new Promise((resolve) => resolve([])),
  loadBatchExperiments: () => new Promise((resolve) => resolve(undefined)),
  loadInputSets: (applicationId) => new Promise((resolve) => resolve([])),
  loadScenarioTests: () => new Promise((resolve) => resolve(undefined)),
  loadShadowTests: (applicationId) => new Promise((resolve) => resolve([])),
  loadSwitchbackTests: (applicationId, pageToken) =>
    new Promise((resolve) => resolve([])),
  scenarioTests: null,
  scenarioTestsError: null,
  setAcceptanceTests: () => null,
  setAcceptanceTestsError: () => null,
  setBatchExperiments: () => null,
  setBatchExperimentsError: () => null,
  setInputSets: () => null,
  setInputSetsError: () => null,
  setScenarioTests: () => null,
  setScenarioTestsError: () => null,
  setShadowTests: () => null,
  setShadowTestsError: () => null,
  setSwitchbackTests: () => null,
  setSwitchbackTestsError: () => null,
  shadowTests: null,
  shadowTestsError: "",
  switchbackTests: { items: null, next_page_token: "" },
  switchbackTestsError: "",
});

const ExperimentsProvider = ({ children }: { children: React.ReactNode }) => {
  const {
    entities: batchExperiments,
    entitiesLoadError: batchExperimentsError,
    loadEntities: loadBatchExperiments,
    setEntities: setBatchExperiments,
    setEntitiesLoadError: setBatchExperimentsError,
  } = useManageEntities("experiments/batch");

  const {
    entities: scenarioTests,
    entitiesLoadError: scenarioTestsError,
    loadEntities: loadScenarioTests,
    setEntities: setScenarioTests,
    setEntitiesLoadError: setScenarioTestsError,
  } = useManageEntities("experiments/batch");

  const {
    inputSets,
    inputSetsError,
    loadInputSets,
    setInputSets,
    setInputSetsError,
  } = useInputSets();

  const {
    acceptanceTests,
    acceptanceTestsError,
    loadAcceptanceTests,
    setAcceptanceTests,
    setAcceptanceTestsError,
  } = useAcceptanceTests();

  const {
    shadowTests,
    shadowTestsError,
    loadShadowTests,
    setShadowTests,
    setShadowTestsError,
  } = useShadowTests();

  const {
    switchbackTests,
    switchbackTestsError,
    loadSwitchbackTests,
    setSwitchbackTests,
    setSwitchbackTestsError,
  } = useSwitchbackTests();

  const value: ExperimentsContextProps = {
    acceptanceTests,
    acceptanceTestsError,
    batchExperiments,
    batchExperimentsError,
    inputSets,
    inputSetsError,
    loadAcceptanceTests,
    loadBatchExperiments,
    loadInputSets,
    loadScenarioTests,
    loadShadowTests,
    loadSwitchbackTests,
    scenarioTests,
    scenarioTestsError,
    setAcceptanceTests,
    setAcceptanceTestsError,
    setBatchExperiments,
    setBatchExperimentsError,
    setInputSets,
    setInputSetsError,
    setScenarioTests,
    setScenarioTestsError,
    setShadowTests,
    setShadowTestsError,
    setSwitchbackTests,
    setSwitchbackTestsError,
    shadowTests,
    shadowTestsError,
    switchbackTests,
    switchbackTestsError,
  };

  return (
    <ExperimentsContext.Provider value={value}>
      {children}
    </ExperimentsContext.Provider>
  );
};

export const useExperiments = () => React.useContext(ExperimentsContext);

export default ExperimentsProvider;
