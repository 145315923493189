import { isObject } from "lodash";

import { hasShiftSchedulingSchemaTopLevel } from "../../../utils/schemaCheckShiftScheduling";

export type RunInputVizType = "routing" | "scheduling" | "custom";
export const getRunInputVizType = (inputState: {
  [key: string]: any;
}): RunInputVizType => {
  const input = inputState?.input;
  if (!input || !isObject(input)) return "custom";

  // note: this is a shortcut that may not always be available
  // should consider a full schema check when we move from
  // cloud fleet to nextroute-powered demos
  if (inputState?.markerCoords?.length) {
    return "routing";
  }

  if (hasShiftSchedulingSchemaTopLevel(input)) {
    return "scheduling";
  }

  return "custom";
};
