import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { AppResponse } from "../../api/core/controlPlane.types";
import { useUser } from "../../AuthProvider";
import ExperimentsProvider from "../../contexts/experiments/Experiments.context";
import { getAccUrl } from "../../utils/navigation";
import AppInput from "../App/subpages/AppInput";
import AppInputs from "../App/subpages/AppInputs";
import EditAppInput from "../App/subpages/EditAppInput";
import NewAppInput from "../App/subpages/NewAppInput";
import AcceptanceTest from "../Experiments/subpages/AcceptanceTest";
import AcceptanceTests from "../Experiments/subpages/AcceptanceTests";
import BatchExperiment from "../Experiments/subpages/BatchExperiment";
import BatchExperiments from "../Experiments/subpages/BatchExperiments";
import EditInputSetDetails from "../Experiments/subpages/EditInputSetDetails";
import InputSet from "../Experiments/subpages/InputSet";
import InputSets from "../Experiments/subpages/InputSets";
import NewAcceptanceTest from "../Experiments/subpages/NewAcceptanceTest";
import NewExperiment from "../Experiments/subpages/NewExperiment";
import NewInputSet from "../Experiments/subpages/NewInputSet";
import NewShadowTest from "../Experiments/subpages/NewShadowTest";
import NewSwitchbackTest from "../Experiments/subpages/NewSwitchbackTest";
import ShadowTest from "../Experiments/subpages/ShadowTest";
import ShadowTests from "../Experiments/subpages/ShadowTests";
import SwitchbackTest from "../Experiments/subpages/SwitchbackTest/SwitchbackTest.page";
import SwitchbackTests from "../Experiments/subpages/SwitchbackTests";

import EditAcceptanceTest from "./subpages/EditAcceptanceTest";
import EditBatchExperiment from "./subpages/EditBatchExperiment";
import EditScenarioTest from "./subpages/EditScenarioTest";
import EditShadowTest from "./subpages/EditShadowTest";
import EditSwitchbackTest from "./subpages/EditSwitchbackTest";
import NewScenarioTest from "./subpages/NewScenarioTest";
import ScenarioTest from "./subpages/ScenarioTest";
import ScenarioTests from "./subpages/ScenarioTests";

type ExperimentRoutesProps = {
  app: AppResponse;
  path: string;
  setDisplayPages?: React.Dispatch<React.SetStateAction<boolean>>;
};

const ExperimentRoutes = ({
  app,
  setDisplayPages,
  path,
}: ExperimentRoutesProps) => {
  const [user] = useUser();
  const { id: accountId } = user;
  const appId = app.id;

  return (
    <ExperimentsProvider>
      <Switch>
        {/* Batch */}
        <Route exact path={`${path}/batch`}>
          <BatchExperiments {...{ app }} />
        </Route>
        <Route exact path={`${path}/batch/new`}>
          <NewExperiment {...{ app }} />
        </Route>
        <Route exact path={`${path}/batch/:id`}>
          <BatchExperiment {...{ app }} />
        </Route>
        <Route exact path={`${path}/batch/:id/edit`}>
          <EditBatchExperiment {...{ app }} />
        </Route>
        <Route path={`${path}/batch`}>
          <Redirect
            to={getAccUrl(accountId, `/app/${appId}/experiments/batch`)}
          />
        </Route>

        {/* Scenario */}
        <Route exact path={`${path}/scenario`}>
          <ScenarioTests {...{ app }} />
        </Route>
        <Route exact path={`${path}/scenario/new`}>
          <NewScenarioTest {...{ app }} />
        </Route>
        <Route exact path={`${path}/scenario/:id`}>
          <ScenarioTest {...{ app, setDisplayPages }} />
        </Route>
        <Route exact path={`${path}/scenario/:id/edit`}>
          <EditScenarioTest {...{ app }} />
        </Route>
        <Route path={`${path}/scenario`}>
          <Redirect
            to={getAccUrl(accountId, `/app/${appId}/experiments/scenario`)}
          />
        </Route>

        {/* Shadow */}
        <Route exact path={`${path}/shadow`}>
          <ShadowTests {...{ app }} />
        </Route>
        <Route exact path={`${path}/shadow/new`}>
          <NewShadowTest {...{ app }} />
        </Route>
        <Route exact path={`${path}/shadow/:id`}>
          <ShadowTest {...{ app }} />
        </Route>
        <Route exact path={`${path}/shadow/:id/edit`}>
          <EditShadowTest {...{ app }} />
        </Route>
        <Route path={`${path}/shadow`}>
          <Redirect
            to={getAccUrl(accountId, `/app/${appId}/experiments/shadow`)}
          />
        </Route>

        {/* Switchback */}
        <Route exact path={`${path}/switchback`}>
          <SwitchbackTests {...{ app }} />
        </Route>
        <Route exact path={`${path}/switchback/new`}>
          <NewSwitchbackTest {...{ app }} />
        </Route>
        <Route exact path={`${path}/switchback/:id`}>
          <SwitchbackTest {...{ app }} />
        </Route>
        <Route exact path={`${path}/switchback/:id/edit`}>
          <EditSwitchbackTest {...{ app }} />
        </Route>
        <Route path={`${path}/switchback`}>
          <Redirect
            to={getAccUrl(accountId, `/app/${appId}/experiments/switchback`)}
          />
        </Route>

        {/* Acceptance */}
        <Route exact path={`${path}/acceptance`}>
          <AcceptanceTests {...{ app }} />
        </Route>
        <Route exact path={`${path}/acceptance/new`}>
          <NewAcceptanceTest {...{ app }} />
        </Route>
        <Route exact path={`${path}/acceptance/:id`}>
          <AcceptanceTest {...{ app }} />
        </Route>
        <Route exact path={`${path}/acceptance/:id/edit`}>
          <EditAcceptanceTest {...{ app }} />
        </Route>
        <Route path={`${path}/acceptance`}>
          <Redirect
            to={getAccUrl(accountId, `/app/${appId}/experiments/acceptance`)}
          />
        </Route>
        {/* redirects for old acceptance test patterns */}
        <Route exact path={`${path}/acceptance-tests`}>
          <Redirect to={getAccUrl(accountId, `/app/${appId}/acceptance`)} />
        </Route>
        <Route exact path={`${path}/acceptance-tests/new`}>
          <Redirect to={getAccUrl(accountId, `/app/${appId}/acceptance/new`)} />
        </Route>
        <Route
          exact
          path={`${path}/acceptance-test/:id`}
          render={(props) => (
            <Redirect
              to={getAccUrl(
                accountId,
                `/app/${appId}/acceptance/${props.match.params.id}`
              )}
            />
          )}
        />

        {/* Input sets */}
        <Route exact path={`${path}/input-sets`}>
          <InputSets {...{ app }} />
        </Route>
        <Route exact path={`${path}/input-sets/new`}>
          <NewInputSet {...{ app }} />
        </Route>
        <Route exact path={`${path}/input-set/:id`}>
          <InputSet {...{ app }} />
        </Route>
        <Route exact path={`${path}/input-set/:id/edit`}>
          <EditInputSetDetails {...{ app }} />
        </Route>
        <Route exact path={`${path}/input-set`}>
          <Redirect
            to={getAccUrl(accountId, `/app/${appId}/experiments/input-sets`)}
          />
        </Route>

        {/* Inputs */}
        <Route exact path={`${path}/inputs`}>
          <AppInputs {...{ app }} />
        </Route>
        <Route exact path={`${path}/inputs/new`}>
          <NewAppInput {...{ app }} />
        </Route>
        <Route exact path={`${path}/input/:id`}>
          <AppInput {...{ app }} />
        </Route>
        <Route exact path={`${path}/input/:id/edit`}>
          <EditAppInput {...{ app }} />
        </Route>
        <Route exact path={`${path}/input`}>
          <Redirect
            to={getAccUrl(accountId, `/app/${appId}/experiments/inputs`)}
          />
        </Route>

        <Redirect to={getAccUrl(accountId, `/apps`)} />
      </Switch>
    </ExperimentsProvider>
  );
};

export default ExperimentRoutes;
