import { Box, Flex, IllustrationBunnyCelebrate, Text } from "@console/dsc";

export const getActionButtonLabel = (
  pageNumber: number,
  totalPages: number,
  isSubmitted: boolean
) => {
  if (isSubmitted) return "Get started!";

  if (pageNumber === totalPages) {
    return "Submit";
  }
  return "Next";
};

export const getModalPageTitle = (pageNumber: number, isSubmitted: boolean) => {
  if (isSubmitted) return "Success! Let’s get started!";

  switch (pageNumber) {
    case 1:
      return "Team profile";
    case 2:
      return "Project types";
    case 3:
      return "Model runs";
    default:
      return undefined;
  }
};

export const getCompletedContent = () => {
  return (
    <Box mt={4}>
      <Text mt={3} styleName="body-1" hasLink>
        Your answers have been submitted. If you have any other questions or
        would like to talk to someone from Nextmv don&#8217;t hesitate to email{" "}
        <a href="mailto:support@nextmv.io">support@nextmv.io</a>.
      </Text>

      <Flex mt={2} pr={6} justifyContent="center">
        <IllustrationBunnyCelebrate />
      </Flex>
    </Box>
  );
};
