import { Box, Flex } from "@console/dsc";
import {
  styleHideVisually,
  styleScrollbarY,
} from "@console/dsc/src/lib/constants";
import { rem } from "@console/dsc/src/lib/tools";
import styled from "@emotion/styled";

type StyledUserQuestionsPageProps = {
  isActive: boolean;
};
type StyledModalContentBoxProps = {
  isSubmitted: boolean;
};

export const StyledUserQuestionsPage = styled(
  Box
)<StyledUserQuestionsPageProps>`
  ${(props) => !props.isActive && styleHideVisually}
`;

export const StyledModalContentBox = styled(Box)<StyledModalContentBoxProps>`
  position: relative;

  ${(props) =>
    !props.isSubmitted &&
    `
      overflow-y: scroll;
      ${styleScrollbarY(props.theme)}
    `}
  ${(props) =>
    props.isSubmitted &&
    `
      overflow: hidden;
    `}
`;

export const StyledProcessingOverlayFlex = styled(Flex)`
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-right: ${({ theme }) => theme.spacing.s9};
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  backdrop-filter: blur(${rem(1)});
`;
