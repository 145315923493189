// https://usehooks-ts.com/react-hook/use-interval

import { useEffect, useRef, useState } from "react";

export default function useInterval(
  callback: () => void,
  delay: number | null,
  finalCallback?: () => void
) {
  const [isCompleted, setIsCompleted] = useState(true);
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    // Note: 0 is a valid value for delay.
    if (!delay && delay !== 0) {
      // "callback" function to run one last time
      if (!isCompleted && finalCallback) {
        setIsCompleted(true);
        return finalCallback();
      } else {
        return;
      }
    }

    isCompleted && setIsCompleted(false);
    const id = setInterval(() => savedCallback.current(), delay);

    return () => {
      clearInterval(id);
    };
  }, [delay, finalCallback, isCompleted]);
}
