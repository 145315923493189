import * as React from "react";
import { components } from "react-select";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { DateTime } from "luxon";

import Flex from "../../Flex";
import Text from "../../Text";

export const Capitalize = styled.span`
  text-transform: capitalize;
`;

// get Option base component from library
const { Option } = components;

const CustomOptionMeta = (props: any) => {
  const theme = useTheme();

  // get data to build option, this is the object from
  // the data array for this particular option
  const { data } = props;
  const { count, countLabel, id, name, lastUpdated, type } = data;

  const renderMetaOption = () => {
    return (
      <Flex width="100%" flexDirection="column">
        <Flex alignItems="baseline" justifyContent="flex-start">
          {name && (
            <Text
              styleName="body-2-bold"
              styles={{ color: theme.color.gray800 }}
            >
              {name}
            </Text>
          )}

          {count && (
            <Text
              as="span"
              ml={2}
              styleName="meta-1"
              styles={{ color: theme.color.gray600 }}
            >
              {count} {countLabel}
            </Text>
          )}
        </Flex>

        {id && <Text styleName="body-3">{id}</Text>}

        {type && (
          <Text mt={1} styleName="body-3">
            <Capitalize>{type}</Capitalize>
          </Text>
        )}

        {lastUpdated && (
          <Text
            as="time"
            mt={1}
            styleName="meta-1"
            {...(lastUpdated && {
              htmlDatetime: lastUpdated,
              htmlTitle: DateTime.fromISO(lastUpdated).toFormat(
                "LLL dd, yyyy @ hh:mm:ss ZZZZ"
              ),
            })}
          >
            {DateTime.fromISO(lastUpdated).toRelative()}
          </Text>
        )}
      </Flex>
    );
  };

  return <Option {...props}>{renderMetaOption()}</Option>;
};

export default CustomOptionMeta;
