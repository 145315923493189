import * as React from "react";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";

import { svgIconStyle } from "../lib/constants";

type StyledSvgProps = {
  activeColor?: string;
  focusColor?: string;
  hoverColor?: string;
};
const StyledSvg = styled.svg<StyledSvgProps>`
  ${(props) =>
    svgIconStyle({
      activeColor: props.activeColor,
      focusColor: props.focusColor,
      hoverColor: props.hoverColor,
    })}
`;

const IconCheckEncircled = ({
  activeColor,
  focusColor,
  hoverColor,
  iconColor,
  iconSize,
}: {
  activeColor?: string;
  focusColor?: string;
  hoverColor?: string;
  iconColor?: string;
  iconSize?: number;
}) => {
  const theme = useTheme();
  const thisIconSize = iconSize || 16;
  const thisIconColor = iconColor || theme.color.gray700;

  return (
    <StyledSvg
      {...{ activeColor, focusColor, hoverColor }}
      width={thisIconSize}
      height={thisIconSize}
      viewBox="0 0 16 16"
      fill={thisIconColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99987 0.777194C4.0109 0.777194 0.777186 4.01091 0.777186 7.99988C0.777186 11.9888 4.0109 15.2226 7.99987 15.2226C11.9888 15.2226 15.2226 11.9888 15.2226 7.99988C15.2226 4.0109 11.9888 0.777194 7.99987 0.777194ZM2.10719 7.99988C2.10719 4.74544 4.74544 2.10719 7.99987 2.10719C11.2543 2.10719 13.8926 4.74545 13.8926 7.99988C13.8926 11.2543 11.2543 13.8926 7.99987 13.8926C4.74544 13.8926 2.10719 11.2543 2.10719 7.99988ZM10.9725 6.00285C11.1949 5.68669 11.119 5.25003 10.8028 5.02754C10.4867 4.80506 10.05 4.881 9.82753 5.19716L6.93319 9.31018L5.83283 8.18945C5.56198 7.91358 5.11878 7.90952 4.84292 8.18037C4.56706 8.45122 4.56299 8.89442 4.83384 9.17028L6.52273 10.8904C6.66828 11.0387 6.87216 11.1146 7.07922 11.0977C7.28628 11.0808 7.47513 10.9728 7.59469 10.8029L10.9725 6.00285Z"
      />
    </StyledSvg>
  );
};

export default IconCheckEncircled;
