import React from "react";
import { Text } from "@console/dsc";
import { useTheme } from "@emotion/react";

const CustomLabel = ({ isYAxis, viewBox, stroke, isBold, children }: any) => {
  const theme = useTheme();

  const isVert = isYAxis;

  const cx = isVert ? viewBox.x - 8 : viewBox.x + viewBox.width / 2;
  const cy = isVert
    ? viewBox.height / 2 + viewBox.y
    : viewBox.y + viewBox.height + 12;

  const rotate = isVert ? `270 ${cx} ${cy}` : 0;

  return (
    <Text
      // CSS style properties (via keyword)
      styleName={isBold ? "meta-1-bold" : "meta-1"}
      // SVG-specific properties (<text> element)
      as="text"
      fill={isBold ? theme.color.gray900 : theme.color.gray600}
      x={cx}
      y={cy}
      transform={`rotate(${rotate})`}
      textAnchor="middle"
      stroke={stroke}
    >
      {children}
    </Text>
  );
};

export default CustomLabel;
