import React from "react";
import { Box, DecisionCount, Flex, Text, Tooltip } from "@console/dsc";
import { formatNumber, rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";

import { getDecisionsUsedPercentage } from "../../utils/decisions";

type DecisionCounterProps = {
  tooltipText: string;
  decisionsUsed?: number;
  decisionsAvailable?: number;
};

type ProgressBarProps = {
  progressPercentage?: number;
};

const ProgressBar = styled.div<ProgressBarProps>`
  width: 100%;
  height: ${({ theme }) => theme.spacing.s2};
  position: relative;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.spacing.s2};
  background-color: ${({ theme }) => theme.color.gray200};

  &::before {
    content: "";
    display: block;
    height: ${({ theme }) => theme.spacing.s2};
    position: absolute;
    left: 0;
    top: 0;
    border-radius: ${({ theme }) => theme.spacing.s2} 0 0
      ${({ theme }) => theme.spacing.s2};
    background-color: ${({ theme }) => theme.color.teal600};

    width: ${(props) =>
      props.progressPercentage ? `${props.progressPercentage}%` : 0};
  }
`;

const ProgressBarContainer = styled(Box)`
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoint.md}) {
    display: block;
  }
`;

const DecisionCounter = ({
  decisionsUsed,
  decisionsAvailable,
  tooltipText,
}: DecisionCounterProps) => {
  const theme = useTheme();

  if (
    (!decisionsUsed && typeof decisionsUsed !== "number") ||
    !decisionsAvailable
  )
    return null;

  const decisionsUsedPercentage = getDecisionsUsedPercentage({
    decisionsUsed,
    decisionsAvailable,
  });

  return (
    <Flex alignItems="center">
      <ProgressBarContainer width={rem(160)} noShrink>
        <ProgressBar progressPercentage={decisionsUsedPercentage} />
      </ProgressBarContainer>

      <Flex ml={[0, 0, 4]} alignItems="center">
        <DecisionCount
          isDetailed
          isSmall
          mt={[rem(1), rem(1), 0]}
          amount={decisionsUsed}
        />
        <Text
          ml={rem(1)}
          styleName="body-3"
          styles={{ color: theme.color.gray600 }}
        >
          /
        </Text>
        <Text
          ml={rem(1)}
          styleName="body-3"
          styles={{ color: theme.color.gray600 }}
        >
          {formatNumber(decisionsAvailable)}
        </Text>
      </Flex>

      <Tooltip ml={1}>{tooltipText}</Tooltip>
    </Flex>
  );
};

export default DecisionCounter;
