import React, { useState } from "react";
import { useTheme } from "@emotion/react";
import { flexRender } from "@tanstack/react-table";

import Flex from "../../Flex";
import Filter from "../custom/Filter";
import { StyledSortControl } from "../Table2.styled";
import { Table2HeaderProps } from "../Table2.types";

const Table2Header = ({
  canFilter,
  canSort,
  header,
  table,
}: Table2HeaderProps) => {
  const theme = useTheme();
  const [isFilterActive, setIsFilterActive] = useState(false);

  const handleFilterControlOnClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.nativeEvent.stopImmediatePropagation();
    e.preventDefault();

    setIsFilterActive(!isFilterActive);

    return null;
  };

  return (
    <th>
      {header.isPlaceholder ? null : (
        <Flex
          minHeight={theme.spacing.s7}
          ml={-1}
          alignItems="center"
          justifyContent="space-between"
        >
          {!isFilterActive &&
            (canSort && header.column.getCanSort() ? (
              <StyledSortControl
                onClick={header.column.getToggleSortingHandler()}
                sortOrder={header.column.getIsSorted()}
              >
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
              </StyledSortControl>
            ) : (
              flexRender(header.column.columnDef.header, header.getContext())
            ))}

          {canFilter && header.column.getCanFilter() ? (
            <Filter
              {...{
                handleFilterControlOnClick,
                isFilterActive,
              }}
              htmlId={header.id}
              headerLabel={header.column.columnDef.header}
              column={header.column}
              table={table}
            />
          ) : null}
        </Flex>
      )}
    </th>
  );
};

export default Table2Header;
