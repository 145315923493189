import * as React from "react";

import BaseAvatar, { AvatarSize } from "./BaseAvatar";

const AvatarHere = ({ size }: { size?: AvatarSize }) => {
  return (
    <BaseAvatar {...{ size }}>
      <circle cx="24" cy="24" r="24" fill="#48DAD0" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.0251 16.8661L31.6154 16.4564C28.8839 13.7079 25.9306 14.6639 24.4625 16.132C23.3358 17.2587 22.6359 18.8122 22.9261 19.9048L17.0194 13.9981L13.8612 17.1222L25.5022 28.7632H12.1538L18.8287 35.438L25.5029 28.7639L25.5038 28.7648H31.7519L27.5524 24.5652C25.3502 22.3289 25.3161 21.1339 26.3745 20.0755C27.3987 19.0512 28.6108 19.6828 30.7276 21.7997L34.876 25.948L38 22.824L32.0251 16.8661Z"
        fill="white"
      />
    </BaseAvatar>
  );
};

export default AvatarHere;
