import React, { useEffect, useMemo, useState } from "react";
import { Redirect, useLocation, useParams } from "react-router-dom";
import { Box, Notification } from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";

import Header from "../../../../components/Header";
import { useMetaTitle } from "../../../../components/Layout";
import Loading from "../../../../components/Loading";
import { useIntegrations } from "../../../../contexts/integrations/Integrations.context";
import { getIntegrationAvatar } from "../../helpers/avatars";
import { availableIntegrationConfig } from "../../Integrations.config";

import EditInstalledIntegrationForm from "./EditInstalledIntegration.Form";

// get query params
const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

const EditInstalledIntegration = () => {
  const [, setMetaTitle] = useMetaTitle();
  const { pathname } = useLocation();
  const query = useQuery();
  const { id } = useParams<{ id: string }>();
  const {
    getIntegrationById,
    clearIntegrationsError,
    integrationsLoading,
    integrationsError,
    updateIntegrationConfiguration,
  } = useIntegrations();
  const installedIntegration = React.useMemo(
    () => getIntegrationById(id!),
    [getIntegrationById, id]
  );
  useEffect(() => {
    updateIntegrationConfiguration(installedIntegration);

    setMetaTitle(
      installedIntegration ? `Edit ${installedIntegration.name}` : "Edit"
    );
  }, [installedIntegration, setMetaTitle, updateIntegrationConfiguration]);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    return clearIntegrationsError;
  }, [clearIntegrationsError]);

  // build return URLs
  // if Edit button clicked from main Installed integrations list (?source=list),
  // return to main list, otherwise return to run profile details view
  const returnPathInstalledIntegrations = pathname
    .split("/")
    .slice(0, -2)
    .join("/");
  const returnPath =
    query.get("source") === "list"
      ? returnPathInstalledIntegrations
      : pathname.split("/").slice(0, -1).join("/");

  const integrationAvailable = installedIntegration
    ? availableIntegrationConfig[installedIntegration.type] || undefined
    : undefined;

  if (
    submitted &&
    !integrationsError &&
    installedIntegration &&
    !integrationsLoading
  ) {
    return <Redirect to={returnPath} />;
  }

  if (installedIntegration) {
    const isLoadingConfiguration =
      installedIntegration &&
      availableIntegrationConfig[installedIntegration.type]
        .shouldLoadAdditionalConfig &&
      !installedIntegration?.configuration;

    return (
      <>
        <Header
          configPageTitle={{
            label: installedIntegration
              ? `Edit ${installedIntegration.name}`
              : "Edit",
            isEarlyAccess:
              installedIntegration &&
              availableIntegrationConfig[installedIntegration.type]
                ?.isEarlyAccess,
            icon:
              installedIntegration &&
              getIntegrationAvatar(installedIntegration.type, 32),
          }}
        />
        <Box mt={rem(-1)}>
          {integrationsLoading || isLoadingConfiguration ? (
            <Loading />
          ) : (
            <EditInstalledIntegrationForm
              {...{
                integration: installedIntegration,
                integrationsError,
                returnPath,
                privateInputs: integrationAvailable?.privateInputs,
                publicInputs: integrationAvailable?.publicInputs,
                setSubmitted,
              }}
            />
          )}
        </Box>
      </>
    );
  }

  if (integrationsLoading) {
    return <Loading />;
  }

  if (integrationsError) {
    return (
      <Notification
        mt={2}
        type="error"
        hasContactExtra
        message={integrationsError}
      />
    );
  }

  if (submitted) {
    return <Redirect to={returnPathInstalledIntegrations} />;
  }

  return <Redirect to={returnPath} />;
};

export default EditInstalledIntegration;
