import React from "react";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";

import { useUser } from "../../AuthProvider";
import { checkHasTeamsAccess } from "../../utils/authProviderHelpers";
import {
  userHasAccessToAction,
  userHasAccessToView,
} from "../../utils/rbac_utils";
import { ActionGroups } from "../../utils/rbac_utils/types";
import AccessDenied from "../AccessDenied";

import APIKeys from "./subpages/APIKeys";
import Details from "./subpages/Details";
import EditDetails from "./subpages/EditDetails";
import Members from "./subpages/Members";
import { isCurrentUserRoot } from "./subpages/Members/utils/isCurrentUserRoot";
import NewAPIKey from "./subpages/NewAPIKey";
import NoTeamsAccess from "./subpages/NoTeamsAccess";
import Plan from "./subpages/Plan";
import Usage from "./subpages/Usage";

const TeamRoutes = () => {
  const { path } = useRouteMatch();
  const location = useLocation();
  const [user] = useUser();
  const { id, organizations } = user;
  const team = organizations?.find((team) => team.id === id);
  const isRoot = isCurrentUserRoot(organizations, user.roles);
  const hasTeamsAccess = checkHasTeamsAccess(user);
  const isUserNonRootOnUnpaidAccount = !isRoot && !checkHasTeamsAccess(user);

  const userCanAccess = userHasAccessToView(
    user.roles,
    path,
    location.pathname,
    {}
  );

  const canUserEditTeamDetails = userHasAccessToAction(
    user.roles,
    ActionGroups.OrgAdmin,
    {}
  );

  if (!userCanAccess) {
    return <AccessDenied />;
  }

  return (
    <Switch>
      <Route exact path={path}>
        {isRoot || hasTeamsAccess ? (
          <Details team={team} />
        ) : (
          <NoTeamsAccess isRootUser={false} team={team} />
        )}
      </Route>

      {isUserNonRootOnUnpaidAccount && <Redirect to={path} />}

      <Route exact path={`${path}/members`}>
        <Members />
      </Route>
      <Route exact path={`${path}/api-keys/new`}>
        <NewAPIKey />
      </Route>
      <Route exact path={`${path}/api-keys`}>
        <APIKeys />
      </Route>
      <Route exact path={`${path}/usage`}>
        <Usage />
      </Route>
      <Route exact path={`${path}/plan`}>
        <Plan team={team} />
      </Route>

      {canUserEditTeamDetails && (
        <Route exact path={`${path}/details/edit`}>
          <EditDetails team={team} />
        </Route>
      )}

      <Redirect to={path} />
    </Switch>
  );
};

export default TeamRoutes;
