import * as React from "react";
import styled from "@emotion/styled";

export type AvatarSize = 64 | 48 | 36 | 32 | 24 | 20 | 16;

type BaseAvatarProps = {
  children: React.ReactNode;
  size?: AvatarSize;
  viewBox?: [number, number, number, number];
};

const StyledAvatar = styled.svg`
  flex-shrink: 0;
`;

const BaseAvatar = ({ children, size, viewBox }: BaseAvatarProps) => {
  const thisSize = size || 48;

  return (
    <StyledAvatar
      width={thisSize}
      height={thisSize}
      viewBox={viewBox ? viewBox.join(" ") : "0 0 48 48"}
      xmlns="http://www.w3.org/2000/svg"
    >
      {children}
    </StyledAvatar>
  );
};

export default BaseAvatar;
