import * as React from "react";

import BaseIcon, { IconProps } from "./BaseIcon";

const IconTriangleUp = ({
  iconSize,
  iconColor,
  iconColorActive,
  iconColorFocus,
  iconColorHover,
  ...rest
}: IconProps) => {
  return (
    <BaseIcon
      {...{
        iconSize,
        iconColor,
        iconColorActive,
        iconColorFocus,
        iconColorHover,
      }}
      {...rest}
    >
      <path d="M4.26667 9.6H11.7333L8 4.8L4.26667 9.6Z" />
    </BaseIcon>
  );
};

export default IconTriangleUp;
