import { Box } from "@console/dsc";

import AddConfigOptions from "../../../../../components/AddConfigOptions";
import ControlPanel from "../../../../../components/ControlPanel";
import { appTooltips } from "../../../data/appTooltips";

import { InputControlsProps } from "./InputControls";

export type RunOptionsProps = Pick<
  InputControlsProps,
  | "addEmptyConfigOption"
  | "handleConfigOptionChange"
  | "pendingConfigOptions"
  | "removeConfigOption"
> & {
  isClone: boolean;
  isRunning: boolean;
  refreshEditorHeight: () => void;
};

const RunOptions = ({
  addEmptyConfigOption,
  handleConfigOptionChange,
  isClone,
  isRunning,
  pendingConfigOptions,
  refreshEditorHeight,
  removeConfigOption,
}: RunOptionsProps) => {
  return (
    <ControlPanel
      testId="create-new-run-configuration"
      headerTitle="Configuration"
      headerExtra="(optional)"
      headerTooltipContent={appTooltips.newRunOptions.content}
      headerTooltipExtraLinkLabel={appTooltips.newRunOptions.extraLinkLabel}
      headerTooltipExtraLinkUrl={appTooltips.newRunOptions.extraLinkUrl}
      isOpen={isClone}
      onClick={refreshEditorHeight}
    >
      <Box mr={-2}>
        <AddConfigOptions
          {...{
            addEmptyConfigOption,
            handleConfigOptionChange,
            isRunning,
            pendingConfigOptions,
            removeConfigOption,
          }}
        />
      </Box>
    </ControlPanel>
  );
};

export default RunOptions;
