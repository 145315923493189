import { DateTime } from "luxon";

import { GetBannerMessageParams } from "../Banner.types";

const getFreeTrialLabel = (daysRemaining: number) => {
  if (daysRemaining === 1) return "1 day remaining";
  if (daysRemaining > 14) return "14 days remaining";
  return `${daysRemaining} days remaining`;
};

const freeTrialPeriod = 14;

export const getFreeTrialDaysRemaining = (
  trialStartDate: string | undefined
) => {
  if (!trialStartDate) {
    return 0;
  }
  const startDate = DateTime.fromISO(trialStartDate);
  const timeLeft = startDate.diffNow("days").days + freeTrialPeriod;

  if (timeLeft > 0) {
    return Math.floor(timeLeft) + 1;
  }
  return 0;
};

export const getBannerMessage = ({
  type,
  user,
}: GetBannerMessageParams): string | undefined => {
  switch (type) {
    case "free-trial":
      return `Free trial: 
          ${getFreeTrialLabel(
            getFreeTrialDaysRemaining(user.planInfo?.trialStartDate)
          )}`;
    case "completed":
      return "Your free trial has ended. Please contact support@nextmv.io.";
    default:
      return;
  }
};
