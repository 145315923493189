import styled from "@emotion/styled";

type StyledFileDropzoneProps = {
  isDropzoneActive: boolean;
};
export const StyledFileDropzone = styled("div")<StyledFileDropzoneProps>`
  padding-top: ${({ theme }) => theme.spacing.s9};
  padding-right: ${({ theme }) => theme.spacing.s4};
  padding-bottom: ${({ theme }) => theme.spacing.s9};
  padding-left: ${({ theme }) => theme.spacing.s4};
  border-width: ${({ theme }) => theme.border.width};
  border-style: dashed;
  border-color: ${(props) =>
    props.isDropzoneActive
      ? props.theme.color.sky400
      : props.theme.color.gray400};
  border-radius: ${({ theme }) => theme.border.radius};
  background-color: ${(props) =>
    props.isDropzoneActive
      ? props.theme.color.sky100
      : props.theme.color.gray100};
`;
