import {
  IntegrationKind,
  RunProfile,
} from "../../../api/core/controlPlane.types";
import { availableIntegrationConfig } from "../../Integrations/Integrations.config";
import { AvailableIntegration } from "../../Integrations/Integrations.types";

export const emptyRp: RunProfile = {
  name: "",
  version: "",
  id: "",
  integrations: [],
};

export const getIntegrationConfigByType = (
  type: IntegrationKind
): AvailableIntegration | undefined =>
  availableIntegrationConfig[type] || undefined;
