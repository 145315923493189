import React from "react";
import { AvatarAppInstance, Bin, Box, Flex, Text } from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";

type AppDefaultInstanceProps = {
  instanceId: string;
};

const AppDefaultInstance = ({ instanceId }: AppDefaultInstanceProps) => {
  const theme = useTheme();

  return (
    <Bin
      isMini
      mr={[0, 3, 3, 0]}
      mb={3}
      title={
        <Flex ml={-1} alignItems="center">
          <AvatarAppInstance size={20} />
          <Text as="h4" mb={rem(1)} ml={rem(2)} styleName="meta-2-bold">
            Default Instance
          </Text>
        </Flex>
      }
      width="100%"
      maxWidth={rem(296)}
    >
      <Box mt={1}>
        <Text
          as="strong"
          styleName="body-3-bold"
          styles={{ color: theme.color.gray800 }}
        >
          {/* TODO: link with URL and get instance name */}
          {instanceId}
        </Text>
      </Box>
    </Bin>
  );
};

export default AppDefaultInstance;
