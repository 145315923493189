import styled from "@emotion/styled";
import { space } from "styled-system";

import { filterProps } from "../../lib/filterProps";
import { styleLinks } from "../../lib/linkStyles";
import { rem } from "../../lib/tools";

import {
  GetLabelSizeStyles,
  GetLabelStateStyles,
  GetLabelTextColorParams,
  StyledLabelProps,
} from "./InputCheckbox.types";

const getLabelTextColor = ({
  selected,
  isDisabled,
  isGray,
  theme,
}: GetLabelTextColorParams) => {
  if (isDisabled) return theme.color.gray500;
  if (selected) return theme.color.gray800;
  if (isGray) return theme.color.gray600;
  return theme.color.gray700;
};

const getLabelSizeStyles = ({
  isSmall,
  isMedium,
  theme,
}: GetLabelSizeStyles) => {
  if (isSmall) {
    return `
      padding-left: ${theme.spacing.s4};
      font-size: ${theme.ui2Typography.fontSizeBody3};
      line-height: ${theme.ui2Typography.lineHeightBody3};
      &::before {
        width: ${theme.spacing.s3};
        height: ${theme.spacing.s3};
        top: ${theme.spacing.s1};
        background-size: ${theme.spacing.s3};
      }
    `;
  }
  if (isMedium) {
    return `
      padding-left: ${theme.spacing.s5};
      font-size: ${theme.ui2Typography.fontSizeBody2};
      line-height: ${theme.ui2Typography.lineHeightBody2};
      &::before {
        width: ${theme.spacing.s4};
        height: ${theme.spacing.s4};
        top: ${rem(3)};
        background-size: ${theme.spacing.s4};
      }
    `;
  }
  return `
    padding-left: ${theme.spacing.s6};
    font-size: ${theme.ui2Typography.fontSizeBody1};
    line-height: ${theme.ui2Typography.lineHeightBody1};
    &::before {
      width: ${theme.spacing.s4};
      height: ${theme.spacing.s4};
      top: ${theme.spacing.s1};
      background-size: ${theme.spacing.s4};
    }
  `;
};

const getLabelStateStyles = ({
  isDisabled,
  selected,
  theme,
}: GetLabelStateStyles) => {
  if (isDisabled) {
    return `
      cursor: not-allowed;
      &::before {
        border-color: ${theme.color.gray300};
        background-color: ${theme.color.gray100};
      }
    `;
  }

  if (selected) {
    return `
      font-weight: ${theme.ui2Typography.fontWeightBody2Bold};
      &::before {
        border-color: ${isDisabled ? theme.color.gray400 : theme.color.gray700};
        background-color: ${
          isDisabled ? theme.color.gray400 : theme.color.gray700
        };
      }
    `;
  }

  return `
    &::before {
      border-color: ${theme.color.gray300};
      background-color: ${theme.color.gray100};
    }
  `;
};

export const StyledLabel = styled("label", {
  shouldForwardProp: (prop: any) => filterProps(prop),
})<StyledLabelProps>`
  ${space}

  display: block;
  min-height: ${(props) =>
    props.isSmall ? props.theme.spacing.s5 : props.theme.spacing.s6};
  position: relative;
  font-family: ${({ theme }) => theme.ui2Typography.fontFamilyBody2};
  font-weight: ${({ theme }) => theme.ui2Typography.fontWeightBody2};
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;

  ${(props) =>
    getLabelSizeStyles({
      isSmall: props.isSmall,
      isMedium: props.isMedium,
      theme: props.theme,
    })}

  ${(props) =>
    getLabelStateStyles({
      selected: props.selected,
      isDisabled: props.isDisabled,
      theme: props.theme,
    })}

  color: ${(props) =>
    getLabelTextColor({
      selected: props.selected,
      isDisabled: props.isDisabled,
      isGray: props.isGray,
      theme: props.theme,
    })};

  ${({ theme }) => styleLinks(theme)}

  &::before {
    content: "";
    box-sizing: border-box;
    display: block;
    position: absolute;
    left: 0;
    border-width: ${({ theme }) => theme.border.width};
    border-style: ${({ theme }) => theme.border.style};
    border-radius: ${rem(2)};
    background-image: url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.7419 4.01017C12.0308 4.19907 12.1119 4.5864 11.923 4.87531L7.67302 11.3753C7.57286 11.5285 7.41059 11.6301 7.22905 11.6532C7.0475 11.6764 6.86492 11.6189 6.72949 11.4957L3.97949 8.99574C3.72408 8.76355 3.70526 8.36827 3.93745 8.11285C4.16964 7.85744 4.56492 7.83862 4.82033 8.07081L7.02795 10.0777L10.8768 4.19124C11.0657 3.90234 11.453 3.82127 11.7419 4.01017Z' fill='white'/%3e %3c/svg%3e");
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1;
  }
`;
