import * as React from "react";

import BaseIcon, { IconProps } from "./BaseIcon";

const IconCheckEncircledGraphic = ({
  iconSize,
  iconColor,
  iconColorActive,
  iconColorFocus,
  iconColorHover,
}: IconProps) => {
  return (
    <BaseIcon
      {...{
        iconColor,
        iconColorActive,
        iconColorFocus,
        iconColorHover,
      }}
      iconSize={iconSize || 48}
      viewBox={[0, 0, 48, 48]}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.6942 6.90793L44.6019 5.71369L42.2134 3.89844L41.3058 5.09268L23.4094 28.6406L16.6211 21.0038L15.6246 19.8826L13.3823 21.8757L14.3789 22.9969L22.3789 31.9969L23.5906 33.3601L24.6942 31.9079L37.9686 14.4416C39.8333 17.1613 40.9242 20.453 40.9242 23.9995C40.9242 33.3458 33.3474 40.9226 24.0012 40.9226C14.6549 40.9226 7.07812 33.3458 7.07812 23.9995C7.07812 14.6532 14.6549 7.07648 24.0012 7.07648C27.501 7.07648 30.7527 8.13891 33.4514 9.95885L35.1178 7.76618C31.9551 5.59621 28.1264 4.32648 24.0012 4.32648C13.1361 4.32648 4.32812 13.1344 4.32812 23.9995C4.32812 34.8646 13.1361 43.6726 24.0012 43.6726C34.8662 43.6726 43.6742 34.8646 43.6742 23.9995C43.6742 19.5513 42.1979 15.4478 39.7084 12.1524L43.6942 6.90793Z"
      />
    </BaseIcon>
  );
};

export default IconCheckEncircledGraphic;
