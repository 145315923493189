import { isEmpty } from "lodash";

import {
  ShadowTest,
  SwitchbackTestResponse,
} from "../../../api/core/controlPlane.types";
import { formatDate } from "../../../utils/format";
import { GetTestStartCriteriaReturn } from "../types/Experiment.types";

export const getExperimentStartCriteria = (
  test: ShadowTest | SwitchbackTestResponse,
  kind: "shadow" | "switchback"
): GetTestStartCriteriaReturn => {
  let formattedStartCriteriaValue = undefined;
  let startCriteriaValue = undefined;
  switch (kind) {
    case "shadow":
      const shadowTest = test as ShadowTest;
      if (isEmpty(shadowTest.start_events)) return undefined;

      startCriteriaValue = shadowTest.start_events.time;
      formattedStartCriteriaValue =
        startCriteriaValue && formatDate(startCriteriaValue, true);
      break;
    case "switchback":
      const switchbackTest = test as SwitchbackTestResponse;
      startCriteriaValue = switchbackTest.plan.start;
      if (isEmpty(startCriteriaValue)) return undefined;

      formattedStartCriteriaValue = formatDate(startCriteriaValue!, true);
      break;
    default:
      return undefined;
  }

  return {
    id: "date",
    shortType: "date",
    type: "Start date",
    value: startCriteriaValue!,
    formattedValue: formattedStartCriteriaValue,
  };
};
