import { GetColorParams, GetSizeParams } from "../Notification.types";

export const getBackgroundColor = ({ type, theme }: GetColorParams) => {
  switch (type) {
    case "error":
      return theme.color.red100;
    case "notice":
      return theme.color.yellow100;
    case "success":
      return theme.color.green100;
    case "tip":
      return theme.color.sky100;
    default:
      return theme.color.gray100;
  }
};

export const getBorderColor = ({ type, theme }: GetColorParams) => {
  switch (type) {
    case "error":
      return theme.color.red200;
    case "notice":
      return theme.color.yellow300;
    case "success":
      return theme.color.green200;
    case "tip":
      return theme.color.sky200;
    default:
      return theme.color.gray100;
  }
};

export const getIconColor = ({ type, theme }: GetColorParams) => {
  switch (type) {
    case "error":
      return theme.color.red500;
    case "notice":
      return theme.color.yellow600;
    case "success":
      return theme.color.green500;
    case "tip":
      return theme.color.sky700;
    default:
      return theme.color.gray600;
  }
};

export const getBorderWidth = ({ size, theme }: GetSizeParams) => {
  if (size === "small") return theme.border.width;
  return theme.border.widthMediumShadowOnly;
};
