import { DATA_POLLING_DEFAULT } from "../config/general";
import useRunDetails from "../contexts/apps/hooks/useRunDetails";
import { isLogsLiveResolved } from "../pages/App/subpages/RunDetails/utils/runDataChecks";

import useInterval from "./useInterval";

const useLogsPolling = (appId: string, runId: string) => {
  const {
    loadRunLogs,
    loadRunLogsLive,
    runLogs,
    runLogsError,
    runLogsLive,
    runLogsLiveError,
  } = useRunDetails();

  const shouldPollEntity = (): boolean => {
    if (
      !isLogsLiveResolved(runLogsLive) &&
      runLogsLive?.next_available_in_seconds
    ) {
      return true;
    }
    return false;
  };

  // final callback function to run at end of polling
  const finalEntityDataLoad = () => {
    loadRunLogs(appId, runId);
  };

  const pollInterval = runLogsLive?.next_available_in_seconds
    ? runLogsLive?.next_available_in_seconds * 1000
    : DATA_POLLING_DEFAULT;

  useInterval(
    async () => {
      let timeSince = runLogsLive?.next_page_token;
      loadRunLogsLive(appId, runId, timeSince);
    },
    shouldPollEntity() ? pollInterval : null,
    finalEntityDataLoad
  );

  return {
    loadRunLogs,
    loadRunLogsLive,
    runLogs,
    runLogsError,
    runLogsLive,
    runLogsLiveError,
  };
};

export default useLogsPolling;
