import React from "react";
import { useTheme } from "@emotion/react";

import { IconSearch } from "../../../icons";
import Flex from "../../Flex";
import { StyledSearchControl } from "../Table2.styled";
import { Table2FilterProps } from "../Table2.types";

import DebouncedInput from "./DebouncedInput";

const Filter = ({
  column,
  handleFilterControlOnClick,
  headerLabel,
  htmlId,
  isFilterActive,
  table,
}: Table2FilterProps) => {
  const theme = useTheme();

  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();
  const isValueNumber: boolean = typeof firstValue === "number";
  const filterInputId = `table-filter-${htmlId}`;

  const renderFilterType = () => {
    return isValueNumber ? (
      <>
        <DebouncedInput
          type="number"
          htmlId={filterInputId}
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
          value={(columnFilterValue as [number, number])?.[0] ?? ""}
          onChange={(value) =>
            column.setFilterValue((old: [number, number]) => [value, old?.[1]])
          }
          placeholder="Min"
        />
        <DebouncedInput
          type="number"
          htmlId={`${filterInputId}-2`}
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
          value={(columnFilterValue as [number, number])?.[1] ?? ""}
          onChange={(value) =>
            column.setFilterValue((old: [number, number]) => [old?.[0], value])
          }
          placeholder="Max"
        />
      </>
    ) : (
      <DebouncedInput
        htmlId={filterInputId}
        type="text"
        value={(columnFilterValue ?? "") as string}
        onChange={(value) => column.setFilterValue(value)}
        placeholder={`Filter ${headerLabel}`}
      />
    );
  };

  return (
    <Flex width={isFilterActive ? "100%" : "auto"} alignItems="center">
      {isFilterActive && (
        <Flex width="100%" flexDirection="column">
          {renderFilterType()}
        </Flex>
      )}

      <StyledSearchControl
        isActive={isFilterActive}
        onClick={(e) => handleFilterControlOnClick(e)}
        href={`#${filterInputId}`}
      >
        <IconSearch
          iconColor={theme.color.gray400}
          iconColorHover={theme.color.gray600}
          iconColorFocus={theme.color.gray700}
          iconColorActive={theme.color.gray700}
        />
      </StyledSearchControl>
    </Flex>
  );
};

export default Filter;
