import { MarketplaceAppConstraint } from "../../../api/core/controlPlane.types";

export const ORTOOLS_DEMAND_FORECASTING_PARTNER_ID = "nextmv";
export const ORTOOLS_DEMAND_FORECASTING_APP_ID = "dem.forecast.ortools";
export const ORTOOLS_DEMAND_FORECASTING_SUBSCRIPTION_ID = `${ORTOOLS_DEMAND_FORECASTING_PARTNER_ID}-${ORTOOLS_DEMAND_FORECASTING_APP_ID}`;
export const ORTOOLS_DEMAND_FORECASTING_ICON_URL =
  "/images/logos/or-tools-icon.svg";
export const ORTOOLS_DEMAND_FORECASTING_NAME = "OR-Tools Demand Forecasting";
export const ORTOOLS_DEMAND_FORECASTING_TEMPLATE_NAME =
  "demand-forecasting-ortools";
export const ORTOOLS_DEMAND_FORECASTING_SHORT_DESC =
  "Solve a demand forecasting model with OR-Tools.";
export const ORTOOLS_DEMAND_FORECASTING_SUMMARY =
  "Solve a demand forecasting model with the low-code OR-Tools Demand Forecasting app. Provide historical demand, and then run the app to forecast future demand using Least Absolute Deviation (LAD) regression.";
export const ORTOOLS_DEMAND_FORECASTING_DOCS_LINK =
  "https://www.nextmv.io/docs/community-apps/demand-forecasting";
export const ORTOOLS_DEMAND_FORECASTING_CODE_REPO_LINK =
  "https://github.com/nextmv-io/community-apps/tree/develop/demand-forecasting-ortools";
export const ORTOOLS_DEMAND_FORECASTING_MODEL_TYPE = ["Forecasting"];
export const ORTOOLS_DEMAND_FORECASTING_LANGUAGE = ["Python"];
export const ORTOOLS_DEMAND_FORECASTING_PACKAGES = ["OR-Tools"];
export const ORTOOLS_DEMAND_FORECASTING_SOLVER = ["SCIP"];

export const ORTOOLS_DEMAND_FORECASTING_CONSTRAINTS: MarketplaceAppConstraint[] =
  [
    {
      id: "timezone",
      name: "timezone",
      property: "timezone",
      docsUrl:
        "https://www.nextmv.io/docs/community-apps/demand-forecasting#input",
      summary: "Timezone of the demand times.",
      type: "string",
    },
    {
      id: "demands",
      name: "demands",
      property: "demands",
      docsUrl:
        "https://www.nextmv.io/docs/community-apps/demand-forecasting#input",
      summary: "Historical demand over a period of time.",
      type: "array",
    },
  ];
