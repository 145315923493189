import React from "react";
import { useHistory } from "react-router-dom";
import { Flex, IconCheck, Tag, Text } from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";

import { StyledTeamOption } from "../OrgSwitcher.styled";
import { TeamOptionProps } from "../OrgSwitcher.types";
import { switchToOrg } from "../utils/switchToOrg";

const TeamOption = ({
  isCurrentTeam,
  isYou,
  setIsOrgSwitcherActive,
  team,
}: TeamOptionProps) => {
  const theme = useTheme();
  const history = useHistory();

  return (
    <StyledTeamOption
      {...{ isCurrentTeam }}
      onClick={(e) =>
        switchToOrg({
          e,
          history,
          isCurrentTeam,
          teamId: team.id,
          setIsOrgSwitcherActive,
        })
      }
    >
      <Flex
        alignItems="center"
        mt={isYou ? rem(-5) : 0}
        mb={isYou ? rem(-5) : 0}
      >
        <Text
          as="h4"
          styleName={isCurrentTeam ? "body-2-bold" : "body-2"}
          styles={{
            color: isCurrentTeam ? theme.color.sky800 : theme.color.gray700,
          }}
        >
          {team.name}
        </Text>
        {isYou && (
          <Tag
            ml={2}
            label="you"
            textColor={isCurrentTeam ? theme.color.sky800 : theme.color.gray700}
            backgroundColor={
              isCurrentTeam ? theme.color.sky300 : theme.color.gray200
            }
          />
        )}
      </Flex>

      {isCurrentTeam && (
        <Flex ml="auto">
          <IconCheck iconColor={theme.color.sky800} />
        </Flex>
      )}
    </StyledTeamOption>
  );
};

export default TeamOption;
