import { User } from "../../../api/core/dataPlane.types";
import { Subscription } from "../../../api/payment/controlPlane.types";
import {
  PLAN_TYPE_LEGACY_BASIC,
  PLAN_TYPE_LEGACY_ENTERPRISE,
  PLAN_TYPE_LEGACY_PREMIUM,
} from "../../../config/plans";

export const hasPlatformAccess = (user: User) => {
  return (
    user?.planInfo?.planType &&
    user.planInfo.planType === PLAN_TYPE_LEGACY_ENTERPRISE
  );
};

export const isLegacyCustomer = (sub: Subscription) => {
  return (
    sub.planType &&
    sub.planType === (PLAN_TYPE_LEGACY_BASIC || PLAN_TYPE_LEGACY_PREMIUM)
  );
};
