import React, { createContext, useContext, useMemo } from "react";

const LayoutContext = createContext<
  [string, React.Dispatch<React.SetStateAction<string>>]
>(["", () => ""]);

export const useMetaTitle = () => {
  const context = useContext(LayoutContext);

  if (!context) {
    throw new Error(`useMetaTitle must be used within a LayoutContext`);
  }
  return context;
};

const LayoutProvider = (props: any) => {
  const [metaTitle, setMetaTitle] = React.useState("");
  const value = useMemo(() => [metaTitle, setMetaTitle], [metaTitle]);

  return <LayoutContext.Provider value={value} {...props} />;
};

export const withLayoutProvider = (Component: any) => (props: any) => {
  return (
    <LayoutProvider>
      <Component {...props} />
    </LayoutProvider>
  );
};
