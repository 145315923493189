import { useEffect, useRef } from "react";
import { Polyline, PolylineProps, useMap } from "react-leaflet";
import L from "leaflet";

import { getDefaultPatterns } from "./utils/polylineWithArrows";

export default function PolylineWithArrows(props: PolylineProps) {
  const map = useMap();
  const polyRef = useRef() as React.MutableRefObject<L.Polyline>;
  const decoratorRef = useRef<L.PolylineDecorator>();

  useEffect(() => {
    const polyline = polyRef.current;
    if (!polyline) return;

    decoratorRef.current = L.polylineDecorator(polyline, {
      patterns: getDefaultPatterns(props?.pathOptions?.color),
    }).addTo(map);

    return () => {
      decoratorRef?.current?.remove();
    };
  }, [map, props?.pathOptions?.color, props.positions]);
  return <Polyline ref={polyRef} {...props} />;
}
