import { MarketplaceAppConstraint } from "../../../api/core/controlPlane.types";

export const ORTOOLS_SHIFT_PLANNING_PARTNER_ID = "nextmv";
export const ORTOOLS_SHIFT_PLANNING_APP_ID = "shift.plan.ortools";
export const ORTOOLS_SHIFT_PLANNING_SUBSCRIPTION_ID = `${ORTOOLS_SHIFT_PLANNING_PARTNER_ID}-${ORTOOLS_SHIFT_PLANNING_APP_ID}`;
export const ORTOOLS_SHIFT_PLANNING_ICON_URL =
  "/images/logos/or-tools-icon.svg";
export const ORTOOLS_SHIFT_PLANNING_NAME = "OR-Tools Shift Planning";
export const ORTOOLS_SHIFT_PLANNING_TEMPLATE_NAME = "shift-planning-ortools";
export const ORTOOLS_SHIFT_PLANNING_SHORT_DESC =
  "Solve a MIP-based shift planning model with OR-Tools.";
export const ORTOOLS_SHIFT_PLANNING_SUMMARY =
  "Solve a shift planning model with the low-code OR-Tools Shift Planning app. Define shift templates, worker demand, and penalties for oversupply and undersupply, and then run the app to create shifts which satisfy the worker demand.";
export const ORTOOLS_SHIFT_PLANNING_DOCS_LINK =
  "https://www.nextmv.io/docs/community-apps/shift-planning";
export const ORTOOLS_SHIFT_PLANNING_CODE_REPO_LINK =
  "https://github.com/nextmv-io/community-apps/tree/develop/shift-planning-ortools";
export const ORTOOLS_SHIFT_PLANNING_MODEL_TYPE = ["Scheduling"];
export const ORTOOLS_SHIFT_PLANNING_LANGUAGE = ["Python"];
export const ORTOOLS_SHIFT_PLANNING_PACKAGES = ["OR-Tools"];
export const ORTOOLS_SHIFT_PLANNING_SOLVER = ["SCIP"];

export const ORTOOLS_SHIFT_PLANNING_CONSTRAINTS: MarketplaceAppConstraint[] = [
  {
    id: "shifts",
    name: "shifts",
    property: "shifts",
    docsUrl: "https://www.nextmv.io/docs/community-apps/shift-planning#input",
    summary: "Possible shift types to select from.",
    type: "array",
  },
  {
    id: "demands",
    name: "demands",
    property: "demands",
    docsUrl: "https://www.nextmv.io/docs/community-apps/shift-planning#input",
    summary: "Forecasted worker needs over a period of time.",
    type: "array",
  },
  {
    id: "qualification",
    name: "qualification",
    property: "qualification",
    docsUrl: "https://www.nextmv.io/docs/community-apps/shift-planning#shift",
    summary: "Which workers are compatible with which shifts.",
    type: "string",
  },
  {
    id: "min_workers",
    name: "min_workers",
    property: "min_workers",
    docsUrl: "https://www.nextmv.io/docs/community-apps/shift-planning#shift",
    summary: "Minimum number of workers for the shift.",
    type: "integer",
  },
  {
    id: "max_workers",
    name: "max_workers",
    property: "max_workers",
    docsUrl: "https://www.nextmv.io/docs/community-apps/shift-planning#shift",
    summary: "Maximum number of workers for the shift.",
    type: "integer",
  },
  {
    id: "cost",
    name: "cost",
    property: "cost",
    docsUrl: "https://www.nextmv.io/docs/community-apps/shift-planning#shift",
    summary: "The cost for the shift.",
    type: "integer",
  },
  {
    id: "times",
    name: "times",
    property: "times",
    docsUrl: "https://www.nextmv.io/docs/community-apps/shift-planning#shift",
    summary: "The time windows covered by this shift.",
    type: "array",
  },
  {
    id: "start_time",
    name: "start_time",
    property: "start_time",
    docsUrl: "https://www.nextmv.io/docs/community-apps/shift-planning#demand",
    summary: "The start time of the predicted demand.",
    type: "timestamp",
  },
  {
    id: "end_time",
    name: "end_time",
    property: "end_time",
    docsUrl: "https://www.nextmv.io/docs/community-apps/shift-planning#demand",
    summary: "The end time of the predicted demand.",
    type: "timestamp",
  },
  {
    id: "count",
    name: "end_time",
    property: "end_time",
    docsUrl: "https://www.nextmv.io/docs/community-apps/shift-planning#demand",
    summary: "The end time of the predicted demand.",
    type: "timestamp",
  },
  {
    id: "over_supply_cost",
    name: "over_supply_cost",
    property: "over_supply_cost",
    docsUrl: "https://www.nextmv.io/docs/community-apps/shift-planning#demand",
    summary: "The cost for oversupplying demand.",
    type: "integer",
  },
  {
    id: "under_supply_cost",
    name: "under_supply_cost",
    property: "under_supply_cost",
    docsUrl: "https://www.nextmv.io/docs/community-apps/shift-planning#demand",
    summary: "The cost for undersupplying demand.",
    type: "integer",
  },
];
