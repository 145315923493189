export type PolicyRole = "Admin" | "Viewer" | "Operator" | "Developer";

export type PolicyType = "permit" | "forbid";

export type PolicyActionDefinition = {
  type: PolicyType;
  roles: PolicyRole[];
  conditions?: PolicyConditions;
};

export type PolicyConditions = {
  [key in PolicyRole]?: PolicyCondition;
};

export type PolicyCondition = {
  type: "when" | "unless";
  condition: string;
};

export type PolicyRoleMapping = Record<ActionGroups, PolicyActionDefinition>;

export type RouteToActionGroup = Record<string, ActionGroups>;

export type BaseRouteToActionGroups = { [key: string]: RouteToActionGroup };

export enum Roles {
  Operator = "Operator",
  Developer = "Developer",
  Admin = "Admin",
  Viewer = "Viewer",
}

export enum ActionGroups {
  OrgAdmin = "OrgAdmin",
  OrgView = "OrgView",
  SecurityOperator = "SecurityOperator",
  RunOperator = "RunOperator",
  RunViewer = "RunViewer",
  AppOperator = "AppOperator",
  AppViewer = "AppViewer",
  AppVersionOperator = "AppVersionOperator",
  AppInstanceOperator = "AppInstanceOperator",
  AppProductOperator = "AppProductOperator",
  ExperimentOperator = "ExperimentOperator",
  ExperimentViewer = "ExperimentViewer",
  InputSetOperator = "InputSetOperator",
  InputSetViewer = "InputSetViewer",
  MarketplaceViewer = "MarketplaceViewer",
  MarketplaceOperator = "MarketplaceOperator",
  WebhooksOperator = "WebhooksOperator",
  WebhooksViewer = "WebhooksViewer",
}
