import styled from "@emotion/styled";

export const StyledFileList = styled.ul``;

export const StyledFileItem = styled.li`
  display: flex;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing.s2};
  padding-top: ${({ theme }) => theme.spacing.s2};
  padding-right: ${({ theme }) => theme.spacing.s2};
  padding-bottom: ${({ theme }) => theme.spacing.s3};
  padding-left: ${({ theme }) => theme.spacing.s1};
  border-width: ${({ theme }) => theme.border.width};
  border-style: ${({ theme }) => theme.border.style};
  border-color: ${({ theme }) => theme.color.gray200};
  border-radius: ${({ theme }) => theme.border.radius};
  background-color: ${({ theme }) => theme.color.gray100};
`;
