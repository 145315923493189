import React, { useState } from "react";
import { Link } from "react-router-dom";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";

import { IconCheck, IconCopy, IconLock } from "../../icons";
import Button2 from "../Button2";
import Flex from "../Flex";
import Text from "../Text";

import {
  getIconColor,
  getTagBackgroundColor,
  getTagLabel,
  getTagStyleName,
  getTagTextColor,
} from "./utils/presets";
import { StyledTag } from "./Tag.styles";
import { TagProps } from "./Tag.types";

const Tag = ({
  backgroundColor,
  contentToCopy,
  label,
  textColor,
  hasIconLock,
  size,
  styles,
  testId,
  type,
  url,
  // styled-system props
  m,
  mt,
  mr,
  mb,
  ml,
  ...rest
}: TagProps) => {
  const theme = useTheme();
  const tagLabel = label || getTagLabel({ type });
  const tagSize = size || "default";
  const tagStyleName = getTagStyleName({ size: tagSize, type });
  const tagTextColor = textColor || getTagTextColor({ theme, type });
  const tagBackgroundColor =
    backgroundColor || getTagBackgroundColor({ theme, type });

  const [copyMessage, setCopyMessage] = useState("");

  const copyContent = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    navigator.clipboard.writeText(contentToCopy || "");

    // signal to user that text was copied
    setCopyMessage("Copied!");

    // remove message after 3 seconds
    const timer = setTimeout(() => {
      setCopyMessage("");
    }, 3000);

    return () => clearTimeout(timer);
  };

  return (
    <Flex alignItems="center">
      <StyledTag
        {...{ m, mt, mr, mb, ml, styles }}
        pt={tagSize === "small" ? rem(1) : rem(2)}
        pr={tagSize === "small" ? 1 : rem(6)}
        pb={tagSize === "small" ? rem(2) : rem(3)}
        pl={hasIconLock ? 1 : tagSize === "small" ? 1 : rem(6)}
        alignItems="center"
        background={tagBackgroundColor}
        size={tagSize}
        {...rest}
      >
        {hasIconLock && (
          <IconLock iconSize={16} iconColor={getIconColor({ type, theme })} />
        )}

        {typeof tagLabel === "string" ? (
          <Text
            as="span"
            ml={hasIconLock ? rem(2) : 0}
            styleName={tagStyleName}
            styles={{
              letterSpacing: "0.01em",
              color: tagTextColor,
            }}
            hasLinkBlack={!!url}
            {...(testId && {
              "data-testid": testId,
            })}
          >
            {url ? <Link to={url}>{tagLabel}</Link> : tagLabel}
          </Text>
        ) : (
          tagLabel
        )}
      </StyledTag>

      {contentToCopy && (
        <Button2
          data-testid="tag-control-copy-content"
          ml={1}
          icon={
            copyMessage ? (
              <IconCheck iconSize={18} iconColor={theme.color.gray500} />
            ) : (
              <IconCopy iconSize={15} iconColor={theme.color.gray500} />
            )
          }
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
            copyContent(e)
          }
          type="text-quiet"
          htmlTitle="Copy to clipboard"
          aria-label="Copy to clipboard"
          size="small"
          styles={{
            height: rem(24),
            minWidth: rem(24),
            paddingRight: 0,
            paddingLeft: 0,
          }}
          {...(copyMessage && {
            label: copyMessage,
          })}
        />
      )}
    </Flex>
  );
};

export default Tag;
