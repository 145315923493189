import { useCallback, useState } from "react";

import { getMapMarkerPoints } from "../../../../../components/Map/utils/getMapMarkerPoints";
import { useDebounceCallback } from "../../../../../hooks/useDebounce";

const INPUT_DEBOUNCE_INTERVAL = 300;

export type UseCustomInputReturnValue = ReturnType<typeof useCustomInput>;

export default function useCustomInput() {
  const [input, setInput] = useState<any>(null);
  const [valid, setValid] = useState(true);
  const [modified, setModified] = useState(false);

  const activeTab = "input";

  const handleValidationError = useCallback((e: any[]) => {
    setValid(!e?.length);
  }, []);

  const onInputChange = useCallback(
    (input: any) => {
      setInput(input);
      setModified(true);
    },
    [setInput]
  );

  const [onInputChangeDebounced] = useDebounceCallback(
    onInputChange,
    INPUT_DEBOUNCE_INTERVAL
  );

  return {
    inputState: {
      input,
      valid,
      modified,
      markerCoords: getMapMarkerPoints(input),
    },
    activeTab,
    handleValidationError,
    onInputChange: onInputChangeDebounced,
    setModified,
  };
}
