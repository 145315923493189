import L from "leaflet";

import { sanitizeStr } from "../../../../../../../utils/sanitize";
import { MarkerPoint, VisibleVehicleRouteIds } from "../../../../../Map.types";

// Return value is an object where key is vehicleId and value
// is a MarkerPoint[] - this is necessary to properly render
// the "stop number" on the map (since this is not stored on the point object)
// Function also excludes annotations that are currently "hidden"
// based on the position of the vehicle "display" toggle
export const sortPointsByVehicleId = (
  points: MarkerPoint[],
  visibleVehicleRoutesById: VisibleVehicleRouteIds
): { [key: string]: MarkerPoint[] } => {
  return points.reduce((pointsByVehicleId, point) => {
    if (!point.vehicleId || !visibleVehicleRoutesById[point.vehicleId])
      return pointsByVehicleId;

    return {
      ...pointsByVehicleId,
      [point.vehicleId]: [
        ...(pointsByVehicleId[
          point.vehicleId as keyof typeof pointsByVehicleId
        ] || []),
        point,
      ],
    };
  }, {});
};

// maps through arrays generated by sortPointsByVehicleId function
// and runs a callback for each point - in StopAnnotations, the callback
// is to render a Marker JSX element to display on the map
export const mapPointsByVehicleId = <T>(
  points: MarkerPoint[],
  visibleVehicleRoutesById: VisibleVehicleRouteIds,
  callback: (
    point: MarkerPoint,
    stopNumber: number,
    vehicleIndex: number,
    totalVehicleStops: number
  ) => T
): T[] => {
  return Object.values(
    sortPointsByVehicleId(points, visibleVehicleRoutesById)
  ).reduce((callbackValues, pointArray, vehicleIndex) => {
    const vehicleIdCallbackValues = (pointArray as MarkerPoint[]).map(
      (point, i) => callback(point, i + 1, vehicleIndex + 1, pointArray.length)
    );
    return [...callbackValues, ...vehicleIdCallbackValues];
  }, [] as T[]);
};

export const getAnnotationHtml = (point: MarkerPoint, stopNumber: number) => {
  return `
    <div
      class="map-stop-annotation-inner" 
      style="background-color: ${point.color};"
    >
      <div>
        ${stopNumber}
      </div>
      <div class="map-stop-annotation-divider"></div>
      <div>
        ${sanitizeStr(point.name)}
      </div>
    </div>
  `;
};

export const createAnnotationIcon = (
  point: MarkerPoint,
  stopNumber: number
) => {
  return new L.DivIcon({
    className: "map-stop-annotation",
    iconAnchor: [50, 35],
    html: getAnnotationHtml(point, stopNumber),
  });
};

// numbers that are used in this function are arbitrary,
// used multiples of 100 for simplicity sake
export const getAnnotationZIndexOffset = (
  stopNumber: number,
  vehicleIndex: number,
  totalVehicleStops: number
) => {
  // make sure annotations from the same vehicle render together on the same "layer"
  // (ex. want to make sure ALL vehicle-2 annotations render above or below any
  // not a mix of rendering above and below)
  const offset = vehicleIndex * 1000;
  // stops that are earlier in the route are rendered higher, so offset needs to start
  // at the highest possible for the first stopNumber and then decrease from there
  const highestOffsetForVehicle = offset + 100 * totalVehicleStops;
  // make sure annotations from a later stop render below annotations from an earlier stop
  const offsetWithStopNumber = highestOffsetForVehicle - stopNumber * 100;
  return offsetWithStopNumber;
};
