import { MarketplaceAppConstraint } from "../../../api/core/controlPlane.types";

export const PYOMO_SHIFT_ASSIGNMENT_PARTNER_ID = "nextmv";
export const PYOMO_SHIFT_ASSIGNMENT_APP_ID = "shift.assign.pyomo";
export const PYOMO_SHIFT_ASSIGNMENT_SUBSCRIPTION_ID = `${PYOMO_SHIFT_ASSIGNMENT_PARTNER_ID}-${PYOMO_SHIFT_ASSIGNMENT_APP_ID}`;
export const PYOMO_SHIFT_ASSIGNMENT_ICON_URL = "/images/logos/pyomo-icon.png";
export const PYOMO_SHIFT_ASSIGNMENT_NAME = "Pyomo Shift Assignment";
export const PYOMO_SHIFT_ASSIGNMENT_TEMPLATE_NAME = "shift-assignment-pyomo";
export const PYOMO_SHIFT_ASSIGNMENT_SHORT_DESC =
  "Solve a MIP-based shift assignment model with Pyomo.";
export const PYOMO_SHIFT_ASSIGNMENT_SUMMARY =
  "Solve a shift assignment model with the low-code Pyomo Shift Assignment app. Define worker attributes such as availability, preferences, and qualifications as well as shift requirements and assignment rules and then run the app to assign workers to shifts.";
export const PYOMO_SHIFT_ASSIGNMENT_DOCS_LINK =
  "https://www.nextmv.io/docs/community-apps/shift-assignment";
export const PYOMO_SHIFT_ASSIGNMENT_CODE_REPO_LINK =
  "https://github.com/nextmv-io/community-apps/tree/develop/shift-assignment-pyomo";
export const PYOMO_SHIFT_ASSIGNMENT_MODEL_TYPE = ["Scheduling"];
export const PYOMO_SHIFT_ASSIGNMENT_LANGUAGE = ["Python"];
export const PYOMO_SHIFT_ASSIGNMENT_PACKAGES = ["Pyomo"];
export const PYOMO_SHIFT_ASSIGNMENT_SOLVER = ["CBC"];

export const PYOMO_SHIFT_ASSIGNMENT_CONSTRAINTS: MarketplaceAppConstraint[] = [
  {
    id: "workers",
    name: "workers",
    property: "workers",
    docsUrl: "https://www.nextmv.io/docs/community-apps/shift-assignment#input",
    summary: "Availabile workers.",
    type: "array",
  },
  {
    id: "shifts",
    name: "shifts",
    property: "shifts",
    docsUrl: "https://www.nextmv.io/docs/community-apps/shift-assignment#input",
    summary: "Availabile shifts.",
    type: "array",
  },
  {
    id: "availability",
    name: "availability",
    property: "availability",
    docsUrl:
      "https://www.nextmv.io/docs/community-apps/shift-assignment#worker",
    summary: "Availability times of the worker.",
    type: "array",
  },
  {
    id: "min_rest_hours_between_shifts",
    name: "min_rest_hours_between_shifts",
    property: "min_rest_hours_between_shifts",
    docsUrl: "https://www.nextmv.io/docs/community-apps/shift-assignment#rule",
    summary: "Number of hours a worker must rest between shifts.",
    type: "int",
  },
  {
    id: "qualifications",
    name: "qualifications",
    property: "qualifications",
    docsUrl:
      "https://www.nextmv.io/docs/community-apps/shift-assignment#worker",
    summary: "Which workers are compatible with which shifts.",
    type: "array",
  },
  {
    id: "preferences",
    name: "preferences",
    property: "preferences",
    docsUrl:
      "https://www.nextmv.io/docs/community-apps/shift-assignment#worker",
    summary: "Worker preferences for shifts.",
    type: "map",
  },
  {
    id: "start_time",
    name: "start_time",
    property: "start_time",
    docsUrl: "https://www.nextmv.io/docs/community-apps/shift-assignment#shift",
    summary: "Start time of a shift.",
    type: "timestamp",
  },
  {
    id: "end_time",
    name: "end_time",
    property: "end_time",
    docsUrl: "https://www.nextmv.io/docs/community-apps/shift-assignment#shift",
    summary: "End time of a shift.",
    type: "timestamp",
  },
  {
    id: "count",
    name: "count",
    property: "count",
    docsUrl: "https://www.nextmv.io/docs/community-apps/shift-assignment#shift",
    summary: "Number of workers needed for the shift.",
    type: "integer",
  },
];
