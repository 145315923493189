import { Box, Flex } from "@console/dsc";
import {
  styleScrollbarX,
  styleScrollbarY,
} from "@console/dsc/src/lib/constants";
import { rem } from "@console/dsc/src/lib/tools";
import styled from "@emotion/styled/macro";
import tinycolor from "tinycolor2";

import {
  BANNER_HEIGHT,
  BANNER_HEIGHT_MOBILE,
  HEADER_HEIGHT,
  HEADER_HEIGHT_EXPANDED_MOBILE,
  MAIN_NAV_MOBILE_HEIGHT,
  MAIN_NAV_SIDEBAR_WIDTH,
  ORG_HEADER_HEIGHT,
} from "../../../../utils/constants";

import {
  DOWNLOAD_OUTPUT_HEIGHT,
  PANEL_TABS_HEIGHT,
  PANEL_WIDTH,
  WORKER_AVAILABILITY_HOUR_WIDTH,
} from "./utils/constants";
import {
  GetRoutingContainerHeightProps,
  ScrollYFlexProps,
  StyledChartsContainerProps,
  StyledHourPanelsFlexProps,
  StyledRunResultContainerProps,
  StyledRunViewContainerProps,
  StyledScheduleWorkersColProps,
  WorkerIdProps,
  WorkerRequiredPeriodProps,
  WorkerSchedulePeriodProps,
  WorkerScheduleRowProps,
} from "./RunDetails.types";

const getRoutingContainerHeight = ({
  breakpoint,
  hasBanner,
}: GetRoutingContainerHeightProps) => {
  if (breakpoint === "md") {
    return hasBanner
      ? `calc(
          100vh -
          ${ORG_HEADER_HEIGHT} -
          ${BANNER_HEIGHT} -
          ${HEADER_HEIGHT}
        )`
      : `calc(
          100vh -
          ${ORG_HEADER_HEIGHT} -
          ${HEADER_HEIGHT}
        )`;
  }
  if (breakpoint === "sm") {
    return hasBanner
      ? `calc(
          100vh -
          ${ORG_HEADER_HEIGHT} -
          ${BANNER_HEIGHT} -
          ${HEADER_HEIGHT_EXPANDED_MOBILE}
        )`
      : `calc(
          100vh -
          ${ORG_HEADER_HEIGHT} -
          ${HEADER_HEIGHT_EXPANDED_MOBILE}
        )`;
  }
  // mobile
  return hasBanner
    ? `calc(
        100vh -
        ${ORG_HEADER_HEIGHT} -
        ${BANNER_HEIGHT_MOBILE} -
        ${HEADER_HEIGHT_EXPANDED_MOBILE} -
        ${MAIN_NAV_MOBILE_HEIGHT}
      )`
    : `calc(
        100vh -
        ${ORG_HEADER_HEIGHT} -
        ${HEADER_HEIGHT_EXPANDED_MOBILE} -
        ${MAIN_NAV_MOBILE_HEIGHT}
      )`;
};

export const StyledRunResultContainer = styled(
  Flex
)<StyledRunResultContainerProps>`
  width: calc(
    100% + ${({ theme }) => theme.spacing.s4} +
      ${({ theme }) => theme.spacing.s4}
  );
  height: ${(props) =>
    getRoutingContainerHeight({
      hasBanner: props.hasBanner,
    })};
  margin-top: calc(
    ${HEADER_HEIGHT_EXPANDED_MOBILE} + ${({ theme }) => theme.spacing.s9}
  );
  margin-left: calc(${({ theme }) => theme.spacing.s4} * -1);
  flex-direction: column;
  align-items: stretch;
  position: relative;

  @media (min-width: ${({ theme }) => theme.breakpoint.sm}) {
    width: calc(
      100% + ${({ theme }) => theme.spacing.s6} +
        ${({ theme }) => theme.spacing.s4}
    );
    height: ${(props) =>
      getRoutingContainerHeight({
        breakpoint: "sm",
        hasBanner: props.hasBanner,
      })};
    margin-top: ${HEADER_HEIGHT_EXPANDED_MOBILE};
    margin-left: calc(${({ theme }) => theme.spacing.s6} * -1);
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.md}) {
    width: calc(
      100% + ${({ theme }) => theme.spacing.s6} +
        ${({ theme }) => theme.spacing.s7}
    );
    height: ${(props) =>
      getRoutingContainerHeight({
        breakpoint: "md",
        hasBanner: props.hasBanner,
      })};
    margin-top: ${HEADER_HEIGHT};
    margin-left: calc(${({ theme }) => theme.spacing.s6} * -1);
    flex-direction: row;
  }
`;

export const StyledControlPanel = styled(Flex)`
  width: 100%;
  height: 40vh;
  flex-direction: column;
  flex-shrink: 0;
  position: relative;
  border-bottom-width: ${({ theme }) => theme.border.width};
  border-bottom-style: ${({ theme }) => theme.border.style};
  border-bottom-color: ${({ theme }) => theme.color.gray200T};
  z-index: 998;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}) {
    width: ${PANEL_WIDTH};
    height: 100%;
    border-bottom-width: 0;
    box-shadow: ${({ theme }) => theme.border.width} 0 0 0
      ${({ theme }) => theme.color.gray200T};
  }
`;

export const StyledControlPanelTabsContainer = styled(Flex)`
  width: 100%;
  height: ${PANEL_TABS_HEIGHT};
  align-items: center;
  padding-top: ${({ theme }) => theme.spacing.s1};
  padding-left: ${({ theme }) => theme.spacing.s2};
  position: absolute;
  left: 0;
  top: ${DOWNLOAD_OUTPUT_HEIGHT};
  border-bottom-width: ${({ theme }) => theme.border.width};
  border-bottom-style: ${({ theme }) => theme.border.style};
  border-bottom-color: ${({ theme }) => theme.color.gray200};
  background-color: ${({ theme }) => theme.color.white};
  z-index: 1;
`;

export const StyledInputEditorContainer = styled(Flex)`
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

export const StyledSolutionOutputContainer = styled(Flex)`
  width: 100%;
  height: 100%;
  padding-top: ${PANEL_TABS_HEIGHT};
`;

export const StyledMapContainer = styled(Box)`
  width: 100%;
  height: 100%;
  position: relative;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}) {
    width: auto;
    flex-grow: 1;
  }
`;

export const StyledChartsContainer = styled(Box)<StyledChartsContainerProps>`
  width: 100vw;
  height: 100%;
  position: relative;

  /* overflow scroll for narrow views */
  overflow-y: scroll;
  ${(props) => styleScrollbarY(props.theme)}

  @media (min-width: ${({ theme }) => theme.breakpoint.sm}) {
    width: calc(
      100vw - ${MAIN_NAV_SIDEBAR_WIDTH} - ${({ theme }) => theme.spacing.s2}
    );
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.md}) {
    width: calc(
      100vw - ${MAIN_NAV_SIDEBAR_WIDTH} - ${PANEL_WIDTH} -
        ${({ theme }) => theme.spacing.s10}
    );
    flex-grow: 1;
  }

  ${(props) =>
    `
      width: calc(
        100vw -
        ${props.theme.spacing.s1} -
        ${props.theme.spacing.s1}
      );

      @media (min-width: ${props.theme.breakpoint.sm}) {
        width: calc(
          100vw - 
          ${MAIN_NAV_SIDEBAR_WIDTH} -
          ${props.theme.spacing.s2} -
          ${props.theme.spacing.s1} -
          ${props.theme.spacing.s1}
        );
      }

      @media (min-width: ${props.theme.breakpoint.md}) {
        width: calc(
          100vw - 
          ${MAIN_NAV_SIDEBAR_WIDTH} - 
          ${PANEL_WIDTH} -
          ${props.theme.spacing.s10} - 
          ${props.theme.spacing.s1} - 
          ${props.theme.spacing.s1}
        );
      }
    `}
`;

export const ScrollYFlex = styled(Flex)<ScrollYFlexProps>`
  height: 100%;
  position: relative;
  background-color: ${({ theme }) => theme.color.white};

  overflow-y: scroll;
  overflow-x: hidden;

  ${(props) => styleScrollbarY(props.theme)}

  /* visual hack to add border to frame vertical scrollbar */
  box-shadow: 
    inset -11px 0 0 0 ${({ theme }) => theme.color.white}, 
    inset -12px 0 0 0 ${({ theme }) => theme.color.gray200};

  /* for routing demo only */
  ${(props) =>
    props.mobileOnly &&
    `
      @media (min-width: ${props.theme.breakpoint.md}) {
        overflow-y: visible
      }
    `}
`;

export const VehicleControlsHeader = styled(Flex)`
  position: absolute;
  right: 0;
  top: ${DOWNLOAD_OUTPUT_HEIGHT};
  transform: translateY(${rem(-0.5)});
  z-index: 1;
`;

export const StyledLogs = styled.pre`
  white-space: pre-wrap;
  font-family: ${({ theme }) => theme.ui2Typography.fontFamilyCode};
  font-size: ${({ theme }) => theme.ui2Typography.fontSizeCode};
  font-style: ${({ theme }) => theme.ui2Typography.fontStyleCode};
  font-weight: ${({ theme }) => theme.ui2Typography.fontWeightCode};
  line-height: ${({ theme }) => theme.ui2Typography.lineHeightCode};
`;

export const StyledScheduleWorkersCol = styled(
  Box
)<StyledScheduleWorkersColProps>`
  width: ${rem(226)};
  min-height: ${(props) => props.panelHeight};
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  background-color: ${({ theme }) => theme.color.white};
  z-index: 2;

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    box-shadow: ${rem(1)} 0 0 0 ${({ theme }) => theme.color.gray200T},
      0 ${rem(2)} ${rem(24)} ${rem(-4)} ${({ theme }) => theme.color.gray200T},
      0 0 ${rem(15)} ${rem(-5)} ${({ theme }) => theme.color.gray100T};
    z-index: 1;
  }
`;

export const StyledScheduleWorkersContent = styled.div`
  position: relative;
  z-index: 2;
`;

export const WorkerId = styled.p<WorkerIdProps>`
  margin-top: ${({ theme }) => theme.spacing.s1};
  padding-top: ${({ theme }) => theme.spacing.s1};
  padding-right: ${({ theme }) => theme.spacing.s2};
  padding-bottom: ${({ theme }) => theme.spacing.s1};
  padding-left: ${({ theme }) => theme.spacing.s2};
  font-family: ${({ theme }) => theme.ui2Typography.fontFamilyBody3Bold};
  font-size: ${({ theme }) => theme.ui2Typography.fontSizeBody3Bold};
  font-style: ${({ theme }) => theme.ui2Typography.fontStyleBody3Bold};
  font-weight: ${({ theme }) => theme.ui2Typography.fontWeightBody3Bold};
  line-height: ${({ theme }) => theme.ui2Typography.lineHeightBody3Bold};
  letter-spacing: ${({ theme }) => theme.ui2Typography.letterSpacingBody3Bold};
  text-transform: ${({ theme }) => theme.ui2Typography.textTransformBody3Bold};
  color: ${(props) => tinycolor(props.color).darken(10).toHexString()};
  border-width: ${({ theme }) => theme.border.widthThick};
  border-style: ${({ theme }) => theme.border.style};
  border-color: ${(props) => props.color};
  border-radius: ${({ theme }) => theme.border.radius};
  background-color: ${(props) =>
    tinycolor(props.color).setAlpha(0.1).toRgbString()};
`;

export const WorkerScheduleCanvas = styled(Box)`
  position: absolute;
  left: 0;
  top: 0;
`;

export const WorkerScheduleRow = styled.ul<WorkerScheduleRowProps>`
  display: flex;
  position: absolute;
  margin-top: ${(props) => rem(props.positionTop)};
`;
export const WorkerSchedulePeriod = styled.li<WorkerSchedulePeriodProps>`
  width: calc(${(props) => rem(props.width)} - ${rem(1)});
  height: ${({ theme }) => theme.spacing.s7};
  margin-left: ${rem(1)};
  position: absolute;
  left: ${(props) => rem(props.positionLeft)};
  border-radius: ${({ theme }) => theme.border.radius};
  box-shadow: inset 0 0 0 ${({ theme }) => theme.border.widthMediumShadowOnly}
    ${(props) => tinycolor(props.color).setAlpha(0.3).toRgbString()};
  background-color: ${(props) => props.backgroundColor};
`;

export const WorkerRequiredPeriod = styled.div<WorkerRequiredPeriodProps>`
  width: ${(props) => rem(props.width)};
  height: ${(props) => rem(props.height)};
  position: absolute;
  left: ${(props) => rem(props.positionLeft)};
  border-radius: ${({ theme }) => theme.border.radius};
  box-shadow: inset 0 0 0 ${({ theme }) => theme.border.width}
    ${({ theme }) => theme.color.orange500};
`;

export const StyledHourPanelsFlex = styled(Flex)<StyledHourPanelsFlexProps>`
  width: 100%;
  min-height: ${(props) => props.panelHeight};
  flex-direction: column;
  overflow-x: scroll;
  z-index: 0;

  ${({ theme }) => styleScrollbarX(theme)}
`;

export const StyledHourPanel = styled.div`
  box-sizing: border-box;
  width: 100%;
  flex-grow: 1;
  border-width: ${({ theme }) => theme.border.widthThick};
  border-style: ${({ theme }) => theme.border.style};
  border-color: ${({ theme }) => theme.color.gray200};
  border-right: 0;
  background: ${({ theme }) => theme.color.gray100};
`;
export const StyledHourCol = styled(Flex)`
  width: ${rem(WORKER_AVAILABILITY_HOUR_WIDTH)};
  flex-direction: column;
  flex-shrink: 0;

  /* rounded corners for StyledHourPanel */
  &:first-of-type {
    ${StyledHourPanel} {
      border-top-left-radius: ${({ theme }) => theme.border.radiusLarge};
      border-bottom-left-radius: ${({ theme }) => theme.border.radiusLarge};
    }
  }
  &:nth-last-of-type(2) {
    ${StyledHourPanel} {
      border-right-width: ${({ theme }) => theme.border.widthThick};
      border-right-style: ${({ theme }) => theme.border.style};
      border-right-color: ${({ theme }) => theme.color.gray200};
      border-top-right-radius: ${({ theme }) => theme.border.radiusLarge};
      border-bottom-right-radius: ${({ theme }) => theme.border.radiusLarge};
    }
  }
`;

export const StyledRunViewContainer = styled(Box)<StyledRunViewContainerProps>`
  @media (min-width: ${({ theme }) => theme.breakpoint.rg}) {
    height: calc(100% - ${HEADER_HEIGHT});
  }

  ${(props) =>
    props.isExpanded &&
    `
      margin-top: calc(${HEADER_HEIGHT_EXPANDED_MOBILE} + ${props.theme.spacing.s9});
      @media (min-width: ${props.theme.breakpoint.sm}) {
        margin-top: ${HEADER_HEIGHT_EXPANDED_MOBILE};
      }
      @media (min-width: ${props.theme.breakpoint.rg}) {
        margin-top: ${HEADER_HEIGHT};
        margin-right: ${props.theme.spacing["-s7"]};
        margin-left: ${props.theme.spacing["-s6"]};
        padding-left: ${props.theme.spacing.s6};
      }
    `}
`;

export const StyledRunDetailsSidebar = styled(Box)`
  @media (min-width: ${({ theme }) => theme.breakpoint.rg}) {
    border-left-width: ${({ theme }) => theme.border.width};
    border-left-style: ${({ theme }) => theme.border.style};
    border-left-color: ${({ theme }) => theme.color.gray200};
  }
`;

export const StyledRunLogEntry = styled.pre`
  font-family: ${({ theme }) => theme.ui2Typography.fontFamilyCode};
  font-size: ${({ theme }) => theme.ui2Typography.fontSizeCode};
  font-style: ${({ theme }) => theme.ui2Typography.fontStyleCode};
  font-weight: ${({ theme }) => theme.ui2Typography.fontWeightCode};
  line-height: ${({ theme }) => theme.ui2Typography.lineHeightCode};
  letter-spacing: ${({ theme }) => theme.ui2Typography.letterSpacingCode};
  color: ${({ theme }) => theme.color.gray700};
  white-space: pre-wrap;
`;
