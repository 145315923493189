import React from "react";
import { Text } from "@console/dsc";
import { useTheme } from "@emotion/react";

const FreeTrialSummary = () => {
  const theme = useTheme();

  return (
    <>
      <Text mt={2} styleName="body-1">
        Enter your credit card for instant access to the{" "}
        <Text
          as="strong"
          styleName="body-1-bold"
          styles={{ color: theme.color.gray800 }}
        >
          14-day free trial
        </Text>
        . Your card will{" "}
        <Text
          as="strong"
          styleName="body-1-bold"
          styles={{ color: theme.color.gray800 }}
        >
          NOT
        </Text>{" "}
        be charged and a subscription will{" "}
        <Text
          as="strong"
          styleName="body-1-bold"
          styles={{ color: theme.color.gray800 }}
        >
          NOT
        </Text>{" "}
        be automatically started at the end of your trial.
      </Text>
    </>
  );
};

export default FreeTrialSummary;
