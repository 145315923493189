import { GeneralEvents } from "../../../analytics/types/general.types";
import {
  getCommandChangeDirectory,
  getCommandCommunityClone,
  getCommandConfigure,
  getCommandInstall,
} from "../utils/manageCliCommands";

interface InstallCommands {
  download: string;
  configure: string;
  init: string;
  initCd: string;
}

type InstallStepCommandProps = {
  ariaLabel: string;
  command: (param?: string | null) => string;
  tracking: (templateId?: string) => GeneralEvents;
};

export const installStepsCommands: {
  [key in keyof InstallCommands]: InstallStepCommandProps;
} = {
  download: {
    ariaLabel: "Copy CLI install command to clipboard",
    command: (apiKey) =>
      getCommandInstall({
        apiKey,
        apiBaseUrl: process.env.REACT_APP_API_BASE,
        consoleBaseUrl: process.env.REACT_APP_REDIRECT_URL,
      }),
    tracking: () => ({
      view: "Install Page",
      action: "Command Copied",
      meta: {
        type: "install-cli",
      },
    }),
  },
  configure: {
    ariaLabel: "Copy CLI configure command to clipboard",
    command: (apiKey) => getCommandConfigure({ apiKey }),
    tracking: () => ({
      view: "Install Page",
      action: "Command Copied",
      meta: {
        type: "configure",
      },
    }),
  },
  init: {
    ariaLabel: "Copy project clone command to clipboard",
    command: (templateId) => getCommandCommunityClone(templateId),
    tracking: (templateId) => ({
      view: "Template Page",
      action: "Command Copied",
      meta: {
        type: "clone",
        template: templateId,
      },
    }),
  },
  initCd: {
    ariaLabel: "Copy change to project directory command",
    command: (templateId) => getCommandChangeDirectory(templateId),
    tracking: (templateId) => ({
      view: "Template Page",
      action: "Command Copied",
      meta: {
        type: "cd",
        template: templateId,
      },
    }),
  },
};
