import { Auth } from "aws-amplify";

import ExpectedSSOError from "../../customErrors/ExpectedSSOError";
import NotFoundError from "../../customErrors/NotFoundError";
import { NEXTMV_ACCOUNT_HEADER_KEY } from "../core/core.config";

import { getToken } from "./cognito";

export const handleSSOResponse = async (resp: Response): Promise<Response> => {
  if ([401, 403].includes(resp.status)) {
    throw new ExpectedSSOError();
  }

  return await handleResponse(resp);
};

export const handleResponse = async (resp: Response): Promise<Response> => {
  if ([400, 401, 403].includes(resp.status)) {
    let resJson;
    try {
      resJson = await resp.json();
    } catch (err) {
      console.error(err);
      throw new Error((err as Error).message);
    }

    if (resJson.error) {
      throw new Error(resJson.error);
    }
    throw new Error(`the server responded with status code ${resp.status}`);
  }

  if (!resp.ok) {
    switch (resp.status) {
      case 500:
        throw new Error("an internal server error occurred");
      case 404:
        throw new NotFoundError();
      default:
        throw new Error(`the server responded with status code ${resp.status}`);
    }
  }

  return resp;
};

export const fetchWithToken = (baseUrl: string) =>
  async function (
    path: string,
    method: string = "GET",
    body?: string,
    options?: object
  ): Promise<Response> {
    let token = await getToken();
    if (!token) {
      // use signOut to clear the userReducer in AuthProvider,
      // which triggers a redirect to the /login screen
      await Auth.signOut();
      throw new Error("User session has expired");
    }
    const resp = await fetch(`${baseUrl}${path}`, {
      method,
      body,
      headers: {
        Authorization: `Bearer ${token}`,
        ...options,
      },
    });

    return handleResponse(resp);
  };

export const fetchWithAccount = (account: string, baseUrl: string) =>
  async function (
    path: string,
    method: string = "GET",
    body?: string,
    options?: object
  ): Promise<Response> {
    let token = await getToken();
    if (!token) {
      // use signOut to clear the userReducer in AuthProvider,
      // which triggers a redirect to the /login screen
      await Auth.signOut();
      throw new Error("User session has expired");
    }

    const resp = await fetch(`${baseUrl}${path}`, {
      method,
      body,
      headers: {
        Authorization: `Bearer ${token}`,
        [NEXTMV_ACCOUNT_HEADER_KEY]: account,
        ...options,
      },
    });

    return handleResponse(resp);
  };

export const fetchFileUpload = () =>
  async function (
    path: string,
    method: string = "PUT",
    body: ArrayBuffer | Blob,
    additionalHeaders: object
  ): Promise<Response> {
    const resp = await fetch(path, {
      method,
      body,
      headers: {
        ...additionalHeaders,
      },
    });

    return handleResponse(resp);
  };

export const fetchFileContents = () =>
  async function (path: string, method: string = "GET"): Promise<Response> {
    const resp = await fetch(path, {
      method,
    });

    return handleResponse(resp);
  };
