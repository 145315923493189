import * as React from "react";
import { components } from "react-select";
import { useTheme } from "@emotion/react";

import { renderSolutionOption } from "./OptionSolution";

// get SingleValue base component from library
const { SingleValue } = components;

const CustomSingleValueSolution = (props: any) => {
  const theme = useTheme();

  // get data to build option, this is the object from
  // the data array for this particular option
  const { data } = props;

  return (
    <SingleValue {...props}>
      {renderSolutionOption({ theme, data })}
    </SingleValue>
  );
};

export default CustomSingleValueSolution;
