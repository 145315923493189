import { isEmpty } from "lodash";

import { ShadowTestResponse } from "../../../../../api/core/controlPlane.types";
import { formatDate } from "../../../../../utils/format";
import { GetTestStartCriteriaReturn } from "../../../types/Experiment.types";

type GetShadowTestInstancesReturn = {
  baselineInstances: string;
  candidateInstances: string;
};
export const getShadowTestInstances = (
  shadowTest: ShadowTestResponse
): GetShadowTestInstancesReturn | undefined => {
  if (shadowTest.comparisons.length === 0) return undefined;

  let baselineInstances: string[] = [];
  let candidateInstances: string[] = [];

  shadowTest.comparisons.forEach((comparison) => {
    baselineInstances.push(comparison.baseline_instance_id);
    candidateInstances.push(...comparison.candidate_instance_ids);
  });

  return {
    baselineInstances: baselineInstances.join(", "),
    candidateInstances: candidateInstances.join(", "),
  };
};

export const getShadowTestEndCriteria = (shadowTest: ShadowTestResponse) => {
  const maxRuns = shadowTest.termination_events?.maximum_runs;
  const date = shadowTest.termination_events?.time;

  return [
    {
      id: "max-runs",
      shortType: "Max runs",
      type: "Maximum runs",
      value: maxRuns,
      formattedValue: maxRuns,
      shortValue: maxRuns,
    },
    ...(date
      ? [
          {
            id: "date",
            shortType: "Date",
            type: "End date",
            value: date,
            formattedValue: formatDate(date, true),
            shortValue: formatDate(date, false, true),
          },
        ]
      : []),
  ];
};

export const getShadowTestStartCriteria = (
  shadowTest: ShadowTestResponse
): GetTestStartCriteriaReturn => {
  if (isEmpty(shadowTest.start_events)) return undefined;

  const formattedStartCriteriaValue =
    shadowTest.start_events.time &&
    formatDate(shadowTest.start_events.time, true);

  return {
    id: "date",
    shortType: "date",
    type: "Start date",
    value: shadowTest.start_events.time,
    formattedValue: formattedStartCriteriaValue,
  };
};
