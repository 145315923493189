import React, { useEffect, useMemo, useState } from "react";
import { Box, Button2, Flex, Table2 } from "@console/dsc";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../../../analytics";
import { useUser } from "../../../../AuthProvider";
import Header from "../../../../components/Header";
import { useMetaTitle } from "../../../../components/Layout";
import PlanNotice from "../../../../components/PlanNotice";
import StandardError from "../../../../components/StandardError";
import { useAppCollection } from "../../../../contexts/apps/App.context";
import { userHasAccessToAction } from "../../../../utils/rbac_utils";
import { ActionGroups } from "../../../../utils/rbac_utils/types";
import { AppPageProps } from "../../App.types";
import RunsQueryDateControls from "../../components/RunsQueryDateControls";
import useRunsFilter from "../../hooks/useRunsFilter";

import { getFilteredRuns } from "./utils/getFilteredRuns";
import { getRunHistoryEmptyMessage } from "./utils/getRunHistoryEmptyMessage";
import { getRunHistoryItems } from "./utils/getRunHistoryItems";
import { getRunHistoryTableHeaders } from "./utils/getRunHistoryTableHeaders";
import { getTableLayoutSchemaAppRunHistory } from "./utils/tableLayoutSchemas";
import { Table2HeaderObj } from "./RunHistory.types";

const pageTitle = "Runs";

const RunHistory = ({
  app,
  testOnlyHideDatePicker = false,
}: AppPageProps & { testOnlyHideDatePicker?: boolean }) => {
  const [{ id: accountId, roles }] = useUser();
  const [, setMetaTitle] = useMetaTitle();
  const theme = useTheme();

  const [filterText, setFilterText] = useState("");

  const {
    loadRuns,
    runHistory,
    runsLoadError,
    additionalRunsLoading,
    runsNextPageToken,
    setRunHistory,
    setAdditionalRunsLoading,
  } = useAppCollection();

  const {
    clearSelectDateRange,
    confirmSelectDateRange,
    isActiveDatePicker,
    isDateQueriedRuns,
    queryEnd,
    queryStart,
    setIsActiveDatePicker,
    setIsDateQueriedRuns,
    setQueryEnd,
    setQueryStart,
  } = useRunsFilter();

  // manage page display
  useEffect(() => {
    setMetaTitle(pageTitle);
  }, [setMetaTitle]);

  // load runs if not in context already
  useEffect(() => {
    if (!runHistory && !queryStart && !queryEnd && !runsLoadError) {
      loadRuns({
        applicationId: app.id,
        shouldPaginate: true,
      });
    }
  }, [app.id, loadRuns, queryEnd, queryStart, runHistory, runsLoadError]);

  // load new runs if queried by date
  useEffect(() => {
    if (queryStart && queryEnd && !isActiveDatePicker) {
      setRunHistory(null);
      setIsDateQueriedRuns(true);
      loadRuns({
        applicationId: app.id,
        queryStart,
        queryEnd,
        shouldPaginate: true,
      });
    }
  }, [
    app.id,
    isActiveDatePicker,
    loadRuns,
    queryEnd,
    queryStart,
    runsNextPageToken,
    setIsDateQueriedRuns,
    setRunHistory,
  ]);

  const closeSelectDateRange = () => {
    setIsActiveDatePicker(false);

    if (!queryStart && !queryEnd && isDateQueriedRuns) {
      setRunHistory(null);
      loadRuns({
        applicationId: app.id,
        shouldPaginate: true,
      });
    }
    return;
  };

  const loadMoreRuns = (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    e.preventDefault();
    e.stopPropagation();

    setAdditionalRunsLoading(true);
    loadRuns({
      applicationId: app.id,
      nextPageToken: runsNextPageToken,
      queryStart,
      queryEnd,
      shouldAppend: true,
      shouldPaginate: true,
    });
  };

  const refreshRuns = () => {
    loadRuns({
      applicationId: app.id,
      queryStart,
      queryEnd,
      shouldPaginate: true,
    });
    return;
  };

  const runHistoryData = useMemo(() => {
    return getRunHistoryItems({
      hasName: true,
      hasStatistics: false,
      runHistory: runHistory,
    });
  }, [runHistory]);
  const filteredRunHistory = getFilteredRuns(filterText, runHistoryData);
  const runHistoryHeaders: Table2HeaderObj[] = useMemo(() => {
    return getRunHistoryTableHeaders({
      accId: accountId,
      appId: app.id,
      runHistory,
      theme,
      showRunName: true,
    });
  }, [accountId, app.id, runHistory, theme]);

  if (runsLoadError) return <StandardError errorMessage={runsLoadError} />;

  const canUserCreateRun = userHasAccessToAction(
    roles,
    ActionGroups.RunOperator,
    {}
  );

  return (
    <Box pb={[6, 8]}>
      <Header
        configPageTitle={{
          label: pageTitle,
          tooltipContent: `All remote runs (local runs excluded) made with ${app.name} are saved and can be accessed via the run ID. Recent runs are shown in the table below.`,
        }}
        configActionButton={{
          label: "New run",
          url: `/acc/${accountId}/app/${app.id}/runs/new`,
          onClick: () =>
            trackEvent("RunHistory", {
              view: "Run History",
              action: "Create New Run Button Clicked",
            }),
          isActionAllowed: canUserCreateRun,
        }}
        configFilter={{
          inputText: filterText,
          testId: "filter-runs",
          setInputText: setFilterText,
        }}
      />

      <PlanNotice {...{ app }} type="no-custom-apps" />

      <Flex mt={3}>
        <RunsQueryDateControls
          {...{
            clearSelectDateRange,
            closeSelectDateRange,
            confirmSelectDateRange,
            isActiveDatePicker,
            queryEnd,
            queryStart,
            refreshRuns,
            setIsActiveDatePicker,
            setQueryEnd,
            setQueryStart,
            setRunHistory,
            testOnlyHideDatePicker,
          }}
          itemsLoading={!runHistory}
        />
      </Flex>

      <Table2
        testId="app-runs-table"
        isWide
        canFilter
        canSort
        headers={runHistoryHeaders}
        isStickyColumn
        mt={3}
        layoutSchema={getTableLayoutSchemaAppRunHistory(runHistory)}
        emptyMessage={getRunHistoryEmptyMessage({
          filterText,
          isLoading: !runHistory,
        })}
        fileNameCSV={`run_history_${app.id}`}
        showCSVLink
        csvLinkTopValue={theme.spacing.s1}
        data={filteredRunHistory}
      />

      {runsNextPageToken && (
        <Button2
          mt={2}
          label="Load more"
          onClick={loadMoreRuns}
          isLoading={additionalRunsLoading}
        />
      )}
    </Box>
  );
};

export default RunHistory;
