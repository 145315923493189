import {
  getPlanData as getPlanDataCall,
  getPromoCode as getPromoCodeCall,
} from "../../api/payment/controlPlane";

// @legacy-plans (unused)
export const checkPromoCode = (search: string) => {
  const urlParams = new URLSearchParams(search);
  const promoValue = urlParams.get("promo");
  return !!promoValue;
};

// @legacy-plans (unused)
export const getPromoCode = async (search: string) => {
  const urlParams = new URLSearchParams(search);
  const promoValue = urlParams.get("promo");
  if (promoValue) {
    const promoCode = await getPromoCodeCall(promoValue);
    return [promoCode, promoValue] as const;
  } else {
    return null;
  }
};

export const getPlanData = async (priceId: string) => {
  if (priceId) {
    const planData = await getPlanDataCall(priceId);
    return [planData, priceId] as const;
  } else {
    return null;
  }
};
