import { Polyline } from "react-leaflet";
import { useTheme } from "@emotion/react";

import { useDebounceCallback } from "../../../../../../hooks/useDebounce";
import { Coords } from "../../../../Map.types";
import { ROUTE_FOCUS_TIMEOUT } from "../../../../utils/constants";

import PolylineWithArrows from "./PolylineWithArrows";

export default function RouteFeature({
  coords,
  color,
  isHidden,
  focusedRouteId,
  setFocusedRouteId,
  routeID,
  displayDirectionalArrows,
}: {
  coords: Coords[];
  color: string;
  isHidden: boolean;
  focusedRouteId: string;
  routeID: string;
  setFocusedRouteId: (routeId: string) => void;
  displayDirectionalArrows: boolean;
}) {
  const theme = useTheme();
  const isFocused = focusedRouteId === routeID;
  const [setFocusedDebounced, clearFocusedDebounceTimeout] =
    useDebounceCallback(() => {
      setFocusedRouteId(routeID);
    }, ROUTE_FOCUS_TIMEOUT);

  const RouteLine = displayDirectionalArrows ? PolylineWithArrows : Polyline;

  return (
    <RouteLine
      key={routeID}
      positions={coords}
      pathOptions={{
        color: focusedRouteId === "" || isFocused ? color : theme.color.gray400,
        stroke: !isHidden,
      }}
      eventHandlers={{
        mouseover: (x) => setFocusedDebounced(),
        mouseout: (x) => {
          clearFocusedDebounceTimeout();
          setTimeout(() => {
            if (isFocused) {
              setFocusedRouteId("");
            }
          }, 300);
        },
      }}
    />
  );
}
