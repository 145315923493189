/*
  @legacy-plans (unused)
  Note: all of the functionality here is currently unused.
*/
import React from "react";

import { PlanResponse } from "../../../api/payment/controlPlane.types";
import { PromotionalCode } from "../../../api/payment/paymentApi.types";
import {
  PlansAction,
  PlansActionKind,
  PlansError,
  PlansState,
} from "../Plans.context.types";

const plansReducer = (state: PlansState, action: PlansAction): PlansState => {
  switch (action.type) {
    case PlansActionKind.LOAD:
      return {
        ...state,
        error: "",
        loading: false,
        plans: action.payload,
      };
    case PlansActionKind.SET_SELECTED_PLAN:
      return {
        ...state,
        selectedPlan: action.payload,
      };
    case PlansActionKind.SET_PROMO_CODE:
      return {
        ...state,
        promoCode: action.payload,
      };
    case PlansActionKind.SET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case PlansActionKind.LOADING:
      return {
        ...state,
        loading: action.payload,
        ...(action.payload ? { error: "" } : {}),
      };
    case PlansActionKind.PROMO_CODE_LOADING:
      return {
        ...state,
        promoCodeLoading: action.payload,
        ...(action.payload ? { error: "" } : {}),
      };
    default:
      throw new Error("plans action not supported");
  }
};

const defaultPlansState: PlansState = {
  plans: [],
  selectedPlan: undefined,
  promoCode: undefined,
  error: "",
  loading: true,
  promoCodeLoading: true,
};

const usePlansReducer = () => {
  const reducer = React.useReducer(plansReducer, defaultPlansState);
  const [state, dispatch] = reducer;

  const setSelectedPlan = React.useCallback(
    (plan: PlanResponse | undefined) => {
      dispatch({
        type: PlansActionKind.SET_SELECTED_PLAN,
        payload: plan,
      });
    },
    [dispatch]
  );

  const setPromoCode = React.useCallback(
    (promoCode: PromotionalCode | undefined) => {
      dispatch({
        type: PlansActionKind.SET_PROMO_CODE,
        payload: promoCode,
      });
    },
    [dispatch]
  );

  const setPromoCodeLoading = React.useCallback(
    (payload: boolean) => {
      dispatch({
        type: PlansActionKind.PROMO_CODE_LOADING,
        payload,
      });
    },
    [dispatch]
  );

  const setPlansLoading = React.useCallback(
    (payload: boolean) => {
      dispatch({ type: PlansActionKind.LOADING, payload });
    },
    [dispatch]
  );

  const updatePlans = React.useCallback(
    (plans: PlanResponse[]) => {
      dispatch({ type: PlansActionKind.LOAD, payload: plans });
    },
    [dispatch]
  );

  const setPlansError = React.useCallback(
    (error: PlansError) => {
      dispatch({
        type: PlansActionKind.SET_ERROR,
        payload: error,
      });
    },
    [dispatch]
  );

  return {
    ...state,
    setSelectedPlan,
    setPromoCodeLoading,
    setPromoCode,
    updatePlans,
    setPlansLoading,
    setPlansError,
  };
};

export default usePlansReducer;
