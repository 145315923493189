import { CompatibilityConfiguration } from "../../../../../api/core/controlPlane.types";
import { COMPATIBILITY_ITEMS } from "../constants";
import { CompatibilityValues } from "../types";

export const getCompatibilityItemValue = (
  key: string,
  type: "workers" | "tasks",
  compatibility: CompatibilityConfiguration
) => {
  return compatibility?.[type]?.[key] ?? true;
};

export const getInitialValues = (compatibility: CompatibilityConfiguration) => {
  const initialValues = Object.entries(COMPATIBILITY_ITEMS).reduce(
    (intialValues, [key, value]) => {
      return {
        ...intialValues,
        [key]: value.reduce(
          (items, item) => ({
            ...items,
            [item.id]: getCompatibilityItemValue(
              item.id,
              key as keyof typeof COMPATIBILITY_ITEMS,
              compatibility
            ),
          }),
          {}
        ),
      };
    },
    {} as CompatibilityValues
  );
  return initialValues;
};

export const updateAllCompatibilityValues = (valueToSet: boolean) => {
  return Object.entries(COMPATIBILITY_ITEMS).reduce(
    (intialValues, [key, value]) => {
      return {
        ...intialValues,
        [key]: value.reduce(
          (items, item) => ({
            ...items,
            [item.id]: valueToSet,
          }),
          {}
        ),
      };
    },
    {} as CompatibilityValues
  );
};

export const isAllToggleActive = (
  compatibilityValues: CompatibilityValues,
  isAllToggled: boolean
): boolean => {
  const allCompatibilityValuesOn = Object.values(compatibilityValues).every(
    (checkboxValues) => Object.values(checkboxValues).every((value) => value)
  );

  const allCompatibilityValuesOff = Object.values(compatibilityValues).every(
    (checkboxValues) => Object.values(checkboxValues).every((value) => !value)
  );
  if (allCompatibilityValuesOn) {
    return true;
  } else if (allCompatibilityValuesOff) {
    return false;
  }
  // if neither all are on or all are off,
  // keep the toggle in the same position it was before
  return isAllToggled;
};
