import * as React from "react";

import BaseAvatar, { AvatarSize } from "./BaseAvatar";

const AvatarExperimentInputSet = ({
  size,
  type,
}: {
  size?: AvatarSize;
  type: "default" | "fill";
}) => {
  const thisType = type || "default";

  return thisType === "fill" ? (
    <BaseAvatar {...{ size }} viewBox={[0, 0, 24, 24]}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM6.02502 7.27502C6.02502 6.58467 6.58467 6.02502 7.27503 6.02502H16.725C17.4154 6.02502 17.975 6.58467 17.975 7.27503V16.725C17.975 17.4154 17.4154 17.975 16.725 17.975H7.27502C6.58467 17.975 6.02502 17.4154 6.02502 16.725V7.27502ZM7.40003 7.40002L7.40002 9.51253H16.6V7.40003L7.40003 7.40002ZM7.40002 10.8875V13.1125L16.6 13.1125V10.8875H7.40002ZM7.40002 14.4875L7.40002 16.6H16.6V14.4875L7.40002 14.4875Z"
        fill="#F28E2C"
      />
    </BaseAvatar>
  ) : (
    <BaseAvatar {...{ size }}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.75 24C45.75 36.0122 36.0122 45.75 24 45.75C11.9878 45.75 2.25 36.0122 2.25 24C2.25 11.9878 11.9878 2.25 24 2.25C36.0122 2.25 45.75 11.9878 45.75 24ZM48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24ZM33.45 21.525V26.475L14.55 26.475V21.525H33.45ZM14.55 28.725L33.45 28.725V33.45L14.55 33.45L14.55 28.725ZM33.45 19.275V14.55L14.55 14.55L14.55 19.275H33.45ZM33.45 12.3C34.6927 12.3 35.7 13.3074 35.7 14.55L35.7 33.45C35.7 34.6927 34.6927 35.7 33.45 35.7L14.55 35.7C13.3074 35.7 12.3 34.6927 12.3 33.45V14.55C12.3 13.3074 13.3074 12.3 14.55 12.3H33.45Z"
        fill="#F46F3E"
      />
    </BaseAvatar>
  );
};

export default AvatarExperimentInputSet;
