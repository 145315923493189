type NavigateToMonthParams = {
  e: React.MouseEvent<HTMLButtonElement, MouseEvent>;
  goToMonth: (month: Date) => void;
  month?: Date;
};
export const navigateToMonth = ({
  e,
  month,
  goToMonth,
}: NavigateToMonthParams) => {
  e.preventDefault();
  e.stopPropagation();
  return month && goToMonth(month);
};
