import Parser from "rss-parser";

import { NextmvBlogRSSItem } from "../Dashboard.types";
import { nextmvBlogPosts } from "../data/blogPosts";

// TODO set up CORS/CSP to allow this (see parseURL below)
// const nextmvBlogRSSFeedUrl = "https://www.nextmv.io/blog/rss.xml";

export const getNextmvBlogPosts = async (
  limit?: number
): Promise<NextmvBlogRSSItem[]> => {
  const parser = new Parser();
  // const nextmvBlogRSSFeed = await parser.parseURL(nextmvBlogRSSFeedUrl);
  const nextmvBlogRSSFeed = await parser.parseString(nextmvBlogPosts);

  return limit
    ? (nextmvBlogRSSFeed.items.slice(0, limit) as NextmvBlogRSSItem[])
    : (nextmvBlogRSSFeed.items as NextmvBlogRSSItem[]);
};
