import { Flex, Tag, Text, Tooltip } from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";

import {
  AppInstance,
  AppResponse,
} from "../../../../../api/core/controlPlane.types";
import { useUser } from "../../../../../AuthProvider";
import { META_LABEL_WIDTH } from "../../../../../config/general";
import { getAccUrl } from "../../../../../utils/navigation";
import ExecutionClass from "../../../components/ExecutionClass";
import InstanceConfig from "../../../components/InstanceConfig";
import { appTooltips } from "../../../data/appTooltips";
import { isLatestMarketplaceVersion } from "../../../utils/marketplace";

export type InstanceDetailsProps = {
  app: AppResponse;
  instance: AppInstance;
};

const InstanceDetails = ({
  app,
  instance: { id: instanceId, version_id: versionId, configuration },
}: InstanceDetailsProps) => {
  const [{ id: accountId }] = useUser();
  const theme = useTheme();

  const instanceDetails = [
    {
      id: "instance-id",
      label: "ID",
      value: <Tag ml={rem(-6)} type="id" size="small" label={instanceId} />,
    },
    {
      id: "version-id",
      label: "Version ID",
      value: versionId && (
        <>
          <Tag
            ml={rem(-6)}
            type="id"
            size="small"
            label={versionId}
            {...(app.type &&
              app.type !== "subscription" && {
                url: getAccUrl(
                  accountId,
                  `/app/${app.id}/version/${versionId}`
                ),
              })}
          />
          {app.type === "subscription" &&
            isLatestMarketplaceVersion(versionId) && (
              <Tooltip ml={1}>
                {appTooltips.newRunInstanceMarketplaceLatestVersion.content}
              </Tooltip>
            )}
        </>
      ),
    },
    {
      id: "execution-class",
      label: "Execution class",
      value: configuration?.execution_class && (
        <ExecutionClass
          isAbbreviated
          isSmall
          executionClass={configuration.execution_class}
        />
      ),
    },
  ];

  return (
    <Flex flexDirection="column">
      {instanceDetails.map((instanceDetail, index) => {
        if (!instanceDetail.value) return null;
        return (
          <Flex key={instanceDetail.id} mt={index === 0 ? 3 : 1}>
            <Text styleName="body-3" mt={rem(-3)} width={META_LABEL_WIDTH}>
              {instanceDetail.label}
            </Text>
            {instanceDetail.value}
          </Flex>
        );
      })}

      <Flex mt={3} pb={2} width={"100%"}>
        <Text
          styleName="body-3"
          mt={rem(9)}
          width={META_LABEL_WIDTH}
          flexShrink={0}
        >
          Configuration
        </Text>

        <Flex
          hasBorderTop
          flexDirection="column"
          pt={2}
          width={`calc(100% - ${META_LABEL_WIDTH})`}
        >
          {!configuration?.options ? (
            <Flex>
              <Text
                styleName="body-3"
                styles={{
                  color: theme.color.gray600,
                }}
              >
                None
              </Text>
            </Flex>
          ) : (
            <InstanceConfig isAbbreviated options={configuration.options} />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default InstanceDetails;
