import { Text } from "@console/dsc";

import {
  RunsData,
  RunStatistics,
} from "../../../../../api/core/controlPlane.types";
import { KEY_STATISTICS_STATUS } from "../../../../../config/experiments";
import {
  formatGroupedSummaryTableName,
  formatIndicatorValue,
} from "../../../../Experiments/utils/groupedSummaryTable";
import { getStatusTextColor } from "../../RunDetails/utils/getStatusTextColor";
import { HeaderObj } from "../RunHistory.types";

const allowedIndicatorKeys = [
  "result.value",
  "run.duration",
  "metadata.duration",
  "run.iterations",
];

const hasAllowedIndicator = (key: string, experimentRuns: RunsData) => {
  if (!experimentRuns) return false;

  for (let i = 0; i < experimentRuns.length; i++) {
    const statistics = experimentRuns[i].statistics as RunStatistics;
    if (statistics) {
      for (let k = 0; k < (statistics.indicators || []).length; k++) {
        if (statistics.indicators?.[k].name === key) {
          return true;
        }
      }
    }
  }

  return false;
};

const getCustomHeaders = (
  experimentRuns: RunsData,
  theme: any
): HeaderObj[] => {
  const customHeaders: { [key: string]: HeaderObj } = {};
  if (experimentRuns) {
    for (let i = 0; i < experimentRuns.length; i++) {
      const statistics = experimentRuns[i].statistics as RunStatistics;
      if (statistics) {
        for (let k = 0; k < (statistics.indicators || []).length; k++) {
          const indicator = statistics.indicators?.[k].name || "";
          if (!customHeaders[indicator] && indicator.includes(".custom.")) {
            const header = {
              id: indicator,
              accessorKey: indicator,
              value: formatGroupedSummaryTableName(indicator),
              header: formatGroupedSummaryTableName(indicator),
              label: formatGroupedSummaryTableName(indicator),
              cell: (props: any) => {
                return (
                  <Text
                    as="span"
                    styleName="code"
                    styles={{
                      display: "block",
                      fontSize: theme.ui2Typography.fontSizeMeta1,
                      textAlign: "right",
                      textRendering: "auto",
                      WebkitFontSmoothing: "auto",
                    }}
                  >
                    {formatIndicatorValue({
                      indicator: indicator,
                      type: "custom-indicator",
                      value: props.getValue(),
                    })}
                  </Text>
                );
              },
            };
            customHeaders[indicator] = header;
          }
        }
      }
    }
  }

  return Object.values(customHeaders);
};

export const getStatisticsHeaders = (
  runs: RunsData,
  theme: any
): HeaderObj[] => {
  if (!runs) {
    return [];
  }

  const statisticsHeaders: HeaderObj[] = [];

  allowedIndicatorKeys.forEach((key) => {
    if (hasAllowedIndicator(key, runs)) {
      const formattedHeaderName = formatGroupedSummaryTableName(key);
      const header = {
        id: key,
        accessorKey: key,
        header: formattedHeaderName,
        label: formattedHeaderName,
        value: formattedHeaderName,
        cell: (props: any) => {
          return (
            <Text
              as="span"
              styleName="code"
              styles={{
                display: "block",
                fontSize: theme.ui2Typography.fontSizeMeta1,
                textAlign: "right",
                textRendering: "auto",
                WebkitFontSmoothing: "auto",
              }}
            >
              {formatIndicatorValue({
                indicator: key,
                type: "single-run-value",
                value: props.getValue(),
              })}
            </Text>
          );
        },
      };
      statisticsHeaders.push(header);
    }
  });

  const customHeaders = getCustomHeaders(runs, theme);

  // add statistics header as first item
  const formattedHeaderName = formatGroupedSummaryTableName(
    KEY_STATISTICS_STATUS
  );
  return [
    {
      id: KEY_STATISTICS_STATUS,
      accessorKey: KEY_STATISTICS_STATUS,
      value: formattedHeaderName,
      header: formattedHeaderName,
      label: formattedHeaderName,
      cell: (props: any) => {
        return (
          <Text
            styles={{
              color: getStatusTextColor({ status: props.getValue(), theme }),
            }}
            as="span"
            styleName="body-3"
          >
            {props.getValue()}
          </Text>
        );
      },
    },
  ].concat(statisticsHeaders, customHeaders);
};
