import React from "react";

import Layout from "../../components/Layout";

import { accountPages } from "./data/pages";
import AccountRoutes from "./Account.routes";

const Account = () => {
  return (
    <Layout sectionTitle="Account" sectionPages={accountPages}>
      <AccountRoutes />
    </Layout>
  );
};

export default Account;
