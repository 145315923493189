import { FooterView } from "../Footer.types";

export const getEntityErrorTestId = (view: FooterView) => {
  switch (view) {
    case "create":
      return "create-entity-error-message";
    case "edit":
      return "edit-entity-error-message";
    case "list":
      return "list-entity-error-message";
    default:
      return undefined;
  }
};

export const getFooterButtonTestId = (
  view: FooterView,
  isSecondary: boolean = false
) => {
  switch (view) {
    case "create":
      return `create-entity-button${isSecondary ? "-secondary" : ""}`;
    case "edit":
      return `edit-entity-button${isSecondary ? "-secondary" : ""}`;
    case "list":
      return `list-entity-button${isSecondary ? "-secondary" : ""}`;
    default:
      return undefined;
  }
};

export const getActionButtonLabel = (view: FooterView) => {
  switch (view) {
    case "create":
      return "Create";
    case "edit":
      return "Save";
    case "list":
      return "Load more";
    default:
      return undefined;
  }
};
