import { SolutionRoute } from "../../../../../components/Map/Map.types";
import { getSolutionRoutes } from "../../../../../components/Map/utils/mapping";

type GetNewSolutionRouteParams = {
  fetchWithAccount: any;
  solutions: any;
  setErrorMessageFailedRoutes: React.Dispatch<React.SetStateAction<string>>;
  setRunSolutionRoute: React.Dispatch<
    React.SetStateAction<SolutionRoute | undefined>
  >;
};

export const getNewSolutionRoute = async ({
  fetchWithAccount,
  solutions,
  setErrorMessageFailedRoutes,
  setRunSolutionRoute,
}: GetNewSolutionRouteParams) => {
  if (!Array.isArray(solutions) || !solutions.length) {
    return;
  }
  try {
    const newSolutionRoute = await getSolutionRoutes(
      solutions[0],
      fetchWithAccount
    );
    setRunSolutionRoute(newSolutionRoute);
    return;
  } catch {
    // setErrorMessageFailedRoutes("An error occurred");
  }
};
