import { Box } from "@console/dsc";
import { styleScrollbarY } from "@console/dsc/src/lib/constants";
import { rem } from "@console/dsc/src/lib/tools";
import styled from "@emotion/styled";

type CustomAceEditorStyledProps = {
  controlEditorModePositionTop?: number;
};
const CustomAceEditorStyled = styled(Box)<CustomAceEditorStyledProps>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  /* target plain wrapping div around .jsoneditor */
  > div {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    height: 100%;
    flex-grow: 1;
    position: relative;
  }

  /* Set all fonts to monospace */
  * {
    font-family: ${({ theme }) => theme.ui2Typography.fontFamilyCode};
  }

  /* editor bounding box */
  .jsoneditor {
    border: 0;
    min-height: 100%;
    height: 100%;
    flex-grow: 1;
    position: relative;
  }
  /* EDITOR MAIN */
  /*
    toolbar
    NOTE: specifically referencing "div" here because the
    dropdown nav that appears when you click the menu
    option to switch editor views also has a class of
    "jsoneditor-menu" but it is a ul tag; so we reference
    that one with the ul tag in the CSS as it needs to be
    styled differently.
  */

  div.jsoneditor-menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: ${rem(36)};
    padding-right: ${({ theme }) => theme.spacing.s2};
    border: 0;
    background-color: transparent;

    /*
      style active editor mode selection
      "div" is specifically called out because there is
      also a direct child of this div.jsoneditor-modes
      that is a button element with the same classname.
    */
    div.jsoneditor-modes {
      display: block;
      float: none;

      button.jsoneditor-modes {
        display: block;
        height: auto;
        margin: 0;
        padding-top: ${rem(6)};
        padding-right: ${({ theme }) => theme.spacing.s2};
        padding-bottom: ${rem(6)};
        padding-left: ${({ theme }) => theme.spacing.s2};
        font-family: ${({ theme }) => theme.ui2Typography.fontFamilyMeta2};
        font-size: ${({ theme }) => theme.ui2Typography.fontSizeMeta2};
        font-weight: ${({ theme }) => theme.ui2Typography.fontWeightMeta2};
        line-height: ${({ theme }) => theme.ui2Typography.lineHeightMeta2};
        letter-spacing: 0;
        text-decoration: none;
        cursor: pointer;
        color: ${({ theme }) => theme.color.gray600};

        &:hover {
          color: ${({ theme }) => theme.color.gray700};
        }
        &:focus-visible {
          color: ${({ theme }) => theme.color.gray700};
          outline: none;
          box-shadow: 0 0 0 ${rem(2)} ${({ theme }) => theme.color.gray300T};
          background-color: ${({ theme }) => theme.color.gray100T};
        }
        &:active {
          color: ${({ theme }) => theme.color.gray800};
        }
      }
    }

    /* style tree view expand/collapse all buttons */
    .jsoneditor-expand-all,
    .jsoneditor-collapse-all {
      width: ${({ theme }) => theme.spacing.s4};
      height: ${({ theme }) => theme.spacing.s4};
    }

    /* style tree view expand/collapse all icons */
    > button.jsoneditor-expand-all,
    > button.jsoneditor-collapse-all {
      cursor: pointer;
      background-position: center;
      background-repeat: no-repeat;
      background-color: transparent;
      opacity: 0.47;
      &:hover,
      &:focus {
        opacity: 0.74;
      }
      &:active {
        opacity: 0.92;
      }
    }

    /* specific icon for tree view expand all */
    > button.jsoneditor-expand-all {
      background-image: url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath d='M11.7167 5.5L7.98333 0.7L4.25 5.5H11.7167Z' fill='%230f1111'/%3e %3cpath d='M11.7167 10.5L7.98333 15.3L4.25 10.5H11.7167Z' fill='%230f1111'/%3e %3cpath d='M15 7.375H1V8.625H15V7.375Z' fill='%230f1111'/%3e %3c/svg%3e");
      background-size: ${({ theme }) => theme.spacing.s4};
    }

    /* specific icon for tree view collapse all */
    > button.jsoneditor-collapse-all {
      background-image: url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath d='M11.7167 1.25L7.98333 6.05L4.25 1.25H11.7167Z' fill='%230f1111'/%3e %3cpath d='M11.7167 14.75L7.98333 9.95L4.25 14.75H11.7167Z' fill='%230f1111'/%3e %3cpath d='M15 7.375H1V8.625H15V7.375Z' fill='%230f1111'/%3e %3c/svg%3e");
      background-size: ${({ theme }) => theme.spacing.s4};
    }

    /* style search bar and related icons */
    .jsoneditor-search {
      display: flex;
      align-items: center;
      margin-right: ${({ theme }) => theme.spacing.s4};
      margin-left: auto;
      position: static;

      /* results found label */
      .jsoneditor-results {
        font-family: ${({ theme }) => theme.ui2Typography.fontFamilyMeta2};
        font-size: ${({ theme }) => theme.ui2Typography.fontSizeMeta2};
        font-weight: ${({ theme }) => theme.ui2Typography.fontWeightMeta2};
        line-height: ${({ theme }) => theme.ui2Typography.lineHeightMeta2};
        color: ${({ theme }) => theme.color.gray500};
      }

      /* search and icons wrapper */
      .jsoneditor-frame {
        display: flex;
        align-items: center;
      }
      /* search text */
      input[type="text"] {
        font-family: ${({ theme }) => theme.ui2Typography.fontFamilyMeta2};
        font-size: ${({ theme }) => theme.ui2Typography.fontSizeMeta2};
        font-weight: ${({ theme }) => theme.ui2Typography.fontWeightMeta2};
        line-height: ${({ theme }) => theme.ui2Typography.lineHeightMeta2};
        color: ${({ theme }) => theme.color.gray700};
      }

      /* refresh (search) button */
      button.jsoneditor-refresh {
        background-position: center;
        background-repeat: no-repeat;
        background-color: transparent;
        background-image: url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.4033 6.93333C10.4033 8.84976 8.84976 10.4033 6.93333 10.4033C5.0169 10.4033 3.46333 8.84976 3.46333 6.93333C3.46333 5.01691 5.0169 3.46333 6.93333 3.46333C8.84976 3.46333 10.4033 5.01691 10.4033 6.93333ZM9.82468 10.7651C9.02051 11.3729 8.01901 11.7333 6.93333 11.7333C4.28236 11.7333 2.13333 9.5843 2.13333 6.93333C2.13333 4.28237 4.28236 2.13333 6.93333 2.13333C9.5843 2.13333 11.7333 4.28237 11.7333 6.93333C11.7333 8.01902 11.3729 9.02051 10.7651 9.82468L13.8036 12.8631L14.2738 13.3333L13.3333 14.2738L12.8631 13.8036L9.82468 10.7651Z' fill='%23889091'/%3e %3c/svg%3e");
        background-size: ${({ theme }) => theme.spacing.s4};
      }

      /* next/previous buttons */
      button.jsoneditor-next,
      button.jsoneditor-previous {
        background-position: center;
        background-repeat: no-repeat;
        background-color: transparent;
        opacity: 0.47;

        &:hover,
        &:focus {
          opacity: 0.74;
        }
        &:active {
          opacity: 0.92;
        }
      }
      /* next (down) button */
      button.jsoneditor-next {
        background-image: url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 2.00167C8.36727 2.00167 8.665 2.2994 8.665 2.66667L8.665 11.7279L11.7964 8.59644C12.0561 8.33674 12.4772 8.33674 12.7369 8.59644C12.9966 8.85614 12.9966 9.27719 12.7369 9.53689L8.47022 13.8036C8.34551 13.9283 8.17636 13.9983 8 13.9983C7.82363 13.9983 7.65448 13.9283 7.52977 13.8036L3.2631 9.53689C3.00341 9.27719 3.00341 8.85614 3.2631 8.59644C3.5228 8.33674 3.94386 8.33674 4.20356 8.59644L7.335 11.7279L7.335 2.66667C7.335 2.2994 7.63273 2.00167 8 2.00167Z' fill='%230f1111'/%3e %3c/svg%3e");
        background-size: ${({ theme }) => theme.spacing.s4};
      }

      /* previous (up) button */
      button.jsoneditor-previous {
        background-image: url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.52978 2.19644C7.78948 1.93674 8.21053 1.93674 8.47023 2.19644L12.7369 6.46311C12.9966 6.72281 12.9966 7.14386 12.7369 7.40356C12.4772 7.66326 12.0561 7.66326 11.7964 7.40356L8.665 4.27212L8.665 13.3333C8.665 13.7006 8.36727 13.9983 8 13.9983C7.63274 13.9983 7.335 13.7006 7.335 13.3333L7.335 4.27212L4.20356 7.40356C3.94386 7.66326 3.52281 7.66326 3.26311 7.40356C3.00341 7.14386 3.00341 6.72281 3.26311 6.46311L7.52978 2.19644Z' fill='%230f1111'/%3e %3c/svg%3e");
        background-size: ${({ theme }) => theme.spacing.s4};
      }
    }

    /* make code/tree view selector first */
    div.jsoneditor-modes {
      order: 0;
    }
    .jsoneditor-expand-all,
    .jsoneditor-collapse-all,
    .jsoneditor-search {
      order: 1;
    }

    /* hide format JSON button */
    .jsoneditor-format {
      display: none;
    }

    /* hide compact JSON button */
    .jsoneditor-compact {
      display: none;
    }

    /* hide sort button */
    .jsoneditor-sort {
      display: none;
    }

    /* hide filter, sort, transform button */
    .jsoneditor-transform {
      display: none;
    }

    /* hide repair button */
    .jsoneditor-repair {
      display: none;
    }

    /* hide undo/redo buttons */
    .jsoneditor-undo,
    .jsoneditor-redo {
      display: none;
    }

    /* remove powered by link */
    .jsoneditor-poweredBy {
      display: none;
    }
  }
  /* end div.jsoneditor-menu */

  /* style differently in code mode */
  .jsoneditor-mode-code div.jsoneditor-menu {
    position: absolute;
    width: auto;
    top: ${(props) => rem(props.controlEditorModePositionTop || -54)};
    right: 0;
  }

  /*
    adjust z-index of root element for editor view switch
    menu so it appears over line number column
  */
  .jsoneditor-contextmenu {
    z-index: 11;
  }

  /* special case to move inside visible frame */
  .jsoneditor-mode-code .jsoneditor-contextmenu {
    transform: translateX(-50%);
  }

  /*
    menu to switch editor view modes (JSON/Tree)
    matches Breadcrumb dropdown panel style
  */
  ul.jsoneditor-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: ${({ theme }) => theme.spacing.s11};
    padding-top: calc(${({ theme }) => theme.spacing.s2} - ${rem(2)});
    padding-right: calc(${({ theme }) => theme.spacing.s2} + ${rem(1)});
    padding-bottom: calc(${({ theme }) => theme.spacing.s2} - ${rem(1)});
    padding-left: calc(${({ theme }) => theme.spacing.s2} + ${rem(1)});
    list-style: none;
    border: 0;
    border-radius: ${({ theme }) => theme.border.radiusSmall};
    background-color: ${({ theme }) => theme.color.white};
    box-shadow: 0 0 0 ${rem(1)} ${({ theme }) => theme.color.gray200T};
    z-index: 999;

    li {
      position: relative;
      margin-top: ${({ theme }) => theme.spacing.s1};

      /*
        this would be first-of-type but there's an empty
        li element in the list which is throwing off the
        logic, hence starting with the second (and the
        first so that the hidden li does not take on any
        margin)
      */
      &:first-of-type,
      &:nth-of-type(2) {
        margin-top: 0;
      }

      /*
        menu option (button element within the li)
        overriding some styles, adding some new
      */
      .jsoneditor-type-modes {
        position: relative;
        padding: 0;
        width: ${({ theme }) => theme.spacing.s11};
        font-family: ${({ theme }) => theme.ui2Typography.fontFamilyMeta2};
        font-size: ${({ theme }) => theme.ui2Typography.fontSizeMeta2};
        font-weight: ${({ theme }) => theme.ui2Typography.fontWeightMeta2};
        line-height: ${({ theme }) => theme.ui2Typography.lineHeightMeta2};
        color: ${({ theme }) => theme.color.gray600};

        &:hover {
          color: ${({ theme }) => theme.color.gray800};
          background-color: transparent;
        }
        &:focus-visible {
          color: ${({ theme }) => theme.color.gray800};
          outline: none;
          box-shadow: 0 0 0 ${rem(2)} ${({ theme }) => theme.color.gray300T};
          background-color: ${({ theme }) => theme.color.gray100T};
        }
        &:active {
          color: ${({ theme }) => theme.color.gray800};
          background-color: transparent;
        }

        .jsoneditor-text {
          font-family: ${({ theme }) => theme.ui2Typography.fontFamilyMeta2};
        }
      }

      /* selected option */
      .jsoneditor-selected {
        padding-left: calc(${({ theme }) => theme.spacing.s4} + ${rem(1)});
        color: ${({ theme }) => theme.color.gray800};
        background-position: left center;
        background-repeat: no-repeat;
        background-color: transparent;
        background-image: url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.7419 4.0102C12.0308 4.1991 12.1119 4.58643 11.923 4.87534L7.67301 11.3753C7.57285 11.5285 7.41059 11.6301 7.22904 11.6533C7.04749 11.6764 6.86491 11.6189 6.72949 11.4958L3.97949 8.99577C3.72408 8.76358 3.70525 8.3683 3.93744 8.11289C4.16964 7.85747 4.56492 7.83865 4.82033 8.07084L7.02794 10.0778L10.8768 4.19127C11.0657 3.90237 11.453 3.8213 11.7419 4.0102Z' fill='%231F2222'/%3e %3c/svg%3e");
        background-size: ${({ theme }) => theme.spacing.s4};

        .jsoneditor-text {
          font-family: ${({ theme }) => theme.ui2Typography.fontFamilyMeta2};
        }
      }

      /* reset menu item text wrapper CSS */
      .jsoneditor-text {
        padding: 0;
      }
      /* hide icon next to menu option */
      .jsoneditor-icon {
        display: none;
      }
    }
  }

  /* bottom status bar */
  .jsoneditor-statusbar {
    display: flex;
    align-items: center;
    width: 100%;
    height: ${({ theme }) => theme.spacing.s7};
    padding-left: ${({ theme }) => theme.spacing.s4};
    position: absolute;
    bottom: 0;
    font-family: ${({ theme }) => theme.ui2Typography.fontFamilyMeta2};
    font-size: ${({ theme }) => theme.ui2Typography.fontSizeMeta2};
    font-weight: ${({ theme }) => theme.ui2Typography.fontWeightMeta2};
    line-height: ${({ theme }) => theme.ui2Typography.lineHeightMeta2};
    color: ${({ theme }) => theme.color.gray600};
    border-top: 0;
    border-radius: ${({ theme }) => theme.border.radius};
    background-color: ${({ theme }) => theme.color.gray100};
    z-index: 11;
  }

  /* JSON VIEW */
  /* line numbers (matching CSS specificity for overrides) */
  .ace_editor {
    /* scrollbar */
    .ace_scrollbar-v {
      ${({ theme }) => styleScrollbarY(theme)}
    }

    /* number container */
    .ace_gutter {
      background-color: ${({ theme }) => theme.color.white};
    }

    /* individual line number */
    .ace_gutter-cell {
      padding-left: ${({ theme }) => theme.spacing.s4};
      font-family: ${({ theme }) => theme.ui2Typography.fontFamilyCode};
      font-size: ${({ theme }) => theme.ui2Typography.fontSizeCode};
      font-weight: ${({ theme }) => theme.ui2Typography.fontWeightCode};
      line-height: ${rem(19)};
      color: ${({ theme }) => theme.color.gray500};
    }

    /* expand/collapse icon for JSON code stanzas */
    .ace_fold-widget {
      border: 0;
      opacity: 0.47;
      &:hover,
      &:focus {
        background-color: transparent;
        box-shadow: 0;
        opacity: 0.92;
      }
      &:active {
        opacity: 0.92;
      }
      &:focus-visible {
        box-shadow: 0 0 0 ${rem(1)} ${({ theme }) => theme.color.gray200};
      }
    }
    .ace_fold-widget.ace_open {
      background-position: left ${rem(1)} top ${rem(2)};
      background-repeat: no-repeat;
      background-color: transparent;
      background-image: url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath d='M4.26667 6.4H11.7333L8 11.2L4.26667 6.4Z' fill='%230f1111'/%3e %3c/svg%3e");
      background-size: ${({ theme }) => theme.spacing.s3};
    }
    .ace_fold-widget.ace_closed {
      background-position: left ${rem(1)} top ${rem(3)};
      background-repeat: no-repeat;
      background-color: transparent;
      background-image: url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath d='M6.4 11.7333L6.4 4.26667L11.2 8L6.4 11.7333Z' fill='%230f1111'/%3e %3c/svg%3e");
      background-size: ${({ theme }) => theme.spacing.s3};
    }

    /* active line */
    .ace_gutter-active-line {
      background-color: transparent;
    }
  }

  /* TREE VIEW */
  /*
    remove tree view option menu
  - this is the menu that allows you to transform types,
    sort, extract, duplicate, etc. some of the
    functionality is helpful, but other parts make it
    easy to create invalid input schema
  - also, it is a bit clunky, so in the general theme
    of slimming down the editor, this portion of
    functionality has been cut for now
  - the direct selector (>) is to avoid unintentionally
    hiding child td's in child table elements of the
    top-level td's
  */
  table.jsoneditor-tree > tbody > tr > td:first-of-type,
  table.jsoneditor-tree > tbody > tr > td:nth-of-type(2) {
    display: none;
  }

  /* custom styling for tree view navigator */
  .jsoneditor-navigation-bar {
    height: ${({ theme }) => theme.spacing.s7};
    padding-left: ${({ theme }) => theme.spacing.s2};
    border-bottom-width: ${({ theme }) => theme.border.width};
    border-bottom-style: ${({ theme }) => theme.border.style};
    border-bottom-color: ${({ theme }) => theme.color.gray200};
    background-color: ${({ theme }) => theme.color.white};
  }
  .jsoneditor-treepath {
    display: flex;
    align-items: center;
    height: ${({ theme }) => theme.spacing.s7};
    padding: 0;
    font-family: ${({ theme }) => theme.ui2Typography.fontFamilyMeta2};
    font-size: ${({ theme }) => theme.ui2Typography.fontSizeMeta2};
    font-weight: ${({ theme }) => theme.ui2Typography.fontWeightMeta2};
    line-height: ${({ theme }) => theme.ui2Typography.lineHeightMeta2};
  }
  .jsoneditor-treepath-element {
    margin: 0;
    font-family: ${({ theme }) => theme.ui2Typography.fontFamilyMeta2};
    font-size: ${({ theme }) => theme.ui2Typography.fontSizeMeta2};
    font-weight: ${({ theme }) => theme.ui2Typography.fontWeightMeta2};
    line-height: ${({ theme }) => theme.ui2Typography.lineHeightMeta2};
  }
  .jsoneditor-treepath .jsoneditor-treepath-seperator {
    margin: 0;

    /* hide text icon character */
    display: block;
    overflow: hidden;
    text-indent: -9999px;

    /* implement same icon from Breadcrumbs component */
    padding-right: calc(${({ theme }) => theme.spacing.s4} + ${rem(2)});
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath d='M6.4 11.7333L6.4 4.26667L11.2 8L6.4 11.7333Z' fill='%23889091'/%3e %3c/svg%3e");
    background-size: ${({ theme }) => theme.spacing.s4};
  }

  /* extra spacing at top of tree view */
  .jsoneditor-tree-inner {
    padding-top: ${({ theme }) => theme.spacing.s2};
  }

  /* add border around search input and icons */
  .jsoneditor-search .jsoneditor-frame {
    border-width: ${({ theme }) => theme.border.width};
    border-style: ${({ theme }) => theme.border.style};
    border-color: ${({ theme }) => theme.color.gray300};
    border-radius: ${({ theme }) => theme.border.radiusSmall};
  }
  .jsoneditor-search .jsoneditor-frame input {
    border: 0;
  }

  /*
    rules needed to make the tree view stay within
    the bounding editor box with overflow-y scroll
  */
  /*
    menu needs to be a step higher than its peer
    naigation-bar so that the pop-over menu appears
    above it
  */
  div.jsoneditor-menu {
    position: relative;
    z-index: 2;
  }
  .jsoneditor-navigation-bar {
    position: relative;
    z-index: 1;
  }
  /*
    this div sits above the menu and navigation bar
    in the DOM and uses a negative top margin and
    matching top padding to provide space for them
    to sit directly above visually. we need to set
    the positioning for this div to absolute so
    that it does not continue to expand to
    accommodate its child contents but adheres to
    the 100% height of its parent (which is filling
    the alloted space). then the overflow of its
    child element .jsoneditor-tree is what triggers
    the scrolling. all that to say when we set the
    position as absolute we need to set the z-index
    to be lower than the .jsoneditor-menu and
    .jsoneditor-navigation-bar that sits on top of
    it visually.
  */
  .jsoneditor-outer {
    position: absolute;
    z-index: 0;
    margin-top: 0 !important;
    /*margin-bottom: 0 !important;*/
    padding-top: 0 !important;
    /*padding-bottom: 0 !important;*/
  }
  /*
    contents of tree view that scroll, specified
    with table as td elements within the table also
    have class of jsoneditor-tree
  */
  table.jsoneditor-tree {
    overflow-y: scroll;
  }

  /* change expand/collapse icon for editor content */
  .jsoneditor-button.jsoneditor-collapsed {
    background-position: left ${rem(2)} top ${rem(2)};
    background-repeat: no-repeat;
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath d='M6.4 11.7333L6.4 4.26667L11.2 8L6.4 11.7333Z' fill='%23889091'/%3e %3c/svg%3e");
    background-size: ${({ theme }) => theme.spacing.s5};
  }
  .jsoneditor-button.jsoneditor-expanded {
    background-position: left ${rem(3)} top ${rem(1)};
    background-repeat: no-repeat;
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath d='M4.26667 6.4H11.7333L8 11.2L4.26667 6.4Z' fill='%23889091'/%3e %3c/svg%3e");
    background-size: ${({ theme }) => theme.spacing.s5};
  }

  /* code content */
  .ace_content {
    border-right: 0;
  }
`;

export default CustomAceEditorStyled;
