import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Button2,
  Flex,
  IconCaretY,
  IconChevronRight,
  Input,
  Tag,
  Text,
  Tooltip,
} from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";

import { useUser } from "../../AuthProvider";
import { getBannerType } from "../Banner/utils/getBannerType";
import ButtonRefresh from "../ButtonRefresh";
import SectionTitle from "../SectionTitle";

import {
  StyledHeader,
  StyledPageSwitcherContainer,
  StyledPageSwitcherItem,
  StyledPageSwitcherLink,
  StyledPageSwitcherList,
} from "./Header.styled";
import { HeaderButton, HeaderProps, PageSwitchOption } from "./Header.types";

const Header = ({
  configActionButton,
  configFilter,
  configPageTitle,
  isActionButtonDisabled,
  isActionButtonLoading,
  isExpanded,
  isSecondaryButtonDisabled,
  isSecondaryButtonLoading,
  isShortenAncestorTitle,
  refreshButton,
  secondaryButton,
  switchOptions,
  topNavExtra,
}: HeaderProps) => {
  const theme = useTheme();
  const [user] = useUser();
  const bannerType = getBannerType(user);
  const [isPageSwitcherActive, setIsPageSwitcherActive] = useState(false);

  const handlePageSwitcherDisplay = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.nativeEvent.stopImmediatePropagation();
    e.preventDefault();
    return setIsPageSwitcherActive(!isPageSwitcherActive);
  };

  const renderPageSwitcher = (options: PageSwitchOption[]) => {
    return (
      <StyledPageSwitcherContainer
        onClick={(e) => handlePageSwitcherDisplay(e)}
      >
        <IconCaretY iconSize={20} iconColor={theme.color.gray600} />

        <StyledPageSwitcherList
          id="page-switcher"
          isActive={isPageSwitcherActive}
        >
          {options.map((option) => (
            <StyledPageSwitcherItem key={option.id}>
              <StyledPageSwitcherLink
                to={option.url}
                onClick={() => setIsPageSwitcherActive(!isPageSwitcherActive)}
              >
                {option.label}
              </StyledPageSwitcherLink>
            </StyledPageSwitcherItem>
          ))}
        </StyledPageSwitcherList>
      </StyledPageSwitcherContainer>
    );
  };

  const getButtonData = (headerButton: HeaderButton) => {
    const filteredOptions = Array.isArray(headerButton)
      ? headerButton.filter((option) => option?.isActionAllowed)
      : [headerButton];

    if (!filteredOptions.length) return null;

    return filteredOptions;
  };

  const renderActionButton = (actionButton: HeaderButton) => {
    const buttonData = getButtonData(actionButton);

    if (!buttonData || !buttonData?.length) return;

    const commonActionButtonProps = {
      "data-testid": "header-button-one",
      ml: 2,
      isDisabled: isActionButtonDisabled,
      isLoading: isActionButtonLoading,
    };

    if (buttonData.length > 1) {
      return (
        <Button2 {...commonActionButtonProps} multiSelectOptions={buttonData} />
      );
    } else {
      const buttonDatum = buttonData[0];
      if (!buttonDatum) return;

      return (
        <Button2
          {...commonActionButtonProps}
          label={buttonDatum.label}
          to={!buttonDatum.url || isActionButtonDisabled ? "" : buttonDatum.url}
          onClick={buttonDatum?.onClick}
        />
      );
    }
  };

  const renderSecondaryButton = (secondaryButton: HeaderButton) => {
    const buttonData = getButtonData(secondaryButton);

    if (!buttonData || !buttonData?.length) return;

    const commonSecondaryButtonProps = {
      "data-testid": "header-button-two",
      ml: [configFilter ? 2 : 0, configFilter ? 2 : 0, 2],
      type: "outline",
      isDisabled: isSecondaryButtonDisabled,
      isLoading: isSecondaryButtonLoading,
    };

    if (buttonData.length > 1) {
      return (
        <Button2
          {...commonSecondaryButtonProps}
          multiSelectOptions={buttonData}
        />
      );
    } else {
      const buttonDatum = buttonData[0];
      if (!buttonDatum) return;
      return (
        <Button2
          {...commonSecondaryButtonProps}
          onClick={buttonDatum?.onClick}
          label={buttonDatum.label}
          to={!buttonDatum.url || isActionButtonDisabled ? "" : buttonDatum.url}
        />
      );
    }
  };

  const hasConfigActionButton =
    !!configActionButton &&
    (Array.isArray(configActionButton)
      ? !configActionButton.every((option) => !option?.isActionAllowed)
      : configActionButton.isActionAllowed);
  const hasSecondaryButton =
    !!secondaryButton &&
    (Array.isArray(secondaryButton)
      ? !secondaryButton.every((option) => !option?.isActionAllowed)
      : secondaryButton.isActionAllowed);

  return (
    <StyledHeader
      {...{ isExpanded }}
      hasBanner={!!bannerType}
      flexGrow={1}
      alignItems={["flex-start", "center"]}
      flexDirection={["column", "row"]}
      pt={5}
      pb={[4, 5]}
      pl={[
        isExpanded ? 4 : 0,
        isExpanded ? 4 : 0,
        isExpanded ? 4 : 0,
        isExpanded ? 6 : 0,
      ]}
      pr={[
        isExpanded ? 4 : 0,
        isExpanded ? 4 : 0,
        isExpanded ? 4 : 0,
        isExpanded ? 7 : 0,
      ]}
      mr={[
        isExpanded ? -4 : 0,
        isExpanded ? -4 : 0,
        isExpanded ? -4 : 0,
        isExpanded ? -7 : 0,
      ]}
    >
      <Flex
        alignItems={["flex-start", "flex-start", "flex-start", "center"]}
        flexDirection={["column", "column", "column", "row"]}
        flexShrink={0}
      >
        {configPageTitle && (
          <Flex
            flexDirection="row"
            alignItems="center"
            flexShrink={[1, 1, 1, 0]}
          >
            {configPageTitle?.ancestorLabel && configPageTitle?.ancestorUrl && (
              <Flex
                flexShrink={[1, 1, 1, 0]}
                alignItems="center"
                mt={-1}
                mb={-2}
              >
                {configPageTitle?.ancestorIcon && (
                  <Flex
                    mb={rem(2)}
                    mr={2}
                    minHeight={theme.spacing.s7}
                    alignItems="center"
                  >
                    <Flex mt={rem(-1)}>{configPageTitle.ancestorIcon}</Flex>
                  </Flex>
                )}

                <Text
                  as="h3"
                  mt={configPageTitle?.ancestorIcon ? -1 : 0}
                  styleName="header-1"
                  styles={{
                    color: theme.color.gray800,
                    a: { color: theme.color.gray800, textDecoration: "none" },
                    ...(isShortenAncestorTitle && {
                      maxWidth: "300px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }),
                  }}
                >
                  <Link to={configPageTitle.ancestorUrl}>
                    {configPageTitle.ancestorLabel}
                  </Link>
                </Text>

                <Flex alignItems="center" mr={1} ml={1} mb={1}>
                  <IconChevronRight />
                </Flex>
              </Flex>
            )}
            {configPageTitle?.parentLabel && configPageTitle?.parentUrl && (
              <Flex alignItems="center" flexShrink={[1, 1, 1, 0]}>
                <Text
                  styleName="header-1"
                  styles={{
                    color: theme.color.gray800,
                    a: { color: theme.color.gray800, textDecoration: "none" },
                  }}
                >
                  <Link to={configPageTitle.parentUrl}>
                    {configPageTitle.parentLabel}
                  </Link>
                </Text>

                <Flex alignItems="center" mr={1} ml={1}>
                  <IconChevronRight />
                </Flex>
              </Flex>
            )}
            <Flex alignItems="center" flexShrink={[1, 1, 1, 0]}>
              {configPageTitle?.icon && (
                <Box mb={rem(-1)} mr={3}>
                  {configPageTitle.icon}
                </Box>
              )}

              {switchOptions &&
                !!switchOptions.length &&
                renderPageSwitcher(switchOptions)}

              <SectionTitle
                testId="header-section-title"
                isShortenTitle={
                  !!(
                    configPageTitle.parentLabel || configPageTitle.ancestorLabel
                  )
                }
              >
                {configPageTitle.label}
              </SectionTitle>

              {configPageTitle.tooltipContent && (
                <Box ml={1}>
                  <Tooltip
                    children={configPageTitle.tooltipContent}
                    extraLinkLabel={configPageTitle.tooltipExtraLinkLabel}
                    extraLinkUrl={configPageTitle.tooltipExtraLinkUrl}
                  />
                </Box>
              )}
            </Flex>
            {configPageTitle?.isEarlyAccess && (
              <Tag
                mt={-2}
                ml={[0, 0, 0, 2]}
                order={[0, 0, 0, 1]}
                type="early-access"
              />
            )}
          </Flex>
        )}

        {topNavExtra && (
          <Box
            flexShrink={0}
            mt={[2, 2, 2, 0]}
            ml={[isExpanded ? 8 : 0, isExpanded ? 8 : 0, isExpanded ? 8 : 0, 7]}
          >
            {topNavExtra}
          </Box>
        )}
      </Flex>

      {(configFilter ||
        hasConfigActionButton ||
        hasSecondaryButton ||
        refreshButton) && (
        <Flex
          width={["100%", configFilter ? "100%" : "auto"]}
          mt={[3, -2]}
          mb={[0, -2]}
          ml={[isExpanded ? 8 : 0, "auto"]}
          pl={[0, configFilter ? 4 : 0]}
          pt={rem(2)}
          justifyContent="space-between"
        >
          {configFilter && (
            <Box width={["100%", rem(200)]}>
              <Input
                data-testid={
                  configFilter.testId ? configFilter.testId : undefined
                }
                htmlType="text"
                size="small"
                placeholder="Filter"
                value={configFilter.inputText}
                onChange={(e: { target: { value: string } }) => {
                  return configFilter.setInputText(e.target.value);
                }}
              />
            </Box>
          )}

          {(hasConfigActionButton || hasSecondaryButton || refreshButton) && (
            <Flex
              flexShrink={0}
              ml={[
                configFilter ? 2 : 0,
                configFilter ? 2 : 0,
                configFilter ? 2 : 0,
                2,
              ]}
            >
              {refreshButton && (
                <ButtonRefresh
                  emptyItems={refreshButton.emptyItems}
                  itemsLoading={refreshButton.itemsLoading}
                  loadItems={refreshButton.loadItems}
                  setItems={refreshButton.setItems}
                />
              )}

              {hasSecondaryButton && renderSecondaryButton(secondaryButton)}
              {hasConfigActionButton && renderActionButton(configActionButton)}
            </Flex>
          )}
        </Flex>
      )}
    </StyledHeader>
  );
};

export default Header;
