import { RouteComponentProps } from "react-router-dom";

type SwitchToOrgParams = {
  e: { preventDefault: () => void };
  history: RouteComponentProps["history"];
  isCurrentTeam: boolean;
  teamId: string;
  setIsOrgSwitcherActive?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const switchToOrg = ({
  e,
  isCurrentTeam,
  history,
  setIsOrgSwitcherActive,
  teamId,
}: SwitchToOrgParams) => {
  e.preventDefault();

  if (setIsOrgSwitcherActive && isCurrentTeam) {
    return setIsOrgSwitcherActive(false);
  }

  history.push(`/acc/${teamId}/dashboard`);

  setIsOrgSwitcherActive && setIsOrgSwitcherActive(false);

  return;
};
