import { ParsedFeatureFlags } from "../../../api/core/dataPlane.types";
import { MainNavPageType } from "../MainNav.types";

export const getUrlBase = (accId?: string | null) =>
  accId ? `/acc/${accId}` : "";

export const getMainNavPages = (
  accId?: string | null,
  features?: ParsedFeatureFlags
): MainNavPageType[] => {
  if (!accId) {
    return [];
  }
  const urlBase = `/acc/${accId}`;

  return [
    {
      id: "dashboard",
      label: "Dashboard",
      url: `${urlBase}/dashboard`,
    },
    {
      id: "apps",
      label: "Apps",
      url: `${urlBase}/apps`,
    },
    {
      id: "marketplace",
      label: "Marketplace",
      url: `${urlBase}/marketplace`,
    },
    {
      id: "team",
      label: "Team",
      url: `${urlBase}/team`,
    },
  ];
};
