import { rem } from "@console/dsc/src/lib/tools";
import { DateTime } from "luxon";

import {
  RunOutputScheduling,
  WorkerToSchedule,
  WorkerToScheduleAvailability,
} from "../RunDetails.types";

import {
  MILLISECOND_HOUR,
  WORKER_AVAILABILITY_HOUR_WIDTH,
  WORKER_AVAILABILITY_ROW_HEIGHT,
  WORKER_AVAILABILITY_ROW_SPACE,
} from "./constants";
import { getOnTheHourDateTimeStart } from "./renderWorkerData";

type GetStartEndRangeParams = {
  entities: WorkerToScheduleAvailability[];
  rangeMinTime?: string;
  rangeMaxTime?: string;
};
export const getStartEndRange = ({
  entities,
  rangeMinTime = "",
  rangeMaxTime = "",
}: GetStartEndRangeParams): [string, string] | undefined => {
  if (!Array.isArray(entities) || !entities.length) return;

  entities.forEach((entity) => {
    const entityStart = entity?.start || entity?.start_time;
    const entityEnd = entity?.end || entity?.end_time;

    if (!rangeMinTime) rangeMinTime = entityStart || "";
    if (!rangeMaxTime) rangeMaxTime = entityEnd || "";

    if (entityStart && entityStart < rangeMinTime) {
      rangeMinTime = entityStart;
    }
    if (entityEnd && entityEnd > rangeMaxTime) {
      rangeMaxTime = entityEnd;
    }
  });

  // incorrect schema could lead to this result
  if (!rangeMinTime || !rangeMaxTime) return;

  return [rangeMinTime, rangeMaxTime];
};

export const getWorkersRange = (
  workers?: WorkerToSchedule[]
): [string, string] | undefined => {
  if (!Array.isArray(workers) || !workers.length) return;

  let rangeMinTime: string = "";
  let rangeMaxTime: string = "";

  workers.forEach((worker) => {
    worker.availability.forEach((workerAvailability) => {
      const workerStart = DateTime.fromISO(
        workerAvailability?.start || workerAvailability?.start_time || ""
      ).toISO();
      const workerEnd = DateTime.fromISO(
        workerAvailability?.end || workerAvailability?.end_time || ""
      ).toISO();

      if (!rangeMinTime) rangeMinTime = workerStart || "";
      if (!rangeMaxTime) rangeMaxTime = workerEnd || "";

      if (workerStart && workerStart < rangeMinTime) {
        rangeMinTime = workerStart;
      }
      if (workerEnd && workerEnd > rangeMaxTime) {
        rangeMaxTime = workerEnd;
      }
    });
  });

  // incorrect schema could lead to this result
  if (!rangeMinTime || !rangeMaxTime) return;

  return [rangeMinTime, rangeMaxTime];
};

export type VizHourRange = {
  startTime: string;
  endTime: string;
  hourBlockCount: number;
};
export const getVizHourRange = (
  range1?: [string, string],
  range2?: [string, string]
): VizHourRange | undefined => {
  let rangeMinTime = "";
  let rangeMaxTime = "";

  if (!range1 && !range2) return;

  if (range1 && range2) {
    rangeMinTime = range1[0] < range2[0] ? range1[0] : range2[0];
    rangeMaxTime = range1[1] > range2[1] ? range1[1] : range2[1];
  } else {
    rangeMinTime =
      (range1?.length ? range1[0] : "") || (range2?.length ? range2[0] : "");
    rangeMaxTime =
      (range1?.length ? range1[1] : "") || (range2?.length ? range2[1] : "");
  }

  if (!rangeMinTime || !rangeMaxTime) return;

  const rangeMinTimeMS = DateTime.fromISO(rangeMinTime).toMillis();
  const rangeMaxTimeMS = DateTime.fromISO(rangeMaxTime).toMillis();
  const totalHours = (rangeMaxTimeMS - rangeMinTimeMS) / MILLISECOND_HOUR;

  return {
    startTime: rangeMinTime,
    endTime: rangeMaxTime,
    hourBlockCount: totalHours,
  };
};

export const getPanelHeight = (workerCount: number): string => {
  const HOUR_PANEL_DETAILS = rem(46);

  return `max(
    100%,
    calc(
      ${HOUR_PANEL_DETAILS} +
      ${rem(workerCount * WORKER_AVAILABILITY_ROW_HEIGHT)} +
      ${rem((workerCount - 1) * WORKER_AVAILABILITY_ROW_SPACE)}
    )
  )`;
};

export const getWorkerRequiredPeriodHeight = (
  workerCount: number,
  additionalHeight: number = 0
): number => {
  const totalHeight =
    workerCount * WORKER_AVAILABILITY_ROW_HEIGHT +
    (workerCount - 1) * WORKER_AVAILABILITY_ROW_SPACE +
    additionalHeight;

  return Math.max(totalHeight, WORKER_AVAILABILITY_ROW_HEIGHT);
};

export const getPeriodWidth = (
  period: WorkerToScheduleAvailability,
  additionalWidth: number = 0
) => {
  const startMS = DateTime.fromISO(
    period?.start || period?.start_time || ""
  ).toMillis();
  const endMS = DateTime.fromISO(
    period?.end || period?.end_time || ""
  ).toMillis();

  return (
    ((endMS - startMS) / MILLISECOND_HOUR) * WORKER_AVAILABILITY_HOUR_WIDTH +
    additionalWidth
  );
};
export const getPeriodPosition = (
  period: WorkerToScheduleAvailability,
  hourRange?: VizHourRange
) => {
  if (!hourRange) return 0;
  const dateTimeStart = getOnTheHourDateTimeStart(hourRange.startTime);

  const frameStartMS = dateTimeStart.toMillis();
  const periodStartMS = DateTime.fromISO(
    period?.start || period?.start_time || ""
  ).toMillis();

  return (
    ((periodStartMS - frameStartMS) / MILLISECOND_HOUR) *
    WORKER_AVAILABILITY_HOUR_WIDTH
  );
};

type GetAssignedWorkerPositionTopParams = {
  workerId: string;
  workers?: WorkerToSchedule[];
};
export const getAssignedWorkerPositionTop = ({
  workers,
  workerId,
}: GetAssignedWorkerPositionTopParams) => {
  return workers ? workers.findIndex((worker) => worker.id === workerId) : 0;
};

const getWorkerColorArray = (theme: any) => {
  return [
    theme.color.chartDenim,
    theme.color.chartOrange,
    theme.color.chartRed,
    theme.color.chartPowderBlue,
    theme.color.chartGreen,
    theme.color.chartMaude,
    theme.color.chartPink,
    theme.color.chartStone,
    theme.color.chartPurple,
    theme.color.chartLime,
    theme.color.chartBrown,
    theme.color.chartBlue,
    theme.color.chartLavendar,
    theme.color.chartBrick,
    theme.color.chartApricot,
    theme.color.chartMoss,
    theme.color.chartTaupe,
    theme.color.chartBerry,
    theme.color.chartCornflower,
  ];
};

type GetWorkerColorsParams = {
  theme: any;
  workers?: WorkerToSchedule[];
};
export const getWorkerColors = ({
  theme,
  workers,
}: GetWorkerColorsParams): { [key: string]: string } | undefined => {
  if (!workers) return;

  const colorArray = getWorkerColorArray(theme);

  let workerColors = {} as { [key: string]: string };
  workers.forEach((worker, i) => {
    workerColors[worker.id] = getWorkerStyleColor(i, colorArray);
  });

  return workerColors;
};

const getWorkerStyleColor = (i: number, colors: string[]): string => {
  if (i >= colors.length) {
    return getWorkerStyleColor(i - colors.length, colors);
  }
  return colors[i];
};

export const getAssignedWorkerShifts = (output: RunOutputScheduling) => {
  if (!output?.solutions.length) return [];
  return output?.solutions[0].assigned_shifts;
};
