import { isObject } from "lodash";
import { DateTime } from "luxon";

import {
  RequiredWorkerShift,
  WorkerToSchedule,
} from "../subpages/RunDetails/RunDetails.types";

export const hasShiftSchedulingSchemaTopLevel = (input: any) => {
  if (!isObject(input)) return false;

  if (
    input.hasOwnProperty("workers") &&
    input.hasOwnProperty("required_workers")
  ) {
    return true;
  }

  return false;
};

const isValidAssignedShift = (assignedShift: { [key: string]: any }) => {
  if (
    typeof assignedShift?.worker_id !== "string" ||
    typeof assignedShift?.start !== "string" ||
    typeof assignedShift?.end !== "string"
  ) {
    return false;
  }
  return true;
};
export const isValidAssignedShifts = (assignedShifts: any) => {
  if (!Array.isArray(assignedShifts) && assignedShifts !== null) return false;

  // no solution found
  if (assignedShifts === null) return true;

  for (const assignedShift of assignedShifts) {
    if (!isObject(assignedShift)) return false;
    if (!isValidAssignedShift(assignedShift)) return false;
  }
  return true;
};

const hasValidAvailability = (availability: any) => {
  if (!Array.isArray(availability)) return false;

  for (const available of availability) {
    if (
      !isObject(available) ||
      !DateTime.fromISO(
        available["start" as keyof {}] || available["start_time" as keyof {}]
      ).isValid ||
      !DateTime.fromISO(
        available["end" as keyof {}] || available["end_time" as keyof {}]
      ).isValid
    ) {
      return false;
    }
  }

  return true;
};

export const parseSchemaShiftSchedulingWorkers = (
  workers?: WorkerToSchedule[]
): WorkerToSchedule[] => {
  if (!Array.isArray(workers)) return [];

  return workers.filter((worker) => {
    if (
      worker?.availability &&
      Array.isArray(worker.availability) &&
      hasValidAvailability(worker.availability)
    ) {
      return true;
    }
    return false;
  });
};

export const parseSchemaShiftSchedulingRequiredWorkers = (
  requiredWorkers?: RequiredWorkerShift[]
): RequiredWorkerShift[] => {
  if (!Array.isArray(requiredWorkers)) return [];

  return requiredWorkers.filter((requiredWorker) => {
    if (requiredWorker?.count && hasValidAvailability([requiredWorker])) {
      return true;
    }
    return false;
  });
};
