import * as React from "react";

import BaseIcon, { IconProps } from "./BaseIcon";

const IconSearch = ({
  iconSize,
  iconColor,
  iconColorActive,
  iconColorFocus,
  iconColorHover,
  ...rest
}: IconProps) => {
  return (
    <BaseIcon
      {...{
        iconSize,
        iconColor,
        iconColorActive,
        iconColorFocus,
        iconColorHover,
      }}
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4936 6.4968C10.4936 8.70416 8.70416 10.4936 6.4968 10.4936C4.28943 10.4936 2.5 8.70416 2.5 6.4968C2.5 4.28943 4.28943 2.5 6.4968 2.5C8.70416 2.5 10.4936 4.28943 10.4936 6.4968ZM9.81715 10.8778C8.89467 11.578 7.74426 11.9936 6.4968 11.9936C3.461 11.9936 1 9.53259 1 6.4968C1 3.461 3.461 1 6.4968 1C9.53259 1 11.9936 3.461 11.9936 6.4968C11.9936 7.74426 11.578 8.89467 10.8778 9.81715L14.3562 13.2955L14.8865 13.8259L13.8259 14.8865L13.2955 14.3562L9.81715 10.8778Z"
      />
    </BaseIcon>
  );
};

export default IconSearch;
