import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  AvatarApp,
  Box,
  Flex,
  Loading,
  Notification,
  RowDetail,
  Tag,
  Text,
} from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";
import { DateTime } from "luxon";

import { trackEvent } from "../../../../analytics";
import { API_BASE } from "../../../../api/core/core.config";
import { getToken } from "../../../../api/utils/cognito";
import { useUser } from "../../../../AuthProvider";
import Header from "../../../../components/Header";
import { useMetaTitle } from "../../../../components/Layout";
import PivotTableRuns from "../../../../components/PivotTableRuns";
import ScenarioDefinitions from "../../../../components/ScenarioDefinitions";
import StandardError from "../../../../components/StandardError";
import { MEASURE_ENTITY_ROW } from "../../../../config/general";
import usePolling from "../../../../hooks/usePolling";
import {
  HEADER_HEIGHT,
  HEADER_HEIGHT_EXPANDED_MOBILE,
} from "../../../../utils/constants";
import { getIsCompletedState } from "../../../../utils/entities/getIsCompletedState";
import { getAccUrl } from "../../../../utils/navigation";
import { userHasAccessToAction } from "../../../../utils/rbac_utils";
import { ActionGroups } from "../../../../utils/rbac_utils/types";
import { AppPageProps } from "../../../App/App.types";
import useReturnPaths from "../../../App/hooks/useReturnPaths";
import { Table2HeaderObj } from "../../../App/subpages/RunHistory/RunHistory.types";
import { getRunHistoryTableHeaders } from "../../../App/subpages/RunHistory/utils/getRunHistoryTableHeaders";
import { getStatisticsHeaders } from "../../../App/subpages/RunHistory/utils/getStatisticsHeaders";
import { getAvatarColor } from "../../../Apps/utils/renderAppsList";
import ExperimentDetailsSection from "../../components/ExperimentDetailsSection";
import ExperimentProgressBar from "../../components/ExperimentProgressBar";
import ExperimentRuns from "../../components/ExperimentRuns";
import { scenarioTooltips } from "../../data/microcopy";
import { StartStopModalType } from "../../types/Experiment.types";
import {
  getIsExperimentResolved,
  shouldDisplayExperimentActions,
} from "../../utils/experimentStatus";
import { getOptionSetsByScenarioFromRuns } from "../../utils/getOptionSetsByScenarioFromRuns";
import { getScenarioConfigOptionsHeaders } from "../../utils/getScenarioConfigOptionsHeaders";
import { renderActionContent } from "../../utils/renderActions";
import CancelBatchExperimentConfirmModal from "../BatchExperiment/components/CancelBatchExperimentConfirmModal";
import useBatchExperimentCancel from "../BatchExperiment/hooks/useBatchExperimentCancel";

const ScenarioTest = ({ app, setDisplayPages }: AppPageProps) => {
  const [{ id: accountId, roles, features }] = useUser();
  const [token, setToken] = useState<string | null>(null);
  const [, setMetaTitle] = useMetaTitle();
  const theme = useTheme();
  const { pathname, search } = useLocation();
  const { returnPathList } = useReturnPaths();
  const params = useParams() as {
    id: string;
    field?: string;
  };

  const searchParams = new URLSearchParams(search);

  const batchViz = searchParams.get("batch_viz");
  const runViz = searchParams.get("run_viz");
  const encodedApiUrl = encodeURIComponent(API_BASE);
  const customVizAllowed = features?.streamlit;

  const {
    entity: scenarioTest,
    entityMetadata: scenarioTestMetadata,
    entityLoadError: scenarioTestError,
    entityLoadMetadataError: scenarioTestMetadataError,
    entityLoadRunsError: scenarioTestRunsError,
    entityRuns: scenarioTestRuns,
    loadEntity: loadScenarioTest,
    loadEntityMetadata: loadScenarioTestMetadata,
    loadEntityRuns: loadScenarioTestRuns,
  } = usePolling(app, "experiments/batch", { hasRuns: true });

  // token retrieval
  useEffect(() => {
    if (!token && (features || {}).streamlit && runViz) {
      getToken().then((token) => {
        setToken(token);
      });
    }
  }, [runViz, features, token]);

  // meta title display
  useEffect(() => {
    if (scenarioTestMetadata) {
      setMetaTitle(scenarioTestMetadata.name);
    }
  }, [scenarioTestMetadata, setMetaTitle]);

  // hide sidebar to expand pivot table area
  useEffect(() => {
    setDisplayPages && setDisplayPages(false);
  }, [setDisplayPages]);

  // reset sidebar display on exit
  useEffect(() => {
    return () => {
      setDisplayPages && setDisplayPages(true);
    };
  }, [setDisplayPages]);

  // load scenario test metadata and runs
  useEffect(() => {
    if (
      params.id &&
      !scenarioTestMetadata &&
      !scenarioTestRuns &&
      !scenarioTestMetadataError &&
      !scenarioTestRunsError
    ) {
      loadScenarioTestMetadata(app.id, params.id);
      loadScenarioTestRuns(app.id, params.id);
    }
  }, [
    app.id,
    scenarioTestMetadata,
    scenarioTestMetadataError,
    scenarioTestRuns,
    scenarioTestRunsError,
    loadScenarioTestMetadata,
    loadScenarioTestRuns,
    params.id,
  ]);

  useEffect(() => {
    if (
      !scenarioTest &&
      scenarioTestMetadata &&
      getIsExperimentResolved(scenarioTestMetadata)
    ) {
      loadScenarioTest(app.id, params.id);
    }
  }, [app.id, loadScenarioTest, params.id, scenarioTest, scenarioTestMetadata]);

  const statisticsHeaders = useMemo(() => {
    return getStatisticsHeaders(scenarioTestRuns, theme);
  }, [scenarioTestRuns, theme]);

  const scenarioDefinitions = useMemo(() => {
    return getOptionSetsByScenarioFromRuns(scenarioTestRuns);
  }, [scenarioTestRuns]);

  const scenarioConfigOptionsHeaders = useMemo(() => {
    if (!scenarioDefinitions) return [];
    return getScenarioConfigOptionsHeaders(scenarioDefinitions, theme);
  }, [scenarioDefinitions, theme]);

  const runHistoryHeaders: Table2HeaderObj[] = useMemo(() => {
    if (!scenarioTestRuns) {
      return [];
    }
    return getRunHistoryTableHeaders({
      accId: accountId,
      appId: app.id,
      isLinkTargetBlank: true,
      isScenarioTest: true,
      runHistory: scenarioTestRuns,
      scenarioConfigOptionsHeaders,
      statsHeaders: statisticsHeaders,
      theme,
    });
  }, [
    accountId,
    app.id,
    scenarioConfigOptionsHeaders,
    scenarioTestRuns,
    statisticsHeaders,
    theme,
  ]);

  // allow canceling scenario tests in progress
  const {
    cancelModalActionsError,
    displayConfirmModal,
    handleBatchExperimentCancel: handleScenarioTestCancel,
    setCancelModalActionsError,
    setDisplayConfirmModal,
  } = useBatchExperimentCancel(app.id, params.id);

  const handleCancelModalActions = async (actionType: StartStopModalType) => {
    await handleScenarioTestCancel(actionType);
    await loadScenarioTest(app.id, params.id);
  };

  const handleActionClick = (actionType: StartStopModalType) => {
    trackEvent("Scenario", {
      view: "Scenario Test",
      action: "Action Button Clicked",
      meta: {
        type: actionType,
      },
    });

    setCancelModalActionsError(null);
    setDisplayConfirmModal(actionType);
  };

  if (scenarioTestError) {
    return <StandardError errorMessage={scenarioTestError} />;
  }
  if (!scenarioTestMetadata) {
    return <Loading type="full-screen" dotColor={theme.color.orange500} />;
  }

  const canUserCreateAndEditScenario = userHasAccessToAction(
    roles,
    ActionGroups.ExperimentOperator,
    {}
  );

  const isCompletedState = getIsCompletedState(scenarioTestMetadata.status);

  return (
    <>
      <Header
        isExpanded
        configPageTitle={{
          label: scenarioTestMetadata.name,
          parentLabel: "Scenario Tests",
          parentUrl: returnPathList,
          ancestorIcon: (
            <AvatarApp
              size={32}
              iconColor={getAvatarColor({
                appType: app?.type,
                theme,
              })}
            />
          ),
          ancestorLabel: app.name,
          ancestorUrl: getAccUrl(accountId, `/app/${app.id}`),
        }}
        configActionButton={{
          label: "Edit",
          url: `${pathname}/edit`,
          onClick: () =>
            trackEvent("Scenario", {
              view: "Scenario Test",
              action: "Edit Button Clicked",
            }),
          isActionAllowed: canUserCreateAndEditScenario,
        }}
        secondaryButton={{
          label: "New scenario",
          url: `${returnPathList}/new`,
          testId: "create-new-scenario-test-button",
          isActionAllowed: canUserCreateAndEditScenario,
          onClick: () =>
            trackEvent("Scenario", {
              view: "Scenario Test",
              action: "Create Scenario Test Button Clicked",
            }),
        }}
        // TODO: Reenable later
        // secondaryButton={[
        //   {
        //     label: "New scenario",
        //     url: `${returnPathList}/new`,
        //     testId: "create-new-scenario-test-button",
        //     isActionAllowed: canUserCreateAndEditScenario,
        //     onClick: () =>
        //       trackEvent("Scenario", {
        //         view: "Scenario Test",
        //         action: "Create Scenario Test Button Clicked",
        //       }),
        //   },
        //   {
        //     label: "Clone scenario",
        //     url: `${returnPathList}/new?cloneId=${scenarioTestMetadata.id}`,
        //     testId: "clone-new-scenario-test-button",
        //     isActionAllowed: canUserCreateAndEditScenario,
        //     onClick: () =>
        //       trackEvent("Scenario", {
        //         view: "Scenario Test",
        //         action: "Clone Scenario Test Button Clicked",
        //       }),
        //   },
        // ]}
      />
      <Box
        mt={[
          `calc(
            ${HEADER_HEIGHT_EXPANDED_MOBILE} + ${theme.spacing.s9}
          )`,
          HEADER_HEIGHT_EXPANDED_MOBILE,
          HEADER_HEIGHT,
        ]}
      >
        <RowDetail
          hasNoBorder
          property="Name"
          render={
            <Box maxWidth={MEASURE_ENTITY_ROW}>
              <Text styleName="body-2">{scenarioTestMetadata.name}</Text>
            </Box>
          }
        />

        <RowDetail
          property="Status"
          testId="scenario-test-row-status"
          tooltipCopy={scenarioTooltips.status.content}
          render={<Text styleName="body-2">{scenarioTestMetadata.status}</Text>}
        />

        <RowDetail
          property="ID"
          testId="scenario-test-row-id"
          tooltipCopy={scenarioTooltips.id.content}
          render={
            <Flex>
              <Tag
                mb={-1}
                ml={rem(-6)}
                type="id"
                label={scenarioTestMetadata.id}
                contentToCopy={scenarioTestMetadata.id}
              />
            </Flex>
          }
        />

        {scenarioTestMetadata?.description && (
          <RowDetail
            property="Description"
            testId="scenario-test-row-description"
            render={
              <Box maxWidth={MEASURE_ENTITY_ROW}>
                <Text styleName="body-2">
                  {scenarioTestMetadata.description}
                </Text>
              </Box>
            }
          />
        )}

        <RowDetail
          property="Created"
          render={
            <Text
              as="time"
              styleName="body-2"
              dateTime={scenarioTestMetadata.created_at}
              title={scenarioTestMetadata.created_at}
            >
              {DateTime.fromISO(scenarioTestMetadata.created_at).toFormat(
                "yyyy-MM-dd · h:mm:ss a ZZZZ"
              )}
            </Text>
          }
        />

        <RowDetail
          property="Scenarios"
          secondaryLabel="For reference only"
          tooltipCopy={scenarioTooltips.scenariosDetail.content}
          render={
            <ScenarioDefinitions scenarioDefinitions={scenarioDefinitions} />
          }
        />
      </Box>

      {shouldDisplayExperimentActions(scenarioTestMetadata.status, roles) && (
        <ExperimentDetailsSection title="Actions">
          {renderActionContent({
            handleStartStopClick: handleActionClick,
            status: scenarioTestMetadata.status,
            hiddenActions: {
              complete: true,
            },
          })}
        </ExperimentDetailsSection>
      )}

      {scenarioTestMetadata.status === "started" && (
        <ExperimentProgressBar
          runsStarted={scenarioTestMetadata.number_of_runs}
          runsProcessed={scenarioTestMetadata.number_of_completed_runs}
          runsTotal={scenarioTestMetadata.number_of_requested_runs}
        />
      )}

      {!isCompletedState && (
        <Notification
          type="tip"
          message="The test results will be displayed after the runs have completed and been processed."
        />
      )}

      {!!(customVizAllowed && batchViz) && scenarioTest && (
        <iframe
          title="Custom Batch Viz"
          src={`https://${batchViz}.streamlit.app//?batch_id=${params.id}&app_id=${app.id}&status=${scenarioTest.status}&url=${encodedApiUrl}&embedded=true`}
          width="100%"
          height="500"
        ></iframe>
      )}

      {isCompletedState && scenarioTestRuns && (
        <ExperimentDetailsSection title="Explore Result">
          <PivotTableRuns
            runs={scenarioTestRuns}
            scenarioConfigOptionsHeaders={scenarioConfigOptionsHeaders}
            statisticsHeaders={statisticsHeaders}
          />
        </ExperimentDetailsSection>
      )}

      {scenarioTestRuns &&
        !scenarioTestRunsError &&
        !!scenarioTestRuns?.length &&
        !(customVizAllowed && runViz) && (
          <ExperimentRuns
            kind="batch"
            experimentRuns={scenarioTestRuns}
            runHistoryHeaders={runHistoryHeaders}
            scenarioConfigOptionsHeaders={scenarioConfigOptionsHeaders}
            statisticsHeaders={statisticsHeaders}
          />
        )}
      {scenarioTestRunsError && (
        <StandardError errorMessage={scenarioTestRunsError} />
      )}

      {!!(customVizAllowed && runViz) && scenarioTest && (
        <>
          {token && (
            <iframe
              title="Custom Run Viz"
              src={`https://${runViz}.streamlit.app//?batch_id=${params.id}&app_id=${app.id}&status=${scenarioTest.status}&url=${encodedApiUrl}&account=${accountId}&token=${token}&embedded=true`}
              width="100%"
              height="500"
            ></iframe>
          )}
        </>
      )}

      {displayConfirmModal && (
        <CancelBatchExperimentConfirmModal
          error={cancelModalActionsError}
          handleStartStopModalActions={handleCancelModalActions}
          modalType={displayConfirmModal}
          setDisplayConfirmModal={setDisplayConfirmModal}
          testName={scenarioTestMetadata.name}
          hasStartCriteria={false}
        />
      )}
    </>
  );
};

export default ScenarioTest;
