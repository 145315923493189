import { useEffect, useState } from "react";

import { ExecutionClassType } from "../../../../../api/core/dataPlane.types";
import {
  ConfigOption,
  HandleConfigOptionChangeParams,
} from "../../../../../components/AddConfigOptions/AddConfigOptions.types";
import { EC_DEFAULT } from "../../../../../config/apps";

const emptyConfigOption = {
  option: "",
  value: "",
};

const useNewInstance = () => {
  const [pendingVersionId, setPendingVersionId] = useState<string>("");
  const [pendingExecutionClass, setPendingExecutionClass] =
    useState<ExecutionClassType>(EC_DEFAULT);
  const [pendingConfigOptions, setPendingConfigOptions] = useState<
    ConfigOption[]
  >([emptyConfigOption]);

  useEffect(() => {
    if (pendingConfigOptions.length === 0) {
      setPendingConfigOptions([emptyConfigOption]);
    }
  }, [pendingConfigOptions.length]);

  const addEmptyConfigOption = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    return setPendingConfigOptions((prevState) => {
      return [...prevState, emptyConfigOption];
    });
  };

  const removeConfigOption = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    removedIndex: number
  ) => {
    e.preventDefault();
    const configOptionsModified = pendingConfigOptions.filter(
      (_pendingOption, index) => {
        return index !== removedIndex;
      }
    );
    return setPendingConfigOptions(configOptionsModified);
  };

  const handleConfigOptionChange = ({
    e,
    type,
    index,
    pendingConfigOptions,
  }: HandleConfigOptionChangeParams) => {
    const datum = e.target.value;
    const modifiedPendingConfigOptions = pendingConfigOptions.map(
      (pendingOption, i) => {
        if (index === i) {
          const modifiedPendingConfigOption = { ...pendingOption };
          modifiedPendingConfigOption[type] = datum;
          return modifiedPendingConfigOption;
        }
        return pendingOption;
      }
    );
    return setPendingConfigOptions(modifiedPendingConfigOptions);
  };

  // convert config options array to options object for payload
  const convertToConfigOptions = (
    pendingConfigOptions: ConfigOption[]
  ): { [key: string]: string } => {
    return pendingConfigOptions.reduce((configOptions, configOption) => {
      if (configOption.option) {
        configOptions[configOption.option.trim()] = configOption.value.trim();
      }
      return configOptions;
    }, {} as { [key: string]: string });
  };

  // convert config options object to array for UI display
  const convertToPendingConfigOptions = (configOptions: {
    [key: string]: string;
  }): ConfigOption[] => {
    return Object.keys(configOptions).map((configOptionKey) => {
      return {
        option: configOptionKey,
        value: configOptions[configOptionKey],
      };
    });
  };

  return {
    addEmptyConfigOption,
    convertToConfigOptions,
    convertToPendingConfigOptions,
    handleConfigOptionChange,
    pendingConfigOptions,
    pendingExecutionClass,
    pendingVersionId,
    removeConfigOption,
    setPendingConfigOptions,
    setPendingExecutionClass,
    setPendingVersionId,
  };
};

export default useNewInstance;
