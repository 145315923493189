import { GetLabelColorParams, GetStyleNameParams } from "../Toggle.types";

export const getStyleName = ({ isActive, size }: GetStyleNameParams) => {
  if (isActive) {
    return size === "x-small" ? "body-3-bold" : "body-2-bold";
  } else {
    return size === "x-small" ? "body-3" : "body-2";
  }
};

export const getLabelColor = ({
  isActive,
  isDisabled,
  theme,
}: GetLabelColorParams) => {
  if (isDisabled) {
    return theme.color.gray600;
  } else {
    return isActive ? theme.color.gray800 : theme.color.gray600;
  }
};
