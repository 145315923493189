import {
  GoToNextStepParams,
  HandleDetailsToggleParams,
} from "../Install.types";

export const goToNextStep = ({
  setIsActiveCurrent,
  setIsActiveNext,
}: GoToNextStepParams) => {
  setIsActiveCurrent(false);
  setIsActiveNext(true);
  return;
};

export const handleDetailsToggle = ({
  e,
  isActive,
  setIsActiveCurrent,
}: HandleDetailsToggleParams) => {
  e.preventDefault();

  return setIsActiveCurrent(!isActive);
};
