export const sampleInputNextrouteDistributionExampleDay2 = {
  defaults: {
    stops: {
      unplanned_penalty: 2000000000,
    },
    vehicles: {
      capacity: 350,
      end_location: {
        lat: 39.9658,
        lon: -76.3223,
      },
      speed: 20,
      start_location: {
        lat: 39.9658,
        lon: -76.3223,
      },
    },
  },
  stops: [
    {
      id: "location-1",
      location: {
        lat: 39.9657,
        lon: -76.2867,
      },
      max_wait: 600,
      quantity: -15,
      start_time_window: [
        "2024-04-16T13:00:00-05:00",
        "2024-04-16T20:00:00-05:00",
      ],
    },
    {
      id: "location-2",
      location: {
        lat: 40.0123,
        lon: -76.2498,
      },
      max_wait: 600,
      quantity: -17,
      start_time_window: [
        "2024-04-16T13:00:00-05:00",
        "2024-04-16T20:00:00-05:00",
      ],
    },
    {
      id: "location-3",
      location: {
        lat: 40.0211,
        lon: -76.3417,
      },
      max_wait: 600,
      quantity: -27,
      start_time_window: [
        "2024-04-16T13:00:00-05:00",
        "2024-04-16T20:00:00-05:00",
      ],
    },
    {
      id: "location-4",
      location: {
        lat: 39.9572,
        lon: -76.372,
      },
      max_wait: 600,
      quantity: -25,
      start_time_window: [
        "2024-04-16T13:00:00-05:00",
        "2024-04-16T20:00:00-05:00",
      ],
    },
    {
      id: "location-5",
      location: {
        lat: 39.9259,
        lon: -76.3569,
      },
      max_wait: 600,
      quantity: -15,
      start_time_window: [
        "2024-04-16T13:00:00-05:00",
        "2024-04-16T20:00:00-05:00",
      ],
    },
    {
      id: "location-6",
      location: {
        lat: 39.9813,
        lon: -76.2452,
      },
      max_wait: 600,
      quantity: -22,
      start_time_window: [
        "2024-04-16T13:00:00-05:00",
        "2024-04-16T20:00:00-05:00",
      ],
    },
    {
      id: "location-7",
      location: {
        lat: 40.0251,
        lon: -76.3797,
      },
      max_wait: 600,
      quantity: -27,
      start_time_window: [
        "2024-04-16T13:00:00-05:00",
        "2024-04-16T20:00:00-05:00",
      ],
    },
    {
      id: "location-8",
      location: {
        lat: 40.0429,
        lon: -76.247,
      },
      max_wait: 600,
      quantity: -29,
      start_time_window: [
        "2024-04-16T04:00:00-05:00",
        "2024-04-16T13:00:00-05:00",
      ],
    },
    {
      id: "location-9",
      location: {
        lat: 39.9673,
        lon: -76.3701,
      },
      max_wait: 600,
      quantity: -22,
      start_time_window: [
        "2024-04-16T13:00:00-05:00",
        "2024-04-16T20:00:00-05:00",
      ],
    },
    {
      id: "location-10",
      location: {
        lat: 40.005,
        lon: -76.3818,
      },
      max_wait: 600,
      quantity: -25,
      start_time_window: [
        "2024-04-16T04:00:00-05:00",
        "2024-04-16T13:00:00-05:00",
      ],
    },
    {
      id: "location-11",
      location: {
        lat: 40.0142,
        lon: -76.3938,
      },
      max_wait: 600,
      quantity: -26,
      start_time_window: [
        "2024-04-16T13:00:00-05:00",
        "2024-04-16T20:00:00-05:00",
      ],
    },
    {
      id: "location-12",
      location: {
        lat: 39.9687,
        lon: -76.3455,
      },
      max_wait: 600,
      quantity: -29,
      start_time_window: [
        "2024-04-16T04:00:00-05:00",
        "2024-04-16T13:00:00-05:00",
      ],
    },
    {
      id: "location-13",
      location: {
        lat: 39.9662,
        lon: -76.319,
      },
      max_wait: 600,
      quantity: -15,
      start_time_window: [
        "2024-04-16T04:00:00-05:00",
        "2024-04-16T13:00:00-05:00",
      ],
    },
    {
      id: "location-14",
      location: {
        lat: 39.9782,
        lon: -76.3316,
      },
      max_wait: 600,
      quantity: -23,
      start_time_window: [
        "2024-04-16T04:00:00-05:00",
        "2024-04-16T13:00:00-05:00",
      ],
    },
    {
      id: "location-15",
      location: {
        lat: 40.0008,
        lon: -76.2643,
      },
      max_wait: 600,
      quantity: -22,
      start_time_window: [
        "2024-04-16T13:00:00-05:00",
        "2024-04-16T20:00:00-05:00",
      ],
    },
    {
      id: "location-16",
      location: {
        lat: 39.9773,
        lon: -76.2904,
      },
      max_wait: 600,
      quantity: -26,
      start_time_window: [
        "2024-04-16T04:00:00-05:00",
        "2024-04-16T13:00:00-05:00",
      ],
    },
    {
      id: "location-17",
      location: {
        lat: 40.0148,
        lon: -76.3746,
      },
      max_wait: 600,
      quantity: -17,
      start_time_window: [
        "2024-04-16T13:00:00-05:00",
        "2024-04-16T20:00:00-05:00",
      ],
    },
    {
      id: "location-18",
      location: {
        lat: 39.9725,
        lon: -76.3114,
      },
      max_wait: 600,
      quantity: -16,
      start_time_window: [
        "2024-04-16T04:00:00-05:00",
        "2024-04-16T13:00:00-05:00",
      ],
    },
    {
      id: "location-19",
      location: {
        lat: 39.9492,
        lon: -76.3214,
      },
      max_wait: 600,
      quantity: -25,
      start_time_window: [
        "2024-04-16T04:00:00-05:00",
        "2024-04-16T13:00:00-05:00",
      ],
    },
    {
      id: "location-20",
      location: {
        lat: 39.9818,
        lon: -76.3118,
      },
      max_wait: 600,
      quantity: -27,
      start_time_window: [
        "2024-04-16T13:00:00-05:00",
        "2024-04-16T20:00:00-05:00",
      ],
    },
    {
      id: "location-21",
      location: {
        lat: 39.8463,
        lon: -76.396,
      },
      max_wait: 600,
      quantity: -19,
      start_time_window: [
        "2024-04-16T04:00:00-05:00",
        "2024-04-16T13:00:00-05:00",
      ],
    },
    {
      id: "location-22",
      location: {
        lat: 39.9924,
        lon: -76.3016,
      },
      max_wait: 600,
      quantity: -18,
      start_time_window: [
        "2024-04-16T04:00:00-05:00",
        "2024-04-16T13:00:00-05:00",
      ],
    },
    {
      id: "location-23",
      location: {
        lat: 39.9535,
        lon: -76.4265,
      },
      max_wait: 600,
      quantity: -19,
      start_time_window: [
        "2024-04-16T04:00:00-05:00",
        "2024-04-16T13:00:00-05:00",
      ],
    },
    {
      id: "location-24",
      location: {
        lat: 39.9281,
        lon: -76.3899,
      },
      max_wait: 600,
      quantity: -25,
      start_time_window: [
        "2024-04-16T13:00:00-05:00",
        "2024-04-16T20:00:00-05:00",
      ],
    },
    {
      id: "location-25",
      location: {
        lat: 39.9393,
        lon: -76.2833,
      },
      max_wait: 600,
      quantity: -26,
      start_time_window: [
        "2024-04-16T13:00:00-05:00",
        "2024-04-16T20:00:00-05:00",
      ],
    },
    {
      id: "location-26",
      location: {
        lat: 39.9588,
        lon: -76.399,
      },
      max_wait: 600,
      quantity: -21,
      start_time_window: [
        "2024-04-16T13:00:00-05:00",
        "2024-04-16T20:00:00-05:00",
      ],
    },
    {
      id: "location-27",
      location: {
        lat: 40.0107,
        lon: -76.3215,
      },
      max_wait: 600,
      quantity: -29,
      start_time_window: [
        "2024-04-16T13:00:00-05:00",
        "2024-04-16T20:00:00-05:00",
      ],
    },
    {
      id: "location-28",
      location: {
        lat: 39.878,
        lon: -76.4449,
      },
      max_wait: 600,
      quantity: -22,
      start_time_window: [
        "2024-04-16T04:00:00-05:00",
        "2024-04-16T13:00:00-05:00",
      ],
    },
    {
      id: "location-29",
      location: {
        lat: 40.0148,
        lon: -76.2832,
      },
      max_wait: 600,
      quantity: -16,
      start_time_window: [
        "2024-04-16T04:00:00-05:00",
        "2024-04-16T13:00:00-05:00",
      ],
    },
    {
      id: "location-30",
      location: {
        lat: 39.9462,
        lon: -76.4034,
      },
      max_wait: 600,
      quantity: -18,
      start_time_window: [
        "2024-04-16T04:00:00-05:00",
        "2024-04-16T13:00:00-05:00",
      ],
    },
    {
      id: "location-31",
      location: {
        lat: 39.9176,
        lon: -76.3712,
      },
      max_wait: 600,
      quantity: -19,
      start_time_window: [
        "2024-04-16T04:00:00-05:00",
        "2024-04-16T13:00:00-05:00",
      ],
    },
    {
      id: "location-32",
      location: {
        lat: 39.9991,
        lon: -76.4622,
      },
      max_wait: 600,
      quantity: -17,
      start_time_window: [
        "2024-04-16T13:00:00-05:00",
        "2024-04-16T20:00:00-05:00",
      ],
    },
    {
      id: "location-33",
      location: {
        lat: 39.9967,
        lon: -76.3131,
      },
      max_wait: 600,
      quantity: -16,
      start_time_window: [
        "2024-04-16T04:00:00-05:00",
        "2024-04-16T13:00:00-05:00",
      ],
    },
    {
      id: "location-34",
      location: {
        lat: 39.8954,
        lon: -76.3419,
      },
      max_wait: 600,
      quantity: -15,
      start_time_window: [
        "2024-04-16T04:00:00-05:00",
        "2024-04-16T13:00:00-05:00",
      ],
    },
    {
      id: "location-35",
      location: {
        lat: 39.8746,
        lon: -76.3312,
      },
      max_wait: 600,
      quantity: -27,
      start_time_window: [
        "2024-04-16T13:00:00-05:00",
        "2024-04-16T20:00:00-05:00",
      ],
    },
    {
      id: "location-36",
      location: {
        lat: 39.8611,
        lon: -76.374,
      },
      max_wait: 600,
      quantity: -16,
      start_time_window: [
        "2024-04-16T04:00:00-05:00",
        "2024-04-16T13:00:00-05:00",
      ],
    },
    {
      id: "location-37",
      location: {
        lat: 39.9504,
        lon: -76.3675,
      },
      max_wait: 600,
      quantity: -21,
      start_time_window: [
        "2024-04-16T04:00:00-05:00",
        "2024-04-16T13:00:00-05:00",
      ],
    },
    {
      id: "location-38",
      location: {
        lat: 40.015,
        lon: -76.214,
      },
      max_wait: 600,
      quantity: -19,
      start_time_window: [
        "2024-04-16T04:00:00-05:00",
        "2024-04-16T13:00:00-05:00",
      ],
    },
    {
      id: "location-39",
      location: {
        lat: 39.8821,
        lon: -76.1992,
      },
      max_wait: 600,
      quantity: -21,
      start_time_window: [
        "2024-04-16T04:00:00-05:00",
        "2024-04-16T13:00:00-05:00",
      ],
    },
    {
      id: "location-40",
      location: {
        lat: 39.9248,
        lon: -76.4257,
      },
      max_wait: 600,
      quantity: -17,
      start_time_window: [
        "2024-04-16T13:00:00-05:00",
        "2024-04-16T20:00:00-05:00",
      ],
    },
    {
      id: "location-41",
      location: {
        lat: 40.0172,
        lon: -76.2713,
      },
      max_wait: 600,
      quantity: -26,
      start_time_window: [
        "2024-04-16T13:00:00-05:00",
        "2024-04-16T20:00:00-05:00",
      ],
    },
    {
      id: "location-42",
      location: {
        lat: 39.9476,
        lon: -76.2659,
      },
      max_wait: 600,
      quantity: -18,
      start_time_window: [
        "2024-04-16T04:00:00-05:00",
        "2024-04-16T13:00:00-05:00",
      ],
    },
    {
      id: "location-43",
      location: {
        lat: 39.9051,
        lon: -76.2278,
      },
      max_wait: 600,
      quantity: -26,
      start_time_window: [
        "2024-04-16T13:00:00-05:00",
        "2024-04-16T20:00:00-05:00",
      ],
    },
    {
      id: "location-44",
      location: {
        lat: 40.0006,
        lon: -76.3635,
      },
      max_wait: 600,
      quantity: -26,
      start_time_window: [
        "2024-04-16T13:00:00-05:00",
        "2024-04-16T20:00:00-05:00",
      ],
    },
    {
      id: "location-45",
      location: {
        lat: 39.9218,
        lon: -76.313,
      },
      max_wait: 600,
      quantity: -19,
      start_time_window: [
        "2024-04-16T13:00:00-05:00",
        "2024-04-16T20:00:00-05:00",
      ],
    },
    {
      id: "location-46",
      location: {
        lat: 40.0263,
        lon: -76.2321,
      },
      max_wait: 600,
      quantity: -26,
      start_time_window: [
        "2024-04-16T04:00:00-05:00",
        "2024-04-16T13:00:00-05:00",
      ],
    },
    {
      id: "location-47",
      location: {
        lat: 39.9816,
        lon: -76.4251,
      },
      max_wait: 600,
      quantity: -16,
      start_time_window: [
        "2024-04-16T04:00:00-05:00",
        "2024-04-16T13:00:00-05:00",
      ],
    },
    {
      id: "location-48",
      location: {
        lat: 40.0304,
        lon: -76.3837,
      },
      max_wait: 600,
      quantity: -25,
      start_time_window: [
        "2024-04-16T13:00:00-05:00",
        "2024-04-16T20:00:00-05:00",
      ],
    },
    {
      id: "location-49",
      location: {
        lat: 39.8872,
        lon: -76.3671,
      },
      max_wait: 600,
      quantity: -26,
      start_time_window: [
        "2024-04-16T04:00:00-05:00",
        "2024-04-16T13:00:00-05:00",
      ],
    },
    {
      id: "location-50",
      location: {
        lat: 39.9295,
        lon: -76.2645,
      },
      max_wait: 600,
      quantity: -21,
      start_time_window: [
        "2024-04-16T04:00:00-05:00",
        "2024-04-16T13:00:00-05:00",
      ],
    },
    {
      id: "location-51",
      location: {
        lat: 40.0179,
        lon: -76.4372,
      },
      max_wait: 600,
      quantity: -27,
      start_time_window: [
        "2024-04-16T13:00:00-05:00",
        "2024-04-16T20:00:00-05:00",
      ],
    },
    {
      id: "location-52",
      location: {
        lat: 39.9502,
        lon: -76.3037,
      },
      max_wait: 600,
      quantity: -25,
      start_time_window: [
        "2024-04-16T13:00:00-05:00",
        "2024-04-16T20:00:00-05:00",
      ],
    },
  ],
  vehicles: [
    {
      start_time: "2024-04-16T13:00:00-05:00",
      end_time: "2024-04-16T20:59:00-05:00",
      id: "yawning-lemur",
    },
    {
      start_time: "2024-04-16T18:00:00-05:00",
      end_time: "2024-04-16T23:59:00-05:00",
      id: "cantankerous-chinchilla",
    },
    {
      start_time: "2024-04-16T13:00:00-05:00",
      end_time: "2024-04-16T20:59:00-05:00",
      id: "enigmatic-toucan",
    },
    {
      start_time: "2024-04-16T09:00:00-05:00",
      end_time: "2024-04-16T16:59:00-05:00",
      id: "munificent-quokka",
    },
    {
      start_time: "2024-04-16T16:00:00-05:00",
      end_time: "2024-04-16T23:59:00-05:00",
      id: "frivolous-otter",
    },
    {
      start_time: "2024-04-16T09:00:00-05:00",
      end_time: "2024-04-16T16:59:00-05:00",
      id: "gibbous-okapi",
    },
    {
      start_time: "2024-04-16T13:00:00-05:00",
      end_time: "2024-04-16T20:59:00-05:00",
      id: "aberrant-giraffe",
    },
    {
      start_time: "2024-04-16T09:00:00-05:00",
      end_time: "2024-04-16T16:59:00-05:00",
      id: "lugubrious-sloth",
    },
    {
      start_time: "2024-04-16T13:00:00-05:00",
      end_time: "2024-04-16T23:59:00-05:00",
      id: "enigmatic-aardvark",
    },
    {
      start_time: "2024-04-16T09:00:00-05:00",
      end_time: "2024-04-16T16:59:00-05:00",
      id: "rambunctious-binturong",
    },
    {
      start_time: "2024-04-16T09:00:00-05:00",
      end_time: "2024-04-16T16:59:00-05:00",
      id: "sesquipedalian-sea-otter",
    },
    {
      start_time: "2024-04-16T13:00:00-05:00",
      end_time: "2024-04-16T20:59:00-05:00",
      id: "dapper-porcupine",
    },
    {
      start_time: "2024-04-16T12:00:00-05:00",
      end_time: "2024-04-16T17:59:00-05:00",
      id: "lachrymose-tapir",
    },
    {
      start_time: "2024-04-16T11:00:00-05:00",
      end_time: "2024-04-16T17:59:00-05:00",
      id: "whimsical-fennec-fox",
    },
    {
      start_time: "2024-04-16T16:00:00-05:00",
      end_time: "2024-04-16T23:59:00-05:00",
      id: "bombastic-hedgehog",
    },
    {
      start_time: "2024-04-16T09:00:00-05:00",
      end_time: "2024-04-16T23:59:00-05:00",
      id: "recalcitrant-hippopotamus",
    },
    {
      start_time: "2024-04-16T13:00:00-05:00",
      end_time: "2024-04-16T20:59:00-05:00",
      id: "vainglorious-okapi",
    },
    {
      start_time: "2024-04-16T09:00:00-05:00",
      end_time: "2024-04-16T16:59:00-05:00",
      id: "beneficent-axolotl",
    },
    {
      start_time: "2024-04-16T09:00:00-05:00",
      end_time: "2024-04-16T16:59:00-05:00",
      id: "quixotic-lemur",
    },
    {
      start_time: "2024-04-16T09:00:00-05:00",
      end_time: "2024-04-16T16:59:00-05:00",
      id: "delirious-chinchilla",
    },
    {
      start_time: "2024-04-16T10:00:00-05:00",
      end_time: "2024-04-16T16:59:00-05:00",
      id: "jocular-chinchilla",
    },
    {
      start_time: "2024-04-16T12:00:00-05:00",
      end_time: "2024-04-16T16:59:00-05:00",
      id: "kaleidoscopic-bush-baby",
    },
    {
      start_time: "2024-04-16T13:00:00-05:00",
      end_time: "2024-04-16T17:59:00-05:00",
      id: "ebullient-lemur",
    },
    {
      start_time: "2024-04-16T09:00:00-05:00",
      end_time: "2024-04-16T16:59:00-05:00",
      id: "effervescent-wombat",
    },
    {
      start_time: "2024-04-16T13:00:00-05:00",
      end_time: "2024-04-16T20:59:00-05:00",
      id: "querulous-proboscis-monkey",
    },
    {
      start_time: "2024-04-16T09:00:00-05:00",
      end_time: "2024-04-16T16:59:00-05:00",
      id: "beneficent-dik-dik",
    },
    {
      start_time: "2024-04-16T13:00:00-05:00",
      end_time: "2024-04-16T20:59:00-05:00",
      id: "bombastic-sloth",
    },
    {
      start_time: "2024-04-16T13:00:00-05:00",
      end_time: "2024-04-16T20:59:00-05:00",
      id: "perspicacious-red-panda",
    },
    {
      start_time: "2024-04-16T12:00:00-05:00",
      end_time: "2024-04-16T18:59:00-05:00",
      id: "rambunctious-pangolin",
    },
    {
      start_time: "2024-04-16T13:00:00-05:00",
      end_time: "2024-04-16T20:59:00-05:00",
      id: "beneficent-red-panda",
    },
    {
      start_time: "2024-04-16T13:00:00-05:00",
      end_time: "2024-04-16T20:59:00-05:00",
      id: "turbulent-mandrill",
    },
    {
      start_time: "2024-04-16T13:00:00-05:00",
      end_time: "2024-04-16T20:59:00-05:00",
      id: "zealous-blobfish",
    },
    {
      start_time: "2024-04-16T11:00:00-05:00",
      end_time: "2024-04-16T18:59:00-05:00",
      id: "vivacious-guinea-pig",
    },
    {
      start_time: "2024-04-16T13:00:00-05:00",
      end_time: "2024-04-16T20:59:00-05:00",
      id: "ebullient-red-panda",
    },
  ],
};
