import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import Profile from "./subpages/Profile";

const AccountRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/profile`}>
        <Profile />
      </Route>

      <Redirect to={`${path}/api-keys`} />
    </Switch>
  );
};

export default AccountRoutes;
