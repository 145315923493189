import * as React from "react";

import BaseIcon, { IconProps } from "./BaseIcon";

const IconMainNavLogout = ({
  iconColor,
  iconColorActive,
  iconColorFocus,
  iconColorHover,
  ...rest
}: IconProps) => {
  return (
    <BaseIcon
      {...{
        iconColor,
        iconColorActive,
        iconColorFocus,
        iconColorHover,
      }}
      iconSize={42}
      viewBox={[0, 0, 42, 42]}
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.75 13.75H14.5V28.25H23.75H24.5V29.75H23.75H14.25C13.5596 29.75 13 29.1904 13 28.5V13.5C13 12.8096 13.5596 12.25 14.25 12.25H23.75H24.5V13.75H23.75ZM28.4303 16.8697L27.9 16.3394L26.8393 17.4L27.3697 17.9304L29.6893 20.25H19.5H18.75V21.75H19.5H29.6893L27.3697 24.0697L26.8393 24.6L27.9 25.6607L28.4303 25.1304L32.0303 21.5304L32.5607 21L32.0303 20.4697L28.4303 16.8697Z"
      />
    </BaseIcon>
  );
};

export default IconMainNavLogout;
