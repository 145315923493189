import * as React from "react";
import styled from "@emotion/styled";
import { space } from "styled-system";

import { filterProps } from "../../lib/filterProps";
import { styleLinks } from "../../lib/linkStyles";
import { rem } from "../../lib/tools";

type StyledLabelProps = {
  disabled?: boolean;
  selected?: boolean;
  small?: boolean;
};

const StyledLabel = styled("label", {
  shouldForwardProp: (prop: any) => filterProps(prop),
})<StyledLabelProps>`
  ${space}

  display: block;
  padding-left: ${(props) =>
    props.small ? props.theme.spacing.s4 : props.theme.spacing.s5};
  position: relative;
  font-family: ${({ theme }) => theme.ui2Typography.fontFamilyBody2};
  font-size: ${({ theme }) => theme.ui2Typography.fontSizeBody2};
  font-weight: ${({ theme }) => theme.ui2Typography.fontWeightBody2Bold};
  line-height: ${({ theme }) => theme.ui2Typography.lineHeightBody2};
  letter-spacing: ${({ theme }) => theme.ui2Typography.letterSpacingBody2};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  color: ${(props) =>
    props.disabled
      ? props.theme.color.gray500
      : props.selected
      ? props.theme.color.gray800
      : props.theme.color.gray700};

  ${(props) =>
    props.small &&
    `
      font-family: ${props.theme.ui2Typography.fontFamilyBody3};
      font-size: ${props.theme.ui2Typography.fontSizeBody3};
      font-weight: ${props.theme.ui2Typography.fontWeightBody3Bold};
      line-height: ${props.theme.ui2Typography.lineHeightBody3};
      letter-spacing: ${props.theme.ui2Typography.letterSpacingBody3};
    `}

  ${({ theme }) => styleLinks(theme)}

  &::before {
    content: "";
    box-sizing: border-box;
    display: block;
    width: ${(props) =>
      props.small ? props.theme.spacing.s3 : props.theme.spacing.s4};
    height: ${(props) =>
      props.small ? props.theme.spacing.s3 : props.theme.spacing.s4};
    position: absolute;
    left: 0;
    top: ${(props) => (props.small ? rem(1) : rem(1))};
    border-width: ${(props) =>
      props.selected
        ? props.small
          ? props.theme.border.width
          : props.theme.border.widthThick
        : props.theme.border.width};
    border-style: ${({ theme }) => theme.border.style};
    border-color: ${(props) =>
      props.selected
        ? props.disabled
          ? props.theme.color.gray400
          : props.theme.color.gray700
        : props.theme.color.gray300};
    border-radius: ${({ theme }) => theme.spacing.s2};
    z-index: 1;

    /* eye roll */
    ${(props) => !props.small && `transform: translateY(${rem(-0.5)});`}
  }

  ${(props) =>
    !!props.selected &&
    `
    &::after {
      content: "";
      box-sizing: border-box;
      display: block;
      width: ${props.small ? rem(6) : props.theme.spacing.s2};
      height: ${props.small ? rem(6) : props.theme.spacing.s2};
      position: absolute;
      left: ${props.small ? rem(3) : rem(4)};
      top: ${props.small ? rem(4) : rem(5)};
      border-radius: ${props.theme.spacing.s2};
      background-color: ${
        props.disabled ? props.theme.color.gray400 : props.theme.color.gray700
      };
      z-index: 2;

      /* eye roll */
      ${!props.small && `transform: translateY(${rem(-0.5)});`}
    }
  `}
`;

const StyledInput = styled("input", {
  shouldForwardProp: (prop: any) => filterProps(prop),
})`
  position: absolute;
  opacity: 0;
`;

const InputRadio = ({
  checkValue,
  disabled,
  handleChange,
  id,
  groupName,
  label,
  small,
  value,
  // styled-system space props (margin only)
  m,
  mt,
  mr,
  mb,
  ml,
}: {
  checkValue: string;
  disabled: boolean;
  handleChange?: (e: React.SyntheticEvent) => void;
  id: string;
  groupName: string;
  label: React.ReactNode;
  small?: boolean;
  value: string;
  m?: number | string | [];
  mt?: number | string | [];
  mr?: number | string | [];
  mb?: number | string | [];
  ml?: number | string | [];
}) => {
  return (
    <React.Fragment>
      <StyledInput
        type="radio"
        disabled={disabled}
        id={id}
        name={groupName}
        value={value}
        checked={!!(checkValue === value)}
        onChange={handleChange}
      />
      <StyledLabel
        {...{ disabled, m, mt, mr, mb, ml, small }}
        htmlFor={id}
        selected={!!(checkValue === value)}
      >
        {label}
      </StyledLabel>
    </React.Fragment>
  );
};

export default InputRadio;
