import React from "react";
import { Modal2 } from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";

import StandardError from "../../../../../components/StandardError";
import { APIKeyChangeModalProps, ModalType } from "../APIKeys.types";
import { getModalContent } from "../utils/confirmModalContent";

const APIKeyChangeModal = ({
  modalInfo,
  setDisplayConfirmModal,
  changeKey,
  error,
}: APIKeyChangeModalProps) => {
  const theme = useTheme();
  const modalContent = getModalContent(modalInfo);

  return (
    <Modal2
      isActive={true}
      width={rem(600)}
      title={modalContent.title}
      summary={modalContent.summary}
      closeActionOnClick={(e: any) => setDisplayConfirmModal(e, undefined)}
      primaryActionLabel={modalContent.primaryActionLabel}
      primaryActionOnClick={(e: any) => changeKey(e, modalInfo)}
      {...(modalInfo.modalType === ModalType.Delete && {
        primaryActionStyles: {
          backgroundColor: theme.color.red600,
          "&:hover, &:focus, &:active": {
            backgroundColor: theme.color.red700,
          },
          "&:focus-visible": {
            outlineColor: theme.color.red800,
          },
        },
      })}
      secondaryActionLabel={modalContent.secondaryActionLabel}
      secondaryActionOnClick={(e: any) => setDisplayConfirmModal(e, undefined)}
      children={error ? <StandardError errorMessage={error} /> : null}
    />
  );
};

export default APIKeyChangeModal;
