// hook adapted from https://github.com/jaredLunde/react-hook/blob/master/packages/debounce/src/index.tsx
// https://github.com/jaredLunde/react-hook/blob/master/LICENSE

import React from "react";

export const useDebounceCallback = <CallbackArgs extends any[]>(
  callback: (...args: CallbackArgs) => void,
  wait = 100
): [(...args: CallbackArgs) => void, () => void] => {
  // this is the same code as the "useLatest" hook from react-hook package,
  // see https://github.com/streamich/react-use/blob/master/docs/useLatest.md
  // (different package, but same idea) for why putting the callback in a ref is necessary
  const storedCallback = React.useRef(callback);
  // make sure callback is updated whenever hook re-renders since it is in a ref
  React.useEffect(() => {
    storedCallback.current = callback;
  });

  const timeout = React.useRef<ReturnType<typeof setTimeout>>();
  // Cleans up pending timeouts when the deps change
  React.useEffect(
    () => () => {
      timeout.current && clearTimeout(timeout.current);
      timeout.current = void 0;
    },
    [wait, storedCallback]
  );

  const clearDebouncedTimeout = React.useCallback(function () {
    const { current } = timeout;
    current && clearTimeout(current);
  }, []);

  const debouncedFunc = React.useCallback(
    function () {
      const args = arguments;
      // Clear the timeout every call and start waiting again
      clearDebouncedTimeout();
      // Waits for `wait` before invoking the callback
      timeout.current = setTimeout(() => {
        timeout.current = void 0;
        storedCallback.current.apply(null, args as any);
      }, wait);
    },
    [clearDebouncedTimeout, wait]
  );

  return [debouncedFunc, clearDebouncedTimeout];
};
