import React from "react";
import { Box, Text } from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { DateTime } from "luxon";
import tinycolor from "tinycolor2";

import {
  StyledHourCol,
  StyledHourPanel,
  WorkerId,
  WorkerRequiredPeriod,
  WorkerScheduleCanvas,
  WorkerSchedulePeriod,
  WorkerScheduleRow,
} from "../RunDetails.styled";
import {
  RequiredWorkerShift,
  SchedulingSolutionAssignedShift,
  WorkerToSchedule,
} from "../RunDetails.types";

import { WORKER_AVAILABILITY_ROW_HEIGHT } from "./constants";
import {
  getAssignedWorkerPositionTop,
  getPeriodPosition,
  getPeriodWidth,
  getWorkerRequiredPeriodHeight,
  VizHourRange,
} from "./getWorkerData";

export const getOnTheHourDateTimeStart = (time: string) => {
  const startDateTime = DateTime.fromISO(time);

  if (startDateTime.minute !== 0) {
    return startDateTime.set({ minute: 0 });
  } else {
    return startDateTime;
  }
};

type RenderHoursPanelsParams = { hourRange?: VizHourRange; theme: any };
export const renderHoursPanels = ({
  hourRange,
  theme,
}: RenderHoursPanelsParams) => {
  if (!hourRange) return <></>;

  const dateTimeStart = getOnTheHourDateTimeStart(hourRange.startTime);

  const panels = [];
  for (let i = 0; i <= hourRange.hourBlockCount; i++) {
    const thisDateTime = dateTimeStart.plus({
      hours: i,
    });

    panels.push(
      <StyledHourCol key={`${hourRange.startTime}-${i}`}>
        <Box pr={3} pb={2} pl={2}>
          <Text
            styleName="meta-2"
            styles={{
              letterSpacing: "0.01em",
              textTransform: "uppercase",
              color: theme.color.gray600,
            }}
          >
            {thisDateTime.toFormat("yyyy-MM-dd")}
          </Text>

          <Text styleName="body-2-bold" styles={{ color: theme.color.gray800 }}>
            {thisDateTime.toFormat("h a").toLowerCase()}
            <Text
              as="span"
              ml={1}
              styleName="label"
              styles={{ color: theme.color.gray800 }}
            >
              {thisDateTime.toFormat("ZZZZ")}
            </Text>
          </Text>
        </Box>

        <StyledHourPanel />
      </StyledHourCol>
    );
  }

  return panels.map((panel) => panel);
};

type RenderWorkerSchedulePeriodsParams = {
  theme: any;
  hourRange?: VizHourRange;
  workers?: WorkerToSchedule[];
  workerColors?: { [key: string]: string };
};
export const renderWorkerSchedulePeriods = ({
  hourRange,
  theme,
  workerColors,
  workers,
}: RenderWorkerSchedulePeriodsParams) => {
  if (!workers) return <></>;
  return (
    <WorkerScheduleCanvas pt={10}>
      {workers.map((worker, i) => {
        const workerPeriodColor = workerColors
          ? workerColors[worker.id]
          : theme.color.gray600;

        return (
          <WorkerScheduleRow
            key={`${worker.id}-${i}`}
            positionTop={WORKER_AVAILABILITY_ROW_HEIGHT * i}
          >
            {worker.availability.map((available, i) => (
              <WorkerSchedulePeriod
                key={`${available.start}-${i}`}
                width={getPeriodWidth(available)}
                positionLeft={getPeriodPosition(available, hourRange)}
                color={workerPeriodColor}
                backgroundColor={tinycolor(workerPeriodColor)
                  .setAlpha(0.3)
                  .toRgbString()}
              >
                <Text
                  mt={1}
                  ml={2}
                  as="span"
                  styleName="meta-1-bold"
                  styles={{
                    display: "block",
                    color: tinycolor(workerPeriodColor)
                      .darken(10)
                      .toHexString(),
                  }}
                  title={`${available.start} to ${available.end}`}
                >
                  {DateTime.fromISO(
                    available?.start || available?.start_time || ""
                  )
                    .toFormat("h:mma")
                    .toLowerCase()}{" "}
                  to{" "}
                  {DateTime.fromISO(available?.end || available?.end_time || "")
                    .toFormat("h:mma")
                    .toLowerCase()}
                </Text>
              </WorkerSchedulePeriod>
            ))}
          </WorkerScheduleRow>
        );
      })}
    </WorkerScheduleCanvas>
  );
};

type RenderWorkerRequiredPeriodsParams = {
  requiredWorkerShifts: RequiredWorkerShift[];
  theme: any;
  workerCount: number;
  hourRange?: VizHourRange;
};
export const renderWorkerRequiredPeriods = ({
  hourRange,
  requiredWorkerShifts,
  workerCount,
  theme,
}: RenderWorkerRequiredPeriodsParams) => {
  if (!Array.isArray(requiredWorkerShifts)) return <></>;
  return (
    <WorkerScheduleCanvas pt={rem(61)}>
      {requiredWorkerShifts.map((requiredWorkerShift, i) => {
        return (
          <WorkerRequiredPeriod
            key={`${requiredWorkerShift.start}-${i}`}
            width={getPeriodWidth(requiredWorkerShift, 1)}
            height={getWorkerRequiredPeriodHeight(workerCount, 1)}
            positionLeft={getPeriodPosition(requiredWorkerShift, hourRange)}
          >
            <Text
              as="span"
              mt={1}
              ml={1}
              pt={rem(1)}
              pr={rem(6)}
              pb={rem(2)}
              pl={rem(6)}
              styleName="meta-2-bold"
              styles={{
                display: "inline-block",
                color: theme.color.white,
                borderRadius: theme.border.radius,
                backgroundColor: theme.color.orange500,
              }}
            >
              {requiredWorkerShift.count}
            </Text>
          </WorkerRequiredPeriod>
        );
      })}
    </WorkerScheduleCanvas>
  );
};

type RenderAssignedWorkerPeriodsParams = {
  assignedWorkerShifts: SchedulingSolutionAssignedShift[];
  theme: any;
  hourRange?: VizHourRange;
  workerColors?: { [key: string]: string };
  workers?: WorkerToSchedule[];
};
export const renderAssignedWorkerPeriods = ({
  assignedWorkerShifts,
  hourRange,
  theme,
  workerColors,
  workers,
}: RenderAssignedWorkerPeriodsParams) => {
  return (
    <WorkerScheduleCanvas pt={10}>
      {assignedWorkerShifts.map((assignedWorkerShift) => {
        const workerPeriodColor = workerColors
          ? workerColors[assignedWorkerShift.worker_id]
          : theme.color.gray600;

        return (
          <WorkerScheduleRow
            key={assignedWorkerShift.worker_id}
            positionTop={
              WORKER_AVAILABILITY_ROW_HEIGHT *
              getAssignedWorkerPositionTop({
                workers,
                workerId: assignedWorkerShift.worker_id,
              })
            }
          >
            <WorkerSchedulePeriod
              width={getPeriodWidth(assignedWorkerShift)}
              positionLeft={getPeriodPosition(assignedWorkerShift, hourRange)}
              color={workerPeriodColor}
              backgroundColor={tinycolor(workerPeriodColor).toRgbString()}
            >
              <Text
                mt={1}
                ml={2}
                as="span"
                styleName="body-3-bold"
                styles={{
                  display: "block",
                  color: theme.color.white,
                }}
                title={`${assignedWorkerShift.start} to ${assignedWorkerShift.end}`}
              >
                {assignedWorkerShift.worker_id} &ndash;{" "}
                {DateTime.fromISO(assignedWorkerShift.start)
                  .toFormat("h:mma")
                  .toLowerCase()}{" "}
                to{" "}
                {DateTime.fromISO(assignedWorkerShift.end)
                  .toFormat("h:mma")
                  .toLowerCase()}
                {assignedWorkerShift?.shift_id && (
                  <Text
                    as="span"
                    ml={1}
                    styleName="meta-1-bold"
                    styles={{ color: theme.color.white }}
                  >
                    ({assignedWorkerShift.shift_id})
                  </Text>
                )}
              </Text>
            </WorkerSchedulePeriod>
          </WorkerScheduleRow>
        );
      })}
    </WorkerScheduleCanvas>
  );
};

type RenderWorkersParams = {
  theme: any;
  workerColors?: { [key: string]: string };
  workers?: WorkerToSchedule[];
};
export const renderWorkers = ({
  theme,
  workers,
  workerColors,
}: RenderWorkersParams) => {
  return workers
    ? workers.map((worker, i) => (
        <WorkerId
          key={`${worker.id}-${i}`}
          color={workerColors ? workerColors[worker.id] : theme.color.gray600}
        >
          {worker.id}
        </WorkerId>
      ))
    : [];
};
