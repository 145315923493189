import { MarketplaceAppConstraint } from "../../../api/core/controlPlane.types";

export const SHIFT_SCHEDULING_PARTNER_ID = "nextmv";
export const SHIFT_SCHEDULING_APP_ID = "shift.scheduling";
export const SHIFT_SCHEDULING_SUBSCRIPTION_ID = `${SHIFT_SCHEDULING_PARTNER_ID}-${SHIFT_SCHEDULING_APP_ID}`;
export const SHIFT_SCHEDULING_ICON_URL = "/images/logos/highs-icon.png";
export const SHIFT_SCHEDULING_NAME = "HiGHS Shift Scheduling";
export const SHIFT_SCHEDULING_TEMPLATE_NAME = "shift-scheduling-gosdk";
export const SHIFT_SCHEDULING_SHORT_DESC =
  "Assign workers to shifts, minimizing overages and maximizing objectives.";
export const SHIFT_SCHEDULING_SUMMARY =
  "Solve a shift scheduling problem with the low-code Nextmv Shift Scheduling app. Define available workers and open shifts, and then run the app to get an assigned shift plan.";
export const SHIFT_SCHEDULING_IMAGE_URL =
  "/images/illustration-marketplace-app-shift-scheduling.png";
export const SHIFT_SCHEDULING_IMAGE_ALT =
  "Abstract illustration of color-coded boxes on a calendar.";
export const SHIFT_SCHEDULING_SCREENSHOT_URL =
  "/images/marketplace-app-shift-scheduling-screenshot-1.png";
export const SHIFT_SCHEDULING_SCREENSHOT_ALT =
  "Two screenshots showing the shift scheduling app visualizations for the schedule input and result.";
export const SHIFT_SCHEDULING_DOCS_LINK =
  "https://www.nextmv.io/docs/community-apps/shift-scheduling";
export const SHIFT_SCHEDULING_CODE_REPO_LINK =
  "https://github.com/nextmv-io/community-apps/tree/develop/shift-scheduling-gosdk";
export const SHIFT_SCHEDULING_MODEL_TYPE = ["Scheduling"];
export const SHIFT_SCHEDULING_LANGUAGE = ["Go"];
export const SHIFT_SCHEDULING_PACKAGES = ["MIP"];
export const SHIFT_SCHEDULING_SOLVER = ["HiGHS"];

export const SHIFT_SCHEDULING_CONSTRAINTS: MarketplaceAppConstraint[] = [
  {
    id: "workers",
    name: "Workers",
    property: "workers",
    docsUrl:
      "https://www.nextmv.io/docs/shift-scheduling/reference/input-output-schema#input",
    summary: "Workers to assign.",
    type: "array of worker",
  },
  {
    id: "required-workers",
    name: "Required Workers",
    property: "required_workers",
    docsUrl:
      "https://www.nextmv.io/docs/shift-scheduling/reference/input-output-schema#input",
    summary:
      "A predicted need of a certain number of workers in defined time windows.",
    type: "array of required worker",
  },
  {
    id: "worker-availability",
    name: "Worker Availability",
    property: "availability",
    docsUrl:
      "https://www.nextmv.io/docs/shift-scheduling/reference/input-output-schema#worker",
    summary: "Available time windows for the worker.",
    type: "array of availability",
  },
  {
    id: "availability-start",
    name: "Availability Start",
    property: "start",
    docsUrl:
      "https://www.nextmv.io/docs/shift-scheduling/reference/input-output-schema#availability",
    summary: "The start of an availability time window of the worker.",
    type: "timestamp",
  },
  {
    id: "availability-end",
    name: "Availability End",
    property: "end",
    docsUrl:
      "https://www.nextmv.io/docs/shift-scheduling/reference/input-output-schema#availability",
    summary: "The end of an availability time window of the worker.",
    type: "timestamp",
  },
  {
    id: "required-worker-start",
    name: "Required Worker Start",
    property: "start",
    docsUrl:
      "https://www.nextmv.io/docs/shift-scheduling/reference/input-output-schema#required-worker",
    summary: "The start time of a required worker time window.",
    type: "timestamp",
  },
  {
    id: "required-worker-end",
    name: "Required Worker End",
    property: "end",
    docsUrl:
      "https://www.nextmv.io/docs/shift-scheduling/reference/input-output-schema#required-worker",
    summary: "The end time of a required worker time window.",
    type: "timestamp",
  },
  {
    id: "required-worker-count",
    name: "Required Worker Count",
    property: "count",
    docsUrl:
      "https://www.nextmv.io/docs/shift-scheduling/reference/input-output-schema#required-worker",
    summary:
      "The number of workers needed for the required worker time window.",
    type: "number",
  },
];
