import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  AvatarAppInput,
  Box,
  Button2,
  Flex,
  Loading,
  RowDetail,
  Tag,
  Text,
} from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../../../analytics";
import { useUser } from "../../../../AuthProvider";
import Header from "../../../../components/Header";
import { useMetaTitle } from "../../../../components/Layout";
import StandardError from "../../../../components/StandardError";
import { RUN_FORMAT_CSV_ARCHIVE } from "../../../../config/apps";
import { MEASURE_ENTITY_ROW } from "../../../../config/general";
import useAppInput from "../../../../contexts/apps/hooks/useAppInput";
import useManageEntity from "../../../../hooks/useManageEntity";
import { formatDate } from "../../../../utils/format";
import { getAccUrl } from "../../../../utils/navigation";
import { userHasAccessToAction } from "../../../../utils/rbac_utils";
import { ActionGroups } from "../../../../utils/rbac_utils/types";
import { appTooltipCopy } from "../../../Apps/data/microcopy";
import { AppPageProps } from "../../App.types";
import useReturnPaths from "../../hooks/useReturnPaths";

import { getAppInputFormatType } from "./utils/getAppInputFormatType";

const AppInput = ({ app }: AppPageProps) => {
  const [{ id: accountId, roles }] = useUser();
  const theme = useTheme();
  const [, setMetaTitle] = useMetaTitle();
  const params = useParams() as { id: string };
  const { pathname } = useLocation();
  const { returnPathList } = useReturnPaths();

  const refDownloadButton = useRef<any>();
  const [isDownloadInputInitiated, setIsDownloadInputInitiated] =
    useState(false);

  const { loadGzippedAppInput, loadGzippedAppInputError } = useAppInput();

  const {
    entity: appInput,
    entityLoadError: appInputError,
    loadEntity: loadAppInput,
  } = useManageEntity("inputs");

  const isAppInputGZipped =
    getAppInputFormatType(appInput) === RUN_FORMAT_CSV_ARCHIVE;

  // page display
  useEffect(() => {
    setMetaTitle(appInput?.name || "");
  }, [setMetaTitle, appInput]);

  useEffect(() => {
    if (loadGzippedAppInputError && isDownloadInputInitiated) {
      setIsDownloadInputInitiated(false);
    }
  }, [isDownloadInputInitiated, loadGzippedAppInputError]);

  // load app input details
  useEffect(() => {
    if (!appInput && !appInputError) {
      loadAppInput(app.id, params.id);
    }
  }, [app.id, appInput, appInputError, loadAppInput, params.id]);

  // programmatically initiate download
  useEffect(() => {
    if (appInput && !appInputError && isDownloadInputInitiated) {
      if (!isAppInputGZipped) {
        // write download link to button (a tag) and initiate user click
        if (refDownloadButton.current) {
          refDownloadButton.current.setAttribute("download", appInput.id);
          refDownloadButton.current.setAttribute("href", appInput.download_url);
          refDownloadButton.current.click();
        }
        setIsDownloadInputInitiated(false);
      } else {
        loadGzippedAppInput(appInput?.download_url, appInput?.id);
        setIsDownloadInputInitiated(false);
      }
    }
  }, [
    appInput,
    appInputError,
    isDownloadInputInitiated,
    isAppInputGZipped,
    loadGzippedAppInput,
  ]);

  if (appInputError) {
    return <StandardError errorMessage={appInputError} />;
  }
  if (!appInput) {
    return <Loading type="full-screen" dotColor={theme.color.orange500} />;
  }

  const handleDownloadInput = (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    if (!isDownloadInputInitiated) {
      e.preventDefault();
      e.stopPropagation();

      setIsDownloadInputInitiated(true);
    }
  };

  const canUserCreateAndEditInputs = userHasAccessToAction(
    roles,
    ActionGroups.InputSetOperator,
    {}
  );

  return (
    <>
      <Header
        configPageTitle={{
          label: appInput.name,
          ancestorIcon: <AvatarAppInput />,
          ancestorLabel: "Inputs",
          ancestorUrl: returnPathList,
        }}
        configActionButton={{
          label: "Edit",
          url: `${pathname}/edit`,
          onClick: () =>
            trackEvent("AppInputs", {
              view: "App Input",
              action: "Edit Button Clicked",
            }),
          isActionAllowed: canUserCreateAndEditInputs,
        }}
        secondaryButton={{
          label: "New input",
          url: `${returnPathList}/new`,
          isActionAllowed: canUserCreateAndEditInputs,
        }}
      />

      <RowDetail
        hasNoBorder
        property="Name"
        render={
          <Box maxWidth={MEASURE_ENTITY_ROW}>
            <Text styleName="body-2">{appInput.name}</Text>
          </Box>
        }
      />

      <RowDetail
        property="ID"
        tooltipCopy={appTooltipCopy.appInputId}
        render={
          <Flex>
            <Tag
              ml={rem(-6)}
              type="id"
              label={appInput.id}
              contentToCopy={appInput.id}
            />
          </Flex>
        }
      />

      {appInput.description && (
        <RowDetail
          property="Description"
          render={
            <Box maxWidth={MEASURE_ENTITY_ROW}>
              <Text styleName="body-2">{appInput.description}</Text>
            </Box>
          }
        />
      )}

      <RowDetail
        property="Date created"
        render={
          <Box maxWidth={MEASURE_ENTITY_ROW}>
            <Text as="time" styleName="body-2" htmlTitle={appInput.created_at}>
              {formatDate(appInput.created_at, true)}
            </Text>
          </Box>
        }
      />

      <RowDetail
        property="Last updated"
        render={
          <Box maxWidth={MEASURE_ENTITY_ROW}>
            <Text as="time" styleName="body-2" htmlTitle={appInput.updated_at}>
              {formatDate(appInput.updated_at, true)}
            </Text>
          </Box>
        }
      />

      {appInput.run_id && (
        <RowDetail
          property="Run ID"
          render={
            <Box maxWidth={MEASURE_ENTITY_ROW}>
              <Text styleName="body-2" hasLinkBlack>
                <Link
                  to={getAccUrl(
                    accountId,
                    `/app/${app.id}/run/${appInput.run_id}`
                  )}
                  target="_blank"
                  rel="noreferrer"
                >
                  {appInput.run_id}
                </Link>
              </Text>
            </Box>
          }
        />
      )}

      {loadGzippedAppInputError && (
        <StandardError errorMessage={loadGzippedAppInputError} />
      )}

      {appInput.download_url && (
        <Flex width="100%" pt={4} hasBorderTop>
          <Button2
            innerRef={refDownloadButton}
            type="outline"
            label="Download Input"
            href="#"
            onClick={handleDownloadInput}
            styles={{
              minWidth: rem(106),
            }}
          />
        </Flex>
      )}
    </>
  );
};

export default AppInput;
