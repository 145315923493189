import { isEmpty } from "lodash";

import { Subscription } from "../../api/payment/controlPlane.types";

export const isPaymentIncomplete = (
  subscription: Subscription | null | undefined,
  pendingSetupIntentSecret: string
) => {
  return (
    subscription?.planStatus === "incomplete" ||
    Boolean(subscription && pendingSetupIntentSecret)
  );
};

// https://stripe.com/docs/api/subscriptions/object#subscription_object-status
export const confirmUserSubscription = (
  subscription: Subscription | null | undefined,
  pendingSetupIntentSecret: string
) =>
  !!subscription &&
  !isEmpty(subscription) &&
  !["canceled", "incomplete", "incomplete_expired"].includes(
    subscription.planStatus
  ) &&
  !pendingSetupIntentSecret;
