import { Bin, Box, Flex, IconExecutable, Text } from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";

import { AppExecutable } from "../../../api/core/controlPlane.types";
import { formatDateTime12Hour } from "../../../utils/timeHelpers";
import { maskUserEmail } from "../../../utils/user";

type ExecutableDetailsProps = {
  executable?: AppExecutable;
  label?: string;
};

const ExecutableDetails = ({ executable, label }: ExecutableDetailsProps) => {
  const theme = useTheme();

  const renderExecutableDetails = () => {
    if (!executable) {
      return (
        <Text
          testId="no-active-executable-message"
          styleName="body-3"
          styles={{ color: theme.color.gray600 }}
        >
          None
        </Text>
      );
    }
    return (
      <>
        <Text styleName="body-3-bold" styles={{ color: theme.color.gray800 }}>
          {maskUserEmail(executable.user_email)}
        </Text>
        <Text mt={rem(2)} styleName="meta-1">
          {formatDateTime12Hour(executable?.uploaded_at)}
        </Text>

        {executable.requirements?.runtime &&
          executable.requirements?.executable_type && (
            <Text mt={1} styleName="meta-1-bold">
              {executable.requirements.runtime}{" "}
              {executable.requirements.executable_type}
            </Text>
          )}
      </>
    );
  };

  return (
    <Bin
      isMini
      {...(label && {
        title: (
          <Flex ml={rem(-2)}>
            <IconExecutable iconColor={theme.color.yellow700} />
            <Text
              as="h4"
              ml={1}
              styleName="meta-2-bold"
              styles={{ color: theme.color.yellow800 }}
            >
              {label}
            </Text>
          </Flex>
        ),
      })}
      width="100%"
      maxWidth={rem(296)}
    >
      <Box mt={label ? 2 : 0} pt={executable ? 0 : 1}>
        {!label && executable && (
          <IconExecutable mb={rem(2)} iconColor={theme.color.yellow700} />
        )}
        {renderExecutableDetails()}
      </Box>
    </Bin>
  );
};

export default ExecutableDetails;
