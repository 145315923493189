import { ApplicationRunTableRowData } from "../RunHistory.types";

export const getFilteredRuns = (
  filterText: string,
  runs: ApplicationRunTableRowData[]
) => {
  if (!filterText || !runs) return runs;
  return runs.filter((run) => {
    const id = run.id as string;
    return id && id.toLowerCase().includes(filterText.toLowerCase());
  });
};
