import { ConfigOption } from "../../../components/AddConfigOptions/AddConfigOptions.types";
import { PlanSet } from "../../../pages/Experiments/Experiments.types";

export const getFilteredPlanSetConfigOptions = (
  configOptions: ConfigOption[]
) => {
  return configOptions.filter((configOption) => {
    const trimmedOption = configOption.option.trim();
    const trimmedValue = configOption.value.trim();
    return trimmedOption && trimmedValue;
  });
};

const getPlanSetConfigOptionValues = (configOptions: ConfigOption[]) => {
  return getFilteredPlanSetConfigOptions(configOptions).reduce(
    (acc: string[][], configOption) => {
      const configOptionValues = configOption.value
        .split(",")
        .map((val) => val.trim())
        .filter(Boolean);
      return [...acc, configOptionValues];
    },
    []
  );
};

export const getPlanSetConfigOptionValueSets = (
  configOptions: ConfigOption[]
): string[][] => {
  const configOptionValueArrays = getPlanSetConfigOptionValues(configOptions);
  return configOptionValueArrays.reduce(
    (acc: string[][], curr: string[]) => {
      return acc.flatMap((val) => {
        return curr.map((v) => [val, v].flat());
      });
    },
    [[]]
  );
};

export const getPlanSetRunCount = (planSet: PlanSet) => {
  const configOptions = getFilteredPlanSetConfigOptions(planSet.configOptions);
  const cartesianConfigOptionValueSets =
    getPlanSetConfigOptionValueSets(configOptions);

  return (
    (planSet.inputCount || 0) * (cartesianConfigOptionValueSets.length || 1)
  );
};
