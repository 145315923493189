export const QUERY_PARAM_TYPE_SCENARIO_PLAN = "scenario";
export const DEFAULT_OPTION_SET_ID_LABEL = "scenario";

export const MAX_EXPERIMENT_RUN_LIMIT = 100;

export const EXPERIMENT_STATUS_CANCELED = "canceled" as const;
export const EXPERIMENT_STATUS_CANCELLED = "cancelled" as const;
export const EXPERIMENT_STATUS_COMPLETED = "completed" as const;
export const EXPERIMENT_STATUS_DRAFT = "draft" as const;
export const EXPERIMENT_STATUS_FAILED = "failed" as const;
export const EXPERIMENT_STATUS_STARTED = "started" as const;
export const EXPERIMENT_STATUS_UNKNOWN = "unknown" as const;

export const TEST_ACTION_CANCEL = "cancel" as const;
export const TEST_ACTION_COMPLETE = "complete" as const;
export const TEST_ACTION_START = "start" as const;

export const PAYLOAD_EXPERIMENT_TYPE_BATCH = "batch" as const;
export const PAYLOAD_EXPERIMENT_TYPE_SCENARIO = "scenario" as const;

// Input sets
export const INPUT_SET_MAX_INPUTS = 50;
export const INPUT_SET_MAX_RUN_IDS = 20;
export const INPUT_SET_CREATE_TYPE_INPUTS = "inputs" as const;
export const INPUT_SET_CREATE_TYPE_INSTANCE_DATE = "instance-date" as const;
export const INPUT_SET_CREATE_TYPE_RUN_IDS = "run-ids" as const;
export const INPUT_SET_CREATE_TYPE_UPLOAD_FILE = "upload-file" as const;

// Experiment runs
export const KEY_STATISTICS_STATUS = "statistics-status";

// Scenario testing
export const TAB_ID_INPUT_SET = "input-set" as const;
export const TAB_ID_UPLOAD_FILES = "upload-files" as const;
