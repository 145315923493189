import * as React from "react";

import BaseAvatar, { AvatarSize } from "./BaseAvatar";

const AvatarExperimentScenario = ({ size }: { size?: AvatarSize }) => {
  return (
    <BaseAvatar {...{ size }} viewBox={[0, 0, 24, 24]}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM8.39742 6.62512C7.55661 6.62512 6.875 7.30673 6.875 8.14755C6.875 8.98836 7.55661 9.66997 8.39742 9.66997C9.23824 9.66997 9.91985 8.98836 9.91985 8.14755C9.91985 7.30673 9.23824 6.62512 8.39742 6.62512ZM5.625 8.14755C5.625 6.61638 6.86626 5.37512 8.39742 5.37512C9.92859 5.37512 11.1698 6.61638 11.1698 8.14755C11.1698 9.67871 9.92859 10.92 8.39742 10.92C6.86626 10.92 5.625 9.67871 5.625 8.14755ZM17.9086 6.64189C18.1527 6.39782 18.1527 6.00209 17.9086 5.75801C17.6645 5.51393 17.2688 5.51393 17.0247 5.75801L15.4833 7.29941L13.9419 5.75803C13.6979 5.51396 13.3021 5.51396 13.0581 5.75803C12.814 6.00211 12.814 6.39784 13.0581 6.64192L14.5994 8.1833L13.0581 9.72468C12.814 9.96875 12.814 10.3645 13.0581 10.6086C13.3021 10.8526 13.6979 10.8526 13.9419 10.6086L15.4833 9.06718L17.0247 10.6086C17.2688 10.8527 17.6645 10.8527 17.9086 10.6086C18.1527 10.3645 18.1527 9.96878 17.9086 9.7247L16.3672 8.1833L17.9086 6.64189ZM7.225 16.7933L9.6955 15.4267L7.225 14.06V16.7933ZM5.975 13.424C5.975 12.7575 6.69037 12.3357 7.27355 12.6583L10.8937 14.661C11.4957 14.994 11.4957 15.8593 10.8937 16.1923L7.27355 18.195C6.69037 18.5176 5.975 18.0958 5.975 17.4293V13.424ZM12.775 13.55C12.775 13.0668 13.1668 12.675 13.65 12.675H17.35C17.8332 12.675 18.225 13.0668 18.225 13.55V17.25C18.225 17.7333 17.8332 18.125 17.35 18.125H13.65C13.1668 18.125 12.775 17.7333 12.775 17.25V13.55ZM14.025 13.925V16.875H16.975V13.925H14.025Z"
        fill="#CD672E"
      />
    </BaseAvatar>
  );
};

export default AvatarExperimentScenario;
