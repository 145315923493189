import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { order, space, width } from "styled-system";

import { styleAsCodeInline, styleCodeInline } from "../../lib/codeStyles";
import { filterProps } from "../../lib/filterProps";
import {
  styleAsLink,
  styleAsLinkGray,
  styleLinks,
  styleLinksBlack,
  styleLinksGray,
} from "../../lib/linkStyles";
import { CSSStylesProps } from "../../lib/renderExtraStyles";

import { getTextStyle } from "./utils/getTextStyle";
import { RenderTextStyleParams, StyledTextProps } from "./Text.types";

/*
  Note: the styles prop is run through Emotions `css` function
  which returns it as an object with the original styles processed
  to a `styles` prop (the same key names are a coincidence).
*/
const ui2RenderTextStyle = ({
  styleName,
  styles,
  theme,
}: RenderTextStyleParams) => {
  const textStyleBase = getTextStyle(styleName, theme);

  const renderTextStyle = (styles: CSSStylesProps) => `
    ${textStyleBase}
    ${css(styles).styles}

    /*
      antialias all except code (ternary structured this way 
      to deal with CSS hierarch issues, e.g. if a code style
      is used within another style)
    */
    ${
      styleName !== "code"
        ? `
          text-rendering: optimizeLegibility;
          -webkit-font-smoothing: antialiased;
        `
        : `
          text-rendering: auto !important;
          -webkit-font-smoothing: auto !important;
        `
    }
  `;

  return renderTextStyle(styles);
};

export const StyledText = styled("span", {
  shouldForwardProp: (prop: any) => filterProps(prop),
})<StyledTextProps>`
  /* styled-system design API */
  ${order}
  ${space}
  ${width}

  /* ui2 props */
  ${(props) =>
    props.styleName &&
    ui2RenderTextStyle({
      styleName: props.styleName,
      styles: props.styles,
      theme: props.theme,
    })}

  /* link styling */
  ${(props) => props.isLinkGray && styleAsLinkGray(props.theme)}
  ${(props) => props.isLink && styleAsLink(props.theme)}
  /* link styling for child elements */
  ${(props) => props.hasLinkGray && styleLinksGray(props.theme)}
  ${(props) => props.hasLinkBlack && styleLinksBlack(props.theme)}
  ${(props) => props.hasLink && styleLinks(props.theme)}

  /* code styling */
  ${(props) => props.isCodeInline && styleAsCodeInline(props.theme)}
  /* code styling for child elements */
  ${(props) => props.hasCodeInline && styleCodeInline(props.theme)}
`;
