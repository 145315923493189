import { RunsData } from "../../../api/core/controlPlane.types";
import { OptionSetDefinitionsByScenario } from "../../../components/ScenarioDefinitions/ScenarioDefinitions.types";
import { sortAlpha } from "../../../utils/sort";

export const getOptionSetsByScenarioFromRuns = (
  runs: RunsData
): OptionSetDefinitionsByScenario | undefined => {
  if (!runs) return;

  let scenarioOptionSets: OptionSetDefinitionsByScenario = {};

  runs.forEach((run) => {
    const {
      application_instance_id,
      application_version_id,
      input_set_id,
      option_set,
      options,
      scenario_id,
    } = run;

    if (!scenario_id || !option_set) return;

    // setup empty object for parent scenario
    if (!scenarioOptionSets[scenario_id]) {
      scenarioOptionSets[scenario_id] = {};
    }

    // add specific scenario option set definition
    if (!scenarioOptionSets[scenario_id][option_set]) {
      scenarioOptionSets[scenario_id][option_set] = {
        inputSetId: input_set_id,
        instanceId: application_instance_id,
        options: options,
        scenarioId: scenario_id,
        versionId: application_version_id,
      };
    }
  });

  // sort scenario and option set ID alphabetically
  const sortedScenarioOptionSets = Object.keys(scenarioOptionSets)
    .sort((a, b) => sortAlpha(a, b))
    .reduce((acc, key) => {
      const optionSets = scenarioOptionSets[key];
      const sortedOptionSets = Object.keys(optionSets)
        .sort((a, b) => sortAlpha(a, b))
        .reduce((acc, key) => {
          return { ...acc, [key]: optionSets[key] };
        }, {});

      return { ...acc, [key]: sortedOptionSets };
    }, {});

  return sortedScenarioOptionSets;
};
