import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import {
  PaymentIntent,
  PaymentIntentResult,
  SetupIntent,
  SetupIntentResult,
  StripeError,
} from "@stripe/stripe-js";

import { trackEvent } from "../../../analytics";
import {
  createOrGetPendingSubscription,
  getPendingSubscription,
} from "../../../api/payment/controlPlane";
import {
  PlanResponse,
  PromotionalCode,
  Subscription,
} from "../../../api/payment/controlPlane.types";
import { UserActionKind, useUser } from "../../../AuthProvider";
import { PLAN_TYPE_LEGACY_PREMIUM } from "../../../config/plans";
import {
  ReceiptData,
  SubscriptionAction,
  SubscriptionActionKind,
} from "../Subscription.context.types";

type intentType = "payment" | "setup";

export default function useCreateSubscription({
  setProcessing,
  setSubscriptionError,
  setReceiptData,
  pendingSetupIntentSecret,
  setPendingSetupIntentSecret,
  paymentIsIncomplete,
  subscriptionDispatch,
  paymentIntentSecret,
  setPaymentIntentSecret,
}: {
  setProcessing: (isProcessing: boolean) => void;
  setSubscriptionError: (error: string) => void;
  setReceiptData: (value: ReceiptData) => void;
  pendingSetupIntentSecret: string;
  setPendingSetupIntentSecret: (secret: string) => void;
  paymentIsIncomplete: boolean;
  subscriptionDispatch: React.Dispatch<SubscriptionAction>;
  paymentIntentSecret: string;
  setPaymentIntentSecret: (secret: string) => void;
}) {
  const stripe = useStripe();
  const elements = useElements();
  const [user, userDispatch] = useUser();

  if (!(stripe && elements)) {
    return () => null;
  }

  const checkoutError = (
    error: StripeError,
    planData: Subscription | PlanResponse
  ) => {
    trackEvent("Billing", {
      view: "Checkout",
      action: "Error Processing Payment",
      meta: {
        errorMessage: error.message,
        selectedPlan: `$${planData?.price?.toLocaleString()}: ${planData?.decisionsPerMonth?.toLocaleString()}`,
        type: planData.planType,
      },
    });
    throw new Error(`${error.message}`);
  };

  const checkoutSuccess = (
    intent: SetupIntent | PaymentIntent,
    planData: Subscription | PlanResponse,
    promoCode?: PromotionalCode | undefined
  ) => {
    trackEvent("Billing", {
      view: "Checkout",
      action: "Payment Successful",
      meta: {
        selectedPlan: `$${planData?.price?.toLocaleString()}: ${planData?.decisionsPerMonth?.toLocaleString()}`,
        type: planData.planType,
      },
    });

    const { id, created } = intent;
    setReceiptData({
      amount: "amount" in intent ? intent.amount : 0,
      id,
      created,
      ...(promoCode && { couponCode: promoCode?.customerFacingCode }),
    });

    if (planData.planType === PLAN_TYPE_LEGACY_PREMIUM) {
      userDispatch({ type: UserActionKind.ADD_FLAGS_FROM_CONFIG });
    }
  };

  const confirmCard = async (
    intentSecret: string,
    intentType: intentType,
    name: string
  ) => {
    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      console.error("Card element was not accessible");
      throw new Error("There was an error with processing the payment.");
    }

    const payload = {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: name,
        },
      },
    };

    return intentType === "payment"
      ? stripe.confirmCardPayment(intentSecret, payload)
      : stripe.confirmCardSetup(intentSecret, payload);
  };

  const handleIntent = async (
    intentSecret: string,
    intentType: intentType,
    name: string,
    planData: Subscription | PlanResponse,
    promoCode?: PromotionalCode | undefined
  ) => {
    try {
      let intent;
      let error;
      if (intentType === "payment") {
        const intentResult = (await confirmCard(
          intentSecret,
          intentType,
          name
        )) as PaymentIntentResult;

        error = intentResult.error;
        intent = intentResult.paymentIntent;
      } else {
        const intentResult = (await confirmCard(
          intentSecret,
          intentType,
          name
        )) as SetupIntentResult;

        error = intentResult.error;
        intent = intentResult.setupIntent;
      }

      if (error) {
        checkoutError(error, planData);
      }

      if (intent) {
        checkoutSuccess(intent, planData, promoCode);
      }
    } catch (e) {
      setProcessing(false);
      return setSubscriptionError((e as any).message);
    }
  };

  const createSubscription = async (
    name: string,
    agreeToTerms: boolean,
    planData: Subscription | PlanResponse,
    promoCode: PromotionalCode | undefined
  ) => {
    // clear any prior error messages
    setSubscriptionError("");

    // if somehow get through DOM required inputs,
    // return with an error message
    if (!name) {
      return setSubscriptionError("Name is a required field.");
    }
    if (!agreeToTerms) {
      return setSubscriptionError(
        "The terms and conditions must be agreed to."
      );
    }

    // button loading
    setProcessing(true);

    // subscription already created, setupIntent has not been properly used (i.e.
    // error from Stripe when processing the card)
    if (pendingSetupIntentSecret) {
      await handleIntent(
        pendingSetupIntentSecret,
        "setup",
        name,
        planData,
        promoCode
      );
    } else if (paymentIntentSecret) {
      // subscription already created, paymentIntent has not been properly used
      await handleIntent(paymentIntentSecret, "payment", name, planData);
    } else {
      // subscription either does not exist, or is in a pending state
      let paymentIntentSecretResp = "";
      let pendingSetupIntentSecretResp = "";

      try {
        const {
          paymentIntentSecret: returnedPaymentIntentSecret,
          pendingSetupIntentSecret,
          subscription,
        } = paymentIsIncomplete
          ? await getPendingSubscription()
          : await createOrGetPendingSubscription(
              JSON.stringify({
                planId: planData?.planId,
                account: user.id,
                promoCode: promoCode?.customerFacingCode,
              })
            );
        if (!subscription) {
          throw new Error("Error creating or getting subscription");
        }
        paymentIntentSecretResp = returnedPaymentIntentSecret;
        pendingSetupIntentSecretResp = pendingSetupIntentSecret;
        setPendingSetupIntentSecret(pendingSetupIntentSecret);
        setPaymentIntentSecret(returnedPaymentIntentSecret);

        subscriptionDispatch({
          type: SubscriptionActionKind.UPDATE,
          payload: subscription,
        });
      } catch (e) {
        console.error(e);
        trackEvent("Billing", {
          view: "Checkout",
          action: "Error Creating User Subscription",
          meta: {
            selectedPlan: `$${planData?.price?.toLocaleString()}: ${planData?.decisionsPerMonth?.toLocaleString()}`,
            type: planData.planType,
          },
        });
        setProcessing(false);
        return setSubscriptionError(
          "Unable to create or retrieve subscription"
        );
      }
      // subscription's first invoice is non-0, paymentIntent needs to be used
      // to confirm card & pay first invoice
      if (paymentIntentSecretResp) {
        await handleIntent(paymentIntentSecretResp, "payment", name, planData);
      } else if (pendingSetupIntentSecretResp) {
        // first invoice for subscription is 0 (due to promo code),
        // run setupIntent in order to attach payment info to subscription
        await handleIntent(
          pendingSetupIntentSecretResp,
          "setup",
          name,
          planData,
          promoCode
        );
      } else {
        checkoutError(
          {
            message: "Error processing payment",
          } as StripeError,
          planData
        );
      }
    }
  };

  return createSubscription;
}
