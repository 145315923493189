import {
  BatchExperimentMetadata,
  ExperimentStatus,
} from "../../../api/core/controlPlane.types";
import { UserRoles } from "../../../api/core/dataPlane.types";
import {
  EXPERIMENT_STATUS_CANCELED,
  EXPERIMENT_STATUS_CANCELLED,
  EXPERIMENT_STATUS_COMPLETED,
  EXPERIMENT_STATUS_FAILED,
} from "../../../config/experiments";
import { userHasAccessToAction } from "../../../utils/rbac_utils";
import { ActionGroups } from "../../../utils/rbac_utils/types";

export const shouldDisplayExperimentActions = (
  status: ExperimentStatus,
  roles: UserRoles = []
) => {
  const userCanPerformActions = userHasAccessToAction(
    roles,
    ActionGroups.ExperimentOperator,
    {}
  );
  if (!userCanPerformActions) {
    return false;
  }
  if (["completed", "canceled", "cancelled"].includes(status)) {
    return false;
  }
  return true;
};

export const getIsExperimentResolved = (
  experiment: BatchExperimentMetadata
): boolean => {
  if (!experiment) return false;

  const status = experiment.status;

  return (
    status === EXPERIMENT_STATUS_CANCELED ||
    status === EXPERIMENT_STATUS_CANCELLED ||
    status === EXPERIMENT_STATUS_COMPLETED ||
    status === EXPERIMENT_STATUS_FAILED
  );
};
