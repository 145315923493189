import * as React from "react";
import { components } from "react-select";

import { renderIntegrationOption } from "./OptionIntegration";

// get SingleValue base component from library
const { SingleValue } = components;

const CustomSingleValueIntegration = (props: any) => {
  // get data to build option, this is the object from
  // the data array for this particular option
  const { data } = props;

  return <SingleValue {...props}>{renderIntegrationOption(data)}</SingleValue>;
};

export default CustomSingleValueIntegration;
