import * as React from "react";

import BaseIcon, { IconProps } from "./BaseIcon";

const IconEyeOpen = ({
  iconSize,
  iconColor,
  iconColorActive,
  iconColorFocus,
  iconColorHover,
  ...rest
}: IconProps) => {
  return (
    <BaseIcon
      {...{
        iconSize,
        iconColor,
        iconColorActive,
        iconColorFocus,
        iconColorHover,
      }}
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00004 10.7717C5.5092 10.7717 3.39841 9.52609 2.0374 7.58494C3.39841 5.64376 5.5092 4.39811 8.00004 4.39811C10.4909 4.39811 12.6017 5.64376 13.9627 7.58494C12.6017 9.52609 10.4909 10.7717 8.00004 10.7717ZM8.00004 2.99811C4.81593 2.99811 2.17461 4.70176 0.604508 7.21394C0.462641 7.44093 0.462641 7.72895 0.60451 7.95594C2.17461 10.4681 4.81593 12.1717 8.00004 12.1717C11.1842 12.1717 13.8255 10.4681 15.3956 7.95594C15.5374 7.72895 15.5374 7.44093 15.3956 7.21394C13.8255 4.70176 11.1842 2.99811 8.00004 2.99811ZM8.00001 9.52834C9.07333 9.52834 9.94342 8.65824 9.94342 7.58493C9.94342 6.51161 9.07333 5.64152 8.00001 5.64152C6.9267 5.64152 6.05661 6.51161 6.05661 7.58493C6.05661 8.65824 6.9267 9.52834 8.00001 9.52834Z"
      />
    </BaseIcon>
  );
};

export default IconEyeOpen;
