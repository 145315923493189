import { getPathWithoutOrg } from "./getPathWithoutOrg";

type IsActivePageParams = {
  currentPath: string;
  pageUrl: string;
};

export const removeLeadingSlash = (path: string) => {
  if (path.startsWith("/")) {
    return path.substring(1);
  }
  return path;
};

export const checkIsActivePage = ({
  currentPath,
  pageUrl,
}: IsActivePageParams) => {
  return currentPath === pageUrl;
};

export const checkIsActiveSection = ({
  currentPath,
  pageUrl,
}: IsActivePageParams) => {
  const pagePathWithoutOrg = getPathWithoutOrg(pageUrl);
  // split path into sub routes while keeping the "/" slug prefix
  const pathSlugs = currentPath.split(/(?=\/)/g);
  return pathSlugs[0] === pagePathWithoutOrg;
};

export const getAccUrl = (accId: string | null | undefined, path: string) => {
  if (!accId) return `/${removeLeadingSlash(path)}`;
  return `/acc/${accId}/${removeLeadingSlash(path)}`;
};

// attempt to remember URL before log in redirect
export type LocationState = {
  from: {
    pathname: string;
  };
};

export const getFromUrl = (state: LocationState) => {
  const fromUrl: string = state?.from?.pathname || "/";
  return fromUrl;
};
export const navigateToAttemptedPage = (state: LocationState) => {
  window.location.href = getFromUrl(state);
};
