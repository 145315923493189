import * as React from "react";

import BaseIcon, { IconProps } from "./BaseIcon";

const IconProfileAPIKey = ({
  iconColor,
  iconColorActive,
  iconColorFocus,
  iconColorHover,
  ...rest
}: IconProps) => {
  return (
    <BaseIcon
      {...{
        iconColor,
        iconColorActive,
        iconColorFocus,
        iconColorHover,
      }}
      iconSize={32}
      viewBox={[0, 0, 32, 32]}
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.5 16C30.5 24.0081 24.0081 30.5 16 30.5C7.99187 30.5 1.5 24.0081 1.5 16C1.5 7.99187 7.99187 1.5 16 1.5C24.0081 1.5 30.5 7.99187 30.5 16ZM32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16ZM16 13.75H24.25L26.3125 14.5V16.9584L24.8438 18.1007L23.9062 16.75L22.5312 18.5381L21.1562 16.75L19.7812 18.56L16 18.5625L14.7625 20.2125C14.5063 20.554 14.1742 20.8312 13.7923 21.0222C13.4105 21.2131 12.9894 21.3125 12.5625 21.3125H9.8125C9.45136 21.3125 9.09376 21.2414 8.76011 21.1032C8.42646 20.965 8.1233 20.7624 7.86794 20.5071C7.61258 20.2517 7.41001 19.9485 7.27182 19.6149C7.13362 19.2812 7.06249 18.9236 7.0625 18.5625V13.75C7.06249 13.3889 7.13362 13.0313 7.27182 12.6976C7.41001 12.364 7.61258 12.0608 7.86794 11.8054C8.1233 11.5501 8.42646 11.3475 8.76011 11.2093C9.09376 11.0711 9.45136 11 9.8125 11H12.5625C12.9894 11 13.4105 11.0994 13.7923 11.2903C14.1742 11.4813 14.5063 11.7585 14.7625 12.1L16 13.75ZM10.9861 17.8993C11.1151 17.7704 11.1875 17.5955 11.1875 17.4132V14.8958C11.1875 14.7135 11.1151 14.5386 10.9861 14.4097C10.8572 14.2808 10.6823 14.2083 10.5 14.2083H9.8125C9.63016 14.2083 9.4553 14.2808 9.32636 14.4097C9.19743 14.5386 9.125 14.7135 9.125 14.8958V17.4132C9.125 17.5955 9.19743 17.7704 9.32636 17.8993C9.4553 18.0282 9.63016 18.1007 9.8125 18.1007H10.5C10.6823 18.1007 10.8572 18.0282 10.9861 17.8993Z"
      />
    </BaseIcon>
  );
};

export default IconProfileAPIKey;
