import { AppsData, AppType } from "../../../api/core/controlPlane.types";

type GetAppsListParams = {
  apps: AppsData;
  type?: AppType;
};
export const getAppsByType = ({ apps, type }: GetAppsListParams): AppsData => {
  if (!apps) return [];
  if (type) {
    return apps.filter((app) => app.type === type);
  }
  return apps;
};
