import { PageNavigationItem } from "../../../components/MainNav/MainNav.types";

export const configPages: PageNavigationItem[] = [
  {
    id: "run-profiles",
    label: "Run Profiles",
    url: "/config/run-profiles",
  },
  {
    id: "my-integrations",
    label: "My Integrations",
    url: "/config/integrations/installed",
  },
  {
    id: "section-integrations",
    label: "integrations",
    isSectionLabel: true,
  },
  {
    id: "integrations-nextmv-routing",
    label: "Nextmv Routing",
    url: "/config/integrations/nextmv-routing",
  },
];
