import { PlanResponse } from "../../api/payment/controlPlane.types";
import { PromotionalCode } from "../../api/payment/paymentApi.types";

export enum PlansActionKind {
  LOAD,
  SET_ERROR,
  LOADING,
  SET_SELECTED_PLAN,
  SET_PROMO_CODE,
  PROMO_CODE_LOADING,
  PLAN_CUSTOM_LOADING,
}

export type PlansError = "plans" | "promo" | "";
export type PlanCustomError = "no-plan" | "other" | "";

interface LOAD {
  type: PlansActionKind.LOAD;
  payload: PlanResponse[];
}

interface SET_ERROR {
  type: PlansActionKind.SET_ERROR;
  payload: PlansError;
}

interface SET_SELECTED_PLAN {
  type: PlansActionKind.SET_SELECTED_PLAN;
  payload: PlanResponse | undefined;
}

interface SET_PROMO_CODE {
  type: PlansActionKind.SET_PROMO_CODE;
  payload: PromotionalCode | undefined;
}

interface LOADING {
  type: PlansActionKind.LOADING;
  payload: boolean;
}

interface PROMO_CODE_LOADING {
  type: PlansActionKind.PROMO_CODE_LOADING;
  payload: boolean;
}

interface PLAN_CUSTOM_LOADING {
  type: PlansActionKind.PLAN_CUSTOM_LOADING;
  payload: boolean;
}

export type PlansAction =
  | LOAD
  | SET_ERROR
  | LOADING
  | PROMO_CODE_LOADING
  | SET_SELECTED_PLAN
  | SET_PROMO_CODE
  | PLAN_CUSTOM_LOADING;

export type PlansState = {
  plans: PlanResponse[];
  selectedPlan: PlanResponse | undefined;
  promoCode: PromotionalCode | undefined;
  error: PlansError;
  loading: boolean;
  promoCodeLoading: boolean;
};

export type PlansContextProps = {
  loadPlans: () => Promise<PlanResponse[] | undefined>;
  loadPromoCode: (
    plans: PlanResponse[]
  ) => Promise<PromotionalCode | undefined>;
  loadPlanCustom: (id: string) => Promise<any | undefined>;
  loadPlansAndPromo: () => void;
  doesPromoExist: boolean;
  getAllPlans: () => PlanResponse[];
  selectedPlan: PlanResponse | undefined;
  setSelectedPlan: (plan: PlanResponse | undefined) => void;
  plansError: PlansError;
  setPlansError: (error: PlansError) => void;
  promoCode: PromotionalCode | undefined;
  setPromoCode: (promoCode: PromotionalCode | undefined) => void;
  plansLoading: boolean;
  promoCodeLoading: boolean;
  setPromoCodeLoading: (isLoading: boolean) => void;
  planCustom: PlanResponse | undefined;
  planCustomError: PlanCustomError;
  planCustomLoading: boolean;
  setPlanCustomError: (error: PlanCustomError) => void;
  setPlanCustomLoading: (isLoading: boolean) => void;
};
