import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import { getPlans } from "../../api/payment/controlPlane";
import { PlanResponse } from "../../api/payment/controlPlane.types";

import usePlanCustom from "./hooks/usePlanCustom";
import usePlansReducer from "./hooks/usePlansReducer";
import { PlansContextProps } from "./Plans.context.types";
import { checkPromoCode, getPlanData, getPromoCode } from "./utils";

export const PlansContext = React.createContext<PlansContextProps>({
  loadPlans: () => new Promise((resolve) => resolve([])),
  loadPromoCode: () => new Promise((resolve) => resolve(undefined)),
  loadPlanCustom: (id) => new Promise((resolve) => resolve(undefined)),
  loadPlansAndPromo: () => null,
  doesPromoExist: false,
  getAllPlans: () => [],
  setSelectedPlan: (plan) => null,
  selectedPlan: undefined,
  plansError: "",
  setPlansError: (error) => null,
  plansLoading: true,
  promoCode: undefined,
  setPromoCode: (code) => null,
  promoCodeLoading: true,
  setPromoCodeLoading: () => null,
  planCustom: undefined,
  planCustomError: "",
  planCustomLoading: true,
  setPlanCustomError: (error) => null,
  setPlanCustomLoading: () => null,
});

const PlansProvider = ({ children }: { children: React.ReactNode }) => {
  const { search } = useLocation();

  // @legacy-plans (unused)
  const {
    selectedPlan,
    promoCode,
    error,
    loading,
    plans,
    promoCodeLoading,
    updatePlans,
    setPlansError,
    setPlansLoading,
    setPromoCode,
    setPromoCodeLoading,
    setSelectedPlan,
  } = usePlansReducer();

  const {
    planCustom,
    planCustomError,
    planCustomLoading,
    setPlanCustom,
    setPlanCustomError,
    setPlanCustomLoading,
  } = usePlanCustom();

  const history = useHistory();

  // @legacy-plans (unused)
  const getAllPlans = React.useCallback((): PlanResponse[] => plans, [plans]);

  // @legacy-plans (unused)
  const loadPromoCode = React.useCallback(
    async (plans: PlanResponse[]) => {
      setPromoCodeLoading(true);
      try {
        const promoCodeDetails = await getPromoCode(search);
        if (promoCodeDetails) {
          const [promoCode, promoValue] = promoCodeDetails;
          const parsedPromoCode = {
            ...promoCode,
            customerFacingCode: promoValue,
          };
          const planToSet = [...plans].filter(
            (plan: PlanResponse) => plan.planId === promoCode?.planId
          )[0];
          if (!planToSet) {
            throw new Error(
              "Plan for promotional code not found in list of loaded plans."
            );
          }
          setPromoCode(parsedPromoCode);
          setSelectedPlan(planToSet);
          setPromoCodeLoading(false);
          return parsedPromoCode;
        } else {
          setPromoCodeLoading(false);
        }
      } catch (e: any) {
        console.error(e);
        setPlansError("promo");
        setPromoCodeLoading(false);
      }
    },
    [search, setPlansError, setPromoCode, setPromoCodeLoading, setSelectedPlan]
  );

  const loadPlanCustom = React.useCallback(
    async (priceId: string) => {
      setPlanCustomLoading(true);

      try {
        const planCustomData = await getPlanData(priceId);

        if (planCustomData) {
          const [planData] = planCustomData;

          setPlanCustom(planData);
          setPlanCustomLoading(false);
          return planData;
        } else {
          // NOTE: not sure if there is a scenario that ends up here
          setPlanCustomError("no-plan");
          setPlanCustomLoading(false);
        }
      } catch (e: any) {
        console.error(e);
        setPlanCustomError("other");
        setPlanCustomLoading(false);
      }
    },
    [setPlanCustom, setPlanCustomError, setPlanCustomLoading]
  );

  // @legacy-plans (unused)
  const doesPromoExist = React.useMemo(() => {
    return checkPromoCode(search);
  }, [search]);

  // @legacy-plans (unused)
  const loadPlans = React.useCallback(async () => {
    setPlansLoading(true);
    try {
      const plans = await getPlans();
      updatePlans(plans);
      setPlansLoading(false);
      return plans;
    } catch (e: any) {
      console.error(e);
      setPlansError("plans");
      setPlansLoading(false);
    }
  }, [setPlansLoading, updatePlans, setPlansError]);

  // @legacy-plans (unused)
  const loadPlansAndPromo = React.useCallback(async () => {
    const plans = await loadPlans();
    if (doesPromoExist && plans) {
      const promoCode = await loadPromoCode(plans);
      promoCode &&
        history.push(`/billing/checkout?promo=${promoCode.customerFacingCode}`);
    }
  }, [doesPromoExist, history, loadPlans, loadPromoCode]);

  const value: PlansContextProps = {
    // @legacy-plans (unused)
    getAllPlans,
    loadPlans,
    loadPromoCode,
    loadPlansAndPromo,
    doesPromoExist,
    selectedPlan,
    setSelectedPlan,
    promoCode,
    promoCodeLoading,
    setPromoCodeLoading,
    setPromoCode,
    plansError: error,
    setPlansError,
    plansLoading: loading,

    // currently used by CustomPlan.page
    loadPlanCustom,
    planCustom,
    planCustomError,
    planCustomLoading,
    setPlanCustomError,
    setPlanCustomLoading,
  };

  return (
    <PlansContext.Provider value={value}>{children}</PlansContext.Provider>
  );
};

// usePlans() only used on CustomPlan.page
// with PlansProvider passed through authenticatedConfig)
export const usePlans = () => React.useContext(PlansContext);

export default PlansProvider;
