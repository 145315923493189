import { CreateAppSubscriptionPayload } from "../../api/core/controlPlane.types";

export type MarketplaceContextProps = {
  addNewAppSubscription: (payload: CreateAppSubscriptionPayload) => void;
  appSubscriptions: AppSubscription[];
  appSubscriptionsActionError: string;
  appSubscriptionsActionLoading: boolean;
  appSubscriptionsError: string;
  appSubscriptionsLoading: boolean;
  clearAppSubscriptionsActionError: () => void;
  setAppSubscriptions: React.Dispatch<React.SetStateAction<AppSubscription[]>>;
  setAppSubscriptionsError: React.Dispatch<React.SetStateAction<string>>;
  setAppSubscriptionsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  loadAppSubscriptions: () => Promise<any | undefined>;
};

export type AppSubscription = {
  app_title: string;
  partner_name: string;
  subscription_date: string;
  subscription_id: string;
};

export enum AppSubscriptionsActionKind {
  ADD,
  SET_ERROR,
  CLEAR_ERROR,
  LOADING,
}

interface ADD {
  type: AppSubscriptionsActionKind.ADD;
  payload: CreateAppSubscriptionPayload;
}

interface SET_ERROR {
  type: AppSubscriptionsActionKind.SET_ERROR;
  payload: string;
}

interface CLEAR_ERROR {
  type: AppSubscriptionsActionKind.CLEAR_ERROR;
}

interface LOADING {
  type: AppSubscriptionsActionKind.LOADING;
}

export type AppSubscriptionsState = {
  error: string;
  loading: boolean;
};
export type AppSubscriptionsAction = ADD | SET_ERROR | CLEAR_ERROR | LOADING;
