import * as React from "react";

import BaseIcon, { IconProps } from "./BaseIcon";

const IconCopy = ({
  iconSize,
  iconColor,
  iconColorActive,
  iconColorFocus,
  iconColorHover,
  ...rest
}: IconProps) => {
  return (
    <BaseIcon
      {...{
        iconSize,
        iconColor,
        iconColorActive,
        iconColorFocus,
        iconColorHover,
      }}
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 10.2C0.5 10.8627 1.03726 11.4 1.7 11.4H4.90004V13.9999C4.90004 14.6627 5.4373 15.1999 6.10004 15.1999H14.1C14.7628 15.1999 15.3 14.6627 15.3 13.9999V5.99994C15.3 5.3372 14.7628 4.79994 14.1 4.79994H10.9V2.2C10.9 1.53726 10.3627 1 9.7 1H1.7C1.03726 1 0.5 1.53726 0.5 2.2V10.2ZM9.5 4.79994V2.4L1.9 2.4L1.9 10H4.90004V5.99994C4.90004 5.3372 5.43729 4.79994 6.10004 4.79994H9.5ZM6.30004 13.7999V6.19994H13.9V13.7999H6.30004Z"
      />
    </BaseIcon>
  );
};

export default IconCopy;
