import * as React from "react";

import BaseAvatar, { AvatarSize } from "./BaseAvatar";

type AvatarAppVersionProps = {
  size?: AvatarSize;
  type: "active" | "inactive";
};

const AvatarAppVersion = ({ size, type }: AvatarAppVersionProps) => {
  return (
    <BaseAvatar {...{ size }} viewBox={[0, 0, 24, 24]}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3304 4.00042C12.121 3.90269 11.879 3.90269 11.6696 4.00042L4.66962 7.26709C4.39456 7.39545 4.21875 7.67151 4.21875 7.97504V16.025C4.21875 16.3286 4.39456 16.6046 4.66962 16.733L11.6696 19.9997C11.879 20.0974 12.121 20.0974 12.3304 19.9997L19.3304 16.733C19.6054 16.6046 19.7813 16.3286 19.7813 16.025V7.97504C19.7813 7.67151 19.6054 7.39545 19.3304 7.26709L12.3304 4.00042ZM12 10.1012L6.91889 7.94169L12 5.57051L17.0811 7.94169L12 10.1012ZM5.78125 15.5275V9.15595L11.2188 11.4669V18.065L5.78125 15.5275ZM12.7813 18.065V11.4669L18.2188 9.15595V15.5275L12.7813 18.065Z"
        fill="#F7B100"
      />
    </BaseAvatar>
  );
};

export default AvatarAppVersion;
