// import Theme from '../style/emotion.d'
import { rem } from "./tools";

// hide visually only, screen readers have access
export const styleHideVisually = `
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
`;

// undo styleHideVisually
export const styleHideVisuallyReset = `
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  position: inherit;
  overflow: visible;
  clip: auto;
  clip-path: none;
`;

/*
  hide scrollbar completely,
  see: https://stackoverflow.com/a/49278385/1241287
*/
export const hideScrollbar = () => {
  return `
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  `;
};

/*
  custom scrollbar styles
  these styles are mainly used for inner elements scrolling
*/
export const styleScrollbarY = (theme: any) => {
  return `
    /* firefox */
    scrollbar-width: thin;
    /* thumb track */
    scrollbar-color: ${theme.color.gray400} transparent;

    /* chrome, edge */
    ::-webkit-scrollbar {
      width: ${rem(12)};
      background-color: transparent;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=);
      background-repeat: repeat;
      background-size: 100% auto;
    }
    ::-webkit-scrollbar-track {
      border: ${rem(3)} solid transparent;
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      border: ${rem(3)} solid transparent;
      border-radius: ${rem(6)};
      box-shadow: inset 0 0 0 3px ${theme.color.gray400};
    }
  `;
};
export const styleScrollbarX = (theme: any) => {
  return `
    /* firefox */
    scrollbar-width: thin;
    /* thumb track */
    scrollbar-color: ${theme.color.gray400T} transparent;

    /* chrome, edge */
    ::-webkit-scrollbar {
      height: ${rem(12)};
      background-color: transparent;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=);
      background-repeat: repeat;
      background-size: 100% auto;
    }
    ::-webkit-scrollbar-track {
      border: ${rem(3)} solid transparent;
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      border: ${rem(3)} solid transparent;
      border-radius: ${rem(6)};
      box-shadow: inset 0 0 0 3px ${theme.color.gray400T};
    }
  `;
};

// base style for icon components in icons directory
export const svgIconStyle = ({
  activeColor,
  focusColor,
  hoverColor,
}: {
  activeColor?: string;
  focusColor?: string;
  hoverColor?: string;
}) => {
  return `
  flex-shrink: 0;
  ${
    !!hoverColor &&
    `
    &:hover {
      fill: ${hoverColor};
    }`
  }
  ${
    !!focusColor &&
    `
    &:focus {
      fill: ${focusColor};
    }`
  }
  ${
    !!activeColor &&
    `
    &:active {
      fill: ${activeColor};
    }`
  }
  `;
};
