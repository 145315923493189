import { trackEvent } from "../../../analytics";
import {
  PutRunDetailsPayload,
  RunMetadataResponse,
} from "../../../api/core/controlPlane.types";

export const trackRunDetailsFieldChanges = (
  runMetadata: RunMetadataResponse,
  pendingRunDetails: PutRunDetailsPayload
) => {
  let runMetadataKey: keyof RunMetadataResponse;
  for (runMetadataKey in runMetadata) {
    if (
      ["name", "description"].includes(runMetadataKey) &&
      runMetadata[runMetadataKey] !==
        pendingRunDetails[runMetadataKey as "name" | "description"]
    ) {
      trackEvent("RunHistory", {
        view: "Edit Run Details",
        action: "Field Changed",
        meta: {
          field: runMetadataKey as "name" | "description",
        },
      });
    }
  }
};
