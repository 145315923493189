export const COMPATIBILITY_ITEMS = {
  tasks: [
    { id: "complete_before_after", label: "Complete before & after" },
    { id: "dependencies", label: "Dependencies" },
    { id: "location", label: "Location" },
    { id: "quantity", label: "Quantity" },
    { id: "service_time", label: "Service time" },
  ],
  workers: [
    { id: "active_task", label: "Active task" },
    { id: "capacity", label: "Capacity" },
    { id: "location", label: "Location" },
    { id: "tasks", label: "Tasks" },
  ],
};
