import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Box, Text } from "@console/dsc";

import Header from "../../components/Header";

const pageTitle = "Page not found";
const NotFound = () => {
  return (
    <Box mr={[4, 0]} ml={[4, 6]}>
      <Helmet>
        <title>{pageTitle} | Nextmv Cloud</title>
      </Helmet>
      <Header configPageTitle={{ label: pageTitle }} />

      <Text mt={6} styleName="body-1" hasLink>
        The page you are looking for cannot be found. Try starting from{" "}
        <Link to="/">the dashboard</Link>.
      </Text>
    </Box>
  );
};

export default NotFound;
