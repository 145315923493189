import * as React from "react";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";

import { svgIconStyle } from "../lib/constants";

type StyledSvgProps = {
  activeColor?: string;
  focusColor?: string;
  hoverColor?: string;
};
const StyledSvg = styled.svg<StyledSvgProps>`
  ${(props) =>
    svgIconStyle({
      activeColor: props.activeColor,
      focusColor: props.focusColor,
      hoverColor: props.hoverColor,
    })}
`;

const IconEmail = ({
  activeColor,
  focusColor,
  hoverColor,
  iconColor,
  iconSize,
}: {
  activeColor?: string;
  focusColor?: string;
  hoverColor?: string;
  iconColor?: string;
  iconSize?: number;
}) => {
  const theme = useTheme();
  const thisIconSize = iconSize || 16;
  return (
    <StyledSvg
      {...{ activeColor, focusColor, hoverColor }}
      width={thisIconSize}
      height={thisIconSize}
      viewBox="0 0 16 16"
      fill={iconColor || theme.color.gray700}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.03333 2.16669C0.481047 2.16669 0.0333328 2.6144 0.0333328 3.16669V12.8334C0.0333328 13.3856 0.48105 13.8334 1.03333 13.8334H14.9667C15.519 13.8334 15.9667 13.3856 15.9667 12.8334V3.16669C15.9667 2.6144 15.5189 2.16669 14.9667 2.16669H1.03333ZM1.03333 4.2179V3.16669H14.9667V4.2179C14.8734 4.2109 14.7772 4.23279 14.6922 4.28674L8 8.53372L1.30779 4.28674C1.22278 4.23279 1.12657 4.2109 1.03333 4.2179ZM1.03333 5.1785V12.8334L14.9667 12.8334L14.9667 5.1785L8.24112 9.44664C8.09394 9.54004 7.90606 9.54004 7.75888 9.44664L1.03333 5.1785Z"
      />
    </StyledSvg>
  );
};

export default IconEmail;
