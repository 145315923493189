import { ENDPOINT_APPS } from "../../config/endpoints";
import {
  TEST_ACTION_CANCEL,
  TEST_ACTION_COMPLETE,
} from "../../config/experiments";
import { AppSubscription } from "../../contexts/marketplace/Marketplace.context.types";
import { fetchFileContents, fetchFileUpload } from "../utils/fetch";

import {
  AcceptanceTests,
  AppEntityEndpoints,
  BatchExperimentType,
  CreateAppSubscriptionPayload,
  CreateEntityPayloads,
  CreateIntegrationPayload,
  CreateRunPayload,
  CreateRunProfilePayload,
  GetEntitiesResponses,
  GetEntityMetadataResponses,
  GetEntityResponses,
  InputSets,
  IntegrationDeleteResponse,
  IntegrationKind,
  IntegrationResponse,
  Integrations,
  MarketplaceAppVersions,
  MarketplacePartnerApp,
  MemberRoleInfo,
  Organization,
  OrganizationMember,
  OrganizationRole,
  OrganizationRunCount,
  PutIntegrationPayload,
  PutRunDetailsPayload,
  PutRunProfilePayload,
  RestEditMethod,
  RunCreateResponse,
  RunDetailsWithOutputUrlResponse,
  RunInputResponse,
  RunInputWithURLResponse,
  RunLogs,
  RunLogsLive,
  RunMetadataResponse,
  RunProfile,
  RunProfileDeleteResponse,
  RunProfiles,
  RunsResponse,
  RunUploadMetadataCreateResponse,
  ShadowTests,
  SwitchbackTestsResponse,
  UpdateEntityPayloads,
  UploadFileData,
} from "./controlPlane.types";
import { fetchCoreV1WithAccount } from "./core";
import {
  LIST_VIEW_DEFAULT_LIMIT,
  NEXT_PAGE_TOKEN_QUERY_PARAM,
} from "./core.config";
import { UserOrgKey } from "./dataPlane.types";

export const createEntity =
  (account: string) =>
  async <AppEntityEndpoint extends keyof CreateEntityPayloads>(
    entityEndpoint: AppEntityEndpoint,
    payload: CreateEntityPayloads[AppEntityEndpoint],
    shouldReturnEntity: boolean,
    applicationId?: string
  ) => {
    let endpoint = "";
    if (entityEndpoint === ENDPOINT_APPS) {
      endpoint = `/${ENDPOINT_APPS}`;
    } else {
      endpoint = `/${ENDPOINT_APPS}/${applicationId}/${entityEndpoint}`;
    }

    const res = await fetchCoreV1WithAccount(account)(
      endpoint,
      "POST",
      JSON.stringify(payload)
    );
    return shouldReturnEntity ? res.json() : undefined;
  };

export const deleteEntity =
  (account: string) =>
  async (
    applicationId: string,
    entityEndpoint: keyof AppEntityEndpoints,
    entityId?: string
  ) => {
    let endpoint = "";
    if (entityEndpoint === ENDPOINT_APPS) {
      endpoint = `/${ENDPOINT_APPS}/${applicationId}`;
    } else {
      endpoint = `/${ENDPOINT_APPS}/${applicationId}/${entityEndpoint}/${entityId}`;
    }

    await fetchCoreV1WithAccount(account)(endpoint, "DELETE");
    return;
  };

export const getEntity =
  (account: string) =>
  async <AppEntityEndpoint extends keyof GetEntityResponses>(
    applicationId: string,
    entityEndpoint: AppEntityEndpoint,
    entityId?: string
  ): Promise<GetEntityResponses[AppEntityEndpoint]> => {
    let endpoint = "";
    if (entityEndpoint === ENDPOINT_APPS) {
      endpoint = `/${ENDPOINT_APPS}/${applicationId}`;
    } else {
      endpoint = `/${ENDPOINT_APPS}/${applicationId}/${entityEndpoint}/${entityId}`;
    }

    const res = await fetchCoreV1WithAccount(account)(endpoint);
    return res.json();
  };

export const getEntityMetadata =
  (account: string) =>
  async <AppEntityEndpoint extends keyof GetEntityMetadataResponses>(
    applicationId: string,
    entityEndpoint: AppEntityEndpoint,
    entityId: string
  ): Promise<GetEntityMetadataResponses[AppEntityEndpoint]> => {
    const endpoint = `/applications/${applicationId}/${entityEndpoint}/${entityId}/metadata`;
    const res = await fetchCoreV1WithAccount(account)(endpoint);
    return res.json();
  };

export const getEntityRuns =
  (account: string) =>
  async (
    applicationId: string,
    entityEndpoint: keyof Pick<
      AppEntityEndpoints,
      "experiments/batch" | "experiments/shadow" | "experiments/switchback"
    >,
    entityId?: string,
    nextPageToken?: string,
    queryStart?: string,
    queryEnd?: string
  ): Promise<RunsResponse> => {
    let endpoint = `/applications/${applicationId}/${entityEndpoint}/${entityId}/runs`;

    if (nextPageToken) {
      endpoint += `?${NEXT_PAGE_TOKEN_QUERY_PARAM}=${nextPageToken}`;
    }
    if (queryStart && queryEnd) {
      endpoint += `${nextPageToken ? "&" : "?"}start=${encodeURIComponent(
        queryStart
      )}&end=${encodeURIComponent(queryEnd)}`;
    }

    const res = await fetchCoreV1WithAccount(account)(endpoint);
    return res.json();
  };

export const updateEntity =
  (account: string) =>
  async <AppEntityEndpoint extends keyof UpdateEntityPayloads>(
    applicationId: string,
    entityEndpoint: AppEntityEndpoint,
    payload: UpdateEntityPayloads[AppEntityEndpoint],
    method: RestEditMethod,
    entityId?: string
  ) => {
    let endpoint = "";
    if (entityEndpoint === ENDPOINT_APPS) {
      endpoint = `/${ENDPOINT_APPS}/${applicationId}`;
    } else {
      endpoint = `/${ENDPOINT_APPS}/${applicationId}/${entityEndpoint}/${entityId}`;
    }

    await fetchCoreV1WithAccount(account)(
      endpoint,
      method,
      JSON.stringify(payload)
    );
    return;
  };

// entities
export const getEntities =
  (account: string) =>
  async <AppEntityEndpoint extends keyof GetEntitiesResponses>({
    entityEndpoint,
    applicationId,
    limit,
    nextPageToken,
    queryEnd,
    queryStart,
    shouldPaginate,
    type,
  }: {
    entityEndpoint: AppEntityEndpoint;
    applicationId?: string;
    limit?: number;
    nextPageToken?: string;
    queryEnd?: string;
    queryStart?: string;
    shouldPaginate?: boolean;
    type?: BatchExperimentType | "";
  }) => {
    let endpoint = "";
    let queryLeadChar = "?";

    if (entityEndpoint === ENDPOINT_APPS) {
      endpoint = `/${ENDPOINT_APPS}`;
    } else {
      endpoint = `/${ENDPOINT_APPS}/${applicationId}/${entityEndpoint}`;
    }

    if (shouldPaginate) {
      endpoint += `${queryLeadChar}pagereturn=true`;
      queryLeadChar = "&";
    }
    if (nextPageToken) {
      endpoint += `${queryLeadChar}${NEXT_PAGE_TOKEN_QUERY_PARAM}=${nextPageToken}`;
      queryLeadChar = "&";
    }
    if (queryStart && queryEnd) {
      endpoint += `${queryLeadChar}start=${encodeURIComponent(
        queryStart
      )}&end=${encodeURIComponent(queryEnd)}`;
      queryLeadChar = "&";
    }
    if (type) {
      endpoint += `${queryLeadChar}type=${type}`;
      queryLeadChar = "&";
    }
    if (limit) {
      endpoint += `${queryLeadChar}limit=${limit}`;
      queryLeadChar = "&";
    }

    const res = await fetchCoreV1WithAccount(account)(endpoint);
    return res.json();
  };

// legacy
export const createRunProfile =
  (account: string) =>
  async (payload: CreateRunProfilePayload): Promise<RunProfile> => {
    const res = await fetchCoreV1WithAccount(account)(
      "/routing/profiles/run",
      "POST",
      JSON.stringify(payload)
    );
    return res.json();
  };

export const getRunProfiles =
  (account: string) => async (): Promise<RunProfiles> => {
    const res = await fetchCoreV1WithAccount(account)("/routing/profiles/run");
    return res.json();
  };

export const getRunProfile =
  (account: string) =>
  async (id: string): Promise<RunProfile> => {
    const res = await fetchCoreV1WithAccount(account)(
      `/routing/profiles/run/${id}`
    );
    return res.json();
  };

export const updateRunProfile =
  (account: string) =>
  async (id: string, payload: PutRunProfilePayload): Promise<RunProfile> => {
    const res = await fetchCoreV1WithAccount(account)(
      `/routing/profiles/run/${id}`,
      "PUT",
      JSON.stringify(payload)
    );
    return res.json();
  };

export const deleteRunProfile =
  (account: string) =>
  async (id: string): Promise<RunProfileDeleteResponse> => {
    await fetchCoreV1WithAccount(account)(
      `/routing/profiles/run/${id}`,
      "DELETE"
    );
    return { id };
  };

export const createIntegration =
  (account: string) =>
  async <T extends IntegrationKind>(
    integrationType: IntegrationKind,
    payload: CreateIntegrationPayload<T>
  ): Promise<IntegrationResponse<T>> => {
    const res = await fetchCoreV1WithAccount(account)(
      `/routing/profiles/integration/${integrationType}`,
      "POST",
      JSON.stringify(payload)
    );
    return res.json();
  };

export const updateIntegration =
  (account: string) =>
  async <T extends IntegrationKind>(
    integrationType: IntegrationKind,
    id: string,
    payload: PutIntegrationPayload<T>
  ): Promise<IntegrationResponse<T>> => {
    const res = await fetchCoreV1WithAccount(account)(
      `/routing/profiles/integration/${integrationType}/${id}`,
      "PUT",
      JSON.stringify(payload)
    );
    return res.json();
  };

export const deleteIntegration =
  (accountId: string) =>
  async (
    id: string,
    integrationType: IntegrationKind
  ): Promise<IntegrationDeleteResponse> => {
    await fetchCoreV1WithAccount(accountId)(
      `/routing/profiles/integration/${integrationType}/${id}`,
      "DELETE"
    );
    return { id, type: integrationType };
  };

export const getIntegrations =
  (accountId: string) => async (): Promise<Integrations> => {
    const res = await fetchCoreV1WithAccount(accountId)(
      "/routing/profiles/integration"
    );
    return res.json();
  };

export const getIntegration =
  (account: string) =>
  async <T extends IntegrationKind>(
    integrationType: IntegrationKind,
    id: string
  ): Promise<IntegrationResponse<T>> => {
    const res = await fetchCoreV1WithAccount(account)(
      `/routing/profiles/integration/${integrationType}/${id}`
    );
    return res.json();
  };

export const getMarketplacePartnerApp =
  (account: string) =>
  async (
    partnerId: string,
    applicationId: string
  ): Promise<MarketplacePartnerApp> => {
    const res = await fetchCoreV1WithAccount(account)(
      `/marketplace/partners/${partnerId}/apps/${applicationId}`
    );
    return res.json();
  };

export const getMarketplaceVersions =
  (account: string) =>
  async (
    partnerId: string,
    applicationId: string
  ): Promise<MarketplaceAppVersions> => {
    const res = await fetchCoreV1WithAccount(account)(
      `/marketplace/partners/${partnerId}/apps/${applicationId}/versions`
    );
    return res.json();
  };

export const getInputSets =
  (account: string) =>
  async (applicationId: string): Promise<InputSets> => {
    const res = await fetchCoreV1WithAccount(account)(
      `/applications/${applicationId}/experiments/inputsets`
    );
    return res.json();
  };

export const getAcceptanceTests =
  (account: string) =>
  async (
    applicationId: string,
    limit: number = LIST_VIEW_DEFAULT_LIMIT
  ): Promise<AcceptanceTests> => {
    const res = await fetchCoreV1WithAccount(account)(
      `/applications/${applicationId}/experiments/acceptance?limit=${limit}`
    );
    return res.json();
  };

export const cancelRun =
  (account: string) =>
  async (applicationId: string, runId: string): Promise<void> => {
    await fetchCoreV1WithAccount(account)(
      `/applications/${applicationId}/runs/${runId}/cancel`,
      "PATCH"
    );
    return;
  };

export const getRunMetadata =
  (account: string) =>
  async (
    applicationId: string,
    runId: string
  ): Promise<RunMetadataResponse> => {
    const res = await fetchCoreV1WithAccount(account)(
      `/applications/${applicationId}/runs/${runId}/metadata`
    );
    return res.json();
  };

export const getRunDetailsWithOutputUrl =
  (account: string) =>
  async (
    applicationId: string,
    runId: string
  ): Promise<RunDetailsWithOutputUrlResponse> => {
    const res = await fetchCoreV1WithAccount(account)(
      `/applications/${applicationId}/runs/${runId}?format=url`
    );
    return res.json();
  };

export const createRunUploadMetadata =
  (account: string) =>
  async (applicationId: string): Promise<RunUploadMetadataCreateResponse> => {
    const res = await fetchCoreV1WithAccount(account)(
      `/applications/${applicationId}/runs/uploadurl`,
      "POST"
    );
    return res.json();
  };

export const uploadFileToUrl = async (
  uploadUrl: string,
  fileData: UploadFileData
): Promise<JSON> => {
  if (fileData.contentType !== "csv-archive") {
    const file = await fileData.file.arrayBuffer();
    const res = await fetchFileUpload()(uploadUrl, "PUT", file, {});
    return res.json();
  }

  const res = await fetchFileUpload()(uploadUrl, "PUT", fileData.file, {
    "Content-Type": "application/gzip",
  });
  return res.json();
};

export const getFileContents = async (
  uploadUrl: string,
  responseType: "text" | "blob" = "text"
): Promise<string | Blob> => {
  const res = await fetchFileContents()(uploadUrl, "GET");
  if (responseType === "blob") {
    return await res.blob();
  }
  return await res.text();
};

export const createRunDetails =
  (account: string) =>
  async (
    applicationId: string,
    instanceId: string,
    payload: CreateRunPayload
  ): Promise<RunCreateResponse> => {
    const res = await fetchCoreV1WithAccount(account)(
      `/applications/${applicationId}/runs?instance_id=${instanceId}`,
      "POST",
      JSON.stringify(payload),
      {
        "request-source": "console",
      }
    );
    return res.json();
  };

export const updateRunDetails =
  (account: string) =>
  async (
    applicationId: string,
    runId: string,
    payload: PutRunDetailsPayload
  ): Promise<RunMetadataResponse> => {
    const res = await fetchCoreV1WithAccount(account)(
      `/applications/${applicationId}/runs/${runId}`,
      "PUT",
      JSON.stringify(payload)
    );
    return res.json();
  };

export const getRunInput =
  (account: string) =>
  async (applicationId: string, runId: string): Promise<RunInputResponse> => {
    const res = await fetchCoreV1WithAccount(account)(
      `/applications/${applicationId}/runs/${runId}/input`
    );
    return res.json();
  };

export const getRunInputURL =
  (account: string) =>
  async (
    applicationId: string,
    runId: string
  ): Promise<RunInputWithURLResponse> => {
    const res = await fetchCoreV1WithAccount(account)(
      `/applications/${applicationId}/runs/${runId}/input?format=url`
    );
    return res.json();
  };

export const getRunLogsLive =
  (account: string) =>
  async (
    applicationId: string,
    runId: string,
    timeSince?: string
  ): Promise<RunLogsLive> => {
    const url = timeSince
      ? `/applications/${applicationId}/runs/${runId}/logs/live?since=${timeSince}`
      : `/applications/${applicationId}/runs/${runId}/logs/live`;
    const res = await fetchCoreV1WithAccount(account)(url);
    return res.json();
  };

export const getRunLogs =
  (account: string) =>
  async (applicationId: string, runId: string): Promise<RunLogs> => {
    const res = await fetchCoreV1WithAccount(account)(
      `/applications/${applicationId}/runs/${runId}/logs`
    );
    return res.json();
  };

export const inviteMember =
  (account: string) =>
  async (orgId: string, memberEmail: string): Promise<OrganizationMember> => {
    const res = await fetchCoreV1WithAccount(account)(
      `/organization/${orgId}/member`,
      "POST",
      JSON.stringify({
        email: memberEmail,
      })
    );
    return res.json();
  };

export const getOrgInfo = async (orgId: string): Promise<Organization> => {
  const res = await fetchCoreV1WithAccount(orgId)(`/organization/${orgId}`);
  return res.json();
};

export const getOrgRunCount = async (
  orgId: string
): Promise<OrganizationRunCount> => {
  const res = await fetchCoreV1WithAccount(orgId)(
    `/organization/${orgId}/run-count`
  );
  return res.json();
};

export const sendInviteResponse =
  (account: string) =>
  async (orgId: string, accepted: boolean): Promise<void> => {
    await fetchCoreV1WithAccount(account)(
      `/organization/${orgId}/member/invitation`,
      "PUT",
      JSON.stringify({
        accepted,
      })
    );
    return;
  };

export const leaveOrg = async (orgId: string): Promise<void> => {
  await fetchCoreV1WithAccount(orgId)(
    `/organization/${orgId}/member/leave`,
    "PUT"
  );
  return;
};

export const removeMember = async (
  orgId: string,
  email: string
): Promise<void> => {
  await fetchCoreV1WithAccount(orgId)(
    `/organization/${orgId}/member/deactivate`,
    "PUT",
    JSON.stringify({
      email,
    })
  );
  return;
};

export const updateMemberRole = async (
  orgId: string,
  email: string,
  role: OrganizationRole
): Promise<MemberRoleInfo> => {
  const path = `/organization/${orgId}/member/role`;
  const res = await fetchCoreV1WithAccount(orgId)(
    path,
    "PUT",
    JSON.stringify({
      email,
      role,
    })
  );
  return res.json();
};

export const updateOrgInfo = async (
  orgId: string,
  payload: any
): Promise<{ name: string }> => {
  const res = await fetchCoreV1WithAccount(orgId)(
    `/organization/${orgId}`,
    "PUT",
    JSON.stringify({
      ...payload,
    })
  );
  return res.json();
};

export const getAppSubscriptions =
  (account: string) => async (): Promise<AppSubscription[]> => {
    const res = await fetchCoreV1WithAccount(account)(
      "/marketplace/subscriptions"
    );
    return res.json();
  };

export const createAppSubscription =
  (account: string) =>
  async (payload: CreateAppSubscriptionPayload): Promise<any> => {
    const res = await fetchCoreV1WithAccount(account)(
      `/marketplace/subscriptions`,
      "POST",
      JSON.stringify(payload)
    );
    return res.json();
  };

export const addOrgKey = async (
  orgId: string,
  name: string,
  description: string
): Promise<UserOrgKey> => {
  const resp = await fetchCoreV1WithAccount(orgId)(
    `/organization/${orgId}/keys`,
    "POST",
    JSON.stringify({ name, description })
  );
  return resp.json() as unknown as UserOrgKey;
};

export const deleteOrgKey = async (
  orgId: string,
  api_key: string
): Promise<UserOrgKey> => {
  const resp = await fetchCoreV1WithAccount(orgId)(
    `/organization/${orgId}/keys`,
    "DELETE",
    JSON.stringify({ api_key })
  );
  return resp as unknown as UserOrgKey;
};

export const disableOrgKey = async (
  orgId: string,
  api_key: string
): Promise<UserOrgKey> => {
  const resp = await fetchCoreV1WithAccount(orgId)(
    `/organization/${orgId}/keys/disable`,
    "PUT",
    JSON.stringify({ api_key })
  );
  return resp as unknown as UserOrgKey;
};

export const enableOrgKey = async (
  orgId: string,
  api_key: string
): Promise<UserOrgKey> => {
  const resp = await fetchCoreV1WithAccount(orgId)(
    `/organization/${orgId}/keys/enable`,
    "PUT",
    JSON.stringify({ api_key })
  );
  return resp as unknown as UserOrgKey;
};

export const getShadowTests =
  (account: string) =>
  async (
    applicationId: string,
    limit: number = LIST_VIEW_DEFAULT_LIMIT
  ): Promise<ShadowTests> => {
    const res = await fetchCoreV1WithAccount(account)(
      `/applications/${applicationId}/experiments/shadow?limit=${limit}`
    );
    return res.json();
  };

export const startShadowTest =
  (account: string) =>
  async (applicationId: string, shadowTestId: string): Promise<any> => {
    await fetchCoreV1WithAccount(account)(
      `/applications/${applicationId}/experiments/shadow/${shadowTestId}/start`,
      "PUT"
    );
    return;
  };

export const stopShadowTest =
  (account: string) =>
  async (
    applicationId: string,
    shadowTestId: string,
    intent: typeof TEST_ACTION_COMPLETE | typeof TEST_ACTION_CANCEL
  ): Promise<undefined> => {
    await fetchCoreV1WithAccount(account)(
      `/applications/${applicationId}/experiments/shadow/${shadowTestId}/stop`,
      "PUT",
      JSON.stringify({ intent })
    );
    return;
  };

export const startSwitchbackTest =
  (account: string) =>
  async (applicationId: string, switchbackTestId: string): Promise<any> => {
    await fetchCoreV1WithAccount(account)(
      `/applications/${applicationId}/experiments/switchback/${switchbackTestId}/start`,
      "PUT"
    );
    return;
  };

export const getSwitchbackTests =
  (account: string) =>
  async (
    applicationId: string,
    pageToken?: string,
    limit: number = LIST_VIEW_DEFAULT_LIMIT
  ): Promise<SwitchbackTestsResponse> => {
    let reqUrl = `/applications/${applicationId}/experiments/switchback?limit=${limit}`;
    if (pageToken) {
      reqUrl = `${reqUrl}?pagetoken=${pageToken}`;
    }
    const res = await fetchCoreV1WithAccount(account)(reqUrl);
    return res.json();
  };

export const stopSwitchbackTest =
  (account: string) =>
  async (
    applicationId: string,
    switchbackTestId: string,
    intent: typeof TEST_ACTION_COMPLETE | typeof TEST_ACTION_CANCEL
  ): Promise<undefined> => {
    await fetchCoreV1WithAccount(account)(
      `/applications/${applicationId}/experiments/switchback/${switchbackTestId}/stop`,
      "PUT",
      JSON.stringify({ intent })
    );
    return;
  };

export const cancelBatchExperiment =
  (account: string) =>
  async (
    applicationId: string,
    batchExperimentId: string
  ): Promise<undefined> => {
    await fetchCoreV1WithAccount(account)(
      `/applications/${applicationId}/experiments/batch/${batchExperimentId}/cancel`,
      "PUT"
    );
    return;
  };
