import * as React from "react";

import BaseIllustration, { IllustrationProps } from "./BaseIllustration";

const IllustrationBunnyLaptopCoffee = ({
  illustrationHeight,
  illustrationWidth,
  illustrationWidths,
}: IllustrationProps) => {
  return (
    <BaseIllustration
      {...{ illustrationHeight, illustrationWidth, illustrationWidths }}
      viewBox={[0, 0, 167, 171]}
    >
      <path
        d="M81.6242 90.4232H81.6099C77.4339 90.4232 72.1059 86.4112 70.2051 81.8582C68.8486 78.6034 69.4188 75.4757 71.815 73.0507C75.5878 69.2261 79.015 69.347 81.2354 70.1246C85.5554 71.6395 88.5914 76.8293 89.0114 80.9732C89.2994 83.6202 88.4844 86.2752 86.8574 88.0782C86.2004 88.8172 85.3944 89.4082 84.4924 89.8122C83.5902 90.2162 82.6127 90.4242 81.6242 90.4232ZM72.6243 73.8571C70.0006 76.5154 70.4326 79.4332 71.2591 81.4112C72.9699 85.5152 77.9004 89.2592 81.6012 89.2682C82.4315 89.2752 83.2536 89.1042 84.0115 88.7652C84.7694 88.4262 85.4454 87.9282 85.9934 87.3042C87.4044 85.7402 88.1014 83.4162 87.8564 81.0912C87.4594 77.3045 84.7234 72.5726 80.8322 71.2104C78.1049 70.2542 75.2623 71.1701 72.6127 73.8571H72.6243Z"
        fill="#012B42"
      />
      <path
        d="M53.7998 133.899C53.2699 133.899 52.7198 133.88 52.1496 133.842C43.7515 133.289 33.035 128.736 30.3595 122.345C28.6488 118.264 29.8987 112.613 34.0718 105.554C36.0407 102.27 38.2836 99.1582 40.7765 96.2522L41.1595 95.8202L42.0235 96.5832L41.6405 97.0152C41.4994 97.1742 27.661 112.924 31.4251 121.898C33.853 127.684 44.1403 132.146 52.2274 132.684C56.5128 132.972 59.5714 132.108 60.6139 130.334C60.8873 129.911 61.056 129.43 61.1061 128.929C61.1561 128.428 61.0862 127.922 60.9019 127.454C59.3093 123.281 50.0558 120.254 49.9579 120.222L49.8427 120.185L49.7533 120.104C48.2298 118.759 47.9333 116.697 48.8894 114.146C49.3475 112.966 49.9575 111.852 50.7038 110.831L51.0523 110.373L51.9682 111.07L51.6197 111.528C51.5794 111.579 47.7835 116.651 50.4302 119.165C51.6139 119.56 60.2683 122.584 61.9704 127.042C62.2166 127.669 62.3117 128.346 62.2482 129.017C62.1846 129.688 61.9642 130.334 61.6046 130.904C60.4699 132.866 57.7253 133.899 53.7998 133.899Z"
        fill="#012B42"
      />
      <path
        d="M79.0892 144.808C78.4967 144.828 77.9068 144.719 77.3601 144.49C76.8133 144.261 76.3225 143.917 75.9212 143.48C75.3884 142.872 73.7641 141.021 77.318 127.551C79.046 121.019 81.1743 114.844 81.1973 114.784L81.3874 114.239L82.4761 114.617L82.286 115.161C79.0373 124.535 74.6713 140.306 76.7881 142.72C77.4274 143.451 78.4354 143.771 79.6278 143.621C83.7001 143.109 88.6684 137.611 93.2644 128.542C96.5964 121.966 96.8014 114.867 93.8784 107.434C92.5534 104.068 90.7074 100.931 88.4064 98.1402L88.0234 97.7112L88.8874 96.9452L89.2704 97.3742C91.6574 100.25 93.5674 103.491 94.9264 106.973C97.0344 112.31 98.6324 120.475 94.2814 129.06C87.7494 141.954 82.3781 144.433 79.7602 144.762C79.5377 144.791 79.3136 144.806 79.0892 144.808Z"
        fill="#012B42"
      />
      <path
        d="M71.8952 46.8892L71.7109 45.7516L72.2869 45.6595C72.4078 45.6393 84.5474 43.5715 97.8954 34.0703C101.268 31.6713 118.231 18.9158 122.908 2.1427L122.062 1.2614C107.042 4.7462 93.7194 15.5145 85.1634 23.9673C79.5757 29.4653 74.478 35.4399 69.9281 41.8233L69.6056 42.2985L68.6523 41.6505L68.9749 41.1724C73.5676 34.7264 78.7135 28.6931 84.3544 23.1407C93.0744 14.5295 106.711 3.5308 122.116 0.0719013L122.433 0L124.178 1.8201L124.095 2.134C123.582 4.0348 121.938 9.1497 117.71 15.5721C112.938 22.8211 106.478 29.3615 98.5524 35.0121C92.9534 39.0014 86.8404 42.2149 80.3797 44.5651C75.6248 46.2729 72.6037 46.7769 72.4625 46.7971L71.8952 46.8892Z"
        fill="#012B42"
      />
      <path
        d="M56.062 45.7074L55.486 45.6642C55.3622 45.6642 42.8371 44.6533 28.4572 36.2293C20.0505 31.3016 13.0521 25.3573 7.6579 18.5576C2.8771 12.5327 0.786201 7.582 0.109401 5.7359L0 5.4162L1.5811 3.4492L1.9036 3.4924C17.5564 5.5775 32.1062 15.3292 41.5555 23.1426C47.6642 28.1752 53.3227 33.7306 58.4668 39.7458L58.8297 40.1922L57.9369 40.9208L57.574 40.4744C52.4718 34.512 46.8595 29.0057 40.8009 24.0181C31.5388 16.3602 17.3289 6.8274 2.0649 4.6904L1.3017 5.6408C2.6496 9.1544 9.1958 23.6092 29.0361 35.2357C34.8652 38.654 41.1292 41.2696 47.6582 43.0117C52.4505 44.2703 55.535 44.5093 55.5667 44.5122L56.1427 44.5554L56.062 45.7074Z"
        fill="#012B42"
      />
      <path
        d="M52.8203 103.361L52.2443 103.237C52.106 103.208 38.4577 100.089 34.4142 87.0602C31.8942 78.9362 35.8542 64.9562 43.8462 53.8192C49.7531 45.5853 56.4174 40.9629 62.6123 40.8045C69.5963 40.626 75.8603 43.5578 81.2516 49.5136C86.1764 54.9626 90.1794 62.9028 92.8434 72.4816C97.8634 90.5622 89.1344 98.7932 85.4074 102.31L84.9434 102.759L84.5294 103.159L83.7313 102.327L84.146 101.927L84.6294 101.466C88.2064 98.0932 96.5814 90.1932 91.7464 72.784C89.1344 63.378 85.2144 55.5962 80.4107 50.2797C75.2612 44.5831 69.2852 41.7808 62.6555 41.9508C56.9156 42.0976 50.4068 46.6653 44.7995 54.4874C37.1329 65.178 33.147 79.0332 35.5316 86.7142C39.3793 99.0982 52.3768 102.079 52.5064 102.108L53.0824 102.232L52.8203 103.361Z"
        fill="#012B42"
      />
      <path
        d="M51.7065 80.5812C52.0469 79.2602 50.942 77.834 49.2387 77.395C47.5354 76.956 45.8786 77.6707 45.5383 78.9912C45.1979 80.3122 46.3028 81.7382 48.0061 82.1772C49.7094 82.6162 51.3661 81.9022 51.7065 80.5812Z"
        fill="#012B42"
      />
      <path
        d="M80.405 82.2612C81.9648 81.5002 82.7513 79.9032 82.1616 78.6942C81.5719 77.4845 79.8294 77.1204 78.2696 77.8809C76.7098 78.6413 75.9233 80.2382 76.513 81.4482C77.1026 82.6572 78.8452 83.0212 80.405 82.2612Z"
        fill="#012B42"
      />
      <path
        d="M63.6498 85.8962C63.5864 85.8962 63.5259 85.8962 63.4626 85.8962C61.6194 85.7232 59.4939 83.4622 59.2578 83.2062L60.1045 82.4252C60.6661 83.0332 62.3797 84.6372 63.5605 84.7502C64.5627 84.8422 66.2792 83.3102 67.1231 82.3042L67.9871 83.0502C67.7595 83.3412 65.5333 85.8962 63.6498 85.8962Z"
        fill="#012B42"
      />
      <path
        d="M64.1088 84.9972L62.957 85.0192L63.0958 92.1832L64.2476 92.1612L64.1088 84.9972Z"
        fill="#012B42"
      />
      <path
        d="M65.6769 93.1562C62.8161 93.1572 60.0493 92.1352 57.875 90.2762L58.667 89.4412C60.6348 91.0902 63.1155 92.0012 65.6826 92.0192H65.8554L65.8756 93.1712L65.6769 93.1562Z"
        fill="#012B42"
      />
      <path
        d="M78.8868 54.2852H74.5668C74.5668 51.3635 73.4062 48.5616 71.3403 46.4957C69.2744 44.4298 66.4724 43.2692 63.5508 43.2692C60.6292 43.2692 57.8272 44.4298 55.7613 46.4957C53.6954 48.5616 52.5348 51.3635 52.5348 54.2852H48.2148C48.2148 50.2178 49.8306 46.317 52.7066 43.441C55.5827 40.5649 59.4834 38.9492 63.5508 38.9492C67.6182 38.9492 71.5189 40.5649 74.395 43.441C77.2711 46.317 78.8868 50.2178 78.8868 54.2852Z"
        fill="#1B9CAA"
      />
      <path
        d="M29.9993 170.467H99.0155L115.264 152.508H15.3516L29.9993 170.467Z"
        fill="#A1DFE6"
      />
      <path
        d="M30.0016 170.469H99.0174L113.034 117.48H17.5859L30.0016 170.469Z"
        fill="#1B9CAA"
      />
      <path
        d="M63.0456 140.136C62.9679 139.984 59.1691 134.149 61.3176 132.83C63.3509 131.574 64.2149 139.517 64.2149 139.517C64.2149 139.517 65.0789 132.634 67.0344 133.593C69.1916 134.647 65.1279 140.122 65.1279 140.122L63.0456 140.136Z"
        fill="#1B9CAA"
      />
      <path
        d="M64.1625 144.489L63.7507 140.71H62.6937L62.4892 140.324C60.6806 137.378 58.9526 133.6 61.0032 132.332C61.1918 132.206 61.4101 132.132 61.6364 132.116C61.8627 132.1 62.0892 132.144 62.2934 132.243C63.2438 132.71 63.8659 134.512 64.2547 136.246C64.632 134.881 65.1993 133.522 66.0057 133.078C66.1989 132.97 66.4159 132.912 66.6371 132.909C66.8584 132.906 67.0769 132.959 67.2729 133.061C67.4967 133.167 67.6933 133.323 67.8479 133.517C68.0026 133.711 68.1112 133.937 68.1657 134.178C68.6611 136.215 65.8934 140.022 65.5737 140.451L65.4038 140.681H64.632L64.1625 144.489ZM61.7347 133.274C61.6888 133.279 61.645 133.296 61.608 133.323C60.7238 133.867 61.4323 136.364 63.3676 139.561H63.6268C63.3388 136.828 62.5411 133.66 61.7865 133.291C61.77 133.281 61.7512 133.275 61.7318 133.274H61.7347ZM66.6537 134.081C66.6191 134.081 66.5854 134.091 66.5558 134.109C65.758 134.541 65.0265 137.594 64.7788 139.553H64.8192C65.8819 138.061 67.3104 135.555 67.0454 134.469C67.03 134.391 66.9957 134.318 66.9455 134.256C66.8953 134.195 66.8307 134.146 66.7574 134.115C66.725 134.095 66.6886 134.082 66.6508 134.078L66.6537 134.081Z"
        fill="#FFFD54"
      />
      <path
        d="M60.636 140.718C61.356 138.287 66.802 138.331 67.738 140.614C68.5502 142.593 66.298 153.96 64.1985 153.978C61.8052 153.995 59.939 143.08 60.636 140.718Z"
        fill="#1B9CAA"
      />
      <path
        d="M64.1889 154.552C63.6475 154.552 63.1377 154.238 62.6712 153.619C60.6811 150.975 59.4398 142.721 60.0792 140.552C60.4565 139.279 61.8763 138.427 63.7857 138.329C65.5627 138.239 67.6305 138.841 68.267 140.394C69.0763 142.366 67.3829 150.943 65.675 153.503C65.2085 154.203 64.7246 154.546 64.1947 154.552H64.1889ZM64.1457 139.472H63.8577C62.4725 139.541 61.4299 140.091 61.1995 140.872C60.6552 142.715 61.9022 150.664 63.6043 152.919C63.8289 153.207 64.0507 153.394 64.2033 153.394C64.2033 153.394 64.3963 153.371 64.7333 152.861C66.4785 150.24 67.7832 142.205 67.2158 140.826C66.8645 140.022 65.6117 139.472 64.1457 139.472Z"
        fill="#FFFD54"
      />
      <path
        d="M140.303 135.906C135.07 135.906 125.171 135.148 125.171 132.277C125.171 129.406 135.07 128.648 140.303 128.648C145.536 128.648 155.434 129.406 155.434 132.277C155.434 135.148 145.527 135.906 140.303 135.906ZM140.303 129.8C136.452 129.8 132.841 130.114 130.128 130.681C127.02 131.332 126.323 132.07 126.323 132.277C126.323 132.484 127.023 133.222 130.128 133.872C132.841 134.448 136.464 134.754 140.303 134.754C144.142 134.754 147.756 134.44 150.469 133.864C153.577 133.213 154.274 132.476 154.274 132.268C154.274 132.061 153.574 131.324 150.469 130.673C147.756 130.12 144.145 129.8 140.303 129.8Z"
        fill="#012B42"
      />
      <path
        d="M140.303 168.365C135.07 168.365 125.171 167.608 125.171 164.736V132.279C125.171 132.126 125.232 131.98 125.34 131.872C125.448 131.764 125.595 131.703 125.747 131.703C125.9 131.703 126.047 131.764 126.155 131.872C126.263 131.98 126.323 132.126 126.323 132.279V164.736C126.323 165.419 130.623 167.213 140.303 167.213C149.983 167.213 154.282 165.419 154.282 164.736V132.279C154.282 132.126 154.343 131.98 154.451 131.872C154.559 131.764 154.706 131.703 154.858 131.703C155.011 131.703 155.158 131.764 155.266 131.872C155.374 131.98 155.434 132.126 155.434 132.279V164.736C155.426 167.608 145.527 168.365 140.303 168.365Z"
        fill="#012B42"
      />
      <path
        d="M127.695 133.804C130.212 132.891 134.912 132.277 140.298 132.277C145.683 132.277 150.378 132.891 152.901 133.804C150.383 134.716 145.683 135.33 140.298 135.33C134.912 135.33 130.212 134.728 127.695 133.804Z"
        fill="#012B42"
      />
      <path
        d="M157.993 155.876C156.825 155.878 155.67 155.627 154.609 155.139C154.54 155.107 154.478 155.062 154.427 155.007C154.375 154.951 154.335 154.886 154.309 154.815C154.283 154.744 154.271 154.669 154.273 154.593C154.276 154.517 154.294 154.443 154.326 154.374C154.357 154.305 154.402 154.243 154.457 154.192C154.513 154.14 154.578 154.1 154.649 154.074C154.72 154.048 154.796 154.036 154.871 154.038C154.947 154.041 155.021 154.059 155.09 154.091C156.143 154.575 157.3 154.788 158.457 154.712C159.613 154.636 160.732 154.272 161.713 153.654C162.693 153.036 163.504 152.183 164.071 151.173C164.639 150.162 164.945 149.026 164.962 147.867C164.98 146.708 164.707 145.564 164.17 144.537C163.633 143.51 162.848 142.633 161.887 141.986C160.925 141.339 159.818 140.942 158.664 140.831C157.51 140.721 156.347 140.9 155.28 141.352C155.141 141.405 154.987 141.402 154.85 141.343C154.713 141.285 154.604 141.176 154.546 141.038C154.488 140.901 154.485 140.747 154.538 140.608C154.591 140.469 154.696 140.356 154.831 140.292C155.938 139.826 157.135 139.611 158.335 139.664C159.535 139.716 160.709 140.034 161.771 140.595C162.834 141.156 163.758 141.946 164.478 142.908C165.198 143.869 165.696 144.979 165.935 146.156C166.174 147.333 166.149 148.549 165.861 149.716C165.573 150.882 165.03 151.97 164.271 152.901C163.512 153.832 162.555 154.583 161.471 155.099C160.386 155.616 159.2 155.885 157.999 155.888L157.993 155.876Z"
        fill="#012B42"
      />
      <path
        d="M142.697 135.71L141.992 134.8L142.447 134.449C142.767 134.201 143.024 133.881 143.196 133.516C143.369 133.15 143.453 132.748 143.442 132.344C143.43 131.94 143.323 131.544 143.129 131.189C142.935 130.834 142.66 130.53 142.326 130.301C141.838 129.967 141.437 129.521 141.156 129C140.875 128.479 140.723 127.899 140.711 127.308C140.7 126.716 140.83 126.131 141.09 125.599C141.35 125.068 141.733 124.607 142.208 124.253L142.409 124.104C142.734 123.862 142.997 123.548 143.176 123.186C143.356 122.823 143.448 122.424 143.444 122.02C143.44 121.615 143.34 121.218 143.154 120.859C142.967 120.501 142.698 120.191 142.369 119.956L142.265 119.881C141.784 119.537 141.392 119.084 141.122 118.558C140.852 118.032 140.711 117.449 140.711 116.857C140.711 116.266 140.852 115.683 141.122 115.157C141.392 114.631 141.784 114.178 142.265 113.833L142.841 113.422L143.512 114.358L142.936 114.769C142.604 115.007 142.333 115.32 142.146 115.683C141.959 116.047 141.862 116.449 141.862 116.857C141.862 117.266 141.959 117.668 142.146 118.032C142.333 118.395 142.604 118.708 142.936 118.945L143.04 119.02C143.516 119.361 143.906 119.809 144.176 120.329C144.446 120.848 144.59 121.424 144.596 122.01C144.602 122.595 144.469 123.174 144.208 123.698C143.948 124.223 143.567 124.678 143.098 125.028L142.896 125.178C142.568 125.422 142.303 125.74 142.123 126.107C141.943 126.474 141.854 126.878 141.862 127.287C141.87 127.695 141.975 128.096 142.169 128.455C142.363 128.815 142.64 129.123 142.977 129.354C143.461 129.685 143.859 130.126 144.14 130.641C144.421 131.155 144.576 131.729 144.593 132.315C144.61 132.901 144.488 133.483 144.237 134.013C143.986 134.543 143.614 135.006 143.15 135.364L142.697 135.71Z"
        fill="#012B42"
      />
      <path
        d="M136.233 125.116L135.527 124.206L135.982 123.855C136.302 123.607 136.559 123.288 136.732 122.922C136.905 122.556 136.988 122.155 136.977 121.75C136.965 121.346 136.858 120.95 136.664 120.595C136.47 120.24 136.195 119.936 135.861 119.708C135.373 119.373 134.972 118.927 134.691 118.406C134.411 117.886 134.258 117.305 134.247 116.714C134.235 116.122 134.365 115.537 134.625 115.006C134.885 114.474 135.269 114.013 135.743 113.66L135.945 113.51C136.269 113.268 136.532 112.954 136.711 112.592C136.891 112.23 136.983 111.83 136.979 111.426C136.975 111.022 136.876 110.624 136.689 110.265C136.502 109.907 136.233 109.598 135.904 109.363L135.801 109.288C135.32 108.944 134.928 108.49 134.657 107.964C134.387 107.438 134.246 106.855 134.246 106.264C134.246 105.672 134.387 105.089 134.657 104.564C134.928 104.038 135.32 103.584 135.801 103.24L136.377 102.828L137.048 103.764L136.472 104.176C136.139 104.413 135.868 104.726 135.681 105.09C135.495 105.453 135.397 105.855 135.397 106.264C135.397 106.672 135.495 107.075 135.681 107.438C135.868 107.801 136.139 108.114 136.472 108.352L136.575 108.427C137.052 108.767 137.441 109.215 137.711 109.735C137.982 110.254 138.125 110.83 138.131 111.416C138.137 112.001 138.004 112.58 137.744 113.105C137.483 113.629 137.103 114.085 136.633 114.434L136.431 114.584C136.103 114.828 135.838 115.146 135.659 115.513C135.479 115.88 135.389 116.284 135.397 116.693C135.405 117.101 135.51 117.502 135.704 117.861C135.898 118.221 136.175 118.529 136.512 118.76C136.996 119.091 137.394 119.532 137.675 120.047C137.956 120.561 138.111 121.135 138.128 121.721C138.145 122.307 138.023 122.889 137.772 123.419C137.521 123.949 137.149 124.412 136.685 124.771L136.233 125.116Z"
        fill="#012B42"
      />
      <path
        d="M51.6196 56.3948C56.3627 48.1795 55.741 38.9409 50.2311 35.7597C44.7212 32.5785 36.4095 36.6595 31.6663 44.8748C26.9232 53.0901 27.5449 62.3288 33.0548 65.5099C38.5647 68.6911 46.8765 64.6101 51.6196 56.3948Z"
        fill="#FF9773"
      />
      <path
        d="M36.4219 64.1205C35.0719 64.1356 33.742 63.7917 32.5685 63.1241L32.0703 62.8361L32.6463 61.8396L33.1445 62.1276C37.6028 64.7023 44.4139 61.2636 48.3307 54.4956C52.2475 47.7276 51.8069 40.0956 47.3487 37.5266L46.8504 37.2386L47.4264 36.2421L47.9247 36.5301C52.933 39.4101 53.5637 47.7391 49.3301 55.0716C46.0872 60.6876 40.9147 64.1205 36.4219 64.1205Z"
        fill="#FFC0AB"
      />
      <path
        d="M94.3094 65.5058C99.8194 62.3247 100.44 53.086 95.6974 44.8707C90.9544 36.6554 82.6428 32.5744 77.1328 35.7556C71.6229 38.9367 71.0013 48.1754 75.7444 56.3907C80.4875 64.606 88.7994 68.687 94.3094 65.5058Z"
        fill="#FF9773"
      />
      <path
        d="M90.1194 63.3026C85.9524 63.3026 81.1629 60.1346 78.1591 54.9218L77.8711 54.4236L78.8675 53.8476L79.1555 54.3458C81.9463 59.1814 86.3354 62.165 90.0764 62.165C91.1434 62.1774 92.1934 61.9061 93.1204 61.3788L93.6194 61.0908L94.1954 62.0873L93.6964 62.3753C92.6074 62.9955 91.3734 63.3155 90.1194 63.3026Z"
        fill="#FFC0AB"
      />
      <path
        d="M29.4003 29.1389L28.5273 29.8906L41.5839 45.0542L42.4569 44.3025L29.4003 29.1389Z"
        fill="#FFFD54"
      />
      <path
        d="M21.193 41.856L20.7656 42.9257L34.5629 48.4387L34.9903 47.3689L21.193 41.856Z"
        fill="#FFFD54"
      />
      <path
        d="M24.2083 51.8945H16.5273V53.0465H24.2083V51.8945Z"
        fill="#FFFD54"
      />
      <path
        d="M103.476 34.7864L88.7894 46.5234L89.5084 47.4233L104.195 35.6863L103.476 34.7864Z"
        fill="#FFFD54"
      />
      <path
        d="M105.25 42.2619L98.7894 44.8437L99.2164 45.9135L105.677 43.3317L105.25 42.2619Z"
        fill="#FFFD54"
      />
      <path
        d="M104.616 53.7343H92.6565V54.8863H104.616V53.7343Z"
        fill="#FFFD54"
      />
    </BaseIllustration>
  );
};

export default IllustrationBunnyLaptopCoffee;
