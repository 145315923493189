import React, { useState } from "react";

import { getShadowTests } from "../../../api/core/controlPlane";
import { ShadowTests } from "../../../api/core/controlPlane.types";
import { useUser } from "../../../AuthProvider";

const useShadowTests = () => {
  const [{ id: accountId }] = useUser();

  const [shadowTests, setShadowTests] = useState<ShadowTests>(null);
  const [shadowTestsError, setShadowTestsError] = useState<string>("");

  const loadShadowTests = React.useCallback(
    async (applicationId: string, limit?: number) => {
      try {
        const tests = await getShadowTests(accountId || "")(
          applicationId,
          limit
        );

        setShadowTests(tests);
        setShadowTestsError("");
        return tests;
      } catch (e: any) {
        console.error(e);
        setShadowTestsError(e.toString());
      }
    },
    [accountId]
  );

  return {
    loadShadowTests,
    shadowTests,
    shadowTestsError,
    setShadowTests,
    setShadowTestsError,
  };
};

export default useShadowTests;
