import React, { useCallback, useEffect, useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import { useLocation } from "react-router-dom";
import {
  Box,
  Button2,
  Flex,
  IconX,
  Loading,
  Notification,
  Text,
} from "@console/dsc";
import { useTheme } from "@emotion/react";
import { DateTime } from "luxon";

import { EntityErrorMessage } from "../../api/core/controlPlane.types";
import { StyledFileDropzone } from "../../pages/App/subpages/NewAppRun/NewAppRun.styled";
import { areAllFilesCsv } from "../../utils/fileHandling/areAllFilesCsv";
import { formatBytes } from "../../utils/format";

import useFileIntercept from "./hooks/useFileIntercept";
import { getLocalDirectory } from "./utils/getLocalDirectory";
import { getRejectedFilesErrorMessage } from "./utils/rejectedFiles";
import { StyledFileItem, StyledFileList } from "./UploadFile.styled";
import { UploadFileProps } from "./UploadFile.types";

const UploadFile = ({
  acceptedFiles,
  app,
  isMultiple = false,
  isMultipleTypeAllowed = false,
  maxFiles = 1,
  setAcceptedFiles,
  m,
  mt,
  mr,
  mb,
  ml,
}: UploadFileProps) => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const localDirectory = getLocalDirectory(pathname);

  const [isDropzoneActive, setIsDropzoneActive] = useState(false);
  const [acceptedFilesError, setAcceptedFilesError] =
    useState<EntityErrorMessage>(null);
  const [localRejectedFiles, setLocalRejectedFiles] = useState<
    FileRejection[] | null
  >(null);

  const {
    fileInterceptError,
    interceptFiles,
    isFilesUploadProcessing,
    setFileInterceptError,
  } = useFileIntercept({ app, setAcceptedFiles });

  useEffect(() => {
    if (
      acceptedFiles &&
      acceptedFiles.length > 1 &&
      !isMultipleTypeAllowed &&
      !areAllFilesCsv(acceptedFiles)
    ) {
      setAcceptedFilesError(
        "Multiple files must all be CSV format to be uploaded as input."
      );
      return;
    }

    setAcceptedFilesError("");
  }, [acceptedFiles, isMultipleTypeAllowed]);

  const onDrop = useCallback(
    (droppedFiles) => {
      if (!droppedFiles || !droppedFiles.length) {
        setIsDropzoneActive(false);
        return;
      }

      const uploadedFiles = isMultiple
        ? [...(acceptedFiles || []), ...droppedFiles]
        : [...droppedFiles];

      if (uploadedFiles && uploadedFiles.length) {
        setAcceptedFiles(uploadedFiles);
      }

      setIsDropzoneActive(false);
    },
    [acceptedFiles, isMultiple, setAcceptedFiles]
  );

  const onDragEnter = useCallback(() => {
    setIsDropzoneActive(true);
  }, []);
  const onDragLeave = useCallback(() => {
    setIsDropzoneActive(false);
  }, []);

  const { fileRejections, getRootProps, getInputProps, isDragActive, open } =
    useDropzone({
      // TODO: set on front-end with new plans
      // maxSize: INPUT_LARGE_FILE_THRESHOLD_BYTES,
      onDragEnter,
      onDragLeave,
      onDrop,
      multiple: isMultiple,
      maxFiles: maxFiles,
      getFilesFromEvent: (e) => interceptFiles(e, localDirectory),
    });

  // manage file rejections locally
  useEffect(() => {
    if (fileRejections) {
      setLocalRejectedFiles(fileRejections);
    }
  }, [fileRejections]);

  const clearAcceptedFiles = (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    e.preventDefault();
    e.stopPropagation();

    setAcceptedFilesError(null);
    setFileInterceptError(null);
    setLocalRejectedFiles(null);
    setAcceptedFiles(null);
    setIsDropzoneActive(false);
    return;
  };

  const clearAcceptedFile = (
    e: {
      preventDefault: () => void;
      stopPropagation: () => void;
    },
    index: number
  ) => {
    e.preventDefault();
    e.stopPropagation();

    // last file about to be cleared, clear any errors as well
    if (acceptedFiles?.length === 1) {
      setAcceptedFilesError(null);
      setFileInterceptError(null);
    }

    setAcceptedFiles((prevState) => {
      if (!prevState) return null;
      return prevState.filter((_item, i) => i !== index);
    });
    setIsDropzoneActive(false);

    return;
  };

  const renderAcceptedFileMetadata = (file: File) => {
    return (
      <>
        <Text styleName="body-3" mr={2} styles={{ color: theme.color.gray600 }}>
          {DateTime.fromMillis(file.lastModified)
            .toFormat("yyyy-MM-dd · h:mm:ssa")
            .toLowerCase()}
        </Text>
        <Text styleName="meta-1-bold" styles={{ color: theme.color.gray600 }}>
          {formatBytes(file.size)}
        </Text>
      </>
    );
  };

  const renderAcceptedFileSummary = (file: File, isList?: boolean) => {
    if (!file) return null;
    return (
      <Box ml={2}>
        <Text styleName="body-2-bold" styles={{ color: theme.color.gray800 }}>
          {file.name}
        </Text>
        {isList ? (
          <Flex alignItems="baseline">{renderAcceptedFileMetadata(file)}</Flex>
        ) : (
          renderAcceptedFileMetadata(file)
        )}
      </Box>
    );
  };

  const renderAcceptedFileItem = (file: File, index: number) => {
    if (!file) return null;
    return (
      <StyledFileItem key={`${file.name}-${index}`}>
        {renderAcceptedFileSummary(file, true)}
        {renderClearFileButton(
          (e: { preventDefault: () => void; stopPropagation: () => void }) =>
            clearAcceptedFile(e, index)
        )}
      </StyledFileItem>
    );
  };

  const renderClearFileButton = (
    onClick: (e: {
      preventDefault: () => void;
      stopPropagation: () => void;
    }) => void
  ) => {
    return (
      <Button2
        ml="auto"
        type="text-quiet"
        icon={<IconX />}
        onClick={onClick}
        styles={{
          width: theme.spacing.s7,
          zIndex: 100,
          svg: {
            fill: theme.color.gray500,
          },
        }}
      />
    );
  };

  const conditionallyRenderFileRejectionOrErrorNotification = () => {
    if (
      (!localRejectedFiles || !localRejectedFiles.length) &&
      !acceptedFilesError
    )
      return null;

    return (
      <Notification
        mt={2}
        type="error"
        message={
          acceptedFilesError ||
          getRejectedFilesErrorMessage(localRejectedFiles!, isMultiple)
        }
      />
    );
  };

  const renderDropzoneLabel = () => {
    if (isDragActive) {
      return `Drop file${isMultiple ? "s" : ""} here`;
    }
    return `Or drag and drop file${isMultiple ? "s" : ""} in this space`;
  };
  const getButtonLabel = () => {
    return `Upload file${isMultiple ? "s" : ""}`;
  };

  const renderDropzoneContents = () => {
    // have files, but processing them
    if (isFilesUploadProcessing && acceptedFiles && acceptedFiles.length) {
      return (
        <Box ml={2}>
          <Loading />
        </Box>
      );
    }

    // have files, but just supporting single file upload, so show it
    if (
      !isMultiple &&
      !isFilesUploadProcessing &&
      acceptedFiles &&
      acceptedFiles.length
    ) {
      return renderAcceptedFileSummary(acceptedFiles[0]);
    }

    return (
      <Text ml={2} styleName="body-3" styles={{ color: theme.color.gray600 }}>
        {renderDropzoneLabel()}
      </Text>
    );
  };

  return (
    <Box {...{ m, mt, mr, mb, ml }} relative>
      <StyledFileDropzone {...getRootProps()} {...{ isDropzoneActive }}>
        <input {...getInputProps()} />

        <Flex alignItems="center">
          <Button2
            isDisabled={isDragActive}
            htmlType="button"
            type="outline"
            label={getButtonLabel()}
            onClick={(e: {
              preventDefault: () => void;
              stopPropagation: () => void;
            }) => {
              e.preventDefault();
              e.stopPropagation();
              open();
            }}
            styles={{ backgroundColor: theme.color.white }}
          />

          {renderDropzoneContents()}
        </Flex>
      </StyledFileDropzone>

      {fileInterceptError && (
        <Notification mt={2} type="error" message={fileInterceptError} />
      )}

      {!isMultiple && !!acceptedFiles?.length && (
        <Box
          style={{
            position: "absolute",
            right: theme.spacing.s1,
            top: theme.spacing.s1,
          }}
        >
          {renderClearFileButton(
            (e: { preventDefault: () => void; stopPropagation: () => void }) =>
              clearAcceptedFiles(e)
          )}
        </Box>
      )}

      {isMultiple && !!acceptedFiles?.length && (
        <StyledFileList>
          {acceptedFiles.map((acceptedFile, index) =>
            renderAcceptedFileItem(acceptedFile, index)
          )}
        </StyledFileList>
      )}

      {conditionallyRenderFileRejectionOrErrorNotification()}
    </Box>
  );
};

export default UploadFile;
