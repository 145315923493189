import { rem } from "../lib/tools";

const s1 = rem(4);
const s2 = rem(8);
const s3 = rem(12);
const s4 = rem(16);
const s5 = rem(20);
const s6 = rem(24);
const s7 = rem(32);
const s8 = rem(40);
const s9 = rem(48);
const s10 = rem(64);
const s11 = rem(96);

export const spacing = {
  s1,
  s2,
  s3,
  s4,
  s5,
  s6,
  s7,
  s8,
  s9,
  s10,
  s11,
  "-s1": `-${s1}`,
  "-s2": `-${s2}`,
  "-s3": `-${s3}`,
  "-s4": `-${s4}`,
  "-s5": `-${s5}`,
  "-s6": `-${s6}`,
  "-s7": `-${s7}`,
  "-s8": `-${s8}`,
  "-s9": `-${s9}`,
  "-s10": `-${s10}`,
  "-s11": `-${s11}`,
};
