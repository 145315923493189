import * as React from "react";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";

import { svgIconStyle } from "../lib/constants";

type StyledSvgProps = {
  activeColor?: string;
  focusColor?: string;
  hoverColor?: string;
};
const StyledSvg = styled.svg<StyledSvgProps>`
  ${(props) =>
    svgIconStyle({
      activeColor: props.activeColor,
      focusColor: props.focusColor,
      hoverColor: props.hoverColor,
    })}
`;

const IconRouteRoad = ({
  activeColor,
  focusColor,
  hoverColor,
  iconColor,
  iconSize,
}: {
  activeColor?: string;
  focusColor?: string;
  hoverColor?: string;
  iconColor?: string;
  iconSize?: number;
}) => {
  const theme = useTheme();
  const thisIconSize = iconSize || 28;
  return (
    <StyledSvg
      {...{ activeColor, focusColor, hoverColor }}
      width={thisIconSize}
      height={thisIconSize}
      viewBox="0 0 28 28"
      fill={iconColor || theme.color.gray700}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7609 16.5823C21.0048 16.0538 21.2315 15.4957 21.4046 14.9441C21.7631 13.8019 21.819 12.9129 21.5907 12.3684C21.4919 12.1329 21.3318 11.9396 21.0502 11.7902C20.7513 11.6317 20.2713 11.501 19.5167 11.4991C18.9288 11.5226 18.4572 11.8846 17.9896 12.8129C17.6096 13.5673 17.3402 14.4796 17.0504 15.4609L17.0504 15.461L17.0504 15.461C16.9708 15.7303 16.8898 16.0049 16.8044 16.2828C16.6162 16.896 16.4055 17.5239 16.1437 18.0718C15.8869 18.6091 15.5363 19.1672 15.017 19.5502C14.4498 19.9685 13.7636 20.1171 13.0123 19.951C12.3157 19.7969 11.6039 19.3831 10.8617 18.7778L7.54736 16.1409L11.0949 12.0771L9.85036 10.9708C10.425 10.6122 10.888 10.0916 11.1756 9.47285L13.1644 11.2407C13.5746 11.6053 13.6143 12.2323 13.2534 12.6457L10.4307 15.8792L12.1118 17.2164L12.1216 17.2245C12.7553 17.7419 13.1829 17.9403 13.4442 17.9981C13.6526 18.0442 13.7446 18.0035 13.83 17.9406C13.9632 17.8423 14.1394 17.6274 14.3391 17.2095C14.5338 16.8021 14.7093 16.2925 14.8925 15.6958C14.9607 15.4737 15.0301 15.2376 15.1021 14.9928L15.1021 14.9928C15.3964 13.9924 15.7335 12.846 16.2034 11.9132C16.8012 10.7263 17.7801 9.55153 19.4692 9.49953L19.4846 9.49906H19.4999C20.4748 9.49906 21.31 9.66412 21.9873 10.0233C22.6841 10.3929 23.1609 10.941 23.4351 11.5951C23.9528 12.8297 23.6989 14.3129 23.3128 15.543C23.094 16.2402 22.8085 16.9297 22.5111 17.5613C22.0438 17.08 21.4394 16.7327 20.7609 16.5823Z"
      />
      <path d="M10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10C9.10457 10 10 9.10457 10 8Z" />
      <path d="M22 20C22 18.8954 21.1046 18 20 18C18.8954 18 18 18.8954 18 20C18 21.1046 18.8954 22 20 22C21.1046 22 22 21.1046 22 20Z" />
    </StyledSvg>
  );
};

export default IconRouteRoad;
