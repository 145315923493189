import { DemoTabs, MarkerPoint, RouteSetByID } from "../../../../../Map.types";

export type StatKey =
  | "route_duration"
  | "stops_duration"
  | "waiting_duration"
  | "travel_distance"
  | "travel_time";

export type VehicleInfo = {
  vehicleId: string;
  vehicleTextColor: string;
  vehicleTotalTime?: number;
  vehicleTotalTravelTime?: number;
  vehicleTotalServiceTime?: number;
  vehicleTotalWaitTime?: number;
  vehicleTotalRouteDistance?: number;
};

type VehicleDisplayCheck = {
  hasTotalTime: boolean;
  hasTotalTravelTime: boolean;
  hasTotalServiceTime: boolean;
  hasTotalWaitTime: boolean;
  hasTotalRouteDistance: boolean;
};

export default function getVehicleDisplayData(
  activeTab: DemoTabs,
  point: MarkerPoint,
  routesById?: RouteSetByID
) {
  const getVehicleStat = (vehicleId: string, statKey: StatKey): number => {
    if (!routesById || activeTab !== "result") {
      return 0;
    }
    return routesById[vehicleId][statKey] || 0;
  };

  const uniqueVehicleIds = [
    ...Array.from(
      new Set(point.depotInfo?.map((depotInfoStop) => depotInfoStop.vehicleId))
    ),
  ];

  const vehiclesInfo = uniqueVehicleIds.map((vehicleId) =>
    point.depotInfo?.find(
      (depotInfoStop) => depotInfoStop.vehicleId === vehicleId
    )
  );

  const vehicleDisplayData: VehicleInfo[] = vehiclesInfo
    .map((vehicleInfo) => {
      const vehicleId = vehicleInfo?.vehicleId;
      if (!vehicleId) return null;

      return {
        vehicleId: vehicleId,
        vehicleTextColor: vehicleInfo?.vehicleTextColor || "",
        vehicleTotalTime: getVehicleStat(vehicleId, "route_duration"),
        vehicleTotalTravelTime: getVehicleStat(vehicleId, "travel_time"),
        vehicleTotalServiceTime: getVehicleStat(vehicleId, "stops_duration"),
        vehicleTotalWaitTime: getVehicleStat(vehicleId, "waiting_duration"),
        vehicleTotalRouteDistance: getVehicleStat(vehicleId, "travel_distance"),
      };
    })
    .filter((v) => !!v);

  return vehicleDisplayData;
}

export const getVehicleDisplayCheck = (
  vehicleDisplayData: VehicleInfo[]
): VehicleDisplayCheck => {
  let hasTotalTime = false;
  let hasTotalTravelTime = false;
  let hasTotalServiceTime = false;
  let hasTotalWaitTime = false;
  let hasTotalRouteDistance = false;

  vehicleDisplayData.forEach((vehicleDisplayItem) => {
    if (hasTotalTime) return;
    if (hasTotalTravelTime) return;
    if (hasTotalServiceTime) return;
    if (hasTotalWaitTime) return;
    if (hasTotalRouteDistance) return;

    hasTotalTime = !!vehicleDisplayItem.vehicleTotalTime;
    hasTotalTravelTime = !!vehicleDisplayItem.vehicleTotalTravelTime;
    hasTotalServiceTime = !!vehicleDisplayItem.vehicleTotalServiceTime;
    hasTotalWaitTime = !!vehicleDisplayItem.vehicleTotalWaitTime;
    hasTotalRouteDistance = !!vehicleDisplayItem.vehicleTotalRouteDistance;
    return;
  });

  return {
    hasTotalTime,
    hasTotalTravelTime,
    hasTotalServiceTime,
    hasTotalWaitTime,
    hasTotalRouteDistance,
  };
};
