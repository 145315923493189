import { Flex, Text, Tooltip } from "@console/dsc";
import {
  CombinedTableCSVHeader,
  TableData,
  TableHeader,
} from "@console/dsc/src/components/Table2/Table2.types";

import {
  BatchExperimentGroupedSummary,
  GroupedSummaryIndicatorDistribution,
  GroupedSummaryIndicatorDistributionPercentiles,
} from "../../../api/core/controlPlane.types";
import { FlexInline } from "../Experiments.styled";
import { GroupedSummaryChartBoxPlotData } from "../Experiments.types";

import {
  displayedIndicatorDistributions,
  formatGroupedSummaryColHeaderName,
  formatIndicatorValue,
  formatPercentileLabel,
  getDistributionIndicatorTooltipContent,
  sortIndicatorDistributionColumns,
} from "./groupedSummaryTable";

export type GroupedSummaryTable = {
  headers: TableHeader;
  data: TableData[];
  countWarning: boolean;
  charts: (GroupedSummaryChartBoxPlotData | null)[];
  percentileHeaders: TableHeader;
  percentiles: TableData[];
};
export type GroupedSummaryTables = {
  [key: string]: GroupedSummaryTable;
};

export type IndicatorsState = {
  [key: string]: boolean;
};

export type StatsByIndicator = {
  [key: string]: (keyof GroupedSummaryIndicatorDistribution)[];
};

const groupKeysMatch = (matchKeys: string[], givenKeys: string[]) => {
  if (matchKeys.length !== givenKeys.length) return false;
  return matchKeys.every((key, i) => key === givenKeys[i]);
};

const isTargetSummary = (
  groupKeys: string[],
  groupKeysToMatch: string[]
): boolean => {
  if (!groupKeys) {
    return false;
  }

  return (
    groupKeys &&
    !!groupKeys.length &&
    groupKeys.length === groupKeysToMatch.length &&
    groupKeysMatch(groupKeysToMatch, groupKeys)
  );
};

export const getFilteredSummaries = (
  groupedSummaries: BatchExperimentGroupedSummary[],
  groupKeysToMatch: string[] = ["instanceID", "versionID"]
) => {
  return groupedSummaries.filter((summary) =>
    isTargetSummary(summary.group_keys, groupKeysToMatch)
  );
};

export const mapAvailableIndicatorsToStats = (
  targetedGroupSummaries: BatchExperimentGroupedSummary[]
): StatsByIndicator => {
  const indicatorsToAvailableStats: StatsByIndicator = {};
  targetedGroupSummaries.forEach((summary) => {
    if (!summary.indicator_distributions || !summary.indicator_keys) return;

    summary.indicator_keys.forEach((key) => {
      indicatorsToAvailableStats[key] = Array.from(
        new Set(
          (indicatorsToAvailableStats[key] || []).concat(
            Object.keys(
              summary.indicator_distributions?.[key] || {}
            ) as (keyof GroupedSummaryIndicatorDistribution)[]
          )
        )
      );
    });
  });

  // sort values
  Object.keys(indicatorsToAvailableStats).forEach((key) => {
    indicatorsToAvailableStats[key] = indicatorsToAvailableStats[key].sort(
      sortIndicatorDistributionColumns
    );
  });

  return indicatorsToAvailableStats;
};

export const initIndicatorsState = (
  targetedSummaries: BatchExperimentGroupedSummary[]
) => {
  const indicatorsState: IndicatorsState = {};
  targetedSummaries.forEach((summary) => {
    if (!summary.indicator_distributions || !summary.indicator_keys) return;

    summary.indicator_keys.forEach((key) => {
      indicatorsState[key] = false;
    });
  });

  return indicatorsState;
};

const getLeadHeadersForTargetedSummaries = (
  targetedSummaries: BatchExperimentGroupedSummary[],
  theme: any
): TableHeader => {
  const leadHeaderIds: string[] = [];

  targetedSummaries.forEach((summary) => {
    leadHeaderIds.push(...(summary.group_keys || []));
    if (summary.number_of_runs_total) {
      leadHeaderIds.push("number_of_runs_total");
    }
  });

  const uniqueLeadHeaderIds = new Set(leadHeaderIds);

  const leadHeaders: TableHeader = [];
  uniqueLeadHeaderIds.forEach((id) =>
    leadHeaders.push({
      id: id,
      accessorKey: id,
      label: formatGroupedSummaryColHeaderName(id),
      cell:
        id === "number_of_runs_total"
          ? (props) => {
              return (
                <Text
                  as="span"
                  styleName="code"
                  styles={{
                    display: "block",
                    fontSize: theme.ui2Typography.fontSizeMeta1,
                    textAlign: "right",
                    textRendering: "auto",
                    WebkitFontSmoothing: "auto",
                  }}
                >
                  {props.getValue()}
                </Text>
              );
            }
          : (props) => {
              return (
                props.getValue() || (
                  <Text
                    styleName="body-3"
                    styles={{ color: theme.color.gray500 }}
                  >
                    None
                  </Text>
                )
              );
            },
      header: formatGroupedSummaryColHeaderName(id),
    })
  );

  return leadHeaders;
};

const getDistributionHeader = (
  indicator: string,
  distributionKey: string,
  shiftParameter: number,
  theme: any
): CombinedTableCSVHeader => {
  const distributionIndicatorTooltipContent =
    getDistributionIndicatorTooltipContent({
      key: distributionKey as keyof Omit<
        GroupedSummaryIndicatorDistribution,
        "percentiles"
      >,
      shiftParameter,
      indicator,
    });
  return {
    id: `${indicator}-${distributionKey}`,
    accessorKey: `${indicator}-${distributionKey}`,
    label: formatGroupedSummaryColHeaderName(distributionKey),
    header: () => {
      return (
        <Flex width="100%" justifyContent="flex-end">
          <Text
            as="span"
            styleName="label"
            styles={{ color: theme.color.gray600 }}
          >
            {formatGroupedSummaryColHeaderName(distributionKey)}
            {distributionIndicatorTooltipContent && (
              <FlexInline flexShrink={0}>
                <Tooltip mt={-1} ml={1} mr={-1} direction="left">
                  {distributionIndicatorTooltipContent}
                </Tooltip>
              </FlexInline>
            )}
          </Text>
        </Flex>
      );
    },
    cell: (props) => {
      return (
        <Flex width="100%" justifyContent="flex-end">
          <Text
            as="span"
            styleName="code"
            styles={{
              fontSize: theme.ui2Typography.fontSizeMeta1,
              textRendering: "auto",
              WebkitFontSmoothing: "auto",
            }}
          >
            {formatIndicatorValue({
              indicator,
              type: distributionKey as keyof Omit<
                GroupedSummaryIndicatorDistribution,
                "shifted_geometric_mean" | "percentiles"
              >,
              value: props.getValue(),
            })}
          </Text>
        </Flex>
      );
    },
  };
};

const getDataByIndicatorKeys = (
  indicator: string,
  targetedSummaries: BatchExperimentGroupedSummary[],
  availableStatsByIndicatorKey: StatsByIndicator
): TableData[] => {
  const dataArr: TableData[] = [];
  targetedSummaries.forEach((summary) => {
    const dataObj: { [key: string]: any } = {};
    // get data aligned with lead headers
    summary.group_keys.forEach((key, i) => {
      dataObj[key] = summary.group_values[i];
      dataObj["number_of_runs_total"] = summary.number_of_runs_total || 0;

      availableStatsByIndicatorKey[indicator] &&
        availableStatsByIndicatorKey[indicator].forEach((distroKey) => {
          if (!displayedIndicatorDistributions.includes(distroKey)) {
            return;
          }

          if (
            summary.indicator_distributions[indicator]?.[distroKey] !==
            undefined
          ) {
            dataObj[`${indicator}-${distroKey}`] =
              distroKey === "shifted_geometric_mean"
                ? summary.indicator_distributions[indicator][distroKey].value
                : summary.indicator_distributions[indicator][distroKey];
          } else if (distroKey === "count") {
            dataObj[`${indicator}-${distroKey}`] = 0;
          }
        });
    });
    dataArr.push(dataObj);
  });

  return dataArr;
};

const getHeadersByIndicator = (
  indicator: string,
  leadHeaders: TableHeader,
  availableStatsByIndicatorKey: { [key: string]: string[] },
  geoShift: number,
  theme: any
) => {
  let headers = leadHeaders;

  availableStatsByIndicatorKey[indicator] &&
    availableStatsByIndicatorKey[indicator].forEach((distributionKey) => {
      if (!displayedIndicatorDistributions.includes(distributionKey)) {
        return;
      }

      headers = headers.concat(
        getDistributionHeader(indicator, distributionKey, geoShift, theme)
      );
    });

  return headers;
};

const getGeometricShift = (
  targetedSummaries: BatchExperimentGroupedSummary[]
): number => {
  for (const summary of targetedSummaries) {
    for (const indicator of Object.keys(summary.indicator_distributions)) {
      const shift =
        summary.indicator_distributions?.[indicator]?.shifted_geometric_mean
          ?.shift;
      if (!isNaN(shift)) {
        return shift;
      }
    }
  }
  return 0;
};

const getChartsByIndicator = (
  indicator: string,
  targetSummaries: BatchExperimentGroupedSummary[]
) => {
  const chartDataArr: GroupedSummaryChartBoxPlotData[] = [];
  targetSummaries.forEach((summary) => {
    const singleChartData: GroupedSummaryChartBoxPlotData = {
      groupKeys: summary.group_keys,
      groupValues: summary.group_values,
    };

    if (summary.indicator_distributions[indicator]) {
      const distributions = summary.indicator_distributions[indicator];
      singleChartData["average"] = distributions.mean;
      singleChartData["min"] = distributions.min;
      singleChartData["percentile25"] = distributions.percentiles.p25;
      singleChartData["median"] = distributions.percentiles.p50;
      singleChartData["percentile75"] = distributions.percentiles.p75;
      singleChartData["max"] = distributions.max;
    }

    chartDataArr.push(singleChartData);
  });

  return chartDataArr;
};

const getPercentileHeadersByIndicator = (
  indicator: string,
  targetedSummaries: BatchExperimentGroupedSummary[],
  theme: any
) => {
  const percentileHeaders: TableHeader = [
    {
      id: "percentile",
      accessorKey: "percentile",
      label: "Percentile",
      header: () => (
        <Text as="strong" styleName="body-3-bold">
          Percentile
        </Text>
      ),
      cell: (props) => {
        return props.getValue();
      },
    },
  ];
  targetedSummaries.forEach((summary) => {
    const otherHeaders = summary.group_values.map((groupValue, index) => {
      return {
        id: `${index}-${indicator}-${summary.group_values.join("")}`,
        accessorKey: `${indicator}-${summary.group_values.join("")}`,
        label: groupValue,
        header: () => (
          <Flex width="100%" justifyContent="flex-end">
            <Text as="strong" styleName="body-3-bold">
              {groupValue}
            </Text>
          </Flex>
        ),
        cell: (props) => {
          return (
            <Flex width="100%" justifyContent="flex-end">
              <Text
                as="span"
                styleName="code"
                styles={{
                  fontSize: theme.ui2Typography.fontSizeMeta1,
                  textRendering: "auto",
                  WebkitFontSmoothing: "auto",
                }}
              >
                {formatIndicatorValue({
                  indicator,
                  type: "percentiles",
                  value: props.getValue(),
                })}
              </Text>
            </Flex>
          );
        },
      } as CombinedTableCSVHeader;
    });
    percentileHeaders.push(...otherHeaders);
  });

  return percentileHeaders;
};

const getPercentilesByIndicator = (
  indicator: string,
  targetedSummaries: BatchExperimentGroupedSummary[]
) => {
  const byPercent: {
    [key: string]: TableData;
  } = {};
  targetedSummaries.forEach((summary) => {
    Object.keys(
      summary.indicator_distributions[indicator]?.percentiles ||
        ({} as GroupedSummaryIndicatorDistributionPercentiles)
    ).forEach((percentileKey) => {
      byPercent[
        percentileKey as keyof GroupedSummaryIndicatorDistributionPercentiles
      ] = {
        ...byPercent[percentileKey],
        percentile: formatPercentileLabel(
          percentileKey as keyof GroupedSummaryIndicatorDistributionPercentiles
        ),
        [`${indicator}-${summary.group_values.join("")}`]: summary
          .indicator_distributions[indicator].percentiles[
          percentileKey as keyof GroupedSummaryIndicatorDistributionPercentiles
        ] as string | number,
      } as TableData;
    });
  });

  return Object.values(byPercent);
};

const getCountWarningsByIndicator = (
  indicator: string,
  dataByIndicator: any
) => {
  for (let i = 0; i < dataByIndicator.length; i++) {
    const dataValues = dataByIndicator[i];
    if (dataValues.number_of_runs_total !== dataValues[`${indicator}-count`]) {
      return true;
    }
  }

  return false;
};

const buildFinalObject = (
  headers: TableHeader,
  data: TableData[],
  countWarning: boolean,
  charts: GroupedSummaryChartBoxPlotData[],
  percentileHeaders: TableHeader,
  percentiles: TableData[]
) => {
  return {
    headers,
    data,
    countWarning,
    charts,
    percentiles,
    percentileHeaders,
  };
};

export const getGroupedSummaryTables = (
  theme: any,
  targetedSummaries: BatchExperimentGroupedSummary[],
  availableStatsByIndicatorKey: StatsByIndicator,
  indicator: string
): GroupedSummaryTable => {
  if (!targetedSummaries || targetedSummaries.length === 0) {
    // empty GroupedSummaryTable
    return {
      charts: [],
      countWarning: false,
      data: [],
      headers: [],
      percentileHeaders: [],
      percentiles: [],
    };
  }
  const geoShift = getGeometricShift(targetedSummaries);

  const leadHeaders = getLeadHeadersForTargetedSummaries(
    targetedSummaries,
    theme
  );

  const headersByIndicator = getHeadersByIndicator(
    indicator,
    leadHeaders,
    availableStatsByIndicatorKey,
    geoShift,
    theme
  );

  const dataByIndicatorKey = getDataByIndicatorKeys(
    indicator,
    targetedSummaries,
    availableStatsByIndicatorKey
  );

  const countWarningsByIndicator = getCountWarningsByIndicator(
    indicator,
    dataByIndicatorKey
  );

  const chartsByIndicator = getChartsByIndicator(indicator, targetedSummaries);

  const percentileHeadersByIndicator = getPercentileHeadersByIndicator(
    indicator,
    targetedSummaries,
    theme
  );

  const percentilesByIndicator = getPercentilesByIndicator(
    indicator,
    targetedSummaries
  );

  const groupedSummaryTable = buildFinalObject(
    headersByIndicator,
    dataByIndicatorKey,
    countWarningsByIndicator,
    chartsByIndicator,
    percentileHeadersByIndicator,
    percentilesByIndicator
  );

  groupedSummaryTable.data.sort(sortTableData);
  groupedSummaryTable.charts.sort(sortChartData);

  return groupedSummaryTable;
};

const sortTableData = (a: TableData, b: TableData) => {
  if (a.instanceID < b.instanceID) return -1;
  if (a.instanceID > b.instanceID) return 1;
  return 0;
};
const sortChartData = (
  a: GroupedSummaryChartBoxPlotData | null,
  b: GroupedSummaryChartBoxPlotData | null
) => {
  if (
    a &&
    a.groupValues &&
    a.groupValues.length > 0 &&
    b &&
    b.groupValues &&
    b.groupValues.length > 0
  ) {
    if (a.groupValues[0] < b.groupValues[0]) return -1;
    if (a.groupValues[0] > b.groupValues[0]) return 1;
  }

  return 0;
};
