import * as React from "react";

import BaseIcon, { IconProps } from "./BaseIcon";

const IconSocialDiscuss = ({
  iconSize,
  iconColor,
  iconColorActive,
  iconColorFocus,
  iconColorHover,
}: IconProps) => {
  return (
    <BaseIcon
      {...{
        iconSize,
        iconColor,
        iconColorActive,
        iconColorFocus,
        iconColorHover,
      }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3781 3.31017L2.62185 3.3102C1.89648 3.3102 1.30846 3.89822 1.30846 4.62358V10.1329C1.30846 10.8583 1.89649 11.4463 2.62185 11.4463H8.00003C8.17355 11.4463 8.33995 11.5152 8.46265 11.6379L10.494 13.6692V12.1005C10.494 11.7392 10.7869 11.4463 11.1482 11.4463H13.3782C14.1035 11.4463 14.6915 10.8583 14.6915 10.1329V4.62356C14.6915 3.8982 14.1035 3.31017 13.3781 3.31017ZM2.62184 2.00173L13.3781 2.00171C14.8262 2.00171 16 3.17555 16 4.62356V10.1329C16 11.5809 14.8262 12.7548 13.3782 12.7548H11.8024V15.2487C11.8024 15.5133 11.643 15.7519 11.3985 15.8531C11.1541 15.9544 10.8727 15.8984 10.6856 15.7113L7.72904 12.7548H2.62185C1.17384 12.7548 0 11.5809 0 10.1329V4.62358C0 3.17558 1.17384 2.00174 2.62184 2.00173Z"
      />
    </BaseIcon>
  );
};

export default IconSocialDiscuss;
