import { Box, Text } from "@console/dsc";

import { TEXT_MEASURE_MAX_WIDTH } from "../../../utils/constants";

type EmptyAppsMessageProps = {
  message: string;
  docsLink?: string;
  testId?: string;
};

const EmptyAppsMessage = ({
  docsLink,
  message,
  testId,
}: EmptyAppsMessageProps) => {
  return (
    <Box mt={4} maxWidth={TEXT_MEASURE_MAX_WIDTH}>
      <Text
        styleName="body-1"
        {...(testId && {
          "data-testid": testId,
        })}
      >
        {message}
      </Text>
      {docsLink && (
        <Text mt={2} styleName="body-1" hasLink>
          <a href={docsLink} target="_blank" rel="noreferrer">
            Read more in Docs
          </a>
        </Text>
      )}
    </Box>
  );
};

export default EmptyAppsMessage;
