import { startCase } from "lodash";

import {
  GroupedSummaryIndicatorDistribution,
  GroupedSummaryIndicatorDistributionPercentiles,
} from "../../../api/core/controlPlane.types";
import { FormatIndicatorValueParams } from "../Experiments.types";

/*
  NOTE
  result.elapsed  and run.time are being deprecated in favor of
  result.duration and run.duration, however, the backend is still
  returning the former values and not the latter. Once the backend
  has been updated these deprecated values can be removed
*/
export const groupedSummaryOrderConfig = {
  "result.value": 0,
  "metadata.duration": 1,
  "result.elapsed": 2,
  "result.duration": 2,
  "run.time": 3,
  "run.duration": 3,
  "run.iterations": 4,
};

export const displayedIndicatorDistributions = [
  "min",
  "max",
  "count",
  "mean",
  "std",
  "shifted_geometric_mean",
];
export const displayedGroupedSummaryKeys = [
  "group_keys",
  "group_values",
  "indicator_keys",
  "number_of_runs_total",
];

export const formatGroupedSummaryTableName = (str: string) => {
  switch (str) {
    case "metadata.duration":
      return "Total Execution Duration";
    case "result.elapsed":
    case "result.duration":
      return "Solution Duration";
    case "run.time":
    case "run.duration":
      return "Run Duration";
    default:
      return startCase(str.replace("result.", ""));
  }
};

export const formatNumberLocaleString = (
  val: number,
  minDecimals: number = 0,
  maxDecimals: number = 2
) => {
  return val.toLocaleString("en-US", {
    maximumFractionDigits: maxDecimals,
    minimumFractionDigits: minDecimals,
  });
};

const formatSeconds = (val: number) => {
  if (val === undefined || val === null || typeof val === "string") {
    return "";
  }

  if (val < 0.1) {
    // 4 non-zero figures max for val < 100ms
    const valMsPrecise = formatNumberLocaleString(val, 2, 4);
    return `${valMsPrecise} s`;
  }
  // Display down to hundreths of a second for val >= 100ms
  return `${formatNumberLocaleString(val, 2)} s`;
};

export const formatIndicatorValue = ({
  indicator,
  type,
  value,
}: FormatIndicatorValueParams): string => {
  if (value === undefined || value === null) return "";

  if (
    [
      "metadata.duration",
      "result.elapsed",
      "result.duration",
      "run.time",
      "run.duration",
    ].includes(indicator) &&
    type !== "count"
  ) {
    return formatSeconds(value);
  }

  if (
    ["run.iterations"].includes(indicator) ||
    ["count", "custom-indicator"].includes(type)
  ) {
    return formatNumberLocaleString(value, 0);
  }

  if (value === 0) return "0.00";

  return formatNumberLocaleString(value, 2);
};

export const formatGroupedSummaryColHeaderName = (name: string) => {
  if (name === "number_of_runs_total") {
    return "Total Runs";
  }
  if (name === "shifted_geometric_mean") {
    return "Geo. Mean";
  }
  if (name === "count") {
    return "n";
  }
  return startCase(name);
};

export const formatPercentileLabel = (
  percentile: keyof GroupedSummaryIndicatorDistributionPercentiles
): string => {
  const percent = parseInt(percentile.replace("p", ""));

  return typeof percent === "number" ? percent.toString() : percentile;
};

const configIndicatorDistributions: {
  [key in keyof GroupedSummaryIndicatorDistribution]: number;
} = {
  min: 2,
  max: 3,
  count: 1,
  mean: 4,
  std: 5,
  shifted_geometric_mean: 6,
  percentiles: 7,
};

export const sortIndicatorDistributionColumns = (
  a: keyof GroupedSummaryIndicatorDistribution,
  b: keyof GroupedSummaryIndicatorDistribution
): number => {
  const sortOrderA = configIndicatorDistributions[a];
  const sortOrderB = configIndicatorDistributions[b];

  if (sortOrderA < sortOrderB) return -1;
  if (sortOrderA > sortOrderB) return 1;
  return 0;
};

type GetDistributionIndicatorTooltipContentParams = {
  key: keyof Omit<GroupedSummaryIndicatorDistribution, "percentiles">;
  shiftParameter: number;
  indicator: string;
};

export const getDistributionIndicatorTooltipContent = ({
  key,
  shiftParameter,
  indicator,
}: GetDistributionIndicatorTooltipContentParams) => {
  if (key === "shifted_geometric_mean") {
    return groupedSummaryDictionary[key](shiftParameter).tooltipContent;
  }
  if (key === "mean" || key === "min" || key === "max") {
    return groupedSummaryDictionary[key](indicator).tooltipContent;
  }
  return groupedSummaryDictionary[
    key as keyof Omit<
      GroupedSummaryIndicatorDistribution,
      "shifted_geometric_mean" | "mean" | "min" | "max" | "percentiles"
    >
  ].tooltipContent;
};

const groupedSummaryDictionary = {
  mean: (indicator: string) => ({
    tooltipContent: `The average ${formatGroupedSummaryTableName(
      indicator
    ).toLowerCase()} of last solution for each run.`,
  }),
  shifted_geometric_mean: (shiftParameter?: number) => ({
    tooltipContent: `Shifted geometric mean of the value of the last solution found ${
      shiftParameter ? `(shift parameter = ${shiftParameter})` : ""
    }`,
  }),

  min: (indicator: string) => ({
    tooltipContent: `The minimum ${formatGroupedSummaryTableName(
      indicator
    ).toLowerCase()} of last solutions for each run.`,
  }),
  max: (indicator: string) => ({
    tooltipContent: `The maximum ${formatGroupedSummaryTableName(
      indicator
    ).toLowerCase()}  of last solutions for each run.`,
  }),
  count: {
    tooltipContent: "Number of observations for summary statistics.",
  },
  std: {
    tooltipContent: "Standard deviation.",
  },
};
