import { useState } from "react";
import { Details, Flex, Text, Toggle } from "@console/dsc";
import { useTheme } from "@emotion/react";
import { isEmpty } from "lodash";

import {
  RouteSetByID,
  SolutionRoute,
  VisibleVehicleRouteIds,
} from "../../../../../../../../components/Map/Map.types";
import { getVehicleStopCount } from "../../../../../../../../components/Map/utils/mapping";
import RoutePart from "../RoutePart";

import useRouteFocus from "./hooks/useRouteFocus";

export type VehicleDetailsProps = {
  routesById: RouteSetByID;
  routeSet: SolutionRoute;
  focusedRouteId: string;
  setFocusedRouteId: (focusedRouteId: string) => void;
  displayVehiclesNoRoutes: boolean;
  visibleVehicleRoutesById: VisibleVehicleRouteIds;
  setVisibleVehicleRoutesById: (
    visibleRoutesById: VisibleVehicleRouteIds
  ) => void;
};

export default function VehicleDetails({
  routesById,
  routeSet,
  focusedRouteId,
  setFocusedRouteId,
  displayVehiclesNoRoutes,
  visibleVehicleRoutesById,
  setVisibleVehicleRoutesById,
}: VehicleDetailsProps) {
  const theme = useTheme();
  const [openVehicles, setOpenVehicles] = useState<string[]>([]);
  const {
    setFocusedDebounced,
    clearFocusedDebounceTimeout,
    handleUnfocusRoute,
  } = useRouteFocus(setFocusedRouteId);

  if (isEmpty(routesById)) return null;

  const details = Object.keys(routesById)?.map((k) => {
    const currentRoute = routeSet.routesById[k];
    const route = currentRoute?.route;
    const isFocused = currentRoute?.id === focusedRouteId;
    const isRouteVisible = visibleVehicleRoutesById[currentRoute.id];

    const handleToggleClick = () => {
      // if route is currently focused & being turned off,
      // we need to remove focus
      isFocused && isRouteVisible && setFocusedRouteId("");
      // if route is being turned off, make sure
      // it doesn't gain focus afterward via the debounced focus call
      isRouteVisible && clearFocusedDebounceTimeout();
      // if route is being turned on, make sure if user continues
      // to hover over it that it gets focus
      !isRouteVisible && setFocusedDebounced(currentRoute, !isRouteVisible);
      const updatedVisibleVehicleRoutesById = {
        ...visibleVehicleRoutesById,
        [currentRoute.id]: !isRouteVisible,
      };
      setVisibleVehicleRoutesById(updatedVisibleVehicleRoutesById);
    };

    return !displayVehiclesNoRoutes && !currentRoute.hasRoute ? null : (
      <div
        className="vehicle-route-details"
        key={currentRoute.id}
        data-testid={`${currentRoute.id}-details`}
      >
        <Details
          key={currentRoute.id}
          hasBorderTop
          mr={3}
          onMouseOver={() => setFocusedDebounced(currentRoute, isRouteVisible)}
          onMouseOut={() => handleUnfocusRoute(isRouteVisible, isFocused)}
          onClick={() => {
            if (openVehicles.indexOf(currentRoute.id) !== -1) {
              setOpenVehicles(
                openVehicles.filter((ov) => ov !== currentRoute.id)
              );
            }
            setOpenVehicles((prev) => [...prev, currentRoute.id]);
          }}
          summary={
            <Flex alignItems="center" justifyContent="space-between">
              <Toggle
                bgColor={currentRoute.color}
                isActive={isRouteVisible}
                size="small"
                label={currentRoute.id}
                onClickFunc={handleToggleClick}
                aria-label={`${currentRoute.id} checkbox`}
              />
              <Text
                mr={2}
                styleName="body-3"
                textColor={
                  currentRoute.hasRoute
                    ? theme.color.gray700
                    : theme.color.gray600
                }
              >
                {currentRoute.hasRoute
                  ? `${getVehicleStopCount(
                      currentRoute.id,
                      currentRoute.route
                    )} stops`
                  : "no route"}
              </Text>
            </Flex>
          }
        >
          {openVehicles.indexOf(currentRoute.id) !== -1 &&
            route &&
            route.constructor === Array &&
            route.length &&
            route.map((part, index) => (
              <RoutePart
                key={`${part}-${index}`}
                part={part}
                index={index}
                partTotal={route.length}
              />
            ))}
        </Details>
      </div>
    );
  });

  return <>{details}</>;
}
