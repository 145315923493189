import { rem } from "@console/dsc/src/lib/tools";
import { css } from "@emotion/react";

import { CSSStylesProps } from "../../../lib/renderExtraStyles";
import { GetInputSizeParams } from "../Input.types";

export const getInputHeightValue = ({ size, theme }: GetInputSizeParams) => {
  switch (size) {
    case "large":
      return rem(44);
    case "small":
      return theme.spacing.s7;
    default:
      return theme.spacing.s8;
  }
};

export const getInputBorderRadiusValue = ({
  size,
  theme,
}: GetInputSizeParams) => {
  switch (size) {
    case "large":
      return theme.border.radiusSmall;
    case "small":
      return theme.border.radiusSmall;
    default:
      return theme.border.radius;
  }
};

export const getInputFontStyles = ({
  size,
  theme,
}: GetInputSizeParams): CSSStylesProps => {
  switch (size) {
    case "large":
      return {
        fontFamily: theme.ui2Typography.fontFamilyBody1,
        fontSize: theme.ui2Typography.fontSizeBody1,
        fontStyle: theme.ui2Typography.fontStyleBody1,
        fontWeight: theme.ui2Typography.fontWeightBody1,
        lineHeight: theme.ui2Typography.lineHeightBody1,
        letterSpacing: theme.ui2Typography.letterSpacingBody1,
        textTransform: theme.ui2Typography.textTransformBody1,
      };
    case "small":
      return {
        fontFamily: theme.ui2Typography.fontFamilyBody3,
        fontSize: theme.ui2Typography.fontSizeBody3,
        fontStyle: theme.ui2Typography.fontStyleBody3,
        fontWeight: theme.ui2Typography.fontWeightBody3,
        lineHeight: theme.ui2Typography.lineHeightBody3,
        letterSpacing: theme.ui2Typography.letterSpacingBody3,
        textTransform: theme.ui2Typography.textTransformBody3,
      };
    default:
      return {
        fontFamily: theme.ui2Typography.fontFamilyBody2,
        fontSize: theme.ui2Typography.fontSizeBody2,
        fontStyle: theme.ui2Typography.fontStyleBody2,
        fontWeight: theme.ui2Typography.fontWeightBody2,
        lineHeight: theme.ui2Typography.lineHeightBody2,
        letterSpacing: theme.ui2Typography.letterSpacingBody2,
        textTransform: theme.ui2Typography.textTransformBody2,
      };
  }
};

export const getInputSizeStyle = ({ size, theme }: GetInputSizeParams) => {
  const inputHeightValue = getInputHeightValue({ size, theme });
  const inputFontStyles = getInputFontStyles({ size, theme });

  switch (size) {
    case "large":
      return `
        ${css(inputFontStyles).styles}
        height: ${inputHeightValue};
        border-radius: ${getInputBorderRadiusValue({ size, theme })};
      `;
    case "small":
      return `
        ${css(inputFontStyles).styles}
        height: ${inputHeightValue};
        border-radius: ${getInputBorderRadiusValue({ size, theme })};
      `;
    default:
      return `
        ${css(inputFontStyles).styles}
        height: ${inputHeightValue};
        border-radius: ${getInputBorderRadiusValue({ size, theme })};
      `;
  }
};
