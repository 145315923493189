import * as React from "react";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";

import { svgIconStyle } from "../lib/constants";

const StyledSvg = styled.svg`
  ${svgIconStyle({})}
`;

const IconRabbit = ({ iconColor }: { iconColor?: string }) => {
  const theme = useTheme();

  return (
    <StyledSvg
      width="21"
      height="12"
      viewBox="0 0 21 12"
      fill={iconColor || theme.color.gray700}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17.2871 7.05005L16.9039 8.76298L19.7212 10.4759C20.0142 10.6562 20.1269 11.0394 19.9691 11.3549L19.8339 11.6254C19.6761 11.9184 19.3381 12.0761 19.0225 11.9635L13.478 10.1153L11.4496 8.76298H3.08778L1.62277 10.228C1.35231 10.4984 0.92408 10.4984 0.676156 10.228L0.202847 9.75468C-0.0676156 9.48422 -0.0676156 9.05598 0.202847 8.80806L2.68209 6.35136C2.86239 6.17105 3.11032 6.10343 3.3357 6.17105L4.19217 6.41897L5.86002 4.97651L4.95848 4.36797C4.41755 4.00735 4.34994 3.24104 4.80071 2.79027C5.25148 2.3395 6.01779 2.40712 6.3784 2.94804L7.05456 3.9172L12.5314 5.13428C13.2977 5.31459 14.0866 5.02159 14.5824 4.39051L14.8529 4.02989L11.1115 3.10581C10.6156 2.99312 10.21 2.6325 10.0296 2.15919C9.71411 1.3478 10.0973 0.423724 10.9087 0.108185C11.382 -0.0721233 11.9229 -0.0270462 12.3511 0.243416L16.6109 2.99312C16.949 2.94804 17.3096 3.01566 17.6026 3.2185L19.586 4.66097C20.0368 4.99905 20.2396 5.56251 20.1044 6.10344L20.0142 6.28374C19.9917 6.39644 19.879 6.50913 19.7663 6.53167L17.2871 7.05005Z" />
    </StyledSvg>
  );
};

export default IconRabbit;
