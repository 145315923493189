import React from "react";
import { useLocation } from "react-router-dom";
import { Box, Button2, Flex, Tag, Text } from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";

import { useUser } from "../../AuthProvider";
import { getPathWithoutOrg } from "../../utils/getPathWithoutOrg";
import { checkIsActivePage } from "../../utils/navigation";
import { getBannerType } from "../Banner/utils/getBannerType";
import { PageNavigationItem } from "../MainNav/MainNav.types";
import SectionTitle from "../SectionTitle";

import {
  SectionNavItem,
  SectionNavLink,
  SectionNavList,
  SectionSubNavList,
  StyledSectionNav,
  StyledSectionNavSidebar,
} from "./SectionNav.styles";
import { SectionNavProps } from "./SectionNav.types";

type RenderPageContentParams = {
  isActivePage: boolean;
  page: PageNavigationItem;
};
const renderPageContent = ({ isActivePage, page }: RenderPageContentParams) => {
  if (!page?.url) {
    return (
      <>
        {page.label}
        {(page?.isBeta || page?.tag) && (
          <Tag
            ml={1}
            size="small"
            type={page?.isBeta ? "early-access" : "default"}
            label={page?.tag || "beta"}
          />
        )}
      </>
    );
  }
  if (page?.type === "button") {
    return (
      <Flex mt={4}>
        <Button2
          type="outline"
          label={page.label}
          isDisabled={page.isDisabled}
          to={page.url}
        />
      </Flex>
    );
  }
  return (
    <SectionNavLink
      data-testid={`${page.id}-link`}
      to={page.url}
      isactive={isActivePage.toString()}
      isdisabled={page?.isDisabled ? page?.isDisabled.toString() : undefined}
      disabled={page?.isDisabled ? true : undefined}
      aria-current={isActivePage ? "page" : undefined}
      aria-disabled={page?.isDisabled ? page.isDisabled : undefined}
    >
      {page.label}
      {page.isBeta && (
        <Tag
          ml={1}
          size="small"
          type="early-access"
          label={page?.tag || "beta"}
        />
      )}
    </SectionNavLink>
  );
};

const renderPage = (page: PageNavigationItem, pathname: string) => {
  const isActivePage: boolean =
    !!page?.url &&
    checkIsActivePage({
      currentPath: getPathWithoutOrg(pathname),
      pageUrl: getPathWithoutOrg(page.url),
    });

  return (
    <SectionNavItem
      key={page.id}
      id={page.id}
      className={`section-page-${page.id}`}
      isActive={isActivePage}
      data-testid={page.id}
      isDisabled={page?.isDisabled}
    >
      {renderPageContent({ isActivePage, page })}
    </SectionNavItem>
  );
};

const SectionNav = ({
  ariaLabel,
  pages,
  sectionIcon,
  sectionTitle,
}: SectionNavProps) => {
  const { pathname } = useLocation();
  const theme = useTheme();
  const [user] = useUser();
  const bannerType = getBannerType(user);

  return (
    <StyledSectionNavSidebar hasBanner={!!bannerType}>
      <Flex relative>
        {sectionIcon && (
          <Box mt={rem(-4.5)} mr={2} mb={rem(-9)} ml={-1}>
            {sectionIcon}
          </Box>
        )}
        {sectionTitle && <SectionTitle as="h3">{sectionTitle}</SectionTitle>}
      </Flex>

      <StyledSectionNav aria-label={ariaLabel}>
        <SectionNavList>
          {pages.map((page) => {
            if (page?.isSectionLabel) {
              return (
                <Text
                  key={page.id}
                  as="h4"
                  mt={7}
                  mb={3}
                  styleName="label"
                  styles={{ color: theme.color.gray600 }}
                >
                  {page.label}
                </Text>
              );
            }
            if (page?.pages && !!page.pages.length) {
              return (
                <React.Fragment key={page.id}>
                  {renderPage(page, pathname)}

                  <SectionSubNavList>
                    {page.pages.map((page) => {
                      return renderPage(page, pathname);
                    })}
                  </SectionSubNavList>
                </React.Fragment>
              );
            } else {
              return renderPage(page, pathname);
            }
          })}
        </SectionNavList>
      </StyledSectionNav>
    </StyledSectionNavSidebar>
  );
};

export default SectionNav;
