import {
  GetCommandConfigureParams,
  GetCommandInstallParams,
} from "../Install.types";

export const getCommandInstall = ({
  apiKey,
  apiBaseUrl,
  consoleBaseUrl,
}: GetCommandInstallParams) => {
  const renderApiKey = apiKey || "<YOUR_API_KEY>";

  return `export NEXTMV_API_KEY=${renderApiKey}
export NEXTMV_BASE_URL=${apiBaseUrl}
curl -sS "${consoleBaseUrl}/install-cli.txt" | bash -`;
};

export const getCommandConfigure = ({ apiKey }: GetCommandConfigureParams) => {
  const renderApiKey = apiKey || "<YOUR_API_KEY>";

  return `nextmv configure --api-key ${renderApiKey}`;
};

export const getCommandCommunityClone = (
  templateId?: string | null
): string => {
  if (!templateId) return "";
  return `nextmv community clone -a ${templateId}`;
};

export const getCommandChangeDirectory = (
  templateId?: string | null
): string => {
  if (!templateId) return "";
  return `cd ${templateId}`;
};
