export const getObjectiveData = (output?: any) => {
  if (!output) return;

  if (
    output?.solutions &&
    Array.isArray(output.solutions) &&
    output.solutions.length
  ) {
    const solution = output.solutions[0];
    if (typeof solution !== "object" || Array.isArray(solution)) return;

    if (solution?.objective) {
      return solution.objective;
    }
  }

  return;
};
