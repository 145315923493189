import { trackEvent } from "../../../analytics";
import { AppInstanceResponse } from "../../../api/core/controlPlane.types";
import { ExecutionClassType } from "../../../api/core/dataPlane.types";
import {
  EC_8C_16GB_12H,
  EC_8C_16GB_120M,
  EC_16C_60GB_12H,
  EC_16C_60GB_30M,
  EC_DEFAULT,
} from "../../../config/apps";
import { CheckInstanceForCurrentParams } from "../App.types";

export const isInstanceDefault = ({
  app,
  instance,
}: CheckInstanceForCurrentParams) => {
  return app.default_instance === instance.id;
};

export const trackInstanceFieldChanges = (
  instance: AppInstanceResponse,
  pendingInstance: AppInstanceResponse
) => {
  let instanceKey: keyof AppInstanceResponse;
  for (instanceKey in instance) {
    if (
      instanceKey === "version_id" &&
      instance[instanceKey] !== pendingInstance[instanceKey]
    ) {
      trackEvent("Instances", {
        view: "Edit Instance",
        action: "Field Changed",
        meta: {
          field: instanceKey as "version_id",
        },
      });
    }
    if (
      instanceKey === "configuration" &&
      instance[instanceKey]?.options !== pendingInstance[instanceKey]?.options
    ) {
      trackEvent("Instances", {
        view: "Edit Instance",
        action: "Field Changed",
        meta: {
          field: "configuration.options",
        },
      });
    }
  }
};

export const getExecutionClassDescription = (
  executionClass: ExecutionClassType
): string => {
  switch (executionClass) {
    case EC_DEFAULT:
      return "6 cores, 9500 MB, 14.5 minutes max. run time";
    case EC_8C_16GB_120M:
      return "8 cores, 16 GB, 120 minutes max. run time";
    case EC_8C_16GB_12H:
      return "8 cores, 16 GB, 12 hours max. run time";
    case EC_16C_60GB_30M:
      return "16 cores, 60 GB, 30 minutes max. run time";
    case EC_16C_60GB_12H:
      return "16 cores, 60 GB, 12 hours max. run time";
    default:
      return "";
  }
};

export const checkIsPremiumExecutionClass = (
  executionClass: ExecutionClassType
): boolean => {
  if (executionClass === EC_DEFAULT) return false;
  return true;
};
