import styled from "@emotion/styled";

import { filterProps } from "../../lib/filterProps";
import { rem } from "../../lib/tools";

import { GetToggleSizeStylesParams, ToggleButtonProps } from "./Toggle.types";

const getToggleSizeStyles = ({ size, theme }: GetToggleSizeStylesParams) => {
  switch (size) {
    case "x-small":
      return `
        width: ${rem(28)};
        height: ${theme.spacing.s4};
        &::after {
          width: ${theme.spacing.s3};
          height: ${theme.spacing.s3};
        }
        &[aria-pressed="true"] {
          &::after {
            transform: translateX(${theme.spacing.s3});
          }
        }
      `;
    case "small":
      return `
        width: ${theme.spacing.s7};
        height: ${theme.spacing.s5};
        &::after {
          width: ${theme.spacing.s4};
          height: ${theme.spacing.s4};
        }
        &[aria-pressed="true"] {
          &::after {
            transform: translateX(${theme.spacing.s3});
          }
        }
      `;
    default:
      return `
        width: ${theme.spacing.s8};
        height: ${theme.spacing.s6};
        &::after {
          width: ${theme.spacing.s5};
          height: ${theme.spacing.s5};
        }
        &[aria-pressed="true"] {
          &::after {
            transform: translateX(${theme.spacing.s4});
          }
        }
      `;
  }
};

export const ToggleButton = styled("button", {
  shouldForwardProp: (prop: any) => filterProps(prop),
})<ToggleButtonProps>`
  position: relative;
  cursor: pointer;
  border: 0;
  border-radius: ${({ theme }) => theme.spacing.s3};
  background-color: ${({ theme }) => theme.color.gray300};

  &:focus-visible {
    outline: 0;
    box-shadow: 0 0 0 ${rem(1.5)} ${({ theme }) => theme.color.white},
      0 0 0 ${rem(3.5)} ${(props) => props.bgColor};
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    left: ${rem(2)};
    top: ${rem(2)};
    border-radius: ${rem(10)};
    background-color: ${({ theme }) => theme.color.white};
    transition: transform 50ms ease-out;
    z-index: 1;
  }

  &[aria-pressed="true"] {
    background-color: ${(props) => props.bgColor};

    &::after {
      transition: transform 50ms ease-out;
    }
  }

  ${(props) =>
    getToggleSizeStyles({
      size: props.size,
      theme: props.theme,
    })}

  ${(props) =>
    props.isDisabled &&
    `
      cursor: not-allowed;
      opacity: 0.75;
    `}
`;
