import React from "react";
import { Box, Button2, Flex, Text } from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";

type DeleteActionProps = {
  cancelDeleteItem: (e: { preventDefault: () => void }) => void;
  deleteItem: (e: { preventDefault: () => void }) => void;
  itemName: string;
  itemTypeName: string;
};

const DeleteAction = ({
  cancelDeleteItem,
  deleteItem,
  itemName,
  itemTypeName,
}: DeleteActionProps) => {
  const theme = useTheme();

  return (
    <Flex
      hasBorderTop
      hasBorderWidthThick
      pt={4}
      justifyContent="space-between"
    >
      <Box width="100%">
        <Text styleName="body-1-bold" styles={{ color: theme.color.gray800 }}>
          Are you sure you want to delete the {itemName} {itemTypeName}?
        </Text>
        <Text mt={1} styleName="body-2">
          This action cannot be undone.
        </Text>
        <Button2
          mt={2}
          label="Delete Run Profile"
          data-testid="confirm-delete-run-profile"
          onClick={(e: { preventDefault: () => void }) => deleteItem(e)}
          styles={{
            backgroundColor: theme.color.red500,
            "&:hover": {
              backgroundColor: theme.color.red600,
            },
            "&:focus, &:active": {
              backgroundColor: theme.color.red700,
            },
            "&:focus-visible": {
              outlineWidth: rem(1.5),
              outlineStyle: "solid",
              outlineColor: theme.color.red900,
            },
          }}
        />
      </Box>

      <Button2
        ml={4}
        type="text"
        label="Cancel"
        onClick={(e: { preventDefault: () => void }) => cancelDeleteItem(e)}
      />
    </Flex>
  );
};

export default DeleteAction;
