import * as React from "react";

import BaseIcon, { IconProps } from "./BaseIcon";

const IconCaretDown = ({
  iconSize,
  iconColor,
  iconColorActive,
  iconColorFocus,
  iconColorHover,
  ...rest
}: IconProps) => {
  return (
    <BaseIcon
      {...{
        iconSize,
        iconColor,
        iconColorActive,
        iconColorFocus,
        iconColorHover,
      }}
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 5.79289L8 8.79289L11 5.79289L11.7071 6.5L8 10.2071L4.29289 6.5L5 5.79289Z"
      />
    </BaseIcon>
  );
};

export default IconCaretDown;
