import {
  GetPendingSubscriptionResponse,
  PlanResponse,
  Subscription,
} from "./controlPlane.types";
import {
  fetchPaymentApiWithToken,
  handleResponse,
  handleSubscriptionResponse,
} from "./paymentApi";
import { PromotionalCode } from "./paymentApi.types";

// @legacy-plans (unused)
export const getPlans = async (): Promise<PlanResponse[]> => {
  const res = await fetchPaymentApiWithToken("/plans");
  return res.json();
};

export const createSubscription = async (body: string) => {
  const resp = await fetchPaymentApiWithToken("/subscription", "POST", body);
  const data = await handleResponse(resp);
  return data;
};

export const getSubscription = async (): Promise<Subscription | null> => {
  try {
    const r = await fetchPaymentApiWithToken("/subscription");
    const respJson = await handleSubscriptionResponse(r);
    return respJson;
  } catch (e) {
    // prevent extra browser console error message on custom plan /checkout page
    // console.error(e);
    return null;
  }
};

export const getPendingSubscription =
  async (): Promise<GetPendingSubscriptionResponse> => {
    const resp = await fetchPaymentApiWithToken("/subscription/pending");
    const data = await handleResponse(resp);
    return data;
  };

export const createOrGetPendingSubscription = async (
  body: string
): Promise<GetPendingSubscriptionResponse> => {
  try {
    const data = await createSubscription(body);
    return data;
  } catch (e) {
    console.error(e);
    const data = await getPendingSubscription();
    return data;
  }
};

// @legacy-plans (unused)
export const getPromoCode = async (
  customerFacingCode: string
): Promise<PromotionalCode> => {
  const res = await fetchPaymentApiWithToken(
    `/promotioncode/${customerFacingCode}`
  );
  const data = await handleResponse(res);
  return data;
};

export const getPlanData = async (priceId: string): Promise<PlanResponse> => {
  const res = await fetchPaymentApiWithToken(`/plan/${priceId}`);
  const data = await handleResponse(res);
  return data;
};
