import { OrganizationMember } from "../../../../../api/core/controlPlane.types";
import { TeamMemberModalContent } from "../Members.types";

export const getModalContentTeamMemberRemoval = (
  teamMember: OrganizationMember
): TeamMemberModalContent => {
  return {
    title: `Remove member`,
    summary: `Are you sure you want to remove ${teamMember.email} from your team? This action cannot be undone. However, you can reinvite ${teamMember.email} to your team at any time.`,
    primaryActionLabel: "Remove from team",
    secondaryActionLabel: "Cancel",
  };
};

export const getModalContentTeamMemberInviteRemoval = (
  teamMember: OrganizationMember
): TeamMemberModalContent => {
  return {
    title: `Remove invite`,
    summary: `This will remove and disable the invite sent to ${teamMember.email}. You can resend the invite at any time.`,
    primaryActionLabel: "Remove invite",
    secondaryActionLabel: "Cancel",
  };
};

export const getModalContentEditTeamMemberRole = (
  teamMember: OrganizationMember
): TeamMemberModalContent => {
  return {
    title: `Edit role`,
    summary: `Select the role of ${teamMember.email} using the dropdown below. Click the Save button to save changes.`,
    primaryActionLabel: "Save",
    secondaryActionLabel: "Cancel",
  };
};
