import {
  AcceptanceTests,
  AppInputsData,
  AppInstancesData,
  AppsData,
  AppVersionsData,
  BatchExperiments,
  InputSets,
  ShadowTests,
  SwitchbackTestsList,
} from "../../api/core/controlPlane.types";
import { UserOrganizations } from "../../api/core/dataPlane.types";

type Entities =
  | null
  | AppInputsData
  | AcceptanceTests
  | AppInstancesData
  | AppsData
  | AppVersionsData
  | BatchExperiments
  | InputSets
  | ShadowTests
  | SwitchbackTestsList
  | UserOrganizations;

export const getFilteredEntities = (filterText: string, entities: Entities) => {
  if (!entities) return null;
  if (!filterText) return entities;

  return entities.filter((entity) => {
    const lowerCaseFilterText = filterText.toLowerCase();
    return (
      entity.name.toLowerCase().includes(lowerCaseFilterText) ||
      entity.id.toLowerCase().includes(lowerCaseFilterText) ||
      ("description" in entity &&
        entity?.description?.toLowerCase().includes(lowerCaseFilterText))
    );
  });
};
