import React, { useMemo } from "react";
import { useTheme } from "@emotion/react";

import {
  parseSchemaShiftSchedulingRequiredWorkers,
  parseSchemaShiftSchedulingWorkers,
} from "../../../utils/schemaCheckShiftScheduling";
import VisualizationSchedulingInput from "../../RunDetails/components/VisualizationSchedulingInput";
import {
  RequiredWorkerShift,
  WorkerToSchedule,
} from "../../RunDetails/RunDetails.types";
import {
  getStartEndRange,
  getVizHourRange,
  getWorkerColors,
  getWorkersRange,
} from "../../RunDetails/utils/getWorkerData";

type SchedulingVisualizationProps = {
  requiredWorkers: RequiredWorkerShift[];
  workers: WorkerToSchedule[];
};
const SchedulingVisualization = ({
  requiredWorkers,
  workers,
}: SchedulingVisualizationProps) => {
  const theme = useTheme();

  // filter out bad data before trying to render visualization
  const sanitizedWorkers = useMemo(() => {
    return parseSchemaShiftSchedulingWorkers(workers);
  }, [workers]);
  const sanitizedRequiredWorkerShifts = useMemo(() => {
    return parseSchemaShiftSchedulingRequiredWorkers(requiredWorkers);
  }, [requiredWorkers]);

  return (
    <VisualizationSchedulingInput
      workers={sanitizedWorkers}
      workerColors={getWorkerColors({
        theme,
        workers: sanitizedWorkers,
      })}
      requiredWorkerShifts={sanitizedRequiredWorkerShifts}
      visualizeHoursRange={getVizHourRange(
        getWorkersRange(sanitizedWorkers),
        getStartEndRange({
          entities: sanitizedRequiredWorkerShifts,
        })
      )}
    />
  );
};

export default SchedulingVisualization;
