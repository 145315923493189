import React, { Dispatch, SetStateAction, useState } from "react";
import {
  Box,
  Button2,
  Flex,
  IconLock,
  Input,
  Notification,
  RowDetail,
  Text,
} from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../../../analytics";
import {
  Configuration,
  Integration,
  IntegrationKind,
  PutIntegrationPayload,
} from "../../../../api/core/controlPlane.types";
import { useIntegrations } from "../../../../contexts/integrations/Integrations.context";
import { integrationsTooltipCopy } from "../../data/microcopy";
import { PrivateInput, PublicInput } from "../../Integrations.types";
import FeatureCompatibility from "../FeatureCompatibility";

import DeleteAction from "./DeleteAction";

type EditInstalledIntegrationFormProps = {
  integration: Integration;
  integrationsError: string;
  privateInputs: PrivateInput[] | undefined;
  publicInputs?: PublicInput[];
  returnPath: string;
  setSubmitted: Dispatch<SetStateAction<boolean>>;
};

const EditInstalledIntegrationForm = ({
  integration,
  integrationsError,
  privateInputs,
  publicInputs,
  returnPath,
  setSubmitted,
}: EditInstalledIntegrationFormProps) => {
  const theme = useTheme();
  const { editIntegration, removeIntegration } = useIntegrations();

  const [editName, setEditName] = useState(integration?.name || "");
  const [description, setDescription] = useState(
    integration?.description || ""
  );
  const [showDeleteProfile, setShowDeleteProfile] = useState(false);

  const [privateInputValues, setPrivateInputValues] = useState<{
    [key: string]: any;
  }>({});

  const [additionalConfig, setAdditionalConfig] = useState<{
    [key: string]: any;
  }>({});

  const [errors, setErrors] = useState({
    name: false,
  });

  const handlePrivateInputChange: (event: {
    target: { value: string; name: string };
  }) => void = ({ target: { value, name } }) => {
    setPrivateInputValues({ ...privateInputValues, [name]: value });
  };

  const handleReplacePrivateInput = (name: string) => {
    setPrivateInputValues({
      [name]: "",
    });
  };

  const handleInstalledIntegrationSave = (e: {
    preventDefault: () => void;
  }) => {
    e.preventDefault();

    if (!editName) {
      setErrors({ ...errors, name: true });
      return;
    }

    if (editName !== integration.name) {
      trackEvent("Integrations", {
        view: "Edit",
        action: "Field Changed",
        meta: {
          field: "name",
          type: integration.type,
        },
      });
    }

    trackEvent("Integrations", {
      view: "Edit",
      action: "Edit Integration Saved",
      meta: {
        type: integration.type,
      },
    });

    const payload = {
      name: editName,
      description,
    } as PutIntegrationPayload<IntegrationKind>;

    // first set configuration to the original so we don't lose it if no changes
    // const configObj: { [index: string]: string | Object } = {};
    const configObj = integration?.configuration || {};

    (privateInputs || []).forEach((input) => {
      const key = input.id;
      if (privateInputValues[key]) {
        trackEvent("Integrations", {
          view: "Edit",
          action: "Field Changed",
          meta: {
            field: key,
            type: integration.type,
          },
        });

        configObj[key] = privateInputValues[key];
      } else {
        configObj[key] = "";
      }
    });

    for (const [key, value] of Object.entries(
      additionalConfig as Configuration[IntegrationKind]
    )) {
      if (value) {
        trackEvent("Integrations", {
          view: "Edit",
          action: "Field Changed",
          meta: {
            field: key,
            type: integration.type,
          },
        });
      }
      configObj[key] = value;
    }

    payload["configuration"] = configObj as Configuration[IntegrationKind];

    editIntegration(integration.type, integration.id, payload);

    setSubmitted(true);
  };

  const confirmInstalledIntegrationDelete = (e: {
    preventDefault: () => void;
  }) => {
    e.preventDefault();

    return setShowDeleteProfile(true);
  };

  const deleteInstalledIntegration = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    trackEvent("Integrations", {
      action: "Integration Deleted",
      view: "Edit",
      meta: {
        type: integration.type,
      },
    });

    removeIntegration(integration.id, integration.type);

    setSubmitted(true);
  };

  const cancelDeleteInstalledIntegration = (e: {
    preventDefault: () => void;
  }) => {
    e.preventDefault();

    return setShowDeleteProfile(false);
  };

  const renderPublicInput = (input: PublicInput) => {
    switch (input.type) {
      case "onfleetFeatureCompatibility":
        // we can assume config from integration is onfleet & typecast
        // because this input type only exists for onfleet integrations
        const configuration =
          integration?.configuration as Configuration["onfleet"];
        return (
          <FeatureCompatibility
            compatibility={configuration.compatibility}
            onCompatibilityValueChange={(newValues) =>
              setAdditionalConfig({
                ...additionalConfig,
                compatibility: newValues,
              })
            }
            isEditable
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <form onSubmit={(e) => handleInstalledIntegrationSave(e)}>
        <RowDetail
          property="Name"
          secondaryLabel="For reference only"
          tooltipCopy={integrationsTooltipCopy.name.content}
          render={
            <Box width="100%" maxWidth={rem(408)}>
              <Input
                htmlType="text"
                errorMessage="Please enter a name"
                isError={errors.name}
                placeholder="Profile name"
                value={editName}
                onChange={(e: { target: { value: string } }) =>
                  setEditName(e.target.value)
                }
              />
            </Box>
          }
        />

        <RowDetail
          property="ID"
          secondaryLabel="Read-only"
          tooltipCopy={integrationsTooltipCopy.idEditView.content}
          tooltipExtraLinkLabel={
            integrationsTooltipCopy.idEditView.extraLinkLabel
          }
          tooltipExtraLinkUrl={integrationsTooltipCopy.idEditView.extraLinkUrl}
          render={
            <Box width="100%" maxWidth={rem(408)}>
              <Input
                readOnly
                isDisabled
                htmlType="text"
                placeholder="Integration ID"
                value={integration.id}
              />
            </Box>
          }
        />

        <RowDetail
          property="Description"
          secondaryLabel="(optional)"
          render={
            <Box width="100%" maxWidth={rem(408)}>
              <Input
                name="description"
                htmlType="text"
                placeholder="Integration description"
                value={description}
                onChange={(e: { target: { value: string } }) =>
                  setDescription(e.target.value)
                }
              />
            </Box>
          }
        />

        {privateInputs &&
          privateInputs.map((privateInput: PrivateInput) => (
            <RowDetail
              key={privateInput.id}
              property={privateInput.label}
              tooltipCopy={privateInput?.tooltipCopy?.content}
              tooltipExtraLinkLabel={privateInput?.tooltipCopy?.extraLinkLabel}
              tooltipExtraLinkUrl={privateInput?.tooltipCopy?.extraLinkUrl}
              render={
                privateInputValues[privateInput.id] != null ? (
                  <Box width="100%" maxWidth={rem(408)}>
                    <Input
                      htmlType="text"
                      name={privateInput.id}
                      icon={<IconLock iconColor={theme.color.green500} />}
                      placeholder={privateInput.placeholder}
                      value={privateInputValues[privateInput.id]}
                      data-testid="access-key-input"
                      onChange={handlePrivateInputChange}
                      styles={{
                        color: theme.color.green800,
                        borderColor: theme.color.green500,
                        "&::placeholder": {
                          color: "#67C7A2",
                        },
                        "&:focus-visible": {
                          boxShadow: `0 0 ${theme.spacing.s1} 0 ${theme.color.green300}`,
                        },
                        "&:active, &:focus": {
                          borderColor: theme.color.green600,
                        },
                      }}
                    />
                  </Box>
                ) : (
                  <Flex alignItems="baseline">
                    <Text
                      mr={1}
                      styleName="body-2"
                      styles={{ color: theme.color.green700 }}
                    >
                      (Not shown)
                    </Text>
                    <Box alignSelf="center">
                      <IconLock iconColor={theme.color.green600} />
                    </Box>
                    <Text
                      as="a"
                      isLink
                      ml={2}
                      styleName="meta-1-bold"
                      data-testid="replace-api-key-button"
                      onClick={(e: React.MouseEvent) => {
                        e.preventDefault();
                        handleReplacePrivateInput(privateInput.id);
                      }}
                    >
                      Replace API Key
                    </Text>
                  </Flex>
                )
              }
            />
          ))}

        {publicInputs &&
          publicInputs.map((input) => (
            <RowDetail
              key={input.id}
              property={input.label}
              secondaryLabel={input.secondaryLabel}
              tooltipCopy={input.tooltipCopy}
              render={renderPublicInput(input)}
            ></RowDetail>
          ))}

        {!showDeleteProfile && (
          <Flex
            hasBorderTop
            hasBorderWidthThick
            pt={4}
            justifyContent="space-between"
          >
            <Flex alignItems="center">
              <Button2
                data-testid="submit-edit"
                size="large"
                htmlType="submit"
                label="Save"
              />
              <Button2
                data-testid="cancel-edit"
                ml={2}
                size="large"
                type="text"
                to={returnPath}
                label="Cancel"
                onClick={() =>
                  trackEvent("Integrations", {
                    view: "Edit",
                    action: "Edit Integration Canceled",
                    meta: {
                      type: integration.type,
                    },
                  })
                }
              />
            </Flex>

            <Button2
              ml="auto"
              type="text"
              data-testid="delete-integration"
              label="Delete Integration"
              onClick={(e: { preventDefault: () => void }) =>
                confirmInstalledIntegrationDelete(e)
              }
              styles={{
                color: theme.color.red600,
                "&:hover": {
                  color: theme.color.red700,
                },
                "&:focus, &:active": {
                  color: theme.color.red800,
                },
                "&:focus-visible": {
                  outlineWidth: rem(1.5),
                  outlineStyle: "solid",
                  outlineColor: theme.color.red900,
                },
              }}
            />
          </Flex>
        )}
      </form>

      {integrationsError && (
        <Notification
          mt={4}
          hasContactExtra
          type="error"
          message={`Error: ${integrationsError}`}
        />
      )}

      {showDeleteProfile && (
        <DeleteAction
          cancelDeleteItem={cancelDeleteInstalledIntegration}
          deleteItem={deleteInstalledIntegration}
          itemName={integration.name}
          itemTypeName={"integration"}
        />
      )}
    </>
  );
};

export default EditInstalledIntegrationForm;
