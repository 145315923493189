import { UserOrganization } from "../../../../../api/core/dataPlane.types";
import { DetailsModalContent } from "../Details.types";

export const getModalContentLeaveTeam = (
  team: UserOrganization
): DetailsModalContent => {
  return {
    title: `Leave Team`,
    summary: `Are you sure you want to leave the team ${team.name}? This action cannot be undone,
    but you can be re-invited to the team by an administrator.`,
    primaryActionLabel: "Leave team",
    secondaryActionLabel: "Cancel",
  };
};
