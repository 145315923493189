import { PageNavigationItem } from "../../../components/MainNav/MainNav.types";

export const getMarketplacePages = (accId: string): PageNavigationItem[] => [
  {
    id: "marketplace-apps",
    label: "Apps",
    url: `/acc/${accId}/marketplace`,
  },
  {
    id: "marketplace-integrations",
    label: "Integrations",
    url: `/acc/${accId}/marketplace/integrations`,
  },
];
