import { PageNavigationItem } from "../../../components/MainNav/MainNav.types";
import { getAccUrl } from "../../../utils/navigation";
import { AppPagesProps } from "../App.types";

export const getAppPages = ({
  activeSectionId,
  appId,
  appType,
  accountId,
  canUserRun,
  features,
}: AppPagesProps): PageNavigationItem[] => {
  return [
    {
      id: "app-details",
      label: "Details",
      url: getAccUrl(accountId, `/app/${appId}`),
    },
    {
      id: "app-runs",
      label: "Runs",
      url: getAccUrl(accountId, `/app/${appId}/runs`),
      pages: ["runs"].includes(activeSectionId)
        ? [
            {
              id: "app-runs-all",
              label: "All",
              url: getAccUrl(accountId, `/app/${appId}/runs`),
            },
            {
              id: "app-runs-queue",
              label: "Queue",
              url: getAccUrl(accountId, `/app/${appId}/runs/queue`),
            },
          ]
        : [],
    },
    {
      id: "app-instances",
      label: "Instances",
      url: getAccUrl(accountId, `/app/${appId}/instances`),
    },
    ...(appType === "custom"
      ? [
          {
            id: "app-versions",
            label: "Versions",
            url: getAccUrl(accountId, `/app/${appId}/versions`),
          },
        ]
      : []),
    {
      id: "app-experiments",
      label: "Experiments",
      url: getAccUrl(accountId, `/app/${appId}/experiments`),
      pages: ["experiments", "experiment", "input-sets", "input-set"].includes(
        activeSectionId
      )
        ? [
            {
              id: "app-experiments-scenario",
              label: "Scenario",
              url: getAccUrl(accountId, `/app/${appId}/experiments/scenario`),
            },
            {
              id: "app-experiments-batch",
              label: "Batch",
              url: getAccUrl(accountId, `/app/${appId}/experiments/batch`),
            },
            {
              id: "app-experiments-acceptance",
              label: "Acceptance",
              url: getAccUrl(accountId, `/app/${appId}/experiments/acceptance`),
            },
            {
              id: "app-experiments-shadow",
              label: "Shadow",
              url: getAccUrl(accountId, `/app/${appId}/experiments/shadow`),
            },
            {
              id: "app-experiments-switchback",
              label: "Switchback",
              url: getAccUrl(accountId, `/app/${appId}/experiments/switchback`),
            },
            {
              id: "app-input-sets",
              label: "Input Sets",
              url: getAccUrl(accountId, `/app/${appId}/experiments/input-sets`),
            },
            {
              id: "app-inputs",
              label: "Inputs",
              url: getAccUrl(accountId, `/app/${appId}/experiments/inputs`),
            },
          ]
        : [],
    },
    // LEAVING FOR LATER REF
    // {
    //   id: "api-reference",
    //   label: "API Reference",
    //   url: getAccUrl(accountId, `/app/${appId}/api-reference`),
    // },
    // {
    //   id: "settings",
    //   label: "Settings",
    //   url: getAccUrl(accountId, `/app/${appId}/settings`),
    // },
    ...(canUserRun
      ? [
          {
            id: "run-app",
            label: "Run app",
            url: getAccUrl(accountId, `/app/${appId}/runs/new`),
          },
        ]
      : []),
  ] as PageNavigationItem[];
};
