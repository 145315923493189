import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import {
  AvatarExperimentBatch,
  Box,
  Input,
  Loading,
  RowDetail,
  Tabs,
} from "@console/dsc";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../../../analytics";
import { CreateBatchExperimentPayload } from "../../../../api/core/controlPlane.types";
import Footer from "../../../../components/Footer";
import Header from "../../../../components/Header";
import InstanceSelect from "../../../../components/InstanceSelect";
import { useMetaTitle } from "../../../../components/Layout";
import {
  INPUT_SET_MAX_INPUTS,
  PAYLOAD_EXPERIMENT_TYPE_BATCH,
  TAB_ID_INPUT_SET,
  TAB_ID_UPLOAD_FILES,
} from "../../../../config/experiments";
import { INPUT_WIDTH_STANDARD } from "../../../../config/general";
import { useExperiments } from "../../../../contexts/experiments/Experiments.context";
import useManageEntity from "../../../../hooks/useManageEntity";
import useStandardInputs from "../../../../hooks/useStandardInputs";
import { getSafeNameIdForEntity } from "../../../../utils/entities/getSafeNameIdForEntity";
import { AppPageProps } from "../../../App/App.types";
import useReturnPaths from "../../../App/hooks/useReturnPaths";
import SelectInputSet from "../../components/SelectInputSet";
import { createOptions } from "../../components/utils";
import { getConfigForNewInputSet } from "../../config/configDisplay";
import { BATCH_MAX_INSTANCE_LIMIT } from "../../data/constants";
import {
  batchTooltips,
  inputSetTooltips,
  sharedTooltipCopy,
} from "../../data/microcopy";
import { RowInputSetTabId } from "../../Experiments.types";
import { getSafeCloneName } from "../../utils/getSafeCloneName";
import NewInputSet from "../NewInputSet";

const pageTitle = "Create new experiment";

const NewExperiment = ({ app }: AppPageProps) => {
  const theme = useTheme();
  const [, setMetaTitle] = useMetaTitle();
  const { returnPath, returnPathList } = useReturnPaths();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const cloneId = searchParams.get("cloneId");

  // batch experiment management
  const [isProcessing, setIsProcessing] = useState(false);
  const [pendingInputSetId, setPendingInputSetId] = useState<string>("");
  const [pendingInstanceIds, setPendingInstanceIds] = useState<
    (string | undefined)[]
  >([]);

  // input set management
  const [activeTabInputSet, setActiveTabInputSet] =
    useState<RowInputSetTabId>(TAB_ID_INPUT_SET);
  const [isInputSetAdded, setIsInputSetAdded] = useState(false);
  const [isInputSetProcessing, setIsInputSetProcessing] = useState(false);
  const [shouldSubmitInputSet, setShouldSubmitInputSet] = useState(false);
  const [shouldUseFilesForInputSet, setShouldUseFilesForInputSet] =
    useState(false);

  const { loadBatchExperiments } = useExperiments();

  const {
    addEntity: addExperiment,
    entity: batchExperiment,
    entityAddError: batchExperimentAddError,
    entityLoadError: batchExperimentLoadError,
    isEntityAdded: isBatchExperimentAdded,
    loadEntity: loadBatchExperiment,
    setEntityAddError: setBatchExperimentAddError,
  } = useManageEntity("experiments/batch");

  const {
    getStandardInputsProps,
    pendingStandardInputs,
    standardInputsErrors,
    updateStandardInputs,
  } = useStandardInputs(app, "experiments/batch");

  const newInputSetIdAndName = useMemo(() => {
    return getSafeNameIdForEntity("inpset", pendingStandardInputs.id);
  }, [pendingStandardInputs.id]);

  // page display
  useEffect(() => {
    setMetaTitle(pageTitle);
  }, [setMetaTitle]);

  // add empty id for initial instance selection
  useEffect(() => {
    if (!pendingInstanceIds.length) {
      setPendingInstanceIds([""]);
    }
  }, [pendingInstanceIds.length]);

  // get batch experiment data for cloning if cloneId present
  useEffect(() => {
    if (cloneId && !batchExperiment && !batchExperimentLoadError) {
      loadBatchExperiment(app.id, cloneId);
    }
  }, [
    app.id,
    batchExperiment,
    batchExperimentLoadError,
    cloneId,
    loadBatchExperiment,
  ]);

  // if clone data, prefill new batch experiment fields
  useEffect(() => {
    if (cloneId && batchExperiment && !pendingStandardInputs.id) {
      // modify name to prevent duplicate ID error
      const modifiedBatchExperimentName = `${getSafeCloneName(
        batchExperiment.name
      )} clone`;

      // ID set automatically by name field
      updateStandardInputs([
        { key: "name", value: modifiedBatchExperimentName },
        { key: "description", value: batchExperiment?.description },
      ]);
      setPendingInputSetId(batchExperiment.input_set_id);
      setPendingInstanceIds(batchExperiment.instance_ids);
    }
  }, [
    batchExperiment,
    cloneId,
    pendingStandardInputs.id,
    updateStandardInputs,
  ]);

  // disable loading state if add new experiment error
  useEffect(() => {
    if (batchExperimentAddError && isProcessing) {
      setIsProcessing(false);
    }
  }, [batchExperimentAddError, isProcessing]);

  const handleBatchExperimentCreate = useCallback(async () => {
    setIsProcessing(true);

    // remove empty values (e.g. empty instance select box)
    const filteredPendingInstanceIds = pendingInstanceIds.filter(Boolean);
    trackEvent("Experiments", {
      view: "Create Batch Experiment",
      action: "Instances Added",
      meta: {
        count: filteredPendingInstanceIds.length,
      },
    });

    let payload: CreateBatchExperimentPayload = {
      ...pendingStandardInputs,
      type: PAYLOAD_EXPERIMENT_TYPE_BATCH,
    };

    if (shouldUseFilesForInputSet) {
      payload["input_set_id"] = newInputSetIdAndName.id;
    } else {
      payload["input_set_id"] = pendingInputSetId;
    }
    payload["instance_ids"] = [...filteredPendingInstanceIds];

    await addExperiment(app.id, payload);
  }, [
    addExperiment,
    app.id,
    newInputSetIdAndName.id,
    pendingInputSetId,
    pendingInstanceIds,
    pendingStandardInputs,
    shouldUseFilesForInputSet,
  ]);

  // finish create process after input set created
  useEffect(() => {
    if (isInputSetAdded && isInputSetProcessing) {
      setIsInputSetProcessing(false);
      handleBatchExperimentCreate();
    }
  }, [handleBatchExperimentCreate, isInputSetProcessing, isInputSetAdded]);

  const handleBatchExperimentPreCreate = (e: {
    preventDefault: any;
    stopPropagation: any;
  }) => {
    e.preventDefault();
    e.stopPropagation();

    if (shouldUseFilesForInputSet && !isInputSetAdded) {
      setIsInputSetProcessing(true);
      setShouldSubmitInputSet(true);
      setBatchExperimentAddError(null);
    } else {
      handleBatchExperimentCreate();
    }

    return;
  };

  const handleCancel = () => {
    trackEvent("Experiments", {
      view: "Create Batch Experiment",
      action: "Create Batch Experiment Canceled",
    });
    return;
  };

  const handleInputSetTabClick = (
    e: {
      preventDefault: () => void;
      stopPropagation: () => void;
    },
    tabId: RowInputSetTabId
  ) => {
    e.preventDefault();
    e.stopPropagation();
    setActiveTabInputSet(tabId);
  };

  if (cloneId && !batchExperiment && !batchExperimentLoadError) {
    return <Loading type="full-screen" dotColor={theme.color.orange500} />;
  }

  if (isBatchExperimentAdded) {
    trackEvent("Experiments", {
      view: "Create Batch Experiment",
      action: "New Batch Experiment Created",
    });

    loadBatchExperiments({
      applicationId: app.id,
      type: PAYLOAD_EXPERIMENT_TYPE_BATCH,
    });

    return <Redirect to={returnPathList} />;
  }

  const isActionButtonDisabled =
    !pendingStandardInputs.name ||
    !pendingStandardInputs.id ||
    (!shouldUseFilesForInputSet && !pendingInputSetId) ||
    !pendingInstanceIds.filter(Boolean).length ||
    !!standardInputsErrors.name ||
    !!standardInputsErrors.id;

  return (
    <>
      <Header
        configPageTitle={{
          label: pageTitle,
          ancestorIcon: <AvatarExperimentBatch size={24} type="fill" />,
          ancestorLabel: "Batch Experiments",
          ancestorUrl: returnPathList,
        }}
      />

      <Box pb={[6, 6, 8]}>
        <form>
          <RowDetail
            hasNoBorder
            property="Name"
            secondaryLabel="For reference only"
            render={
              <Box width="100%" maxWidth={INPUT_WIDTH_STANDARD}>
                <Input
                  {...getStandardInputsProps({
                    placeholder: "Batch experiment name",
                    testId: "new-batch-name-input",
                    type: "name",
                    trackEventCategory: "Experiments",
                    trackEventProperties: {
                      view: "Create Batch Experiment",
                      action: "Field Entered",
                      meta: {
                        field: "name",
                      },
                    },
                  })}
                />
              </Box>
            }
          />

          <RowDetail
            property="ID"
            tooltipCopy={sharedTooltipCopy.id("batch experiment").content}
            render={
              <Box width="100%" maxWidth={INPUT_WIDTH_STANDARD}>
                <Input
                  {...getStandardInputsProps({
                    placeholder: "Batch experiment ID",
                    testId: "new-batch-id-input",
                    type: "id",
                    trackEventCategory: "Experiments",
                    trackEventProperties: {
                      view: "Create Batch Experiment",
                      action: "Batch Experiment ID Changed",
                    },
                  })}
                />
              </Box>
            }
          />

          <RowDetail
            property="Description"
            secondaryLabel="(optional)"
            render={
              <Box width="100%" maxWidth={INPUT_WIDTH_STANDARD}>
                <Input
                  {...getStandardInputsProps({
                    placeholder: "Batch experiment description",
                    testId: "new-batch-description-input",
                    type: "description",
                    trackEventCategory: "Experiments",
                    trackEventProperties: {
                      view: "Create Batch Experiment",
                      action: "Field Entered",
                      meta: {
                        field: "description",
                      },
                    },
                  })}
                />
              </Box>
            }
          />

          <RowDetail
            property={
              activeTabInputSet === TAB_ID_UPLOAD_FILES
                ? "Upload files"
                : "Input Set"
            }
            tooltipCopy={
              activeTabInputSet === TAB_ID_UPLOAD_FILES
                ? inputSetTooltips.createViewUploadFiles.content
                : sharedTooltipCopy.inputSet("batch experiment").content
            }
            {...(activeTabInputSet === TAB_ID_UPLOAD_FILES && {
              secondaryLabel: `Upload up to ${INPUT_SET_MAX_INPUTS} files`,
            })}
            render={
              <Box>
                <Tabs
                  Tabs
                  ml={2}
                  mb={3}
                  size="small"
                  type="panel"
                  tabs={[
                    {
                      id: TAB_ID_INPUT_SET,
                      isActive: activeTabInputSet === TAB_ID_INPUT_SET,
                      label: "Existing",
                      isDisabled: shouldUseFilesForInputSet,
                      onClick: (e: {
                        preventDefault: () => void;
                        stopPropagation: () => void;
                      }) => handleInputSetTabClick(e, TAB_ID_INPUT_SET),
                    },
                    {
                      id: TAB_ID_UPLOAD_FILES,
                      isActive: activeTabInputSet === TAB_ID_UPLOAD_FILES,
                      label: "Upload files",
                      onClick: (e: {
                        preventDefault: () => void;
                        stopPropagation: () => void;
                      }) => handleInputSetTabClick(e, TAB_ID_UPLOAD_FILES),
                    },
                  ]}
                />

                {activeTabInputSet === TAB_ID_INPUT_SET && (
                  <SelectInputSet
                    {...{ pendingInputSetId }}
                    testId="new-batch-input-set-select"
                    appId={app.id}
                    onAdd={setPendingInputSetId}
                    trackEventCategory="Experiments"
                    trackEventProperties={{
                      view: "Create Batch Experiment",
                      action: "Input Set Selected",
                    }}
                  />
                )}

                {activeTabInputSet === TAB_ID_UPLOAD_FILES && (
                  <Box mt={-4} mb={-4}>
                    <NewInputSet
                      app={app}
                      configDisplay={getConfigForNewInputSet({
                        createTypeOption: createOptions[1],
                        entity: "batch experiment",
                        id: newInputSetIdAndName.id,
                        name: newInputSetIdAndName.name,
                      })}
                      setParentError={setBatchExperimentAddError}
                      setSignalHasSubmitted={setIsInputSetAdded}
                      setSignalIsInUse={setShouldUseFilesForInputSet}
                      signalHasSubmitted={isInputSetAdded}
                      signalShouldSubmit={shouldSubmitInputSet}
                      signalIsInUse={shouldUseFilesForInputSet}
                      wrapper={(children: React.ReactNode) => (
                        <div>{children}</div>
                      )}
                    />
                  </Box>
                )}
              </Box>
            }
          />

          <RowDetail
            property="Instances"
            tooltipCopy={batchTooltips.instance.content}
            secondaryLabel={`Select up to ${BATCH_MAX_INSTANCE_LIMIT} instances to compare`}
            render={
              <InstanceSelect
                {...{ app, pendingInstanceIds, setPendingInstanceIds }}
              />
            }
          />

          <Footer
            actionButtonLabel="Create batch experiment"
            app={app}
            endpoint="experiments/batch"
            error={batchExperimentAddError}
            handleCancel={handleCancel}
            handleMainAction={handleBatchExperimentPreCreate}
            isActionButtonLoading={isProcessing || isInputSetProcessing}
            isActionButtonDisabled={isActionButtonDisabled}
            returnPath={returnPath}
            returnPathList={returnPathList}
            view="create"
          />
        </form>
      </Box>
    </>
  );
};

export default NewExperiment;
