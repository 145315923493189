import { matchPath } from "react-router-dom";
import { startCase } from "lodash";

import { UserRole, UserRoles } from "../../api/core/dataPlane.types";
import { getPathWithoutOrg } from "../getPathWithoutOrg";

import { baseRouteToActionGroups, roleMapping } from "./config";
import { ActionGroups, PolicyCondition, RouteToActionGroup } from "./types";

const findMatchingRoute = (
  pathname: string,
  routeMapping?: RouteToActionGroup
): string | null => {
  if (!routeMapping) {
    return null;
  }
  const routes = Object.keys(routeMapping);
  for (const route of routes) {
    const match = matchPath(pathname, {
      path: route,
      exact: true,
    });
    if (match) {
      return route;
    }
  }
  return null;
};

const getActionGroupForView = (
  path: string,
  pathname: string
): ActionGroups | null => {
  const route = getPathWithoutOrg(path);
  const pathExtension = getPathWithoutOrg(pathname);

  const matchingRoute = findMatchingRoute(
    pathExtension,
    baseRouteToActionGroups[route]
  );
  if (matchingRoute === null) {
    return null;
  }
  return baseRouteToActionGroups[route][
    matchingRoute
  ] as any as ActionGroups | null;
};

export const transformRoles = (roles: UserRoles = []) => {
  return roles.map((r) => {
    if (r === "root") return "admin";
    if (r === "member") return "developer";
    return r;
  });
};

// This is not a full representation of what we need but
// will brute force this until we need more
// keeping this simple for now to avoid the use of eval
const evalCondition = (
  condition: string,
  resourceAttributes: { [key: string]: any }
) => {
  const negate = condition.startsWith("!");
  if (condition.includes("resource.") && !resourceAttributes) {
    return false;
  }
  const attribute = condition.replace(/[!]?resource\./, "");
  const value = resourceAttributes[attribute];
  if (value === undefined || value === null) {
    return false;
  }
  if (negate) {
    return !value;
  }
  return value;
};

const everyUserRoleHasARestriction = (
  roles: string[],
  restrictedRoles: string[]
) => {
  return roles.every((r) => {
    return restrictedRoles.includes(startCase(r));
  });
};

const checkPermissionConditions = (
  transformRoles: string[],
  conditions: { [key: string]: PolicyCondition } | undefined,
  resource: { [key: string]: any }
) => {
  // if there are no conditions, return true
  if (!conditions) return true;
  // if there are conditions but not every role has a condition, return true
  if (!everyUserRoleHasARestriction(transformRoles, Object.keys(conditions))) {
    return true;
  }

  // code to be executed for each role
  // This is not a full representation of what we need but
  // will brute force this until we need more
  for (const role of transformRoles) {
    const condition = conditions[startCase(role)];
    // if there is no condition for the role, continue
    if (!condition) continue;
    // if there is a condition for the role, check if it is satisfied
    if (
      condition.type === "when" &&
      evalCondition(condition.condition, resource)
    ) {
      return true;
    } else if (
      condition.type === "unless" &&
      !evalCondition(condition.condition, resource)
    ) {
      return true;
    }
  }
  return false;
};

const hasAccess = (
  userRoles: UserRoles = [],
  actionGroup: string,
  //TODO: This needs to take MULTIPLE resources be able to check status of all for
  // parent/child relationships
  resourceAttributes: { [key: string]: any }
): boolean => {
  const permission = roleMapping[actionGroup as keyof typeof roleMapping];

  const transformedRoles = transformRoles(userRoles);
  if (permission.type === "permit") {
    return (
      permission.roles.some((r: string) =>
        (transformedRoles as UserRoles).includes(r.toLowerCase() as UserRole)
      ) &&
      checkPermissionConditions(
        transformedRoles,
        permission.conditions,
        resourceAttributes
      )
    );
  }
  return false;
};

export const userHasAccessToView = (
  userRoles: UserRoles = [],
  path: string,
  pathname: string,
  resourceAttributes: { [key: string]: any }
): boolean => {
  const actionGroup = getActionGroupForView(path, pathname);
  if (!actionGroup) {
    return true;
  }
  return hasAccess(userRoles, actionGroup, resourceAttributes);
};

export const userHasAccessToAction = (
  userRoles: UserRoles = [],
  actionGroup: ActionGroups,
  resourceAttributes: { [key: string]: any }
) => {
  return hasAccess(userRoles, actionGroup, resourceAttributes);
};
