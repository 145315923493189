import {
  RunScenarioOptionSetConfig,
  RunsData,
  RunsDatum,
  RunStatistics,
} from "../../../../../api/core/controlPlane.types";
import { KEY_STATISTICS_STATUS } from "../../../../../config/experiments";
import {
  ApplicationRunTableRowData,
  GetRunHistoryItems,
  HeaderObj,
} from "../RunHistory.types";

const collectKeysFromHeaders = (
  headers: HeaderObj[],
  excludeIds?: string[]
) => {
  const headerIds = headers.map((header) => header.id);

  if (excludeIds) {
    return headerIds.filter((headerId) => {
      return !excludeIds.includes(headerId);
    });
  }
  return headerIds;
};

export const isSwitchbackRunWithPlanId = (experimentRuns: RunsData) => {
  return (
    !!experimentRuns &&
    experimentRuns.some(
      (run) =>
        run.switchback_experiment &&
        !isNaN(run.switchback_experiment?.plan_unit_index)
    )
  );
};

export const getRunHistoryItems = ({
  hasName,
  hasStatistics,
  runHistory,
  scenarioConfigOptionsHeaders = [],
  statsHeaders = [],
  showPlanUnitIndex = false,
}: GetRunHistoryItems): ApplicationRunTableRowData[] => {
  const allowedKeys = [
    "group_edit_id", // special for runs queue
    "id",
    ...(showPlanUnitIndex && isSwitchbackRunWithPlanId(runHistory)
      ? ["switchback_experiment"]
      : []),
    ...(hasName ? ["name"] : []),
    ...(runHistory && runHistory.some((run) => !!run.input_id)
      ? ["input_id"]
      : []),
    "status_v2",
    "scenario_id",
    "input_set_id",
    "repetition",
    "option_set",
    "options",
    "created_at",
    "user_email",
    "application_id",
    "application_instance_id",
    "application_version_id",
    "execution_class",
    ...(hasStatistics ? ["statistics"] : []),
  ];
  if (!runHistory) return [];

  return runHistory.map((run) => {
    if (!run?.experiment_id) run.experiment_id = "";

    let modifiedRun: ApplicationRunTableRowData = {};

    allowedKeys.forEach((allowedKey) => {
      if (allowedKey === "switchback_experiment") {
        if (!run.switchback_experiment) return;
        modifiedRun["plan_unit_index"] =
          run.switchback_experiment?.plan_unit_index;
        return;
      }
      if (!(allowedKey === "statistics" || allowedKey === "options")) {
        modifiedRun[allowedKey] =
          run[
            allowedKey as keyof Omit<
              RunsDatum,
              "statistics" | "switchback_experiment" | "options"
            >
          ] || "";
        return;
      }
      if (allowedKey === "options") {
        const scenarioConfigOptions = run[
          allowedKey
        ] as RunScenarioOptionSetConfig;
        const scenarioConfigOptionsIds = collectKeysFromHeaders(
          scenarioConfigOptionsHeaders
        );

        // fill empty if data doesn't exists
        if (
          typeof scenarioConfigOptions !== "object" ||
          Array.isArray(scenarioConfigOptions)
        ) {
          scenarioConfigOptionsIds.forEach((configOptionId) => {
            modifiedRun[configOptionId] = "";
          });
        } else {
          scenarioConfigOptionsIds.forEach((configOptionId) => {
            modifiedRun[configOptionId] = scenarioConfigOptions[configOptionId];
          });
        }
      }
      if (allowedKey === "statistics") {
        const statsKeys = collectKeysFromHeaders(statsHeaders, [
          KEY_STATISTICS_STATUS,
        ]);
        const statisticsObj: RunStatistics | undefined = run[allowedKey];

        modifiedRun[KEY_STATISTICS_STATUS] = statisticsObj?.status || "none";

        // fill empty statistics values if undefined
        if (!statisticsObj || !statisticsObj.indicators) {
          statsKeys.forEach((statKey) => {
            modifiedRun[statKey] = "";
          });
        } else {
          statsKeys.forEach((statKey) => {
            const indicatorMatch = (statisticsObj.indicators || []).find(
              (indicator) => indicator.name === statKey
            );
            if (indicatorMatch) {
              modifiedRun[indicatorMatch.name] = indicatorMatch.value;
            } else {
              modifiedRun[statKey] = "";
            }
          });
        }

        return;
      }
    });

    return modifiedRun;
  });
};
