import { rem } from "../../../lib/tools";
import { TooltipDirection, TooltipOrientation } from "../Tooltip.types";

export const renderLeftPosition = ({
  direction,
  theme,
}: {
  direction: TooltipDirection;
  theme: any;
}) => {
  switch (direction) {
    case "right":
      return `calc(${theme.spacing.s4} + ${rem(6)})`;
    case "left":
      return "auto";
  }
};

export const renderRightPosition = ({
  direction,
  theme,
}: {
  direction: TooltipDirection;
  theme: any;
}) => {
  switch (direction) {
    case "right":
      return "auto";
    case "left":
      return `calc(${theme.spacing.s4} + ${rem(6)})`;
  }
};

export const renderTopPosition = ({
  orientation,
  theme,
}: {
  orientation: TooltipOrientation;
  theme: any;
}) => {
  switch (orientation) {
    case "top":
      return `calc(${theme.spacing.s1} * -1)`;
    case "bottom":
      return "auto";
  }
};

export const renderBottomPosition = ({
  orientation,
  theme,
}: {
  orientation: TooltipOrientation;
  theme: any;
}) => {
  switch (orientation) {
    case "top":
      return "auto";
    case "bottom":
      return `calc(${theme.spacing.s1} * -1)`;
  }
};

export const renderArrowBackgroundImage = ({
  direction,
  theme,
}: {
  direction: TooltipDirection;
  theme: any;
}) => {
  switch (direction) {
    case "right":
      return `url("data:image/svg+xml,%3csvg width='5' height='9' viewBox='0 0 5 9' fill='${theme.color.gray900.replace(
        "#",
        "%23"
      )}' fill-opacity='0.92' xmlns='http://www.w3.org/2000/svg'%3e %3cpath d='M5 0L5 9L-4.3512e-07 4.5L5 0Z' /%3e %3c/svg%3e")`;
    case "left":
      return `url("data:image/svg+xml,%3csvg width='5' height='9' viewBox='0 0 5 9' fill='${theme.color.gray900.replace(
        "#",
        "%23"
      )}' fill-opacity='0.92' xmlns='http://www.w3.org/2000/svg'%3e %3cpath d='M0 9L1.18021e-06 1.19209e-07L5 4.5L0 9Z'/%3e %3c/svg%3e")`;
  }
};

export const renderArrowBackgroundPosition = ({
  direction,
  theme,
}: {
  direction: TooltipDirection;
  theme: any;
}) => {
  switch (direction) {
    case "right":
      return `${rem(3)} ${theme.spacing.s1}`;
    case "left":
      return `right ${rem(3)} top ${theme.spacing.s1}`;
  }
};

export const renderArrowLeftPosition = ({
  direction,
  theme,
}: {
  direction: TooltipDirection;
  theme: any;
}) => {
  switch (direction) {
    case "right":
      return `calc(-1 * ${theme.spacing.s2})`;
    case "left":
      return "auto";
  }
};

export const renderArrowRightPosition = ({
  direction,
  theme,
}: {
  direction: TooltipDirection;
  theme: any;
}) => {
  switch (direction) {
    case "right":
      return "auto";
    case "left":
      return `calc(-1 * ${theme.spacing.s2})`;
  }
};

export const renderArrowTopPosition = ({
  orientation,
  theme,
}: {
  orientation: TooltipOrientation;
  theme: any;
}) => {
  switch (orientation) {
    case "top":
      return theme.spacing.s1;
    case "bottom":
      return "auto";
  }
};

export const renderArrowBottomPosition = ({
  orientation,
  theme,
}: {
  orientation: TooltipOrientation;
  theme: any;
}) => {
  switch (orientation) {
    case "top":
      return "auto";
    case "bottom":
      return theme.spacing.s1;
  }
};
