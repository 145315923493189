import React, { useState } from "react";

import { getSwitchbackTests } from "../../../api/core/controlPlane";
import { SwitchbackTestsResponse } from "../../../api/core/controlPlane.types";
import { useUser } from "../../../AuthProvider";

const useSwitchbackTests = () => {
  const [{ id: accountId }] = useUser();

  const [switchbackTests, setSwitchbackTests] =
    useState<SwitchbackTestsResponse>({ items: null, next_page_token: "" });
  const [switchbackTestsError, setSwitchbackTestsError] = useState<string>("");

  const loadSwitchbackTests = React.useCallback(
    async (applicationId: string, pageToken?: string, limit?: number) => {
      try {
        const tests = await getSwitchbackTests(accountId || "")(
          applicationId,
          pageToken,
          limit
        );

        setSwitchbackTests((prevState) => {
          if (pageToken) {
            return {
              items:
                prevState && prevState?.items
                  ? tests && tests.items
                    ? prevState.items.concat(tests.items)
                    : prevState.items
                  : tests.items,
              next_page_token: tests.next_page_token || "",
            };
          }
          return tests;
        });
        setSwitchbackTestsError("");
        return tests;
      } catch (e: any) {
        console.error(e);
        setSwitchbackTestsError(e.toString());
      }
    },
    [accountId]
  );

  return {
    loadSwitchbackTests,
    switchbackTests,
    switchbackTestsError,
    setSwitchbackTests,
    setSwitchbackTestsError,
  };
};

export default useSwitchbackTests;
