import React from "react";

import { useFetchWithToken } from "../utils/hooks";

import { Subscription } from "./controlPlane.types";
import { handleSubscriptionResponse } from "./paymentApi";
import { PAYMENT_API_BASE } from "./paymentApi.config";

export const useFetchPayments = () => {
  return useFetchWithToken(`${PAYMENT_API_BASE}`);
};

export const useGetSubscription = () => {
  const fetchPayments = useFetchPayments();
  const getSubscription =
    React.useCallback(async (): Promise<Subscription | null> => {
      try {
        const r = await fetchPayments("/subscription");
        const respJson = await handleSubscriptionResponse(r);
        return respJson;
      } catch (e) {
        console.error(e);
        return null;
      }
    }, [fetchPayments]);

  return getSubscription;
};
