import { Link } from "react-router-dom";
import { filterProps } from "@console/dsc/src/lib/filterProps";
import { rem } from "@console/dsc/src/lib/tools";
import styled from "@emotion/styled";

import {
  MAIN_NAV_MOBILE_HEIGHT,
  MAIN_NAV_SIDEBAR_WIDTH,
  SECTION_NAV_SIDEBAR_WIDTH,
} from "../../utils/constants";
import { getMainViewHeight } from "../MainNav/utils/getMainViewHeight";

import {
  GetSectionNavLinkColorParams,
  SectionNavItemProps,
  SectionNavLinkProps,
  StyledSectionNavSidebarProps,
} from "./SectionNav.types";

export const StyledSectionNavSidebar = styled.div<StyledSectionNavSidebarProps>`
  box-sizing: border-box;
  width: 100%;
  height: ${(props) =>
    getMainViewHeight({
      hasBanner: props.hasBanner,
      type: "section-nav",
    })};
  margin-left: 0;
  padding-top: calc(
    ${MAIN_NAV_MOBILE_HEIGHT} + ${({ theme }) => theme.spacing.s5}
  );
  padding-right: ${({ theme }) => theme.spacing.s4};
  padding-bottom: ${({ theme }) => theme.spacing.s5};
  padding-left: ${({ theme }) => theme.spacing.s4};
  border-bottom-width: ${({ theme }) => theme.border.width};
  border-bottom-style: ${({ theme }) => theme.border.style};
  border-bottom-color: ${({ theme }) => theme.color.gray200};
  background-color: ${({ theme }) => theme.color.white};

  @media (min-width: ${({ theme }) => theme.breakpoint.sm}) {
    width: calc(100% - ${MAIN_NAV_SIDEBAR_WIDTH});
    padding-top: ${({ theme }) => theme.spacing.s5};
    margin-left: ${MAIN_NAV_SIDEBAR_WIDTH};
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.md}) {
    width: ${SECTION_NAV_SIDEBAR_WIDTH};
    height: ${(props) =>
      getMainViewHeight({
        breakpoint: "md",
        hasBanner: props.hasBanner,
        type: "section-nav",
      })};
    padding-top: ${({ theme }) => theme.spacing.s5};
    padding-bottom: 0;
    padding-left: calc(
      ${({ theme }) => theme.spacing.s1} + ${({ theme }) => theme.spacing.s6}
    );
    position: fixed;
    border-bottom: 0;
    border-right-width: ${({ theme }) => theme.border.width};
    border-right-style: ${({ theme }) => theme.border.style};
    border-right-color: ${({ theme }) => theme.color.gray200};
  }
`;

export const StyledSectionNav = styled.nav`
  margin-top: ${({ theme }) => theme.spacing.s4};

  @media (min-width: ${({ theme }) => theme.breakpoint.md}) {
    margin-top: ${({ theme }) => theme.spacing.s7};
  }
`;

export const SectionNavList = styled.ul``;
export const SectionSubNavList = styled.ul`
  margin-top: ${({ theme }) => theme.spacing.s1};
  margin-bottom: ${({ theme }) => theme.spacing.s1};
  padding-top: ${({ theme }) => theme.spacing.s1};
  padding-bottom: ${({ theme }) => theme.spacing.s2};
  padding-left: ${({ theme }) => theme.spacing.s3};
  border-left-width: ${({ theme }) => theme.border.width};
  border-left-style: ${({ theme }) => theme.border.style};
  border-left-color: ${({ theme }) => theme.color.gray200};

  li {
    margin-bottom: ${rem(-2)};
  }
`;

export const SectionNavItem = styled("li", {
  shouldForwardProp: (prop: any) => filterProps(prop),
})<SectionNavItemProps>`
  margin-top: ${({ theme }) => theme.spacing.s2};
  font-family: ${({ theme }) => theme.ui2Typography.fontFamilyBody2};
  font-size: ${({ theme }) => theme.ui2Typography.fontSizeBody2};
  font-style: ${({ theme }) => theme.ui2Typography.fontStyleBody2};
  font-weight: ${(props) =>
    props.isActive
      ? props.theme.ui2Typography.fontWeightBody2Bold
      : props.theme.ui2Typography.fontWeightBody2};
  line-height: 1.33;
  color: ${(props) =>
    props.isDisabled ? props.theme.color.gray600 : props.theme.color.gray700};
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  &:first-of-type {
    margin-top: 0;
  }

  /* special run app nav style */
  &.section-page-run-app a {
    display: inline-block;
    margin-top: ${({ theme }) => theme.spacing.s1};
    margin-left: ${({ theme }) => theme.spacing["-s1"]};
    padding-top: ${({ theme }) => theme.spacing.s1};
    padding-right: ${({ theme }) => theme.spacing.s2};
    padding-bottom: ${({ theme }) => theme.spacing.s1};
    padding-left: ${({ theme }) => theme.spacing.s2};
    border-width: ${({ theme }) => theme.border.widthThick};
    border-style: ${({ theme }) => theme.border.style};
    border-color: ${({ theme }) => theme.color.gray200};
    border-radius: ${({ theme }) => theme.border.radius};
    background-color: ${({ theme }) => theme.color.white};
    &:hover {
      background-color: ${({ theme }) => theme.color.gray100};
    }
  }
`;

const getSectionNavLinkColor = ({
  isactive,
  isdisabled,
  state,
  theme,
}: GetSectionNavLinkColorParams) => {
  if (isactive === "true") {
    return theme.color.gray800;
  }
  if (isdisabled === "true") {
    return theme.color.gray500;
  }
  return state === "hover" ? theme.color.gray800 : theme.color.gray700;
};

export const SectionNavLink = styled(Link, {
  shouldForwardProp: (prop: any) => filterProps(prop),
})<SectionNavLinkProps>`
  text-decoration: none;
  color: ${(props) =>
    getSectionNavLinkColor({
      isactive: props.isactive,
      isdisabled: props.isdisabled,
      theme: props.theme,
      state: "default",
    })};

  &:hover,
  &:focus,
  &:active {
    color: ${(props) =>
      getSectionNavLinkColor({
        isactive: props.isactive,
        isdisabled: props.isdisabled,
        theme: props.theme,
        state: "hover",
      })};
  }

  ${(props) =>
    props.isdisabled === "true" &&
    `
      pointer-events: none;
      cursor: not-allowed;
    `}
`;
