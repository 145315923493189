import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  AvatarExperimentBatch,
  Box,
  Flex,
  Loading,
  Notification,
  RowDetail,
  Tag,
  Text,
} from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";
import { DateTime } from "luxon";

import { trackEvent } from "../../../../analytics";
import { API_BASE } from "../../../../api/core/core.config";
import { getToken } from "../../../../api/utils/cognito";
import { useUser } from "../../../../AuthProvider";
import Header from "../../../../components/Header";
import { useMetaTitle } from "../../../../components/Layout";
import StandardError from "../../../../components/StandardError";
import { MEASURE_ENTITY_ROW } from "../../../../config/general";
import usePolling from "../../../../hooks/usePolling";
import { getIsCompletedState } from "../../../../utils/entities/getIsCompletedState";
import { getAccUrl } from "../../../../utils/navigation";
import { userHasAccessToAction } from "../../../../utils/rbac_utils";
import { ActionGroups } from "../../../../utils/rbac_utils/types";
import { AppPageProps } from "../../../App/App.types";
import useReturnPaths from "../../../App/hooks/useReturnPaths";
import { DEV_INT_ID } from "../../../App/subpages/RunDetails/utils/constants";
import { Table2HeaderObj } from "../../../App/subpages/RunHistory/RunHistory.types";
import { getRunHistoryTableHeaders } from "../../../App/subpages/RunHistory/utils/getRunHistoryTableHeaders";
import { getStatisticsHeaders } from "../../../App/subpages/RunHistory/utils/getStatisticsHeaders";
import ExperimentDetailsSection from "../../components/ExperimentDetailsSection";
import ExperimentGroupedSummaries from "../../components/ExperimentGroupedSummaries";
import ExperimentProgressBar from "../../components/ExperimentProgressBar";
import ExperimentRuns from "../../components/ExperimentRuns";
import { batchTooltips } from "../../data/microcopy";
import { StartStopModalType } from "../../types/Experiment.types";
import {
  getIsExperimentResolved,
  shouldDisplayExperimentActions,
} from "../../utils/experimentStatus";
import { renderActionContent } from "../../utils/renderActions";

import CancelBatchExperimentConfirmModal from "./components/CancelBatchExperimentConfirmModal";
import useBatchExperimentCancel from "./hooks/useBatchExperimentCancel";

const BatchExperiment = ({ app }: AppPageProps) => {
  const [{ id: accId, roles, features }] = useUser();
  const [token, setToken] = useState<string | null>(null);
  const [, setMetaTitle] = useMetaTitle();
  const theme = useTheme();
  const { pathname, search } = useLocation();
  const { returnPathList } = useReturnPaths();
  const params = useParams() as {
    id: string;
    field?: string;
  };

  const searchParams = new URLSearchParams(search);

  const batchViz = searchParams.get("batch_viz");
  const runViz = searchParams.get("run_viz");
  const encodedApiUrl = encodeURIComponent(API_BASE);
  const customVizAllowed = features?.streamlit;

  const {
    entity: batchExperiment,
    entityMetadata: batchExperimentMetadata,
    entityLoadError: batchExperimentError,
    entityLoadMetadataError: batchExperimentMetadataError,
    entityLoadRunsError: batchExperimentRunsError,
    entityRuns: batchExperimentRuns,
    loadEntity: loadBatchExperiment,
    loadEntityMetadata: loadBatchExperimentMetadata,
    loadEntityRuns: loadBatchExperimentRuns,
  } = usePolling(app, "experiments/batch", { hasRuns: true });

  // token retrieval
  useEffect(() => {
    if (!token && (features || {}).streamlit && runViz) {
      getToken().then((token) => {
        setToken(token);
      });
    }
  }, [runViz, features, token]);

  // page display
  useEffect(() => {
    if (batchExperimentMetadata) {
      setMetaTitle(batchExperimentMetadata.name);
    }
  }, [batchExperimentMetadata, setMetaTitle]);

  // load batch experiment metadata and runs
  useEffect(() => {
    if (
      params.id &&
      !batchExperimentMetadata &&
      !batchExperimentRuns &&
      !batchExperimentMetadataError &&
      !batchExperimentRunsError
    ) {
      loadBatchExperimentMetadata(app.id, params.id);
      loadBatchExperimentRuns(app.id, params.id);
    }
  }, [
    app.id,
    batchExperimentMetadata,
    batchExperimentMetadataError,
    batchExperimentRuns,
    batchExperimentRunsError,
    loadBatchExperimentMetadata,
    loadBatchExperimentRuns,
    params.id,
  ]);

  useEffect(() => {
    if (
      !batchExperiment &&
      batchExperimentMetadata &&
      getIsExperimentResolved(batchExperimentMetadata)
    ) {
      loadBatchExperiment(app.id, params.id);
    }
  }, [
    app.id,
    batchExperiment,
    batchExperimentMetadata,
    loadBatchExperiment,
    params.id,
  ]);

  const statisticsHeaders = useMemo(() => {
    return getStatisticsHeaders(batchExperimentRuns, theme);
  }, [batchExperimentRuns, theme]);

  const runHistoryHeaders: Table2HeaderObj[] = useMemo(() => {
    if (!batchExperimentRuns) {
      return [];
    }
    return getRunHistoryTableHeaders({
      accId,
      appId: app.id,
      isLinkTargetBlank: true,
      runHistory: batchExperimentRuns,
      statsHeaders: statisticsHeaders,
      theme,
    });
  }, [accId, app.id, batchExperimentRuns, statisticsHeaders, theme]);

  // Setup to allow manually canceling batch experiments in progress
  const {
    cancelModalActionsError,
    displayConfirmModal,
    handleBatchExperimentCancel,
    setCancelModalActionsError,
    setDisplayConfirmModal,
  } = useBatchExperimentCancel(app.id, params.id);

  const handleCancelModalActions = async (actionType: StartStopModalType) => {
    await handleBatchExperimentCancel(actionType);
    await loadBatchExperiment(app.id, params.id);
  };

  const handleActionClick = (actionType: StartStopModalType) => {
    trackEvent("Experiments", {
      view: "Batch Experiment",
      action: "Action Button Clicked",
      meta: {
        type: actionType,
      },
    });

    setCancelModalActionsError(null);
    setDisplayConfirmModal(actionType);
  };

  if (batchExperimentError) {
    return <StandardError errorMessage={batchExperimentError} />;
  }
  if (!batchExperimentMetadata) {
    return <Loading type="full-screen" dotColor={theme.color.orange500} />;
  }

  const canUserCreateAndEditBatch = userHasAccessToAction(
    roles,
    ActionGroups.ExperimentOperator,
    {}
  );

  const isCompletedState = getIsCompletedState(batchExperimentMetadata.status);

  return (
    <>
      <Header
        configPageTitle={{
          label: batchExperimentMetadata.name,
          ancestorIcon: <AvatarExperimentBatch type="fill" size={24} />,
          ancestorLabel: "Batch Experiments",
          ancestorUrl: returnPathList,
        }}
        configActionButton={{
          label: "Edit",
          url: `${pathname}/edit`,
          onClick: () =>
            trackEvent("Experiments", {
              view: "Batch Experiment",
              action: "Edit Button Clicked",
            }),
          isActionAllowed: canUserCreateAndEditBatch,
        }}
        secondaryButton={[
          {
            label: "New experiment",
            url: `${returnPathList}/new`,
            testId: "create-new-batch-experiment-button",
            isActionAllowed: canUserCreateAndEditBatch,
            onClick: () =>
              trackEvent("Experiments", {
                view: "Batch Experiments",
                action: "Create Experiment Button Clicked",
              }),
          },
          {
            label: "Clone experiment",
            url: `${returnPathList}/new?cloneId=${batchExperimentMetadata.id}`,
            testId: "clone-new-batch-experiment-button",
            isActionAllowed: canUserCreateAndEditBatch,
            onClick: () =>
              trackEvent("Experiments", {
                view: "Batch Experiments",
                action: "Clone Experiment Button Clicked",
              }),
          },
        ]}
      />

      <RowDetail
        hasNoBorder
        property="Name"
        render={
          <Box maxWidth={MEASURE_ENTITY_ROW}>
            <Text styleName="body-2">{batchExperimentMetadata.name}</Text>
          </Box>
        }
      />

      <RowDetail
        property="Status"
        testId="batch-experiment-row-status"
        tooltipCopy={batchTooltips.status.content}
        render={
          <Text styleName="body-2">{batchExperimentMetadata.status}</Text>
        }
      />

      <RowDetail
        property="ID"
        testId="batch-experiment-row-id"
        tooltipCopy={batchTooltips.id.content}
        render={
          <Flex>
            <Tag
              ml={rem(-6)}
              type="id"
              label={batchExperimentMetadata.id}
              contentToCopy={batchExperimentMetadata.id}
            />
          </Flex>
        }
      />

      {batchExperimentMetadata?.description && (
        <RowDetail
          property="Description"
          testId="batch-experiment-row-description"
          render={
            <Box maxWidth={MEASURE_ENTITY_ROW}>
              <Text styleName="body-2">
                {batchExperimentMetadata.description}
              </Text>
            </Box>
          }
        />
      )}

      {batchExperiment?.input_set_id && (
        <RowDetail
          property="Input set ID"
          testId="batch-experiment-row-input-set-id"
          tooltipCopy={batchTooltips.inputSetId.content}
          render={
            <Flex>
              <Tag
                ml={rem(-6)}
                type="id"
                label={batchExperiment.input_set_id}
                contentToCopy={batchExperiment.input_set_id}
                url={getAccUrl(
                  accId,
                  `/app/${app.id}/experiments/input-set/${batchExperiment.input_set_id}`
                )}
              />
            </Flex>
          }
        />
      )}

      {batchExperiment?.instance_ids && (
        <RowDetail
          property="Instances"
          testId="batch-experiment-row-instances-id"
          render={
            <Box mt={-1}>
              {batchExperiment.instance_ids.map((instanceId: string) => (
                <Box key={instanceId}>
                  <Tag
                    testId={`batch-experiment-instance-${instanceId}`}
                    ml={rem(-6)}
                    type="id"
                    label={instanceId}
                    contentToCopy={instanceId}
                    {...(instanceId !== DEV_INT_ID && {
                      url: getAccUrl(
                        accId,
                        `/app/${app.id}/instance/${instanceId}`
                      ),
                    })}
                  />
                </Box>
              ))}
            </Box>
          }
        />
      )}

      <RowDetail
        property="Created"
        render={
          <Text
            as="time"
            styleName="body-2"
            dateTime={batchExperimentMetadata.created_at}
            title={batchExperimentMetadata.created_at}
          >
            {DateTime.fromISO(batchExperimentMetadata.created_at).toFormat(
              "yyyy-MM-dd · h:mm:ss a ZZZZ"
            )}
          </Text>
        }
      />

      {shouldDisplayExperimentActions(
        batchExperimentMetadata.status,
        roles
      ) && (
        <ExperimentDetailsSection title="Actions">
          {renderActionContent({
            handleStartStopClick: handleActionClick,
            status: batchExperimentMetadata.status,
            hiddenActions: {
              complete: true,
            },
          })}
        </ExperimentDetailsSection>
      )}

      {batchExperimentMetadata.status === "started" && (
        <ExperimentProgressBar
          runsStarted={batchExperimentMetadata.number_of_runs}
          runsProcessed={batchExperimentMetadata.number_of_completed_runs}
          runsTotal={batchExperimentMetadata.number_of_requested_runs}
        />
      )}

      {!isCompletedState && (
        <Notification
          type="tip"
          message="The experiment results will be displayed after the runs have completed and been processed."
        />
      )}

      {!(customVizAllowed && batchViz) &&
        isCompletedState &&
        batchExperiment &&
        !!batchExperiment.grouped_distributional_summaries?.length && (
          <ExperimentGroupedSummaries
            kind="batch"
            experiment={batchExperiment}
          />
        )}
      {!!(customVizAllowed && batchViz) && batchExperiment && (
        <iframe
          title="Custom Batch Viz"
          src={`https://${batchViz}.streamlit.app//?batch_id=${params.id}&app_id=${app.id}&status=${batchExperiment.status}&url=${encodedApiUrl}&embedded=true`}
          width="100%"
          height="500"
        ></iframe>
      )}

      {batchExperimentRuns &&
        !batchExperimentRunsError &&
        !!batchExperimentRuns?.length &&
        !(customVizAllowed && runViz) && (
          <ExperimentRuns
            kind="batch"
            experimentRuns={batchExperimentRuns}
            runHistoryHeaders={runHistoryHeaders}
            statisticsHeaders={statisticsHeaders}
          />
        )}
      {batchExperimentRunsError && (
        <StandardError errorMessage={batchExperimentRunsError} />
      )}

      {!!(customVizAllowed && runViz) && batchExperiment && (
        <>
          {token && (
            <iframe
              title="Custom Run Viz"
              src={`https://${runViz}.streamlit.app//?batch_id=${params.id}&app_id=${app.id}&status=${batchExperiment.status}&url=${encodedApiUrl}&account=${accId}&token=${token}&embedded=true`}
              width="100%"
              height="500"
            ></iframe>
          )}
        </>
      )}

      {displayConfirmModal && (
        <CancelBatchExperimentConfirmModal
          error={cancelModalActionsError}
          handleStartStopModalActions={handleCancelModalActions}
          modalType={displayConfirmModal}
          setDisplayConfirmModal={setDisplayConfirmModal}
          testName={batchExperimentMetadata.name}
          hasStartCriteria={false}
        />
      )}
    </>
  );
};

export default BatchExperiment;
