import * as React from "react";

import BaseIcon, { IconProps } from "./BaseIcon";

const IconLogout = ({
  iconSize,
  iconColor,
  iconColorActive,
  iconColorFocus,
  iconColorHover,
}: IconProps) => {
  return (
    <BaseIcon
      {...{
        iconSize,
        iconColor,
        iconColorActive,
        iconColorFocus,
        iconColorHover,
      }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1317 3.33H3.33V13.2H10.1317C10.4989 13.2 10.7967 13.4977 10.7967 13.865C10.7967 14.2323 10.4989 14.53 10.1317 14.53H3.165C2.52159 14.53 2 14.0084 2 13.365V3.165C2 2.52159 2.52159 2 3.165 2H10.1317C10.4989 2 10.7967 2.29773 10.7967 2.665C10.7967 3.03227 10.4989 3.33 10.1317 3.33ZM12.2352 5.69477C11.9755 5.43508 11.5545 5.43508 11.2948 5.69477C11.0351 5.95447 11.0351 6.37553 11.2948 6.63523L12.2595 7.6H6.39833C6.03106 7.6 5.73333 7.89773 5.73333 8.265C5.73333 8.63227 6.03106 8.93 6.39833 8.93H12.2595L11.2948 9.89477C11.0351 10.1545 11.0351 10.5755 11.2948 10.8352C11.5545 11.0949 11.9755 11.0949 12.2352 10.8352L14.3352 8.73523C14.5949 8.47553 14.5949 8.05447 14.3352 7.79477L12.2352 5.69477Z"
      />
    </BaseIcon>
  );
};

export default IconLogout;
