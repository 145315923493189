export const getTickLabelStyle = (theme: any) => {
  return {
    fontFamily: theme.ui2Typography.fontFamilyMeta2,
    fontSize: theme.ui2Typography.fontSizeMeta2,
    fontWeight: theme.ui2Typography.fontWeightMeta2,
    fill: theme.color.gray600,
    textRendering: "optimizeLegibility" as CanvasTextRendering,
    WebkitFontSmoothing: "antialiased",
  };
};

export const getTickLabelStyleAlt = (theme: any) => {
  return {
    fontFamily: theme.ui2Typography.fontFamilyMeta2Bold,
    fontSize: theme.ui2Typography.fontSizeMeta2Bold,
    fontWeight: theme.ui2Typography.fontWeightMeta2Bold,
    letterSpacing: theme.ui2Typography.letterSpacingMeta2Bold,
    textTransform: "uppercase",
    fill: theme.color.gray600,
    textRendering: "optimizeLegibility" as CanvasTextRendering,
    WebkitFontSmoothing: "antialiased",
  };
};
