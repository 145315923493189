import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import MarketplaceApps from "./subpages/Apps/Apps.page";
import AvailableIntegrations from "./subpages/AvailableIntegrations";

const MarketplaceRoutes = () => {
  const { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <MarketplaceApps />
      </Route>

      <Route exact path={`${path}/integrations`}>
        <AvailableIntegrations />
      </Route>
      <Route exact path={`${path}/my-integrations`}>
        <Redirect to={`${url}/integrations`} />
      </Route>
      <Route exact path={`${path}/available-integrations`}>
        <Redirect to={`${url}/integrations`} />
      </Route>

      <Redirect to={path} />
    </Switch>
  );
};

export default MarketplaceRoutes;
