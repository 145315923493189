import React, { useEffect } from "react";
import { Redirect, useLocation, useParams } from "react-router-dom";
import { Box } from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";

import { IntegrationKind } from "../../../../api/core/controlPlane.types";
import { useUser } from "../../../../AuthProvider";
import Header from "../../../../components/Header";
import { useMetaTitle } from "../../../../components/Layout";
import { useIntegrations } from "../../../../contexts/integrations/Integrations.context";
import { getIntegrationAvatar } from "../../helpers/avatars";
import { integrationIsFenced } from "../../helpers/fencing";
import { availableIntegrationConfig } from "../../Integrations.config";

import NewIntegrationForm from "./NewIntegration.Form";

const NewIntegration = () => {
  const [user] = useUser();
  const [, setMetaTitle] = useMetaTitle();
  const { pathname } = useLocation();
  const { integrationType } = useParams<{ integrationType: IntegrationKind }>();
  const integrationBasePath = pathname.split("/").slice(0, -1).join("/");
  const userIsBlocked = integrationIsFenced(user, integrationType);
  const { clearIntegrationsError } = useIntegrations();

  useEffect(() => {
    return clearIntegrationsError;
  }, [clearIntegrationsError]);

  const integrationAvailable = React.useMemo(
    () => availableIntegrationConfig[integrationType] || undefined,
    [integrationType]
  );

  useEffect(() => {
    setMetaTitle(`Add new ${integrationAvailable.name}`);
  }, [setMetaTitle, integrationAvailable]);

  if (!integrationAvailable || userIsBlocked) {
    return <Redirect to={integrationBasePath} />;
  }

  return (
    <>
      <Header
        configPageTitle={{
          label: `Add new ${integrationAvailable.name}`,
          isEarlyAccess: integrationAvailable?.isEarlyAccess,
          icon: getIntegrationAvatar(integrationAvailable.type, 32),
        }}
      />
      <Box mt={rem(-1)}>
        <NewIntegrationForm
          integrationName={integrationAvailable.name}
          basePath={integrationBasePath}
          privateInputs={integrationAvailable?.privateInputs}
          publicInputs={integrationAvailable?.publicInputs}
          integrationType={integrationType!}
        />
      </Box>
    </>
  );
};

export default NewIntegration;
