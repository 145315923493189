import React from "react";
import { Box, Loading, Select } from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";

import {
  AppType,
  AppVersionsData,
  EntityErrorMessage,
  MarketplaceAppVersions,
  MarketplacePartnerApp,
} from "../../../api/core/controlPlane.types";
import StandardError from "../../../components/StandardError";
import { VersionOption } from "../App.types";
import { getMarketplacePartnerAppLatestVersions } from "../utils/marketplace";

type VersionSelectProps = {
  marketplaceVersions: MarketplaceAppVersions;
  marketplaceVersionsError: EntityErrorMessage;
  pendingVersionId: string | undefined;
  setPendingVersionId: React.Dispatch<React.SetStateAction<string>>;
  versions: AppVersionsData;
  versionsError: EntityErrorMessage;
  appType?: AppType;
  marketplacePartnerApp?: MarketplacePartnerApp;
};

const VersionSelect = ({
  appType,
  marketplacePartnerApp,
  marketplaceVersions,
  marketplaceVersionsError,
  pendingVersionId,
  setPendingVersionId,
  versions,
  versionsError,
}: VersionSelectProps) => {
  const thisVersionsError =
    appType === "subscription" ? marketplaceVersionsError : versionsError;
  const thisVersionsLoading =
    appType === "subscription" ? !marketplaceVersions : !versions;

  const versionOptions: VersionOption[] = React.useMemo(() => {
    const marketplaceLatestVersions =
      (marketplacePartnerApp &&
        getMarketplacePartnerAppLatestVersions(marketplacePartnerApp)) ||
      [];

    const thisVersions =
      appType === "subscription"
        ? [
            ...(marketplaceLatestVersions ? marketplaceLatestVersions : []),
            ...(marketplaceVersions ? marketplaceVersions : []),
          ]
        : versions;

    const versionOptions = () => {
      if (!thisVersions) return [];
      return thisVersions.reduce((options: VersionOption[], version) => {
        options.push({
          id: version.id,
          name: version?.name || "",
          lastUpdated: version?.updated_at || version?.created_at,
        });
        return options;
      }, []);
    };
    return versionOptions();
  }, [appType, marketplacePartnerApp, marketplaceVersions, versions]);

  const getVersionOptionFromId = (versionId: string): VersionOption => {
    const selectedVersionOption = versionOptions.find((versionOption) => {
      return versionOption.id === versionId;
    });
    if (selectedVersionOption) return selectedVersionOption;
    return versionOptions[0];
  };

  const updateVersionSelection = (selectedVersion: VersionOption) => {
    setPendingVersionId(selectedVersion.id);
    return;
  };

  if (thisVersionsError) {
    return <StandardError errorMessage={thisVersionsError} />;
  }
  if (thisVersionsLoading) {
    return <Loading />;
  }

  return (
    <Box maxWidth={rem(408)}>
      <Select
        testId="version-app-select"
        type="meta"
        placeholder={
          !versionOptions.length ? "No versions available" : "Select version"
        }
        options={versionOptions}
        getOptionValue={(versionSelected: VersionOption) => versionSelected.id}
        value={pendingVersionId && getVersionOptionFromId(pendingVersionId)}
        onChange={(selection: VersionOption) => {
          updateVersionSelection(selection);
        }}
      />
    </Box>
  );
};
export default VersionSelect;
