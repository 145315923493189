import * as React from "react";
import styled from "@emotion/styled";
import Slider from "rc-slider";
import { space } from "styled-system";

import { filterProps } from "../../lib/filterProps";
import { rem } from "../../lib/tools";

type BasicStyledSystem = number | string | any[];

// I copied the CSS from this file and took out what we
// didn't need (the extra tooltip styles, vertical slider,
// dots, marks, etc.). That said, I added our styles as
// overrides so if we ever want to just go back to the
// import we can and it should still work the same.
// import 'rc-slider/assets/index.css'

const sliderCSS = `
  .rc-slider {
    position: relative;
    height: 14px;
    padding: 5px 0;
    width: 100%;
    border-radius: 6px;
    touch-action: none;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .rc-slider * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .rc-slider-rail {
    position: absolute;
    width: 100%;
    background-color: #e9e9e9;
    height: 4px;
    border-radius: 6px;
  }
  .rc-slider-track {
    position: absolute;
    left: 0;
    height: 4px;
    border-radius: 6px;
    background-color: #abe2fb;
  }
  .rc-slider-handle {
    position: absolute;
    width: 14px;
    height: 14px;
    cursor: pointer;
    cursor: -webkit-grab;
    margin-top: -5px;
    cursor: grab;
    border-radius: 50%;
    border: solid 2px #96dbfa;
    background-color: #fff;
    touch-action: pan-x;
  }
  .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    border-color: #57c5f7;
    box-shadow: 0 0 0 5px #96dbfa;
  }
  .rc-slider-handle:focus {
    outline: none;
  }
  .rc-slider-handle-click-focused:focus {
    border-color: #96dbfa;
    box-shadow: unset;
  }
  .rc-slider-handle:hover {
    border-color: #57c5f7;
  }
  .rc-slider-handle:active {
    border-color: #57c5f7;
    box-shadow: 0 0 5px #57c5f7;
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }
  .rc-slider-disabled {
    background-color: #e9e9e9;
  }
  .rc-slider-disabled .rc-slider-track {
    background-color: #ccc;
  }
  .rc-slider-disabled .rc-slider-handle,
  .rc-slider-disabled .rc-slider-dot {
    border-color: #ccc;
    box-shadow: none;
    background-color: #fff;
    cursor: not-allowed;
  }
  .rc-slider-disabled .rc-slider-mark-text,
  .rc-slider-disabled .rc-slider-dot {
    cursor: not-allowed !important;
  }
`;

const SliderStyle = styled("div", {
  shouldForwardProp: (prop: any) => filterProps(prop),
})`
  ${space}

  ${sliderCSS}

  .rc-slider {
    width: 100%;
    height: ${({ theme }) => theme.spacing.s1};
    padding: 0;
    border-radius: 0;
  }
  .rc-slider-rail {
    height: ${({ theme }) => theme.spacing.s1};
    border-radius: ${rem(2)};
    background-color: ${({ theme }) => theme.color.gray300};
  }
  .rc-slider-track {
    height: ${({ theme }) => theme.spacing.s1};
    border-radius: ${rem(2)};
    background-color: ${({ theme }) => theme.color.orange400};
  }
  .rc-slider-handle {
    width: ${({ theme }) => theme.spacing.s4};
    height: ${({ theme }) => theme.spacing.s4};
    margin-top: ${rem(-6)};
    border-width: ${rem(3)};
    border-style: ${({ theme }) => theme.border.style};
    border-color: ${({ theme }) => theme.color.orange400};
    background-color: ${({ theme }) => theme.color.white};
  }
  .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    border-color: ${({ theme }) => theme.color.orange500};
    box-shadow: none;
  }
  .rc-slider-handle-click-focused:focus,
  .rc-slider-handle-click-focused:focus-visible {
    border-color: ${({ theme }) => theme.color.orange500};
    outline: none;
    box-shadow: none;
  }
  .rc-slider-handle:hover {
    border-color: ${({ theme }) => theme.color.orange500};
  }
  .rc-slider-handle:active {
    border-color: ${({ theme }) => theme.color.orange500};
    box-shadow: none;
  }

  /* disabled styles */
  .rc-slider-disabled {
    background-color: transparent;
  }
  .rc-slider-disabled .rc-slider-rail {
    background-color: ${({ theme }) => theme.color.gray200};
  }
  .rc-slider-disabled .rc-slider-track {
    background-color: ${({ theme }) => theme.color.gray300};
  }
  .rc-slider-disabled .rc-slider-handle {
    border-color: ${({ theme }) => theme.color.gray300};
    box-shadow: none;
    background-color: ${({ theme }) => theme.color.white};
  }
`;

const SliderComponent = ({
  defaultValue,
  isDisabled,
  value,
  min,
  max,
  onChange,
  step,
  m,
  mt,
  mr,
  mb,
  ml,
  p,
  pt,
  pr,
  pb,
  pl,
  ...rest
}: {
  defaultValue: number;
  value: number;
  isDisabled?: boolean;
  min: number;
  max: number;
  onChange: (value: number) => any;
  step: number | null;
  m?: BasicStyledSystem;
  mt?: BasicStyledSystem;
  mr?: BasicStyledSystem;
  mb?: BasicStyledSystem;
  ml?: BasicStyledSystem;
  p?: BasicStyledSystem;
  pt?: BasicStyledSystem;
  pr?: BasicStyledSystem;
  pb?: BasicStyledSystem;
  pl?: BasicStyledSystem;
}) => {
  return (
    <SliderStyle {...{ m, mt, mr, mb, ml, p, pt, pr, pb, pl }}>
      <Slider
        {...{
          defaultValue,
          value,
          disabled: isDisabled,
          min,
          max,
          onChange,
          step,
          ...rest,
        }}
      />
    </SliderStyle>
  );
};

export default SliderComponent;
