import React from "react";

import { UseMapStateReturnValue } from "../../hooks/useMapState";
import { SolutionRoute } from "../../Map.types";

import MapMenu from "./components/MapMenu";
import RouteToggle from "./components/RouteToggle";
import { MapControlsFlex } from "./MapControls.styled";

type MapControlsProps = {
  mapState: UseMapStateReturnValue;
  isViewResult: boolean;
  routeSet: SolutionRoute;
  unassignedCount: number;
};

export default function MapControls({
  mapState,
  isViewResult,
  routeSet,
  unassignedCount,
}: MapControlsProps) {
  const { mapDisplayMode, setMapDisplayMode } = mapState;

  const isRouteToggleDisabled = !isViewResult || !routeSet.hasRoads;
  const isMapControlDisabled = !isViewResult;

  const [isMapMenuActive, setIsMapMenuActive] = React.useState(false);

  return (
    <MapControlsFlex>
      <RouteToggle
        isMapMenuActive={isMapMenuActive}
        isRouteToggleDisabled={isRouteToggleDisabled}
        mapDisplayMode={mapDisplayMode}
        setMapDisplayMode={setMapDisplayMode}
      />
      <MapMenu
        mapState={mapState}
        isMapControlDisabled={isMapControlDisabled}
        isMapMenuActive={isMapMenuActive}
        setIsMapMenuActive={setIsMapMenuActive}
        unassignedCount={unassignedCount}
      />
    </MapControlsFlex>
  );
}
