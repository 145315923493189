import {
  AcceptanceTestMetric,
  AppInstance,
  BatchExperiment,
  CreateAcceptanceTestPayload,
} from "../../../api/core/controlPlane.types";
import {
  INPUT_SET_CREATE_TYPE_INPUTS,
  INPUT_SET_CREATE_TYPE_INSTANCE_DATE,
  INPUT_SET_CREATE_TYPE_RUN_IDS,
  INPUT_SET_CREATE_TYPE_UPLOAD_FILE,
} from "../../../config/experiments";
import {
  getDatePastHour,
  getDatePastMonth,
  getDatePastYear,
} from "../../../contexts/experiments/utils/utils";
import {
  EMPTY_ACC_METRIC_OPERATOR,
  EXP_ACC_METRIC_TYPE,
  EXP_ACC_STATISTIC,
} from "../data/constants";
import {
  InputSetCreateTypeOption,
  InputSetDateRangeOption,
} from "../Experiments.types";
import {
  getSenseLabel,
  supportedAcceptanceTestOperators,
} from "../utils/acceptanceTestMetrics";

export const emptyExperiment: BatchExperiment = {
  id: "",
  name: "",
  description: "",
  created_at: "",
  input_set_id: "",
  status: "started",
  instance_ids: [],
  summaries: [],
};

export const defaultRunDateOption: InputSetDateRangeOption = {
  label: "Past 3 months",
  value: getDatePastMonth(3),
};
export const runDateOptions: InputSetDateRangeOption[] = [
  {
    label: "Past 24 hours",
    value: getDatePastHour(24),
  },
  {
    label: "Past month",
    value: getDatePastMonth(1),
  },
  defaultRunDateOption,
  {
    label: "Past year",
    value: getDatePastYear(1),
  },
];

export const createOptions: InputSetCreateTypeOption[] = [
  {
    label: "Inputs",
    value: INPUT_SET_CREATE_TYPE_INPUTS,
  },
  {
    label: "Upload files",
    value: INPUT_SET_CREATE_TYPE_UPLOAD_FILE,
  },
  {
    label: "Instance + date range",
    value: INPUT_SET_CREATE_TYPE_INSTANCE_DATE,
  },
  {
    label: "Run IDs",
    value: INPUT_SET_CREATE_TYPE_RUN_IDS,
  },
];

export const emptyAcceptanceMetric: AcceptanceTestMetric = {
  field: "",
  // TODO these options will change as more compare metrics become available
  statistic: EXP_ACC_STATISTIC,
  metric_type: EXP_ACC_METRIC_TYPE,
  params: {
    operator: EMPTY_ACC_METRIC_OPERATOR,
  },
};

export const emptyAcceptanceTestPayload: CreateAcceptanceTestPayload = {
  id: "",
  name: "",
  description: "",
  experiment_id: "",
  control: {
    instance_id: "",
  },
  candidate: {
    instance_id: "",
  },
  metrics: [emptyAcceptanceMetric],
};

export const metricOperatorOptions = supportedAcceptanceTestOperators.map(
  (operator) => ({
    label: getSenseLabel(operator),
    value: operator,
  })
);

export const getInstanceName = (
  instance: AppInstance,
  defaultId: string | undefined
) => {
  return instance.id === defaultId
    ? `${instance.name} (default)`
    : instance.name;
};

export const getAcceptanceInstanceDisplayName = (
  type: "control" | "candidate"
) => {
  if (type === "control") return "Baseline";
  if (type === "candidate") return "Candidate";
  return "";
};

export const getRange = (series: number[], padding: number = 0.1) => {
  let min = series.reduce(
    (min, curr, index) => {
      if (index === 0) return min;
      if (curr < min) return curr;
      return min;
    },
    series.length > 0 ? series[0] : 0
  );

  let max = series.reduce(
    (max, curr, index) => {
      if (index === 0) return max;
      if (curr > max) return curr;
      return max;
    },
    series.length > 0 ? series[0] : 0
  );

  const pad = (max - min) * padding;
  min -= pad;
  max += pad;

  if (min === max) {
    min -= 1;
    max += 1;
  }

  return { min, max };
};
