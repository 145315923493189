import { DesignSystemTheme } from "../../../style/theme.types";
import { DesignSystemTextStyles } from "../Text.types";

export const getTextStyle = (
  styleName: DesignSystemTextStyles,
  theme: DesignSystemTheme
) => {
  switch (styleName) {
    case "label":
      return `
        font-family: ${theme.ui2Typography.fontFamilyLabel};
        font-size: ${theme.ui2Typography.fontSizeLabel};
        font-style: ${theme.ui2Typography.fontStyleLabel};
        font-weight: ${theme.ui2Typography.fontWeightLabel};
        line-height: ${theme.ui2Typography.lineHeightLabel};
        letter-spacing: ${theme.ui2Typography.letterSpacingLabel};
        text-transform: ${theme.ui2Typography.textTransformLabel};
      `;
    case "meta-1":
      return `
        font-family: ${theme.ui2Typography.fontFamilyMeta1};
        font-size: ${theme.ui2Typography.fontSizeMeta1};
        font-style: ${theme.ui2Typography.fontStyleMeta1};
        font-weight: ${theme.ui2Typography.fontWeightMeta1};
        line-height: ${theme.ui2Typography.lineHeightMeta1};
        letter-spacing: ${theme.ui2Typography.letterSpacingMeta1};
        text-transform: ${theme.ui2Typography.textTransformMeta1};
      `;
    case "meta-1-bold":
      return `
        font-family: ${theme.ui2Typography.fontFamilyMeta1Bold};
        font-size: ${theme.ui2Typography.fontSizeMeta1Bold};
        font-style: ${theme.ui2Typography.fontStyleMeta1Bold};
        font-weight: ${theme.ui2Typography.fontWeightMeta1Bold};
        line-height: ${theme.ui2Typography.lineHeightMeta1Bold};
        letter-spacing: ${theme.ui2Typography.letterSpacingMeta1Bold};
        text-transform: ${theme.ui2Typography.textTransformMeta1Bold};
      `;
    case "meta-2":
      return `
        font-family: ${theme.ui2Typography.fontFamilyMeta2};
        font-size: ${theme.ui2Typography.fontSizeMeta2};
        font-style: ${theme.ui2Typography.fontStyleMeta2};
        font-weight: ${theme.ui2Typography.fontWeightMeta2};
        line-height: ${theme.ui2Typography.lineHeightMeta2};
        letter-spacing: ${theme.ui2Typography.letterSpacingMeta2};
        text-transform: ${theme.ui2Typography.textTransformMeta2};
      `;
    case "meta-2-bold":
      return `
        font-family: ${theme.ui2Typography.fontFamilyMeta2Bold};
        font-size: ${theme.ui2Typography.fontSizeMeta2Bold};
        font-style: ${theme.ui2Typography.fontStyleMeta2Bold};
        font-weight: ${theme.ui2Typography.fontWeightMeta2Bold};
        line-height: ${theme.ui2Typography.lineHeightMeta2Bold};
        letter-spacing: ${theme.ui2Typography.letterSpacingMeta2Bold};
        text-transform: ${theme.ui2Typography.textTransformMeta2Bold};
      `;
    case "code":
      return `
        font-family: ${theme.ui2Typography.fontFamilyCode};
        font-size: ${theme.ui2Typography.fontSizeCode};
        font-style: ${theme.ui2Typography.fontStyleCode};
        font-weight: ${theme.ui2Typography.fontWeightCode};
        line-height: ${theme.ui2Typography.lineHeightCode};
        letter-spacing: ${theme.ui2Typography.letterSpacingCode};
        text-transform: ${theme.ui2Typography.textTransformCode};
      `;
    case "code-bold":
      return `
        font-family: ${theme.ui2Typography.fontFamilyCodeBold};
        font-size: ${theme.ui2Typography.fontSizeCodeBold};
        font-style: ${theme.ui2Typography.fontStyleCodeBold};
        font-weight: ${theme.ui2Typography.fontWeightCodeBold};
        line-height: ${theme.ui2Typography.lineHeightCodeBold};
        letter-spacing: ${theme.ui2Typography.letterSpacingCodeBold};
        text-transform: ${theme.ui2Typography.textTransformCodeBold};
      `;
    case "body-1":
      return `
        font-family: ${theme.ui2Typography.fontFamilyBody1};
        font-size: ${theme.ui2Typography.fontSizeBody1};
        font-style: ${theme.ui2Typography.fontStyleBody1};
        font-weight: ${theme.ui2Typography.fontWeightBody1};
        line-height: ${theme.ui2Typography.lineHeightBody1};
        letter-spacing: ${theme.ui2Typography.letterSpacingBody1};
        text-transform: ${theme.ui2Typography.textTransformBody1};
      `;
    case "body-1-italic":
      return `
        font-family: ${theme.ui2Typography.fontFamilyBody1Italic};
        font-size: ${theme.ui2Typography.fontSizeBody1Italic};
        font-style: ${theme.ui2Typography.fontStyleBody1Italic};
        font-weight: ${theme.ui2Typography.fontWeightBody1Italic};
        line-height: ${theme.ui2Typography.lineHeightBody1Italic};
        letter-spacing: ${theme.ui2Typography.letterSpacingBody1Italic};
        text-transform: ${theme.ui2Typography.textTransformBody1Italic};
      `;
    case "body-1-bold":
      return `
        font-family: ${theme.ui2Typography.fontFamilyBody1Bold};
        font-size: ${theme.ui2Typography.fontSizeBody1Bold};
        font-style: ${theme.ui2Typography.fontStyleBody1Bold};
        font-weight: ${theme.ui2Typography.fontWeightBody1Bold};
        line-height: ${theme.ui2Typography.lineHeightBody1Bold};
        letter-spacing: ${theme.ui2Typography.letterSpacingBody1Bold};
        text-transform: ${theme.ui2Typography.textTransformBody1Bold};
      `;
    case "body-1-plus":
      return `
        font-family: ${theme.ui2Typography.fontFamilyBody1Plus};
        font-size: ${theme.ui2Typography.fontSizeBody1Plus};
        font-style: ${theme.ui2Typography.fontStyleBody1Plus};
        font-weight: ${theme.ui2Typography.fontWeightBody1Plus};
        line-height: ${theme.ui2Typography.lineHeightBody1Plus};
        letter-spacing: ${theme.ui2Typography.letterSpacingBody1Plus};
        text-transform: ${theme.ui2Typography.textTransformBody1Plus};
      `;
    case "body-1-plus-bold":
      return `
        font-family: ${theme.ui2Typography.fontFamilyBody1PlusBold};
        font-size: ${theme.ui2Typography.fontSizeBody1PlusBold};
        font-style: ${theme.ui2Typography.fontStyleBody1PlusBold};
        font-weight: ${theme.ui2Typography.fontWeightBody1PlusBold};
        line-height: ${theme.ui2Typography.lineHeightBody1PlusBold};
        letter-spacing: ${theme.ui2Typography.letterSpacingBody1PlusBold};
        text-transform: ${theme.ui2Typography.textTransformBody1PlusBold};
      `;
    case "body-2":
      return `
        font-family: ${theme.ui2Typography.fontFamilyBody2};
        font-size: ${theme.ui2Typography.fontSizeBody2};
        font-style: ${theme.ui2Typography.fontStyleBody2};
        font-weight: ${theme.ui2Typography.fontWeightBody2};
        line-height: ${theme.ui2Typography.lineHeightBody2};
        letter-spacing: ${theme.ui2Typography.letterSpacingBody2};
        text-transform: ${theme.ui2Typography.textTransformBody2};
      `;
    case "body-2-italic":
      return `
        font-family: ${theme.ui2Typography.fontFamilyBody2Italic};
        font-size: ${theme.ui2Typography.fontSizeBody2Italic};
        font-style: ${theme.ui2Typography.fontStyleBody2Italic};
        font-weight: ${theme.ui2Typography.fontWeightBody2Italic};
        line-height: ${theme.ui2Typography.lineHeightBody2Italic};
        letter-spacing: ${theme.ui2Typography.letterSpacingBody2Italic};
        text-transform: ${theme.ui2Typography.textTransformBody2Italic};
      `;
    case "body-2-bold":
      return `
        font-family: ${theme.ui2Typography.fontFamilyBody2Bold};
        font-size: ${theme.ui2Typography.fontSizeBody2Bold};
        font-style: ${theme.ui2Typography.fontStyleBody2Bold};
        font-weight: ${theme.ui2Typography.fontWeightBody2Bold};
        line-height: ${theme.ui2Typography.lineHeightBody2Bold};
        letter-spacing: ${theme.ui2Typography.letterSpacingBody2Bold};
        text-transform: ${theme.ui2Typography.textTransformBody2Bold};
      `;
    case "body-3":
      return `
        font-family: ${theme.ui2Typography.fontFamilyBody3};
        font-size: ${theme.ui2Typography.fontSizeBody3};
        font-style: ${theme.ui2Typography.fontStyleBody3};
        font-weight: ${theme.ui2Typography.fontWeightBody3};
        line-height: ${theme.ui2Typography.lineHeightBody3};
        letter-spacing: ${theme.ui2Typography.letterSpacingBody3};
        text-transform: ${theme.ui2Typography.textTransformBody3};
      `;
    case "body-3-italic":
      return `
        font-family: ${theme.ui2Typography.fontFamilyBody3Italic};
        font-size: ${theme.ui2Typography.fontSizeBody3Italic};
        font-style: ${theme.ui2Typography.fontStyleBody3Italic};
        font-weight: ${theme.ui2Typography.fontWeightBody3Italic};
        line-height: ${theme.ui2Typography.lineHeightBody3Italic};
        letter-spacing: ${theme.ui2Typography.letterSpacingBody3Italic};
        text-transform: ${theme.ui2Typography.textTransformBody3Italic};
      `;
    case "body-3-bold":
      return `
        font-family: ${theme.ui2Typography.fontFamilyBody3Bold};
        font-size: ${theme.ui2Typography.fontSizeBody3Bold};
        font-style: ${theme.ui2Typography.fontStyleBody3Bold};
        font-weight: ${theme.ui2Typography.fontWeightBody3Bold};
        line-height: ${theme.ui2Typography.lineHeightBody3Bold};
        letter-spacing: ${theme.ui2Typography.letterSpacingBody3Bold};
        text-transform: ${theme.ui2Typography.textTransformBody3Bold};
      `;
    case "header-1":
      return `
        font-family: ${theme.ui2Typography.fontFamilyHeader1};
        font-size: ${theme.ui2Typography.fontSizeHeader1};
        font-style: ${theme.ui2Typography.fontStyleHeader1};
        font-weight: ${theme.ui2Typography.fontWeightHeader1};
        line-height: ${theme.ui2Typography.lineHeightHeader1};
        letter-spacing: ${theme.ui2Typography.letterSpacingHeader1};
        text-transform: ${theme.ui2Typography.textTransformHeader1};
      `;
    case "header-2":
      return `
        font-family: ${theme.ui2Typography.fontFamilyHeader2};
        font-size: ${theme.ui2Typography.fontSizeHeader2};
        font-style: ${theme.ui2Typography.fontStyleHeader2};
        font-weight: ${theme.ui2Typography.fontWeightHeader2};
        line-height: ${theme.ui2Typography.lineHeightHeader2};
        letter-spacing: ${theme.ui2Typography.letterSpacingHeader2};
        text-transform: ${theme.ui2Typography.textTransformHeader2};
      `;
    case "marketing-header-3":
      return `
        font-family: ${theme.ui2Typography.fontFamilyMarketingHeader3};
        font-size: ${theme.ui2Typography.fontSizeMarketingHeader3};
        font-style: ${theme.ui2Typography.fontStyleMarketingHeader3};
        font-weight: ${theme.ui2Typography.fontWeightMarketingHeader3};
        line-height: ${theme.ui2Typography.lineHeightMarketingHeader3};
        letter-spacing: ${theme.ui2Typography.letterSpacingMarketingHeader3};
        text-transform: ${theme.ui2Typography.textTransformMarketingHeader3};
      `;
    case "marketing-header-4":
      return `
        font-family: ${theme.ui2Typography.fontFamilyMarketingHeader4};
        font-size: ${theme.ui2Typography.fontSizeMarketingHeader4};
        font-style: ${theme.ui2Typography.fontStyleMarketingHeader4};
        font-weight: ${theme.ui2Typography.fontWeightMarketingHeader4};
        line-height: ${theme.ui2Typography.lineHeightMarketingHeader4};
        letter-spacing: ${theme.ui2Typography.letterSpacingMarketingHeader4};
        text-transform: ${theme.ui2Typography.textTransformMarketingHeader4};
      `;
    // body-2 set as default, though styleName is required prop
    default:
      return `
        font-family: ${theme.ui2Typography.fontFamilyBody2};
        font-size: ${theme.ui2Typography.fontSizeBody2};
        font-style: ${theme.ui2Typography.fontStyleBody2};
        font-weight: ${theme.ui2Typography.fontWeightBody2};
        line-height: ${theme.ui2Typography.lineHeightBody2};
        letter-spacing: ${theme.ui2Typography.letterSpacingBody2};
        text-transform: ${theme.ui2Typography.textTransformBody2};
      `;
  }
};
