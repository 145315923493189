import React from "react";

import Layout from "../../components/Layout";

import { configPages } from "./data/pages";
import ConfigRoutes from "./Config.routes";

const Config = () => {
  return (
    <Layout sectionTitle="Configuration" sectionPages={configPages}>
      <ConfigRoutes />
    </Layout>
  );
};

export default Config;
