import { useMemo, useState } from "react";
import { Box, Button2, Notification } from "@console/dsc";
import { InputSize } from "@console/dsc/src/components/Input/Input.types";

import { TrackEvents } from "../../../../../analytics/functions";
import {
  AppResponse,
  EntityErrorMessage,
} from "../../../../../api/core/controlPlane.types";
import {
  ConfigOption,
  HandleConfigOptionChangeParams,
} from "../../../../../components/AddConfigOptions/AddConfigOptions.types";
import { InstanceSelectInstanceIds } from "../../../../../components/InstanceSelect/InstanceSelect.types";
import PlanNotice from "../../../../../components/PlanNotice";
import { AcceptedFiles } from "../../../../../components/UploadFile/UploadFile.types";
import { StandardInputType } from "../../../../../hooks/useStandardInputs";
import { ScrollYFlex } from "../../RunDetails/RunDetails.styled";
import { UseCustomInputReturnValue } from "../hooks/useCustomInput";

import InstanceSelection from "./InstanceSelection";
import RunInput from "./RunInput";
import RunNameDescription from "./RunNameDescription";
import RunOptions from "./RunOptions";

export type InputControlsProps = {
  addEmptyConfigOption: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  acceptedFiles: AcceptedFiles;
  app: AppResponse;
  getStandardInputsProps: <T extends keyof TrackEvents>({
    isDisabled,
    placeholder,
    testId,
    trackEventCategory,
    trackEventProperties,
    size,
    type,
  }: {
    placeholder: string;
    testId: string;
    type: StandardInputType;
    isDisabled?: boolean;
    size?: InputSize;
    trackEventCategory?: T;
    trackEventProperties?: TrackEvents[T];
  }) => any;
  handleConfigOptionChange: ({
    e,
    index,
    pendingConfigOptions,
    type,
  }: HandleConfigOptionChangeParams) => void;
  handleRunPrecheck: (e: any) => void;
  isCloneInputLoading: boolean;
  isPrecheckRunning: boolean;
  isRunning: boolean;
  pendingConfigOptions: ConfigOption[];
  pendingInstanceIds: InstanceSelectInstanceIds;
  removeConfigOption: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: number
  ) => void;
  runAddError: EntityErrorMessage;
  runDetailsActionError: string;
  fileProcessingError: string;
  runInputState: UseCustomInputReturnValue;
  setAcceptedFiles: React.Dispatch<React.SetStateAction<AcceptedFiles>>;
  setIsInputModified: React.Dispatch<React.SetStateAction<boolean>>;
  setPendingInstanceIds: React.Dispatch<
    React.SetStateAction<InstanceSelectInstanceIds>
  >;
  cloneId?: string | null;
};

const InputControls = ({
  acceptedFiles,
  addEmptyConfigOption,
  app,
  cloneId,
  getStandardInputsProps,
  handleConfigOptionChange,
  handleRunPrecheck,
  isCloneInputLoading,
  isPrecheckRunning,
  isRunning,
  pendingConfigOptions,
  pendingInstanceIds,
  removeConfigOption,
  fileProcessingError,
  runAddError,
  runDetailsActionError,
  runInputState,
  setAcceptedFiles,
  setIsInputModified,
  setPendingInstanceIds,
}: InputControlsProps) => {
  const { inputState } = runInputState;

  const [shouldEditorRefresh, setShouldEditorRefresh] = useState(false);

  const filteredPendingInstanceIds = useMemo(() => {
    return pendingInstanceIds.filter(Boolean);
  }, [pendingInstanceIds]);

  const isActionButtonDisabled = useMemo(() => {
    return (
      isRunning ||
      !filteredPendingInstanceIds.length ||
      (!inputState.input && !acceptedFiles)
    );
  }, [
    acceptedFiles,
    filteredPendingInstanceIds.length,
    inputState.input,
    isRunning,
  ]);

  // helper function for editor
  const refreshEditorHeight = () => {
    return setShouldEditorRefresh(true);
  };

  const isClone = !!cloneId;

  const createRunError = runAddError || runDetailsActionError;

  return (
    <>
      <ScrollYFlex flexDirection="column">
        <PlanNotice mr={6} ml={6} mb={5} {...{ app }} type="no-custom-apps" />

        {createRunError && (
          <Box mt={4} mr={3} ml={3}>
            <Notification
              data-testid="new-run-error-message"
              width="100%"
              type="error"
              hasContactExtra
              message={createRunError}
            />
          </Box>
        )}

        {fileProcessingError && (
          <Box mt={4} mr={3} ml={3}>
            <Notification
              width="100%"
              type="error"
              message={fileProcessingError}
            />
          </Box>
        )}

        <RunInput
          {...{
            app,
            acceptedFiles,
            shouldEditorRefresh,
            isClone,
            isRunning,
            refreshEditorHeight,
            runInputState,
            setAcceptedFiles,
            setShouldEditorRefresh,
            setIsInputModified,
          }}
        />

        <InstanceSelection
          {...{
            app,
            filteredPendingInstanceIds,
            pendingInstanceIds,
            setPendingInstanceIds,
            refreshEditorHeight,
          }}
        />

        <RunOptions
          {...{
            addEmptyConfigOption,
            handleConfigOptionChange,
            isClone,
            isRunning,
            pendingConfigOptions,
            removeConfigOption,
            refreshEditorHeight,
          }}
        />

        <RunNameDescription
          {...{
            getStandardInputsProps,
            isClone,
            isRunning,
            refreshEditorHeight,
          }}
        />
      </ScrollYFlex>

      <Box noShrink pt={4} pr={4} pb={5} pl={4} hasBorderTop>
        <Button2
          data-testid="run-custom-app-button"
          size="large"
          isLoading={isRunning || isPrecheckRunning || isCloneInputLoading}
          isDisabled={isActionButtonDisabled}
          onClick={handleRunPrecheck}
          label="Start run"
        />
      </Box>
    </>
  );
};

export default InputControls;
