import { useEffect } from "react";

import { useFetchAndUpdateUser } from "../api/core/dataPlane.hooks";

export function useRefreshUserDataOnMount() {
  const fetchAndUpdateUser = useFetchAndUpdateUser();

  useEffect(() => {
    fetchAndUpdateUser();
  }, [fetchAndUpdateUser]);
}
