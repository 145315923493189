import { Link } from "react-router-dom";
import { Flex, Text } from "@console/dsc";

type RenderDashboardSectionTitleParams = {
  title: string;
  theme: any;
  description?: string;
  isExternalUrl?: boolean;
  url?: string;
};

export const renderDashboardSectionTitle = ({
  title,
  description,
  isExternalUrl,
  theme,
  url,
}: RenderDashboardSectionTitleParams) => {
  const renderUrlOrDescription = () => {
    if (!url) return description;
    if (isExternalUrl) {
      return (
        <a href={url} target="_blank" rel="noreferrer">
          {description}
        </a>
      );
    }
    return <Link to={url}>{description}</Link>;
  };
  return (
    <Flex alignItems="baseline" pb={2} hasBorderBottom hasBorderWidthThick>
      <Text as="h3" styleName="label" styles={{ letterSpacing: "0.08em" }}>
        {title}
      </Text>

      {(url || description) && (
        <Text
          ml={2}
          as="h4"
          styleName="meta-2"
          styles={{ fontWeight: 600, color: theme.color.gray600 }}
          {...(url && {
            hasLinkGray: true,
          })}
        >
          {renderUrlOrDescription()}
        </Text>
      )}
    </Flex>
  );
};
