import React, { useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  AvatarInputSet,
  Box,
  Flex,
  Loading,
  RowDetail,
  Tag,
  Text,
} from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";
import { DateTime } from "luxon";

import { trackEvent } from "../../../../analytics";
import { useUser } from "../../../../AuthProvider";
import Header from "../../../../components/Header";
import { useMetaTitle } from "../../../../components/Layout";
import StandardError from "../../../../components/StandardError";
import { MEASURE_ENTITY_ROW } from "../../../../config/general";
import useManageEntity from "../../../../hooks/useManageEntity";
import { getAccUrl } from "../../../../utils/navigation";
import { userHasAccessToAction } from "../../../../utils/rbac_utils";
import { ActionGroups } from "../../../../utils/rbac_utils/types";
import { AppPageProps } from "../../../App/App.types";
import useReturnPaths from "../../../App/hooks/useReturnPaths";
import { renderAppInputsList } from "../../../App/subpages/AppInputs/utils/renderAppInputsList";
import { inputSetTooltips } from "../../data/microcopy";

const InputSet = ({ app }: AppPageProps) => {
  const [{ id: accountId, roles }] = useUser();
  const [, setMetaTitle] = useMetaTitle();
  const params = useParams() as { id: string };
  const { pathname } = useLocation();
  const theme = useTheme();
  const { returnPathList } = useReturnPaths();

  const {
    entity: inputSet,
    entityLoadError: inputSetError,
    loadEntity: loadInputSet,
  } = useManageEntity("experiments/inputsets");

  // page display
  useEffect(() => {
    if (inputSet) {
      setMetaTitle(inputSet.name);
    }
  }, [inputSet, setMetaTitle]);

  // load input set
  useEffect(() => {
    if (!inputSet && !inputSetError) {
      loadInputSet(app.id, params.id);
    }
  }, [app.id, inputSet, inputSetError, loadInputSet, params.id]);

  if (inputSetError) {
    return <StandardError errorMessage={inputSetError} />;
  }
  if (!inputSet) {
    return <Loading type="full-screen" dotColor={theme.color.orange500} />;
  }

  const canUserCreateAndEditInputSets = userHasAccessToAction(
    roles,
    ActionGroups.InputSetOperator,
    {}
  );
  const canUserCreateAndEditAppInputs = canUserCreateAndEditInputSets;

  const renderInputs = () => {
    const hasInputs = !!inputSet.inputs.length;
    const hasInputIds = !!inputSet.input_ids.length;

    if (hasInputs) {
      return (
        <RowDetail
          property="Inputs"
          tooltipCopy={inputSetTooltips.inputs.content}
          render={
            <Box mt={-2}>
              {renderAppInputsList({
                accountId,
                app,
                appInputs: inputSet.inputs,
                canUserCreateAndEditAppInputs,
                isMini: true,
              })}
            </Box>
          }
        />
      );
    }
    if (hasInputIds) {
      return (
        <RowDetail
          property="Input Ids"
          tooltipCopy={inputSetTooltips.inputIds.content}
          render={inputSet.input_ids.map((inputId) => {
            return (
              <Box maxWidth={rem(480)} mb={1} key={inputId}>
                <Text styleName="body-2" hasLinkBlack>
                  <Link
                    to={getAccUrl(accountId, `/app/${app.id}/run/${inputId}`)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {inputId}
                  </Link>
                </Text>
              </Box>
            );
          })}
        />
      );
    }
  };

  return (
    <>
      <Header
        configPageTitle={{
          label: inputSet.name,
          ancestorIcon: <AvatarInputSet type="fill" size={24} />,
          ancestorLabel: "Input Sets",
          ancestorUrl: returnPathList,
        }}
        configActionButton={{
          label: "Edit",
          url: `${pathname}/edit`,
          onClick: () =>
            trackEvent("InputSets", {
              view: "Input Set",
              action: "Edit Button Clicked",
            }),
          isActionAllowed: canUserCreateAndEditInputSets,
        }}
        secondaryButton={{
          label: "New input set",
          url: `${returnPathList}/new`,
          isActionAllowed: canUserCreateAndEditInputSets,
        }}
      />

      <RowDetail
        hasNoBorder
        property="Name"
        render={
          <Box maxWidth={MEASURE_ENTITY_ROW}>
            <Text styleName="body-2">{inputSet.name}</Text>
          </Box>
        }
      />

      <RowDetail
        property="ID"
        tooltipCopy={inputSetTooltips.id.content}
        render={
          <Flex>
            <Tag
              mb={-1}
              ml={rem(-6)}
              type="id"
              label={inputSet.id}
              contentToCopy={inputSet.id}
            />
          </Flex>
        }
      />

      {inputSet.description && (
        <RowDetail
          property="Description"
          render={
            <Box maxWidth={MEASURE_ENTITY_ROW}>
              <Text styleName="body-2">{inputSet?.description}</Text>
            </Box>
          }
        />
      )}

      <RowDetail
        property="Created"
        tooltipCopy={inputSetTooltips.created.content}
        render={
          <Text
            as="time"
            styleName="body-2"
            dateTime={inputSet.created_at}
            title={inputSet.created_at}
          >
            {DateTime.fromISO(inputSet.created_at).toFormat(
              "yyyy-MM-dd · h:mm a"
            )}
          </Text>
        }
      />

      {inputSet.updated_at && (
        <RowDetail
          property="Last Updated"
          tooltipCopy={inputSetTooltips.lastUpdated.content}
          render={
            <Text
              as="time"
              styleName="body-2"
              dateTime={inputSet.updated_at}
              title={inputSet.updated_at}
            >
              {DateTime.fromISO(inputSet.updated_at).toFormat(
                "yyyy-MM-dd · h:mm a"
              )}
            </Text>
          }
        />
      )}

      {renderInputs()}
    </>
  );
};

export default InputSet;
