import { DesignSystemTextStyles } from "../../Text/Text.types";
import {
  GetTagLabelParams,
  GetTagStyleNameParams,
  GetTagStyleParams,
} from "../Tag.types";

export const getTagLabel = ({ type }: GetTagLabelParams) => {
  switch (type) {
    case "early-access":
      return "early access";
    case "premium":
      return "premium";
    default:
      return "";
  }
};

export const getTagStyleName = ({
  size,
  type,
}: GetTagStyleNameParams): DesignSystemTextStyles => {
  switch (type) {
    case "id":
      return size === "small" ? "body-3-bold" : "body-2-bold";
    default:
      return size === "small" ? "meta-2-bold" : "meta-1-bold";
  }
};

export const getTagBackgroundColor = ({
  theme,
  type,
}: GetTagStyleParams): string => {
  switch (type) {
    case "early-access":
      return theme.color.yellow200;
    case "premium":
      return theme.color.green100;
    default:
      return theme.color.gray100;
  }
};

export const getTagTextColor = ({ theme, type }: GetTagStyleParams): string => {
  switch (type) {
    case "early-access":
      return theme.color.yellow800;
    case "id":
      return theme.color.gray800;
    case "premium":
      return theme.color.green700;
    default:
      return theme.color.gray700;
  }
};

export const getIconColor = ({ type, theme }: GetTagStyleParams): string => {
  switch (type) {
    case "early-access":
      return theme.color.yellow600;
    case "premium":
      return theme.color.green600;
    default:
      return theme.color.gray600;
  }
};
