import {
  ConfigDisplayRows,
  ConfigDisplayViews,
  PageViewType,
} from "../pages/App/App.types";

export const checkConfigDisplayRow = (
  value: string,
  displayConfigRows: ConfigDisplayRows | null
) => {
  return (
    !displayConfigRows ||
    (displayConfigRows &&
      displayConfigRows.find((rowConfig) => rowConfig.id === value))
  );
};

export const checkConfigDisplayView = (
  value: string,
  displayConfigViews: ConfigDisplayViews | null
) => {
  return (
    !displayConfigViews ||
    (displayConfigViews && displayConfigViews.includes(value as PageViewType))
  );
};

export const checkConfigDisplayRowProperty = (
  value: string,
  property: string,
  displayConfigRows: ConfigDisplayRows | null
) => {
  return (
    !displayConfigRows ||
    (
      displayConfigRows &&
      displayConfigRows.find((rowConfig) => rowConfig.id === value)
    )?.views.includes(property)
  );
};
