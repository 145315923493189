import { RunStatusV2 } from "../../../../../api/core/controlPlane.types";
import {
  RUN_STATUS_V2_CANCELED,
  RUN_STATUS_V2_FAILED,
  RUN_STATUS_V2_QUEUED,
  RUN_STATUS_V2_RUNNING,
  RUN_STATUS_V2_SUCCEEDED,
} from "../../../../../config/apps";

type GetStatusTextColorParams = {
  status: RunStatusV2;
  theme: any;
};

export const getStatusTextColor = ({
  status,
  theme,
}: GetStatusTextColorParams) => {
  switch (status) {
    case RUN_STATUS_V2_RUNNING:
      return theme.color.yellow700;
    case RUN_STATUS_V2_SUCCEEDED:
      return theme.color.green700;
    case RUN_STATUS_V2_QUEUED:
      return theme.color.purple600;
    case RUN_STATUS_V2_CANCELED:
      return theme.color.gray600;
    case RUN_STATUS_V2_FAILED:
      return theme.color.red700;
    default:
      return theme.color.gray700;
  }
};
