import { Button2 } from "@console/dsc";

import { PlanType, UserWithOrgs } from "../../../api/core/dataPlane.types";
import {
  PLAN_TYPE_FREE,
  PLAN_TYPE_LEGACY_ENTERPRISE,
  PLAN_TYPE_LEGACY_PREMIUM,
  PLAN_TYPE_PREMIUM,
} from "../../../config/plans";
import { PlanDetails } from "../Team.types";

export const getCurrentPlanType = (user: UserWithOrgs): PlanType => {
  const userPlanType = user?.planInfo?.planType;
  if (!userPlanType) return PLAN_TYPE_FREE;

  // converge legacy premium-level plan types to PLAN_TYPE_PREMIUM
  if (
    userPlanType === PLAN_TYPE_PREMIUM ||
    userPlanType === PLAN_TYPE_LEGACY_ENTERPRISE ||
    userPlanType === PLAN_TYPE_LEGACY_PREMIUM
  ) {
    return PLAN_TYPE_PREMIUM;
  }

  return userPlanType;
};

export const getPlanCta = (plan: PlanDetails) => {
  if (!plan.button) return null;

  return (
    <Button2
      size={plan.button.size}
      testId={plan.button.testId}
      type={plan.button.type}
      onClick={(e: any) => plan.button!.onClick(e, plan)}
      label={plan.button.label}
      {...(plan.buttonUrl &&
        (plan.buttonUrlIsExternal
          ? {
              href: plan.buttonUrl,
            }
          : { to: plan.buttonUrl }))}
      isUrlExternal={plan.buttonUrlIsExternal}
    />
  );
};
