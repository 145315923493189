import { Link } from "react-router-dom";
import { Flex } from "@console/dsc";
import styled from "@emotion/styled";

import {
  BANNER_HEIGHT,
  BANNER_HEIGHT_MOBILE,
  ORG_HEADER_HEIGHT,
  SITE_TOP_BORDER_RADIUS,
} from "../../utils/constants";

import {
  StyledCornerHelperProps,
  StyledOrgHeaderProps,
} from "./OrgHeader.types";

export const StyledOrgHeader = styled(Flex)<StyledOrgHeaderProps>`
  width: 100%;
  height: ${ORG_HEADER_HEIGHT};
  position: fixed;
  left: 0;
  top: ${(props) => (props.hasBanner ? BANNER_HEIGHT_MOBILE : 0)};
  background-color: ${(props) => props.backgroundColor};
  z-index: 10001;

  @media (min-width: ${({ theme }) => theme.breakpoint.sm}) {
    top: ${(props) => (props.hasBanner ? BANNER_HEIGHT : 0)};
  }
`;

export const StyledCornerHelper = styled.span<StyledCornerHelperProps>`
  display: block;
  width: ${SITE_TOP_BORDER_RADIUS};
  height: ${SITE_TOP_BORDER_RADIUS};
  margin-bottom: calc(${SITE_TOP_BORDER_RADIUS} * -1) !important;
  position: absolute;
  left: ${(props) => (props.position === "left" ? 0 : "auto")};
  right: ${(props) => (props.position === "right" ? 0 : "auto")};
  bottom: 0;

  /*
    render "rounded corners" around SiteContainer so that when
    there's overflow scroll the rounded corners stay in place
  */
  &::before,
  &::after {
    content: "";
    display: block;
    width: ${SITE_TOP_BORDER_RADIUS};
    height: ${SITE_TOP_BORDER_RADIUS};
    position: absolute;
    bottom: 0;
  }
  &::before {
    left: 0;
    background-color: ${(props) => props.backgroundColor};
    z-index: 9997;
  }
  &::after {
    left: 0;
    background-color: ${({ theme }) => theme.color.white};
    z-index: 9998;
  }

  ${(props) =>
    props.position === "left" &&
    `
      &::after {
        border-top-left-radius: ${SITE_TOP_BORDER_RADIUS};
      }
    `}
  ${(props) =>
    props.position === "right" &&
    `
      &::after {
        border-top-right-radius: ${SITE_TOP_BORDER_RADIUS};
      }
    `}
`;

export const StyledFlex = styled(Flex)`
  cursor: pointer;
  &:hover,
  &:focus,
  &:active {
    svg {
      path {
        fill-opacity: 1;
      }
    }
  }
`;

export const PlainLink = styled(Link)`
  text-decoration: none;
  color: inherit;

  &:hover,
  &:focus,
  &:active {
    color: inherit;
  }
`;

export const StyledProfileLink = styled.a`
  display: flex;
  cursor: pointer;
  opacity: 0.8;
  border-radius: ${({ theme }) => theme.border.radiusLarge};

  &:hover,
  &:focus,
  &:focus-visible,
  &:active {
    opacity: 1;
  }

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 ${({ theme }) => theme.border.widthMediumShadowOnly}
      ${({ theme }) => theme.color.white};
  }
`;
