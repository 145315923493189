/* 
  Plan types from the backend
  
	// Free
	PlanTypeFree = PlanType("free")

	// Legacy
	PlanTypeBasic      = PlanType("basic")
	PlanTypePremium    = PlanType("premium")
	PlanTypeEnterprise = PlanType("enterprise")

  // Legacy, internal use
  // (maybe) this is tracked as "Free" in the front-end
  PlanTypeInternal   = PlanType("internal")

	// New
	PlanTypeInnovator = PlanType("innovator")
	PlanTypeScaleUp   = PlanType("scale_up")
	PlanTypePremiumV2 = PlanType("premium_v2")
*/

export const PLAN_TYPE_FREE_INTERNAL = "Free" as const;
export const PLAN_TYPE_FREE = "free" as const;
export const PLAN_TYPE_LEGACY_BASIC = "basic" as const;
export const PLAN_TYPE_LEGACY_PREMIUM = "premium" as const;
export const PLAN_TYPE_LEGACY_ENTERPRISE = "enterprise" as const;
export const PLAN_TYPE_INNOVATOR = "innovator" as const;
export const PLAN_TYPE_SCALE_UP = "scale_up" as const;
export const PLAN_TYPE_PREMIUM = "premium_v2" as const;
