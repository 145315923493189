import * as React from "react";

import BaseAvatar, { AvatarSize } from "./BaseAvatar";

const AvatarGoogleMaps = ({ size }: { size?: AvatarSize }) => {
  return (
    <BaseAvatar {...{ size }}>
      <circle cx="24" cy="24" r="24" fill="#EEEFEF" />
      <path
        d="M27.2494 10.2881C26.2814 9.98232 25.2518 9.81512 24.1826 9.81512C21.063 9.81512 18.2712 11.2253 16.41 13.4407L21.206 17.4755L27.2494 10.2881Z"
        fill="#1A73E8"
      />
      <path
        d="M16.4079 13.4402C14.9251 15.2046 14.0297 17.4816 14.0297 19.9655C14.0297 21.8751 14.4103 23.4217 15.0351 24.8077L21.2039 17.475L16.4079 13.4402Z"
        fill="#EA4335"
      />
      <path
        d="M24.1825 16.0847C26.3275 16.0847 28.0655 17.8227 28.0655 19.9677C28.0655 20.9225 27.7201 21.7981 27.1481 22.4735C27.1481 22.4735 30.2149 18.8259 33.1915 15.2883C31.9617 12.9233 29.8299 11.1039 27.2515 10.2877L21.2037 17.4751C21.9165 16.6237 22.9857 16.0847 24.1825 16.0847Z"
        fill="#4285F4"
      />
      <path
        d="M24.1826 23.848C22.0376 23.848 20.2996 22.11 20.2996 19.965C20.2996 19.0168 20.6406 18.1478 21.2038 17.4724L15.035 24.8072C16.0888 27.1458 17.8422 29.0224 19.6484 31.3874L27.146 22.473C26.4354 23.3134 25.3706 23.848 24.1826 23.848Z"
        fill="#FBBC04"
      />
      <path
        d="M26.9986 33.8206C30.3844 28.5252 34.3334 26.1206 34.3334 19.965C34.3334 18.2776 33.9198 16.687 33.1916 15.2878L19.6506 31.3874C20.2248 32.1398 20.8034 32.9406 21.3688 33.8228C23.428 37.004 22.856 38.9114 24.1848 38.9114C25.5092 38.9114 24.9394 37.0018 26.9986 33.8206Z"
        fill="#34A853"
      />
    </BaseAvatar>
  );
};

export default AvatarGoogleMaps;
