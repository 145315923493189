import { ShadowEndCriteriaDurationWeeksOption } from "../NewShadowTest.types";

export const shadowEndCriteriaDurationWeeksOptions: ShadowEndCriteriaDurationWeeksOption[] =
  [
    { label: "1 week from start date", value: 1 },
    { label: "2 weeks from start date", value: 2 },
    { label: "3 weeks from start date", value: 3 },
    { label: "4 weeks from start date", value: 4 },
    { label: "5 weeks from start date", value: 5 },
    { label: "6 weeks from start date", value: 6 },
    { label: "7 weeks from start date", value: 7 },
    { label: "8 weeks from start date", value: 8 },
    { label: "9 weeks from start date", value: 9 },
    { label: "10 weeks from start date", value: 10 },
    { label: "11 weeks from start date", value: 11 },
    { label: "12 weeks from start date", value: 12 },
  ];
