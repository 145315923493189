import { useEffect, useState } from "react";
import { DateTime } from "luxon";

import { ShadowCriteriaPayload } from "../../../../../api/core/controlPlane.types";
import { InstanceSelectInstanceIds } from "../../../../../components/InstanceSelect/InstanceSelect.types";
import { SHADOW_TEST_DEFAULT_MAX_RUNS } from "../../../../../utils/constants";
import { ShadowEndCriteriaDurationWeeksOption } from "../NewShadowTest.types";

const emptyShadowCriteria: ShadowCriteriaPayload = {
  termination_events: {
    maximum_runs: SHADOW_TEST_DEFAULT_MAX_RUNS,
  },
};

const useNewShadowTest = () => {
  const [pendingShadowCriteria, setPendingShadowCriteria] =
    useState<ShadowCriteriaPayload>(emptyShadowCriteria);
  const [pendingBaselineInstanceIds, setPendingBaselineInstanceIds] =
    useState<InstanceSelectInstanceIds>([""]);
  const [pendingCandidateInstanceIds, setPendingCandidateInstanceIds] =
    useState<InstanceSelectInstanceIds>([""]);

  // start criteria
  const [shadowStartCriteriaTime, setShadowStartCriteriaTime] = useState("");
  const [shadowStartCriteriaTimeIsAuto, setShadowStartCriteriaTimeIsAuto] =
    useState(true);

  // end criteria
  const [shadowEndCriteriaDurationWeeks, setShadowEndCriteriaDurationWeeks] =
    useState<ShadowEndCriteriaDurationWeeksOption | null>(null);
  const [shadowEndCriteriaMaxRuns, setShadowEndCriteriaMaxRuns] =
    useState<number>(SHADOW_TEST_DEFAULT_MAX_RUNS);
  const [shadowEndCriteriaMaxRunsError, setShadowEndCriteriaMaxRunsError] =
    useState<string>("");

  // manage pending criteria
  useEffect(() => {
    let terminationEventTime = "";
    const terminationEventDurationWeeks = shadowEndCriteriaDurationWeeks?.value;
    const terminationEventMaxRuns = shadowEndCriteriaMaxRuns;
    const startEventTime = shadowStartCriteriaTime.trim();

    if (terminationEventDurationWeeks && startEventTime) {
      let terminationEventTimeFormat = "yyyy-MM-dd'T'HH:mm:ssZZ";
      if (/Z$/g.test(startEventTime)) {
        terminationEventTimeFormat = "yyyy-MM-dd'T'HH:mm:ss'Z'";
      }

      terminationEventTime = DateTime.fromFormat(
        startEventTime,
        terminationEventTimeFormat
      )
        .plus({ weeks: terminationEventDurationWeeks })
        .toFormat("yyyy-MM-dd'T'HH:mm:ssZZ");
    }

    setPendingShadowCriteria((prevState) => ({
      ...prevState,
      ...(startEventTime
        ? {
            start_events: {
              time: startEventTime,
            },
          }
        : {
            start_events: {
              time: "",
            },
          }),
      ...(terminationEventTime
        ? {
            termination_events: {
              time: terminationEventTime,
              maximum_runs: terminationEventMaxRuns,
            },
          }
        : {
            termination_events: {
              time: "",
              maximum_runs: terminationEventMaxRuns,
            },
          }),
    }));
  }, [
    shadowEndCriteriaDurationWeeks?.value,
    shadowEndCriteriaMaxRuns,
    shadowStartCriteriaTime,
  ]);

  // manage start and end criteria ux
  useEffect(() => {
    // auto-add start criteria time if end criteria time chosen
    if (!shadowStartCriteriaTime && shadowEndCriteriaDurationWeeks) {
      setShadowStartCriteriaTimeIsAuto(true);
      setShadowStartCriteriaTime(
        DateTime.now().toFormat("yyyy-MM-dd'T'HH:mm:ssZZ")
      );
    }

    // clear auto-added start criteria time if end criteria cleared
    // and start criteria has not been modified
    if (
      shadowStartCriteriaTimeIsAuto &&
      shadowStartCriteriaTime &&
      !shadowEndCriteriaDurationWeeks
    ) {
      setShadowStartCriteriaTime("");
    }
  }, [
    shadowEndCriteriaDurationWeeks,
    shadowStartCriteriaTime,
    shadowStartCriteriaTimeIsAuto,
  ]);

  return {
    pendingBaselineInstanceIds,
    pendingCandidateInstanceIds,
    pendingShadowCriteria,
    setPendingBaselineInstanceIds,
    setPendingCandidateInstanceIds,
    setShadowEndCriteriaDurationWeeks,
    setShadowEndCriteriaMaxRuns,
    setShadowEndCriteriaMaxRunsError,
    setShadowStartCriteriaTime,
    setShadowStartCriteriaTimeIsAuto,
    shadowEndCriteriaDurationWeeks,
    shadowEndCriteriaMaxRuns,
    shadowEndCriteriaMaxRunsError,
    shadowStartCriteriaTime,
  };
};

export default useNewShadowTest;
