import { TableHeader } from "../Table2.types";

export const reformatHeaderAccessorKeysNoPeriods = (
  tableHeaders: TableHeader
) => {
  // table library does not view "." as regular keys
  const reformattedHeaders = tableHeaders.map((tableColumn) => {
    return {
      ...tableColumn,
      accessorKey: tableColumn.accessorKey?.split(".").join("-"),
    };
  });
  return reformattedHeaders;
};
