import * as React from "react";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { space } from "styled-system";

interface AvailableIconProps {
  children: React.ReactNode;
  className?: string;
  iconColor?: string;
  iconColorActive?: string;
  iconColorFocus?: string;
  iconColorHover?: string;
  iconOpacity?: number;
  iconSize?: number;
  viewBox?: [number, number, number, number];
}

type BaseIconProps = AvailableIconProps;
export type IconProps = Omit<AvailableIconProps, "children">;

type StyledIconProps = {
  iconColorActive?: string;
  iconColorFocus?: string;
  iconColorHover?: string;
};

const StyledIcon = styled.svg<StyledIconProps>`
  ${space}

  flex-shrink: 0;
  ${(props) =>
    props.iconColorHover &&
    `
  &:hover {
    fill: ${props.iconColorHover};
  }`}
  ${(props) =>
    props.iconColorFocus &&
    `
  &:focus-visible {
    fill: ${props.iconColorFocus};
  }`}
${(props) =>
    props.iconColorActive &&
    `
  &:active {
    fill: ${props.iconColorActive};
  }`}
`;

const BaseIcon = ({
  children,
  iconColor,
  iconColorActive,
  iconColorFocus,
  iconColorHover,
  iconSize,
  viewBox,
  ...rest
}: BaseIconProps) => {
  const theme = useTheme();
  const thisIconSize = iconSize || 16;
  const thisIconColor = iconColor || theme.color.gray700;

  return (
    <StyledIcon
      {...{ iconColorActive, iconColorFocus, iconColorHover }}
      width={thisIconSize}
      height={thisIconSize}
      viewBox={viewBox ? viewBox.join(" ") : "0 0 16 16"}
      fill={thisIconColor}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      {children}
    </StyledIcon>
  );
};

export default BaseIcon;
