import { kebabCase } from "lodash";

import { ENTITY_ID_CHAR_COUNT_MAX } from "../../../utils/constants";

// make sure name string is no longer than ID char count max
// minus 6 characters so "-clone" can be safely appended
export const getSafeCloneName = (name?: string): string => {
  if (!name) return "";

  const safeNameMax = ENTITY_ID_CHAR_COUNT_MAX - 6;
  let safeName = "";

  name.split(" ").forEach((word) => {
    const wordCharCount = word.length;
    let safeWord = "";

    if (wordCharCount > safeNameMax) {
      safeWord = word.substring(0, safeNameMax - 1);
    } else {
      safeWord = word;
    }

    const totalNameCharCount = safeName.length + safeWord.length;
    if (totalNameCharCount >= safeNameMax) {
      return;
    } else {
      safeName = `${safeName}${safeName.length > 0 ? " " : ""}${safeWord}`;
    }
  });

  // final kebab-case test
  // e.g. name is CamelCase and unaccounted for - get added to ID
  const safeNameIdLength = kebabCase(safeName).length;
  if (safeNameIdLength > safeNameMax) {
    return safeName.substring(
      0,
      safeNameMax - (safeNameIdLength - safeNameMax)
    );
  }

  return safeName;
};
