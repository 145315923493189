import React, { useEffect, useState } from "react";
import { Box, Button2, Flex, Input, Notification } from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";

import HeaderPublic from "../../../../components/HeaderPublic";
import { useMetaTitle } from "../../../../components/Layout";
import { AUTH_FLOW_CONTENT_MAX_WIDTH } from "../../../../config/public";
import useAuthFlow from "../../../../hooks/useAuthFlow";
import { handleInputChange } from "../../../../utils/inputHelpers";
import AuthFlowLink from "../../components/AuthFlowLink";
import { LoginPageProps } from "../../LogIn.types";

const pageTitle = "Forgot Password";

const PasswordForgot = ({ username }: LoginPageProps) => {
  const [, setMetaTitle] = useMetaTitle();
  const theme = useTheme();

  const [isEmailSet, setIsEmailSet] = useState(false);

  const {
    email,
    hasSentPasswordResetEmail,
    isProcessing,
    sendPasswordResetEmail,
    setEmail,
  } = useAuthFlow();

  // pre-fill email field if data exists
  useEffect(() => {
    if (username && !isEmailSet) {
      setEmail(username);
      setIsEmailSet(true);
    }
  }, [isEmailSet, setEmail, username]);

  // page display
  useEffect(() => {
    setMetaTitle(pageTitle);
  }, [setMetaTitle]);

  const handlePasswordForgotFormSubmit = async (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    e.preventDefault();
    e.stopPropagation();

    await sendPasswordResetEmail(email);
  };

  return (
    <>
      <HeaderPublic
        configPageTitle={{
          label: "Forgot password",
          tooltipContent:
            "If an account exists for the email, a link will be sent to reset your password. Note that if you use Google or SSO for authentication you must reset your password through those services.",
        }}
        subTitle="Enter your email below to reset your password."
      />

      <Box mt={3} maxWidth={AUTH_FLOW_CONTENT_MAX_WIDTH}>
        {hasSentPasswordResetEmail && (
          <Notification
            mb={1}
            type="success"
            message="An email has been sent. Please check your email to finish resetting your password."
          />
        )}

        <form onSubmit={handlePasswordForgotFormSubmit}>
          <Input
            mt={1}
            isRequired
            testId="login-password-forgot-email-input"
            htmlType="email"
            size="large"
            placeholder="Email address"
            value={email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e, setEmail)
            }
          />

          <Flex mt={2}>
            <Button2
              testId="login-password-forgot-submit-button"
              isLoading={isProcessing}
              size="large"
              label="Reset password"
              styles={{
                minWidth: rem(240),
                backgroundColor: theme.color.sky600,
                "&:hover, &:focus, &:active": {
                  backgroundColor: theme.color.sky700,
                },
                "&:focus-visible": {
                  backgroundColor: theme.color.sky700,
                  outlineWidth: theme.border.widthMediumShadowOnly,
                  outlineStyle: "solid",
                  outlineColor: theme.color.sky800,
                },
              }}
            />
          </Flex>
        </form>

        <AuthFlowLink
          to="/login/password"
          label="Back to login"
          queryParams={{ username: email }}
          trackEventCategory="AuthFlow"
          trackEventProperties={{
            view: "Login Forgot Password",
            action: "Back to Login Link Clicked",
          }}
        />
      </Box>
    </>
  );
};

export default PasswordForgot;
