import { GetInputColorParams } from "../Input.types";

export const getInputTextColorValue = ({
  state,
  theme,
}: GetInputColorParams) => {
  if (state?.isDisabled) return theme.color.gray600;
  if (state?.isSelected) return theme.color.gray800;
  return state?.isError ? theme.color.red600 : theme.color.gray700;
};

export const getPlaceholderTextColorValue = ({
  state,
  theme,
}: GetInputColorParams) => {
  return state?.isError ? theme.color.red400 : theme.color.gray500;
};

export const getInputBorderColorValue = ({
  state,
  theme,
}: GetInputColorParams) => {
  if (state?.isDisabled) return "transparent";
  if (state?.isFocused || state?.isSelected)
    return state?.isError ? theme.color.red400 : theme.color.gray500;
  return state?.isError ? theme.color.red300 : theme.color.gray300;
};

export const getInputBackgroundColorValue = ({
  state,
  theme,
}: GetInputColorParams) => {
  if (state?.isDisabled) return theme.color.gray100;
  if (state?.isSelected) return theme.color.orange100;
  return theme.color.white;
};
