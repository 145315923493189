import * as React from "react";
import { components } from "react-select";

import Flex from "../../Flex";

// get SingleValue base component from library
const { SingleValue } = components;

const CustomSingleValueMeta = (props: any) => {
  // get data to build option, this is the object from
  // the data array for this particular option
  const label = props?.data?.label;

  return (
    <SingleValue {...props}>
      <Flex alignItems="center">{label}</Flex>
    </SingleValue>
  );
};

export default CustomSingleValueMeta;
