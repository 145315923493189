import {
  PLAN_TYPE_INNOVATOR,
  PLAN_TYPE_PREMIUM,
  PLAN_TYPE_SCALE_UP,
} from "../../../../../config/plans";
import {
  GetButtonContentsParams,
  GetPlansParams,
  PlanCtaButton,
  PlanDetails,
} from "../../../Team.types";

// price IDs change per environment
const innovatorPlanId = process.env.REACT_APP_INNOVATOR_PLAN_ID;
const scaleUpPlanId = process.env.REACT_APP_SCALE_UP_PLAN_ID;

const getButtonContents = ({
  handleContactNextmvOnClick,
  handleCustomerPortalLaunch,
  handleSelectPlanOnClick,
  isUserRoot,
  isUserSubscriptionInError,
  planType,
  subscriptionPlanType,
  userPlanType,
}: GetButtonContentsParams): PlanCtaButton | null => {
  const isCurrentPlan = planType === userPlanType;

  // no plan CTA if not team root user
  if (!isUserRoot) return null;

  // no CTA if on premium
  if (userPlanType === PLAN_TYPE_PREMIUM) return null;

  // special CTA if user subscription error
  if (isUserSubscriptionInError) {
    if (subscriptionPlanType === planType) {
      return {
        label: "Manage",
        onClick: handleCustomerPortalLaunch,
        size: "default",
        testId: "plan-manage-button",
        type: "outline-quiet",
      };
    }
    return null;
  }

  // customer billing portal link if on innovator/scale-up
  if (
    isCurrentPlan &&
    (planType === PLAN_TYPE_INNOVATOR || planType === PLAN_TYPE_SCALE_UP)
  ) {
    return {
      label: "Manage",
      onClick: handleCustomerPortalLaunch,
      size: "default",
      testId: "plan-manage-button",
      type: "outline-quiet",
    };
  }

  if (
    (userPlanType === PLAN_TYPE_SCALE_UP && planType === PLAN_TYPE_INNOVATOR) ||
    (userPlanType === PLAN_TYPE_INNOVATOR && planType === PLAN_TYPE_SCALE_UP)
  ) {
    const planButtonLabel =
      planType === PLAN_TYPE_INNOVATOR ? "Downgrade" : "Upgrade";

    return {
      label: planButtonLabel,
      onClick: handleCustomerPortalLaunch,
      size: "default",
      testId: `${planType}-${planButtonLabel.toLowerCase()}-plan-button`,
      type: "primary",
    };
  }

  if (planType === PLAN_TYPE_PREMIUM) {
    return {
      label: "Contact Nextmv",
      onClick: handleContactNextmvOnClick,
      size: "large",
      testId: `${planType}-plan-primary-button`,
      type: "primary",
    };
  }

  return {
    label: "Select plan",
    onClick: handleSelectPlanOnClick,
    size: "large",
    testId: `${planType}-plan-primary-button`,
    type: "primary",
  };
};

export const getPlans = ({
  handleContactNextmvOnClick,
  handleCustomerPortalLaunch,
  handleSelectPlanOnClick,
  isUserRoot,
  isUserSubscriptionInError,
  subscriptionPlanType,
  userPlanType,
}: GetPlansParams): PlanDetails[] => [
  {
    id: PLAN_TYPE_INNOVATOR,
    planType: PLAN_TYPE_INNOVATOR,
    name: "Innovator",
    price: "$20",
    priceCadence: "/month¹",
    summary: "For early learning and starter projects with DecisionOps.",
    button: getButtonContents({
      handleContactNextmvOnClick,
      handleCustomerPortalLaunch,
      handleSelectPlanOnClick,
      isUserSubscriptionInError,
      isUserRoot,
      planType: PLAN_TYPE_INNOVATOR,
      subscriptionPlanType,
      userPlanType,
    }),
    planId: innovatorPlanId,
    features: [
      "Innovator run time & credits",
      "Custom decision modeling",
      "Unique API endpoints",
      "Experimentation tools",
      "Model management, collaboration",
      "Local development, modeling SDK",
      '<a href="https://www.nextmv.io/docs/reference/api/rate-limits" target="_blank" rel="noreferrer"><strong>Innovator rate limits</strong></a>',
      "<strong>1 team, 2 users</strong>",
      "<strong>Community support</strong>",
    ],
    footNotes: [
      '¹ Plus overage fees if applicable (<a href="https://www.nextmv.io/pricing" target="_blank" rel="noreferrer">details</a>).',
    ],
  },
  {
    id: PLAN_TYPE_SCALE_UP,
    planType: PLAN_TYPE_SCALE_UP,
    name: "Scale-up",
    price: "$250",
    priceCadence: "/month¹",
    summary: "For greater team collaboration, scaling operations, and support.",
    button: getButtonContents({
      handleContactNextmvOnClick,
      handleCustomerPortalLaunch,
      handleSelectPlanOnClick,
      isUserSubscriptionInError,
      isUserRoot,
      planType: PLAN_TYPE_SCALE_UP,
      subscriptionPlanType,
      userPlanType,
    }),
    planId: scaleUpPlanId,
    features: [
      "Everything in Innovator, plus:",
      "<strong>Scale-up run time & credits</strong>",
      '<a href="https://www.nextmv.io/docs/reference/api/rate-limits" target="_blank" rel="noreferrer"><strong>Scale-up rate limits</strong></a>',
      "<strong>1 team, 5 users</strong>",
      "<strong>Business hours support</strong>",
    ],
    footNotes: [
      '¹ Plus overage fees if applicable (<a href="https://www.nextmv.io/pricing" target="_blank" rel="noreferrer">details</a>).',
    ],
  },
  {
    id: PLAN_TYPE_PREMIUM,
    planType: PLAN_TYPE_PREMIUM,
    name: "Premium",
    price: "Custom",
    priceCadence: "",
    summary: "For enterprise-grade operations that need premium features.",
    button: getButtonContents({
      handleContactNextmvOnClick,
      handleCustomerPortalLaunch,
      handleSelectPlanOnClick,
      isUserSubscriptionInError,
      isUserRoot,
      planType: PLAN_TYPE_PREMIUM,
      subscriptionPlanType,
      userPlanType,
    }),
    buttonUrl: "https://www.nextmv.io/contact",
    buttonUrlIsExternal: true,
    features: [
      "Everything in Scale-up, plus:",
      "<strong>Premium run time & credits</strong>",
      '<a href="https://www.nextmv.io/docs/reference/api/rate-limits" target="_blank" rel="noreferrer"><strong>Premium rate limits</strong></a>',
      "<strong>Premium compute</strong>",
      "<strong>Commercial solvers</strong>",
      "<strong>Unlimited teams and users</strong>",
      "<strong>User roles and management</strong>",
      "<strong>On-call support</strong>",
    ],
  },
];
