import { Box } from "@console/dsc";
import { inputSharedStyles } from "@console/dsc/src/components/Input/Input.styles";
import {
  getInputPaddingLeftStyle,
  getInputPaddingRightStyle,
} from "@console/dsc/src/components/Input/utils/getInputPadding";
import { getInputSizeStyle } from "@console/dsc/src/components/Input/utils/getInputSizeStyles";
import styled from "@emotion/styled";

// Style Stripe input to match rest of site.
// NOTE: muse use `px` units rather than `rem`
// as root is Stripe's <iframe>, not Console
export const getStripeStyles = (theme: any) => {
  return {
    style: {
      base: {
        fontFamily: theme.ui2Typography.fontFamilyBody1,
        fontSize: "16px",
        fontSmoothing: "subpixel-antialiased",
        fontWeight: theme.ui2Typography.fontWeightBody1,
        lineHeight: "24px",
        color: theme.color.gray700,
        "::placeholder": {
          color: theme.color.gray500,
        },
      },
      invalid: {
        color: theme.color.red600,
        iconColor: theme.color.red500,
      },
    },
  };
};

export const StyledCreditCardContainer = styled(Box)`
  ${inputSharedStyles}
  ${(props) => getInputSizeStyle({ size: "large", theme: props.theme })}
  ${(props) =>
    getInputPaddingLeftStyle({
      hasIcon: false,
      size: "large",
      theme: props.theme,
    })}
  ${(props) =>
    getInputPaddingRightStyle({
      hasIcon: false,
      size: "large",
      theme: props.theme,
    })}

  display: flex;
  align-items: center;
  justify-content: flex-start;

  div {
    width: 100%;
  }
`;
