import { ExperimentStatus } from "../../../../../api/core/controlPlane.types";
import {
  StartStopModalContent,
  StartStopModalType,
} from "../../../types/Experiment.types";

export const getModalContent = (status: ExperimentStatus) => {};

export const modalContent: {
  [K in StartStopModalType]: StartStopModalContent;
} = {
  start: ({ baselineInstances, candidateInstances, testName }) => ({
    title: `Start ${testName}`,
    summary: `While the switchback test is in progress, runs made against the baseline instance will either run on the baseline (${baselineInstances}) or candidate (${candidateInstances}) instance according to the tests plan units. Do you want to start this switchback test?`,
    primaryActionLabel: "Start switchback test",
  }),
  complete: ({ testName }) => ({
    title: `Complete ${testName}`,
    summary: `Marking the switchback test as complete will set the status to complete and end the test immediately. Do you want to mark this test complete?`,
    primaryActionLabel: "Mark as complete",
  }),
  cancel: ({ testName }) => ({
    title: `Cancel ${testName}`,
    summary: `Canceling the switchback test will mark the test status as cancelled and end the test immediately. Do you want to mark this test cancelled?`,
    primaryActionLabel: "Cancel switchback test",
    secondaryActionLabel: "Never mind",
  }),
};
