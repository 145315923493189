import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import { useUser } from "../../AuthProvider";

import MarketplaceAPIReference from "./subpages/APIReference";
import MarketplaceAppDetails from "./subpages/Details";
import { MarketplaceAppPageProps } from "./MarketplaceApp.types";

const MarketplaceAppRoutes = ({
  marketplacePartnerApp,
  marketplacePartnerAppError,
  setDisplayPages,
}: MarketplaceAppPageProps) => {
  const [{ id: accId }] = useUser();
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <MarketplaceAppDetails
          {...{
            marketplacePartnerApp,
            marketplacePartnerAppError,
            setDisplayPages,
          }}
        />
      </Route>

      <Route exact path={`${path}/api-reference`}>
        <MarketplaceAPIReference />
      </Route>

      {/* 
      TODO: @remove-cloud-fleet
      <Route exact path={`${path}/demo/general`}>
        <Redirect to={`${url}/demo`} />
      </Route> */}

      <Redirect to={`/acc/${accId}/marketplace`} />
    </Switch>
  );
};

export default MarketplaceAppRoutes;
