import { MarketplaceAppConstraint } from "../../../api/core/controlPlane.types";

export const ORTOOLS_SHIFT_ASSIGNMENT_PARTNER_ID = "nextmv";
export const ORTOOLS_SHIFT_ASSIGNMENT_APP_ID = "shift.assign.ortools";
export const ORTOOLS_SHIFT_ASSIGNMENT_SUBSCRIPTION_ID = `${ORTOOLS_SHIFT_ASSIGNMENT_PARTNER_ID}-${ORTOOLS_SHIFT_ASSIGNMENT_APP_ID}`;
export const ORTOOLS_SHIFT_ASSIGNMENT_ICON_URL =
  "/images/logos/or-tools-icon.svg";
export const ORTOOLS_SHIFT_ASSIGNMENT_NAME = "OR-Tools Shift Assignment";
export const ORTOOLS_SHIFT_ASSIGNMENT_TEMPLATE_NAME =
  "shift-assignment-ortools";
export const ORTOOLS_SHIFT_ASSIGNMENT_SHORT_DESC =
  "Solve a MIP-based shift assignment model with OR-Tools.";
export const ORTOOLS_SHIFT_ASSIGNMENT_SUMMARY =
  "Solve a shift assignment model with the low-code OR-Tools Shift Assignment app. Define worker attributes such as availability, preferences, and qualifications as well as shift requirements and assignment rules and then run the app to assign workers to shifts.";
export const ORTOOLS_SHIFT_ASSIGNMENT_DOCS_LINK =
  "https://www.nextmv.io/docs/community-apps/shift-assignment";
export const ORTOOLS_SHIFT_ASSIGNMENT_CODE_REPO_LINK =
  "https://github.com/nextmv-io/community-apps/tree/develop/shift-assignment-ortools";
export const ORTOOLS_SHIFT_ASSIGNMENT_MODEL_TYPE = ["Scheduling"];
export const ORTOOLS_SHIFT_ASSIGNMENT_LANGUAGE = ["Python"];
export const ORTOOLS_SHIFT_ASSIGNMENT_PACKAGES = ["OR-Tools"];
export const ORTOOLS_SHIFT_ASSIGNMENT_SOLVER = ["SCIP"];

export const ORTOOLS_SHIFT_ASSIGNMENT_CONSTRAINTS: MarketplaceAppConstraint[] =
  [
    {
      id: "workers",
      name: "workers",
      property: "workers",
      docsUrl:
        "https://www.nextmv.io/docs/community-apps/shift-assignment#input",
      summary: "Availabile workers.",
      type: "array",
    },
    {
      id: "shifts",
      name: "shifts",
      property: "shifts",
      docsUrl:
        "https://www.nextmv.io/docs/community-apps/shift-assignment#input",
      summary: "Availabile shifts.",
      type: "array",
    },
    {
      id: "availability",
      name: "availability",
      property: "availability",
      docsUrl:
        "https://www.nextmv.io/docs/community-apps/shift-assignment#worker",
      summary: "Availability times of the worker.",
      type: "array",
    },
    {
      id: "min_rest_hours_between_shifts",
      name: "min_rest_hours_between_shifts",
      property: "min_rest_hours_between_shifts",
      docsUrl:
        "https://www.nextmv.io/docs/community-apps/shift-assignment#rule",
      summary: "Number of hours a worker must rest between shifts.",
      type: "int",
    },
    {
      id: "qualifications",
      name: "qualifications",
      property: "qualifications",
      docsUrl:
        "https://www.nextmv.io/docs/community-apps/shift-assignment#worker",
      summary: "Which workers are compatible with which shifts.",
      type: "array",
    },
    {
      id: "preferences",
      name: "preferences",
      property: "preferences",
      docsUrl:
        "https://www.nextmv.io/docs/community-apps/shift-assignment#worker",
      summary: "Worker preferences for shifts.",
      type: "map",
    },
    {
      id: "start_time",
      name: "start_time",
      property: "start_time",
      docsUrl:
        "https://www.nextmv.io/docs/community-apps/shift-assignment#shift",
      summary: "Start time of a shift.",
      type: "timestamp",
    },
    {
      id: "end_time",
      name: "end_time",
      property: "end_time",
      docsUrl:
        "https://www.nextmv.io/docs/community-apps/shift-assignment#shift",
      summary: "End time of a shift.",
      type: "timestamp",
    },
    {
      id: "count",
      name: "count",
      property: "count",
      docsUrl:
        "https://www.nextmv.io/docs/community-apps/shift-assignment#shift",
      summary: "Number of workers needed for the shift.",
      type: "integer",
    },
  ];
