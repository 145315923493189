import { useMemo } from "react";
import { useLocation } from "react-router";

const getReturnPathList = (baseUrl: string) => {
  const regexUrlEnd = /(.*)\/(experiment)\/([a-z]*)$/m;
  const regexMatch = baseUrl.match(regexUrlEnd);

  if (regexMatch && regexMatch[2] === "experiment") {
    const baseUrlApp = regexMatch[1];
    const slugExperiments = `${regexMatch[2]}s`;
    const slugExperimentType = regexMatch[3];

    return `${baseUrlApp}/${slugExperiments}/${slugExperimentType}`;
  } else {
    return `${baseUrl}s`;
  }
};

const useReturnPaths = () => {
  const { pathname, search } = useLocation();
  const sourceParam = new URLSearchParams(search).get("source");

  const pathnameArray = pathname.split("/");

  let baseUrl: string;
  let returnPath: string;
  let returnPathList: string;
  let slug: string | undefined = pathnameArray.pop();

  if (slug === "new") {
    baseUrl = pathnameArray.join("/");
    returnPathList = baseUrl;
    returnPath = baseUrl;
  } else {
    if (slug === "edit") {
      slug = pathnameArray.pop();
    }
    baseUrl = pathnameArray.join("/");
    returnPathList = getReturnPathList(baseUrl);
    returnPath = sourceParam === "list" ? returnPathList : `${baseUrl}/${slug}`;
  }

  return useMemo(
    () => ({
      returnPathList,
      returnPath,
    }),
    [returnPath, returnPathList]
  );
};

export default useReturnPaths;
