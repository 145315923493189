import {
  CreateRunProfilePayload,
  PutRunProfilePayload,
  RunProfile,
  RunProfileDeleteResponse,
  RunProfiles,
} from "../../api/core/controlPlane.types";

export enum RunProfilesActionKind {
  UPDATE_ALL,
  UPDATE_ONE,
  ADD,
  SET_ERROR,
  LOADING,
  DELETE_ONE,
}
interface UPDATE_ALL {
  type: RunProfilesActionKind.UPDATE_ALL;
  payload: RunProfiles;
}

interface UPDATE_ONE {
  type: RunProfilesActionKind.UPDATE_ONE;
  payload: RunProfile;
}

interface ADD {
  type: RunProfilesActionKind.ADD;
  payload: RunProfile;
}

interface SET_ERROR {
  type: RunProfilesActionKind.SET_ERROR;
  payload: string;
}

interface LOADING {
  type: RunProfilesActionKind.LOADING;
}

interface DELETE_ONE {
  type: RunProfilesActionKind.DELETE_ONE;
  payload: RunProfileDeleteResponse;
}

export type RunProfilesAction =
  | UPDATE_ALL
  | UPDATE_ONE
  | ADD
  | SET_ERROR
  | DELETE_ONE
  | LOADING;

export type RunProfilesState = {
  runProfiles: RunProfiles;
  error: string;
  loading: boolean;
};

export type RunProfilesContextProps = {
  getFilteredProfiles: (s: string) => RunProfiles;
  addRunProfile: (rp: CreateRunProfilePayload) => void;
  getProfileById: (id: string) => RunProfile | undefined;
  editRunProfile: (id: string, payload: PutRunProfilePayload) => void;
  removeRunProfile: (id: string) => void;
  fetchRunProfiles: () => void;
  clearRunProfileError: () => void;
  runProfilesError: string;
  runProfilesLoading: boolean;
};

export type AnyFunction = (...args: any[]) => any;
