import React from "react";
import { Button2, IconRefresh } from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";

export type ButtonRefreshProps = {
  emptyItems:
    | {
        items: null | any[];
        next_page_token: string;
      }
    | any[]
    | null;
  itemsLoading: boolean;
  loadItems: () => void;
  setItems: React.Dispatch<React.SetStateAction<any>>;
};

const ButtonRefresh = ({
  emptyItems,
  itemsLoading,
  loadItems,
  setItems,
}: ButtonRefreshProps) => {
  const theme = useTheme();

  return (
    <Button2
      ml={2}
      data-testid="button-refresh-list"
      isLoading={itemsLoading}
      type="outline-quiet"
      icon={<IconRefresh iconColor={theme.color.gray600} />}
      label="Refresh"
      onClick={() => {
        setItems(emptyItems);
        loadItems();
      }}
      styles={{
        width: rem(92),
        boxShadow: `inset 0 0 0 ${theme.border.widthMediumShadowOnly} ${theme.color.gray300}`,
        "&:hover": { svg: { fill: theme.color.gray700 } },
      }}
    />
  );
};

export default ButtonRefresh;
