import React from "react";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";
import { DateTime } from "luxon";

import Box from "../Box";
import Flex from "../Flex";
import Text from "../Text";

import {
  ActionLink,
  BreakText,
  EditLink,
  ProfileLink,
  TruncateText,
} from "./PreviewRow2.styled";
import { PreviewRow2Props } from "./PreviewRow2.types";

const PreviewRow2 = ({
  className,
  description,
  hasNoBorder,
  id,
  icon,
  isApp,
  isMini,
  lastUpdated,
  metaSubDescription,
  metaExtra1,
  metaExtra2,
  name,
  tag,
  testId,
  url,
  urlOnClick,
  urlActionLabel,
  urlActionOnClick,
  urlEdit,
  urlEditOnClick,
}: PreviewRow2Props) => {
  const theme = useTheme();

  return (
    <Flex
      {...{ className }}
      width="100%"
      pt={isMini ? 2 : 3}
      pb={isMini ? 2 : 4}
      hasBorderTop={hasNoBorder ? undefined : true}
      {...(testId && {
        "data-testid": testId,
      })}
    >
      <Flex
        width={`calc(100% - ${rem(152)})`}
        alignItems="flex-start"
        flexDirection={["column", "column", "column", "row"]}
        pr={4}
      >
        <Flex alignItems={isApp ? "center" : "stretch"}>
          {icon && (
            <Flex
              width={isMini ? theme.spacing.s7 : theme.spacing.s8}
              mr={isApp ? 1 : 0}
              mt={isMini ? rem(2) : 0}
              noShrink
              alignItems="flex-start"
            >
              {icon}
            </Flex>
          )}

          <Flex
            width={[
              "100%",
              "100%",
              "100%",
              isMini ? "100%" : rem(280),
              isMini ? "100%" : rem(340),
            ]}
            flexDirection={isMini ? "row" : "column"}
            flexWrap="wrap"
            {...(isMini && {
              alignItems: "baseline",
            })}
          >
            {name && (
              <Flex alignItems="flex-end">
                <Text
                  as="h3"
                  mt={rem(2)}
                  mb={rem(1)}
                  mr={isMini ? 2 : 0}
                  styleName="body-1-bold"
                  styles={{
                    lineHeight: theme.ui2Typography.lineHeightBody3Bold,
                    color: theme.color.gray800,
                  }}
                >
                  {url ? (
                    <ProfileLink to={url} onClick={urlOnClick}>
                      <BreakText>{name}</BreakText>
                    </ProfileLink>
                  ) : (
                    <BreakText>{name}</BreakText>
                  )}
                </Text>
                {tag}
              </Flex>
            )}
            {id && (
              <Text
                as="span"
                styleName="body-3"
                styles={{
                  display: "block",
                  color: theme.color.gray600,
                  flexShrink: 0,
                }}
              >
                {id}
              </Text>
            )}
          </Flex>
        </Flex>

        {!isMini && (
          <Flex
            width="100%"
            alignItems="flex-start"
            minWidth={0}
            flexDirection={["column", "column", "column", "column", "row"]}
          >
            {(description || metaSubDescription) && (
              <Box
                mt={[1, 1, 1, rem(3)]}
                pl={[8, 8, 8, 4]}
                width={[
                  "100%",
                  "100%",
                  "100%",
                  "100%",
                  metaExtra1 || metaExtra2 ? "50%" : "100%",
                ]}
              >
                {description && typeof description === "string" ? (
                  <Text styleName="body-3">
                    <TruncateText>{description}</TruncateText>
                  </Text>
                ) : (
                  description
                )}
                {metaSubDescription}
              </Box>
            )}

            {(metaExtra1 || metaExtra2) && (
              <Box
                mt={[1, 1, 1, rem(1)]}
                ml="auto"
                pl={[8, 8, 8, 4]}
                width={["100%", "100%", "100%", "100%", "50%"]}
              >
                {metaExtra1}
                {metaExtra2}
              </Box>
            )}
          </Flex>
        )}
      </Flex>

      {(lastUpdated || urlEdit || (urlActionLabel && urlActionOnClick)) && (
        <Flex
          ml="auto"
          flexShrink={0}
          justifyContent="flex-end"
          width={
            urlEdit || (urlActionLabel && urlActionOnClick)
              ? rem(152)
              : rem(120)
          }
        >
          {lastUpdated && (
            <Text
              mt={rem(3)}
              as="time"
              styleName="body-3"
              {...(lastUpdated && {
                htmlDatetime: lastUpdated,
                htmlTitle: DateTime.fromISO(lastUpdated).toFormat(
                  "LLL dd, yyyy @ hh:mm:ss ZZZZ"
                ),
              })}
              styles={{
                display: "block",
                flexShrink: 0,
                textAlign: "right",
                color: theme.color.gray600,
              }}
            >
              {DateTime.fromISO(lastUpdated).toRelative()}
            </Text>
          )}

          {(urlEdit || (urlActionLabel && urlActionOnClick)) && (
            <Flex
              mt={rem(3.5)}
              ml={lastUpdated ? 2 : 0}
              flexShrink={0}
              justifyContent="flex-end"
            >
              {urlActionLabel && (
                <ActionLink onClick={urlActionOnClick}>
                  {urlActionLabel}
                </ActionLink>
              )}
              {urlEdit && (
                <EditLink to={urlEdit} onClick={urlEditOnClick}>
                  Edit
                </EditLink>
              )}
            </Flex>
          )}
        </Flex>
      )}
    </Flex>
  );
};

export default PreviewRow2;
