import * as React from "react";
import { ThemeProvider } from "@emotion/react";

import theme from "../style/theme";

const CustomThemeProvider = ({
  children,
}: {
  children: React.ReactChild[];
}) => {
  const thisTheme = theme();
  return <ThemeProvider theme={thisTheme}>{children}</ThemeProvider>;
};

export default CustomThemeProvider;
