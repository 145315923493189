import { Box, Table2, Text } from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { get } from "lodash";

import { RunDetailsNestedOutputValue } from "../../../../../api/core/controlPlane.types";
import { Table2HeaderObj } from "../../RunHistory/RunHistory.types";

export const checkIsRunOutputBlockValid = (runOutputBlock: any) => {
  if (!runOutputBlock) return false;
  if (typeof runOutputBlock !== "object") return false;
  if (Array.isArray(runOutputBlock)) return false;
  return true;
};

export const getKeys = (
  obj: RunDetailsNestedOutputValue,
  checkValue = false
): string[] => {
  let keys = [] as string[];
  obj &&
    Object.keys(obj).forEach((key) => {
      if (typeof obj[key] === "object") {
        const subKeys = getKeys(obj[key] as RunDetailsNestedOutputValue);
        keys = keys.concat(
          subKeys.map((subKey) => {
            return `${key}.${subKey}`;
          })
        );
      } else {
        keys.push(key);
      }
    });
  return keys;
};

type GetRunOutputBlockTableHeadersParams = {
  propertyId: string;
  propertyName: string;
  theme: any;
  valueId: string;
  valueName: string;
};
export const getRunOutputBlockTableHeaders = ({
  propertyId,
  propertyName,
  theme,
  valueId,
  valueName,
}: GetRunOutputBlockTableHeadersParams): Table2HeaderObj[] => {
  return [
    {
      id: propertyId,
      accessorKey: propertyId,
      header: propertyName,
      label: propertyName,
      cell: (props) => {
        return (
          <Text
            as="code"
            styleName="code"
            styles={{
              wordBreak: "break-all",
            }}
          >
            {props.getValue()}
          </Text>
        );
      },
    },
    {
      id: valueId,
      accessorKey: valueId,
      header: valueName,
      label: valueName,
      cell: (props) => {
        return (
          <Text
            as="code"
            styleName="code-bold"
            styles={{ color: theme.color.gray800 }}
          >
            {props.getValue()}
          </Text>
        );
      },
    },
  ];
};

type GetRunOutputBlockTableDataParams = {
  propertyId: string;
  valueId: string;
  data?: RunDetailsNestedOutputValue | false;
};
export const getRunOutputBlockTableData = ({
  data,
  propertyId,
  valueId,
}: GetRunOutputBlockTableDataParams):
  | {
      [key: string]: string | number;
    }
  | {}[] => {
  if (!data) {
    return {};
  }

  const keys = getKeys(data);

  return keys.map((key) => {
    const valueValue = get(data, key);
    return {
      [propertyId]: key,
      [valueId]:
        valueValue === null || valueValue === undefined
          ? ""
          : valueValue.toString(),
    };
  });
};

type RenderRunOutputBlockParams = {
  fileNameCSV: string;
  tableHeaders: Table2HeaderObj[];
  tableData:
    | {
        [key: string]: string | number;
      }
    | {}[];
  theme: any;
  altMessage?: string;
  shouldDisplayAltMessage?: boolean;
};
export const renderRunOutputBlock = ({
  altMessage,
  fileNameCSV,
  shouldDisplayAltMessage,
  tableHeaders,
  tableData,
  theme,
}: RenderRunOutputBlockParams) => {
  if (shouldDisplayAltMessage && altMessage) {
    return (
      <Text styleName="body-2" styles={{ color: theme.color.gray600 }}>
        {altMessage}
      </Text>
    );
  }

  return (
    <Box mt={3} pr={3}>
      <Table2
        canFilter
        canSort
        showCSVLink
        csvLinkLocation="bottom"
        fileNameCSV={fileNameCSV}
        size="small"
        type="zebra"
        useMinWidth
        headers={tableHeaders}
        data={tableData || []}
        csvLinkTopValue={rem(-6)}
      />
    </Box>
  );
};
