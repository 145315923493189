import * as React from "react";

import BaseAvatar, { AvatarSize } from "./BaseAvatar";

const AvatarRunProfile = ({ size }: { size?: AvatarSize }) => {
  return (
    <BaseAvatar {...{ size }}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 45.75C36.0122 45.75 45.75 36.0122 45.75 24C45.75 11.9878 36.0122 2.25 24 2.25C11.9878 2.25 2.25 11.9878 2.25 24C2.25 36.0122 11.9878 45.75 24 45.75ZM24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
        fill="#01d181"
      />
      <path
        d="M34.1806 24.8251L33.6059 27.3945L37.8318 29.9639C38.2713 30.2343 38.4404 30.8091 38.2037 31.2824L38.0009 31.6881C37.7642 32.1276 37.2571 32.3642 36.7838 32.1952L28.4671 29.4229L25.4244 27.3945H12.8817L10.6842 29.592C10.2785 29.9977 9.63612 29.9977 9.26423 29.592L8.55427 28.882C8.14858 28.4763 8.14858 27.834 8.55427 27.4621L12.2731 23.777C12.5436 23.5066 12.9155 23.4052 13.2536 23.5066L14.5383 23.8785L17.04 21.7148L15.6877 20.802C14.8763 20.261 14.7749 19.1116 15.4511 18.4354C16.1272 17.7592 17.2767 17.8607 17.8176 18.6721L18.8318 20.1258L27.0471 21.9514C28.1966 22.2219 29.3799 21.7824 30.1236 20.8358L30.5293 20.2948L24.9172 18.9087C24.1735 18.7397 23.5649 18.1988 23.2945 17.4888C22.8212 16.2717 23.3959 14.8856 24.613 14.4123C25.3229 14.1418 26.1343 14.2094 26.7767 14.6151L33.1663 18.7397C33.6735 18.6721 34.2144 18.7735 34.6539 19.0778L37.629 21.2415C38.3051 21.7486 38.6094 22.5938 38.4066 23.4052L38.2713 23.6756C38.2375 23.8447 38.0685 24.0137 37.8994 24.0475L34.1806 24.8251Z"
        fill="#01d181"
      />
    </BaseAvatar>
  );
};

export default AvatarRunProfile;
