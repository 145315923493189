import React from "react";

import {
  createRunProfile,
  deleteRunProfile,
  getRunProfiles,
  updateRunProfile,
} from "../../api/core/controlPlane";
import {
  CreateRunProfilePayload,
  PutRunProfilePayload,
  RunProfile,
  RunProfiles,
} from "../../api/core/controlPlane.types";
import { useUser } from "../../AuthProvider";
import { removeById, replaceById, sortByName } from "../utils";

import {
  AnyFunction,
  RunProfilesAction,
  RunProfilesActionKind,
  RunProfilesContextProps,
  RunProfilesState,
} from "./RunProfiles.context.types";

export const RunProfilesContext = React.createContext<RunProfilesContextProps>({
  getFilteredProfiles: (s: string) => [],
  getProfileById: (id: string) => ({
    name: "",
    id: "",
    version: "",
    measure: { type: "haversine" },
  }),
  addRunProfile: (rp: CreateRunProfilePayload) => {},
  editRunProfile: (id: string, payload: PutRunProfilePayload) => {},
  removeRunProfile: (id: string) => {},
  fetchRunProfiles: () => {},
  clearRunProfileError: () => {},
  runProfilesError: "",
  runProfilesLoading: true,
});

const runProfilesReducer = (
  state: RunProfilesState,
  action: RunProfilesAction
): RunProfilesState => {
  switch (action.type) {
    case RunProfilesActionKind.UPDATE_ALL:
      return {
        error: "",
        loading: false,
        runProfiles: sortByName(action.payload),
      };
    case RunProfilesActionKind.UPDATE_ONE:
      return {
        loading: false,
        error: "",
        runProfiles: sortByName(replaceById(action.payload, state.runProfiles)),
      };
    case RunProfilesActionKind.ADD:
      return {
        loading: false,
        error: "",
        runProfiles: sortByName(state.runProfiles.concat(action.payload)),
      };
    case RunProfilesActionKind.SET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case RunProfilesActionKind.DELETE_ONE:
      const { id } = action.payload;
      return {
        loading: false,
        error: "",
        runProfiles: removeById(id, state.runProfiles),
      };
    case RunProfilesActionKind.LOADING:
      return {
        ...state,
        loading: true,
        error: "",
      };
    default:
      throw new Error("run profiles action not supported");
  }
};

const RunProfilesProvider = ({ children }: { children: React.ReactNode }) => {
  const [{ id: accountId }] = useUser();

  // list run profiles /routing/profiles/run
  const [{ error, loading, runProfiles }, runProfilesDispatch] =
    React.useReducer(runProfilesReducer, {
      error: "",
      runProfiles: [],
      loading: true,
    });

  const getFilteredProfiles = React.useCallback(
    (searchString: string): RunProfiles => {
      return runProfiles.filter((rp: RunProfile) =>
        rp.name.toLocaleLowerCase().includes(searchString.toLocaleLowerCase())
      );
    },
    [runProfiles]
  );

  const wrapFetch = <Func extends AnyFunction>(
    fn: Func,
    action: RunProfilesActionKind
  ): ((...args: Parameters<Func>) => void) => {
    const wrappedFn = async (...args: Parameters<Func>) => {
      runProfilesDispatch({ type: RunProfilesActionKind.LOADING });
      try {
        const resJson = await fn(...args);
        runProfilesDispatch({
          type: action,
          payload: resJson,
        });
      } catch (e: any) {
        runProfilesDispatch({
          type: RunProfilesActionKind.SET_ERROR,
          payload: e.message,
        });
      }
    };
    return wrappedFn;
  };

  const fetchRunProfiles = React.useMemo(
    () =>
      wrapFetch(
        getRunProfiles(accountId || ""),
        RunProfilesActionKind.UPDATE_ALL
      ),
    [accountId]
  );

  const addRunProfile = React.useMemo(
    () =>
      wrapFetch(createRunProfile(accountId || ""), RunProfilesActionKind.ADD),
    [accountId]
  );

  const editRunProfile = React.useMemo(
    () =>
      wrapFetch(
        updateRunProfile(accountId || ""),
        RunProfilesActionKind.UPDATE_ONE
      ),
    [accountId]
  );

  const removeRunProfile = React.useMemo(
    () =>
      wrapFetch(
        deleteRunProfile(accountId || ""),
        RunProfilesActionKind.DELETE_ONE
      ),
    [accountId]
  );

  const getProfileById = React.useCallback(
    (id: string) => runProfiles.find((rp) => rp.id === id),
    [runProfiles]
  );

  React.useEffect(() => {
    if (accountId) {
      fetchRunProfiles();
    }
  }, [fetchRunProfiles, accountId]);

  const clearRunProfileError = React.useCallback(() => {
    runProfilesDispatch({ type: RunProfilesActionKind.SET_ERROR, payload: "" });
  }, []);

  const value = React.useMemo(
    () => ({
      getFilteredProfiles,
      getProfileById,
      editRunProfile,
      fetchRunProfiles,
      addRunProfile,
      removeRunProfile,
      clearRunProfileError,
      runProfilesError: error,
      runProfilesLoading: loading,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [error, loading, runProfiles]
  );

  return (
    <RunProfilesContext.Provider value={value}>
      {children}
    </RunProfilesContext.Provider>
  );
};

export const useRunProfiles = () => React.useContext(RunProfilesContext);

export default RunProfilesProvider;
