import { getUrlBase } from "../../MainNav/data/pages";
import { PageNavigationItem } from "../../MainNav/MainNav.types";

export const getProfileMenuAccountPages = (
  accId?: string | null
): PageNavigationItem[] => {
  const urlBase = getUrlBase(accId);

  return [
    {
      id: "account-profile",
      label: "Profile",
      url: `${urlBase}/account/profile`,
    },
  ];
};

export const getProfileMenuOtherPages = (
  accId?: string | null
): PageNavigationItem[] => {
  return [
    {
      id: "profile-menu-terms",
      label: "Terms of Use",
      url: "https://www.nextmv.io/terms-of-use",
      isExternal: true,
    },
    {
      id: "profile-menu-status",
      label: "Nextmv Status",
      url: "https://nextmv.statuspage.io/",
      isExternal: true,
    },
  ];
};
