import * as React from "react";
import styled from "@emotion/styled";

import { svgIconStyle } from "../lib/constants";

type StyledSvgProps = {
  activeColor?: string;
  focusColor?: string;
  hoverColor?: string;
};
const StyledSvg = styled.svg<StyledSvgProps>`
  ${(props) =>
    svgIconStyle({
      activeColor: props.activeColor,
      focusColor: props.focusColor,
      hoverColor: props.hoverColor,
    })}
`;
const renderSvgs = ({
  activeColor,
  className,
  desc,
  focusColor,
  hoverColor,
  iconColor,
  size,
  title,
  direction,
}: {
  activeColor?: string;
  className?: string;
  desc?: string;
  focusColor?: string;
  hoverColor?: string;
  iconColor?: string;
  size?: number | string;
  title?: string;
  direction?: string;
}) => {
  if (direction === "left") {
    return (
      <StyledSvg
        {...{ className, activeColor, focusColor, hoverColor }}
        width={size || "16"}
        height={size ? undefined : "16"}
        viewBox="0 0 16 16"
        fill={iconColor || "black"}
        xmlns="http://www.w3.org/2000/svg"
      >
        {!!title && <title>{title}</title>}
        {!!desc && <desc>{desc}</desc>}
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.297 3.203C7.58989 3.49589 7.58989 3.97077 7.297 4.26366L3.56066 8L7.297 11.7363C7.58989 12.0292 7.58989 12.5041 7.297 12.797C7.0041 13.0899 6.52923 13.0899 6.23634 12.797L1.96967 8.53033C1.67678 8.23743 1.67678 7.76256 1.96967 7.46967L6.23634 3.203C6.52923 2.91011 7.0041 2.91011 7.297 3.203Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.697 3.203C12.9899 3.49589 12.9899 3.97077 12.697 4.26366L8.96066 8L12.697 11.7363C12.9899 12.0292 12.9899 12.5041 12.697 12.797C12.4041 13.0899 11.9292 13.0899 11.6363 12.797L7.36967 8.53033C7.22901 8.38967 7.15 8.19891 7.15 8C7.15 7.80108 7.22901 7.61032 7.36967 7.46967L11.6363 3.203C11.9292 2.91011 12.4041 2.91011 12.697 3.203Z"
        />
      </StyledSvg>
    );
  } else if (direction === "right") {
    return (
      <StyledSvg
        {...{ className, activeColor, focusColor, hoverColor }}
        width={size || "16"}
        height={size ? undefined : "16"}
        viewBox="0 0 16 16"
        fill={iconColor || "black"}
        xmlns="http://www.w3.org/2000/svg"
      >
        {!!title && <title>{title}</title>}
        {!!desc && <desc>{desc}</desc>}
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.36967 12.797C8.07678 12.5041 8.07678 12.0292 8.36967 11.7363L12.106 8L8.36967 4.26366C8.07678 3.97077 8.07678 3.49589 8.36967 3.203C8.66256 2.91011 9.13744 2.91011 9.43033 3.203L13.697 7.46967C13.9899 7.76256 13.9899 8.23743 13.697 8.53033L9.43033 12.797C9.13744 13.0899 8.66256 13.0899 8.36967 12.797Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.96967 12.797C2.67678 12.5041 2.67678 12.0292 2.96967 11.7363L6.70601 8L2.96967 4.26366C2.67678 3.97077 2.67678 3.49589 2.96967 3.203C3.26256 2.91011 3.73744 2.91011 4.03033 3.203L8.297 7.46967C8.58989 7.76256 8.58989 8.23743 8.297 8.53033L4.03033 12.797C3.73744 13.0899 3.26256 13.0899 2.96967 12.797Z"
        />
      </StyledSvg>
    );
  } else {
    return (
      <StyledSvg
        {...{ className, activeColor, focusColor, hoverColor }}
        width={size || "16"}
        height={size ? undefined : "16"}
        viewBox="0 0 16 16"
        fill={iconColor || "black"}
        xmlns="http://www.w3.org/2000/svg"
      >
        {!!title && <title>{title}</title>}
        {!!desc && <desc>{desc}</desc>}
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.36967 12.797C8.07678 12.5041 8.07678 12.0292 8.36967 11.7363L12.106 8L8.36967 4.26366C8.07678 3.97077 8.07678 3.49589 8.36967 3.203C8.66256 2.91011 9.13744 2.91011 9.43033 3.203L13.697 7.46967C13.9899 7.76256 13.9899 8.23743 13.697 8.53033L9.43033 12.797C9.13744 13.0899 8.66256 13.0899 8.36967 12.797Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.96967 12.797C2.67678 12.5041 2.67678 12.0292 2.96967 11.7363L6.70601 8L2.96967 4.26366C2.67678 3.97077 2.67678 3.49589 2.96967 3.203C3.26256 2.91011 3.73744 2.91011 4.03033 3.203L8.297 7.46967C8.58989 7.76256 8.58989 8.23743 8.297 8.53033L4.03033 12.797C3.73744 13.0899 3.26256 13.0899 2.96967 12.797Z"
        />
      </StyledSvg>
    );
  }
};
const IconDoubleChevron = ({
  activeColor,
  className,
  desc,
  focusColor,
  hoverColor,
  iconColor,
  size,
  title,
  direction,
}: {
  activeColor?: string;
  className?: string;
  desc?: string;
  focusColor?: string;
  hoverColor?: string;
  iconColor?: string;
  size?: number | string;
  title?: string;
  direction?: string;
}) => {
  return (
    <React.Fragment>
      {renderSvgs({
        activeColor,
        className,
        desc,
        focusColor,
        hoverColor,
        iconColor,
        size,
        title,
        direction,
      })}
    </React.Fragment>
  );
};
export default IconDoubleChevron;
