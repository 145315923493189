import { useState } from "react";

import {
  startShadowTest,
  stopShadowTest,
} from "../../../../../api/core/controlPlane";
import { EntityErrorMessage } from "../../../../../api/core/controlPlane.types";
import { useUser } from "../../../../../AuthProvider";
import {
  TEST_ACTION_CANCEL,
  TEST_ACTION_COMPLETE,
} from "../../../../../config/experiments";
import { StartStopModalType } from "../../../types/Experiment.types";

const useShadowTest = (appId: string, shadowTestId: string) => {
  const [{ id: accountId }] = useUser();

  const [displayConfirmModal, setDisplayConfirmModal] =
    useState<StartStopModalType | null>(null);
  const [modalActionsError, setModalActionsError] =
    useState<EntityErrorMessage>(null);

  const handleShadowTestStart = async () => {
    try {
      await startShadowTest(accountId || "")(appId, shadowTestId);
      setDisplayConfirmModal(null);
    } catch (e) {
      setModalActionsError((e as Error).message);
    }
    return;
  };

  const handleShadowTestStop = async (actionType: StartStopModalType) => {
    try {
      await stopShadowTest(accountId || "")(
        appId,
        shadowTestId,
        actionType as typeof TEST_ACTION_COMPLETE | typeof TEST_ACTION_CANCEL
      );
      setDisplayConfirmModal(null);
    } catch (e) {
      setModalActionsError((e as Error).message);
    }
  };

  return {
    displayConfirmModal,
    handleShadowTestStart,
    handleShadowTestStop,
    modalActionsError,
    setDisplayConfirmModal,
    setModalActionsError,
  };
};

export default useShadowTest;
