import { Box, Flex, Text } from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";

import { AppType } from "../../../../../api/core/controlPlane.types";
import { NEXTROUTE_SUBSCRIPTION_ID } from "../../../../MarketplaceApp/data/marketAppNextroute";
import { SHIFT_SCHEDULING_SUBSCRIPTION_ID } from "../../../../MarketplaceApp/data/marketAppShiftScheduling";

type VisualizationPlaceholderProps = {
  appSubscriptionId?: string;
  appType?: AppType;
};

const isVisualizationSupported = (subscriptionId?: string): boolean => {
  if (!subscriptionId) return false;
  if (
    [NEXTROUTE_SUBSCRIPTION_ID, SHIFT_SCHEDULING_SUBSCRIPTION_ID].includes(
      subscriptionId
    )
  ) {
    return true;
  }
  return false;
};

const VisualizationPlaceholder = ({
  appSubscriptionId,
  appType,
}: VisualizationPlaceholderProps) => {
  const theme = useTheme();

  const renderVisualizationPlaceholderContent = () => {
    if (appType === "subscription") {
      return (
        <Box style={{ width: "90%", maxWidth: rem(360) }}>
          <Text styleName="body-2" hasLink styles={{ textAlign: "center" }}>
            {isVisualizationSupported(appSubscriptionId)
              ? "Visualization of input and output will appear here."
              : "Input and output visualization for this app is not supported."}{" "}
            View the{" "}
            <a
              href="https://www.nextmv.io/docs/community-apps/get-started"
              target="_blank"
              rel="noreferrer"
            >
              Community apps section
            </a>{" "}
            of Docs for input schema reference.
          </Text>
        </Box>
      );
    }

    return (
      <>
        <Text
          styleName="body-2"
          mr="auto"
          ml="auto"
          pb={2}
          styles={{ width: "90%", maxWidth: rem(360), textAlign: "center" }}
        >
          If supported by the app, visualization for input and output will
          appear here.
        </Text>
      </>
    );
  };

  return (
    <Flex
      justifyContent="center"
      alignContent="center"
      mr="auto"
      ml="auto"
      flexDirection="column"
      flexWrap="wrap"
      height="100%"
      width="100%"
      background={theme.color.gray100}
    >
      {renderVisualizationPlaceholderContent()}
    </Flex>
  );
};

export default VisualizationPlaceholder;
