import * as React from "react";
import { useTheme } from "@emotion/react";

import BaseAvatar, { AvatarSize } from "./BaseAvatar";

type AvatarMarketplaceAppProps = {
  type: "active" | "inactive";
  size?: AvatarSize;
};

const AvatarMarketplaceApp = ({ size, type }: AvatarMarketplaceAppProps) => {
  const theme = useTheme();
  const iconColor =
    type === "active" ? theme.color.orange600 : theme.color.gray500;

  return (
    <BaseAvatar size={size || 32} viewBox={[0, 0, 32, 32]}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8168 2.99805C15.4623 2.99805 15.1138 3.08971 14.8053 3.26412L4.73262 8.95735C4.41571 9.13648 4.15205 9.39655 3.9686 9.71097C3.78515 10.0254 3.68848 10.3829 3.68848 10.7469V22.0984C3.68848 22.4624 3.78514 22.8199 3.9686 23.1343C4.15205 23.4487 4.41571 23.7088 4.73262 23.8879L14.8053 29.5812C15.1139 29.7556 15.4623 29.8472 15.8168 29.8472C16.1712 29.8472 16.5197 29.7556 16.8282 29.5812L26.9009 23.8879C27.2178 23.7088 27.4815 23.4487 27.6649 23.1343C27.8484 22.8199 27.945 22.4624 27.945 22.0984V10.7469C27.945 10.3829 27.8484 10.0254 27.6649 9.71097C27.4815 9.39655 27.2178 9.13648 26.9009 8.95735L16.8282 3.26412C16.5197 3.08971 16.1712 2.99805 15.8168 2.99805ZM5.88116 22.0184V10.8269L15.8168 5.21111L25.7524 10.8269V22.0184L15.8168 27.6342L5.88116 22.0184ZM12.5963 16.4226C12.5963 14.644 14.0381 13.2021 15.8168 13.2021C17.5954 13.2021 19.0373 14.644 19.0373 16.4226C19.0373 18.2012 17.5954 19.6431 15.8168 19.6431C14.0381 19.6431 12.5963 18.2012 12.5963 16.4226ZM15.8168 11.0094C12.8271 11.0094 10.4036 13.433 10.4036 16.4226C10.4036 19.4122 12.8271 21.8358 15.8168 21.8358C18.8064 21.8358 21.2299 19.4122 21.2299 16.4226C21.2299 13.433 18.8064 11.0094 15.8168 11.0094Z"
        fill={iconColor}
      />
    </BaseAvatar>
  );
};

export default AvatarMarketplaceApp;
