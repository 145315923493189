import { Flex } from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import styled from "@emotion/styled";

export const StyledMetaLinkFlex = styled(Flex)`
  margin-top: ${({ theme }) => theme.spacing.s8};
  margin-bottom: ${({ theme }) => theme.spacing["-s8"]};

  @media (min-height: ${rem(960)}) {
    margin-top: ${({ theme }) => theme.spacing["-s9"]};
    margin-bottom: 0;
  }
`;
