import React from "react";
import {
  Box,
  Button2,
  Flex,
  Input,
  InputCheckbox,
  Notification,
  Text,
  Tooltip,
} from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";
import { lowerCase, upperFirst } from "lodash";

import { trackEvent } from "../../../analytics";
import { SignUpFormField } from "../../../analytics/types/legacy.types";
import PasswordRules from "../../../components/PasswordRules";
import { handleInputChange } from "../../../utils/inputHelpers";
import { RenderSignUpFormInputParams, SignUpFormProps } from "../SignUp.types";

const SignUpForm = ({
  company,
  email,
  emailInputError,
  error,
  password,
  isPage2,
  isPasswordFocused,
  isProcessing,
  isSubscribedNewsletter,
  nameFirst,
  nameLast,
  setCompany,
  setEmail,
  setEmailInputError,
  setIsPage2,
  setIsPasswordFocused,
  setIsSubscribedNewsletter,
  setNameFirst,
  setNameLast,
  setPassword,
  signUpEmailPassword,
}: SignUpFormProps) => {
  const theme = useTheme();

  const handleSignUpFormSubmit = async (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    e.preventDefault();
    e.stopPropagation();
    await signUpEmailPassword();
  };

  const handleSignUpContinue = (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    e.preventDefault();
    e.stopPropagation();

    if (!email) {
      setEmailInputError("Your email address is required.");
      return;
    }

    setEmailInputError(null);
    setIsPage2(true);
  };

  const renderSignUpFormInput = ({
    error,
    id,
    label,
    value,
    setValue,
  }: RenderSignUpFormInputParams) => {
    const getPlaceholder = (id: string) => {
      if (id === "email") return "Email address";
      return upperFirst(lowerCase(id));
    };
    const getHtmlType = (id: string) => {
      if (id === "email") return "email";
      if (id === "password") return "password";
      return "text";
    };

    const placeholder = getPlaceholder(id);

    const trackInputField = () => {
      // @NO-CHANGE used by backend and Hubspot
      trackEvent("Sign Up Form Field Entered", {
        type: placeholder as SignUpFormField,
        content: value,
      });
    };

    return (
      <Input
        mt={label ? 3 : 1}
        testId={`sign-up-${id}-input`}
        htmlId={`input-${id}`}
        htmlType={getHtmlType(id)}
        size="large"
        isRequired
        errorMessage={error}
        isError={!!error}
        label={label}
        placeholder={placeholder}
        value={value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleInputChange(e, setValue)
        }
        {...(id !== "password" && {
          onBlur: () => {
            trackInputField();
          },
        })}
        {...(id === "password" && {
          onFocus: () => setIsPasswordFocused(true),
          onBlur: () => {
            setIsPasswordFocused(false);
            trackInputField();
          },
        })}
        {...(id === "first-name" && {
          autoFocus: true,
        })}
      />
    );
  };

  return (
    <form onSubmit={isPage2 ? handleSignUpFormSubmit : handleSignUpContinue}>
      <Box mb={isPage2 ? 5 : 0}>
        {renderSignUpFormInput({
          error: emailInputError,
          id: "email",
          value: email,
          setValue: setEmail,
          ...(isPage2 && {
            label: "Work email",
          }),
        })}
      </Box>

      {isPage2 && (
        <>
          <Flex alignItems="flex-end" width="100%">
            <Box pr={rem(2)} flexGrow={1}>
              <Text
                mb={1}
                styleName="body-2-bold"
                styles={{ color: theme.color.gray800 }}
              >
                Name
              </Text>
              {renderSignUpFormInput({
                id: "first-name",
                value: nameFirst,
                setValue: setNameFirst,
              })}
            </Box>
            <Box pl={rem(2)} flexGrow={1}>
              {renderSignUpFormInput({
                id: "last-name",
                value: nameLast,
                setValue: setNameLast,
              })}
            </Box>
          </Flex>

          {renderSignUpFormInput({
            id: "company",
            label: "Company",
            value: company,
            setValue: setCompany,
          })}

          <Input
            name="hosted_sign_up"
            value={false}
            onChange={() => {}}
            styles={{
              display: "none",
            }}
          />

          {renderSignUpFormInput({
            id: "password",
            label: "Password",
            value: password,
            setValue: setPassword,
          })}

          <Box mt={-1} mb={-1}>
            <PasswordRules
              password={password}
              isPasswordFocused={isPasswordFocused}
            />
          </Box>

          <Flex mt={3} alignItems="center">
            <InputCheckbox
              groupName="opted_in_to_email_via_cloud"
              isSmall
              isGray
              mt={1}
              checked={isSubscribedNewsletter}
              handleChange={() =>
                setIsSubscribedNewsletter(!isSubscribedNewsletter)
              }
              id="subscribe-newsletter"
              label="Subscribe to newsletter"
              value={isSubscribedNewsletter}
            />
            <Tooltip ml={rem(3)} mt={rem(5)}>
              By checking this box, you agree to receive periodic product email
              updates. You can opt out or update your preferences at any time.
            </Tooltip>
          </Flex>
        </>
      )}

      {error && (
        <Notification
          testId="sign-up-error-notification"
          mt={2}
          type="error"
          message={error}
        />
      )}

      <Flex mt={isPage2 ? 4 : 2}>
        <Button2
          isLoading={isProcessing}
          size="large"
          styles={{
            minWidth: rem(240),
            backgroundColor: theme.color.sky600,
            "&:hover, &:focus, &:active": {
              backgroundColor: theme.color.sky700,
            },
            "&:focus-visible": {
              backgroundColor: theme.color.sky700,
              outlineWidth: theme.border.widthMediumShadowOnly,
              outlineStyle: "solid",
              outlineColor: theme.color.sky800,
            },
          }}
          {...(isPage2 && {
            testId: "sign-up-submit-button",
            htmlType: "submit",
            label: "Sign up for account",
          })}
          {...(!isPage2 && {
            testId: "sign-up-continue-button",
            htmlType: "button",
            label: "Continue",
            onClick: handleSignUpContinue,
          })}
        />
      </Flex>
    </form>
  );
};

export default SignUpForm;
