import {
  BANNER_HEIGHT,
  MAIN_NAV_MOBILE_HEIGHT,
  ORG_HEADER_HEIGHT,
} from "../../../utils/constants";
import { GetMainViewHeightProps } from "../MainNav.types";

export const getMainViewHeight = ({
  breakpoint,
  hasBanner,
  type,
}: GetMainViewHeightProps) => {
  if (breakpoint && ["sm", "md"].includes(breakpoint)) {
    return hasBanner
      ? `calc(
            100% -
            ${ORG_HEADER_HEIGHT} -
            ${BANNER_HEIGHT}
          )`
      : `calc(
            100% -
            ${ORG_HEADER_HEIGHT}
          )`;
  }

  return type === "main-nav" ? MAIN_NAV_MOBILE_HEIGHT : "auto";
};
