import * as React from "react";

import BaseAvatar, { AvatarSize } from "./BaseAvatar";

const AvatarExperimentShadow = ({
  size,
  type,
}: {
  size?: AvatarSize;
  type: "default" | "fill";
}) => {
  const thisType = type || "default";

  return thisType === "fill" ? (
    <BaseAvatar {...{ size }} viewBox={[0, 0, 24, 24]}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM11.6184 6.10431C11.8538 5.96523 12.1462 5.96523 12.3815 6.10431L18.2482 9.57097C18.4765 9.70591 18.6166 9.95142 18.6166 10.2167C18.6166 10.4819 18.4765 10.7274 18.2482 10.8624L12.3815 14.329C12.1462 14.4681 11.8538 14.4681 11.6184 14.329L5.75175 10.8624C5.5234 10.7274 5.3833 10.4819 5.3833 10.2167C5.3833 9.95142 5.5234 9.70591 5.75175 9.57097L11.6184 6.10431ZM7.60756 10.2167L12 12.8122L16.3924 10.2167L12 7.62115L7.60756 10.2167ZM5.4876 13.8684C5.69832 13.5118 6.15824 13.3936 6.51484 13.6043L12 16.8455L17.4851 13.6043C17.8417 13.3936 18.3016 13.5118 18.5123 13.8684C18.723 14.2251 18.6048 14.685 18.2482 14.8957L12.3815 18.3624C12.1462 18.5014 11.8538 18.5014 11.6184 18.3624L5.75175 14.8957C5.39514 14.685 5.27688 14.2251 5.4876 13.8684Z"
        fill="#AF7AA1"
      />
    </BaseAvatar>
  ) : (
    <BaseAvatar {...{ size }}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 45.67C35.968 45.67 45.67 35.968 45.67 24C45.67 12.032 35.968 2.33 24 2.33C12.032 2.33 2.33 12.032 2.33 24C2.33 35.968 12.032 45.67 24 45.67ZM24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48ZM10.3507 27.2356C10.7258 26.6008 11.5444 26.3903 12.1792 26.7654L24 33.7505L35.8209 26.7654C36.4556 26.3903 37.2743 26.6008 37.6493 27.2356C38.0244 27.8703 37.8139 28.689 37.1792 29.0641L24.6792 36.4504C24.2602 36.698 23.7398 36.698 23.3209 36.4504L10.8209 29.0641C10.1861 28.689 9.97558 27.8703 10.3507 27.2356ZM24.6791 11.8506C24.2602 11.6031 23.7398 11.6031 23.3208 11.8506L10.8208 19.237C10.4144 19.4772 10.165 19.9142 10.165 20.3863C10.165 20.8585 10.4144 21.2955 10.8208 21.5357L23.3208 28.922C23.7398 29.1696 24.2602 29.1696 24.6791 28.922L37.1791 21.5357C37.5856 21.2955 37.835 20.8585 37.835 20.3863C37.835 19.9142 37.5856 19.4772 37.1791 19.237L24.6791 11.8506ZM24 26.222L14.1242 20.3863L24 14.5506L33.8758 20.3863L24 26.222Z"
        fill="#AF7AA1"
      />
    </BaseAvatar>
  );
};

export default AvatarExperimentShadow;
