import { PageNavigationItem } from "../../../components/MainNav/MainNav.types";
import { GetMarketplaceAppPagesParams } from "../MarketplaceApp.types";

export const getMarketplaceAppPages = ({
  marketplaceAppId,
  partnerId,
  userId,
}: GetMarketplaceAppPagesParams): PageNavigationItem[] => {
  return [
    {
      id: "marketplace-app-details",
      label: "Details",
      url: `/acc/${userId}/marketplace/app/${partnerId}/${marketplaceAppId}`,
    },
    {
      id: "marketplace-app-api",
      label: "API Reference",
      url: `/acc/${userId}/marketplace/app/${partnerId}/${marketplaceAppId}/api-reference`,
    },
  ];
};
