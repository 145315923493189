import * as React from "react";

import BaseIcon, { IconProps } from "./BaseIcon";

const IconX = ({
  iconSize,
  iconColor,
  iconColorActive,
  iconColorFocus,
  iconColorHover,
}: IconProps) => {
  return (
    <BaseIcon
      {...{
        iconSize,
        iconColor,
        iconColorActive,
        iconColorFocus,
        iconColorHover,
      }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8092 5.13523C12.0689 4.87553 12.0689 4.45447 11.8092 4.19477C11.5495 3.93508 11.1285 3.93508 10.8688 4.19477L8.002 7.06155L5.13523 4.19477C4.87553 3.93508 4.45447 3.93508 4.19477 4.19477C3.93508 4.45447 3.93508 4.87553 4.19477 5.13523L7.06155 8.002L4.19477 10.8688C3.93508 11.1285 3.93508 11.5495 4.19477 11.8092C4.45447 12.0689 4.87553 12.0689 5.13523 11.8092L8.002 8.94245L10.8688 11.8092C11.1285 12.0689 11.5495 12.0689 11.8092 11.8092C12.0689 11.5495 12.0689 11.1285 11.8092 10.8688L8.94245 8.002L11.8092 5.13523Z"
      />
    </BaseIcon>
  );
};

export default IconX;
