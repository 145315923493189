import React from "react";
import { Box, Loading } from "@console/dsc";
import { useTheme } from "@emotion/react";

const LoadingListView = () => {
  const theme = useTheme();

  return (
    <Box mt={5}>
      <Loading dotColor={theme.color.orange500} />
    </Box>
  );
};

export default LoadingListView;
