import * as React from "react";
import styled from "@emotion/styled";
import {
  alignContent,
  alignItems,
  flexDirection,
  flexGrow,
  flexWrap,
  justifyContent,
} from "styled-system";

import Box from "../Box";

const FlexBox = styled(Box)`
  display: flex;
  ${flexDirection}
  ${justifyContent}
  ${alignItems}
  ${alignContent}
  ${flexWrap}
  ${flexGrow}
`;

const Flex = (props) => <FlexBox {...props} />;

export default Flex;
