import React, { useState } from "react";

import { getInputSets } from "../../../api/core/controlPlane";
import { InputSets } from "../../../api/core/controlPlane.types";
import { useUser } from "../../../AuthProvider";

const useInputSets = () => {
  const [{ id: accountId }] = useUser();
  const [inputSets, setInputSets] = useState<InputSets>(null);
  const [inputSetsError, setInputSetsError] = useState<string>("");

  const loadInputSets = React.useCallback(
    async (applicationId: string) => {
      try {
        const inputSets = (await getInputSets(accountId || "")(
          applicationId
        )) as InputSets;

        setInputSets(inputSets);
        setInputSetsError("");
        return inputSets;
      } catch (e: any) {
        console.error(e);
        setInputSetsError(e.toString());
      }
    },
    [accountId]
  );

  return {
    inputSets,
    inputSetsError,
    loadInputSets,
    setInputSets,
    setInputSetsError,
  };
};

export default useInputSets;
