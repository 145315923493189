import React from "react";
import { Button2, IconLightningFill } from "@console/dsc";
import { useTheme } from "@emotion/react";

import { useUser } from "../../AuthProvider";
import {
  PLAN_TYPE_INNOVATOR,
  PLAN_TYPE_LEGACY_BASIC,
  PLAN_TYPE_LEGACY_ENTERPRISE,
  PLAN_TYPE_LEGACY_PREMIUM,
  PLAN_TYPE_PREMIUM,
  PLAN_TYPE_SCALE_UP,
} from "../../config/plans";
import { isCurrentUserRoot } from "../../pages/Team/subpages/Members/utils/isCurrentUserRoot";
import { getCurrentPlanType } from "../../pages/Team/utils/planManagement";

const FreeTrialCta = () => {
  const [user] = useUser();
  const theme = useTheme();

  const currentPlanType = getCurrentPlanType(user);
  const isUserRoot = isCurrentUserRoot(user?.organizations, user.roles);

  if (
    !isUserRoot ||
    currentPlanType === PLAN_TYPE_INNOVATOR ||
    currentPlanType === PLAN_TYPE_SCALE_UP ||
    currentPlanType === PLAN_TYPE_PREMIUM ||
    currentPlanType === PLAN_TYPE_LEGACY_ENTERPRISE ||
    currentPlanType === PLAN_TYPE_LEGACY_BASIC ||
    currentPlanType === PLAN_TYPE_LEGACY_PREMIUM
  ) {
    return null;
  }
  return (
    <Button2
      htmlType="button"
      type="text"
      icon={<IconLightningFill iconColor={theme.color.white} iconSize={12} />}
      icon2={<IconLightningFill iconColor={theme.color.white} iconSize={12} />}
      label="Upgrade"
      to={`/acc/${user.id}/team/plan`}
      styles={{
        color: theme.color.gray100,
        path: {
          opacity: 0.8,
        },
        "&:hover, &:active, &:focus": {
          color: theme.color.white,
        },
        "&:hover svg, &:active svg, &:focus svg": {
          fill: theme.color.white,
        },
        "&:hover path, &:active path, &:focus path": {
          opacity: 1,
        },
        "&:focus-visible": {
          outline: "none",
          boxShadow: `0 0 0 ${theme.border.widthMediumShadowOnly} ${theme.color.white}`,
        },
        "&:focus-visible svg": {
          fill: theme.color.white,
        },
        "&:focus-visible svg path": {
          opacity: 1,
        },
      }}
    />
  );
};

export default FreeTrialCta;
