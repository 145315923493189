import * as React from "react";

import BaseIcon, { IconProps } from "./BaseIcon";

const IconCube = ({
  iconSize,
  iconColor,
  iconColorActive,
  iconColorFocus,
  iconColorHover,
  ...rest
}: IconProps) => {
  return (
    <BaseIcon
      {...{
        iconSize,
        iconColor,
        iconColorActive,
        iconColorFocus,
        iconColorHover,
      }}
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.71879 0.730723C7.89705 0.647539 8.10298 0.647539 8.28123 0.730723L14.6812 3.71739C14.9154 3.82665 15.065 4.06163 15.065 4.32V11.68C15.065 11.9384 14.9154 12.1734 14.6812 12.2826L8.28123 15.2693C8.10298 15.3525 7.89705 15.3525 7.71879 15.2693L1.31879 12.2826C1.08466 12.1734 0.935013 11.9384 0.935013 11.68V4.32C0.935013 4.06163 1.08466 3.82665 1.31879 3.71739L7.71879 0.730723ZM2.26501 5.32519L7.33501 7.47994V13.6225L2.26501 11.2565V5.32519ZM8.66501 13.6225L13.735 11.2565V5.32519L8.66501 7.47994V13.6225ZM8.00001 6.31744L12.7667 4.29161L8.00001 2.06718L3.23337 4.29161L8.00001 6.31744Z"
      />
    </BaseIcon>
  );
};

export default IconCube;
