import React from "react";
import { Text } from "@console/dsc";
import { useTheme } from "@emotion/react";

type SectionTitleProps = {
  children: React.ReactNode;
  as?: keyof JSX.IntrinsicElements | React.ComponentType<any>;
  isShortenTitle?: boolean;
  testId?: string;
};

const SectionTitle = ({
  as,
  children,
  isShortenTitle,
  testId,
}: SectionTitleProps) => {
  const theme = useTheme();
  return (
    <Text
      as={as || "h1"}
      styleName="header-1"
      {...{ testId }}
      styles={{
        color: theme.color.gray800,
        overflowWrap: "anywhere",
        textRendering: "optimizeLegibility",
        WebkitFontSmoothing: "antialiased",
        ...(isShortenTitle && {
          maxWidth: "300px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }),
      }}
    >
      {children}
    </Text>
  );
};

export default SectionTitle;
