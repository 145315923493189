// function to get Integration avatar by id

import { AvatarHere, AvatarOnfleet, AvatarStreets } from "@console/dsc";
import { AvatarSize } from "@console/dsc/src/avatars/BaseAvatar";

import { IntegrationKind } from "../../../api/core/controlPlane.types";

export const getIntegrationAvatar = (
  id: IntegrationKind,
  size?: AvatarSize
) => {
  switch (id) {
    case "here":
      return <AvatarHere {...{ size }} />;
    case "onfleet":
      return <AvatarOnfleet {...{ size }} />;
    case "streets":
      return <AvatarStreets {...{ size }} />;
    default:
      return null;
  }
};
