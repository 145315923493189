import {
  UserOrganization,
  UserOrganizations,
  UserRoles,
} from "../../../../../api/core/dataPlane.types";

export const getRootOrg = (
  usersOrgs: UserOrganizations = []
): UserOrganization | undefined => {
  for (const org of usersOrgs) {
    if (org.role === "root") {
      return org;
    }
  }
  return undefined;
};

export const isCurrentUserRoot = (
  usersOrgs: UserOrganizations = [],
  userRoles: UserRoles = []
) => {
  if (!usersOrgs.length) return true;
  return userRoles.includes("root");
};

// Check for any administrative role.
export const isCurrentUserAdmin = (
  usersOrgs: UserOrganizations = [],
  userRoles: UserRoles = []
) => {
  if (!usersOrgs.length) return true;
  return userRoles.includes("admin") || userRoles.includes("root");
};
