import { useState } from "react";

import { UserOrganization, UserWithOrgs } from "./api/core/dataPlane.types";

// Use this for sorting user organizations by name. Root roles come first. All
// other roles are lumped together.
export function compareOrgs(o1: UserOrganization, o2: UserOrganization) {
  if (o1.role === "root" && o2.role !== "root") {
    return -1;
  }
  if (o1.role !== "root" && o2.role === "root") {
    return 1;
  }
  return o1.name.localeCompare(o2.name);
}

// https://usehooks.com/useLocalStorage/
export function useLocalStorage(key: string, initialValue: any) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.error(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: any) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  return [storedValue, setValue];
}

export function useIsNewUser() {
  const [isNewUser, setIsNewUser] = useLocalStorage("isNewUser", false);
  return [isNewUser, setIsNewUser];
}

// see web/cypress/fixtures/users.json
const integrationTestEmails = [
  "stephen+e2etest@finnovate.io",
  "stephen+e2etestsubscriber@finnovate.io",
  "davidchan@finnovate.io",
  "stephen+e2etestsubscriber@finnovate.io",
];
export const isExistingUserGuess = (user: UserWithOrgs) => {
  // exclude integration tests
  if (user?.email && integrationTestEmails.includes(user.email)) {
    return false;
  }
  if (
    (user?.decisionCount?.current && user.decisionCount.current > 0) ||
    (user.organizations && user.organizations.length > 1) ||
    (user.planInfo?.trialStatus &&
      ["in-progress", "completed"].includes(user.planInfo.trialStatus)) ||
    user.features?.publicruns
  ) {
    return true;
  }
  return false;
};

export function useStoredPromoCode() {
  const [storedPromoCode, setStoredPromoCode] = useLocalStorage(
    "storedPromoCode",
    ""
  );
  return [storedPromoCode, setStoredPromoCode];
}

export function useTourNavLocalStorage() {
  const [tourNavLocalStorage, setTourNavLocalStorage] = useLocalStorage(
    "tourNavLocalStorage",
    {}
  );
  return [tourNavLocalStorage, setTourNavLocalStorage];
}
