import { useState } from "react";

import { AppResponse } from "../../../../../api/core/controlPlane.types";
import { AcceptedFiles } from "../../../../../components/UploadFile/UploadFile.types";
import { INPUT_SET_MAX_RUN_IDS } from "../../../../../config/experiments";
import {
  createOptions,
  defaultRunDateOption,
} from "../../../../Experiments/components/utils";
import {
  InputSetCreateTypeOption,
  InputSetDateRangeOption,
} from "../../../Experiments.types";
import { parseRunIdsNewLine } from "../utils/utils";

const useNewInputSet = (app: AppResponse) => {
  const [acceptedFiles, setAcceptedFiles] = useState<AcceptedFiles>(null);
  // TODO: refactor to pull out this information into individual items
  // then we can just add them to payload conditionally
  // as opposed to having to call utils/modifyCreateInputSetPayload
  const [pendingInputSetCriteria, setPendingInputSetCriteria] = useState({
    run_ids: [],
    start_time: defaultRunDateOption.value,
    maximum_runs: INPUT_SET_MAX_RUN_IDS,
  });
  const [pendingInputSetInstanceIds, setPendingInputSetInstanceIds] = useState([
    app.default_instance,
  ]);
  const [pendingInputSetInputIds, setPendingInputSetInputIds] = useState<
    (string | undefined)[]
  >([""]);

  const [createTypeSelected, setCreateTypeSelected] =
    useState<InputSetCreateTypeOption>(createOptions[0]);
  const [dateRangeSelected, setDateRangeSelected] =
    useState<InputSetDateRangeOption>(defaultRunDateOption);
  const [runIdsString, setRunIdsString] = useState<string>("");

  const convertRunIdsStringToArray = (runIds: string) => {
    return parseRunIdsNewLine(runIds)
      .split("\n")
      .filter((e: string) => e.trim().length > 0);
  };

  const handleInputSetRunIdsChange = <T extends "run_ids">({
    target: { name, value },
  }: {
    target: { name: T; value: string };
  }) => {
    setRunIdsString(parseRunIdsNewLine(value));
    setPendingInputSetCriteria((prevState) => {
      return {
        ...prevState,
        [name]: convertRunIdsStringToArray(value),
      };
    });
  };

  const handleCreateTypeClick = (createType: InputSetCreateTypeOption) => {
    if (createType) {
      setCreateTypeSelected(createType);
    }
  };

  const handleDateSelect = (dateSelected: InputSetDateRangeOption) => {
    if (dateSelected) {
      setDateRangeSelected(dateSelected);
      setPendingInputSetCriteria((prevState) => {
        return {
          ...prevState,
          start_time: dateSelected.value,
        };
      });
    }
  };

  return {
    acceptedFiles,
    createTypeSelected,
    dateRangeSelected,
    handleCreateTypeClick,
    handleDateSelect,
    handleInputSetRunIdsChange,
    pendingInputSetCriteria,
    pendingInputSetInputIds,
    pendingInputSetInstanceIds,
    runIdsString,
    setAcceptedFiles,
    setCreateTypeSelected,
    setPendingInputSetInputIds,
    setPendingInputSetInstanceIds,
    setRunIdsString,
  };
};

export default useNewInputSet;
