import * as React from "react";
import { useTheme } from "@emotion/react";

import Text from "../Text";

import {
  InputContainer,
  PlaceIcon,
  StyledInput,
  StyledTextArea,
} from "./Input.styles";
import { InputProps, RenderInputParams } from "./Input.types";

const renderInput = ({
  datePicker,
  errorMessage,
  errorMessageTestId,
  hasIcon,
  hasLabel,
  htmlId,
  htmlSize,
  htmlType,
  icon,
  iconPosition,
  inputSize,
  isDisabled,
  isError,
  isRequired,
  isTextArea,
  label,
  placeholder,
  rest,
  styles,
  testId,
  theme,
  value,
  m,
  mx,
  my,
  mt,
  mr,
  mb,
  ml,
}: RenderInputParams) => {
  return (
    <InputContainer {...{ m, mx, my, mt, mr, mb, ml }}>
      {datePicker}
      {label && (
        <Text mb={1} styleName="body-2-bold">
          {label}
        </Text>
      )}
      {isTextArea ? (
        <StyledTextArea
          {...{
            inputSize,
            isError,
            placeholder,
            value,
            styles,
            hasLabel,
          }}
          disabled={isDisabled}
          id={htmlId}
          isActive={!!value}
          required={isRequired}
          size={htmlSize}
          type={htmlType}
          {...(testId && {
            "data-testid": testId,
          })}
          {...rest}
        />
      ) : (
        <StyledInput
          {...{
            hasIcon,
            inputSize,
            isError,
            placeholder,
            value,
            styles,
            hasLabel,
          }}
          disabled={isDisabled}
          iconPosition={iconPosition || (hasIcon ? "right" : undefined)}
          id={htmlId}
          isActive={!!value}
          required={isRequired}
          size={htmlSize}
          type={htmlType}
          {...(testId && {
            "data-testid": testId,
          })}
          {...(!!datePicker && {
            autoComplete: "off",
          })}
          {...rest}
        />
      )}

      {isError && errorMessage && (
        <Text
          mt={1}
          ml={1}
          styleName={inputSize === "large" ? "body-3" : "meta-1"}
          styles={{
            color: theme.color.red600,
          }}
          data-testid={errorMessageTestId}
        >
          {errorMessage}
        </Text>
      )}

      {icon && (
        <PlaceIcon iconPosition={iconPosition || "right"} size={inputSize}>
          {icon}
        </PlaceIcon>
      )}
    </InputContainer>
  );
};

const Input = ({
  datePicker,
  errorMessage,
  errorMessageTestId,
  htmlId,
  htmlSize,
  htmlType,
  icon,
  iconPosition,
  isError,
  isDisabled,
  isRequired,
  isTextArea,
  label,
  placeholder,
  styles,
  size,
  testId,
  value,
  m,
  mx,
  my,
  mt,
  mr,
  mb,
  ml,
  ...rest
}: InputProps) => {
  const theme = useTheme();
  const hasIcon = !!icon;
  const hasLabel = !!label;
  const inputSize = size || "default";

  const inputRendered = renderInput({
    datePicker,
    errorMessage,
    errorMessageTestId,
    hasIcon,
    hasLabel,
    htmlId,
    htmlSize,
    htmlType,
    icon,
    iconPosition,
    inputSize,
    isDisabled,
    isError,
    isRequired,
    isTextArea,
    label,
    placeholder,
    rest,
    styles,
    testId,
    theme,
    value,
    m,
    mx,
    my,
    mt,
    mr,
    mb,
    ml,
  });

  return inputRendered;
};

export default Input;
