import * as React from "react";
import { components } from "react-select";
import { useTheme } from "@emotion/react";

import { IconX } from "../../../icons";

const { ClearIndicator } = components;

const CustomClearIndicator = (props: any) => {
  const theme = useTheme();

  const { isDisabled, isFocused } = props;

  const getIconColor = () => {
    if (isDisabled) return theme.color.gray400;
    if (isFocused) return theme.color.gray600;
    return theme.color.gray500;
  };

  const iconColor = getIconColor();

  return (
    <ClearIndicator {...props}>
      <IconX {...{ iconColor }} iconSize={16} />
    </ClearIndicator>
  );
};

export default CustomClearIndicator;
