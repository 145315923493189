import { useState } from "react";
import { isEmpty } from "lodash";

import { CompatibilityConfiguration } from "../../../../../api/core/controlPlane.types";
import { CompatibilityValueObjectType, CompatibilityValues } from "../types";
import {
  getInitialValues,
  isAllToggleActive,
  updateAllCompatibilityValues,
} from "../utils/featureCompatibility";

type UseFeatureCompatibilityProps = {
  compatibility?: CompatibilityConfiguration;
  onCompatibilityValueChange?: (newValues: CompatibilityValues) => void;
};

export default function useFeatureCompatibility({
  compatibility = {},
  onCompatibilityValueChange,
}: UseFeatureCompatibilityProps) {
  const [compatibilityValues, setCompatibilityValues] =
    useState<CompatibilityValues>(getInitialValues(compatibility));
  const [isAllToggled, setIsAllToggled] = useState(
    (Object.values(compatibilityValues.tasks).every((value) => value) &&
      Object.values(compatibilityValues.workers).every((value) => value)) ||
      isEmpty(compatibility)
  );

  const handleSetCompatibilityValues = (newValues: CompatibilityValues) => {
    setCompatibilityValues(newValues);
    onCompatibilityValueChange && onCompatibilityValueChange(newValues);
  };

  const handleToggleAll = () => {
    const updatedCompatibilityValues = !isAllToggled
      ? updateAllCompatibilityValues(true)
      : updateAllCompatibilityValues(false);
    handleSetCompatibilityValues(updatedCompatibilityValues);
    setIsAllToggled(!isAllToggled);
  };

  const handleCheckboxClick = (
    type: CompatibilityValueObjectType,
    id: string
  ) => {
    const newConfigurationValues = {
      ...compatibilityValues,
      [type]: {
        ...compatibilityValues[type],
        [id]: !compatibilityValues[type][id],
      },
    };

    setIsAllToggled(isAllToggleActive(newConfigurationValues, isAllToggled));
    handleSetCompatibilityValues(newConfigurationValues);
  };

  return {
    compatibilityValues,
    handleToggleAll,
    handleCheckboxClick,
    isAllToggled,
  };
}
