export function getDateLabelFromYearMonthKey(yearMonthKey: string): string {
  const splitKey = yearMonthKey.split("-");
  // Consider throwing error here if length is not 2? Should never happen anyway.
  const year: number = parseInt(splitKey[0]);
  const month: number = parseInt(splitKey[1]);

  let dateLabel = "";

  const date = new Date();
  date.setMonth(month - 1);
  dateLabel += date.toLocaleString([], {
    month: "long",
  });

  dateLabel += ` ${year}`;

  return dateLabel;
}
