import styled from "@emotion/styled";

import Flex from "../Flex";

import { getBorderWidth } from "./utils/presets";
import { StyledNotificationFlexProps } from "./Notification.types";

export const StyledNotificationFlex = styled(Flex)<StyledNotificationFlexProps>`
  display: inline-flex;
  border-radius: ${({ theme }) => theme.border.radiusSmall};
  background-color: ${(props) => props.backgroundColor};

  ${(props) =>
    `box-shadow: inset 0 0 0 ${getBorderWidth({
      size: props.size,
      theme: props.theme,
    })} ${props.borderColor};`}
`;
