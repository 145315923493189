import * as React from "react";

import BaseIcon, { IconProps } from "./BaseIcon";

const IconRefresh = ({
  iconSize,
  iconColor,
  iconColorActive,
  iconColorFocus,
  iconColorHover,
  ...rest
}: IconProps) => {
  return (
    <BaseIcon
      {...{
        iconSize,
        iconColor,
        iconColorActive,
        iconColorFocus,
        iconColorHover,
      }}
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.87 7.82278C2.87 10.4216 4.75071 12.1938 6.8734 12.5614C7.24617 12.6259 7.49603 12.9804 7.43149 13.3532C7.36694 13.726 7.01243 13.9758 6.63966 13.9113C3.94768 13.4452 1.5 11.173 1.5 7.82278C1.5 6.37828 2.15844 5.24982 2.90916 4.40509C3.27176 3.99707 3.67029 3.64059 4.04022 3.33854H3.21223C2.84496 3.33854 2.54723 3.04081 2.54723 2.67354C2.54723 2.30628 2.84496 2.00854 3.21223 2.00854H5.89192C6.25919 2.00854 6.55692 2.30628 6.55692 2.67354L6.55692 5.35323C6.55692 5.7205 6.25919 6.01823 5.89192 6.01823C5.52465 6.01823 5.22692 5.7205 5.22692 5.35323V4.14653C4.8023 4.47414 4.34357 4.8534 3.9332 5.31516C3.31642 6.00918 2.87 6.82705 2.87 7.82278ZM12.8528 8.1858C12.8528 5.61431 11.012 3.85269 8.91662 3.45932C8.5448 3.38952 8.29997 3.03151 8.36977 2.65969C8.43957 2.28787 8.79758 2.04304 9.1694 2.11284C11.8268 2.61173 14.2228 4.8716 14.2228 8.1858C14.2228 9.63029 13.5643 10.7587 12.8136 11.6035C12.451 12.0115 12.0525 12.368 11.6826 12.67H12.5105C12.8778 12.67 13.1755 12.9678 13.1755 13.335C13.1755 13.7023 12.8778 14 12.5105 14L9.83086 14C9.46359 14 9.16586 13.7023 9.16586 13.335V10.6553C9.16586 10.2881 9.46359 9.99034 9.83086 9.99034C10.1981 9.99034 10.4959 10.2881 10.4959 10.6553V11.862C10.9205 11.5344 11.3792 11.1552 11.7896 10.6934C12.4064 9.99939 12.8528 9.18153 12.8528 8.1858Z"
      />
    </BaseIcon>
  );
};

export default IconRefresh;
