import React from "react";

import useManageEntities from "../../hooks/useManageEntities";
import useManageEntity from "../../hooks/useManageEntity";

import { AppContextProps } from "./App.context.types";

export const AppContext = React.createContext<AppContextProps>({
  additionalAppInputsLoading: false,
  additionalInstancesLoading: false,
  additionalRunsLoading: true,
  additionalVersionsLoading: false,
  app: null,
  appLoadError: null,
  appInputs: [],
  appInputsLoadError: null,
  appInputsNextPageToken: "",
  editApp: () => new Promise((resolve) => resolve({})),
  instances: [],
  instancesError: null,
  instancesNextPageToken: "",
  loadApp: (id) => new Promise((resolve) => resolve({})),
  loadRuns: () => new Promise((resolve) => resolve([])),
  loadAppInputs: () => new Promise((resolve) => resolve([])),
  loadInstances: () => new Promise((resolve) => resolve([])),
  loadVersions: () => new Promise((resolve) => resolve([])),
  runHistory: [],
  runsLoadError: null,
  runsNextPageToken: "",
  setAppInputs: () => null,
  setRunHistory: () => null,
  setRunsLoadError: () => null,
  setAdditionalAppInputsLoading: () => null,
  setAdditionalInstancesLoading: () => null,
  setAdditionalRunsLoading: () => null,
  setAdditionalVersionsLoading: () => null,
  setVersions: () => null,
  setVersionsError: () => null,
  setInstances: () => null,
  setInstancesError: () => null,
  versions: [],
  versionsError: null,
  versionsNextPageToken: "",
});

const AppProvider = ({ children }: { children: React.ReactNode }) => {
  const {
    editEntity: editApp,
    entity: app,
    entityLoadError: appLoadError,
    loadEntity: loadApp,
  } = useManageEntity("applications");

  const {
    additionalEntitiesLoading: additionalAppInputsLoading,
    entities: appInputs,
    entitiesLoadError: appInputsLoadError,
    entitiesNextPageToken: appInputsNextPageToken,
    loadEntities: loadAppInputs,
    setAdditionalEntitiesLoading: setAdditionalAppInputsLoading,
    setEntities: setAppInputs,
  } = useManageEntities("inputs");

  const {
    additionalEntitiesLoading: additionalRunsLoading,
    entities: runHistory,
    entitiesLoadError: runsLoadError,
    entitiesNextPageToken: runsNextPageToken,
    loadEntities: loadRuns,
    setAdditionalEntitiesLoading: setAdditionalRunsLoading,
    setEntities: setRunHistory,
    setEntitiesLoadError: setRunsLoadError,
  } = useManageEntities("runs");

  const {
    additionalEntitiesLoading: additionalVersionsLoading,
    entities: versions,
    entitiesLoadError: versionsError,
    entitiesNextPageToken: versionsNextPageToken,
    loadEntities: loadVersions,
    setAdditionalEntitiesLoading: setAdditionalVersionsLoading,
    setEntities: setVersions,
    setEntitiesLoadError: setVersionsError,
  } = useManageEntities("versions");

  const {
    additionalEntitiesLoading: additionalInstancesLoading,
    entities: instances,
    entitiesLoadError: instancesError,
    entitiesNextPageToken: instancesNextPageToken,
    loadEntities: loadInstances,
    setAdditionalEntitiesLoading: setAdditionalInstancesLoading,
    setEntities: setInstances,
    setEntitiesLoadError: setInstancesError,
  } = useManageEntities("instances");

  const value: AppContextProps = {
    additionalAppInputsLoading,
    additionalInstancesLoading,
    additionalRunsLoading,
    additionalVersionsLoading,
    app,
    appLoadError,
    appInputs,
    appInputsLoadError,
    appInputsNextPageToken,
    editApp,
    instances,
    instancesError,
    instancesNextPageToken,
    loadApp,
    loadAppInputs,
    loadRuns,
    loadVersions,
    loadInstances,
    runHistory,
    runsLoadError,
    runsNextPageToken,
    setAdditionalAppInputsLoading,
    setAdditionalInstancesLoading,
    setAdditionalVersionsLoading,
    setAppInputs,
    setRunHistory,
    setRunsLoadError,
    setAdditionalRunsLoading,
    setVersions,
    setVersionsError,
    setInstances,
    setInstancesError,
    versions,
    versionsError,
    versionsNextPageToken,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useAppCollection = () => React.useContext(AppContext);

export default AppProvider;
