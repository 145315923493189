export const sampleInputNextrouteDistributionExampleDay1 = {
  defaults: {
    stops: {
      unplanned_penalty: 2000000000,
    },
    vehicles: {
      capacity: 350,
      end_location: {
        lat: 39.965767,
        lon: -76.32231,
      },
      speed: 20,
      start_location: {
        lat: 39.965767,
        lon: -76.32231,
      },
    },
  },
  stops: [
    {
      id: "location-1",
      location: {
        lat: 39.965767,
        lon: -76.28672,
      },
      max_wait: 600,
      quantity: -21,
      start_time_window: [
        "2024-04-15T13:00:00-05:00",
        "2024-04-15T20:00:00-05:00",
      ],
    },
    {
      id: "location-2",
      location: {
        lat: 40.012245,
        lon: -76.2498,
      },
      max_wait: 600,
      quantity: -26,
      start_time_window: [
        "2024-04-15T13:00:00-05:00",
        "2024-04-15T20:00:00-05:00",
      ],
    },
    {
      id: "location-3",
      location: {
        lat: 40.021126,
        lon: -76.34167,
      },
      max_wait: 600,
      quantity: -25,
      start_time_window: [
        "2024-04-15T13:00:00-05:00",
        "2024-04-15T20:00:00-05:00",
      ],
    },
    {
      id: "location-4",
      location: {
        lat: 39.95724,
        lon: -76.372086,
      },
      max_wait: 600,
      quantity: -26,
      start_time_window: [
        "2024-04-15T13:00:00-05:00",
        "2024-04-15T20:00:00-05:00",
      ],
    },
    {
      id: "location-5",
      location: {
        lat: 39.92587,
        lon: -76.356895,
      },
      max_wait: 600,
      quantity: -22,
      start_time_window: [
        "2024-04-15T13:00:00-05:00",
        "2024-04-15T20:00:00-05:00",
      ],
    },
    {
      id: "location-6",
      location: {
        lat: 39.981304,
        lon: -76.24516,
      },
      max_wait: 600,
      quantity: -27,
      start_time_window: [
        "2024-04-15T13:00:00-05:00",
        "2024-04-15T20:00:00-05:00",
      ],
    },
    {
      id: "location-7",
      location: {
        lat: 40.025013,
        lon: -76.379715,
      },
      max_wait: 600,
      quantity: -16,
      start_time_window: [
        "2024-04-15T13:00:00-05:00",
        "2024-04-15T20:00:00-05:00",
      ],
    },
    {
      id: "location-8",
      location: {
        lat: 40.042927,
        lon: -76.24701,
      },
      max_wait: 600,
      quantity: -27,
      start_time_window: [
        "2024-04-15T04:00:00-05:00",
        "2024-04-15T13:00:00-05:00",
      ],
    },
    {
      id: "location-9",
      location: {
        lat: 39.967373,
        lon: -76.370094,
      },
      max_wait: 600,
      quantity: -17,
      start_time_window: [
        "2024-04-15T13:00:00-05:00",
        "2024-04-15T20:00:00-05:00",
      ],
    },
    {
      id: "location-10",
      location: {
        lat: 40.005,
        lon: -76.38189,
      },
      max_wait: 600,
      quantity: -20,
      start_time_window: [
        "2024-04-15T04:00:00-05:00",
        "2024-04-15T13:00:00-05:00",
      ],
    },
    {
      id: "location-11",
      location: {
        lat: 40.014168,
        lon: -76.39379,
      },
      max_wait: 600,
      quantity: -17,
      start_time_window: [
        "2024-04-15T13:00:00-05:00",
        "2024-04-15T20:00:00-05:00",
      ],
    },
    {
      id: "location-12",
      location: {
        lat: 39.968727,
        lon: -76.34551,
      },
      max_wait: 600,
      quantity: -23,
      start_time_window: [
        "2024-04-15T04:00:00-05:00",
        "2024-04-15T13:00:00-05:00",
      ],
    },
    {
      id: "location-13",
      location: {
        lat: 39.96622,
        lon: -76.319016,
      },
      max_wait: 600,
      quantity: -17,
      start_time_window: [
        "2024-04-15T04:00:00-05:00",
        "2024-04-15T13:00:00-05:00",
      ],
    },
    {
      id: "location-14",
      location: {
        lat: 39.97821,
        lon: -76.33169,
      },
      max_wait: 600,
      quantity: -21,
      start_time_window: [
        "2024-04-15T04:00:00-05:00",
        "2024-04-15T13:00:00-05:00",
      ],
    },
    {
      id: "location-15",
      location: {
        lat: 40.000797,
        lon: -76.26427,
      },
      max_wait: 600,
      quantity: -29,
      start_time_window: [
        "2024-04-15T13:00:00-05:00",
        "2024-04-15T20:00:00-05:00",
      ],
    },
    {
      id: "location-16",
      location: {
        lat: 39.977283,
        lon: -76.290436,
      },
      max_wait: 600,
      quantity: -25,
      start_time_window: [
        "2024-04-15T04:00:00-05:00",
        "2024-04-15T13:00:00-05:00",
      ],
    },
    {
      id: "location-17",
      location: {
        lat: 40.014748,
        lon: -76.37462,
      },
      max_wait: 600,
      quantity: -25,
      start_time_window: [
        "2024-04-15T13:00:00-05:00",
        "2024-04-15T20:00:00-05:00",
      ],
    },
    {
      id: "location-18",
      location: {
        lat: 39.97252,
        lon: -76.31148,
      },
      max_wait: 600,
      quantity: -17,
      start_time_window: [
        "2024-04-15T04:00:00-05:00",
        "2024-04-15T13:00:00-05:00",
      ],
    },
    {
      id: "location-19",
      location: {
        lat: 39.949223,
        lon: -76.32142,
      },
      max_wait: 600,
      quantity: -19,
      start_time_window: [
        "2024-04-15T04:00:00-05:00",
        "2024-04-15T13:00:00-05:00",
      ],
    },
    {
      id: "location-20",
      location: {
        lat: 39.98177,
        lon: -76.31184,
      },
      max_wait: 600,
      quantity: -19,
      start_time_window: [
        "2024-04-15T13:00:00-05:00",
        "2024-04-15T20:00:00-05:00",
      ],
    },
    {
      id: "location-21",
      location: {
        lat: 39.846313,
        lon: -76.39604,
      },
      max_wait: 600,
      quantity: -22,
      start_time_window: [
        "2024-04-15T04:00:00-05:00",
        "2024-04-15T13:00:00-05:00",
      ],
    },
    {
      id: "location-22",
      location: {
        lat: 39.992382,
        lon: -76.301544,
      },
      max_wait: 600,
      quantity: -18,
      start_time_window: [
        "2024-04-15T04:00:00-05:00",
        "2024-04-15T13:00:00-05:00",
      ],
    },
    {
      id: "location-23",
      location: {
        lat: 39.953487,
        lon: -76.42643,
      },
      max_wait: 600,
      quantity: -15,
      start_time_window: [
        "2024-04-15T04:00:00-05:00",
        "2024-04-15T13:00:00-05:00",
      ],
    },
    {
      id: "location-24",
      location: {
        lat: 39.92808,
        lon: -76.389915,
      },
      max_wait: 600,
      quantity: -26,
      start_time_window: [
        "2024-04-15T13:00:00-05:00",
        "2024-04-15T20:00:00-05:00",
      ],
    },
    {
      id: "location-25",
      location: {
        lat: 39.939266,
        lon: -76.28334,
      },
      max_wait: 600,
      quantity: -29,
      start_time_window: [
        "2024-04-15T13:00:00-05:00",
        "2024-04-15T20:00:00-05:00",
      ],
    },
    {
      id: "location-26",
      location: {
        lat: 39.958813,
        lon: -76.399086,
      },
      max_wait: 600,
      quantity: -22,
      start_time_window: [
        "2024-04-15T13:00:00-05:00",
        "2024-04-15T20:00:00-05:00",
      ],
    },
    {
      id: "location-27",
      location: {
        lat: 40.010647,
        lon: -76.32155,
      },
      max_wait: 600,
      quantity: -27,
      start_time_window: [
        "2024-04-15T13:00:00-05:00",
        "2024-04-15T20:00:00-05:00",
      ],
    },
    {
      id: "location-28",
      location: {
        lat: 39.878006,
        lon: -76.44497,
      },
      max_wait: 600,
      quantity: -15,
      start_time_window: [
        "2024-04-15T04:00:00-05:00",
        "2024-04-15T13:00:00-05:00",
      ],
    },
    {
      id: "location-29",
      location: {
        lat: 40.01479,
        lon: -76.28321,
      },
      max_wait: 600,
      quantity: -23,
      start_time_window: [
        "2024-04-15T04:00:00-05:00",
        "2024-04-15T13:00:00-05:00",
      ],
    },
    {
      id: "location-30",
      location: {
        lat: 39.946285,
        lon: -76.40335,
      },
      max_wait: 600,
      quantity: -21,
      start_time_window: [
        "2024-04-15T04:00:00-05:00",
        "2024-04-15T13:00:00-05:00",
      ],
    },
    {
      id: "location-31",
      location: {
        lat: 39.91757,
        lon: -76.37118,
      },
      max_wait: 600,
      quantity: -15,
      start_time_window: [
        "2024-04-15T04:00:00-05:00",
        "2024-04-15T13:00:00-05:00",
      ],
    },
    {
      id: "location-32",
      location: {
        lat: 39.999092,
        lon: -76.46219,
      },
      max_wait: 600,
      quantity: -29,
      start_time_window: [
        "2024-04-15T13:00:00-05:00",
        "2024-04-15T20:00:00-05:00",
      ],
    },
    {
      id: "location-33",
      location: {
        lat: 39.996685,
        lon: -76.31311,
      },
      max_wait: 600,
      quantity: -27,
      start_time_window: [
        "2024-04-15T04:00:00-05:00",
        "2024-04-15T13:00:00-05:00",
      ],
    },
    {
      id: "location-34",
      location: {
        lat: 39.89537,
        lon: -76.34195,
      },
      max_wait: 600,
      quantity: -24,
      start_time_window: [
        "2024-04-15T04:00:00-05:00",
        "2024-04-15T13:00:00-05:00",
      ],
    },
    {
      id: "location-35",
      location: {
        lat: 39.874516,
        lon: -76.33112,
      },
      max_wait: 600,
      quantity: -23,
      start_time_window: [
        "2024-04-15T13:00:00-05:00",
        "2024-04-15T20:00:00-05:00",
      ],
    },
    {
      id: "location-36",
      location: {
        lat: 39.86111,
        lon: -76.37394,
      },
      max_wait: 600,
      quantity: -17,
      start_time_window: [
        "2024-04-15T04:00:00-05:00",
        "2024-04-15T13:00:00-05:00",
      ],
    },
    {
      id: "location-37",
      location: {
        lat: 39.950462,
        lon: -76.36758,
      },
      max_wait: 600,
      quantity: -22,
      start_time_window: [
        "2024-04-15T04:00:00-05:00",
        "2024-04-15T13:00:00-05:00",
      ],
    },
    {
      id: "location-38",
      location: {
        lat: 40.015007,
        lon: -76.213974,
      },
      max_wait: 600,
      quantity: -29,
      start_time_window: [
        "2024-04-15T04:00:00-05:00",
        "2024-04-15T13:00:00-05:00",
      ],
    },
    {
      id: "location-39",
      location: {
        lat: 39.882103,
        lon: -76.19916,
      },
      max_wait: 600,
      quantity: -17,
      start_time_window: [
        "2024-04-15T04:00:00-05:00",
        "2024-04-15T13:00:00-05:00",
      ],
    },
    {
      id: "location-40",
      location: {
        lat: 39.9248,
        lon: -76.42568,
      },
      max_wait: 600,
      quantity: -16,
      start_time_window: [
        "2024-04-15T13:00:00-05:00",
        "2024-04-15T20:00:00-05:00",
      ],
    },
    {
      id: "location-41",
      location: {
        lat: 40.01715,
        lon: -76.27129,
      },
      max_wait: 600,
      quantity: -27,
      start_time_window: [
        "2024-04-15T13:00:00-05:00",
        "2024-04-15T20:00:00-05:00",
      ],
    },
    {
      id: "location-42",
      location: {
        lat: 39.94763,
        lon: -76.26599,
      },
      max_wait: 600,
      quantity: -27,
      start_time_window: [
        "2024-04-15T04:00:00-05:00",
        "2024-04-15T13:00:00-05:00",
      ],
    },
    {
      id: "location-43",
      location: {
        lat: 39.905006,
        lon: -76.22782,
      },
      max_wait: 600,
      quantity: -25,
      start_time_window: [
        "2024-04-15T13:00:00-05:00",
        "2024-04-15T20:00:00-05:00",
      ],
    },
    {
      id: "location-44",
      location: {
        lat: 40.000687,
        lon: -76.36346,
      },
      max_wait: 600,
      quantity: -16,
      start_time_window: [
        "2024-04-15T13:00:00-05:00",
        "2024-04-15T20:00:00-05:00",
      ],
    },
    {
      id: "location-45",
      location: {
        lat: 39.9218,
        lon: -76.31298,
      },
      max_wait: 600,
      quantity: -20,
      start_time_window: [
        "2024-04-15T13:00:00-05:00",
        "2024-04-15T20:00:00-05:00",
      ],
    },
    {
      id: "location-46",
      location: {
        lat: 40.026314,
        lon: -76.232056,
      },
      max_wait: 600,
      quantity: -15,
      start_time_window: [
        "2024-04-15T04:00:00-05:00",
        "2024-04-15T13:00:00-05:00",
      ],
    },
    {
      id: "location-47",
      location: {
        lat: 39.981533,
        lon: -76.425095,
      },
      max_wait: 600,
      quantity: -21,
      start_time_window: [
        "2024-04-15T04:00:00-05:00",
        "2024-04-15T13:00:00-05:00",
      ],
    },
    {
      id: "location-48",
      location: {
        lat: 40.03035,
        lon: -76.383736,
      },
      max_wait: 600,
      quantity: -16,
      start_time_window: [
        "2024-04-15T13:00:00-05:00",
        "2024-04-15T20:00:00-05:00",
      ],
    },
    {
      id: "location-49",
      location: {
        lat: 39.88722,
        lon: -76.36708,
      },
      max_wait: 600,
      quantity: -29,
      start_time_window: [
        "2024-04-15T04:00:00-05:00",
        "2024-04-15T13:00:00-05:00",
      ],
    },
    {
      id: "location-50",
      location: {
        lat: 39.92949,
        lon: -76.26449,
      },
      max_wait: 600,
      quantity: -29,
      start_time_window: [
        "2024-04-15T04:00:00-05:00",
        "2024-04-15T13:00:00-05:00",
      ],
    },
    {
      id: "location-51",
      location: {
        lat: 40.017906,
        lon: -76.43718,
      },
      max_wait: 600,
      quantity: -21,
      start_time_window: [
        "2024-04-15T13:00:00-05:00",
        "2024-04-15T20:00:00-05:00",
      ],
    },
    {
      id: "location-52",
      location: {
        lat: 39.950287,
        lon: -76.303795,
      },
      max_wait: 600,
      quantity: -18,
      start_time_window: [
        "2024-04-15T13:00:00-05:00",
        "2024-04-15T20:00:00-05:00",
      ],
    },
    {
      id: "location-53",
      location: {
        lat: 40.007797,
        lon: -76.40003,
      },
      max_wait: 600,
      quantity: -27,
      start_time_window: [
        "2024-04-15T13:00:00-05:00",
        "2024-04-15T20:00:00-05:00",
      ],
    },
    {
      id: "location-54",
      location: {
        lat: 39.943848,
        lon: -76.377945,
      },
      max_wait: 600,
      quantity: -24,
      start_time_window: [
        "2024-04-15T13:00:00-05:00",
        "2024-04-15T20:00:00-05:00",
      ],
    },
    {
      id: "location-55",
      location: {
        lat: 40.03589,
        lon: -76.37997,
      },
      max_wait: 600,
      quantity: -21,
      start_time_window: [
        "2024-04-15T04:00:00-05:00",
        "2024-04-15T13:00:00-05:00",
      ],
    },
    {
      id: "location-56",
      location: {
        lat: 40.02187,
        lon: -76.28001,
      },
      max_wait: 600,
      quantity: -17,
      start_time_window: [
        "2024-04-15T04:00:00-05:00",
        "2024-04-15T13:00:00-05:00",
      ],
    },
    {
      id: "location-57",
      location: {
        lat: 39.87388,
        lon: -76.349945,
      },
      max_wait: 600,
      quantity: -27,
      start_time_window: [
        "2024-04-15T13:00:00-05:00",
        "2024-04-15T20:00:00-05:00",
      ],
    },
    {
      id: "location-58",
      location: {
        lat: 39.939056,
        lon: -76.26718,
      },
      max_wait: 600,
      quantity: -22,
      start_time_window: [
        "2024-04-15T04:00:00-05:00",
        "2024-04-15T13:00:00-05:00",
      ],
    },
    {
      id: "location-59",
      location: {
        lat: 39.9456,
        lon: -76.30287,
      },
      max_wait: 600,
      quantity: -20,
      start_time_window: [
        "2024-04-15T13:00:00-05:00",
        "2024-04-15T20:00:00-05:00",
      ],
    },
    {
      id: "location-60",
      location: {
        lat: 39.87514,
        lon: -76.3167,
      },
      max_wait: 600,
      quantity: -21,
      start_time_window: [
        "2024-04-15T04:00:00-05:00",
        "2024-04-15T13:00:00-05:00",
      ],
    },
    {
      id: "location-61",
      location: {
        lat: 39.962387,
        lon: -76.424675,
      },
      max_wait: 600,
      quantity: -18,
      start_time_window: [
        "2024-04-15T04:00:00-05:00",
        "2024-04-15T13:00:00-05:00",
      ],
    },
    {
      id: "location-62",
      location: {
        lat: 39.991806,
        lon: -76.39229,
      },
      max_wait: 600,
      quantity: -17,
      start_time_window: [
        "2024-04-15T04:00:00-05:00",
        "2024-04-15T13:00:00-05:00",
      ],
    },
    {
      id: "location-63",
      location: {
        lat: 39.902992,
        lon: -76.27321,
      },
      max_wait: 600,
      quantity: -29,
      start_time_window: [
        "2024-04-15T13:00:00-05:00",
        "2024-04-15T20:00:00-05:00",
      ],
    },
    {
      id: "location-64",
      location: {
        lat: 40.069347,
        lon: -76.26291,
      },
      max_wait: 600,
      quantity: -20,
      start_time_window: [
        "2024-04-15T13:00:00-05:00",
        "2024-04-15T20:00:00-05:00",
      ],
    },
    {
      id: "location-65",
      location: {
        lat: 39.960545,
        lon: -76.2991,
      },
      max_wait: 600,
      quantity: -20,
      start_time_window: [
        "2024-04-15T13:00:00-05:00",
        "2024-04-15T20:00:00-05:00",
      ],
    },
  ],
  vehicles: [
    {
      start_time: "2024-04-15T13:00:00-05:00",
      end_time: "2024-04-15T20:59:00-05:00",
      id: "yawning-lemur",
    },
    {
      start_time: "2024-04-15T18:00:00-05:00",
      end_time: "2024-04-15T23:59:00-05:00",
      id: "cantankerous-chinchilla",
    },
    {
      start_time: "2024-04-15T13:00:00-05:00",
      end_time: "2024-04-15T20:59:00-05:00",
      id: "enigmatic-toucan",
    },
    {
      start_time: "2024-04-15T09:00:00-05:00",
      end_time: "2024-04-15T16:59:00-05:00",
      id: "munificent-quokka",
    },
    {
      start_time: "2024-04-15T16:00:00-05:00",
      end_time: "2024-04-15T23:59:00-05:00",
      id: "frivolous-otter",
    },
    {
      start_time: "2024-04-15T09:00:00-05:00",
      end_time: "2024-04-15T16:59:00-05:00",
      id: "gibbous-okapi",
    },
    {
      start_time: "2024-04-15T13:00:00-05:00",
      end_time: "2024-04-15T20:59:00-05:00",
      id: "aberrant-giraffe",
    },
    {
      start_time: "2024-04-15T09:00:00-05:00",
      end_time: "2024-04-15T16:59:00-05:00",
      id: "lugubrious-sloth",
    },
    {
      start_time: "2024-04-15T13:00:00-05:00",
      end_time: "2024-04-15T23:59:00-05:00",
      id: "enigmatic-aardvark",
    },
    {
      start_time: "2024-04-15T09:00:00-05:00",
      end_time: "2024-04-15T16:59:00-05:00",
      id: "rambunctious-binturong",
    },
    {
      start_time: "2024-04-15T09:00:00-05:00",
      end_time: "2024-04-15T16:59:00-05:00",
      id: "sesquipedalian-sea-otter",
    },
    {
      start_time: "2024-04-15T13:00:00-05:00",
      end_time: "2024-04-15T20:59:00-05:00",
      id: "dapper-porcupine",
    },
    {
      start_time: "2024-04-15T12:00:00-05:00",
      end_time: "2024-04-15T17:59:00-05:00",
      id: "lachrymose-tapir",
    },
    {
      start_time: "2024-04-15T11:00:00-05:00",
      end_time: "2024-04-15T17:59:00-05:00",
      id: "whimsical-fennec-fox",
    },
    {
      start_time: "2024-04-15T16:00:00-05:00",
      end_time: "2024-04-15T23:59:00-05:00",
      id: "bombastic-hedgehog",
    },
    {
      start_time: "2024-04-15T09:00:00-05:00",
      end_time: "2024-04-15T23:59:00-05:00",
      id: "recalcitrant-hippopotamus",
    },
    {
      start_time: "2024-04-15T13:00:00-05:00",
      end_time: "2024-04-15T20:59:00-05:00",
      id: "vainglorious-okapi",
    },
    {
      start_time: "2024-04-15T09:00:00-05:00",
      end_time: "2024-04-15T16:59:00-05:00",
      id: "beneficent-axolotl",
    },
    {
      start_time: "2024-04-15T09:00:00-05:00",
      end_time: "2024-04-15T16:59:00-05:00",
      id: "quixotic-lemur",
    },
    {
      start_time: "2024-04-15T09:00:00-05:00",
      end_time: "2024-04-15T16:59:00-05:00",
      id: "delirious-chinchilla",
    },
    {
      start_time: "2024-04-15T10:00:00-05:00",
      end_time: "2024-04-15T16:59:00-05:00",
      id: "jocular-chinchilla",
    },
    {
      start_time: "2024-04-15T12:00:00-05:00",
      end_time: "2024-04-15T16:59:00-05:00",
      id: "kaleidoscopic-bush-baby",
    },
    {
      start_time: "2024-04-15T13:00:00-05:00",
      end_time: "2024-04-15T17:59:00-05:00",
      id: "ebullient-lemur",
    },
    {
      start_time: "2024-04-15T09:00:00-05:00",
      end_time: "2024-04-15T16:59:00-05:00",
      id: "effervescent-wombat",
    },
    {
      start_time: "2024-04-15T13:00:00-05:00",
      end_time: "2024-04-15T20:59:00-05:00",
      id: "querulous-proboscis-monkey",
    },
    {
      start_time: "2024-04-15T09:00:00-05:00",
      end_time: "2024-04-15T16:59:00-05:00",
      id: "beneficent-dik-dik",
    },
    {
      start_time: "2024-04-15T13:00:00-05:00",
      end_time: "2024-04-15T20:59:00-05:00",
      id: "bombastic-sloth",
    },
    {
      start_time: "2024-04-15T13:00:00-05:00",
      end_time: "2024-04-15T20:59:00-05:00",
      id: "perspicacious-red-panda",
    },
    {
      start_time: "2024-04-15T12:00:00-05:00",
      end_time: "2024-04-15T18:59:00-05:00",
      id: "rambunctious-pangolin",
    },
    {
      start_time: "2024-04-15T13:00:00-05:00",
      end_time: "2024-04-15T20:59:00-05:00",
      id: "beneficent-red-panda",
    },
    {
      start_time: "2024-04-15T13:00:00-05:00",
      end_time: "2024-04-15T20:59:00-05:00",
      id: "turbulent-mandrill",
    },
    {
      start_time: "2024-04-15T13:00:00-05:00",
      end_time: "2024-04-15T20:59:00-05:00",
      id: "zealous-blobfish",
    },
    {
      start_time: "2024-04-15T11:00:00-05:00",
      end_time: "2024-04-15T18:59:00-05:00",
      id: "vivacious-guinea-pig",
    },
    {
      start_time: "2024-04-15T13:00:00-05:00",
      end_time: "2024-04-15T20:59:00-05:00",
      id: "ebullient-red-panda",
    },
  ],
};
