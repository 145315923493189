import { MarketplacePartnerAppLocalReturn } from "../../../api/core/controlPlane.types";
import {
  AMPL_FACILITY_LOCATION_APP_ID,
  AMPL_FACILITY_LOCATION_CODE_REPO_LINK,
  AMPL_FACILITY_LOCATION_CONSTRAINTS,
  AMPL_FACILITY_LOCATION_DOCS_LINK,
  AMPL_FACILITY_LOCATION_ICON_URL,
  AMPL_FACILITY_LOCATION_LANGUAGE,
  AMPL_FACILITY_LOCATION_MODEL_TYPE,
  AMPL_FACILITY_LOCATION_NAME,
  AMPL_FACILITY_LOCATION_PACKAGES,
  AMPL_FACILITY_LOCATION_PARTNER_ID,
  AMPL_FACILITY_LOCATION_SHORT_DESC,
  AMPL_FACILITY_LOCATION_SOLVER,
  AMPL_FACILITY_LOCATION_SUBSCRIPTION_ID,
  AMPL_FACILITY_LOCATION_SUMMARY,
} from "../../MarketplaceApp/data/marketAppAmplFacilityLocation";
import {
  AMPL_PRICE_OPTIMIZATION_APP_ID,
  AMPL_PRICE_OPTIMIZATION_CODE_REPO_LINK,
  AMPL_PRICE_OPTIMIZATION_CONSTRAINTS,
  AMPL_PRICE_OPTIMIZATION_DOCS_LINK,
  AMPL_PRICE_OPTIMIZATION_ICON_URL,
  AMPL_PRICE_OPTIMIZATION_LANGUAGE,
  AMPL_PRICE_OPTIMIZATION_MODEL_TYPE,
  AMPL_PRICE_OPTIMIZATION_NAME,
  AMPL_PRICE_OPTIMIZATION_PACKAGES,
  AMPL_PRICE_OPTIMIZATION_PARTNER_ID,
  AMPL_PRICE_OPTIMIZATION_SHORT_DESC,
  AMPL_PRICE_OPTIMIZATION_SOLVER,
  AMPL_PRICE_OPTIMIZATION_SUBSCRIPTION_ID,
  AMPL_PRICE_OPTIMIZATION_SUMMARY,
} from "../../MarketplaceApp/data/marketAppAmplPricing";
import {
  HIGHS_KNAPSACK_APP_ID,
  HIGHS_KNAPSACK_CODE_REPO_LINK,
  HIGHS_KNAPSACK_CONSTRAINTS,
  HIGHS_KNAPSACK_DOCS_LINK,
  HIGHS_KNAPSACK_ICON_URL,
  HIGHS_KNAPSACK_LANGUAGE,
  HIGHS_KNAPSACK_MODEL_TYPE,
  HIGHS_KNAPSACK_NAME,
  HIGHS_KNAPSACK_PACKAGES,
  HIGHS_KNAPSACK_PARTNER_ID,
  HIGHS_KNAPSACK_SHORT_DESC,
  HIGHS_KNAPSACK_SOLVER,
  HIGHS_KNAPSACK_SUBSCRIPTION_ID,
  HIGHS_KNAPSACK_SUMMARY,
} from "../../MarketplaceApp/data/marketAppHiGHSKnapsack";
import {
  ROUTING_LEGACY_APP_ID,
  ROUTING_LEGACY_CONSTRAINTS,
  ROUTING_LEGACY_DOCS_LINK,
  ROUTING_LEGACY_ICON_URL,
  ROUTING_LEGACY_IMAGE_ALT,
  ROUTING_LEGACY_IMAGE_URL,
  ROUTING_LEGACY_MODEL_TYPE,
  ROUTING_LEGACY_NAME,
  ROUTING_LEGACY_PARTNER_ID,
  ROUTING_LEGACY_SHORT_DESC,
  ROUTING_LEGACY_SUBSCRIPTION_ID,
  ROUTING_LEGACY_SUMMARY,
} from "../../MarketplaceApp/data/marketAppLegacyRouting";
import {
  KNAPSACK_APP_ID,
  KNAPSACK_CODE_REPO_LINK,
  KNAPSACK_CONSTRAINTS,
  KNAPSACK_DOCS_LINK,
  KNAPSACK_ICON_URL,
  KNAPSACK_LANGUAGE,
  KNAPSACK_MODEL_TYPE,
  KNAPSACK_NAME,
  KNAPSACK_PACKAGES,
  KNAPSACK_PARTNER_ID,
  KNAPSACK_SHORT_DESC,
  KNAPSACK_SOLVER,
  KNAPSACK_SUBSCRIPTION_ID,
  KNAPSACK_SUMMARY,
} from "../../MarketplaceApp/data/marketAppNextmvKnapsack";
import {
  NEXTROUTE_APP_ID,
  NEXTROUTE_CODE_REPO_LINK,
  NEXTROUTE_CONSTRAINTS,
  NEXTROUTE_DOCS_LINK,
  NEXTROUTE_ICON_URL,
  NEXTROUTE_IMAGE_ALT,
  NEXTROUTE_IMAGE_URL,
  NEXTROUTE_LANGUAGE,
  NEXTROUTE_MODEL_TYPE,
  NEXTROUTE_NAME,
  NEXTROUTE_PACKAGES,
  NEXTROUTE_PARTNER_ID,
  NEXTROUTE_SCREENSHOT_ALT,
  NEXTROUTE_SCREENSHOT_URL,
  NEXTROUTE_SHORT_DESC,
  NEXTROUTE_SOLVER,
  NEXTROUTE_SUBSCRIPTION_ID,
  NEXTROUTE_SUMMARY,
} from "../../MarketplaceApp/data/marketAppNextroute";
import {
  ORDER_FULFILLMENT_APP_ID,
  ORDER_FULFILLMENT_CODE_REPO_LINK,
  ORDER_FULFILLMENT_CONSTRAINTS,
  ORDER_FULFILLMENT_DOCS_LINK,
  ORDER_FULFILLMENT_ICON_URL,
  ORDER_FULFILLMENT_LANGUAGE,
  ORDER_FULFILLMENT_MODEL_TYPE,
  ORDER_FULFILLMENT_NAME,
  ORDER_FULFILLMENT_PACKAGES,
  ORDER_FULFILLMENT_PARTNER_ID,
  ORDER_FULFILLMENT_SHORT_DESC,
  ORDER_FULFILLMENT_SOLVER,
  ORDER_FULFILLMENT_SUBSCRIPTION_ID,
  ORDER_FULFILLMENT_SUMMARY,
} from "../../MarketplaceApp/data/marketAppOrderFulfillment";
import {
  ORTOOLS_DEMAND_FORECASTING_APP_ID,
  ORTOOLS_DEMAND_FORECASTING_CODE_REPO_LINK,
  ORTOOLS_DEMAND_FORECASTING_CONSTRAINTS,
  ORTOOLS_DEMAND_FORECASTING_DOCS_LINK,
  ORTOOLS_DEMAND_FORECASTING_ICON_URL,
  ORTOOLS_DEMAND_FORECASTING_LANGUAGE,
  ORTOOLS_DEMAND_FORECASTING_MODEL_TYPE,
  ORTOOLS_DEMAND_FORECASTING_NAME,
  ORTOOLS_DEMAND_FORECASTING_PACKAGES,
  ORTOOLS_DEMAND_FORECASTING_PARTNER_ID,
  ORTOOLS_DEMAND_FORECASTING_SHORT_DESC,
  ORTOOLS_DEMAND_FORECASTING_SOLVER,
  ORTOOLS_DEMAND_FORECASTING_SUBSCRIPTION_ID,
  ORTOOLS_DEMAND_FORECASTING_SUMMARY,
} from "../../MarketplaceApp/data/marketAppORToolsDemandForecasting";
import {
  ORTOOLS_KNAPSACK_APP_ID,
  ORTOOLS_KNAPSACK_CODE_REPO_LINK,
  ORTOOLS_KNAPSACK_CONSTRAINTS,
  ORTOOLS_KNAPSACK_DOCS_LINK,
  ORTOOLS_KNAPSACK_ICON_URL,
  ORTOOLS_KNAPSACK_LANGUAGE,
  ORTOOLS_KNAPSACK_MODEL_TYPE,
  ORTOOLS_KNAPSACK_NAME,
  ORTOOLS_KNAPSACK_PACKAGES,
  ORTOOLS_KNAPSACK_PARTNER_ID,
  ORTOOLS_KNAPSACK_SHORT_DESC,
  ORTOOLS_KNAPSACK_SOLVER,
  ORTOOLS_KNAPSACK_SUBSCRIPTION_ID,
  ORTOOLS_KNAPSACK_SUMMARY,
} from "../../MarketplaceApp/data/marketAppORToolsKnapsack";
import {
  ORTOOLS_SHIFT_ASSIGNMENT_APP_ID,
  ORTOOLS_SHIFT_ASSIGNMENT_CODE_REPO_LINK,
  ORTOOLS_SHIFT_ASSIGNMENT_CONSTRAINTS,
  ORTOOLS_SHIFT_ASSIGNMENT_DOCS_LINK,
  ORTOOLS_SHIFT_ASSIGNMENT_ICON_URL,
  ORTOOLS_SHIFT_ASSIGNMENT_LANGUAGE,
  ORTOOLS_SHIFT_ASSIGNMENT_MODEL_TYPE,
  ORTOOLS_SHIFT_ASSIGNMENT_NAME,
  ORTOOLS_SHIFT_ASSIGNMENT_PACKAGES,
  ORTOOLS_SHIFT_ASSIGNMENT_PARTNER_ID,
  ORTOOLS_SHIFT_ASSIGNMENT_SHORT_DESC,
  ORTOOLS_SHIFT_ASSIGNMENT_SOLVER,
  ORTOOLS_SHIFT_ASSIGNMENT_SUBSCRIPTION_ID,
  ORTOOLS_SHIFT_ASSIGNMENT_SUMMARY,
} from "../../MarketplaceApp/data/marketAppORToolsShiftAssignment";
import {
  ORTOOLS_SHIFT_PLANNING_APP_ID,
  ORTOOLS_SHIFT_PLANNING_CODE_REPO_LINK,
  ORTOOLS_SHIFT_PLANNING_CONSTRAINTS,
  ORTOOLS_SHIFT_PLANNING_DOCS_LINK,
  ORTOOLS_SHIFT_PLANNING_ICON_URL,
  ORTOOLS_SHIFT_PLANNING_LANGUAGE,
  ORTOOLS_SHIFT_PLANNING_MODEL_TYPE,
  ORTOOLS_SHIFT_PLANNING_NAME,
  ORTOOLS_SHIFT_PLANNING_PACKAGES,
  ORTOOLS_SHIFT_PLANNING_PARTNER_ID,
  ORTOOLS_SHIFT_PLANNING_SHORT_DESC,
  ORTOOLS_SHIFT_PLANNING_SOLVER,
  ORTOOLS_SHIFT_PLANNING_SUBSCRIPTION_ID,
  ORTOOLS_SHIFT_PLANNING_SUMMARY,
} from "../../MarketplaceApp/data/marketAppORToolsShiftPlanning";
import {
  ORTOOLS_SIMPLE_ROUTING_APP_ID,
  ORTOOLS_SIMPLE_ROUTING_CODE_REPO_LINK,
  ORTOOLS_SIMPLE_ROUTING_CONSTRAINTS,
  ORTOOLS_SIMPLE_ROUTING_DOCS_LINK,
  ORTOOLS_SIMPLE_ROUTING_ICON_URL,
  ORTOOLS_SIMPLE_ROUTING_LANGUAGE,
  ORTOOLS_SIMPLE_ROUTING_MODEL_TYPE,
  ORTOOLS_SIMPLE_ROUTING_NAME,
  ORTOOLS_SIMPLE_ROUTING_PACKAGES,
  ORTOOLS_SIMPLE_ROUTING_PARTNER_ID,
  ORTOOLS_SIMPLE_ROUTING_SHORT_DESC,
  ORTOOLS_SIMPLE_ROUTING_SOLVER,
  ORTOOLS_SIMPLE_ROUTING_SUBSCRIPTION_ID,
  ORTOOLS_SIMPLE_ROUTING_SUMMARY,
} from "../../MarketplaceApp/data/marketAppORToolsSimpleRouting";
import {
  PYOMO_KNAPSACK_APP_ID,
  PYOMO_KNAPSACK_CODE_REPO_LINK,
  PYOMO_KNAPSACK_CONSTRAINTS,
  PYOMO_KNAPSACK_DOCS_LINK,
  PYOMO_KNAPSACK_ICON_URL,
  PYOMO_KNAPSACK_LANGUAGE,
  PYOMO_KNAPSACK_MODEL_TYPE,
  PYOMO_KNAPSACK_NAME,
  PYOMO_KNAPSACK_PACKAGES,
  PYOMO_KNAPSACK_PARTNER_ID,
  PYOMO_KNAPSACK_SHORT_DESC,
  PYOMO_KNAPSACK_SOLVER,
  PYOMO_KNAPSACK_SUBSCRIPTION_ID,
  PYOMO_KNAPSACK_SUMMARY,
} from "../../MarketplaceApp/data/marketAppPyomoKnapsack";
import {
  PYOMO_SHIFT_ASSIGNMENT_APP_ID,
  PYOMO_SHIFT_ASSIGNMENT_CODE_REPO_LINK,
  PYOMO_SHIFT_ASSIGNMENT_CONSTRAINTS,
  PYOMO_SHIFT_ASSIGNMENT_DOCS_LINK,
  PYOMO_SHIFT_ASSIGNMENT_ICON_URL,
  PYOMO_SHIFT_ASSIGNMENT_LANGUAGE,
  PYOMO_SHIFT_ASSIGNMENT_MODEL_TYPE,
  PYOMO_SHIFT_ASSIGNMENT_NAME,
  PYOMO_SHIFT_ASSIGNMENT_PACKAGES,
  PYOMO_SHIFT_ASSIGNMENT_PARTNER_ID,
  PYOMO_SHIFT_ASSIGNMENT_SHORT_DESC,
  PYOMO_SHIFT_ASSIGNMENT_SOLVER,
  PYOMO_SHIFT_ASSIGNMENT_SUBSCRIPTION_ID,
  PYOMO_SHIFT_ASSIGNMENT_SUMMARY,
} from "../../MarketplaceApp/data/marketAppPyomoShiftAssignment";
import {
  PYOMO_SHIFT_PLANNING_APP_ID,
  PYOMO_SHIFT_PLANNING_CODE_REPO_LINK,
  PYOMO_SHIFT_PLANNING_CONSTRAINTS,
  PYOMO_SHIFT_PLANNING_DOCS_LINK,
  PYOMO_SHIFT_PLANNING_ICON_URL,
  PYOMO_SHIFT_PLANNING_LANGUAGE,
  PYOMO_SHIFT_PLANNING_MODEL_TYPE,
  PYOMO_SHIFT_PLANNING_NAME,
  PYOMO_SHIFT_PLANNING_PACKAGES,
  PYOMO_SHIFT_PLANNING_PARTNER_ID,
  PYOMO_SHIFT_PLANNING_SHORT_DESC,
  PYOMO_SHIFT_PLANNING_SOLVER,
  PYOMO_SHIFT_PLANNING_SUBSCRIPTION_ID,
  PYOMO_SHIFT_PLANNING_SUMMARY,
} from "../../MarketplaceApp/data/marketAppPyomoShiftPlanning";
import {
  ROUTING_PYVROOM_APP_ID,
  ROUTING_PYVROOM_CODE_REPO_LINK,
  ROUTING_PYVROOM_CONSTRAINTS,
  ROUTING_PYVROOM_DOCS_LINK,
  ROUTING_PYVROOM_ICON_URL,
  ROUTING_PYVROOM_LANGUAGE,
  ROUTING_PYVROOM_MODEL_TYPE,
  ROUTING_PYVROOM_NAME,
  ROUTING_PYVROOM_PACKAGES,
  ROUTING_PYVROOM_PARTNER_ID,
  ROUTING_PYVROOM_SHORT_DESC,
  ROUTING_PYVROOM_SOLVER,
  ROUTING_PYVROOM_SUBSCRIPTION_ID,
  ROUTING_PYVROOM_SUMMARY,
} from "../../MarketplaceApp/data/marketAppRoutingPyvroom";
import {
  SHIFT_SCHEDULING_APP_ID,
  SHIFT_SCHEDULING_CODE_REPO_LINK,
  SHIFT_SCHEDULING_CONSTRAINTS,
  SHIFT_SCHEDULING_DOCS_LINK,
  SHIFT_SCHEDULING_ICON_URL,
  SHIFT_SCHEDULING_IMAGE_ALT,
  SHIFT_SCHEDULING_IMAGE_URL,
  SHIFT_SCHEDULING_LANGUAGE,
  SHIFT_SCHEDULING_MODEL_TYPE,
  SHIFT_SCHEDULING_NAME,
  SHIFT_SCHEDULING_PACKAGES,
  SHIFT_SCHEDULING_PARTNER_ID,
  SHIFT_SCHEDULING_SCREENSHOT_ALT,
  SHIFT_SCHEDULING_SCREENSHOT_URL,
  SHIFT_SCHEDULING_SHORT_DESC,
  SHIFT_SCHEDULING_SOLVER,
  SHIFT_SCHEDULING_SUBSCRIPTION_ID,
  SHIFT_SCHEDULING_SUMMARY,
} from "../../MarketplaceApp/data/marketAppShiftScheduling";

type MarketplaceAppCategoryList = string[];
type CombineCategoriesParams = {
  modelType?: MarketplaceAppCategoryList;
  modelLanguage?: MarketplaceAppCategoryList;
  modelPackages?: MarketplaceAppCategoryList;
  modelSolver?: MarketplaceAppCategoryList;
};
export const combineCategories = ({
  modelLanguage,
  modelPackages,
  modelSolver,
  modelType,
}: CombineCategoriesParams) => {
  return [
    ...(modelSolver ? modelSolver : []),
    ...(modelPackages ? modelPackages : []),
    ...(modelLanguage ? modelLanguage : []),
    ...(modelType ? modelType : []),
  ].filter(Boolean);
};

// TEMP: data will eventually come from the backend
export const marketplaceApps: MarketplacePartnerAppLocalReturn[] = [
  {
    id: NEXTROUTE_SUBSCRIPTION_ID,
    url: `/marketplace/app/${NEXTROUTE_PARTNER_ID}/${NEXTROUTE_APP_ID}`,
    featuredImageUrl: NEXTROUTE_IMAGE_URL,
    featuredImageAlt: NEXTROUTE_IMAGE_ALT,
    name: NEXTROUTE_NAME,
    description: NEXTROUTE_SHORT_DESC,
    features_v2: NEXTROUTE_CONSTRAINTS,
    isActive: true,
    tags: combineCategories({
      modelLanguage: NEXTROUTE_LANGUAGE,
      modelPackages: NEXTROUTE_PACKAGES,
      modelSolver: NEXTROUTE_SOLVER,
      modelType: NEXTROUTE_MODEL_TYPE,
    }),
    modelType: NEXTROUTE_MODEL_TYPE.join(""),
    icon: {
      url: NEXTROUTE_ICON_URL,
      alt: "",
    },
    image: {
      url: NEXTROUTE_IMAGE_URL,
      alt: NEXTROUTE_IMAGE_ALT,
    },
    screenshot: {
      url: NEXTROUTE_SCREENSHOT_URL,
      alt: NEXTROUTE_SCREENSHOT_ALT,
    },
    summary: NEXTROUTE_SUMMARY,
    docsLink: NEXTROUTE_DOCS_LINK,
    codeRepoLink: NEXTROUTE_CODE_REPO_LINK,
  },
  {
    id: ROUTING_LEGACY_SUBSCRIPTION_ID,
    isHidden: true,
    url: `/marketplace/app/${ROUTING_LEGACY_PARTNER_ID}/${ROUTING_LEGACY_APP_ID}`,
    featuredImageUrl: ROUTING_LEGACY_IMAGE_URL,
    featuredImageAlt: ROUTING_LEGACY_IMAGE_ALT,
    name: ROUTING_LEGACY_NAME,
    description: ROUTING_LEGACY_SHORT_DESC,
    features_v2: ROUTING_LEGACY_CONSTRAINTS,
    isActive: true,
    tags: combineCategories({
      modelType: ROUTING_LEGACY_MODEL_TYPE,
    }),
    modelType: ROUTING_LEGACY_MODEL_TYPE.join(""),
    icon: {
      url: ROUTING_LEGACY_ICON_URL,
      alt: "",
    },
    image: {
      url: ROUTING_LEGACY_IMAGE_URL,
      alt: ROUTING_LEGACY_IMAGE_ALT,
    },
    summary: ROUTING_LEGACY_SUMMARY,
    docsLink: ROUTING_LEGACY_DOCS_LINK,
  },
  {
    id: SHIFT_SCHEDULING_SUBSCRIPTION_ID,
    url: `/marketplace/app/${SHIFT_SCHEDULING_PARTNER_ID}/${SHIFT_SCHEDULING_APP_ID}`,
    name: SHIFT_SCHEDULING_NAME,
    description: SHIFT_SCHEDULING_SHORT_DESC,
    features_v2: SHIFT_SCHEDULING_CONSTRAINTS,
    isActive: true,
    tags: combineCategories({
      modelLanguage: SHIFT_SCHEDULING_LANGUAGE,
      modelPackages: SHIFT_SCHEDULING_PACKAGES,
      modelSolver: SHIFT_SCHEDULING_SOLVER,
      modelType: SHIFT_SCHEDULING_MODEL_TYPE,
    }),
    modelType: SHIFT_SCHEDULING_MODEL_TYPE.join(""),
    icon: {
      url: SHIFT_SCHEDULING_ICON_URL,
      alt: "",
    },
    image: {
      url: SHIFT_SCHEDULING_IMAGE_URL,
      alt: SHIFT_SCHEDULING_IMAGE_ALT,
    },
    screenshot: {
      url: SHIFT_SCHEDULING_SCREENSHOT_URL,
      alt: SHIFT_SCHEDULING_SCREENSHOT_ALT,
    },
    summary: SHIFT_SCHEDULING_SUMMARY,
    docsLink: SHIFT_SCHEDULING_DOCS_LINK,
    codeRepoLink: SHIFT_SCHEDULING_CODE_REPO_LINK,
  },
  {
    id: ORDER_FULFILLMENT_SUBSCRIPTION_ID,
    url: `/marketplace/app/${ORDER_FULFILLMENT_PARTNER_ID}/${ORDER_FULFILLMENT_APP_ID}`,
    name: ORDER_FULFILLMENT_NAME,
    description: ORDER_FULFILLMENT_SHORT_DESC,
    features_v2: ORDER_FULFILLMENT_CONSTRAINTS,
    isActive: true,
    tags: combineCategories({
      modelLanguage: ORDER_FULFILLMENT_LANGUAGE,
      modelPackages: ORDER_FULFILLMENT_PACKAGES,
      modelSolver: ORDER_FULFILLMENT_SOLVER,
      modelType: ORDER_FULFILLMENT_MODEL_TYPE,
    }),
    modelType: ORDER_FULFILLMENT_MODEL_TYPE.join(""),
    icon: {
      url: ORDER_FULFILLMENT_ICON_URL,
      alt: "",
    },
    summary: ORDER_FULFILLMENT_SUMMARY,
    docsLink: ORDER_FULFILLMENT_DOCS_LINK,
    codeRepoLink: ORDER_FULFILLMENT_CODE_REPO_LINK,
  },
  {
    id: ORTOOLS_SHIFT_PLANNING_SUBSCRIPTION_ID,
    url: `/marketplace/app/${ORTOOLS_SHIFT_PLANNING_PARTNER_ID}/${ORTOOLS_SHIFT_PLANNING_APP_ID}`,
    name: ORTOOLS_SHIFT_PLANNING_NAME,
    description: ORTOOLS_SHIFT_PLANNING_SHORT_DESC,
    features_v2: ORTOOLS_SHIFT_PLANNING_CONSTRAINTS,
    isActive: true,
    tags: combineCategories({
      modelLanguage: ORTOOLS_SHIFT_PLANNING_LANGUAGE,
      modelPackages: ORTOOLS_SHIFT_PLANNING_PACKAGES,
      modelSolver: ORTOOLS_SHIFT_PLANNING_SOLVER,
      modelType: ORTOOLS_SHIFT_PLANNING_MODEL_TYPE,
    }),
    modelType: ORTOOLS_SHIFT_PLANNING_MODEL_TYPE.join(""),
    icon: {
      url: ORTOOLS_SHIFT_PLANNING_ICON_URL,
      alt: "",
    },
    summary: ORTOOLS_SHIFT_PLANNING_SUMMARY,
    docsLink: ORTOOLS_SHIFT_PLANNING_DOCS_LINK,
    codeRepoLink: ORTOOLS_SHIFT_PLANNING_CODE_REPO_LINK,
  },
  {
    id: ORTOOLS_SIMPLE_ROUTING_SUBSCRIPTION_ID,
    url: `/marketplace/app/${ORTOOLS_SIMPLE_ROUTING_PARTNER_ID}/${ORTOOLS_SIMPLE_ROUTING_APP_ID}`,
    name: ORTOOLS_SIMPLE_ROUTING_NAME,
    description: ORTOOLS_SIMPLE_ROUTING_SHORT_DESC,
    features_v2: ORTOOLS_SIMPLE_ROUTING_CONSTRAINTS,
    isActive: true,
    tags: combineCategories({
      modelLanguage: ORTOOLS_SIMPLE_ROUTING_LANGUAGE,
      modelPackages: ORTOOLS_SIMPLE_ROUTING_PACKAGES,
      modelSolver: ORTOOLS_SIMPLE_ROUTING_SOLVER,
      modelType: ORTOOLS_SIMPLE_ROUTING_MODEL_TYPE,
    }),
    modelType: ORTOOLS_SIMPLE_ROUTING_MODEL_TYPE.join(""),
    icon: {
      url: ORTOOLS_SIMPLE_ROUTING_ICON_URL,
      alt: "",
    },
    summary: ORTOOLS_SIMPLE_ROUTING_SUMMARY,
    docsLink: ORTOOLS_SIMPLE_ROUTING_DOCS_LINK,
    codeRepoLink: ORTOOLS_SIMPLE_ROUTING_CODE_REPO_LINK,
  },
  {
    id: ORTOOLS_SHIFT_ASSIGNMENT_SUBSCRIPTION_ID,
    url: `/marketplace/app/${ORTOOLS_SHIFT_ASSIGNMENT_PARTNER_ID}/${ORTOOLS_SHIFT_ASSIGNMENT_APP_ID}`,
    name: ORTOOLS_SHIFT_ASSIGNMENT_NAME,
    description: ORTOOLS_SHIFT_ASSIGNMENT_SHORT_DESC,
    features_v2: ORTOOLS_SHIFT_ASSIGNMENT_CONSTRAINTS,
    isActive: true,
    tags: combineCategories({
      modelLanguage: ORTOOLS_SHIFT_ASSIGNMENT_LANGUAGE,
      modelPackages: ORTOOLS_SHIFT_ASSIGNMENT_PACKAGES,
      modelSolver: ORTOOLS_SHIFT_ASSIGNMENT_SOLVER,
      modelType: ORTOOLS_SHIFT_ASSIGNMENT_MODEL_TYPE,
    }),
    modelType: ORTOOLS_SHIFT_ASSIGNMENT_MODEL_TYPE.join(""),
    icon: {
      url: ORTOOLS_SHIFT_ASSIGNMENT_ICON_URL,
      alt: "",
    },
    summary: ORTOOLS_SHIFT_ASSIGNMENT_SUMMARY,
    docsLink: ORTOOLS_SHIFT_ASSIGNMENT_DOCS_LINK,
    codeRepoLink: ORTOOLS_SHIFT_ASSIGNMENT_CODE_REPO_LINK,
  },
  {
    id: PYOMO_SHIFT_ASSIGNMENT_SUBSCRIPTION_ID,
    url: `/marketplace/app/${PYOMO_SHIFT_ASSIGNMENT_PARTNER_ID}/${PYOMO_SHIFT_ASSIGNMENT_APP_ID}`,
    name: PYOMO_SHIFT_ASSIGNMENT_NAME,
    description: PYOMO_SHIFT_ASSIGNMENT_SHORT_DESC,
    features_v2: PYOMO_SHIFT_ASSIGNMENT_CONSTRAINTS,
    isActive: true,
    tags: combineCategories({
      modelLanguage: PYOMO_SHIFT_ASSIGNMENT_LANGUAGE,
      modelPackages: PYOMO_SHIFT_ASSIGNMENT_PACKAGES,
      modelSolver: PYOMO_SHIFT_ASSIGNMENT_SOLVER,
      modelType: PYOMO_SHIFT_ASSIGNMENT_MODEL_TYPE,
    }),
    modelType: PYOMO_SHIFT_ASSIGNMENT_MODEL_TYPE.join(""),
    icon: {
      url: PYOMO_SHIFT_ASSIGNMENT_ICON_URL,
      alt: "",
    },
    summary: PYOMO_SHIFT_ASSIGNMENT_SUMMARY,
    docsLink: PYOMO_SHIFT_ASSIGNMENT_DOCS_LINK,
    codeRepoLink: PYOMO_SHIFT_ASSIGNMENT_CODE_REPO_LINK,
  },
  {
    id: PYOMO_SHIFT_PLANNING_SUBSCRIPTION_ID,
    url: `/marketplace/app/${PYOMO_SHIFT_PLANNING_PARTNER_ID}/${PYOMO_SHIFT_PLANNING_APP_ID}`,
    name: PYOMO_SHIFT_PLANNING_NAME,
    description: PYOMO_SHIFT_PLANNING_SHORT_DESC,
    features_v2: PYOMO_SHIFT_PLANNING_CONSTRAINTS,
    isActive: true,
    tags: combineCategories({
      modelLanguage: PYOMO_SHIFT_PLANNING_LANGUAGE,
      modelPackages: PYOMO_SHIFT_PLANNING_PACKAGES,
      modelSolver: PYOMO_SHIFT_PLANNING_SOLVER,
      modelType: PYOMO_SHIFT_PLANNING_MODEL_TYPE,
    }),
    modelType: PYOMO_SHIFT_PLANNING_MODEL_TYPE.join(""),
    icon: {
      url: PYOMO_SHIFT_PLANNING_ICON_URL,
      alt: "",
    },
    summary: PYOMO_SHIFT_PLANNING_SUMMARY,
    docsLink: PYOMO_SHIFT_PLANNING_DOCS_LINK,
    codeRepoLink: PYOMO_SHIFT_PLANNING_CODE_REPO_LINK,
  },
  {
    id: KNAPSACK_SUBSCRIPTION_ID,
    url: `/marketplace/app/${KNAPSACK_PARTNER_ID}/${KNAPSACK_APP_ID}`,
    name: KNAPSACK_NAME,
    description: KNAPSACK_SHORT_DESC,
    features_v2: KNAPSACK_CONSTRAINTS,
    isActive: true,
    tags: combineCategories({
      modelLanguage: KNAPSACK_LANGUAGE,
      modelPackages: KNAPSACK_PACKAGES,
      modelSolver: KNAPSACK_SOLVER,
      modelType: KNAPSACK_MODEL_TYPE,
    }),
    modelType: KNAPSACK_MODEL_TYPE.join(""),
    icon: {
      url: KNAPSACK_ICON_URL,
      alt: "",
    },
    summary: KNAPSACK_SUMMARY,
    docsLink: KNAPSACK_DOCS_LINK,
    codeRepoLink: KNAPSACK_CODE_REPO_LINK,
  },
  {
    id: ORTOOLS_KNAPSACK_SUBSCRIPTION_ID,
    url: `/marketplace/app/${ORTOOLS_KNAPSACK_PARTNER_ID}/${ORTOOLS_KNAPSACK_APP_ID}`,
    name: ORTOOLS_KNAPSACK_NAME,
    description: ORTOOLS_KNAPSACK_SHORT_DESC,
    features_v2: ORTOOLS_KNAPSACK_CONSTRAINTS,
    isActive: true,
    tags: combineCategories({
      modelLanguage: ORTOOLS_KNAPSACK_LANGUAGE,
      modelPackages: ORTOOLS_KNAPSACK_PACKAGES,
      modelSolver: ORTOOLS_KNAPSACK_SOLVER,
      modelType: ORTOOLS_KNAPSACK_MODEL_TYPE,
    }),
    modelType: ORTOOLS_KNAPSACK_MODEL_TYPE.join(""),
    icon: {
      url: ORTOOLS_KNAPSACK_ICON_URL,
      alt: "",
    },
    summary: ORTOOLS_KNAPSACK_SUMMARY,
    docsLink: ORTOOLS_KNAPSACK_DOCS_LINK,
    codeRepoLink: ORTOOLS_KNAPSACK_CODE_REPO_LINK,
  },
  {
    id: PYOMO_KNAPSACK_SUBSCRIPTION_ID,
    url: `/marketplace/app/${PYOMO_KNAPSACK_PARTNER_ID}/${PYOMO_KNAPSACK_APP_ID}`,
    name: PYOMO_KNAPSACK_NAME,
    description: PYOMO_KNAPSACK_SHORT_DESC,
    features_v2: PYOMO_KNAPSACK_CONSTRAINTS,
    isActive: true,
    tags: combineCategories({
      modelLanguage: PYOMO_KNAPSACK_LANGUAGE,
      modelPackages: PYOMO_KNAPSACK_PACKAGES,
      modelSolver: PYOMO_KNAPSACK_SOLVER,
      modelType: PYOMO_KNAPSACK_MODEL_TYPE,
    }),
    modelType: PYOMO_KNAPSACK_MODEL_TYPE.join(""),
    icon: {
      url: PYOMO_KNAPSACK_ICON_URL,
      alt: "",
    },
    summary: PYOMO_KNAPSACK_SUMMARY,
    docsLink: PYOMO_KNAPSACK_DOCS_LINK,
    codeRepoLink: PYOMO_KNAPSACK_CODE_REPO_LINK,
  },

  {
    id: ORTOOLS_DEMAND_FORECASTING_SUBSCRIPTION_ID,
    url: `/marketplace/app/${ORTOOLS_DEMAND_FORECASTING_PARTNER_ID}/${ORTOOLS_DEMAND_FORECASTING_APP_ID}`,
    name: ORTOOLS_DEMAND_FORECASTING_NAME,
    description: ORTOOLS_DEMAND_FORECASTING_SHORT_DESC,
    features_v2: ORTOOLS_DEMAND_FORECASTING_CONSTRAINTS,
    isActive: true,
    tags: combineCategories({
      modelLanguage: ORTOOLS_DEMAND_FORECASTING_LANGUAGE,
      modelPackages: ORTOOLS_DEMAND_FORECASTING_PACKAGES,
      modelSolver: ORTOOLS_DEMAND_FORECASTING_SOLVER,
      modelType: ORTOOLS_DEMAND_FORECASTING_MODEL_TYPE,
    }),
    modelType: ORTOOLS_DEMAND_FORECASTING_MODEL_TYPE.join(""),
    icon: {
      url: ORTOOLS_DEMAND_FORECASTING_ICON_URL,
      alt: "",
    },
    summary: ORTOOLS_DEMAND_FORECASTING_SUMMARY,
    docsLink: ORTOOLS_DEMAND_FORECASTING_DOCS_LINK,
    codeRepoLink: ORTOOLS_DEMAND_FORECASTING_CODE_REPO_LINK,
  },
  {
    id: AMPL_PRICE_OPTIMIZATION_SUBSCRIPTION_ID,
    url: `/marketplace/app/${AMPL_PRICE_OPTIMIZATION_PARTNER_ID}/${AMPL_PRICE_OPTIMIZATION_APP_ID}`,
    name: AMPL_PRICE_OPTIMIZATION_NAME,
    description: AMPL_PRICE_OPTIMIZATION_SHORT_DESC,
    features_v2: AMPL_PRICE_OPTIMIZATION_CONSTRAINTS,
    isActive: true,
    tags: combineCategories({
      modelLanguage: AMPL_PRICE_OPTIMIZATION_LANGUAGE,
      modelPackages: AMPL_PRICE_OPTIMIZATION_PACKAGES,
      modelSolver: AMPL_PRICE_OPTIMIZATION_SOLVER,
      modelType: AMPL_PRICE_OPTIMIZATION_MODEL_TYPE,
    }),
    modelType: AMPL_PRICE_OPTIMIZATION_MODEL_TYPE.join(""),
    icon: {
      url: AMPL_PRICE_OPTIMIZATION_ICON_URL,
      alt: "",
    },
    summary: AMPL_PRICE_OPTIMIZATION_SUMMARY,
    docsLink: AMPL_PRICE_OPTIMIZATION_DOCS_LINK,
    codeRepoLink: AMPL_PRICE_OPTIMIZATION_CODE_REPO_LINK,
  },
  {
    id: AMPL_FACILITY_LOCATION_SUBSCRIPTION_ID,
    url: `/marketplace/app/${AMPL_FACILITY_LOCATION_PARTNER_ID}/${AMPL_FACILITY_LOCATION_APP_ID}`,
    name: AMPL_FACILITY_LOCATION_NAME,
    description: AMPL_FACILITY_LOCATION_SHORT_DESC,
    features_v2: AMPL_FACILITY_LOCATION_CONSTRAINTS,
    isActive: true,
    tags: combineCategories({
      modelLanguage: AMPL_FACILITY_LOCATION_LANGUAGE,
      modelPackages: AMPL_FACILITY_LOCATION_PACKAGES,
      modelSolver: AMPL_FACILITY_LOCATION_SOLVER,
      modelType: AMPL_FACILITY_LOCATION_MODEL_TYPE,
    }),
    modelType: AMPL_FACILITY_LOCATION_MODEL_TYPE.join(""),
    icon: {
      url: AMPL_FACILITY_LOCATION_ICON_URL,
      alt: "",
    },
    summary: AMPL_FACILITY_LOCATION_SUMMARY,
    docsLink: AMPL_FACILITY_LOCATION_DOCS_LINK,
    codeRepoLink: AMPL_FACILITY_LOCATION_CODE_REPO_LINK,
  },
  {
    id: HIGHS_KNAPSACK_SUBSCRIPTION_ID,
    url: `/marketplace/app/${HIGHS_KNAPSACK_PARTNER_ID}/${HIGHS_KNAPSACK_APP_ID}`,
    name: HIGHS_KNAPSACK_NAME,
    description: HIGHS_KNAPSACK_SHORT_DESC,
    features_v2: HIGHS_KNAPSACK_CONSTRAINTS,
    isActive: true,
    tags: combineCategories({
      modelLanguage: HIGHS_KNAPSACK_LANGUAGE,
      modelPackages: HIGHS_KNAPSACK_PACKAGES,
      modelSolver: HIGHS_KNAPSACK_SOLVER,
      modelType: HIGHS_KNAPSACK_MODEL_TYPE,
    }),
    modelType: HIGHS_KNAPSACK_MODEL_TYPE.join(""),
    icon: {
      url: HIGHS_KNAPSACK_ICON_URL,
      alt: "",
    },
    summary: HIGHS_KNAPSACK_SUMMARY,
    docsLink: HIGHS_KNAPSACK_DOCS_LINK,
    codeRepoLink: HIGHS_KNAPSACK_CODE_REPO_LINK,
  },
  {
    id: ROUTING_PYVROOM_SUBSCRIPTION_ID,
    url: `/marketplace/app/${ROUTING_PYVROOM_PARTNER_ID}/${ROUTING_PYVROOM_APP_ID}`,
    name: ROUTING_PYVROOM_NAME,
    description: ROUTING_PYVROOM_SHORT_DESC,
    features_v2: ROUTING_PYVROOM_CONSTRAINTS,
    isActive: true,
    tags: combineCategories({
      modelLanguage: ROUTING_PYVROOM_LANGUAGE,
      modelPackages: ROUTING_PYVROOM_PACKAGES,
      modelSolver: ROUTING_PYVROOM_SOLVER,
      modelType: ROUTING_PYVROOM_MODEL_TYPE,
    }),
    modelType: ROUTING_PYVROOM_MODEL_TYPE.join(""),
    icon: {
      url: ROUTING_PYVROOM_ICON_URL,
      alt: "",
    },
    summary: ROUTING_PYVROOM_SUMMARY,
    docsLink: ROUTING_PYVROOM_DOCS_LINK,
    codeRepoLink: ROUTING_PYVROOM_CODE_REPO_LINK,
  },
];
