import * as border from "../tokens/border";
import * as breakpoints from "../tokens/breakpoints";
import * as color from "../tokens/color";
import { spacing } from "../tokens/space";
import * as typography from "../tokens/typography";

export const breakpointsArray = [
  breakpoints.sm,
  breakpoints.md,
  breakpoints.rg,
  breakpoints.lg,
  breakpoints.xl,
];

const spaceArray = [
  0,
  spacing.s1,
  spacing.s2,
  spacing.s3,
  spacing.s4,
  spacing.s5,
  spacing.s6,
  spacing.s7,
  spacing.s8,
  spacing.s9,
  spacing.s10,
  spacing.s11,
];

// TODO
// const themeDarkColor = {}

const themeLightColor = {
  trueBlack: color.lightBlack,
  trueWhite: color.lightWhite,
  white: color.lightWhite,
  gray100: color.lightGray100,
  gray200: color.lightGray200,
  gray300: color.lightGray300,
  gray400: color.lightGray400,
  gray500: color.lightGray500,
  gray600: color.lightGray600,
  gray700: color.lightGray700,
  gray800: color.lightGray800,
  gray900: color.lightGray900,
  black: color.lightBlack,
  teal100: color.lightTeal100,
  teal200: color.lightTeal200,
  teal300: color.lightTeal300,
  teal400: color.lightTeal400,
  teal500: color.lightTeal500,
  teal600: color.lightTeal600,
  teal700: color.lightTeal700,
  teal800: color.lightTeal800,
  teal900: color.lightTeal900,
  red100: color.lightRed100,
  red200: color.lightRed200,
  red300: color.lightRed300,
  red400: color.lightRed400,
  red500: color.lightRed500,
  red600: color.lightRed600,
  red700: color.lightRed700,
  red800: color.lightRed800,
  red900: color.lightRed900,
  yellow100: color.lightYellow100,
  yellow200: color.lightYellow200,
  yellow300: color.lightYellow300,
  yellow400: color.lightYellow400,
  yellow500: color.lightYellow500,
  yellow600: color.lightYellow600,
  yellow700: color.lightYellow700,
  yellow800: color.lightYellow800,
  gray100T: color.lightGray100T,
  gray200T: color.lightGray200T,
  gray300T: color.lightGray300T,
  gray400T: color.lightGray400T,
  gray500T: color.lightGray500T,
  gray600T: color.lightGray600T,
  gray700T: color.lightGray700T,
  gray800T: color.lightGray800T,
  actionBackground: color.lightActionBackground,
  actionBackgroundHover: color.lightActionBackgroundHover,
  actionBackgroundActive: color.lightActionBackgroundActive,
  actionText: color.lightActionText,
  actionTextHover: color.lightActionTextHover,
  green100: color.lightGreen100,
  green200: color.lightGreen200,
  green300: color.lightGreen300,
  green400: color.lightGreen400,
  green500: color.lightGreen500,
  green600: color.lightGreen600,
  green700: color.lightGreen700,
  green800: color.lightGreen800,
  green900: color.lightGreen900,
  green: color.lightGreen,
  purple: color.lightPurple,
  orange100: color.lightOrange100,
  orange200: color.lightOrange200,
  orange300: color.lightOrange300,
  orange400: color.lightOrange400,
  orange500: color.lightOrange500,
  orange600: color.lightOrange600,
  orange700: color.lightOrange700,
  orange800: color.lightOrange800,
  orange900: color.lightOrange900,
  purple100: color.lightPurple100,
  purple200: color.lightPurple200,
  purple300: color.lightPurple300,
  purple400: color.lightPurple400,
  purple500: color.lightPurple500,
  purple600: color.lightPurple600,
  purple700: color.lightPurple700,
  purple800: color.lightPurple800,
  purple900: color.lightPurple900,
  sky100: color.lightSky100,
  sky200: color.lightSky200,
  sky300: color.lightSky300,
  sky400: color.lightSky400,
  sky500: color.lightSky500,
  sky600: color.lightSky600,
  sky700: color.lightSky700,
  sky800: color.lightSky800,
  sky900: color.lightSky900,
  yellowNeue100: color.lightYellowNeue100,
  yellowNeue200: color.lightYellowNeue200,
  yellowNeue300: color.lightYellowNeue300,
  yellowNeue400: color.lightYellowNeue400,
  yellowNeue500: color.lightYellowNeue500,
  yellowNeue600: color.lightYellowNeue600,
  yellowNeue700: color.lightYellowNeue700,
  yellowNeue800: color.lightYellowNeue800,
  yellowNeue900: color.lightYellowNeue900,
  planBasicAccent: color.lightPlanBasicAccent,
  planBasicBackground: color.lightPlanBasicBackground,
  planBasicBorder: color.lightPlanBasicBorder,
  planBasicButton: color.lightPlanBasicButton,
  planBasicButtonHover: color.lightPlanBasicButtonHover,
  planBasicButtonActive: color.lightPlanBasicButtonActive,
  planBasicBorderFocus: color.lightPlanBasicButtonBorderFocus,
  planPremiumAccent: color.lightPlanPremiumAccent,
  planPremiumBackground: color.lightPlanPremiumBackground,
  planPremiumBorder: color.lightPlanPremiumBorder,
  planPremiumButton: color.lightPlanPremiumButton,
  planPremiumButtonHover: color.lightPlanPremiumButtonHover,
  planPremiumButtonActive: color.lightPlanPremiumButtonActive,
  planPremiumBorderFocus: color.lightPlanPremiumButtonBorderFocus,
  planSdkAccent: color.lightPlanSdkAccent,
  planSdkBackground: color.lightPlanSdkBackground,
  planSdkBorder: color.lightPlanSdkBorder,
  planSdkButton: color.lightPlanSdkButton,
  planSdkButtonHover: color.lightPlanSdkButtonHover,
  planSdkButtonActive: color.lightPlanSdkButtonActive,
  planSdkBorderFocus: color.lightPlanSdkButtonBorderFocus,
  chartDenim: color.chartDenim,
  chartOrange: color.chartOrange,
  chartRed: color.chartRed,
  chartPowderBlue: color.chartPowderBlue,
  chartGreen: color.chartGreen,
  chartYellow: color.chartYellow,
  chartMaude: color.chartMaude,
  chartPink: color.chartPink,
  chartStone: color.chartStone,
  chartPurple: color.chartPurple,
  chartLime: color.chartLime,
  chartBrown: color.chartBrown,
  chartBlue: color.chartBlue,
  chartLavendar: color.chartLavendar,
  chartBrick: color.chartBrick,
  chartApricot: color.chartApricot,
  chartMoss: color.chartMoss,
  chartTaupe: color.chartTaupe,
  chartBerry: color.chartBerry,
  chartCornflower: color.chartCornflower,
  orgNavy: color.lightOrgNavy,
};

// NOTE: mode is not in use at the moment (e.g. dark, light)
/*
  Confusing...
  - `breakpoints` is for styled-system's array to responsive props
  - `breakpoint` is for theme access within styled components
  - `space` is a reserved keyword for styled-systems's
     space-based props: m={3}, p={2}, etc.
  - `spacing` is what we reference in styled components:
     e.g. ({ theme }) => theme.spacing.s1
*/
const theme = (mode?: string) => ({
  border: { ...border },
  breakpoints: breakpointsArray,
  breakpoint: breakpoints,
  color: themeLightColor, // e.g. (mode === 'light') ? themeLightColor : themeDarkColor
  mode: mode,
  space: spaceArray,
  spacing,
  ui2Typography: { ...typography.ui2 },
});

export default theme;
