import * as React from "react";
import { Link } from "react-router-dom";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";

const LogoLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
  border: 0;
`;

const renderLogo = ({
  className,
  logoColor,
  logoHeight,
  logoWidth,
  theme,
}: {
  className?: string;
  logoColor?: string;
  logoHeight?: number;
  logoWidth?: number;
  theme: any;
}) => (
  <svg
    role="img"
    aria-labelledby="nextmv-logo-svg"
    className={`nextmv-logo${className ? ` ${className}` : ""}`}
    width={logoWidth ? logoWidth : logoHeight ? undefined : "92"}
    height={logoHeight ? logoHeight : logoWidth ? undefined : "23"}
    viewBox="0 0 92 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id="nextmv-logo-svg" lang="en">
      Nextmv logo
    </title>
    <path
      d="M50.1692 23L47.2759 18.9127L44.8419 23H41.2828L45.4849 16.3639L41.1909 10.2789H44.819L47.6433 14.2973L50.0773 10.2789H53.6364L49.3655 16.7313L53.7972 23H50.1692Z"
      fill={logoColor || theme.color.teal900}
    />
    <path
      d="M60.3637 23V20.2215H59.1007C58.519 20.2215 58.1057 20.0991 57.8608 19.8541C57.6159 19.6092 57.4934 19.1806 57.4934 18.5683V13.0114H60.3407V10.2789H57.4934V7.13309H54.2557V10.2789V13.0114V18.5223C54.2557 20.1297 54.6308 21.2778 55.3809 21.9667C56.1463 22.6555 57.2408 23 58.6645 23H60.3637Z"
      fill={logoColor || theme.color.teal900}
    />
    <path
      d="M84.3671 19.7756L87.6051 10.3311H91.0743L86.333 22.9999H82.355L77.6137 10.3311H81.106L84.3671 19.7756Z"
      fill={logoColor || theme.color.teal700}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M69.8937 11.5836C70.796 10.8032 71.9723 10.3311 73.2589 10.3311C76.1013 10.3311 78.4056 12.6354 78.4056 15.4778V22.9999H71.6753V22.9999H61.3819V15.4779C61.3819 12.6354 63.6862 10.3312 66.5286 10.3312C67.8152 10.3312 68.9915 10.8032 69.8937 11.5836ZM71.6753 22.9999V15.4779C71.6753 15.4761 71.6753 15.4744 71.6753 15.4727C71.6781 14.6005 72.386 13.8942 73.2589 13.8942C74.1335 13.8942 74.8425 14.6032 74.8425 15.4778V22.9999H71.6753ZM64.9449 15.4778C64.9449 14.6032 65.6539 13.8942 66.5285 13.8942C67.4031 13.8942 68.1121 14.6032 68.1121 15.4778V22.9999H64.9449V15.4778Z"
      fill={logoColor || theme.color.teal700}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.3297 17.4573C42.362 17.1979 42.3787 16.9337 42.3787 16.6655C42.3787 13.1671 39.5427 10.3311 36.0443 10.3311C32.5459 10.3311 29.7099 13.1671 29.7099 16.6655C29.7099 20.1639 32.5459 22.9999 36.0443 22.9999C38.7027 22.9999 40.9786 21.3623 41.9182 19.0409H38.7002C38.0477 19.7699 37.0996 20.2286 36.0444 20.2286C34.3486 20.2286 32.9296 19.0441 32.5695 17.4573H39.5192C39.5192 17.4573 39.5192 17.4573 39.5192 17.4573H42.3297ZM32.8516 15.0819H39.2371C38.654 13.9087 37.4433 13.1024 36.0444 13.1024C34.6454 13.1024 33.4347 13.9087 32.8516 15.0819Z"
      fill={logoColor || theme.color.teal900}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.3755 10.3311C20.5331 10.3311 18.2288 12.6354 18.2288 15.4778V22.9999H28.5222V15.4778C28.5222 12.6354 26.218 10.3311 23.3755 10.3311ZM23.3755 13.8942C22.5009 13.8942 21.7919 14.6032 21.7919 15.4778V22.9999H24.9591V15.4778C24.9591 14.6032 24.2501 13.8942 23.3755 13.8942Z"
      fill={logoColor || theme.color.teal900}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.25053 0C1.25053 0 5.60969 3.86378 7.39297 10.6006C8.63448 15.2908 8.38369 22.9845 8.38369 22.9845H5.60969C5.60969 22.9845 1.15376 16.8064 0.259839 12.1858C-0.684919 7.30231 1.25053 0 1.25053 0ZM3.78269 5.99818C3.78269 5.99818 2.00438 7.6016 2.98778 12.2132C3.97118 16.8248 7.28626 22.0357 7.28626 22.0357C7.28626 22.0357 7.12255 18.8618 6.52892 13.8753C5.93529 8.88888 3.78269 5.99818 3.78269 5.99818Z"
      fill={logoColor || theme.color.teal900}
    />
    <path
      d="M9.27537 12.6811C8.88284 10.2895 7.98744 7.72756 7.98744 7.72756C7.98744 7.72756 8.72285 5.54799 9.86979 3.4675C11.0167 1.387 12.2475 0 12.2475 0C12.2475 0 14.9225 5.64707 14.6252 11.1951C14.4033 15.337 12.7458 19.8654 11.9198 21.8948H9.60997C9.60997 21.8948 9.7704 15.6973 9.27537 12.6811Z"
      fill={logoColor || theme.color.teal700}
    />
  </svg>
);

const Logo = ({
  className,
  logoColor,
  logoHeight,
  logoWidth,
  tabIndex,
  url,
}: {
  className?: string;
  logoColor?: string;
  logoHeight?: number;
  logoWidth?: number;
  tabIndex?: number;
  url?: string;
}) => {
  const theme = useTheme();
  return url ? (
    <LogoLink to={url} {...{ tabIndex }}>
      {renderLogo({ className, logoColor, logoHeight, logoWidth, theme })}
    </LogoLink>
  ) : (
    renderLogo({ className, logoColor, logoHeight, logoWidth, theme })
  );
};

export default Logo;
