import * as React from "react";

import ChartSolutionValueFleet from "./charts/SolutionValueFleet";
import ChartSolutionValueFunction from "./charts/SolutionValueFunction";
import ChartSolutionValueOverTime from "./charts/SolutionValueOverTime";
import { ChartPlaceholder, CustomChartStyleRules } from "./Chart.styled";
import { ChartKind, ChartProps } from "./Chart.types";

/*
  getYAxisWidth()
  ---
  dynamically calculate the y-axis width based on the max
  character count. this is required for the chart to have
  a flush left edge. the basic formula is take the max
  number of characters for the y-axis data and then multiply
  that number by 10 (average charater width in pixels with
  a generous amount of overage to avoid text label cutoff),
  take the ceiling value of the resulting product, round to
  the nearest 10 (to account for a max value of say 95 likely
  being charted with a 100 y-axis value and label) and add 8
  to match the margin between the tick value and the y-axis
*/

// constants
const charWidth = 10;
const roundToNearest = 10;
const yTickMargin = 8;

// function
export const getYAxisWidth = ({
  key,
  data,
  extraPadding,
}: {
  key: string;
  data: any[];
  extraPadding?: number;
}) => {
  // first get greatest value from data array
  const maxVal = data.reduce((acc, current) => {
    // check to make sure property exists (it always should)
    if (current.hasOwnProperty(key) && current[key] > acc) {
      return current[key];
    } else {
      return acc;
    }
  }, 0);

  // round to nearest 1000 and drop thousand marker,
  // (graph uses k abbr.) then round up to nearest 10
  // e.g. 95049 => 95 => 100
  const maxValRoundedK =
    Math.ceil(Math.round(Math.abs(maxVal) / 1000) / roundToNearest) *
    roundToNearest;

  // get number of characters in value e.g. 100 = 2
  const maxValCharCount = maxValRoundedK.toString().length;

  // multiply by char width and add tick margin
  const widthInPx =
    maxValCharCount * charWidth + yTickMargin + (extraPadding || 0);

  // return final calculated pixel value
  return widthInPx;
};

const Chart = ({
  colorArray,
  data,
  kind,
  regionsToDisplay,
  xAxisLabel,
  yAxisLabel,
}: ChartProps) => {
  const yAxisWidth = getYAxisWidth({ key: "value", data: data });

  // quick! TODO: make better
  const keys =
    !!data.length &&
    Object.keys(data[0]).filter(
      (item) => !["solution", "value"].includes(item)
    );

  const getChart = (kind: ChartKind) => {
    switch (kind) {
      case "solution-value-over-time":
        return (
          <ChartSolutionValueOverTime
            {...{
              data,
              xAxisLabel,
              yAxisLabel,
              yAxisWidth,
            }}
          />
        );
      case "solution-value-function":
        return (
          <ChartSolutionValueFunction
            {...{
              data,
              regionsToDisplay,
              xAxisLabel,
              yAxisLabel,
              yAxisWidth,
            }}
          />
        );
      case "solution-value-fleet":
        return (
          <ChartSolutionValueFleet
            {...{
              colorArray,
              data,
              keys,
              xAxisLabel,
              yAxisLabel,
              yAxisWidth,
            }}
          />
        );
    }
  };

  return (
    <CustomChartStyleRules
      className={`chart-style-${kind}`}
      yAxisWidth={yAxisWidth || 0}
    >
      {data.length ? getChart(kind) : <ChartPlaceholder />}
    </CustomChartStyleRules>
  );
};

export default Chart;
