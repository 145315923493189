import { useMemo, useReducer, useState } from "react";

import { createAppSubscription } from "../../../api/core/controlPlane";
import { useUser } from "../../../AuthProvider";
import { AnyFunction } from "../../subscription/Subscription.context.types";
import {
  AppSubscription,
  AppSubscriptionsAction,
  AppSubscriptionsActionKind,
  AppSubscriptionsState,
} from "../Marketplace.context.types";

const useAppSubscriptions = () => {
  const [{ id: accountId }] = useUser();
  const [appSubscriptions, setAppSubscriptions] = useState<AppSubscription[]>(
    []
  );
  const [appSubscriptionsLoading, setAppSubscriptionsLoading] =
    useState<boolean>(true);
  const [appSubscriptionsError, setAppSubscriptionsError] =
    useState<string>("");

  const appSubscriptionsReducer = (
    state: AppSubscriptionsState,
    action: AppSubscriptionsAction
  ): AppSubscriptionsState => {
    switch (action.type) {
      case AppSubscriptionsActionKind.ADD:
        return {
          ...state,
          loading: false,
          error: "",
        };
      case AppSubscriptionsActionKind.SET_ERROR:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case AppSubscriptionsActionKind.CLEAR_ERROR:
        return {
          ...state,
          error: "",
        };
      case AppSubscriptionsActionKind.LOADING:
        return {
          ...state,
          loading: true,
          error: "",
        };
      default:
        throw new Error("Marketplace app subscription action not supported");
    }
  };

  const [{ error, loading }, subscriptionAppsDispatch] = useReducer(
    appSubscriptionsReducer,
    {
      error: "",
      loading: false,
    }
  );

  const wrapFetch = <Func extends AnyFunction>(
    fn: Func,
    action: AppSubscriptionsActionKind
  ): ((...args: Parameters<Func>) => void) => {
    const wrappedFn = async (...args: Parameters<Func>) => {
      subscriptionAppsDispatch({ type: AppSubscriptionsActionKind.LOADING });
      try {
        const resJson = await fn(...args);
        subscriptionAppsDispatch({
          type: action,
          payload: resJson,
        });
      } catch (e: any) {
        subscriptionAppsDispatch({
          type: AppSubscriptionsActionKind.SET_ERROR,
          payload: e.message,
        });
      }
    };
    return wrappedFn;
  };

  const addNewAppSubscription = useMemo(
    () =>
      wrapFetch(
        createAppSubscription(accountId || ""),
        AppSubscriptionsActionKind.ADD
      ),
    [accountId]
  );

  const clearAppSubscriptionsActionError = () => {
    return subscriptionAppsDispatch({
      type: AppSubscriptionsActionKind.CLEAR_ERROR,
    });
  };

  return {
    appSubscriptions,
    appSubscriptionsActionLoading: loading,
    appSubscriptionsActionError: error,
    appSubscriptionsError,
    appSubscriptionsLoading,
    clearAppSubscriptionsActionError,
    addNewAppSubscription,
    setAppSubscriptions,
    setAppSubscriptionsError,
    setAppSubscriptionsLoading,
  };
};

export default useAppSubscriptions;
