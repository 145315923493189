import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { space } from "styled-system";

import { filterProps } from "../../lib/filterProps";
import { renderExtraStyles } from "../../lib/renderExtraStyles";

import {
  getControlIcon,
  getControlIconDimension,
  getControlIconExpanded,
  getControlIconPositionLeft,
  getControlIconPositionRight,
  getSummaryPaddingLeft,
  getSummaryPaddingRight,
} from "./utils/conditionalStyles";
import { StyledDetailsProps, StyledSummaryProps } from "./Details.types";

const highlightFadeOut = (color: string) => keyframes`
  0% {
    background-color: ${color};
  }
  100% {
    background-color: transparent;
  }
`;

export const StyledDetails = styled("details", {
  shouldForwardProp: (prop: any) => filterProps(prop),
})<StyledDetailsProps>`
  display: block;
  padding-top: ${({ theme }) => theme.spacing.s3};
  padding-bottom: ${({ theme }) => theme.spacing.s3};
  ${space}

  /* when details is expanded, change the summary control icon */
  &[open] {
    summary {
      &::before {
        background-image: ${({ theme }) => getControlIconExpanded({ theme })};
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.sm}) {
    padding-top: ${(props) =>
      props.size === "large" ? props.theme.spacing.s4 : props.theme.spacing.s3};
    padding-bottom: ${(props) =>
      props.size === "large" ? props.theme.spacing.s4 : props.theme.spacing.s3};
  }

  /*
    USE WITH CAUTION!
    Fix for Safari bug for opening and closing batch experiments.
    This style rule has been isolated to a prop as it will disable
    all click actions for anything in the summary prop of the
    Details component. The style rules re-enable pointer events
    for select child elements, but is not comprehensive; which is
    why it's only used in one place.
  */
  ${(props) =>
    props.usePointerEventsNone &&
    `
      summary {
        div {
          pointer-events: none;
        }
        div div {
          pointer-events: auto;
        }
      }
    `}

  /* optional top border */
  ${(props) =>
    !!props.hasBorderTop &&
    `
      border-top-width: ${props.theme.border.width};
      border-top-style: ${props.theme.border.style};
      border-top-color: ${props.theme.color.gray200T};
    `}

  /* fade out animation for highlighted metric */
  animation: ${(props) =>
    highlightFadeOut(
      props.isFocusedFade ? props.theme.color.yellow200 : "transparent"
    )} 8s 1 cubic-bezier(1, 0, 0.71, 1);

  ${(props) =>
    props.styles &&
    renderExtraStyles({
      styles: props.styles,
    })}
`;

export const StyledSummary = styled("summary", {
  shouldForwardProp: (prop: any) => filterProps(prop),
})<StyledSummaryProps>`
  display: block;
  position: relative;
  min-height: ${({ theme }) => theme.spacing.s5};

  padding-right: ${({ controlPosition, size, theme }) =>
    getSummaryPaddingRight({ controlPosition, size, theme })};
  padding-left: ${({ controlPosition, size, theme }) =>
    getSummaryPaddingLeft({ controlPosition, size, theme })};

  /* turn off default expand/collapse control */
  list-style: none;
  &::-webkit-details-marker {
    display: none;
  }

  &:hover {
    &::before {
      opacity: 1;
    }
  }

  /* custom control icon */
  &::before {
    content: "";
    display: block;
    cursor: pointer;
    opacity: 0.7;
    width: ${({ size, theme }) => getControlIconDimension({ size, theme })};
    height: ${({ size, theme }) => getControlIconDimension({ size, theme })};
    position: absolute;

    right: ${({ controlPosition }) =>
      getControlIconPositionRight({ controlPosition })};
    left: ${({ controlPosition }) =>
      getControlIconPositionLeft({ controlPosition })};
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    background-image: ${({ controlPosition, theme }) =>
      getControlIcon({ controlPosition, theme })};
    background-position: center;
    background-repeat: no-repeat;
    background-size: ${({ size, theme }) =>
      getControlIconDimension({ size, theme })}};
  }
`;
