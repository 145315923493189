import {
  CreateIntegrationPayload,
  Integration,
  IntegrationDeleteResponse,
  IntegrationKind,
  IntegrationResponse,
  IntegrationRunProfile,
  Integrations,
  IntegrationsRunProfiles,
  PutIntegrationPayload,
} from "../../api/core/controlPlane.types";

export enum IntegrationActionKind {
  UPDATE_ALL,
  UPDATE_ONE,
  ADD,
  SET_ERROR,
  LOADING,
  DELETE_ONE,
}
interface UPDATE_ALL {
  type: IntegrationActionKind.UPDATE_ALL;
  payload: Integrations;
}

interface UPDATE_ONE<T extends IntegrationKind> {
  type: IntegrationActionKind.UPDATE_ONE;
  payload: IntegrationResponse<T>;
}

interface ADD<T extends IntegrationKind> {
  type: IntegrationActionKind.ADD;
  payload: IntegrationResponse<T>;
}

interface SET_ERROR {
  type: IntegrationActionKind.SET_ERROR;
  payload: string;
}

interface LOADING {
  type: IntegrationActionKind.LOADING;
}

interface DELETE_ONE {
  type: IntegrationActionKind.DELETE_ONE;
  payload: IntegrationDeleteResponse;
}

export type IntegrationsAction<T extends IntegrationKind> =
  | UPDATE_ALL
  | UPDATE_ONE<T>
  | ADD<T>
  | SET_ERROR
  | LOADING
  | DELETE_ONE;

export type IntegrationsState = {
  integrations: Integrations;
  error: string;
  loading: boolean;
};

export type IntegrationsContextProps = {
  filterIntegrationsByName: (s: string) => Integrations;
  getFullIntegrationInfo: (
    partialIntegrationInfo: IntegrationsRunProfiles
  ) => (Integration | IntegrationRunProfile)[];
  getAllIntegrations: () => Integrations;
  filterOutIntegrationsByType: (t: IntegrationKind[]) => Integrations;
  addIntegration: <T extends IntegrationKind>(
    intKind: T,
    payload: CreateIntegrationPayload<T>
  ) => void;
  getIntegrationById: (id: string) => Integration | undefined;
  editIntegration: <T extends IntegrationKind>(
    integrationType: T,
    id: string,
    payload: PutIntegrationPayload<T>
  ) => void;
  removeIntegration: (id: string, integrationType: IntegrationKind) => void;
  fetchIntegrations: () => void;
  updateIntegrationConfiguration: (integration?: Integration) => void;
  clearIntegrationsError: () => void;
  integrationsError: string;
  integrationsLoading: boolean;
};

export type AnyFunction = (...args: any[]) => any;
