import { Box, Flex } from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import styled from "@emotion/styled";

export const MapContainerOuterFrame = styled(Box)`
  width: 100%;
  height: 100%;
  position: absolute;
`;
export const MapContainerInnerFrame = styled(Box)`
  width: 100%;
  height: 100%;
  position: absolute;
`;

export const StyledMapMessageFlex = styled(Flex)`
  padding-top: ${rem(5)};
  padding-right: ${({ theme }) => theme.spacing.s2};
  padding-bottom: ${rem(6)};
  padding-left: ${({ theme }) => theme.spacing.s2};
  position: absolute;
  left: ${({ theme }) => theme.spacing.s4};
  bottom: ${({ theme }) => theme.spacing.s4};
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: ${({ theme }) => theme.border.radiusLarge};
  z-index: 2222;
`;
