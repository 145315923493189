import { Box, Flex, Input, Select, Text } from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";

import { trackEvent } from "../../../../../analytics";
import { SHADOW_TEST_DEFAULT_MAX_RUNS } from "../../../../../utils/constants";
import { isInRange } from "../../../../../utils/validate";
import { shadowEndCriteriaDurationWeeksOptions } from "../data/endCriteriaOptions";
import { ShadowEndCriteriaDurationWeeksOption } from "../NewShadowTest.types";

type ShadowTestEndCriteriaProps = {
  setShadowEndCriteriaDurationWeeks: React.Dispatch<
    React.SetStateAction<ShadowEndCriteriaDurationWeeksOption | null>
  >;
  setShadowEndCriteriaMaxRuns: React.Dispatch<React.SetStateAction<number>>;
  setShadowEndCriteriaMaxRunsError: React.Dispatch<
    React.SetStateAction<string>
  >;
  shadowEndCriteriaDurationWeeks: ShadowEndCriteriaDurationWeeksOption | null;
  shadowEndCriteriaMaxRuns: number;
  shadowEndCriteriaMaxRunsError: string;
};

const ShadowTestEndCriteria = ({
  setShadowEndCriteriaDurationWeeks,
  setShadowEndCriteriaMaxRuns,
  setShadowEndCriteriaMaxRunsError,
  shadowEndCriteriaDurationWeeks,
  shadowEndCriteriaMaxRuns,
  shadowEndCriteriaMaxRunsError,
}: ShadowTestEndCriteriaProps) => {
  return (
    <Box width="100%" maxWidth={rem(408)}>
      <Flex alignItems="flex-start">
        <Text styleName="body-3" mt={rem(10)} styles={{ flexShrink: 0 }}>
          Maximum runs
        </Text>
        <Input
          ml={2}
          name="end-criteria-max-runs"
          htmlType="number"
          isError={!!shadowEndCriteriaMaxRunsError}
          errorMessage={shadowEndCriteriaMaxRunsError}
          errorMessageTestId="shadow-test-end-criteria-max-runs"
          placeholder={`Number of runs (max. ${SHADOW_TEST_DEFAULT_MAX_RUNS})`}
          maxLength="2"
          min={1}
          max={SHADOW_TEST_DEFAULT_MAX_RUNS}
          value={shadowEndCriteriaMaxRuns}
          onChange={(e: { target: { value: string } }) => {
            const numTargetValue = parseInt(e.target.value);

            if (shadowEndCriteriaMaxRunsError) {
              isInRange(numTargetValue || 0, 1, SHADOW_TEST_DEFAULT_MAX_RUNS) &&
                setShadowEndCriteriaMaxRunsError("");
            }
            setShadowEndCriteriaMaxRuns(numTargetValue);
          }}
          onBlur={(e: { target: { name: string; value: number } }) => {
            if (
              isInRange(e.target.value || 0, 1, SHADOW_TEST_DEFAULT_MAX_RUNS)
            ) {
              setShadowEndCriteriaMaxRunsError("");
              trackEvent("ShadowTests", {
                view: "Create Shadow Test",
                action: "Field Entered",
                meta: {
                  field: e.target.name as "end-criteria-max-runs",
                  validatePass: true,
                  value: e.target.value,
                },
              });
            } else {
              setShadowEndCriteriaMaxRunsError(
                `Number of runs must be between 1 and ${SHADOW_TEST_DEFAULT_MAX_RUNS}`
              );
              trackEvent("ShadowTests", {
                view: "Create Shadow Test",
                action: "Field Entered",
                meta: {
                  field: e.target.name as "end-criteria-max-runs",
                  validatePass: false,
                },
              });
            }
          }}
        />
      </Flex>

      <Select
        mt={2}
        isClearable
        placeholder="Select test duration (optional)"
        options={shadowEndCriteriaDurationWeeksOptions}
        value={shadowEndCriteriaDurationWeeks}
        onChange={(selection: ShadowEndCriteriaDurationWeeksOption) => {
          trackEvent("ShadowTests", {
            view: "Create Shadow Test",
            action: "Field Selected",
            meta: {
              field: "end-criteria-duration-weeks",
              value: selection && (selection.value as number),
            },
          });
          setShadowEndCriteriaDurationWeeks(selection);
        }}
      />
    </Box>
  );
};

export default ShadowTestEndCriteria;
