/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Integration,
  Integrations,
  RunProfile,
  RunProfiles,
} from "../api/core/controlPlane.types";

export const sortByString = <T extends RunProfiles | Integrations>(
  key: "name" | "id",
  arr: T
): T => {
  return arr.sort((a, b) => a[key].localeCompare(b[key])) as T;
};

export const sortByName = <T extends RunProfiles | Integrations>(
  profiles: T
): T => sortByString("name", profiles);

export const replaceById = <T extends RunProfile | Integration>(
  replacement: T,
  arr: T[]
): T[] => {
  const i = arr.findIndex((el) => el.id === replacement.id);
  return arr.slice(0, i).concat(replacement, arr.slice(i + 1, arr.length));
};

export const removeById = <T extends RunProfile | Integration>(
  toRemove: string,
  arr: T[]
): T[] => {
  const i = arr.findIndex((el) => el.id === toRemove);
  return arr.slice(0, i).concat(arr.slice(i + 1, arr.length));
};
