import { Input } from "@console/dsc";
import { InputSize } from "@console/dsc/src/components/Input/Input.types";

import { TrackEvents } from "../../../../../analytics/functions";
import ControlPanel from "../../../../../components/ControlPanel";
import {
  StandardInputType,
  UseStandardInputReturnValue,
} from "../../../../../hooks/useStandardInputs";
import { appTooltips } from "../../../data/appTooltips";

export type RunNameDescriptionProps = {
  getStandardInputsProps: <T extends keyof TrackEvents>({
    isDisabled,
    placeholder,
    testId,
    trackEventCategory,
    trackEventProperties,
    size,
    type,
  }: {
    placeholder: string;
    testId: string;
    type: StandardInputType;
    isDisabled?: boolean;
    size?: InputSize;
    trackEventCategory?: T;
    trackEventProperties?: TrackEvents[T];
  }) => Pick<UseStandardInputReturnValue, "getStandardInputsProps">;
  isClone: boolean;
  isRunning: boolean;
  refreshEditorHeight: () => void;
};

const RunNameDescription = ({
  getStandardInputsProps,
  isClone,
  isRunning,
  refreshEditorHeight,
}: RunNameDescriptionProps) => {
  return (
    <ControlPanel
      testId="create-new-run-name-description"
      headerTitle="Name & description"
      headerExtra="(optional)"
      headerTooltipContent={appTooltips.newRunNameDescription.content}
      isOpen={isClone}
      onClick={refreshEditorHeight}
    >
      <Input
        {...getStandardInputsProps({
          isDisabled: isRunning,
          placeholder: "Run name",
          testId: "new-run-name-input",
          type: "name",
          trackEventCategory: "RunHistory",
          trackEventProperties: {
            view: "Create Run",
            action: "Field Entered",
            meta: {
              field: "name",
            },
          },
        })}
      />
      <Input
        mt={1}
        {...getStandardInputsProps({
          isDisabled: isRunning,
          placeholder: "Run description",
          testId: "new-run-description-input",
          type: "description",
          trackEventCategory: "RunHistory",
          trackEventProperties: {
            view: "Create Run",
            action: "Field Entered",
            meta: {
              field: "description",
            },
          },
        })}
      />
    </ControlPanel>
  );
};

export default RunNameDescription;
