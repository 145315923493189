import React from "react";
import { Flex, Text } from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";

import { getPasswordRules } from "../../services/password";

type PasswordRulesProps = {
  password: string;
  isPasswordFocused?: boolean;
  isPasswordConfirmFocused?: boolean;
  passwordConfirm?: string;
};

const PasswordRules = ({
  isPasswordFocused,
  isPasswordConfirmFocused,
  password,
  passwordConfirm,
}: PasswordRulesProps) => {
  const theme = useTheme();
  const charCount = password.length;

  const renderValidationMessage = (isValid: boolean, message: string) => {
    return password ? (
      isValid ? (
        <Text mr={1} styleName="meta-1" textColor={theme.color.green600}>
          {message}
        </Text>
      ) : (
        <Text
          mr={1}
          styleName="meta-1"
          textColor={
            isPasswordFocused ? theme.color.gray600 : theme.color.red600
          }
        >
          {message}
        </Text>
      )
    ) : null;
  };

  const renderRuleMatchCount = (message: string, p1: string, p2: string) => {
    return charCount > 0 && p1 !== p2 ? (
      <Text
        mt={rem(1)}
        styleName="meta-1"
        textColor={
          isPasswordConfirmFocused ? theme.color.gray600 : theme.color.red600
        }
      >
        {message}
      </Text>
    ) : p1 === p2 ? (
      <Text mt={rem(1)} styleName="meta-1" textColor={theme.color.green600}>
        {message}
      </Text>
    ) : null;
  };

  return (
    <>
      {password && (
        <>
          <Text mt={2} styleName="meta-1" textColor={theme.color.gray600}>
            Password must contain at least:
          </Text>
          <Flex mt={rem(1)} flexWrap="wrap">
            {getPasswordRules(password).map((rule, index) => (
              <React.Fragment key={`pw-validation-rule-${index}`}>
                {renderValidationMessage(rule.isValid, rule.message)}
              </React.Fragment>
            ))}
          </Flex>
        </>
      )}
      {passwordConfirm &&
        renderRuleMatchCount("Passwords must match", password, passwordConfirm)}
    </>
  );
};

export default PasswordRules;
