import React, { useMemo, useState } from "react";
import {
  Box,
  Flex,
  IconCheckEncircled,
  Loading,
  Modal2,
  ProgressBar,
  Text,
} from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../analytics/";
import { useUser } from "../../AuthProvider";

import { userQuestionsByPage } from "./data/questions";
import { getUserAnswers } from "./utils/getUserAnswers";
import {
  getActionButtonLabel,
  getCompletedContent,
  getModalPageTitle,
} from "./utils/renderModalContent";
import {
  renderQuestionCheckboxOptions,
  renderQuestionDropdownOptions,
} from "./utils/renderOptions";
import {
  StyledModalContentBox,
  StyledProcessingOverlayFlex,
  StyledUserQuestionsPage,
} from "./NewUserQuestions.styled";
import { HubspotField } from "./NewUserQuestions.types";

type NewUserQuestionsProps = {
  setIsNewUser: React.Dispatch<React.SetStateAction<boolean>>;
};

const NewUserQuestions = ({ setIsNewUser }: NewUserQuestionsProps) => {
  const theme = useTheme();
  const [{ email }] = useUser();
  const [isProcessing, setIsProcessing] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [userAnswersByPage, setUserAnswersByPage] =
    useState(userQuestionsByPage);

  const totalPages = userAnswersByPage.length;
  const isFirstPage = pageNumber === 1;

  const userAnswersToBeSubmittedToHubspot = useMemo(
    () => getUserAnswers(userAnswersByPage),
    [userAnswersByPage]
  );

  const handleUserInput = (
    pageIndex: number,
    questionIndex: number,
    optionIndex: number
  ) => {
    setUserAnswersByPage((prevState) => {
      const currentValue =
        prevState[pageIndex][questionIndex].options[optionIndex].selected;

      return prevState.map((page, pIndex) => {
        if (pIndex === pageIndex) {
          return page.map((question, qIndex) => {
            if (qIndex === questionIndex) {
              const copiedQuestion = JSON.parse(JSON.stringify(question));
              const updatedOptions = [];
              if (question.type === "checkbox") {
                updatedOptions.push(
                  ...question.options.map((option, oIndex) => {
                    if (oIndex === optionIndex) {
                      const copiedOption = JSON.parse(JSON.stringify(option));
                      copiedOption.selected = !currentValue;
                      return copiedOption;
                    } else {
                      return option;
                    }
                  })
                );
              } else {
                updatedOptions.push(
                  ...question.options.map((option, oIndex) => {
                    const copiedOption = JSON.parse(JSON.stringify(option));
                    copiedOption.selected = oIndex === optionIndex;
                    return copiedOption;
                  })
                );
              }
              copiedQuestion.options = updatedOptions;
              return copiedQuestion;
            } else {
              return question;
            }
          });
        } else {
          return page;
        }
      });
    });
  };

  const getModalContent = () => {
    return (
      <StyledModalContentBox
        height={rem(390)}
        pb={6}
        mb={-2}
        hasBorderBottom
        hasBorderWidthThick
        isSubmitted
      >
        {isProcessing && (
          <StyledProcessingOverlayFlex>
            <Loading dotColor={theme.color.orange600} />
          </StyledProcessingOverlayFlex>
        )}

        <Text
          as="h3"
          mt={0}
          styleName="marketing-header-4"
          styles={{ color: theme.color.gray800 }}
        >
          {getModalPageTitle(pageNumber, isSubmitted)}
        </Text>

        {isSubmitted && getCompletedContent()}

        {!isSubmitted &&
          userAnswersByPage.map((page, pageIndex) => {
            return (
              <StyledUserQuestionsPage
                key={`page-${pageIndex}`}
                isActive={pageIndex === pageNumber - 1}
              >
                {page.map((question, questionIndex) => {
                  return (
                    <Box
                      key={`question-${questionIndex}`}
                      mt={4}
                      mr={rem(1)}
                      ml={rem(1)}
                    >
                      <Text
                        as="h3"
                        styleName="body-1-plus-bold"
                        styles={{ color: theme.color.gray800 }}
                      >
                        {question.title}
                      </Text>

                      <Box mt={1}>
                        {question.type === "checkbox" &&
                          renderQuestionCheckboxOptions({
                            handleUserInput,
                            options: question.options,
                            pageIndex,
                            questionIndex,
                          })}
                        {question.type === "dropdown" &&
                          renderQuestionDropdownOptions({
                            handleUserInput,
                            options: question.options,
                            orientation: question.orientation,
                            pageIndex,
                            questionIndex,
                          })}
                      </Box>
                    </Box>
                  );
                })}
              </StyledUserQuestionsPage>
            );
          })}
      </StyledModalContentBox>
    );
  };

  const getBarWidthPercentValue = (): number => {
    if (isSubmitted) return 1;
    if (isProcessing) return 0.95;
    return (pageNumber - 1) / totalPages;
  };
  const getModalPageNav = () => {
    const barWidthPercent = `${getBarWidthPercentValue() * 100}%`;
    const currentPageNumber = pageNumber;

    return (
      <Flex
        alignItems="center"
        ml={8}
        mb={9}
        style={{ position: "absolute", left: 0, bottom: 0 }}
      >
        <Text
          mr={4}
          styleName="body-3"
          styles={{
            minWidth: rem(36),
            flexShrink: 0,
            color: theme.color.gray600,
          }}
        >
          {currentPageNumber} of {totalPages}
        </Text>

        <Box width={rem(200)} mr={2} style={{ flexShrink: 0 }}>
          <ProgressBar barWidth={barWidthPercent} />
        </Box>

        <IconCheckEncircled
          iconSize={20}
          iconColor={isSubmitted ? theme.color.green600 : theme.color.gray400}
        />
      </Flex>
    );
  };

  const submitQuestions = () => {
    const submitBtn = document.getElementById("submit-btn");
    if (submitBtn) {
      submitBtn.click();
    }

    const trackingMetaData: { [key in HubspotField]?: string[] | string } = {};
    Object.entries(userAnswersToBeSubmittedToHubspot).forEach(
      ([key, { value }]) => {
        trackingMetaData[key as HubspotField] = value;
      }
    );

    trackEvent("Marketplace", {
      action: "New User Questions Submitted",
      view: "Marketplace Apps",
      meta: trackingMetaData,
    });

    setTimeout(() => {
      setIsProcessing(false);
      setIsSubmitted(true);
    }, 1500);
  };

  const handleCloseAction = (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    e.preventDefault();
    e.stopPropagation();

    setIsNewUser(false);
    return;
  };

  const handlePrimaryActionOnClick = (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    e.preventDefault();
    e.stopPropagation();

    if (isSubmitted) {
      handleCloseAction(e);
      return;
    }

    if (pageNumber === userQuestionsByPage.length) {
      setIsProcessing(true);
      return submitQuestions();
    }

    setPageNumber((prevState) => prevState + 1);

    return;
  };

  const handleSecondaryActionOnClick = (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    e.preventDefault();
    e.stopPropagation();

    if (isFirstPage) {
      return;
    }
    if (pageNumber <= userQuestionsByPage.length) {
      setPageNumber((prevState) => prevState - 1);
    }
    return;
  };

  return (
    <>
      <Modal2
        isActive={true}
        width={rem(600)}
        maxHeight={rem(680)}
        closeActionOnClick={handleCloseAction}
        children={getModalContent()}
        footerExtra={getModalPageNav()}
        primaryActionStyles={{
          order: 1,
          marginLeft: isFirstPage || isSubmitted ? "auto" : 0,
        }}
        primaryIsLoading={isProcessing}
        secondaryActionStyles={{
          order: 0,
          marginRight: theme.spacing.s1,
          marginLeft: "auto",
        }}
        primaryActionLabel={getActionButtonLabel(
          pageNumber,
          totalPages,
          isSubmitted
        )}
        primaryActionOnClick={handlePrimaryActionOnClick}
        {...(!isSubmitted &&
          !isFirstPage && {
            secondaryActionLabel: "← Back",
            secondaryActionOnClick: handleSecondaryActionOnClick,
          })}
      />

      <form
        style={{ display: "none" }}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        {Object.entries(userAnswersToBeSubmittedToHubspot).map(
          ([key, { type, value }]) => {
            if (type === "checkbox" && Array.isArray(value)) {
              return (
                <input
                  type="text"
                  id={key}
                  name={key}
                  value={`;${value.join(";")}`}
                />
              );
            }
            return <input type="text" id={key} name={key} value={value} />;
          }
        )}
        <input type="email" name="email" value={email || ""} />
        <button id="submit-btn" type="submit" />
      </form>
    </>
  );
};

export default NewUserQuestions;
