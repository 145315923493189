import * as React from "react";
import { Box, Flex } from "@console/dsc";
import { useTheme } from "@emotion/react";

import {
  LoadingContainer,
  LoadingDots,
  StyledFullScreenContainer,
} from "./Loading.styles";
import { LoadingProps } from "./Loading.types";

const renderLoadingDots = (dotColor: string) => (
  <LoadingDots {...{ dotColor }} role="alert" aria-busy="true">
    Loading
  </LoadingDots>
);

const renderSinglePage = (dotColor: string) => (
  <Flex width="100%" height="100%" justifyContent="center" alignItems="center">
    <Box pb={11}>{renderLoadingDots(dotColor)}</Box>
  </Flex>
);

const renderFullScreen = (dotColor: string) => (
  <StyledFullScreenContainer>
    <Box pb={11}>{renderLoadingDots(dotColor)}</Box>
  </StyledFullScreenContainer>
);

const Loading = ({ dotColor, type }: LoadingProps) => {
  const theme = useTheme();
  const thisDotColor = dotColor || theme.color.gray500;

  if (type === "no-container") return renderLoadingDots(thisDotColor);
  if (type === "single-page") return renderSinglePage(thisDotColor);
  if (type === "full-screen") return renderFullScreen(thisDotColor);

  return <LoadingContainer>{renderLoadingDots(thisDotColor)}</LoadingContainer>;
};

export default Loading;
