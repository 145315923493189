import React from "react";
import ReactDOM from "react-dom";
// NOTE: had to move Router here so I could access
//       useLocation() hook in App.tsx
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider as CustomThemeProvider } from "@console/dsc";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <React.StrictMode>
    <CustomThemeProvider>
      <Router>
        <App />
      </Router>
    </CustomThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
