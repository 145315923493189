import React from "react";
import { Box, Text } from "@console/dsc";
import { useTheme } from "@emotion/react";

type ExperimentDetailsSectionProps = {
  children: React.ReactNode;
  title: string;
};

const ExperimentDetailsSection = ({
  children,
  title,
}: ExperimentDetailsSectionProps) => {
  const theme = useTheme();

  return (
    <Box
      mt={[6, 8]}
      pt={[5, 7]}
      ml={[0, -6]}
      mr={[0, -7]}
      pr={[0, 7]}
      pl={[0, 6]}
      hasBorderTop
    >
      <Text
        as="h2"
        styleName="header-1"
        styles={{ color: theme.color.gray800 }}
      >
        {title}
      </Text>

      <Box mt={4}>{children}</Box>
    </Box>
  );
};

export default ExperimentDetailsSection;
