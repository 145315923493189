import { trackEvent } from "../../../../../analytics";
import { MainViewType } from "../RunDetails.types";

type GetMainViewTabsParams = {
  mainView: MainViewType;
  hasSeriesData?: boolean;
  isDetailsDisabled?: boolean;
  isInputDisabled?: boolean;
  isLogsDisabled?: boolean;
  isResultDisabled?: boolean;
  setMainView: React.Dispatch<React.SetStateAction<MainViewType>>;
};

type TabOnClickProps = {
  preventDefault: () => void;
  stopPropagation: () => void;
};

export const getMainViewTabs = ({
  mainView,
  hasSeriesData,
  isDetailsDisabled,
  isInputDisabled,
  isLogsDisabled,
  isResultDisabled,
  setMainView,
}: GetMainViewTabsParams) => {
  const mainViewTabs = [
    {
      id: "tab-details",
      label: "Details",
      isActive: mainView === "details",
      isDisabled: isDetailsDisabled,
      onClick: (e: TabOnClickProps) => {
        e.preventDefault();
        e.stopPropagation();
        trackEvent("RunHistory", {
          view: "Run Details",
          action: "Details Tab Clicked",
        });
        return setMainView("details");
      },
    },
    {
      id: "tab-input",
      label: "Input",
      isActive: mainView === "input",
      isDisabled: isInputDisabled,
      onClick: (e: TabOnClickProps) => {
        e.preventDefault();
        e.stopPropagation();
        trackEvent("RunHistory", {
          view: "Run Details",
          action: "Input Tab Clicked",
        });
        return setMainView("input");
      },
    },
    {
      id: "tab-result",
      label: "Result",
      isActive: mainView === "result",
      isDisabled: isResultDisabled,
      onClick: (e: TabOnClickProps) => {
        e.preventDefault();
        e.stopPropagation();
        trackEvent("RunHistory", {
          view: "Run Details",
          action: "Result Tab Clicked",
        });
        return setMainView("result");
      },
    },
    {
      id: "tab-logs",
      label: "Logs",
      isActive: mainView === "logs",
      isDisabled: isLogsDisabled,
      onClick: (e: TabOnClickProps) => {
        e.preventDefault();
        e.stopPropagation();
        trackEvent("RunHistory", {
          view: "Run Details",
          action: "Logs Tab Clicked",
        });
        return setMainView("logs");
      },
    },
    ...(hasSeriesData
      ? [
          {
            id: "tab-analysis",
            label: "Analysis",
            isActive: mainView === "analysis",
            isDisabled: isResultDisabled,
            onClick: (e: TabOnClickProps) => {
              e.preventDefault();
              e.stopPropagation();
              return setMainView("analysis");
            },
            tooltipContent:
              "The analysis view is available if the run result contains a series_data block in the statistics block of the output.",
            tooltipExtraLinkLabel: "More",
            tooltipExtraLinkUrl:
              "https://www.nextmv.io/docs/vehicle-routing/reference/input-output-schema#statistics",
          },
        ]
      : []),
  ];

  return mainViewTabs;
};
