import { ConfigInput, Coords, MarkerPoint } from "../Map.types";

import getInputVehiclePoints from "./getInputVehiclePoints";

export function getMapMarkerPoints(configInput: ConfigInput) {
  if (!configInput) return [];

  const points: MarkerPoint[] =
    configInput && configInput.stops?.length
      ? configInput.stops.reduce((locations, l) => {
          if (l.position && l.position.lat && l.position.lon) {
            locations.push({
              position: [l.position.lat, l.position.lon] as Coords,
              name: l.id,
            });
          }
          if (l.location && l.location.lat && l.location.lon) {
            locations.push({
              position: [l.location.lat, l.location.lon] as Coords,
              name: l.id,
            });
          }
          return locations;
        }, [] as MarkerPoint[])
      : ([] as MarkerPoint[]);
  return configInput
    ? points.concat(getInputVehiclePoints(configInput))
    : points;
}
