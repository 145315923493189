import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import {
  Box,
  Button2,
  Flex,
  IconLock,
  Input,
  Notification,
  RowDetail,
  Text,
} from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";
import { kebabCase } from "lodash";

import { trackEvent } from "../../../../analytics";
import {
  Configuration,
  CreateIntegrationPayload,
  IntegrationKind,
} from "../../../../api/core/controlPlane.types";
import Loading from "../../../../components/Loading";
import { useIntegrations } from "../../../../contexts/integrations/Integrations.context";
import { ENTITY_ID_CHAR_COUNT_MAX } from "../../../../utils/constants";
import { CONTENT_ID_RULES } from "../../../../utils/systemIds";
import { getIdError } from "../../../Config/utils/idError";
import { integrationsTooltipCopy } from "../../data/microcopy";
import {
  AvailableIntegrationName,
  PrivateInput,
  PublicInput,
} from "../../Integrations.types";
import FeatureCompatibility from "../FeatureCompatibility";

type NewIntegrationFormProps = {
  integrationName: AvailableIntegrationName;
  integrationType: IntegrationKind;
  basePath: string;
  privateInputs?: PrivateInput[];
  publicInputs?: PublicInput[];
};

const NewIntegrationForm = ({
  integrationName,
  basePath,
  integrationType,
  privateInputs,
  publicInputs,
}: NewIntegrationFormProps) => {
  const theme = useTheme();

  const {
    addIntegration,
    getIntegrationById,
    integrationsLoading,
    integrationsError,
  } = useIntegrations();

  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [idUpdated, setIdUpdated] = useState(false);
  const [description, setDescription] = useState("");
  const [errors, setErrors] = useState<{ id: string }>({ id: "" });
  const [submitted, setSubmitted] = useState(false);

  const [privateInputValues, setPrivateInputValues] = useState<{
    [key: string]: any;
  }>({});

  const [additionalConfig, setAdditionalConfig] = useState<{
    [key: string]: any;
  }>({});

  const idInputError = getIdError({
    id: id,
    type: integrationName,
    checkIdFunc: getIntegrationById,
  });

  const privateInputsMissing = React.useMemo(() => {
    return !!privateInputs?.find(
      (privateInput) =>
        !privateInputValues[privateInput.id] && privateInput.isRequired
    );
  }, [privateInputs, privateInputValues]);

  const handlePrivateInputChange: (event: {
    target: { value: string; name: string };
  }) => void = ({ target: { value, name } }) => {
    setPrivateInputValues({ ...privateInputValues, [name]: value });
  };

  const handleCreateNewIntegration = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    if (id.length < 3) {
      setErrors({
        id: CONTENT_ID_RULES,
      });
      return;
    }

    trackEvent("Integrations", {
      view: "Add New",
      action: "New Integration Created",
      meta: {
        type: integrationType,
      },
    });

    const payload = {
      name,
      id,
      description,
      configuration: { ...privateInputValues, ...additionalConfig },
    };

    addIntegration(
      integrationType,
      payload as CreateIntegrationPayload<IntegrationKind>
    );

    setSubmitted(true);
  };

  const handleChangeIntegrationNewName = (value: string) => {
    if (!idUpdated) {
      setId(kebabCase(value));
    }
    setName(value);
  };

  const handleChangeIntegrationNewId = (value: string) => {
    setId(value);
    setIdUpdated(true);
  };

  type IntegrationFieldNames = ("name" | "id") &
    Configuration[typeof integrationType];
  const handleInputTrack = ({
    target: { name: nameAttr, value },
  }: {
    target: { name: IntegrationFieldNames; value: string };
  }) => {
    if (value) {
      if (nameAttr === "id" && value !== kebabCase(name)) {
        trackEvent("Integrations", {
          view: "Add New",
          action: "Integration ID Changed",
          meta: {
            type: integrationType,
          },
        });
        return;
      }
      if (nameAttr !== "id") {
        trackEvent("Integrations", {
          view: "Add New",
          action: "Field Entered",
          meta: {
            field: nameAttr,
            type: integrationType,
          },
        });
      }
    }
  };

  const renderPublicInput = (input: PublicInput) => {
    switch (input.type) {
      case "onfleetFeatureCompatibility":
        return (
          <FeatureCompatibility
            isEditable
            onCompatibilityValueChange={(newValues) =>
              setAdditionalConfig({
                ...additionalConfig,
                compatibility: newValues,
              })
            }
          />
        );
      default:
        return null;
    }
  };

  if (integrationsLoading) {
    return <Loading />;
  }

  if (submitted && !integrationsError) {
    return <Redirect to="/config/integrations/installed" />;
  }

  return (
    <form onSubmit={(e) => handleCreateNewIntegration(e)}>
      <RowDetail
        property="Name"
        secondaryLabel="For reference only"
        render={
          <Box width="100%" maxWidth={rem(408)}>
            <Input
              htmlType="text"
              name="name"
              placeholder="Name"
              value={name}
              onChange={(e: { target: { value: string } }) =>
                handleChangeIntegrationNewName(e.target.value)
              }
              onBlur={handleInputTrack}
            />
          </Box>
        }
      />

      <RowDetail
        property="ID"
        secondaryLabel="Cannot be modified once created"
        tooltipCopy={integrationsTooltipCopy.id.content}
        // tooltipExtraLinkLabel={integrationsTooltipCopy.id.extraLinkLabel}
        // tooltipExtraLinkUrl={integrationsTooltipCopy.id.extraLinkUrl}
        render={
          <Box width="100%" maxWidth={rem(408)}>
            <Input
              htmlType="text"
              name="id"
              isError={(!!errors.id && id.length < 3) || idInputError}
              errorMessage={idInputError || errors.id}
              errorMessageTestId="id-error-message"
              placeholder="Unique ID"
              maxLength={ENTITY_ID_CHAR_COUNT_MAX}
              value={id}
              onChange={(e: { target: { value: string } }) =>
                handleChangeIntegrationNewId(e.target.value)
              }
              onBlur={handleInputTrack}
            />
          </Box>
        }
      />

      <RowDetail
        property="Description"
        secondaryLabel="(optional)"
        render={
          <Box width="100%" maxWidth={rem(408)}>
            <Input
              name="description"
              htmlType="text"
              placeholder="Integration description"
              value={description}
              onChange={(e: { target: { value: string } }) =>
                setDescription(e.target.value)
              }
            />
          </Box>
        }
      />

      {privateInputs &&
        privateInputs.map((privateInput: PrivateInput, index: number) => (
          <RowDetail
            key={privateInput.id}
            property={privateInput.label}
            tooltipCopy={privateInput?.tooltipCopy?.content}
            render={
              <Box width="100%" maxWidth={rem(408)}>
                <Input
                  data-testid="access-key-input"
                  name={privateInput.id}
                  htmlType="text"
                  icon={<IconLock iconColor={theme.color.green500} />}
                  placeholder={privateInput.placeholder}
                  value={privateInputValues[privateInput.id]}
                  onChange={handlePrivateInputChange}
                  onBlur={handleInputTrack}
                  styles={{
                    color: theme.color.green800,
                    borderColor: theme.color.green500,
                    "&::placeholder": {
                      color: "#67C7A2",
                    },
                    "&:focus-visible": {
                      boxShadow: `0 0 ${theme.spacing.s1} 0 ${theme.color.green300}`,
                    },
                    "&:active, &:focus": {
                      borderColor: theme.color.green600,
                    },
                  }}
                />
              </Box>
            }
          />
        ))}

      {publicInputs &&
        publicInputs.map((input) => (
          <RowDetail
            key={input.id}
            property={input.label}
            secondaryLabel={input.secondaryLabel}
            tooltipCopy={input.tooltipCopy}
            render={renderPublicInput(input)}
          ></RowDetail>
        ))}

      <Flex
        hasBorderTop
        hasBorderWidthThick
        pt={4}
        justifyContent="space-between"
      >
        <Box>
          {integrationsError && (
            <Notification
              data-testid="new-integration-error-message"
              mt={1}
              mb={5}
              type="error"
              hasContactExtra
              message={`Error: ${integrationsError}`}
            />
          )}

          <Button2
            data-testid="new-integration-submit-button"
            isDisabled={!name || !id || idInputError || privateInputsMissing}
            label={`Create new ${integrationName}`}
            size="large"
            htmlType="submit"
          />

          <Box mt={2} maxWidth={rem(300)}>
            <Text styleName="body-3" hasLink>
              By adding the {integrationName}, you agree to the{" "}
              <a
                href="https://www.nextmv.io/integration-agreement"
                target="_blank"
                rel="noreferrer"
              >
                Integration Terms and Conditions
              </a>
              .
            </Text>
          </Box>
        </Box>

        <Button2
          data-testid="cancel-create"
          ml="auto"
          type="text"
          size="large"
          label="Cancel"
          to={basePath}
          onClick={() =>
            trackEvent("Integrations", {
              view: "Add New",
              action: "New Integration Canceled",
              meta: {
                type: integrationType,
              },
            })
          }
        />
      </Flex>
    </form>
  );
};

export default NewIntegrationForm;
