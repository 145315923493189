import { CustomerHSDSolution } from "../../../types/SchemaCustomerHSD";
import { NextrouteSolution } from "../../../types/SchemaNextroute";
import { RouterSolution } from "../../../types/SchemaRouter";
import { RoutingLegacySolution } from "../../../types/SchemaRoutingLegacy";

export const getModifiedNextrouteOutputSolutions = (runDetailsOuput: any) => {
  const runDetailsOutputSolutionsModified = runDetailsOuput?.solutions?.map(
    (solution: NextrouteSolution) => {
      return {
        statistics: runDetailsOuput.statistics,
        state: {
          unassigned: solution.unplanned.map((unplannedStop) => {
            const { location, ...rest } = unplannedStop;
            return {
              lon: location.lon,
              lat: location.lat,
              ...rest,
            };
          }),
          vehicles: solution.vehicles.map((vehicle) => {
            const newRoute = vehicle.route.map((part) => {
              const { location, ...rest } = part.stop;
              const {
                arrival_time,
                end_time,
                start_time,
                travel_duration,
                cumulative_travel_duration,
                travel_distance,
                cumulative_travel_distance,
              } = part;

              return {
                ...rest,
                lon: location.lon,
                lat: location.lat,
                ...(arrival_time && { eta: arrival_time }),
                ...(end_time && { etd: end_time }),
                ...(start_time && { ets: start_time }),
                ...(travel_duration && { travel_duration }),
                ...(cumulative_travel_duration && {
                  cumulative_travel_duration,
                }),
                ...(travel_distance && { travel_distance }),
                ...(cumulative_travel_distance && {
                  cumulative_travel_distance,
                }),
              };
            });

            return {
              ...vehicle,
              route: newRoute,
              travel_time: vehicle?.route_travel_duration,
              travel_distance: vehicle?.route_travel_distance,
              // nextroute
              route_duration: vehicle?.route_duration,
              stops_duration: vehicle?.route_stops_duration,
              waiting_duration: vehicle?.route_waiting_duration,
            };
          }),
        },
      };
    }
  );
  return runDetailsOutputSolutionsModified;
};

export const getModifiedRouterOutputSolutions = (runDetailsOuput: any) => {
  const runDetailsOutputSolutionsModified = runDetailsOuput?.solutions?.map(
    (solution: RouterSolution) => {
      return {
        statistics: solution.statistics,
        state: {
          unassigned: solution.store.unassigned.map((unassignedStop) => {
            const { position, ...rest } = unassignedStop;
            return {
              lon: position.lon,
              lat: position.lat,
              ...rest,
            };
          }),
          vehicles: solution.store.vehicles.map((vehicle) => {
            const newRoute = vehicle.route.map((part) => {
              const {
                position,
                estimated_arrival,
                estimated_departure,
                estimated_service,
                ...rest
              } = part;

              return {
                ...rest,
                lon: position.lon,
                lat: position.lat,
                ...(estimated_arrival && { eta: estimated_arrival }),
                ...(estimated_service && { ets: estimated_service }),
                ...(estimated_departure && { etd: estimated_departure }),
              };
            });

            return {
              ...vehicle,
              route: newRoute,
              travel_time: vehicle?.route_duration,
            };
          }),
        },
      };
    }
  );
  return runDetailsOutputSolutionsModified;
};

export const getModifiedRoutingLegacyOutputSolutions = (
  runDetailsOuput: RoutingLegacySolution
) => {
  // this is just a pass through because the routing visualizer
  // is based on the same schema as the Routing Legacy app
  // however, must be returned in array to match v0 DEMO schema
  return [runDetailsOuput];
};

export const getModifiedCustomerHSDOutputSolutions = (runDetailsOuput: any) => {
  const runDetailsOutputSolutionsModified = runDetailsOuput?.solutions?.map(
    (solution: CustomerHSDSolution) => {
      return {
        // statistics: solution.statistics,
        state: {
          unassigned: solution.store.unassigned.map((unassignedStop) => {
            const { location, ...rest } = unassignedStop;
            return {
              lon: location.lon,
              lat: location.lat,
              ...rest,
            };
          }),
          vehicles: solution.store.vehicles
            .map((vehicle) => {
              if (vehicle.route === null) {
                return {
                  ...vehicle,
                  route: [],
                  value: vehicle?.route_duration,
                  travel_time: vehicle?.route_duration,
                };
              }

              const newRoute = vehicle.route.map((part) => {
                const { location, ...rest } = part.stop;
                const { arrival_time, start_time } = part;

                return {
                  ...rest,
                  lon: location.lon,
                  lat: location.lat,
                  ...(arrival_time && { eta: arrival_time }),
                  ...(start_time && { etd: start_time }),
                };
              });

              return {
                ...vehicle,
                route: newRoute,
                value: vehicle?.route_duration,
                travel_time: vehicle?.route_duration,
              };
            })
            .filter(Boolean),
        },
      };
    }
  );
  return runDetailsOutputSolutionsModified;
};
