import { useState } from "react";

import { cancelBatchExperiment } from "../../../../../api/core/controlPlane";
import { EntityErrorMessage } from "../../../../../api/core/controlPlane.types";
import { useUser } from "../../../../../AuthProvider";
import { StartStopModalType } from "../../../types/Experiment.types";

const useBatchExperimentCancel = (appId: string, batchExperimentId: string) => {
  const [{ id: accountId }] = useUser();

  const [displayConfirmModal, setDisplayConfirmModal] =
    useState<StartStopModalType | null>(null);
  const [cancelModalActionsError, setCancelModalActionsError] =
    useState<EntityErrorMessage>(null);

  const handleBatchExperimentCancel = async (modalType: StartStopModalType) => {
    try {
      await cancelBatchExperiment(accountId || "")(appId, batchExperimentId);
      setDisplayConfirmModal(null);
    } catch (e) {
      setCancelModalActionsError((e as Error).message);
    }
  };

  return {
    cancelModalActionsError,
    displayConfirmModal,
    handleBatchExperimentCancel,
    setCancelModalActionsError,
    setDisplayConfirmModal,
  };
};

export default useBatchExperimentCancel;
