import { Flex } from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import styled from "@emotion/styled";

export const IntegrationsContainer = styled(Flex)`
  width: calc(100% + ${({ theme }) => theme.spacing.s4});
`;

export const MoreLink = styled.a`
  display: flex;
  align-items: center;
  position: absolute;
  right: ${({ theme }) => theme.spacing.s3};
  top: ${({ theme }) => theme.spacing.s3};
  font-family: ${({ theme }) => theme.ui2Typography.fontFamilyMeta1Bold};
  font-size: ${({ theme }) => theme.ui2Typography.fontSizeMeta1Bold};
  font-style: ${({ theme }) => theme.ui2Typography.fontStyleMeta1Bold};
  font-weight: ${({ theme }) => theme.ui2Typography.fontWeightMeta1Bold};
  line-height: ${({ theme }) => theme.ui2Typography.lineHeightMeta1Bold};
  letter-spacing: ${({ theme }) => theme.ui2Typography.letterSpacingMeta1Bold};
  text-transform: ${({ theme }) => theme.ui2Typography.textTransformMeta1Bold};
  text-decoration: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  color: ${({ theme }) => theme.color.gray600};

  span {
    margin-right: ${rem(2)};
  }
  svg {
    opacity: 0.67;
  }

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.color.gray700};
    svg {
      opacity: 1;
    }
  }
`;
