import React, { useMemo } from "react";
import { getYAxisWidth } from "@console/dsc/src/components/Chart/Chart.component";
import { CustomChartStyleRules } from "@console/dsc/src/components/Chart/Chart.styled";
import CustomLabel from "@console/dsc/src/components/Chart/custom/Label";
import { getTickLabelStyle } from "@console/dsc/src/components/Chart/utils/chartStyles";
import { useTheme } from "@emotion/react";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { AcceptanceTestMetricOperator } from "../../../api/core/controlPlane.types";
import { formatIndicatorValue } from "../utils/groupedSummaryTable";

import CustomTooltipAcceptanceChart from "./CustomTooltipAcceptanceChart";
import { getRange } from "./utils";

type ChartAreaAcceptanceProps = {
  //TODO add chart data type
  data: any;
  indicator: string;
  xAxisLabel: string;
  yAxisLabel: string;
  sense: keyof AcceptanceTestMetricOperator;
};

const ChartAreaAcceptance = ({
  data,
  indicator,
  xAxisLabel,
  yAxisLabel,
  sense,
}: ChartAreaAcceptanceProps) => {
  const theme = useTheme();
  const yAxisWidth = getYAxisWidth({
    key: "y",
    data: data,
    extraPadding: 48,
  });
  const tickLabelStyle = getTickLabelStyle(theme);

  const range = getRange(
    data.map((d: any) => d.x).concat(data.map((d: any) => d.y))
  );

  let chartData = useMemo(
    () =>
      data.map((chart: any) => {
        return (
          chart && {
            tooltipTitle: chart.inputId,
            control: chart.x,
            candidate: chart.y,
            min: chart.y,
            controlName: xAxisLabel,
            candidateName: yAxisLabel,
          }
        );
      }),
    [data, xAxisLabel, yAxisLabel]
  );

  chartData = [
    {
      areaLine: [range.min, range.min],
      areaLineTwo: [range.min, range.max],
      control: range.min,
      tooltipTitle: "",
    },
    ...chartData,
    {
      areaLine: [range.max, range.min],
      areaLineTwo: [range.max, range.max],
      control: range.max,
      tooltipTitle: "",
    },
  ];

  const getAreaFillColor = (
    area: "bottom" | "top",
    sense: keyof AcceptanceTestMetricOperator
  ) => {
    if (sense === "ne") {
      return theme.color.green200;
    }
    if (area === "bottom") {
      if (["lt", "le"].includes(sense)) {
        return theme.color.green200;
      } else {
        return theme.color.red200;
      }
    }
    if (["gt", "ge"].includes(sense)) {
      return theme.color.green200;
    } else {
      return theme.color.red200;
    }
  };

  const getMidlineStrokeColor = (sense: keyof AcceptanceTestMetricOperator) => {
    if (["eq", "ge", "le"].includes(sense)) {
      return theme.color.green600;
    }
    return theme.color.red600;
  };

  return (
    <CustomChartStyleRules
      className={`chart-style-grouped-summary-box-plot`}
      yAxisWidth={yAxisWidth || 0}
      style={{ width: "100%", position: "relative" }}
    >
      <ResponsiveContainer minHeight={350} width="100%">
        <ComposedChart
          data={chartData}
          margin={{ top: 0, bottom: 20, left: 50 }}
        >
          <CartesianGrid
            stroke={theme.color.gray200T}
            style={{
              backgroundColor: theme.color.gray100,
            }}
          />

          <Area
            connectNulls
            dot={false}
            activeDot={false}
            label={false}
            type="monotone"
            dataKey="areaLine"
            fill={getAreaFillColor("bottom", sense)}
            stroke=""
            isAnimationActive={false}
          />
          <Area
            connectNulls
            dot={false}
            activeDot={false}
            label={false}
            type="monotone"
            dataKey="areaLineTwo"
            fill={getAreaFillColor("top", sense)}
            stroke=""
            isAnimationActive={false}
          />

          <ReferenceLine
            segment={[
              { x: range.min, y: range.min },
              { x: range.max, y: range.max },
            ]}
            strokeDasharray="7 4"
            strokeWidth={2.5}
            stroke={getMidlineStrokeColor(sense)}
          />

          <Scatter
            dataKey="candidate"
            name="control"
            fill={theme.color.gray800}
            stroke={theme.color.white}
            strokeWidth={2}
            isAnimationActive={false}
            style={{
              backgroundColor: theme.color.red500,
            }}
          />

          <Tooltip
            content={<CustomTooltipAcceptanceChart {...{ data }} />}
            isAnimationActive={false}
            cursor={{ stroke: theme.color.gray300, strokeWidth: 1 }}
          />

          <XAxis
            type="number"
            dataKey="control"
            label={<CustomLabel isBold>{xAxisLabel}</CustomLabel>}
            domain={[range.min, range.max]}
            allowDataOverflow={true}
            stroke={theme.color.gray300}
            style={tickLabelStyle}
            tickCount={6}
            tickLine={false}
            tickMargin={5}
            tickFormatter={(value) =>
              formatIndicatorValue({
                indicator,
                type: "chart-value",
                value,
              })
            }
          />

          <YAxis
            type="number"
            label={
              <CustomLabel isYAxis isBold>
                {yAxisLabel}
              </CustomLabel>
            }
            domain={[range.min, range.max]}
            allowDataOverflow={true}
            stroke={theme.color.gray300}
            style={tickLabelStyle}
            tickCount={6}
            tickLine={false}
            tickMargin={5}
            tickFormatter={(value) =>
              formatIndicatorValue({ indicator, type: "chart-value", value })
            }
            width={yAxisWidth}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </CustomChartStyleRules>
  );
};

export default ChartAreaAcceptance;
