import {
  PlanResponse,
  PromotionalCode,
  Subscription,
} from "../../api/payment/controlPlane.types";

export type ReceiptData = {
  amount: number;
  id: string;
  created: number;
  couponCode?: string;
};

export enum SubscriptionActionKind {
  UPDATE,
  CREATE,
  SET_ERROR,
  LOADING,
}
interface UPDATE {
  type: SubscriptionActionKind.UPDATE;
  payload: Subscription;
}

interface CREATE {
  type: SubscriptionActionKind.CREATE;
  payload: Subscription;
}

interface SET_ERROR {
  type: SubscriptionActionKind.SET_ERROR;
  payload: string;
}

interface LOADING {
  type: SubscriptionActionKind.LOADING;
  payload: boolean;
}

export type SubscriptionAction = UPDATE | CREATE | SET_ERROR | LOADING;

export type SubscriptionState = {
  subscription: Subscription | null | undefined;
  error: string;
  loading: boolean;
};

export type SubscriptionContextProps = {
  subscription: Subscription | null | undefined;
  setSubscription: (subscription: Subscription) => void;
  fetchSubscription: () => void;
  subscriptionError: string;
  setSubscriptionError: (error: string) => void;
  subscriptionLoading: boolean;
  setSubscriptionLoading: (loading: boolean) => void;
  pendingSetupIntentSecret: string;
  setPendingSetupIntentSecret: (secret: string) => void;
  receiptData: ReceiptData | undefined;
  setReceiptData: (data: ReceiptData | undefined) => void;
  createSubscription: (
    name: string,
    agreeToTerms: boolean,
    planData: Subscription | PlanResponse,
    promoCode: PromotionalCode | undefined
  ) => Promise<void> | null;
};

export type AnyFunction = (...args: any[]) => any;
