import { isEmpty } from "lodash";

import {
  RouteSetByID,
  VisibleVehicleRouteIds,
} from "../../../../../../../components/Map/Map.types";

export const doAllVehiclesHaveRoutes = (routesById: RouteSetByID) => {
  return (
    !isEmpty(routesById) &&
    Object.keys(routesById)?.every((routeId: string) => {
      const route = routesById[routeId];
      return route.hasRoute;
    })
  );
};

export const updateAllVehicleVisibility = (
  visibleVehicleRoutesById: VisibleVehicleRouteIds,
  isVisible: boolean
) => {
  return Object.keys(visibleVehicleRoutesById).reduce(
    (updatedVisibleVehicleRoutesById, vehicleId) => {
      return {
        ...updatedVisibleVehicleRoutesById,
        [vehicleId]: isVisible,
      };
    },
    {}
  );
};

export const getAllTogglePosition = (
  visibleVehicleRoutesById: VisibleVehicleRouteIds,
  isAllToggled: boolean
): boolean => {
  const allVehiclesOn = Object.values(visibleVehicleRoutesById).every(
    (isVisible) => isVisible
  );
  const allVehiclesOff = Object.values(visibleVehicleRoutesById).every(
    (isVisible) => !isVisible
  );
  if (allVehiclesOn) {
    return true;
  } else if (allVehiclesOff) {
    return false;
  }
  // if neither all vehicles are on or all vehicles are off,
  // keep the toggle in the same position it was before
  return isAllToggled;
};
