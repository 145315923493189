import * as React from "react";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";

import { svgIconStyle } from "../lib/constants";

type StyledSvgProps = {
  activeColor?: string;
  focusColor?: string;
  hoverColor?: string;
};
const StyledSvg = styled.svg<StyledSvgProps>`
  ${(props) =>
    svgIconStyle({
      activeColor: props.activeColor,
      focusColor: props.focusColor,
      hoverColor: props.hoverColor,
    })}
`;

const IconValueFuncUnassigned = ({
  activeColor,
  focusColor,
  hoverColor,
  iconColor,
  iconSize,
}: {
  activeColor?: string;
  focusColor?: string;
  hoverColor?: string;
  iconColor?: string;
  iconSize?: number;
}) => {
  const theme = useTheme();
  const thisIconSize = iconSize || 16;
  return (
    <StyledSvg
      {...{ activeColor, focusColor, hoverColor }}
      width={thisIconSize}
      height={thisIconSize}
      viewBox="0 0 16 16"
      fill={iconColor || theme.color.gray700}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.85938 2.44128L15.0459 12.8214C15.4432 13.4879 14.9629 14.3333 14.1869 14.3333H1.81385C1.03787 14.3333 0.557572 13.4879 0.954847 12.8214L7.14137 2.44128C7.5292 1.79057 8.47156 1.79057 8.85938 2.44128ZM7.28204 5.78521C7.26637 5.37786 7.59236 5.03909 8.00001 5.03909C8.40766 5.03909 8.73365 5.37786 8.71798 5.78521L8.55384 10.0528C8.54239 10.3504 8.29782 10.5858 8.00001 10.5858C7.7022 10.5858 7.45762 10.3504 7.44618 10.0528L7.28204 5.78521ZM8.7999 12.1744C8.7999 12.6162 8.44172 12.9744 7.9999 12.9744C7.55807 12.9744 7.19989 12.6162 7.19989 12.1744C7.19989 11.7326 7.55807 11.3744 7.9999 11.3744C8.44172 11.3744 8.7999 11.7326 8.7999 12.1744Z"
      />
    </StyledSvg>
  );
};

export default IconValueFuncUnassigned;
