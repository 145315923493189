import React, { useState } from "react";
import { Box, DatePicker, Input } from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";
import { DateTime } from "luxon";

import { trackEvent } from "../../../../../analytics";

type ShadowTestShadowStartCriteriaProps = {
  setShadowStartCriteriaTime: React.Dispatch<React.SetStateAction<string>>;
  setShadowStartCriteriaTimeIsAuto: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  shadowStartCriteriaTime: string;
};

const ShadowTestShadowStartCriteria = ({
  setShadowStartCriteriaTime,
  setShadowStartCriteriaTimeIsAuto,
  shadowStartCriteriaTime,
}: ShadowTestShadowStartCriteriaProps) => {
  const theme = useTheme();

  const [
    isStartCriteriaTimeDatePickerActive,
    setIsStartCriteriaTimeDatePickerActive,
  ] = useState(false);

  const handleShadowStartCriteriaTimeChange = (e: {
    target: { value: string };
  }) => {
    setShadowStartCriteriaTimeIsAuto(false);
    setShadowStartCriteriaTime(e.target.value);
    return;
  };
  const handleShadowStartCriteriaTimeFocus = () => {
    setShadowStartCriteriaTimeIsAuto(false);
    setIsStartCriteriaTimeDatePickerActive(true);
    return;
  };
  const handleStartTimeDateTimeClear = () => {
    setShadowStartCriteriaTimeIsAuto(true);
    setIsStartCriteriaTimeDatePickerActive(false);
    setShadowStartCriteriaTime("");
    return;
  };
  const handleStartTimeDateTimeClose = () => {
    setShadowStartCriteriaTimeIsAuto(false);
    setIsStartCriteriaTimeDatePickerActive(false);
    trackEvent("ShadowTests", {
      view: "Create Shadow Test",
      action: "Field Entered",
      meta: {
        field: "start-criteria-time",
      },
    });
    return;
  };
  const handleStartTimeDateTimeSelect = () => {
    setShadowStartCriteriaTimeIsAuto(false);
    setIsStartCriteriaTimeDatePickerActive(false);
    trackEvent("ShadowTests", {
      view: "Create Shadow Test",
      action: "Field Entered",
      meta: {
        field: "start-criteria-time",
      },
    });
    return;
  };

  return (
    <Box width="100%" maxWidth={rem(408)}>
      <Input
        datePicker={
          <DatePicker
            orientation="bottom"
            clearAction={handleStartTimeDateTimeClear}
            closeAction={handleStartTimeDateTimeClose}
            isActive={isStartCriteriaTimeDatePickerActive}
            offsetY={`calc(${theme.spacing.s8} + ${theme.spacing.s1})`}
            primaryAction={handleStartTimeDateTimeSelect}
            setDateStart={setShadowStartCriteriaTime}
            selectedDay={shadowStartCriteriaTime}
          />
        }
        name="start-criteria-time"
        htmlType="text"
        placeholder="Date/time to start the test"
        value={
          shadowStartCriteriaTime
            ? DateTime.fromISO(shadowStartCriteriaTime).toFormat(
                "LLL dd, yyyy @ hh:mm a ZZZZ"
              )
            : shadowStartCriteriaTime
        }
        onChange={handleShadowStartCriteriaTimeChange}
        onFocus={handleShadowStartCriteriaTimeFocus}
      />
    </Box>
  );
};

export default ShadowTestShadowStartCriteria;
