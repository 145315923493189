import { APIChangeModalInfo, ModalType } from "../APIKeys.types";

export const getModalContent = (modalInfo: APIChangeModalInfo) => {
  const modalType = modalInfo.modalType;
  const key = modalInfo.key;

  switch (modalType) {
    case ModalType.Delete:
      return {
        title: `Delete ${key.name} API key`,
        summary: `Are you sure you want to delete the ${key.name} API key? This will disable all access for the key and permanently remove it. This action cannot be undone.`,
        primaryActionLabel: "Delete API key",
        secondaryActionLabel: "Cancel",
      };
    case ModalType.Enable:
      return {
        title: `Enable ${key.name} API key`,
        summary: `This will immediately enable the ${key.name} API key.`,
        primaryActionLabel: "Enable API key",
        secondaryActionLabel: "Cancel",
      };
    case ModalType.Disable:
      return {
        title: `Disable ${key.name} API key`,
        summary: `This will immediately disable the ${key.name} API key. You can re-enable the key at any time.`,
        primaryActionLabel: "Disable API key",
        secondaryActionLabel: "Cancel",
      };
    default:
      return {};
  }
};
