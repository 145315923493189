/*
  This function prevents invalid component props 
  from being forwarded by Emotion to the final HTML.

  Emotion's `isValidProp()` does a lot of the work
  but it doesn't catch everything.
*/

import isValidProp from "@emotion/is-prop-valid";

export const filterProps = (prop: string) => {
  switch (prop) {
    case "barColor":
    case "barWidth":
    case "height":
    case "fontSize":
    case "fontWeight":
    case "letterSpacing":
    case "width":
    case "isActiveSection":
    case "isActive":
    case "isactive":
    case "isExpanded":
    case "isFeatured":
    case "isLarge":
    case "iszero":
    case "label":
      return false;
    default:
      return isValidProp(prop);
  }
};
