import React, { useEffect, useState } from "react";
import { Loading, Notification } from "@console/dsc";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../../../analytics";
import {
  getOrgInfo,
  inviteMember,
  removeMember,
  updateMemberRole,
} from "../../../../api/core/controlPlane";
import {
  Organization,
  OrganizationMember,
} from "../../../../api/core/controlPlane.types";
import { useUser } from "../../../../AuthProvider";
import Header from "../../../../components/Header";
import { useMetaTitle } from "../../../../components/Layout";
import StandardError from "../../../../components/StandardError";
import {
  checkHasTeamsAccess,
  hasRolesRestrictions,
  teamMemberLimits,
} from "../../../../utils/authProviderHelpers";
import { userHasAccessToAction } from "../../../../utils/rbac_utils";
import { ActionGroups } from "../../../../utils/rbac_utils/types";
import NoTeamsAccess from "../NoTeamsAccess";

import ConfirmTeamMemberRemovalModal from "./components/ConfirmModal";
import EditRoleModal from "./components/EditRoleModal";
import NewTeamMemberModal from "./components/NewInviteModal";
import TeamMember from "./components/TeamMember";
import {
  ExitModalFuncParams,
  HandleCreateNewInviteParams,
  MemberManageFuncParams,
  MemberRoleManageFuncParams,
  TeamMemberDisplayOptions,
  UpdateOptionsParams,
} from "./Members.types";

const pageTitle = "Members";

const getFilteredTeamMembers = (
  member: OrganizationMember[],
  filterText: string
) => {
  return (member || []).filter((tm) =>
    tm.email.toLocaleLowerCase().includes(filterText.toLocaleLowerCase())
  );
};

const updateOptions = ({ optionsState, teamMemberId }: UpdateOptionsParams) => {
  // get current state of target menu
  const thisOptionsMenu = teamMemberId && optionsState[teamMemberId];

  // toggle target menu option and reset all other options
  Object.keys(optionsState).forEach((key) => {
    if (key === teamMemberId) {
      optionsState[key] = !thisOptionsMenu;
    } else {
      optionsState[key] = false;
    }
  });

  return optionsState;
};

const Members = () => {
  const [user] = useUser();
  const { id: accountId, roles } = user;
  const [, setMetaTitle] = useMetaTitle();
  const theme = useTheme();

  const [filterText, setFilterText] = useState("");
  const [displayOptionsMenu, setDisplayOptionsMenu] =
    useState<TeamMemberDisplayOptions>({});
  const [teamMemberRemovalConfirm, setTeamMemberRemovalConfirm] =
    useState<OrganizationMember>();
  const [teamMemberRemovalProcessing, setTeamMemberRemovalProcessing] =
    useState<boolean>(false);
  const [teamMemberEditRole, setTeamMemberEditRole] =
    useState<OrganizationMember>();
  const [teamMemberEditRoleProcessing, setTeamMemberEditRoleProcessing] =
    useState<boolean>(false);
  const [displayNewInviteModal, setDisplayNewInviteModal] = useState(false);
  const [teamInfo, setTeamInfo] = useState<Organization>();
  const [inviteProcessing, setInviteProcessing] = useState(false);
  const [inviteErrorMessage, setInviteErrorMessage] = useState("");
  const [error, setError] = useState("");

  const filteredTeamMembers = getFilteredTeamMembers(
    teamInfo?.members || [],
    filterText
  );

  const userCanRemoveMembers = userHasAccessToAction(
    roles,
    ActionGroups.OrgAdmin,
    {}
  );

  const userCanEditRole = userCanRemoveMembers && !hasRolesRestrictions(user);

  const teamMemberLimit = teamMemberLimits(user);

  const teamIsAtOrAboveLimit = teamMemberLimit
    ? (teamInfo?.members || []).length >= teamMemberLimit
    : false;

  const userCanCreateMembers =
    userHasAccessToAction(roles, ActionGroups.OrgAdmin, {}) &&
    !teamIsAtOrAboveLimit;

  const hasTeamsAccess = checkHasTeamsAccess(user);

  useEffect(() => {
    setMetaTitle(pageTitle);

    const loadOrgInfo = async () => {
      setError("");
      try {
        const resp = await getOrgInfo(accountId || "");
        setTeamInfo(resp);
        setDisplayOptionsMenu(
          (resp?.members || []).reduce(
            (acc: TeamMemberDisplayOptions, curr: OrganizationMember) => {
              acc[curr.email] = false;
              return acc;
            },
            {} as TeamMemberDisplayOptions
          )
        );
      } catch (e) {
        console.error(e);
        setError((e as Error).message);
      }
    };

    loadOrgInfo();
  }, [setMetaTitle, accountId]);

  const handleCreateNewInvite = async ({
    e,
    inviteEmail,
  }: HandleCreateNewInviteParams) => {
    e.preventDefault();
    setInviteProcessing(true);
    setInviteErrorMessage("");

    try {
      const member = await inviteMember(accountId || "")(
        accountId || "",
        inviteEmail
      );
      setTeamInfo({
        id: teamInfo?.id || "",
        name: teamInfo?.name || "",
        members: (teamInfo?.members || []).concat(member),
      });
      setDisplayOptionsMenu((prevState) => ({
        ...prevState,
        [inviteEmail]: false,
      }));
      setInviteProcessing(false);
    } catch (e) {
      console.error(e);
      setInviteErrorMessage((e as Error).message);
      setInviteProcessing(false);
      return;
    }

    trackEvent("Team", {
      view: "Members",
      action: "New Invite Sent",
    });

    setDisplayNewInviteModal(false);

    return;
  };

  const exitNewTeamMember = ({ e }: ExitModalFuncParams) => {
    e.preventDefault();

    // @orgs TODO: clear input state for email address in modal
    setDisplayNewInviteModal(false);

    return;
  };

  const handleOptionsMenu = ({ e, teamMember }: MemberManageFuncParams) => {
    e.preventDefault();

    const updatedDisplayOptionsMenu = updateOptions({
      optionsState: displayOptionsMenu,
      teamMemberId: teamMember.email,
    });

    setDisplayOptionsMenu({
      ...displayOptionsMenu,
      ...updatedDisplayOptionsMenu,
    });
  };

  const handleTeamMemberRemovalConfirmModal = ({
    e,
    teamMember,
  }: MemberManageFuncParams) => {
    e.preventDefault();
    setTeamMemberRemovalConfirm(teamMember);
  };

  const handleRemoveTeamMember = async ({
    e,
    teamMember,
  }: MemberManageFuncParams) => {
    e.preventDefault();
    setError("");
    setTeamMemberRemovalProcessing(true);

    try {
      await removeMember(accountId || "", teamMember.email);
      setTeamInfo({
        ...teamInfo,
        members: (teamInfo?.members || []).filter(
          (member) => member.email !== teamMember.email
        ),
      } as Organization);
      setTeamMemberRemovalProcessing(false);
    } catch (e) {
      console.error(e);
      setError((e as Error).message);
    }

    trackEvent("Team", {
      view: "Members",
      action: "Team Member Removed",
    });

    setTeamMemberRemovalConfirm(undefined);

    setDisplayOptionsMenu((prevState) => {
      return updateOptions({
        optionsState: prevState,
      });
    });

    return;
  };

  const exitConfirmTeamMemberRemoval = ({ e }: ExitModalFuncParams) => {
    e.preventDefault();

    setTeamMemberRemovalConfirm(undefined);
    setDisplayOptionsMenu((prevState) => {
      return updateOptions({
        optionsState: prevState,
      });
    });

    return;
  };

  const handleTeamMemberEditRoleModal = ({
    e,
    teamMember,
  }: MemberManageFuncParams) => {
    e.preventDefault();
    setTeamMemberEditRole(teamMember);
  };

  const handleTeamMemberEditRole = async ({
    e,
    teamMember,
    teamMemberRole,
  }: MemberRoleManageFuncParams) => {
    e.preventDefault();
    setError("");

    if (teamMember.role === teamMemberRole.value) {
      setDisplayOptionsMenu((prevState) => {
        return updateOptions({
          optionsState: prevState,
        });
      });
      setTeamMemberEditRole(undefined);
      return;
    }
    setTeamMemberEditRoleProcessing(true);
    try {
      const updated = await updateMemberRole(
        accountId || "",
        teamMember.email,
        teamMemberRole.value
      );
      setTeamInfo({
        ...teamInfo,
        members: (teamInfo?.members || []).map((member) =>
          member.email === updated.email ? updated : member
        ),
      } as Organization);
      setDisplayOptionsMenu((prevState) => {
        return updateOptions({
          optionsState: prevState,
        });
      });
      setTeamMemberEditRoleProcessing(false);
      setTeamMemberEditRole(undefined);
    } catch (e) {
      console.error(e);
      setError((e as Error).message);
    }

    trackEvent("Team", {
      view: "Members",
      action: "Team Member Role Edited",
      meta: {
        from: teamMember.role,
        to: teamMemberRole.value,
      },
    });

    return;
  };

  const exitEditTeamMemberRole = ({ e }: ExitModalFuncParams) => {
    e.preventDefault();

    setTeamMemberEditRole(undefined);
    setDisplayOptionsMenu((prevState) => {
      return updateOptions({
        optionsState: prevState,
      });
    });

    return;
  };

  if (error) {
    return <StandardError errorMessage={error} />;
  }
  if (!teamInfo?.members) {
    return <Loading type="single-page" dotColor={theme.color.orange500} />;
  }

  if (!hasTeamsAccess) {
    return <NoTeamsAccess isRootUser={true} />;
  }

  // should always be at lease one team member (root account)
  const renderTeamMembers = (userEmail: string) => {
    return filteredTeamMembers
      .sort((a, b) =>
        a.role === "root" || (a.email === userEmail && b.role !== "root")
          ? -1
          : 1
      )
      .map((teamMember, index) => {
        return (
          <TeamMember
            {...{
              userCanEditRole,
              userCanRemoveMembers,
              isUserThisMember: userEmail === teamMember.email,
              displayOptionsMenu,
              handleTeamMemberRemovalConfirmModal,
              handleTeamMemberEditRoleModal,
              handleOptionsMenu,
              teamMember,
            }}
            key={teamMember.email}
            displayTeamMemberOptionsMenu={displayOptionsMenu[teamMember.email]}
            hasNoBorder={index === 0}
          />
        );
      });
  };

  return (
    <>
      <Header
        configPageTitle={{ label: pageTitle }}
        configActionButton={{
          label: "Invite new member",
          url: "#",
          onClick: () => {
            trackEvent("Team", {
              view: "Members",
              action: "Invite New Member Button Clicked",
            });

            return setDisplayNewInviteModal(true);
          },
          isActionAllowed: userCanCreateMembers,
        }}
        configFilter={
          !(teamInfo?.members || []).length && !filterText
            ? undefined
            : {
                inputText: filterText,
                testId: "filter-members",
                setInputText: setFilterText,
              }
        }
      />

      {teamIsAtOrAboveLimit && (
        <Notification
          mt={4}
          type="notice"
          message={`Team member limit reached. The maximum number of team
        members for your plan is ${teamMemberLimit}.`}
        />
      )}

      {renderTeamMembers(user?.email || "")}

      {teamMemberRemovalConfirm && (
        <ConfirmTeamMemberRemovalModal
          {...{ teamMemberRemovalProcessing }}
          removeTeamMember={handleRemoveTeamMember}
          exitModal={exitConfirmTeamMemberRemoval}
          teamMember={teamMemberRemovalConfirm}
        />
      )}
      {teamMemberEditRole && (
        <EditRoleModal
          {...{ teamMemberEditRoleProcessing }}
          editTeamMemberRole={handleTeamMemberEditRole}
          exitModal={exitEditTeamMemberRole}
          teamMember={teamMemberEditRole}
        />
      )}
      {displayNewInviteModal && (
        <NewTeamMemberModal
          {...{
            inviteErrorMessage,
            displayNewInviteModal,
            handleCreateNewInvite,
            inviteProcessing,
          }}
          exitModal={exitNewTeamMember}
        />
      )}
    </>
  );
};

export default Members;
