import { rem } from "@console/dsc/src/lib/tools";
import styled from "@emotion/styled/macro";

import { ScriptCommandProps, StepDetailsProps } from "./Install.types";

export const CircleNumber = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ theme }) => theme.spacing.s8};
  height: ${({ theme }) => theme.spacing.s8};
  position: absolute;
  left: 0;
  top: ${({ theme }) => theme.spacing.s3};
  border-width: ${({ theme }) => theme.border.width};
  border-style: ${({ theme }) => theme.border.style};
  border-color: ${({ theme }) => theme.color.gray300};
  border-radius: ${({ theme }) => theme.spacing.s8};

  @media (min-width: ${({ theme }) => theme.breakpoint.rg}) {
    left: ${rem(-56)};
  }
`;

export const ScriptCommand = styled.pre<ScriptCommandProps>`
  width: 100%;
  padding-top: ${({ theme }) => theme.spacing.s3};
  padding-right: ${({ theme }) => theme.spacing.s4};
  padding-bottom: ${({ theme }) => theme.spacing.s3};
  padding-left: ${({ theme }) => theme.spacing.s4};
  position: relative;
  font-family: ${({ theme }) => theme.ui2Typography.fontFamilyCode};
  font-size: ${({ theme }) => theme.ui2Typography.fontSizeCode};
  font-style: ${({ theme }) => theme.ui2Typography.fontStyleCode};
  font-weight: ${({ theme }) => theme.ui2Typography.fontWeightCode};
  line-height: ${({ theme }) => theme.ui2Typography.lineHeightCode};
  color: ${(props) =>
    props.type === "gray"
      ? props.theme.color.gray700
      : props.theme.color.sky900};
  border-radius: ${({ theme }) => theme.border.radius};
  background-color: ${(props) =>
    props.type === "gray"
      ? props.theme.color.gray100
      : props.theme.color.sky200};
  word-break: break-all;
  white-space: break-spaces;
`;

export const ToastCopyMessage = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: ${({ theme }) => theme.spacing.s1};
  padding-right: ${({ theme }) => theme.spacing.s2};
  padding-bottom: ${({ theme }) => theme.spacing.s1};
  padding-left: ${({ theme }) => theme.spacing.s1};
  position: absolute;
  right: calc(
    ${({ theme }) => theme.spacing.s9} + ${({ theme }) => theme.spacing.s2}
  );
  top: ${({ theme }) => theme.spacing.s3};
  border-radius: ${({ theme }) => theme.border.radius};
  background-color: ${({ theme }) => theme.color.gray700T};
`;

export const StepDetails = styled.details<StepDetailsProps>`
  box-sizing: border-box;
  margin-top: ${({ theme }) => theme.spacing.s5};
  padding-top: ${({ theme }) => theme.spacing.s5};
  position: relative;
  border-top-width: ${({ theme }) => theme.border.width};
  border-top-style: ${({ theme }) => theme.border.style};
  border-top-color: ${({ theme }) => theme.color.gray200};

  ${(props) => props.isActive && `padding-bottom: ${props.theme.spacing.s4};`}
`;

export const StepDetailsSummary = styled.summary`
  cursor: pointer;

  /* hide default arrow */
  &::marker,
  &::-webkit-details-marker {
    display: none;
    content: "";
    background: none;
  }
`;
