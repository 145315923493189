import styled from "@emotion/styled";

import { styleHideVisually } from "../../lib/constants";
import { filterProps } from "../../lib/filterProps";
import { rem } from "../../lib/tools";
import Box from "../Box";

import {
  StyledCloseLinkProps,
  StyledModalProps,
  StyledScrimProps,
} from "./Modal2.types";

export const StyledModal = styled(Box)<StyledModalProps>`
  opacity: ${(props) => (props.isActive ? 1 : 0)};
  width: 94vw;
  height: auto;
  max-height: 88vh;
  position: relative;
  background: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.border.radiusLarge};
  z-index: 11112;
  transition: opacity 100ms ease-out;

  @media (min-width: ${rem(321)}) {
    max-height: 80vh;
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.sm}) {
    width: 80%;
    height: auto;
    max-width: ${(props) => props.width || rem(600)};
    max-height: ${(props) => props.maxHeight || "60vh"};
  }

  ${(props) => !props.isActive && styleHideVisually}
`;

export const StyledScrim = styled("div", {
  shouldForwardProp: (prop: any) => filterProps(prop),
})<StyledScrimProps>`
  display: ${(props) => (props.isActive ? "flex" : "none")};
  opacity: ${(props) => (props.isActive ? 1 : 0)};
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: ${({ theme }) => theme.color.gray400T};
  z-index: 11111;
  transition: opacity 150ms ease-out;

  ${(props) => props.hasBlur && `backdrop-filter: blur(${rem(1.5)})`};
`;

export const StyledCloseLink = styled("a", {
  shouldForwardProp: (prop: any) => filterProps(prop),
})<StyledCloseLinkProps>`
  display: block;
  width: ${({ theme }) => theme.spacing.s4};
  height: ${({ theme }) => theme.spacing.s4};
  position: absolute;
  right: ${(props) => props.right};
  top: ${(props) => props.top};
  text-decoration: none;
  cursor: pointer;
  opacity: 0.6;
  z-index: 11112;

  &:hover,
  &:focus {
    opacity: 0.9;
  }
  &:active {
    opacity: 1;
  }
  &:focus-visible {
    box-shadow: 0 0 ${({ theme }) => theme.spacing.s1} 0
      ${({ theme }) => theme.color.teal400};
  }
`;
