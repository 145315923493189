import { CONTENT_ID_RULES } from "../../../../../utils/systemIds";

export const tooltipContentNewSwitchbackTest = {
  id: {
    content: `The ID is the unique identifier for your switchback test and can be edited before creating the switchback test. ${CONTENT_ID_RULES}`,
  },
  baselineInstance: {
    content: "The instance to use for the baseline instance.",
  },
  candidateInstance: {
    content:
      "The instance to use for the candidate instance being compared to the baseline instance.",
  },
  startCriteria: {
    content:
      "If defined, the test will begin collecting data on this date when the test is in a started state. The start time is optional. If not provided, it will begin collecting data as soon as the test is started.",
  },
  randomPlan: {
    content:
      "When the switchback test is started, the test will run for the number of plan units specified times the unit duration. For each unit, the test will randomly select either the baseline or candidate instance to run for the duration of that plan unit. So while the test is active, if the plan has selected the candidate instance for the unit, any run made with the baseline instance will run on the candidate instance.",
  },
  units: {
    content:
      "The total number of plan units in your plan. When all plan units are complete, the test status is updated to completed. You can plan up to 1,000 units.",
  },
  unitDuration: {
    content:
      "The duration for each plan unit, specified in minutes. The maximum limit for duration is 10,080 minutes (7 days).",
  },
};
