import * as React from "react";
import { useTheme } from "@emotion/react";

import Flex from "../Flex";
import Text from "../Text";
import Tooltip from "../Tooltip";

import { getTabTextColor, StyledTabs, TabA, TabLink } from "./Tabs.styled";
import { GetTabStyleName, TabsProps } from "./Tabs.types";

const getTabStyleName = ({ isActive, size }: GetTabStyleName) => {
  switch (size) {
    case "small":
      return isActive ? "meta-1-bold" : "meta-1";
    default:
      return isActive ? "body-3-bold" : "body-3";
  }
};

const Tabs = ({ m, mt, mr, mb, ml, size, tabs, type }: TabsProps) => {
  const theme = useTheme();
  const thisType = type || "default";
  const thisSize = size || "default";

  return (
    <StyledTabs {...{ m, mt, mr, mb, ml }}>
      {!!tabs.length &&
        tabs.map((tab, index) => (
          <Text
            key={tab.id}
            data-testid={`test-${tab.id}`}
            as="li"
            ml={index !== 0 ? 4 : 0}
            styleName={getTabStyleName({
              isActive: tab.isActive,
              size: thisSize,
            })}
            styles={{
              display: "block",
              cursor: "pointer",
              color: getTabTextColor({
                isActive: tab.isActive,
                theme,
              }),
            }}
            aria-pressed={tab.isActive ? "true" : "false"}
            aria-label={tab.isActive ? "On" : "Off"}
          >
            <Flex alignItems="center">
              {tab.to ? (
                <TabLink
                  {...{ thisType }}
                  data-testid={`test-${tab.id}-link`}
                  to={tab.to}
                  isDisabled={tab.isDisabled}
                  isActive={tab.isActive}
                  onClick={tab.isDisabled ? undefined : tab.onClick}
                  aria-disabled={tab.isDisabled ? "true" : "false"}
                >
                  {tab.label}
                </TabLink>
              ) : (
                <TabA
                  {...{ thisType }}
                  data-testid={`test-${tab.id}-link`}
                  isDisabled={tab.isDisabled}
                  isActive={tab.isActive}
                  onClick={tab.isDisabled ? undefined : tab.onClick}
                  href={tab.url || undefined}
                  aria-disabled={tab.isDisabled ? "true" : "false"}
                >
                  {tab.label}
                </TabA>
              )}

              {tab?.tooltipContent && (
                <Tooltip
                  ml={1}
                  extraLinkLabel={tab?.tooltipExtraLinkLabel}
                  extraLinkUrl={tab?.tooltipExtraLinkUrl}
                >
                  {tab.tooltipContent}
                </Tooltip>
              )}
            </Flex>
          </Text>
        ))}
    </StyledTabs>
  );
};

export default Tabs;
