import { fetchWithToken } from "../utils/fetch";

import { Subscription } from "./controlPlane.types";
import { PAYMENT_API_BASE } from "./paymentApi.config";
import { SetupIntentResponse } from "./paymentApi.types";

export const fetchPaymentApiWithToken = fetchWithToken(`${PAYMENT_API_BASE}`);

export const getUserSubscription = async (
  token: string
): Promise<Subscription | null> => {
  try {
    const res = await fetch(`${PAYMENT_API_BASE}/subscription`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const respJson = await handleSubscriptionResponse(res);
    return respJson;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const handleResponse = async (resp: Response | undefined) => {
  if (!resp) {
    console.error(resp);
    throw new Error("could not communicate with server");
  }

  const respJson = await resp.json();

  if (respJson.error) {
    throw new Error(respJson.error);
  }

  return respJson;
};

export const handleSubscriptionResponse = async (r: Response | undefined) => {
  if (!r) {
    throw new Error("could not communicate with server");
  }
  if (r.status >= 400 && r.status !== 404) {
    throw new Error("Error occured retreiving user's subscription");
  }
  const respJson = await r.json();
  if (respJson.error) {
    return null;
  }
  return respJson;
};

export const getSetupIntent = async (
  accountId: string
): Promise<SetupIntentResponse> => {
  const res = await fetchPaymentApiWithToken(
    "/setupintent",
    "POST",
    JSON.stringify({
      account: accountId,
    })
  );
  const respJson = await handleResponse(res);
  return respJson;
};
