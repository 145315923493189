import React from "react";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { space } from "styled-system";

import { filterProps } from "../../lib/filterProps";

import { IconLinkProps, SocialIconProps } from "./SocialIcon.types";

const IconLink = styled("a", {
  shouldForwardProp: (prop: any) => filterProps(prop),
})<IconLinkProps>`
  ${space}

  ${(props) =>
    !!props.iconColorHover &&
    `
    &:hover {
      svg {
        fill: ${props.iconColorHover};
      }
    }
  `}
  ${(props) =>
    !!props.iconColorFocus &&
    `
    &:focus {
      svg {
        fill: ${props.iconColorFocus};
      }
    }
  `}
  ${(props) =>
    !!props.iconColorActive &&
    `
    &:active {
      svg {
        fill: ${props.iconColorActive};
      }
    }
  `}
`;

const SocialIcon = ({
  iconColor,
  iconColorActive,
  iconColorFocus,
  iconColorHover,
  iconSize,
  iconType,
  url,
  onClick,
  // spacing props
  m,
  mt,
  mr,
  mb,
  ml,
}: SocialIconProps) => {
  const theme = useTheme();
  const thisSize = iconSize || 16;

  return (
    <IconLink
      as={url ? "a" : "span"}
      href={url || undefined}
      target="_blank"
      onClick={onClick}
      rel="noopener noreferrer"
      {...{
        iconColor,
        iconColorActive,
        iconColorFocus,
        iconColorHover,
        m,
        mt,
        mr,
        mb,
        ml,
      }}
    >
      {iconType === "github" ? (
        <svg
          width={thisSize}
          viewBox="0 0 24 24"
          fill={iconColor || theme.color.gray600}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M12.0527 1.50001C9.42886 1.49734 6.8898 2.42917 4.89056 4.1285C2.89132 5.82783 1.56259 8.18359 1.14247 10.7736C0.722359 13.3636 1.23833 16.0186 2.59792 18.2628C3.95751 20.5069 6.07184 22.1935 8.56202 23.0204C9.11782 23.1221 9.31439 22.7764 9.31439 22.4849C9.31439 22.1935 9.31439 21.5292 9.31439 20.6074C6.23036 21.2784 5.57967 19.123 5.57967 19.123C5.37507 18.455 4.94041 17.881 4.35284 17.503C3.35646 16.8252 4.43417 16.8252 4.43417 16.8252C4.78345 16.8746 5.11691 17.0027 5.40932 17.2C5.70173 17.3973 5.94541 17.6585 6.12191 17.9639C6.27189 18.2359 6.47408 18.4755 6.7169 18.6691C6.95971 18.8627 7.23836 19.0065 7.53687 19.0921C7.83537 19.1778 8.14785 19.2036 8.45637 19.1682C8.76489 19.1328 9.06339 19.0368 9.33472 18.8858C9.37726 18.3282 9.61765 17.8041 10.0125 17.4081C7.55887 17.1302 4.9832 16.1813 4.9832 11.9857C4.96635 10.8897 5.37163 9.82913 6.11514 9.02367C5.784 8.07069 5.8227 7.02812 6.22359 6.10232C6.22359 6.10232 7.15218 5.80408 9.26016 7.23426C11.0703 6.73726 12.9809 6.73726 14.7911 7.23426C16.8991 5.80408 17.8209 6.10232 17.8209 6.10232C18.2273 7.01832 18.2757 8.05341 17.9564 9.00333C18.6999 9.80879 19.1052 10.8693 19.0884 11.9654C19.0884 16.2084 16.5059 17.137 14.0455 17.3878C14.3093 17.653 14.513 17.9718 14.6428 18.3227C14.7727 18.6735 14.8255 19.0482 14.7978 19.4212C14.7978 20.8989 14.7978 22.0918 14.7978 22.451C14.7978 22.8103 14.9944 23.0882 15.557 22.9797C18.0188 22.1311 20.1015 20.4396 21.4371 18.2042C22.7727 15.9688 23.2752 13.3333 22.8559 10.7633C22.4366 8.19322 21.1225 5.85408 19.1457 4.15906C17.1689 2.46404 14.6566 1.52223 12.0527 1.50001Z" />
        </svg>
      ) : iconType === "linkedin" ? (
        <svg
          width={thisSize}
          viewBox="0 0 24 24"
          fill={iconColor || theme.color.gray600}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M21.1863 2.00011H4.14291C3.75605 1.99573 3.38328 2.14509 3.10649 2.41538C2.82969 2.68566 2.67151 3.05478 2.66669 3.44162V20.5568C2.67134 20.9438 2.82941 21.3132 3.1062 21.5838C3.38299 21.8543 3.75585 22.004 4.14291 21.9999H21.1863C21.5738 22.0045 21.9474 21.855 22.2249 21.5845C22.5025 21.3139 22.6614 20.9444 22.6667 20.5568V3.44162C22.6611 3.05427 22.5021 2.68495 22.2246 2.4147C21.947 2.14445 21.5737 1.99535 21.1863 2.00011ZM8.59841 19.0426H5.63187V9.49812H8.59841V19.0426ZM7.11582 8.19338C6.77565 8.19356 6.44306 8.09285 6.16012 7.90399C5.87718 7.71514 5.6566 7.44663 5.52628 7.13241C5.39595 6.81819 5.36173 6.47237 5.42795 6.13871C5.49417 5.80504 5.65785 5.49851 5.89829 5.25787C6.13873 5.01722 6.44513 4.85329 6.77873 4.78679C7.11235 4.72029 7.45819 4.75423 7.77252 4.88429C8.08685 5.01436 8.35555 5.23471 8.54464 5.5175C8.73372 5.80027 8.8347 6.13278 8.83481 6.47295C8.83483 6.92899 8.65378 7.36639 8.33144 7.689C8.00911 8.0116 7.57187 8.19302 7.11582 8.19338ZM19.7091 19.0426H16.7446V14.4011C16.7446 13.2938 16.7243 11.8701 15.2032 11.8701C13.6596 11.8701 13.4232 13.0759 13.4232 14.321V19.0426H10.4589V9.49812H13.3044V10.8022H13.3439C13.6285 10.3153 14.0399 9.9148 14.5341 9.64333C15.0283 9.37184 15.587 9.23956 16.1505 9.26058C19.1544 9.26058 19.7091 11.2374 19.7091 13.8072V19.0426Z" />
        </svg>
      ) : iconType === "twitter" ? (
        <svg
          width={thisSize}
          viewBox="0 0 24 24"
          fill={iconColor || theme.color.gray600}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M17.5387 3H20.7589L13.7238 11.0406L22 21.9821H15.5198L10.4443 15.3461L4.63677 21.9821H1.41469L8.93937 13.3817L1 3H7.6447L12.2325 9.0655L17.5387 3ZM16.4086 20.0547H18.1929L6.67515 4.82617H4.7604L16.4086 20.0547Z" />
        </svg>
      ) : iconType === "youtube" ? (
        <svg
          width={thisSize}
          viewBox="0 0 24 24"
          fill={iconColor || theme.color.gray600}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.9856 4.48089C21.9758 4.74597 22.754 5.52411 23.0189 6.5143C23.498 8.30726 23.5 12.0503 23.5 12.0503C23.5 12.0503 23.5 15.7934 23.0189 17.5863C22.754 18.5765 21.9758 19.3548 20.9856 19.6197C19.1927 20.1008 11.9999 20.1008 11.9999 20.1008C11.9999 20.1008 4.80727 20.1008 3.01432 19.6197C2.02412 19.3548 1.24596 18.5765 0.9809 17.5863C0.5 15.7934 0.5 12.0503 0.5 12.0503C0.5 12.0503 0.5 8.30726 0.9809 6.5143C1.24596 5.52411 2.02412 4.74597 3.01432 4.48089C4.80727 4 11.9999 4 11.9999 4C11.9999 4 19.1927 4 20.9856 4.48089ZM15.6731 12.0506L9.69775 15.5001V8.601L15.6731 12.0506Z"
          />
        </svg>
      ) : null}
    </IconLink>
  );
};

export default SocialIcon;
