import { useEffect, useRef, useState } from "react";
import { Box, Button2, Flex, Text } from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";

import { RunMetadataResponse } from "../../../../../api/core/controlPlane.types";
import useRunInput from "../../../../../contexts/apps/hooks/useRunInput";
import { formatBytes } from "../../../../../utils/format";
import {
  checkIsInputBelowRenderThreshold,
  checkIsRunInputFormatGZIP,
  checkIsRunInputFormatJson,
} from "../utils/runDataChecks";

type DownloadRunInputProps = {
  appId: string;
  runMetadata: RunMetadataResponse;
};

const DownloadRunInput = ({ appId, runMetadata }: DownloadRunInputProps) => {
  const theme = useTheme();
  const refDownloadButton = useRef<any>();
  const inputIsGZIP = checkIsRunInputFormatGZIP(runMetadata);

  const [downloadInputURLInitiated, setDownloadInputURLInitiated] =
    useState(false);

  const {
    loadGzippedRunInput,
    loadRunInputWithURL,
    runInputWithURL,
    runInputWithURLError,
  } = useRunInput();

  // initiate download when runInputUrl ready
  useEffect(() => {
    if (runInputWithURL && !runInputWithURLError && downloadInputURLInitiated) {
      if (!inputIsGZIP) {
        // write download link to button (a tag) and initiate user click
        if (refDownloadButton.current) {
          refDownloadButton.current.setAttribute(
            "download",
            `${runMetadata.id}-input`
          );
          refDownloadButton.current.setAttribute("href", runInputWithURL.url);
          refDownloadButton.current.click();
        }
        setDownloadInputURLInitiated(false);
      } else {
        // handle GZIP file download
        loadGzippedRunInput(runInputWithURL.url, runMetadata.id);
        setDownloadInputURLInitiated(false);
      }
    }
  }, [
    downloadInputURLInitiated,
    inputIsGZIP,
    loadGzippedRunInput,
    runInputWithURL,
    runInputWithURLError,
    runMetadata.id,
  ]);

  // get run input URL when button is clicked
  useEffect(() => {
    if (downloadInputURLInitiated) {
      loadRunInputWithURL(appId, runMetadata.id);
    }
  }, [appId, downloadInputURLInitiated, loadRunInputWithURL, runMetadata.id]);

  const handleDownloadInput = (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    if (!downloadInputURLInitiated) {
      e.preventDefault();
      e.stopPropagation();

      setDownloadInputURLInitiated(true);
    }
  };

  const inputFileSize = runMetadata?.metadata?.input_size;
  const isFormatJson = checkIsRunInputFormatJson(runMetadata);
  const isLargeFile = !checkIsInputBelowRenderThreshold(runMetadata);

  return (
    <Box mt={5}>
      {isLargeFile && (
        <Text styleName="body-2">The run input is too large to render.</Text>
      )}
      {!isFormatJson && !isLargeFile && (
        <Text styleName="body-2">
          The run input is not in JSON format and must be downloaded to view.
        </Text>
      )}

      <Flex
        mt={isLargeFile || !isFormatJson ? 3 : 0}
        pl={isLargeFile || !isFormatJson ? 0 : 5}
        pb={isLargeFile || !isFormatJson ? 0 : 5}
        alignItems="center"
        hasBorderBottom={!isLargeFile && isFormatJson}
      >
        <Button2
          innerRef={refDownloadButton}
          isDisabled={!inputFileSize}
          label="Download Input"
          type="outline"
          isLoading={downloadInputURLInitiated}
          onClick={handleDownloadInput}
          href="#"
          styles={{
            minWidth: rem(106),
          }}
        />
        <Text
          ml={2}
          styleName="code-bold"
          styles={{
            fontSize: theme.ui2Typography.fontSizeMeta1,
            color: theme.color.gray600,
          }}
        >
          ({formatBytes(inputFileSize)})
        </Text>
      </Flex>

      {isLargeFile && (
        <Box maxWidth={rem(380)}>
          <Text
            mt={2}
            styleName="body-3"
            styles={{ color: theme.color.gray600 }}
          >
            Note that if the file is very large it may take a moment for the
            download to initiate.
          </Text>
        </Box>
      )}
    </Box>
  );
};

export default DownloadRunInput;
