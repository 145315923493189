import {
  DepotInfoStop,
  MarkerPoint,
  RouteSetByID,
  VisibleVehicleRouteIds,
} from "../../../Map.types";

export type UseRenderMarkerHelpersProps = {
  routesById?: RouteSetByID;
  displayVehiclesNoRoutes?: boolean;
  visibleVehicleRoutesById?: VisibleVehicleRouteIds;
};

export default function useRenderMarkerHelpers({
  routesById,
  displayVehiclesNoRoutes,
  visibleVehicleRoutesById,
}: UseRenderMarkerHelpersProps) {
  // INFO: When passed a random string or null value, the function
  // below will return true. This is expected behaviour. This is
  // necessary as the hook is used to render points for both
  // input and output data. Input does not pass routesById to the
  // hook. Also, when used for input, a "random string" refers
  // simply to user input.
  const doesVehicleHaveRoute = (vehicleId?: string) => {
    const isVehicleInRoutesById =
      vehicleId && routesById && routesById[vehicleId];
    return isVehicleInRoutesById ? routesById[vehicleId].hasRoute : true;
  };

  const shouldRenderDepot = (depotInfo: DepotInfoStop[]) => {
    return depotInfo.some((stop) => doesVehicleHaveRoute(stop.vehicleId));
  };

  const shouldRenderMarker = (p: MarkerPoint): boolean => {
    const isDepotAndShouldRender =
      p.depotInfo && shouldRenderDepot(p.depotInfo);
    const isMarkerAndShouldRender =
      !p.depotInfo && doesVehicleHaveRoute(p.vehicleId);

    if (visibleVehicleRoutesById) {
      return p.isAssigned
        ? visibleVehicleRoutesById[p.vehicleId || ""] &&
            (displayVehiclesNoRoutes ||
              isDepotAndShouldRender ||
              isMarkerAndShouldRender)
        : displayVehiclesNoRoutes ||
            isDepotAndShouldRender ||
            isMarkerAndShouldRender;
    }

    return (
      displayVehiclesNoRoutes ||
      isDepotAndShouldRender ||
      isMarkerAndShouldRender
    );
  };

  return { shouldRenderMarker, doesVehicleHaveRoute };
}
