import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { AvatarApp, Tabs } from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";
import { isEmpty } from "lodash";

import { trackEvent } from "../../../../analytics";
import { getFileContents } from "../../../../api/core/controlPlane";
import { CreateRunPayload } from "../../../../api/core/controlPlane.types";
import { useUser } from "../../../../AuthProvider";
import { getBannerType } from "../../../../components/Banner/utils/getBannerType";
import Header from "../../../../components/Header";
import { InstanceSelectInstanceIds } from "../../../../components/InstanceSelect/InstanceSelect.types";
import { useMetaTitle } from "../../../../components/Layout";
import Map from "../../../../components/Map";
import useMapState from "../../../../components/Map/hooks/useMapState";
import { EMPTY_ROUTE_SET } from "../../../../components/Map/utils/constants";
import useFileIntercept from "../../../../components/UploadFile/hooks/useFileIntercept";
import { AcceptedFiles } from "../../../../components/UploadFile/UploadFile.types";
import { getLocalDirectory } from "../../../../components/UploadFile/utils/getLocalDirectory";
import { useAppCollection } from "../../../../contexts/apps/App.context";
import useRunDetails from "../../../../contexts/apps/hooks/useRunDetails";
import useRunInput from "../../../../contexts/apps/hooks/useRunInput";
import useStandardInputs from "../../../../hooks/useStandardInputs";
import { unzip } from "../../../../utils/fileHandling/unzip";
import { getAccUrl } from "../../../../utils/navigation";
import { getAvatarColor } from "../../../Apps/utils/renderAppsList";
import { getSafeCloneName } from "../../../Experiments/utils/getSafeCloneName";
import { AppPageProps } from "../../App.types";
import useNewInstance from "../NewInstance/hooks/useNewInstance";
import {
  StyledControlPanel,
  StyledMapContainer,
  StyledRunResultContainer,
} from "../RunDetails/RunDetails.styled";
import { getMainViewTabs } from "../RunDetails/utils/getMainViewTabs";
import {
  checkIsEveryFileBelowRenderThreshold,
  checkIsInputBelowRenderThreshold,
  checkIsRunInputFormatGZIP,
  checkIsRunInputFormatJson,
  checkShouldInputFileBeRendered,
  checkShouldReduceVisuals,
  isFileTypeJson,
} from "../RunDetails/utils/runDataChecks";

import InputControls from "./components/InputControls";
import SchedulingVisualization from "./components/SchedulingVisualization";
import VisualizationPlaceholder from "./components/VisualizationPlaceholder";
import useCustomInput from "./hooks/useCustomInput";
import { getRunInputVizType } from "./utils/getRunInputVizType";

const pageTitle = "New run";

const NewAppRun = ({ app, setDisplayPages }: AppPageProps) => {
  const theme = useTheme();
  const [user] = useUser();
  const { id: accountId } = user;
  const [, setMetaTitle] = useMetaTitle();
  const bannerType = getBannerType(user);
  const mapState = useMapState();
  const { pathname, search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const cloneId = searchParams.get("cloneId");
  const localDirectory = getLocalDirectory(pathname);

  const [acceptedFiles, setAcceptedFiles] = useState<AcceptedFiles>(null);
  const [fileProcessingError, setFileProcessingError] = useState<string>("");
  const [hasLoadedCloneData, setHasLoadedCloneData] = useState<boolean>(false);
  const [hasLoadedCloneFile, setHasLoadedCloneFile] = useState<boolean>(false);
  const [pendingInstanceIds, setPendingInstanceIds] =
    useState<InstanceSelectInstanceIds>([app?.default_instance]);

  const [isRunning, setIsRunning] = useState(false);
  const [newRunId, setNewRunId] = useState("");

  const customInputState = useCustomInput();

  const { loadRuns } = useAppCollection();

  const {
    addEmptyConfigOption,
    convertToConfigOptions,
    convertToPendingConfigOptions,
    handleConfigOptionChange,
    pendingConfigOptions,
    removeConfigOption,
    setPendingConfigOptions,
  } = useNewInstance();

  const {
    addRunDetails,
    createTemporaryRunUploadMetadata,
    isRunAdded,
    loadRunMetadata,
    runAddError,
    runDetailsActionError,
    runMetadata,
    runMetadataError,
  } = useRunDetails();

  const {
    loadRunInput,
    loadRunInputWithURL,
    runInput,
    runInputError,
    runInputWithURL,
    runInputWithURLError,
  } = useRunInput();

  const {
    getStandardInputsProps,
    pendingStandardInputs,
    updateStandardInputs,
  } = useStandardInputs(app, "runs", false);

  const {
    clearWebWorkersAndLocalOPFS,
    isFileUploadProcessRunning,
    isPrecheckRunning,
    fileUploadMetadata,
    setIsFileUploadProcessRunning,
    setIsPrecheckRunning,
    uploadFilesViaWebWorker,
    interceptFiles,
  } = useFileIntercept({
    app,
    setAcceptedFiles,
  });

  const {
    inputState: customAppInputState,
    onInputChange,
    setModified: setIsInputModified,
  } = customInputState;

  const pendingInstanceId = useMemo(() => {
    return pendingInstanceIds.filter(Boolean)[0] || "";
  }, [pendingInstanceIds]);

  // set up checks
  const isInputBelowRenderThreshold =
    checkIsInputBelowRenderThreshold(runMetadata);
  const isInputFormatJson = checkIsRunInputFormatJson(runMetadata);

  // reduce visual styles for output that is below threshold but
  // large for visual displays (used only for CSS style rules)
  const shouldReduceVisuals = checkShouldReduceVisuals(runMetadata);

  const isWaitingForInput = !runInput && !runInputWithURL;

  const cloneRunError =
    runInputError || runInputWithURLError || runMetadataError;

  const isCloneInputLoading =
    !!cloneId && !hasLoadedCloneFile && !isInputBelowRenderThreshold;

  // manage page display
  useEffect(() => {
    setMetaTitle(pageTitle);
    setDisplayPages && setDisplayPages(false);
  }, [setDisplayPages, setMetaTitle]);

  // get run metadata for cloning if cloneId present
  useEffect(() => {
    if (cloneId && !runMetadata && !runMetadataError) {
      loadRunMetadata(app.id, cloneId);
    }
  }, [app.id, cloneId, loadRunMetadata, runMetadata, runMetadataError]);

  // if valid cloneID (metadata present), determine input loading pattern
  useEffect(() => {
    const shouldLoadInput = (): boolean => {
      if (!cloneId || !runMetadata || runInput) return false;
      return isInputFormatJson && isInputBelowRenderThreshold;
    };

    if (cloneId && runMetadata && isWaitingForInput) {
      if (shouldLoadInput()) {
        loadRunInput(app.id, cloneId);
      } else {
        loadRunInputWithURL(app.id, cloneId);
      }
    }
  }, [
    app.id,
    cloneId,
    isInputBelowRenderThreshold,
    isInputFormatJson,
    isWaitingForInput,
    loadRunInput,
    loadRunInputWithURL,
    runInput,
    runMetadata,
  ]);

  // read uploaded file & update input if JSON
  useEffect(() => {
    setFileProcessingError("");
    const readFileData = async (acceptedFile: File) => {
      const acceptedFileBlob = new Blob([acceptedFile]);
      const acceptedFileContents = await acceptedFileBlob.text();

      setIsInputModified(false);
      onInputChange(JSON.parse(acceptedFileContents));

      return;
    };

    // check to make sure it is a single file and that the file size is below
    // the render threshold, and that acceptedFile is an actual File and not
    // an empty shell sent back from webworker (though there should never be the
    // the case where the size is below the threshold but file data doesn't exist)
    if (acceptedFiles && acceptedFiles.length === 1) {
      const acceptedFile = acceptedFiles[0];
      if (
        isFileTypeJson(acceptedFile.type) &&
        acceptedFile instanceof File &&
        checkShouldInputFileBeRendered(acceptedFile.size)
      ) {
        readFileData(acceptedFile);
        return;
      }
    }

    setIsInputModified(false);
    onInputChange(null);
    return;
  }, [acceptedFiles, onInputChange, setIsInputModified]);

  // download input file for clone run if required
  useEffect(() => {
    const getFilesFromUrl = async (
      runInputURL: string,
      cloneId: string,
      isGzip: boolean
    ) => {
      const fileContentsBlob = await getFileContents(runInputURL, "blob");
      let runInputFiles;
      if (isGzip) {
        const unzippedFiles = await unzip(fileContentsBlob as Blob);
        runInputFiles = unzippedFiles.map(
          (fileData) =>
            new File([fileData.blob], fileData.name, { type: "text/csv" })
        );
      } else {
        runInputFiles = [new File([fileContentsBlob], `input-${cloneId}`)];
      }

      if (checkIsEveryFileBelowRenderThreshold(runInputFiles)) {
        setAcceptedFiles(runInputFiles);
      }
      interceptFiles(runInputFiles, localDirectory);

      // only dynamically load file once
      setHasLoadedCloneFile(true);
    };

    if (
      cloneId &&
      runInputWithURL &&
      !runInputWithURLError &&
      !hasLoadedCloneFile
    ) {
      getFilesFromUrl(
        runInputWithURL.url,
        cloneId,
        checkIsRunInputFormatGZIP(runMetadata)
      );
    }
  }, [
    cloneId,
    hasLoadedCloneFile,
    interceptFiles,
    localDirectory,
    runInputWithURL,
    runInputWithURLError,
    runMetadata,
  ]);

  // pre-fill run fields with cloned data (if applicable)
  useEffect(() => {
    if (
      cloneId &&
      runMetadata &&
      !cloneRunError &&
      !isWaitingForInput &&
      !hasLoadedCloneData
    ) {
      // modify name to prevent duplicate ID error
      const modifiedRunName = runMetadata.name
        ? getSafeCloneName(`${runMetadata.name} clone`)
        : "";

      updateStandardInputs([
        { key: "name", value: modifiedRunName },
        { key: "description", value: runMetadata.description },
      ]);
      setPendingInstanceIds([runMetadata.metadata.application_instance_id]);

      if (runMetadata.metadata?.options?.request_options) {
        setPendingConfigOptions(
          convertToPendingConfigOptions(
            runMetadata.metadata?.options?.request_options
          )
        );
      }

      if (runInput && isInputFormatJson) {
        setIsInputModified(false);
        onInputChange(runInput);
      }

      setHasLoadedCloneData(true);
    }
  }, [
    cloneId,
    cloneRunError,
    convertToPendingConfigOptions,
    hasLoadedCloneData,
    isInputFormatJson,
    isWaitingForInput,
    onInputChange,
    runInput,
    runMetadata,
    setIsInputModified,
    setPendingConfigOptions,
    updateStandardInputs,
  ]);

  const handleRunCreate = useCallback(async () => {
    // set running true to activate action button loading state
    // otherwise UX "hangs" waiting for file upload (if present)
    setIsRunning(true);
    setFileProcessingError("");

    let payload: CreateRunPayload = {};

    // if free-form input has been entered, add to payload
    // exception: if preprocessed file data is present use it instead
    // (contents of JSON file are parsed to free-form input sometimes
    // so both could be present)
    if (customAppInputState.input && !fileUploadMetadata) {
      payload["input"] = customAppInputState.input;
    }

    // if preprocessed file data exists, add it
    if (fileUploadMetadata) {
      payload["upload_id"] = fileUploadMetadata.uploadId;
      payload["configuration"] = {
        format: {
          input: {
            type: fileUploadMetadata.contentType,
          },
        },
      };
    }

    // if run config options specified, add to payload
    const runOptions = convertToConfigOptions(pendingConfigOptions);
    if (!isEmpty(runOptions)) {
      payload["options"] = runOptions;
    }

    // if name or description specified, add to payload
    const { name, description } = pendingStandardInputs;
    if (name) payload["name"] = name.trim();
    if (description) payload["description"] = description.trim();

    const newRun = await addRunDetails(
      app.id,
      pendingInstanceId,
      payload,
      true
    );

    if (newRun?.run_id) {
      setNewRunId(newRun?.run_id);
    }
  }, [
    addRunDetails,
    app.id,
    convertToConfigOptions,
    customAppInputState.input,
    fileUploadMetadata,
    pendingConfigOptions,
    pendingInstanceId,
    pendingStandardInputs,
    setIsRunning,
  ]);

  const startFileUploadProcess = useCallback(async () => {
    setIsFileUploadProcessRunning(true);

    // create upload ID & URL to pass to web worker
    const runUploadMetadata = await createTemporaryRunUploadMetadata(app.id);

    if (runUploadMetadata.upload_url && runUploadMetadata.upload_id) {
      // upload files in web worker
      await uploadFilesViaWebWorker(
        acceptedFiles,
        runUploadMetadata,
        localDirectory
      );
    }

    return;
  }, [
    acceptedFiles,
    app.id,
    createTemporaryRunUploadMetadata,
    localDirectory,
    setIsFileUploadProcessRunning,
    uploadFilesViaWebWorker,
  ]);

  useEffect(() => {
    if (fileUploadMetadata && isFileUploadProcessRunning) {
      setIsFileUploadProcessRunning(false);
    }
  }, [
    fileUploadMetadata,
    isFileUploadProcessRunning,
    setIsFileUploadProcessRunning,
  ]);

  useEffect(() => {
    if (isPrecheckRunning) {
      if (acceptedFiles && acceptedFiles.length) {
        if (!fileUploadMetadata && !isFileUploadProcessRunning) {
          startFileUploadProcess();
          return;
        }
        if (fileUploadMetadata) {
          // files are uploaded, ready to start run
          setIsPrecheckRunning(false);
          handleRunCreate();
          return;
        }

        return;
      } else {
        setIsPrecheckRunning(false);
        handleRunCreate();
        return;
      }
    }
  }, [
    acceptedFiles,
    fileUploadMetadata,
    handleRunCreate,
    isFileUploadProcessRunning,
    isPrecheckRunning,
    setIsPrecheckRunning,
    startFileUploadProcess,
  ]);

  // reset pages on exit
  useEffect(() => {
    return () => {
      setDisplayPages && setDisplayPages(true);
    };
  }, [setDisplayPages]);

  // clear OPFS data and close web workers on exit
  useEffect(() => {
    return () => {
      clearWebWorkersAndLocalOPFS(localDirectory);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isRunAdded && newRunId) {
    trackEvent("RunHistory", {
      view: "Create Run",
      action: "New Run Created",
    });

    loadRuns({ applicationId: app.id });
    return (
      <Redirect to={getAccUrl(accountId, `/app/${app.id}/run/${newRunId}`)} />
    );
  }

  const mainViewTabs = getMainViewTabs({
    mainView: "input",
    hasSeriesData: !!(
      app.type === "custom" ||
      (app.type === "subscription" &&
        app.subscription_id === "nextmv-nextroute")
    ),
    isDetailsDisabled: true,
    isInputDisabled: false,
    isLogsDisabled: true,
    isResultDisabled: true,
    setMainView: () => {},
  });

  const handleRunPrecheck = async (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    e.preventDefault();
    e.stopPropagation();

    setIsPrecheckRunning(true);
    setFileProcessingError("");

    return;
  };

  const inputVizType = getRunInputVizType(customAppInputState);

  return (
    <>
      <Header
        configPageTitle={{
          label: "Create new run",
          parentLabel: "Runs",
          parentUrl: getAccUrl(accountId, `/app/${app.id}/runs`),
          ancestorIcon: (
            <AvatarApp
              size={32}
              iconColor={getAvatarColor({
                appType: app?.type,
                theme,
              })}
            />
          ),
          ancestorLabel: app.name,
          ancestorUrl: getAccUrl(accountId, `/app/${app.id}`),
        }}
        isExpanded
        topNavExtra={<Tabs mt={rem(3)} tabs={mainViewTabs} />}
      />

      <StyledRunResultContainer hasBanner={!!bannerType}>
        <StyledControlPanel>
          <InputControls
            {...{
              acceptedFiles,
              addEmptyConfigOption,
              app,
              cloneId,
              getStandardInputsProps,
              handleConfigOptionChange,
              isPrecheckRunning,
              isRunning,
              pendingConfigOptions,
              pendingInstanceIds,
              removeConfigOption,
              fileProcessingError,
              runAddError,
              runDetailsActionError,
              setAcceptedFiles,
              setIsInputModified,
              setPendingInstanceIds,
              isCloneInputLoading,
            }}
            handleRunPrecheck={handleRunPrecheck}
            runInputState={customInputState}
          />
        </StyledControlPanel>

        {inputVizType === "routing" && (
          <StyledMapContainer
            {...(shouldReduceVisuals && {
              className: "large-file-render",
            })}
            style={{
              backgroundColor: theme.color.gray100,
            }}
          >
            <Map
              activeTab="input"
              mapState={mapState}
              routeSet={EMPTY_ROUTE_SET}
              markerCoords={customAppInputState.markerCoords}
            />
          </StyledMapContainer>
        )}
        {inputVizType === "scheduling" && (
          <SchedulingVisualization
            requiredWorkers={customAppInputState.input.required_workers}
            workers={customAppInputState.input.workers}
          />
        )}
        {inputVizType === "custom" && (
          <VisualizationPlaceholder
            appType={app.type}
            appSubscriptionId={app.subscription_id}
          />
        )}
      </StyledRunResultContainer>
    </>
  );
};

export default NewAppRun;
