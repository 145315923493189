export const clearOPFS = async () => {
  try {
    const opfsRoot = await navigator.storage.getDirectory();

    // @ts-expect-error
    const entries = await opfsRoot.values();

    for await (const entry of entries) {
      if (entry.kind === "directory") {
        await opfsRoot.removeEntry(entry.name, { recursive: true });
      }
      if (entry.kind === "file") {
        await opfsRoot.removeEntry(entry.name);
      }
    }
  } catch (_error) {}

  return;
};
