import * as React from "react";

import BaseAvatar, { AvatarSize } from "./BaseAvatar";

const AvatarExperimentBatch = ({
  size,
  type,
}: {
  size?: AvatarSize;
  type: "default" | "fill";
}) => {
  const thisType = type || "default";

  return thisType === "fill" ? (
    <BaseAvatar {...{ size }} viewBox={[0, 0, 24, 24]}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM13.9375 5.125H15.5V18.125H13.9375V5.125ZM16.75 8.125H18.3125V18.125H16.75V8.125ZM11.125 8.875H12.6875V18.125H11.125V8.875ZM5.5 9.625H7.0625V18.125H5.5V9.625ZM8.3125 11.875H9.875V18.125H8.3125V11.875Z"
        fill="#FC6262"
      />
    </BaseAvatar>
  ) : (
    <BaseAvatar {...{ size }}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.75 24C45.75 36.0122 36.0122 45.75 24 45.75C11.9878 45.75 2.25 36.0122 2.25 24C2.25 11.9878 11.9878 2.25 24 2.25C36.0122 2.25 45.75 11.9878 45.75 24ZM48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24ZM13.875 19.5H11.25V36H13.875V19.5ZM16.875 24H19.5V36H16.875V24ZM25.125 18H22.5V36H25.125V18ZM28.125 10.5H30.75V36H28.125V10.5ZM36.375 16.5H33.75V36H36.375V16.5Z"
        fill="#FC6262"
      />
    </BaseAvatar>
  );
};

export default AvatarExperimentBatch;
