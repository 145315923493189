import * as React from "react";

import BaseIcon, { IconProps } from "./BaseIcon";

const IconExternalLink = ({
  iconSize,
  iconColor,
  iconColorActive,
  iconColorFocus,
  iconColorHover,
  ...rest
}: IconProps) => {
  return (
    <BaseIcon
      {...{
        iconSize,
        iconColor,
        iconColorActive,
        iconColorFocus,
        iconColorHover,
      }}
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 3.41667H3.41699V12.5833H12.5837V9.5V8.75H14.0837V9.5V12.8333C14.0837 13.5237 13.524 14.0833 12.8337 14.0833H3.16699C2.47664 14.0833 1.91699 13.5237 1.91699 12.8333V3.16667C1.91699 2.47632 2.47664 1.91667 3.16699 1.91667H6.5H7.25V3.41667H6.5ZM9.38366 1.91667H10.1337H13.3337C13.4354 1.91667 13.5323 1.93691 13.6207 1.97358C13.7092 2.01018 13.7921 2.06443 13.864 2.13634C14.0104 2.28279 14.0837 2.47473 14.0837 2.66667V5.86667V6.61667H12.5837V5.86667V4.47733L7.46399 9.597L6.93366 10.1273L5.873 9.06667L6.40333 8.53634L11.523 3.41667H10.1337H9.38366V1.91667Z"
      />
    </BaseIcon>
  );
};

export default IconExternalLink;
