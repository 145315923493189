import React from "react";
import { Box, Notification } from "@console/dsc";

// NOTE: this component is a legacy component and needs
//       to be replaced with a more up-to-date component
//       but for now just making quick edits to simplify
//       it without having to update the different instances
//       in the code where it's used

const ContactLinkErrorMessage = ({
  errorPrefix = "Error: ",
  errorMsg,
  isEmailLink = false,
  emailSubject = "",
  isLoud = true,
}: {
  errorPrefix?: string;
  errorMsg: string;
  isEmailLink?: boolean;
  emailSubject?: string;
  isLoud?: boolean;
}) => {
  return (
    <Box mt={4}>
      <Notification
        type="error"
        message={`${errorPrefix}${errorMsg}`}
        hasContactExtra
      />
    </Box>
  );
};

export default ContactLinkErrorMessage;
