import * as React from "react";

import BaseAvatar, { AvatarSize } from "./BaseAvatar";

const AvatarAppInput = ({ size }: { size?: AvatarSize }) => {
  return (
    <BaseAvatar size={size || 24} viewBox={[0, 0, 24, 24]}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM7.5 7.5C7.5 7.22386 7.72386 7 8 7H16C16.2761 7 16.5 7.22386 16.5 7.5V16.5C16.5 16.7761 16.2761 17 16 17H8C7.72386 17 7.5 16.7761 7.5 16.5V7.5ZM6 7.5C6 6.39543 6.89543 5.5 8 5.5H16C17.1046 5.5 18 6.39543 18 7.5V16.5C18 17.6046 17.1046 18.5 16 18.5H8C6.89543 18.5 6 17.6046 6 16.5V7.5ZM9 9.5V11H15V9.5H9ZM15 13V14.5H9V13H15Z"
        fill="#BD9784"
      />
    </BaseAvatar>
  );
};

export default AvatarAppInput;
