import {
  nextrouteDemoInputDelivery,
  nextrouteDemoInputDeliveryAdvanced,
  nextrouteDemoInputGeneral,
  nextrouteDemoInputGeneralAdvanced,
  nextrouteDemoInputSourcing,
  nextrouteDemoInputSourcingAdvanced,
} from "../../../../MarketplaceApp/data/demoNextrouteInput";
import {
  shiftSchedulingDemoInputGeneral,
  shiftSchedulingDemoInputMultiDay,
  shiftSchedulingDemoInputMultiDayHalfShifts,
} from "../../../../MarketplaceApp/data/demoShiftSchedulingInput";
import { AMPL_FACILITY_LOCATION_SUBSCRIPTION_ID } from "../../../../MarketplaceApp/data/marketAppAmplFacilityLocation";
import { AMPL_PRICE_OPTIMIZATION_SUBSCRIPTION_ID } from "../../../../MarketplaceApp/data/marketAppAmplPricing";
import { HIGHS_KNAPSACK_SUBSCRIPTION_ID } from "../../../../MarketplaceApp/data/marketAppHiGHSKnapsack";
import { ROUTING_LEGACY_SUBSCRIPTION_ID } from "../../../../MarketplaceApp/data/marketAppLegacyRouting";
import { KNAPSACK_SUBSCRIPTION_ID } from "../../../../MarketplaceApp/data/marketAppNextmvKnapsack";
import { NEXTROUTE_SUBSCRIPTION_ID } from "../../../../MarketplaceApp/data/marketAppNextroute";
import { ORDER_FULFILLMENT_SUBSCRIPTION_ID } from "../../../../MarketplaceApp/data/marketAppOrderFulfillment";
import { ORTOOLS_DEMAND_FORECASTING_SUBSCRIPTION_ID } from "../../../../MarketplaceApp/data/marketAppORToolsDemandForecasting";
import { ORTOOLS_KNAPSACK_SUBSCRIPTION_ID } from "../../../../MarketplaceApp/data/marketAppORToolsKnapsack";
import { ORTOOLS_SHIFT_ASSIGNMENT_SUBSCRIPTION_ID } from "../../../../MarketplaceApp/data/marketAppORToolsShiftAssignment";
import { ORTOOLS_SHIFT_PLANNING_SUBSCRIPTION_ID } from "../../../../MarketplaceApp/data/marketAppORToolsShiftPlanning";
import { ORTOOLS_SIMPLE_ROUTING_SUBSCRIPTION_ID } from "../../../../MarketplaceApp/data/marketAppORToolsSimpleRouting";
import { PYOMO_KNAPSACK_SUBSCRIPTION_ID } from "../../../../MarketplaceApp/data/marketAppPyomoKnapsack";
import { PYOMO_SHIFT_ASSIGNMENT_SUBSCRIPTION_ID } from "../../../../MarketplaceApp/data/marketAppPyomoShiftAssignment";
import { PYOMO_SHIFT_PLANNING_SUBSCRIPTION_ID } from "../../../../MarketplaceApp/data/marketAppPyomoShiftPlanning";
import { ROUTING_PYVROOM_SUBSCRIPTION_ID } from "../../../../MarketplaceApp/data/marketAppRoutingPyvroom";
import { SHIFT_SCHEDULING_SUBSCRIPTION_ID } from "../../../../MarketplaceApp/data/marketAppShiftScheduling";
import { sampleInputAmplFacilityLocation } from "../../../../MarketplaceApp/data/sample/inputAmplFacilityLocation";
import { sampleInputAmplPriceOptimization } from "../../../../MarketplaceApp/data/sample/inputAmplPriceOptimization";
import { sampleGoSdkShiftSchedulingLarge } from "../../../../MarketplaceApp/data/sample/inputGoSdkShiftSchedulingLarge";
import { sampleInputHiGHSKnapsack } from "../../../../MarketplaceApp/data/sample/inputHiGHSKnapsack";
import { sampleInputNextmvKnapsack } from "../../../../MarketplaceApp/data/sample/inputNextmvKnapsack";
import { sampleInputNextmvOrderFulfillment } from "../../../../MarketplaceApp/data/sample/inputNextmvOrderFulfillment";
import { sampleInputNextrouteDistributionExampleDay1 } from "../../../../MarketplaceApp/data/sample/inputNextrouteDistributionExampleDay1";
import { sampleInputNextrouteDistributionExampleDay2 } from "../../../../MarketplaceApp/data/sample/inputNextrouteDistributionExampleDay2";
import { sampleInputNextrouteDistributionExampleDay3 } from "../../../../MarketplaceApp/data/sample/inputNextrouteDistributionExampleDay3";
import { sampleInputORToolsDemandForecastingLarge } from "../../../../MarketplaceApp/data/sample/inputORToolsDemandForecastingLarge";
import { sampleInputORToolsDemandForecastingSmall } from "../../../../MarketplaceApp/data/sample/inputORToolsDemandForecastingSmall";
import { sampleInputORToolsKnapsack } from "../../../../MarketplaceApp/data/sample/inputORToolsKnapsack";
import { sampleInputORToolsRouting } from "../../../../MarketplaceApp/data/sample/inputORToolsRouting";
import { sampleInputORToolsShiftAssignment } from "../../../../MarketplaceApp/data/sample/inputORToolsShiftAssignment";
import { sampleInputORToolsShiftPlanning } from "../../../../MarketplaceApp/data/sample/inputORToolsShiftPlanning";
import { sampleInputPyomoKnapsack } from "../../../../MarketplaceApp/data/sample/inputPyomoKnapsack";
import { sampleInputPyomoShiftAssignment } from "../../../../MarketplaceApp/data/sample/inputPyomoShiftAssignment";
import { sampleInputPyomoShiftPlanning } from "../../../../MarketplaceApp/data/sample/inputPyomoShiftPlanning";
import { sampleInputRoutingLegacy } from "../../../../MarketplaceApp/data/sample/inputRoutingLegacy";
import { sampleInputRoutingPyvroom } from "../../../../MarketplaceApp/data/sample/inputRoutingPyvroom";

type LoadSampleInputParams = {
  onInputChange: (input: any) => void;
  subscriptionId?: string;
  type?: string;
};
export const loadSampleInput = ({
  onInputChange,
  subscriptionId,
  type,
}: LoadSampleInputParams) => {
  switch (subscriptionId) {
    case NEXTROUTE_SUBSCRIPTION_ID:
      switch (type) {
        case "general":
          return onInputChange(nextrouteDemoInputGeneral.data);
        case "general-advanced":
          return onInputChange(nextrouteDemoInputGeneralAdvanced.data);
        case "delivery":
          return onInputChange(nextrouteDemoInputDelivery.data);
        case "delivery-advanced":
          return onInputChange(nextrouteDemoInputDeliveryAdvanced.data);
        case "sourcing":
          return onInputChange(nextrouteDemoInputSourcing.data);
        case "sourcing-advanced":
          return onInputChange(nextrouteDemoInputSourcingAdvanced.data);
        case "distribution-day-1":
          return onInputChange(sampleInputNextrouteDistributionExampleDay1);
        case "distribution-day-2":
          return onInputChange(sampleInputNextrouteDistributionExampleDay2);
        case "distribution-day-3":
          return onInputChange(sampleInputNextrouteDistributionExampleDay3);
      }
      return onInputChange(nextrouteDemoInputGeneral.data);
    case SHIFT_SCHEDULING_SUBSCRIPTION_ID:
      switch (type) {
        case "general":
          return onInputChange(shiftSchedulingDemoInputGeneral.data);
        case "multi-day":
          return onInputChange(shiftSchedulingDemoInputMultiDay.data);
        case "multi-day-half-shifts":
          return onInputChange(shiftSchedulingDemoInputMultiDayHalfShifts.data);
        case "shift-scheduling-large":
          return onInputChange(sampleGoSdkShiftSchedulingLarge);
      }
      return onInputChange(shiftSchedulingDemoInputGeneral.data);
    case ROUTING_LEGACY_SUBSCRIPTION_ID:
      return onInputChange(sampleInputRoutingLegacy);
    case KNAPSACK_SUBSCRIPTION_ID:
      return onInputChange(sampleInputNextmvKnapsack);
    case ORDER_FULFILLMENT_SUBSCRIPTION_ID:
      return onInputChange(sampleInputNextmvOrderFulfillment);
    case ORTOOLS_KNAPSACK_SUBSCRIPTION_ID:
      return onInputChange(sampleInputORToolsKnapsack);
    case ORTOOLS_SHIFT_ASSIGNMENT_SUBSCRIPTION_ID:
      return onInputChange(sampleInputORToolsShiftAssignment);
    case ORTOOLS_SHIFT_PLANNING_SUBSCRIPTION_ID:
      return onInputChange(sampleInputORToolsShiftPlanning);
    case ORTOOLS_SIMPLE_ROUTING_SUBSCRIPTION_ID:
      return onInputChange(sampleInputORToolsRouting);
    case PYOMO_KNAPSACK_SUBSCRIPTION_ID:
      return onInputChange(sampleInputPyomoKnapsack);
    case PYOMO_SHIFT_ASSIGNMENT_SUBSCRIPTION_ID:
      return onInputChange(sampleInputPyomoShiftAssignment);
    case PYOMO_SHIFT_PLANNING_SUBSCRIPTION_ID:
      return onInputChange(sampleInputPyomoShiftPlanning);
    case ORTOOLS_DEMAND_FORECASTING_SUBSCRIPTION_ID:
      switch (type) {
        case "demand-small":
          return onInputChange(sampleInputORToolsDemandForecastingSmall);
        case "demand-large":
          return onInputChange(sampleInputORToolsDemandForecastingLarge);
      }
      return onInputChange(sampleInputORToolsDemandForecastingSmall);
    case AMPL_FACILITY_LOCATION_SUBSCRIPTION_ID:
      return onInputChange(sampleInputAmplFacilityLocation);
    case AMPL_PRICE_OPTIMIZATION_SUBSCRIPTION_ID:
      return onInputChange(sampleInputAmplPriceOptimization);
    case HIGHS_KNAPSACK_SUBSCRIPTION_ID:
      return onInputChange(sampleInputHiGHSKnapsack);
    case ROUTING_PYVROOM_SUBSCRIPTION_ID:
      return onInputChange(sampleInputRoutingPyvroom);
    default:
      break;
  }
};
