import * as React from "react";
import styled from "@emotion/styled";

import { StyledLabel } from "./InputCheckbox.styles";
import { InputCheckboxProps } from "./InputCheckbox.types";

const StyledInput = styled.input`
  position: absolute;
  opacity: 0;
`;

const InputCheckbox = ({
  checked,
  handleChange,
  id,
  isDisabled,
  isGray,
  isMedium,
  isRequired,
  isSmall,
  groupName,
  label,
  value,
  m,
  mt,
  mr,
  mb,
  ml,
  ...rest
}: InputCheckboxProps) => {
  return (
    <>
      <StyledInput
        type="checkbox"
        role="checkbox"
        aria-checked={checked ? "true" : "false"}
        required={isRequired}
        disabled={isDisabled}
        id={id}
        name={groupName}
        value={value}
        checked={checked}
        onChange={handleChange}
        {...rest}
      />

      <StyledLabel
        {...{ isDisabled, isGray, isMedium, isSmall, m, mt, mr, mb, ml }}
        htmlFor={id}
        selected={checked}
      >
        {label}
      </StyledLabel>
    </>
  );
};

export default InputCheckbox;
