import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import { User } from "../../api/core/dataPlane.types";
import { useUser } from "../../AuthProvider";
import EditInstalledIntegration from "../Integrations/components/EditInstalledIntegration.page";
import InstalledIntegration from "../Integrations/components/InstalledIntegration.page";
import InstalledIntegrations from "../Integrations/components/InstalledIntegrations.page";
import NewIntegration from "../Integrations/components/NewIntegration.page";
import { integrationIsFenced } from "../Integrations/helpers/fencing";
import { availableIntegrations } from "../Integrations/Integrations.config";
import AvailableIntegrations from "../Integrations/subpages/AvailableIntegrations";

import EditRunProfile from "./subpages/EditRunProfile";
import NewRunProfile from "./subpages/NewRunProfile";
import RunProfile from "./subpages/RunProfile";
import RunProfiles from "./subpages/RunProfiles";

export const getModifiedIntegrations = (user: User) => {
  return availableIntegrations.map((integration) => ({
    ...integration,
    isFenced: integrationIsFenced(user, integration.type),
  }));
};

const ConfigRoutes = () => {
  const [user] = useUser();
  const { path } = useRouteMatch();

  const modifiedIntegrations = getModifiedIntegrations(user);

  return (
    <Switch>
      {/* run profile routes */}
      <Route exact path={`${path}/run-profiles/new`}>
        <NewRunProfile />
      </Route>
      <Route exact path={`${path}/run-profiles/:id`}>
        <RunProfile />
      </Route>
      <Route exact path={`${path}/run-profiles/:id/edit`}>
        <EditRunProfile />
      </Route>
      <Route exact path={`${path}/run-profiles`}>
        <RunProfiles />
      </Route>

      {/* integration routes */}
      <Route exact path={`${path}/integrations/installed/:id/edit`}>
        <EditInstalledIntegration />
      </Route>
      <Route exact path={`${path}/integrations/installed/:id`}>
        <InstalledIntegration />
      </Route>
      <Route exact path={`${path}/integrations/installed`}>
        <InstalledIntegrations />
      </Route>

      {/* Nextmv Routing app integrations */}
      <Route exact path={`${path}/integrations/nextmv-routing`}>
        <AvailableIntegrations integrations={modifiedIntegrations} />
      </Route>
      <Route
        exact
        path={`${path}/integrations/nextmv-routing/:integrationType/new`}
      >
        <NewIntegration />
      </Route>

      {/* catch-all redirect for old details routes */}
      <Route path={`${path}/integrations/nextmv-routing/:integrationType`}>
        <Redirect to={`${path}/integrations/nextmv-routing`} />
      </Route>

      {/* catch-all redirect for anything after /integrations */}
      <Route path={`${path}/integrations`}>
        <Redirect to={`${path}/integrations/installed`} />
      </Route>

      {/* catch-all redirect to run profiles */}
      <Redirect to={`${path}/run-profiles`} />
    </Switch>
  );
};

export default ConfigRoutes;
