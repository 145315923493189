import * as React from "react";
import {
  LegendCircle,
  TooltipContainer,
  TooltipItem,
  TooltipLabel,
  TooltipList,
} from "@console/dsc/src/components/Chart/custom/Tooltip";
import Text from "@console/dsc/src/components/Text";
import { startCase } from "lodash";

import { formatIndicatorValue } from "../utils/groupedSummaryTable";

type CustomTooltipBoxPlotProps = {
  indicator: string;
  active?: boolean;
  payload?: any[];
};

// hack to display user-focused values rather than
// values required to build the chart step by step
const getBoxPlotDisplayValue = (value: number, key: string, payload: any) => {
  return payload?.display[key] || value;
};

// NOTE: active and payload props are passed from parent Chart
// component, see for example:
// https://recharts.org/en-US/examples/CustomContentOfTooltip
const CustomTooltipBoxPlot = ({
  active,
  indicator,
  payload,
}: CustomTooltipBoxPlotProps) => {
  const tooltipTitle =
    payload && !!payload.length && payload[0].payload.tooltipTitle;
  const clone = JSON.parse(JSON.stringify(payload));

  return !!active ? (
    <TooltipContainer>
      {tooltipTitle &&
        tooltipTitle
          .split(", ")
          .map((tooltipTitleEntity: string, index: number) => (
            <TooltipLabel key={`tooltip-title-${index}`}>
              {tooltipTitleEntity}
            </TooltipLabel>
          ))}

      <TooltipList>
        {!!payload?.length &&
          clone.reverse().map((datum, index) => {
            return (
              <TooltipItem key={`${datum.dataKey}-${index}`}>
                {datum.dataKey === "mean" && datum?.fill && (
                  <LegendCircle backgroundColor={datum.fill} />
                )}
                <Text styleName="meta-1">
                  {startCase(datum.name)}:{" "}
                  <Text
                    as="strong"
                    styleName="meta-1-bold"
                    styles={{ fontFeatureSettings: "tnum" }}
                  >
                    {formatIndicatorValue({
                      indicator,
                      type: "chart-value",
                      value: getBoxPlotDisplayValue(
                        datum.value,
                        datum.name,
                        datum.payload
                      ),
                    })}
                  </Text>
                </Text>
              </TooltipItem>
            );
          })}
      </TooltipList>
    </TooltipContainer>
  ) : (
    <></>
  );
};

export default CustomTooltipBoxPlot;
