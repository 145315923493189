import React from "react";

import { useUser } from "../../AuthProvider";
import Layout from "../../components/Layout";

import { getMarketplacePages } from "./data/pages";
import MarketplaceRoutes from "./Marketplace.routes";

const Apps = () => {
  const [{ id: accId }] = useUser();
  const marketplacePages = accId && getMarketplacePages(accId);

  return (
    <Layout sectionTitle="Marketplace" sectionPages={marketplacePages}>
      <MarketplaceRoutes />
    </Layout>
  );
};

export default Apps;
