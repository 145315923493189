import React, { useState } from "react";
import { Box, Button2, Flex } from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";

import { AppResponse } from "../../../../../api/core/controlPlane.types";
import ControlPanel from "../../../../../components/ControlPanel";
import InputEditor from "../../../../../components/InputEditor";
import UploadFile from "../../../../../components/UploadFile";
import { AcceptedFiles } from "../../../../../components/UploadFile/UploadFile.types";
import { appTooltips } from "../../../data/appTooltips";
import { UseCustomInputReturnValue } from "../hooks/useCustomInput";
import { getSampleInputOptions } from "../utils/getSampleInputOptions";

type RunInputProps = {
  app: AppResponse;
  acceptedFiles: AcceptedFiles;
  isClone: boolean;
  isRunning: boolean;
  shouldEditorRefresh: boolean;
  refreshEditorHeight: () => void;
  runInputState: UseCustomInputReturnValue;
  setAcceptedFiles: React.Dispatch<React.SetStateAction<AcceptedFiles>>;
  setIsInputModified: React.Dispatch<React.SetStateAction<boolean>>;
  setShouldEditorRefresh: React.Dispatch<React.SetStateAction<boolean>>;
};

const tabIdUploadFile = "run-input-upload-file";
const tabIdFreeForm = "run-input-free-form";

const RunInput = ({
  app,
  acceptedFiles,
  isClone,
  isRunning,
  refreshEditorHeight,
  runInputState,
  setAcceptedFiles,
  setIsInputModified,
  setShouldEditorRefresh,
  shouldEditorRefresh,
}: RunInputProps) => {
  const theme = useTheme();

  const [activeTab, setActiveTab] = useState(tabIdFreeForm);
  const [isControlPanelOpen, setIsControlPanelOpen] = useState(!isClone);

  const { inputState, onInputChange, handleValidationError } = runInputState;

  const loadSampleInputButtonOptions =
    app?.type === "subscription" &&
    getSampleInputOptions({
      subscriptionId: app?.subscription_id || "",
      onInputChange,
      setIsInputModified,
    });

  const getInputEditorHeight = (): string => {
    if (loadSampleInputButtonOptions) {
      return `calc(
        100% - 
        ${rem(56)} - 
        ${theme.spacing.s4} - 
        ${theme.spacing.s2}
      )`;
    }
    return `calc(100% - ${theme.spacing.s5})`;
  };
  const getInputEditorContainerBoxHeight = (): string => {
    // 22 is rendered height of ControlPanel summary element
    return `calc(100% - ${rem(22)})`;
  };

  const controlInputControlPanelExpandCollapse = (e: {
    preventDefault: () => void;
  }) => {
    e.preventDefault();

    setIsControlPanelOpen(!isControlPanelOpen);
    return refreshEditorHeight();
  };

  return (
    <ControlPanel
      testId="create-new-run-input"
      headerTitle="Input"
      headerTooltipContent={appTooltips.newRunInput.content}
      hasCustomContentWrapper
      hasNoBorder
      isExpanded
      isOpen={isControlPanelOpen}
      onClick={controlInputControlPanelExpandCollapse}
      tabs={[
        {
          id: tabIdFreeForm,
          // TODO: will update to Free-form when plain text supported as well
          label: "JSON",
          isActive: activeTab === tabIdFreeForm,
          onClick: (e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsControlPanelOpen(true);
            setActiveTab(tabIdFreeForm);
            return;
          },
        },
        {
          id: tabIdUploadFile,
          isActive: activeTab === tabIdUploadFile,
          label: "Upload file",
          onClick: (e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsControlPanelOpen(true);
            setActiveTab(tabIdUploadFile);
          },
        },
      ]}
    >
      {activeTab === tabIdUploadFile && (
        <UploadFile
          mt={4}
          {...{
            acceptedFiles,
            app,
            setAcceptedFiles,
            isMultiple: true,
            maxFiles: -1,
          }}
        />
      )}

      {activeTab === tabIdFreeForm && (
        <Box
          width="100%"
          height={getInputEditorContainerBoxHeight()}
          minHeight={rem(320)}
        >
          {loadSampleInputButtonOptions && (
            <Flex
              mt={4}
              p={3}
              style={{
                height: rem(56),
                position: "relative",
                borderRadius: theme.border.radius,
                backgroundColor: theme.color.gray100,
                zIndex: 99,
              }}
            >
              <Button2
                type="outline"
                {...(Array.isArray(loadSampleInputButtonOptions) && {
                  multiSelectOptions: loadSampleInputButtonOptions,
                })}
                {...(typeof loadSampleInputButtonOptions === "object" &&
                  !Array.isArray(loadSampleInputButtonOptions) && {
                    ...loadSampleInputButtonOptions,
                  })}
                styles={{ backgroundColor: theme.color.white }}
              />
            </Flex>
          )}

          <Flex
            mt={loadSampleInputButtonOptions ? 2 : 5}
            width="100%"
            height={getInputEditorHeight()}
            flexDirection="column"
          >
            {isRunning ? (
              <InputEditor
                input={inputState.input}
                isReadOnly={true}
                isCustomSchema={true}
                controlEditorModePositionTop={
                  loadSampleInputButtonOptions ? -54 : -49
                }
              />
            ) : (
              <InputEditor
                {...{ shouldEditorRefresh, setShouldEditorRefresh }}
                isCustomSchema={true}
                input={inputState.input}
                isModified={inputState.modified}
                onInputChange={onInputChange}
                onValidationError={handleValidationError}
                controlEditorModePositionTop={
                  loadSampleInputButtonOptions ? -54 : -49
                }
              />
            )}
          </Flex>
        </Box>
      )}
    </ControlPanel>
  );
};

export default RunInput;
