import L from "leaflet";

import "leaflet-polylinedecorator";

export const DEFAULT_ARROW_OFFSET = 50;
export const DEFAULT_ARROW_REPEAT = 200;

export const getArrowIconSvg = (color?: string) => {
  return `
    <svg width="30" height="30" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd" 
        clip-rule="evenodd" 
        d="M4.28647 14.0882L5.1984 13.2333L11.5984 7.2333C12.0792 6.7825 12.8274 6.7825 13.3082 7.2333L19.7082 13.2333L20.6202 14.0882L18.9103 15.9121L17.9984 15.0571L12.4533 9.8586L6.90825 15.0571L5.99633 15.9121L4.28647 14.0882Z"
        fill="${color || "black"}"
      />
    </svg>
  `;
};

export const createArrowMarker = (color?: string) => {
  return L.Symbol.marker({
    rotate: true,
    markerOptions: {
      pane: "directional-arrows",
      icon: L.divIcon({
        // use to select the icon in E2E testing
        className: "map-directional-arrow",
        html: getArrowIconSvg(color),
        iconSize: [32, 32],
        iconAnchor: [15, 15],
      }),
    },
  });
};

export const getDefaultPatterns = (color?: string) => [
  {
    offset: DEFAULT_ARROW_OFFSET,
    repeat: DEFAULT_ARROW_REPEAT,
    symbol: createArrowMarker(color),
  },
];
