import React from "react";
import { Button2, DatePicker, Flex, Text } from "@console/dsc";
import { useTheme } from "@emotion/react";
import { DateTime } from "luxon";

import { RunsData } from "../../../api/core/controlPlane.types";
import ButtonRefresh from "../../../components/ButtonRefresh";

type RunsQueryDateControlsProps = {
  clearSelectDateRange: () => void;
  closeSelectDateRange: () => void;
  confirmSelectDateRange: () => void;
  isActiveDatePicker: boolean;
  itemsLoading: boolean;
  queryEnd: string;
  queryStart: string;
  refreshRuns: () => void;
  setIsActiveDatePicker: React.Dispatch<React.SetStateAction<boolean>>;
  setQueryStart: React.Dispatch<React.SetStateAction<string>>;
  setQueryEnd: React.Dispatch<React.SetStateAction<string>>;
  setRunHistory: React.Dispatch<React.SetStateAction<RunsData>>;
  testOnlyHideDatePicker: boolean;
};

const RunsQueryDateControls = ({
  clearSelectDateRange,
  closeSelectDateRange,
  confirmSelectDateRange,
  isActiveDatePicker,
  itemsLoading,
  queryEnd,
  queryStart,
  refreshRuns,
  setIsActiveDatePicker,
  setQueryEnd,
  setQueryStart,
  setRunHistory,
  testOnlyHideDatePicker,
}: RunsQueryDateControlsProps) => {
  const theme = useTheme();

  return (
    <Flex
      alignItems={["flex-start", "flex-start", "flex-start", "center"]}
      flexDirection={["column", "column", "column", "row"]}
      relative
    >
      <Flex alignItems="center">
        <Button2
          type="outline"
          label={queryStart ? "Edit date range" : "Set date range"}
          onClick={() => setIsActiveDatePicker(!isActiveDatePicker)}
        />

        <ButtonRefresh
          emptyItems={null}
          itemsLoading={itemsLoading}
          loadItems={refreshRuns}
          setItems={setRunHistory}
        />
      </Flex>

      {(queryStart || queryEnd) && (
        <Flex mt={[2, 2, 2, 0]} ml={[0, 0, 0, 3]} alignItems="center">
          {queryStart && (
            <Text
              as="time"
              styleName="body-3"
              htmlDatetime={queryStart}
              htmlTitle={queryStart}
            >
              {DateTime.fromISO(queryStart).toFormat("LLL dd, yyyy")}
              <Text
                ml={1}
                as="span"
                styleName="meta-1"
                styles={{
                  letterSpacing: "0.05em",
                  color: theme.color.gray600,
                }}
              >
                {DateTime.fromISO(queryStart).toFormat("hh:mm ZZZZ")}
              </Text>
            </Text>
          )}
          {queryEnd && (
            <Text
              as="time"
              ml={1}
              styleName="body-3"
              htmlDatetime={queryEnd}
              htmlTitle={queryEnd}
            >
              &ndash; {DateTime.fromISO(queryEnd).toFormat("LLL dd, yyyy")}
              <Text
                ml={1}
                as="span"
                styleName="meta-1"
                styles={{
                  letterSpacing: "0.05em",
                  color: theme.color.gray600,
                }}
              >
                {DateTime.fromISO(queryEnd).toFormat("hh:mm ZZZZ")}
              </Text>
            </Text>
          )}
        </Flex>
      )}

      {!testOnlyHideDatePicker && (
        <DatePicker
          isRange
          isFutureDisabled
          clearAction={clearSelectDateRange}
          closeAction={closeSelectDateRange}
          isActive={isActiveDatePicker}
          offsetY={theme.spacing.s8}
          primaryAction={confirmSelectDateRange}
          setDateStart={setQueryStart}
          setDateEnd={setQueryEnd}
        />
      )}
    </Flex>
  );
};

export default RunsQueryDateControls;
