import * as React from "react";
import { useTheme } from "@emotion/react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { toK } from "../../../lib/tools";
import CustomLabel from "../custom/Label";
import CustomTooltip from "../custom/Tooltip";
import { getTickLabelStyle } from "../utils/chartStyles";

// FIXME: TypeScript gives error:
// Cannot use namespace 'Theme' as a type.
// when used below with theme: Theme
// import Theme from '../../../style/emotion.d'

const parameterRegionElements = [
  {
    id: "travelTime",
    element: (
      <Bar
        key="travelTime"
        dataKey="travelTime"
        fill="#00b9cc"
        stackId="this"
        isAnimationActive={false}
        barSize={32}
      />
    ),
  },
  {
    id: "latenessPenalty",
    element: (
      <Bar
        key="latenessPenalty"
        dataKey="latenessPenalty"
        fill="#a26592"
        stackId="this"
        isAnimationActive={false}
        barSize={32}
      />
    ),
  },
  {
    id: "earlinessPenalty",
    element: (
      <Bar
        key="earlinessPenalty"
        dataKey="earlinessPenalty"
        fill="#f6c605"
        stackId="this"
        isAnimationActive={false}
        barSize={32}
      />
    ),
  },
  {
    id: "unassignedPenalty",
    element: (
      <Bar
        key="unassignedPenalty"
        dataKey="unassignedPenalty"
        fill="#fe7316"
        stackId="this"
        isAnimationActive={false}
        barSize={32}
      />
    ),
  },
];

const displaySelectedParameterRegions = (regionsToDisplay: {
  [key: string]: boolean;
}) =>
  parameterRegionElements
    .filter((region) => regionsToDisplay[region.id])
    .map((region) => region.element);

const ChartSolutionValueFunction = ({
  data,
  regionsToDisplay,
  xAxisLabel,
  yAxisLabel,
  yAxisWidth,
}: {
  data: any;
  regionsToDisplay: { [key: string]: boolean };
  xAxisLabel?: string;
  yAxisLabel?: string;
  yAxisWidth: number;
}) => {
  const theme = useTheme();
  const tickLabelStyle = getTickLabelStyle(theme);

  // note that sibling component order corresponds
  // to z-index in the rendered chart (with first in
  // component order being the bottom in z-index)
  return (
    <ResponsiveContainer width="100%" height={340}>
      <BarChart data={data} margin={{ bottom: 24, left: 24 }}>
        <CartesianGrid
          stroke={theme.color.gray200T}
          vertical={false}
          style={{
            backgroundColor: theme.color.gray100,
          }}
        />

        <Tooltip
          // TODO: update so CustomTooltip takes in props derrived from
          // typing from the recharts library - for now, just type them as "any"
          // to prevent error
          // https://recharts.org/en-US/examples/CustomContentOfTooltip
          content={(props: any) => <CustomTooltip {...props} />}
          isAnimationActive={false}
          cursor={{ fill: theme.color.gray200T }}
        />

        {displaySelectedParameterRegions(regionsToDisplay)}

        <XAxis
          dataKey="solution"
          label={<CustomLabel>{xAxisLabel}</CustomLabel>}
          padding={{ left: 24, right: 24 }}
          stroke={theme.color.gray300}
          style={tickLabelStyle}
          tickMargin={8}
          tickSize={0}
          // tickFormatter={value => (Math.round(value * 10) / 10).toString()}
          // type="number"
          // unit="s"
        />

        <YAxis
          // dataKey="value"
          // domain={[ 'dataMin', 'auto' ]}
          interval="preserveStart"
          label={<CustomLabel isYAxis>{yAxisLabel}</CustomLabel>}
          padding={{ top: 16, bottom: 8 }}
          stroke={theme.color.gray300}
          style={tickLabelStyle}
          // tickCount={5}
          tickMargin={4}
          tickSize={0}
          tickFormatter={(value) => toK(value)}
          width={yAxisWidth}
          // unit="k"
        />

        {/* TODO <Legend/>*/}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ChartSolutionValueFunction;
