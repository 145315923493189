import * as React from "react";
import { components } from "react-select";
import styled from "@emotion/styled";

import { rem } from "../../../lib/tools";
import Flex from "../../Flex";
import Text from "../../Text";

export const Capitalize = styled.span`
  text-transform: capitalize;
`;

// get Option base component from library
const { Option } = components;

// copied from web project
type IntegrationKind = "onfleet" | "here" | "streets";
type IntegrationCategory = "system" | "measure";
type IntegrationClass = "system" | "extension";
type IntegrationOption = {
  id: string;
  name: string;
  type: IntegrationKind;
  class: IntegrationClass;
  category: IntegrationCategory;
  description?: string;
  isEarlyAccess?: boolean;
  avatar: React.ReactNode;
};

export const renderIntegrationOption = (data: IntegrationOption) => {
  return (
    <Flex width="100%">
      <Flex alignItems="flex-start" justifyContent="flex-start">
        <Flex mt={rem(2)}>{data?.avatar}</Flex>
        <Flex alignItems="baseline">
          {data?.name && (
            <Text ml={2} styleName="body-1-bold">
              {data.name}
            </Text>
          )}
          {data?.type && (
            <Text ml={1} styleName="body-3">
              <Capitalize>{data.type}</Capitalize>
            </Text>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

const CustomOptionIntegration = (props: any) => {
  // get data to build option, this is the object from
  // the data array for this particular option
  const { data } = props;

  return <Option {...props}>{renderIntegrationOption(data)}</Option>;
};

export default CustomOptionIntegration;
