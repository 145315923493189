/*
  NOTE
  Moved old ID error management functions to the Config section
  as they are only used now for run profiles and integrations.
  These two features are deprecated and are only supported for
  the few legacy customers that remain. Once those customers 
  have been transitioned to v1, this file and its contents can
  be deleted along with everything else related to run profiles
  and integrations.
*/
import {
  AppInstance,
  AppResponse,
  AppVersion,
  BatchExperiment,
  Integration,
  RunProfile,
} from "../../../api/core/controlPlane.types";
import { capitalizeFirstLetter } from "../../../utils/format";
import { CONTENT_ID_RULES } from "../../../utils/systemIds";
import { AvailableIntegrationName } from "../../Integrations/Integrations.types";

export type GetIdErrorType =
  | "run profile"
  | AvailableIntegrationName
  | "app"
  | "batch experiment"
  | "acceptance test"
  | "shadow test"
  | "switchback test"
  | "instance"
  | "version"
  | "input set";
export type CheckIdFunc = (
  arg0: string
) =>
  | RunProfile
  | Integration
  | BatchExperiment
  | AppResponse
  | AppInstance
  | AppVersion
  | undefined;

type GetIdErrorParams = {
  id: string;
  checkIdFunc?: CheckIdFunc;
  type?: GetIdErrorType;
};

export const getIdError = ({
  checkIdFunc,
  id,
  type,
}: GetIdErrorParams): string => {
  if (checkIdFunc && checkIdFunc(id)) {
    return `${capitalizeFirstLetter(
      type || "entity"
    )} with ID ${id} already exist. Please provide a unique ID.`;
  }

  if (!/^[a-z0-9-.]*$/.test(id)) {
    return CONTENT_ID_RULES;
  }
  return "";
};
