import * as React from "react";

import BaseIcon, { IconProps } from "./BaseIcon";

const IconLock = ({
  iconSize,
  iconColor,
  iconColorActive,
  iconColorFocus,
  iconColorHover,
}: IconProps) => {
  return (
    <BaseIcon
      {...{
        iconSize,
        iconColor,
        iconColorActive,
        iconColorFocus,
        iconColorHover,
      }}
    >
      <path d="M8 2.66668C9.09848 2.66668 10 3.5682 10 4.66668V6.66668H6V4.66668C6 3.5682 6.90152 2.66668 8 2.66668ZM11.3333 6.66668V4.66668C11.3333 2.83182 9.83486 1.33334 8 1.33334C6.16514 1.33334 4.66667 2.83182 4.66667 4.66668V6.66668H4C3.26362 6.66668 2.66667 7.26363 2.66667 8.00001V13.3333C2.66667 14.0697 3.26362 14.6667 4 14.6667H12C12.7364 14.6667 13.3333 14.0697 13.3333 13.3333V8.00001C13.3333 7.26363 12.7364 6.66668 12 6.66668H11.3333ZM4 8.00001H12V13.3333H4V8.00001Z" />
    </BaseIcon>
  );
};

export default IconLock;
