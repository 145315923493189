import { ConfigDisplay } from "../../App/App.types";
import { InputSetCreateTypeOption } from "../Experiments.types";

type GetConfigDisplayParams = {
  entity: string;
  id: string;
  name: string;
  createTypeOption?: InputSetCreateTypeOption;
};

export const getConfigForNewInputSet = ({
  createTypeOption,
  entity,
  id,
  name,
}: GetConfigDisplayParams): ConfigDisplay => {
  return {
    views: ["main"],
    rows: [
      {
        id: "upload-files",
        views: ["hasNoBorder", "render"],
      },
    ],
    presets: {
      name: name,
      id: id,
      description: `Automatically created from ${entity}: ${id}`,
      createType: createTypeOption,
    },
  };
};
