import { Box, Flex, Text } from "@console/dsc";
import { useTheme } from "@emotion/react";
import { DateTime } from "luxon";

import {
  ScrollYFlex,
  StyledHourPanelsFlex,
  StyledScheduleWorkersCol,
  StyledScheduleWorkersContent,
} from "../RunDetails.styled";
import { RequiredWorkerShift, WorkerToSchedule } from "../RunDetails.types";
import { getPanelHeight, VizHourRange } from "../utils/getWorkerData";
import {
  renderHoursPanels,
  renderWorkerRequiredPeriods,
  renderWorkers,
  renderWorkerSchedulePeriods,
} from "../utils/renderWorkerData";

type VisualizationSchedulingProps = {
  requiredWorkerShifts: RequiredWorkerShift[];
  visualizeHoursRange?: VizHourRange;
  workers?: WorkerToSchedule[];
  workerColors?: { [key: string]: string };
};

const VisualizationSchedulingInput = ({
  workers,
  workerColors,
  requiredWorkerShifts,
  visualizeHoursRange,
}: VisualizationSchedulingProps) => {
  const theme = useTheme();

  return (
    <ScrollYFlex flexDirection="row" alignItems="stretch">
      <StyledScheduleWorkersCol
        pt={3}
        pr={3}
        pb={4}
        pl={4}
        panelHeight={getPanelHeight(workers ? workers.length : 0)}
      >
        <StyledScheduleWorkersContent>
          <Text styleName="body-2-bold" styles={{ color: theme.color.gray800 }}>
            Available Workers
          </Text>

          <Box mt={10}>{renderWorkers({ theme, workers, workerColors })}</Box>
        </StyledScheduleWorkersContent>
      </StyledScheduleWorkersCol>

      <StyledHourPanelsFlex
        pt={3}
        pr={6}
        pb={5}
        pl={6}
        panelHeight={getPanelHeight(workers ? workers.length : 0)}
      >
        <Text
          ml={2}
          styleName="body-2-bold"
          styles={{
            color: theme.color.gray800,
          }}
        >
          Date &amp; times{" "}
          <Text
            as="span"
            styleName="meta-1"
            styles={{ color: theme.color.gray600 }}
          >
            ({DateTime.now().toFormat("ZZZZZ")})
          </Text>
        </Text>

        <Flex pt={3} flexGrow={1} relative>
          {renderHoursPanels({ hourRange: visualizeHoursRange, theme })}

          <div>
            {renderWorkerSchedulePeriods({
              hourRange: visualizeHoursRange,
              theme,
              workerColors,
              workers,
            })}
            {renderWorkerRequiredPeriods({
              hourRange: visualizeHoursRange,
              requiredWorkerShifts,
              workerCount: workers ? workers.length : 0,
              theme,
            })}
          </div>
        </Flex>
      </StyledHourPanelsFlex>
    </ScrollYFlex>
  );
};

export default VisualizationSchedulingInput;
