import { RunScenarioOptionSetConfig } from "../../../api/core/controlPlane.types";
import { Table2HeaderObj } from "../../../pages/App/subpages/RunHistory/RunHistory.types";
import { StyledOptionSetConfig } from "../ScenarioDefinitions.styled";

export const getOptionsString = (
  options?: RunScenarioOptionSetConfig
): string => {
  if (!options) return "";

  return Object.keys(options)
    .map((optionKey) => {
      return `${optionKey}: ${options[optionKey]}`;
    })
    .join("\n");
};

export const getOptionSetHeaders = (): Table2HeaderObj[] => {
  return [
    {
      id: "option-set-id",
      accessorKey: "optionSetId",
      label: "Option Set ID Header",
      header: "Option Set ID",
    },
    {
      id: "options",
      accessorKey: "options",
      label: "Options Header",
      header: "Options",
      cell: (props) => {
        return (
          <StyledOptionSetConfig>{props.getValue()}</StyledOptionSetConfig>
        );
      },
    },
    {
      id: "input-set-id",
      accessorKey: "inputSetId",
      label: "Input Set ID Header",
      header: "Input Set ID",
    },
    {
      id: "instance-id",
      accessorKey: "instanceId",
      label: "Instance ID Header",
      header: "Instance ID",
    },
    {
      id: "version-id",
      accessorKey: "versionId",
      label: "Version ID Header",
      header: "Version ID",
    },
  ];
};

export const optionSetTableLayoutSchema = [
  { width: "17.5%" }, // option set ID
  { width: "30%" }, // options
  { width: "17.5%" }, // input set ID
  { width: "17.5%" }, // instance ID
  { width: "17.5%" }, // version ID
];
