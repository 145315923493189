import React from "react";
import { Link } from "react-router-dom";
import { Text } from "@console/dsc";
import { useTheme } from "@emotion/react";

import { trackEvent, TrackEvents } from "../../../analytics/functions";

type AuthFlowLinkProps = {
  label: string;
  to: string;
  queryParams?: { [key: string]: string };
  trackEventCategory?: "AuthFlow";
  trackEventProperties?: TrackEvents["AuthFlow"];
};

const AuthFlowLink = ({
  label,
  queryParams,
  to,
  trackEventCategory,
  trackEventProperties,
}: AuthFlowLinkProps) => {
  const theme = useTheme();
  const queryParamString: string = queryParams
    ? Object.keys(queryParams).reduce((query: string, key: string) => {
        return query + `${key}=${encodeURIComponent(queryParams[key])}`;
      }, "?")
    : "";

  return (
    <Text
      mt={[3, 6]}
      styleName="body-2"
      hasLinkGray
      styles={{ color: theme.color.gray600 }}
    >
      <Link
        to={`${to}${queryParamString}`}
        {...(trackEventCategory &&
          trackEventProperties && {
            onClick: () => trackEvent(trackEventCategory, trackEventProperties),
          })}
      >
        {label}
      </Link>
    </Text>
  );
};

export default AuthFlowLink;
