import React from "react";
import { Box, Button2, Flex, Text } from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";

import { ConfirmDeclineProps } from "../Invites.types";

const ConfirmDeclineInvite = ({
  invite,
  primaryActionLabel,
  primaryActionOnClick,
  secondaryActionLabel,
  secondaryActionOnClick,
}: ConfirmDeclineProps) => {
  const theme = useTheme();
  const hasPrimaryAction = !!primaryActionLabel && !!primaryActionOnClick;
  const hasSecondaryAction = !!secondaryActionLabel && !!secondaryActionOnClick;

  return (
    <Flex mt={[4, 0]} flexDirection={["column", "row"]}>
      <Box maxWidth={rem(400)}>
        <Text styleName="body-3">
          Are you sure you want to decline the invite to join{" "}
          <Text
            as="strong"
            styleName="body-3-bold"
            styles={{ color: theme.color.gray800 }}
          >
            {invite.name}
          </Text>
          ?
        </Text>
      </Box>

      <Flex mt={[2, 1]} ml={[0, 4]}>
        {hasPrimaryAction && (
          <Button2
            testId="action-row-confirm-decline-button"
            label={primaryActionLabel}
            onClick={primaryActionOnClick}
          />
        )}
        {hasSecondaryAction && (
          <Button2
            type="outline"
            ml={hasPrimaryAction ? 2 : 0}
            label={secondaryActionLabel}
            onClick={secondaryActionOnClick}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default ConfirmDeclineInvite;
