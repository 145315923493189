import { UserRoles } from "../../../api/core/dataPlane.types";
import { PageNavigationItem } from "../../../components/MainNav/MainNav.types";
import { userHasAccessToAction } from "../../../utils/rbac_utils";
import { ActionGroups } from "../../../utils/rbac_utils/types";

type GetTeamPagesParams = {
  isUserNonRootOnUnpaidAccount: boolean;
  userId?: string | null;
  userRoles: UserRoles;
};

export const getTeamPages = ({
  isUserNonRootOnUnpaidAccount,
  userId,
  userRoles,
}: GetTeamPagesParams): PageNavigationItem[] => {
  const userCanManageAPIKeys = userHasAccessToAction(
    userRoles,
    ActionGroups.SecurityOperator,
    {}
  );

  return isUserNonRootOnUnpaidAccount
    ? [
        {
          id: "team-details",
          label: "Details",
          url: userId ? `/acc/${userId}/team` : "/team",
        },
      ]
    : [
        {
          id: "team-details",
          label: "Details",
          url: userId ? `/acc/${userId}/team` : "/team",
        },
        {
          id: "team-members",
          label: "Members",
          url: userId ? `/acc/${userId}/team/members` : "/team/members",
        },
        ...(userCanManageAPIKeys
          ? [
              {
                id: "team-api-keys",
                label: "API Keys",
                url: userId ? `/acc/${userId}/team/api-keys` : "/team/api-keys",
              },
            ]
          : []),
        {
          id: "team-run-usage",
          label: "Usage",
          url: userId ? `/acc/${userId}/team/usage` : "/team/usage",
        },
        {
          id: "team-plan",
          label: "Plan",
          url: userId ? `/acc/${userId}/team/plan` : "/team/plan",
        },
      ];
};
