import * as React from "react";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";

import { svgIconStyle } from "../lib/constants";

type StyledSvgProps = {
  activeColor?: string;
  focusColor?: string;
  hoverColor?: string;
};
const StyledSvg = styled.svg<StyledSvgProps>`
  ${(props) =>
    svgIconStyle({
      activeColor: props.activeColor,
      focusColor: props.focusColor,
      hoverColor: props.hoverColor,
    })}
`;

const IconDemoTruck = ({
  activeColor,
  focusColor,
  hoverColor,
  iconColor,
  iconSize,
}: {
  activeColor?: string;
  focusColor?: string;
  hoverColor?: string;
  iconColor?: string;
  iconSize?: number;
}) => {
  const theme = useTheme();
  const thisIconSize = iconSize || 32;
  return (
    <StyledSvg
      {...{ activeColor, focusColor, hoverColor }}
      width={thisIconSize}
      height={thisIconSize}
      viewBox="0 0 32 32"
      fill={iconColor || theme.color.gray700}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M25.625 5H6.375C6.14294 5 5.92038 5.09219 5.75628 5.25628C5.59219 5.42038 5.5 5.64294 5.5 5.875V19.875C5.5 20.1071 5.59219 20.3296 5.75628 20.4937C5.92038 20.6578 6.14294 20.75 6.375 20.75V26.875C6.375 27.1071 6.46719 27.3296 6.63128 27.4937C6.79538 27.6578 7.01794 27.75 7.25 27.75H9C9.23206 27.75 9.45462 27.6578 9.61872 27.4937C9.78281 27.3296 9.875 27.1071 9.875 26.875V25.125H22.125V26.875C22.125 27.1071 22.2172 27.3296 22.3813 27.4937C22.5454 27.6578 22.7679 27.75 23 27.75H24.75C24.9821 27.75 25.2046 27.6578 25.3687 27.4937C25.5328 27.3296 25.625 27.1071 25.625 26.875V20.75C25.8571 20.75 26.0796 20.6578 26.2437 20.4937C26.4078 20.3296 26.5 20.1071 26.5 19.875V5.875C26.5 5.64294 26.4078 5.42038 26.2437 5.25628C26.0796 5.09219 25.8571 5 25.625 5V5ZM11.625 22.9375C11.625 23.0535 11.5789 23.1648 11.4969 23.2469C11.4148 23.3289 11.3035 23.375 11.1875 23.375H8.5625C8.44647 23.375 8.33519 23.3289 8.25314 23.2469C8.17109 23.1648 8.125 23.0535 8.125 22.9375V22.0625C8.125 21.9465 8.17109 21.8352 8.25314 21.7531C8.33519 21.6711 8.44647 21.625 8.5625 21.625H11.1875C11.3035 21.625 11.4148 21.6711 11.4969 21.7531C11.5789 21.8352 11.625 21.9465 11.625 22.0625V22.9375ZM23.875 22.9375C23.875 23.0535 23.8289 23.1648 23.7469 23.2469C23.6648 23.3289 23.5535 23.375 23.4375 23.375H20.8125C20.6965 23.375 20.5852 23.3289 20.5031 23.2469C20.4211 23.1648 20.375 23.0535 20.375 22.9375V22.0625C20.375 21.9465 20.4211 21.8352 20.5031 21.7531C20.5852 21.6711 20.6965 21.625 20.8125 21.625H23.4375C23.5535 21.625 23.6648 21.6711 23.7469 21.7531C23.8289 21.8352 23.875 21.9465 23.875 22.0625V22.9375ZM23.875 16.375C23.8743 16.6069 23.7819 16.829 23.618 16.993C23.454 17.1569 23.2319 17.2493 23 17.25H9C8.76814 17.2493 8.54597 17.1569 8.38202 16.993C8.21807 16.829 8.12567 16.6069 8.125 16.375V12C8.12567 11.7681 8.21807 11.546 8.38202 11.382C8.54597 11.2181 8.76814 11.1257 9 11.125H23C23.2319 11.1257 23.454 11.2181 23.618 11.382C23.7819 11.546 23.8743 11.7681 23.875 12V16.375Z" />
    </StyledSvg>
  );
};

export default IconDemoTruck;
