import { MarketplaceAppDemoData } from "../MarketplaceApp.types";

export const scheduleDemoIdGeneral = "general-schedule";
export const scheduleDemoIdMultiDay = "medium-schedule";
export const scheduleDemoIdMultiDayHalfShifts = "large-schedule";

export const shiftSchedulingDemoInputOptions = [
  { label: "General Schedule", value: scheduleDemoIdGeneral },
  { label: "Multi-day Schedule", value: scheduleDemoIdMultiDay },
  {
    label: "Multi-day Schedule w/half-shifts",
    value: scheduleDemoIdMultiDayHalfShifts,
  },
];

export const shiftSchedulingDemoInputGeneral: MarketplaceAppDemoData = {
  id: scheduleDemoIdGeneral,
  description:
    "Generic scheduling example with shift recovery time set to 30 minutes and solver duration set to 3 seconds.",
  data: {
    workers: [
      {
        availability: [
          {
            start: "2023-09-02T12:00:00+00:00",
            end: "2023-09-02T18:00:00+00:00",
          },
          {
            start: "2023-09-02T19:00:00+00:00",
            end: "2023-09-02T23:00:00+00:00",
          },
        ],
        id: "jane-doe",
      },
      {
        availability: [
          {
            start: "2023-09-02T13:00:00+00:00",
            end: "2023-09-02T18:00:00+00:00",
          },
          {
            start: "2023-09-02T19:00:00+00:00",
            end: "2023-09-02T22:00:00+00:00",
          },
        ],
        id: "john-doe",
      },
      {
        availability: [
          {
            start: "2023-09-02T16:00:00+00:00",
            end: "2023-09-03T00:00:00+00:00",
          },
        ],
        id: "jim-smith",
      },
      {
        availability: [
          {
            start: "2023-09-02T11:00:00+00:00",
            end: "2023-09-02T18:00:00+00:00",
          },
        ],
        id: "ashley-jordan",
      },
      {
        availability: [
          {
            start: "2023-09-02T17:00:00+00:00",
            end: "2023-09-03T00:00:00+00:00",
          },
        ],
        id: "pam-linville",
      },
      {
        availability: [
          {
            start: "2023-09-02T12:00:00+00:00",
            end: "2023-09-02T18:00:00+00:00",
          },
        ],
        id: "michael-adkins",
      },
      {
        availability: [
          {
            start: "2023-09-02T15:00:00+00:00",
            end: "2023-09-02T18:00:00+00:00",
          },
          {
            start: "2023-09-02T19:00:00+00:00",
            end: "2023-09-02T22:00:00+00:00",
          },
          {
            start: "2023-09-02T23:00:00+00:00",
            end: "2023-09-03T01:00:00+00:00",
          },
        ],
        id: "cherise-fulmer",
      },
    ],
    required_workers: [
      {
        start: "2023-09-02T13:00:00+00:00",
        end: "2023-09-02T18:00:00+00:00",
        count: 4,
      },
      {
        start: "2023-09-02T18:00:00+00:00",
        end: "2023-09-03T00:00:00+00:00",
        count: 2,
      },
    ],
  },
};

export const shiftSchedulingDemoInputMultiDay: MarketplaceAppDemoData = {
  id: scheduleDemoIdMultiDay,
  description:
    "Multi-day scheduling example with solver duration set to 3 seconds.",
  data: {
    workers: [
      {
        availability: [
          {
            start: "2023-09-02T12:00:00+00:00",
            end: "2023-09-02T22:00:00+00:00",
          },
          {
            start: "2023-09-03T02:00:00+00:00",
            end: "2023-09-03T12:00:00+00:00",
          },
          {
            start: "2023-09-04T12:00:00+00:00",
            end: "2023-09-05T04:00:00+00:00",
          },
        ],
        id: "jane-doe",
      },
      {
        availability: [
          {
            start: "2023-09-02T17:00:00+00:00",
            end: "2023-09-03T03:00:00+00:00",
          },
          {
            start: "2023-09-03T20:00:00+00:00",
            end: "2023-09-05T06:00:00+00:00",
          },
        ],
        id: "john-doe",
      },
      {
        availability: [
          {
            start: "2023-09-02T12:00:00+00:00",
            end: "2023-09-04T00:00:00+00:00",
          },
          {
            start: "2023-09-04T12:00:00+00:00",
            end: "2023-09-05T00:00:00+00:00",
          },
        ],
        id: "jim-smith",
      },
      {
        availability: [
          {
            start: "2023-09-02T12:00:00+00:00",
            end: "2023-09-03T10:00:00+00:00",
          },
          {
            start: "2023-09-04T05:00:00+00:00",
            end: "2023-09-04T18:00:00+00:00",
          },
          {
            start: "2023-09-05T06:00:00+00:00",
            end: "2023-09-05T18:00:00+00:00",
          },
        ],
        id: "ashley-jordan",
      },
      {
        availability: [
          {
            start: "2023-09-02T10:00:00+00:00",
            end: "2023-09-02T18:00:00+00:00",
          },
          {
            start: "2023-09-03T08:00:00+00:00",
            end: "2023-09-03T16:00:00+00:00",
          },
          {
            start: "2023-09-04T07:00:00+00:00",
            end: "2023-09-04T15:00:00+00:00",
          },
          {
            start: "2023-09-05T08:00:00+00:00",
            end: "2023-09-05T18:00:00+00:00",
          },
        ],
        id: "pam-linville",
      },
      {
        availability: [
          {
            start: "2023-09-02T12:00:00+00:00",
            end: "2023-09-02T20:00:00+00:00",
          },
          {
            start: "2023-09-03T08:00:00+00:00",
            end: "2023-09-03T18:00:00+00:00",
          },
          {
            start: "2023-09-03T20:00:00+00:00",
            end: "2023-09-04T06:00:00+00:00",
          },
          {
            start: "2023-09-04T10:00:00+00:00",
            end: "2023-09-04T18:00:00+00:00",
          },
          {
            start: "2023-09-05T00:00:00+00:00",
            end: "2023-09-05T18:00:00+00:00",
          },
        ],
        id: "michael-adkins",
      },
      {
        availability: [
          {
            start: "2023-09-03T06:00:00+00:00",
            end: "2023-09-03T15:00:00+00:00",
          },
          {
            start: "2023-09-03T16:00:00+00:00",
            end: "2023-09-04T02:00:00+00:00",
          },
          {
            start: "2023-09-04T19:00:00+00:00",
            end: "2023-09-05T06:00:00+00:00",
          },
          {
            start: "2023-09-05T12:00:00+00:00",
            end: "2023-09-05T20:00:00+00:00",
          },
        ],
        id: "cherise-fulmer",
      },
      {
        availability: [
          {
            start: "2023-09-02T11:00:00+00:00",
            end: "2023-09-02T21:00:00+00:00",
          },
          {
            start: "2023-09-02T22:00:00+00:00",
            end: "2023-09-03T08:00:00+00:00",
          },
          {
            start: "2023-09-04T00:00:00+00:00",
            end: "2023-09-04T08:00:00+00:00",
          },
          {
            start: "2023-09-04T20:00:00+00:00",
            end: "2023-09-05T16:00:00+00:00",
          },
        ],
        id: "donald-elm",
      },
      {
        availability: [
          {
            start: "2023-09-02T20:00:00+00:00",
            end: "2023-09-03T06:00:00+00:00",
          },
          {
            start: "2023-09-03T08:00:00+00:00",
            end: "2023-09-03T16:00:00+00:00",
          },
          {
            start: "2023-09-03T20:00:00+00:00",
            end: "2023-09-04T04:00:00+00:00",
          },
          {
            start: "2023-09-04T12:00:00+00:00",
            end: "2023-09-04T23:00:00+00:00",
          },
          {
            start: "2023-09-05T09:00:00+00:00",
            end: "2023-09-05T19:00:00+00:00",
          },
        ],
        id: "courtney-orange",
      },
      {
        availability: [
          {
            start: "2023-09-02T18:00:00+00:00",
            end: "2023-09-03T04:00:00+00:00",
          },
          {
            start: "2023-09-03T20:00:00+00:00",
            end: "2023-09-04T21:00:00+00:00",
          },
          {
            start: "2023-09-04T22:00:00+00:00",
            end: "2023-09-05T08:00:00+00:00",
          },
        ],
        id: "barleycorn-wilson",
      },
      {
        availability: [
          {
            start: "2023-09-02T18:00:00+00:00",
            end: "2023-09-03T04:00:00+00:00",
          },
          {
            start: "2023-09-04T00:00:00+00:00",
            end: "2023-09-04T08:00:00+00:00",
          },
          {
            start: "2023-09-04T10:00:00+00:00",
            end: "2023-09-04T20:00:00+00:00",
          },
        ],
        id: "ida-bucket",
      },
    ],
    required_workers: [
      {
        start: "2023-09-02T10:00:00+00:00",
        end: "2023-09-02T18:00:00+00:00",
        count: 1,
      },
      {
        start: "2023-09-02T18:00:00+00:00",
        end: "2023-09-03T02:00:00+00:00",
        count: 1,
      },
      {
        start: "2023-09-03T02:00:00+00:00",
        end: "2023-09-03T10:00:00+00:00",
        count: 1,
      },
      {
        start: "2023-09-03T10:00:00+00:00",
        end: "2023-09-03T18:00:00+00:00",
        count: 1,
      },
      {
        start: "2023-09-03T18:00:00+00:00",
        end: "2023-09-04T02:00:00+00:00",
        count: 1,
      },
      {
        start: "2023-09-04T02:00:00+00:00",
        end: "2023-09-04T10:00:00+00:00",
        count: 1,
      },
      {
        start: "2023-09-04T10:00:00+00:00",
        end: "2023-09-04T18:00:00+00:00",
        count: 1,
      },
      {
        start: "2023-09-04T18:00:00+00:00",
        end: "2023-09-05T02:00:00+00:00",
        count: 1,
      },
      {
        start: "2023-09-05T02:00:00+00:00",
        end: "2023-09-05T10:00:00+00:00",
        count: 1,
      },
      {
        start: "2023-09-05T10:00:00+00:00",
        end: "2023-09-05T18:00:00+00:00",
        count: 1,
      },
    ],
  },
};

export const shiftSchedulingDemoInputMultiDayHalfShifts: MarketplaceAppDemoData =
  {
    id: scheduleDemoIdMultiDayHalfShifts,
    description:
      "Multi-day scheduling example with shift max duration and recovery time set to 4 hours, day max duration set to 12 hours, and solver duration set to 3 seconds. ",
    data: {
      workers: [
        {
          availability: [
            {
              start: "2023-09-02T10:00:00+00:00",
              end: "2023-09-02T22:00:00+00:00",
            },
            {
              start: "2023-09-03T02:00:00+00:00",
              end: "2023-09-03T12:00:00+00:00",
            },
            {
              start: "2023-09-03T16:00:00+00:00",
              end: "2023-09-04T04:00:00+00:00",
            },
            {
              start: "2023-09-04T12:00:00+00:00",
              end: "2023-09-05T04:00:00+00:00",
            },
          ],
          id: "jane-doe",
        },
        {
          availability: [
            {
              start: "2023-09-02T10:00:00+00:00",
              end: "2023-09-03T03:00:00+00:00",
            },
            {
              start: "2023-09-03T12:00:00+00:00",
              end: "2023-09-03T20:00:00+00:00",
            },
            {
              start: "2023-09-03T20:00:00+00:00",
              end: "2023-09-05T06:00:00+00:00",
            },
          ],
          id: "john-doe",
        },
        {
          availability: [
            {
              start: "2023-09-02T12:00:00+00:00",
              end: "2023-09-04T00:00:00+00:00",
            },
            {
              start: "2023-09-04T12:00:00+00:00",
              end: "2023-09-05T00:00:00+00:00",
            },
          ],
          id: "jim-smith",
        },
        {
          availability: [
            {
              start: "2023-09-02T12:00:00+00:00",
              end: "2023-09-03T10:00:00+00:00",
            },
            {
              start: "2023-09-04T05:00:00+00:00",
              end: "2023-09-04T18:00:00+00:00",
            },
            {
              start: "2023-09-05T06:00:00+00:00",
              end: "2023-09-05T18:00:00+00:00",
            },
          ],
          id: "ashley-jordan",
        },
        {
          availability: [
            {
              start: "2023-09-02T10:00:00+00:00",
              end: "2023-09-02T18:00:00+00:00",
            },
            {
              start: "2023-09-03T02:00:00+00:00",
              end: "2023-09-03T16:00:00+00:00",
            },
            {
              start: "2023-09-04T04:00:00+00:00",
              end: "2023-09-04T15:00:00+00:00",
            },
            {
              start: "2023-09-05T08:00:00+00:00",
              end: "2023-09-05T18:00:00+00:00",
            },
          ],
          id: "pam-linville",
        },
        {
          availability: [
            {
              start: "2023-09-02T12:00:00+00:00",
              end: "2023-09-02T20:00:00+00:00",
            },
            {
              start: "2023-09-03T00:00:00+00:00",
              end: "2023-09-03T18:00:00+00:00",
            },
            {
              start: "2023-09-03T20:00:00+00:00",
              end: "2023-09-04T06:00:00+00:00",
            },
            {
              start: "2023-09-04T10:00:00+00:00",
              end: "2023-09-04T18:00:00+00:00",
            },
            {
              start: "2023-09-05T00:00:00+00:00",
              end: "2023-09-05T18:00:00+00:00",
            },
          ],
          id: "michael-adkins",
        },
        {
          availability: [
            {
              start: "2023-09-03T00:00:00+00:00",
              end: "2023-09-03T15:00:00+00:00",
            },
            {
              start: "2023-09-03T16:00:00+00:00",
              end: "2023-09-04T02:00:00+00:00",
            },
            {
              start: "2023-09-04T19:00:00+00:00",
              end: "2023-09-05T06:00:00+00:00",
            },
            {
              start: "2023-09-05T12:00:00+00:00",
              end: "2023-09-05T20:00:00+00:00",
            },
          ],
          id: "cherise-fulmer",
        },
        {
          availability: [
            {
              start: "2023-09-02T11:00:00+00:00",
              end: "2023-09-02T21:00:00+00:00",
            },
            {
              start: "2023-09-02T22:00:00+00:00",
              end: "2023-09-03T08:00:00+00:00",
            },
            {
              start: "2023-09-04T00:00:00+00:00",
              end: "2023-09-04T08:00:00+00:00",
            },
            {
              start: "2023-09-04T20:00:00+00:00",
              end: "2023-09-05T16:00:00+00:00",
            },
          ],
          id: "donald-elm",
        },
        {
          availability: [
            {
              start: "2023-09-02T14:00:00+00:00",
              end: "2023-09-03T06:00:00+00:00",
            },
            {
              start: "2023-09-03T08:00:00+00:00",
              end: "2023-09-03T16:00:00+00:00",
            },
            {
              start: "2023-09-03T20:00:00+00:00",
              end: "2023-09-04T04:00:00+00:00",
            },
            {
              start: "2023-09-04T12:00:00+00:00",
              end: "2023-09-04T23:00:00+00:00",
            },
            {
              start: "2023-09-05T09:00:00+00:00",
              end: "2023-09-05T19:00:00+00:00",
            },
          ],
          id: "courtney-orange",
        },
        {
          availability: [
            {
              start: "2023-09-02T18:00:00+00:00",
              end: "2023-09-03T04:00:00+00:00",
            },
            {
              start: "2023-09-03T12:00:00+00:00",
              end: "2023-09-03T20:00:00+00:00",
            },
            {
              start: "2023-09-03T20:00:00+00:00",
              end: "2023-09-04T21:00:00+00:00",
            },
            {
              start: "2023-09-04T22:00:00+00:00",
              end: "2023-09-05T08:00:00+00:00",
            },
          ],
          id: "barleycorn-wilson",
        },
        {
          availability: [
            {
              start: "2023-09-02T18:00:00+00:00",
              end: "2023-09-03T04:00:00+00:00",
            },
            {
              start: "2023-09-03T08:00:00+00:00",
              end: "2023-09-03T20:00:00+00:00",
            },
            {
              start: "2023-09-04T00:00:00+00:00",
              end: "2023-09-04T08:00:00+00:00",
            },
            {
              start: "2023-09-04T10:00:00+00:00",
              end: "2023-09-05T04:00:00+00:00",
            },
          ],
          id: "ida-bucket",
        },
      ],
      required_workers: [
        {
          start: "2023-09-02T10:00:00+00:00",
          end: "2023-09-02T14:00:00+00:00",
          count: 2,
        },
        {
          start: "2023-09-02T14:00:00+00:00",
          end: "2023-09-02T18:00:00+00:00",
          count: 2,
        },
        {
          start: "2023-09-02T18:00:00+00:00",
          end: "2023-09-02T22:00:00+00:00",
          count: 2,
        },
        {
          start: "2023-09-02T22:00:00+00:00",
          end: "2023-09-03T02:00:00+00:00",
          count: 2,
        },
        {
          start: "2023-09-03T02:00:00+00:00",
          end: "2023-09-03T06:00:00+00:00",
          count: 2,
        },
        {
          start: "2023-09-03T06:00:00+00:00",
          end: "2023-09-03T10:00:00+00:00",
          count: 2,
        },
        {
          start: "2023-09-03T10:00:00+00:00",
          end: "2023-09-03T14:00:00+00:00",
          count: 2,
        },
        {
          start: "2023-09-03T14:00:00+00:00",
          end: "2023-09-03T18:00:00+00:00",
          count: 2,
        },
        {
          start: "2023-09-03T18:00:00+00:00",
          end: "2023-09-03T22:00:00+00:00",
          count: 2,
        },
        {
          start: "2023-09-03T22:00:00+00:00",
          end: "2023-09-04T02:00:00+00:00",
          count: 2,
        },
        {
          start: "2023-09-04T02:00:00+00:00",
          end: "2023-09-04T06:00:00+00:00",
          count: 2,
        },
        {
          start: "2023-09-04T06:00:00+00:00",
          end: "2023-09-04T10:00:00+00:00",
          count: 2,
        },
        {
          start: "2023-09-04T10:00:00+00:00",
          end: "2023-09-04T14:00:00+00:00",
          count: 2,
        },
        {
          start: "2023-09-04T14:00:00+00:00",
          end: "2023-09-04T18:00:00+00:00",
          count: 2,
        },
        {
          start: "2023-09-04T18:00:00+00:00",
          end: "2023-09-04T22:00:00+00:00",
          count: 2,
        },
        {
          start: "2023-09-04T22:00:00+00:00",
          end: "2023-09-05T02:00:00+00:00",
          count: 2,
        },
        {
          start: "2023-09-05T02:00:00+00:00",
          end: "2023-09-05T06:00:00+00:00",
          count: 2,
        },
        {
          start: "2023-09-05T06:00:00+00:00",
          end: "2023-09-05T10:00:00+00:00",
          count: 2,
        },
        {
          start: "2023-09-05T10:00:00+00:00",
          end: "2023-09-05T14:00:00+00:00",
          count: 2,
        },
        {
          start: "2023-09-05T14:00:00+00:00",
          end: "2023-09-05T18:00:00+00:00",
          count: 2,
        },
      ],
    },
  };
