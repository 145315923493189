import { MarketplacePartnerApp } from "../../../api/core/controlPlane.types";

export const getSubscriptionId = (marketplaceApp: MarketplacePartnerApp) => {
  return `${marketplaceApp?.partner_id}-${marketplaceApp?.id}`;
};

export const getMarketplacePartnerId = (subscriptionId: string) => {
  return subscriptionId.split("-")[0];
};

export const getMarketplaceAppId = (subscriptionId: string) => {
  return subscriptionId.split("-")[1];
};
