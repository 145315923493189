import * as React from "react";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";

import { svgIconStyle } from "../lib/constants";

type StyledSvgProps = {
  activeColor?: string;
  focusColor?: string;
  hoverColor?: string;
};
const StyledSvg = styled.svg<StyledSvgProps>`
  ${(props) =>
    svgIconStyle({
      activeColor: props.activeColor,
      focusColor: props.focusColor,
      hoverColor: props.hoverColor,
    })}
`;

const IconValueFuncTotalTravelTime = ({
  activeColor,
  focusColor,
  hoverColor,
  iconColor,
  iconSize,
}: {
  activeColor?: string;
  focusColor?: string;
  hoverColor?: string;
  iconColor?: string;
  iconSize?: number;
}) => {
  const theme = useTheme();
  const thisIconSize = iconSize || 16;
  return (
    <StyledSvg
      {...{ activeColor, focusColor, hoverColor }}
      width={thisIconSize}
      height={thisIconSize}
      viewBox="0 0 16 16"
      fill={iconColor || theme.color.gray700}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.89999 0.533325C5.89999 0.257183 6.12385 0.0333252 6.39999 0.0333252H7.99999H9.59999C9.87614 0.0333252 10.1 0.257183 10.1 0.533325C10.1 0.809468 9.87614 1.03333 9.59999 1.03333H8.49999V2.29142C9.69194 2.37813 10.7986 2.77238 11.7409 3.39518C11.7646 3.35841 11.7925 3.32358 11.8247 3.29138L12.8914 2.22472C13.1355 1.98064 13.5312 1.98064 13.7753 2.22472C14.0193 2.46879 14.0193 2.86452 13.7753 3.1086L12.7114 4.1725C13.9948 5.40832 14.7933 7.14422 14.7933 9.06663C14.7933 12.8185 11.7519 15.86 8 15.86C4.24814 15.86 1.20667 12.8185 1.20667 9.06663C1.20667 5.48294 3.9816 2.54738 7.49999 2.29142V1.03333H6.39999C6.12385 1.03333 5.89999 0.809468 5.89999 0.533325ZM2.20667 9.06663C2.20667 5.86706 4.80043 3.2733 8 3.2733C11.1996 3.2733 13.7933 5.86706 13.7933 9.06663C13.7933 12.2662 11.1996 14.86 8 14.86C4.80043 14.86 2.20667 12.2662 2.20667 9.06663ZM7.99999 9.06663V4.3733C5.40794 4.3733 3.30666 6.47458 3.30666 9.06663C3.30666 11.6587 5.40794 13.76 8 13.76C9.29602 13.76 10.4694 13.2346 11.3187 12.3853L7.99999 9.06663Z"
      />
    </StyledSvg>
  );
};

export default IconValueFuncTotalTravelTime;
