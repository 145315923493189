export const planSummaryLayout = [
  { width: 100 }, // index
  { width: 200 }, // instance_id
  { width: 100 }, // duration_minutes
];

export const planSummaryLayoutWithStartTime = [
  { width: 100 }, // index
  { width: 200 }, // instance_id
  { width: 100 }, // duration_minutes
  { width: 180 }, // start
  { width: 180 }, // stop
];
