import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { accPrefix } from "../routes/utils";

export default function useGetOrgId() {
  const { pathname } = useLocation();
  const [orgId, setOrgId] = useState("");

  useEffect(() => {
    const paths = pathname.split("/");
    if (paths[1] === accPrefix) {
      setOrgId(paths[2]);
      return;
    }
    return setOrgId("");
  }, [pathname]);

  return orgId;
}
