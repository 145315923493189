import { rem } from "../../../lib/tools";
import {
  GetPointerStylesParams,
  GetTourPanelStylesParams,
} from "../Tour.types";

const getTourPanelOuterMarginValue = ({
  position,
  theme,
}: GetTourPanelStylesParams) => {
  switch (position) {
    case "top":
      return `
        margin-top: ${theme.spacing.s2};
      `;
    case "right":
      return `
        margin-right: ${theme.spacing.s2};
      `;
    case "left":
      return `
        margin-left: ${theme.spacing.s2};
      `;
    default:
      return ``;
  }
};

export const getTourPanelStyles = ({
  position,
  theme,
}: GetTourPanelStylesParams) => {
  if (position && Array.isArray(position) && position.length === 2) {
    return `
      ${getTourPanelOuterMarginValue({ position: position[0], theme })};
    
      @media (min-width: ${theme.breakpoint.sm}) {
        ${getTourPanelOuterMarginValue({ position: position[1], theme })};
      }
    `;
  }
  return getTourPanelOuterMarginValue({ position, theme });
};

const getPointerArrowAlignStyles = ({
  align,
  position,
  theme,
}: GetPointerStylesParams) => {
  switch (align) {
    case "top":
      return `
        align-items: flex-start;
        justify-content: flex-end;
        padding-top: ${theme.spacing.s3};
      `;
    case "bottom":
      return `
        align-items: flex-end;
        justify-content: flex-end;
        padding-bottom: ${theme.spacing.s4};
      `;
    case "right":
      return `
        align-items: flex-end;
        justify-content: flex-end;
        padding-right: ${theme.spacing.s3};
      `;
    case "left":
      return `
        align-items: flex-end;
        justify-content: flex-start;
        padding-left: ${theme.spacing.s3};
      `;
    case "center":
      return `
        align-items: center;
        justify-content: center;
        padding-bottom: ${position !== "top" ? theme.spacing.s3 : 0};
      `;
    default:
      return ``;
  }
};

const getPointerContainerStyles = ({
  align,
  position,
  theme,
}: GetPointerStylesParams) => {
  switch (position) {
    case "top":
      return `
        ${getPointerArrowAlignStyles({ align, position, theme })}
        width: 100%;
        height: ${theme.spacing.s2};
        top: ${theme.spacing["-s2"]};
      `;
    case "right":
      return `
        ${getPointerArrowAlignStyles({ align, position, theme })}
        width: ${theme.spacing.s2};
        height: 100%;
        right: ${theme.spacing["-s2"]};
      `;
    case "left":
      return `
        ${getPointerArrowAlignStyles({ align, position, theme })}
        width: ${theme.spacing.s2};
        height: 100%;
        left: ${theme.spacing["-s2"]};
      `;
    default:
      return `
        display: none;
      `;
  }
};

export const getPointerContainer = ({
  align,
  position,
  theme,
}: GetPointerStylesParams) => {
  if (
    align &&
    Array.isArray(align) &&
    align.length === 2 &&
    position &&
    Array.isArray(position) &&
    position.length === 2
  ) {
    return `
      @media (max-width: calc(${theme.breakpoint.sm} - 1px)) {
        ${getPointerContainerStyles({
          align: align[0],
          position: position[0],
          theme,
        })};
      }
      @media (min-width: ${theme.breakpoint.sm}) {
        ${getPointerContainerStyles({
          align: align[1],
          position: position[1],
          theme,
        })};
      }
    `;
  }
  return getPointerContainerStyles({
    align,
    position,
    theme,
  });
};

const getPointerArrowStyles = ({
  align,
  position,
  theme,
}: GetPointerStylesParams) => {
  switch (position) {
    case "top":
      return `
        bottom: ${rem(-2)};
        border-left-width: ${theme.spacing.s2};
        border-left-style: ${theme.border.style};
        border-left-color: transparent;
        border-right-width: ${theme.spacing.s2};
        border-right-style: ${theme.border.style};
        border-right-color: transparent;
        border-bottom-width: ${theme.spacing.s2};
        border-bottom-style: ${theme.border.style};
        border-bottom-color: ${theme.color.white};
      `;
    case "right":
      return `
        left: ${rem(-2)};
        border-top-width: ${theme.spacing.s2};
        border-top-style: ${theme.border.style};
        border-top-color: transparent;
        border-bottom-width: ${theme.spacing.s2};
        border-bottom-style: ${theme.border.style};
        border-bottom-color: transparent;
        border-left-width: ${theme.spacing.s2};
        border-left-style: ${theme.border.style};
        border-left-color: ${
          align === "bottom" ? theme.color.gray100 : theme.color.white
        };
      `;
    case "left":
      return `
        right: ${rem(-2)};
        border-top-width: ${theme.spacing.s2};
        border-top-style: ${theme.border.style};
        border-top-color: transparent;
        border-bottom-width: ${theme.spacing.s2};
        border-bottom-style: ${theme.border.style};
        border-bottom-color: transparent;
        border-right-width: ${theme.spacing.s2};
        border-right-style: ${theme.border.style};
        border-right-color: ${
          align === "bottom" ? theme.color.gray100 : theme.color.white
        };
      `;
    default:
      return ``;
  }
};

export const getPointerArrow = ({
  align,
  position,
  theme,
}: GetPointerStylesParams) => {
  if (
    align &&
    Array.isArray(align) &&
    align.length === 2 &&
    position &&
    Array.isArray(position) &&
    position.length === 2
  ) {
    return `
      @media (max-width: calc(${theme.breakpoint.sm} - 1px)) {
        ${getPointerArrowStyles({
          align: align[0],
          position: position[0],
          theme,
        })};
      }
      @media (min-width: ${theme.breakpoint.sm}) {
        ${getPointerArrowStyles({
          align: align[1],
          position: position[1],
          theme,
        })};
      }
    `;
  }
  return getPointerArrowStyles({
    align,
    position,
    theme,
  });
};

export const getPositionValue = (
  position: "left" | "right" | "top" | "bottom",
  value: string | [string, string] | undefined,
  theme: any
) => {
  if (!value) return `${position}: auto;`;
  if (Array.isArray(value) && value.length === 2) {
    return `
      ${position}: ${value[0]};
    
      @media (min-width: ${theme.breakpoint.sm}) {
        ${position}: ${value[1]};
      }
    `;
  }
  return `${position}: ${value};`;
};
