import {
  MarketplaceAppVersion,
  MarketplacePartnerApp,
} from "../../../api/core/controlPlane.types";

export const getMarketplacePartnerAppLatestVersions = (
  partnerApp: MarketplacePartnerApp
): MarketplaceAppVersion[] => {
  if (
    !partnerApp ||
    !partnerApp?.latest_versions ||
    partnerApp?.latest_versions.length < 1
  ) {
    return [];
  }

  const regexGetMajorVersionNumber = /^v([0-9]+)./m;
  const latestVersion = partnerApp.latest_versions[0];
  const latestVersionRegexMatch = latestVersion.match(
    regexGetMajorVersionNumber
  );
  const latestVersionMajorSeriesNumber =
    latestVersionRegexMatch &&
    latestVersionRegexMatch.length > 1 &&
    parseInt(latestVersionRegexMatch[1], 0);

  if (
    latestVersionMajorSeriesNumber === null ||
    latestVersionMajorSeriesNumber === false ||
    isNaN(latestVersionMajorSeriesNumber)
  ) {
    return [];
  }

  const marketplacePartnerAppLatestVersions = [];
  for (let i = 0; i <= latestVersionMajorSeriesNumber; i++) {
    marketplacePartnerAppLatestVersions.unshift({
      id: `v${i}`,
      name: `Latest v${i}`,
      change_log: [],
      partner_id: partnerApp.partner_id,
      app_id: partnerApp.id,
      created_at: "",
      updated_at: "",
    });
  }
  return marketplacePartnerAppLatestVersions;
};

export const isLatestMarketplaceVersion = (versionId: string) => {
  const regexMajorVersionMatch = /^v([0-9]+)$/m;
  const majorVersionMatch = versionId.match(regexMajorVersionMatch);
  return !!majorVersionMatch;
};
