import * as React from "react";

import BaseIcon, { IconProps } from "./BaseIcon";

const IconChevronLeft = ({
  iconSize,
  iconColor,
  iconColorActive,
  iconColorFocus,
  iconColorHover,
}: IconProps) => {
  return (
    <BaseIcon
      {...{
        iconSize,
        iconColor,
        iconColorActive,
        iconColorFocus,
        iconColorHover,
      }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.75 7.99996L9.17602 13.0583L10.3049 12.0705L6.74315 7.99996L10.3049 3.92941L9.17602 2.94165L4.75 7.99996Z"
      />
    </BaseIcon>
  );
};

export default IconChevronLeft;
