import * as React from "react";
import { components } from "react-select";
import { useTheme } from "@emotion/react";

import { DesignSystemTheme } from "../../../style/theme.types";
import Flex from "../../Flex";
import Text from "../../Text";

// get Option base component from library
const { Option } = components;

type RenderSolutionOptionParams = {
  theme: DesignSystemTheme;
  data: {
    label: string;
    value: number;
    time_elapsed: string;
    is_best: boolean;
    unassigned_stops: number;
  };
};

export const renderSolutionOption = ({
  theme,
  data,
}: RenderSolutionOptionParams) => {
  const isBest = !!data.hasOwnProperty("is_best") && !!data.is_best;

  return (
    <Flex alignItems="baseline" width="100%">
      {!!data.hasOwnProperty("label") && (
        <Text styleName="body-2-bold" styles={{ color: theme.color.gray800 }}>
          {data.label}
        </Text>
      )}
      {!!isBest && (
        <Text ml={2} styleName="label">
          Best&nbsp;&ndash;&nbsp;
        </Text>
      )}
      {!!data.hasOwnProperty("time_elapsed") && (
        <Text ml={isBest ? 0 : 2} styleName="meta-1">
          {data.time_elapsed}&nbsp;&middot;&nbsp;
        </Text>
      )}
      {!!data.hasOwnProperty("unassigned_stops") && (
        <Text styleName="meta-1">{data.unassigned_stops} unassigned</Text>
      )}
    </Flex>
  );
};

const CustomOptionSolution = (props: any) => {
  const theme = useTheme();

  // get data to build option, this is the object from
  // the data array for this particular option
  const { data } = props;

  return <Option {...props}>{renderSolutionOption({ theme, data })}</Option>;
};

export default CustomOptionSolution;
