import React, { useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

import LogIn from "../pages/LogIn";
import SignUp from "../pages/SignUp";
import { useStoredPromoCode } from "../utils";

import { usePageTracking } from "./hooks";

function PublicRoutes() {
  const location = useLocation();
  const [, setStoredPromoCode] = useStoredPromoCode();

  const params = new URLSearchParams(location.search);
  const username = params.get("username");

  useEffect(() => {
    const promoParams = new URLSearchParams(location.search);
    if (location.pathname === "/") {
      const promoCode = promoParams.get("promo");
      promoCode && setStoredPromoCode(promoCode);
    }
  }, [location.pathname, location.search, setStoredPromoCode]);

  usePageTracking();

  return (
    <Switch>
      <Route path="/login">
        <LogIn />
      </Route>
      <Route exact path="/forgot-password">
        <Redirect to="/login/password/forgot" />
      </Route>
      <Route
        exact
        path="/reset-password"
        render={(props) => (
          <Redirect
            to={{
              pathname: "/login/password/reset",
              search: props.location.search,
            }}
          />
        )}
      />

      <Route path="/signup">
        <SignUp {...{ username }} />
      </Route>
      <Route path="/">
        <Redirect
          to={{ pathname: "/login/identify", state: { from: location } }}
        />
      </Route>
    </Switch>
  );
}

export default PublicRoutes;
