import { MarketplaceAppConstraint } from "../../../api/core/controlPlane.types";

export const ROUTING_PYVROOM_PARTNER_ID = "nextmv";
export const ROUTING_PYVROOM_APP_ID = "routing.pyvroom";
export const ROUTING_PYVROOM_SUBSCRIPTION_ID = `${ROUTING_PYVROOM_PARTNER_ID}-${ROUTING_PYVROOM_APP_ID}`;
export const ROUTING_PYVROOM_ICON_URL = "/images/logos/vroom-icon.svg";
export const ROUTING_PYVROOM_NAME = "Pyvroom Routing";
export const ROUTING_PYVROOM_TEMPLATE_NAME = "routing-pyvroom";
export const ROUTING_PYVROOM_SHORT_DESC =
  "Solve an unconstrained vehicle routing problem.";
export const ROUTING_PYVROOM_SUMMARY =
  "Solve an unconstrained vehicle routing problem with the low-code Pyvroom routing app. Define a distance matrix, number of vehicles, and the depot index and then run the app to generate vehicle routes.";
export const ROUTING_PYVROOM_DOCS_LINK =
  "https://www.nextmv.io/docs/community-apps/routing-without-constraints";
export const ROUTING_PYVROOM_CODE_REPO_LINK =
  "https://github.com/nextmv-io/community-apps/tree/develop/routing-pyvroom";
export const ROUTING_PYVROOM_MODEL_TYPE = ["Routing"];
export const ROUTING_PYVROOM_LANGUAGE = ["Python"];
export const ROUTING_PYVROOM_PACKAGES = ["pyvroom"];
export const ROUTING_PYVROOM_SOLVER = ["VROOM"];

export const ROUTING_PYVROOM_CONSTRAINTS: MarketplaceAppConstraint[] = [
  {
    id: "duration_matrix",
    name: "duration_matrix",
    property: "duration_matrix",
    docsUrl:
      "https://www.nextmv.io/docs/community-apps/routing-without-constraints#input",
    summary:
      "Full matrix of durations from each stop to each stop (typically seconds).",
    type: "array of array of integer",
  },
];
