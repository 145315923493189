import React, { useEffect, useRef } from "react";

import CustomAceEditor from "../CustomAceEditor";

type OutputEditorProps = {
  value: any;
  controlEditorModePositionTop?: number;
};

export default function OutputEditor({
  controlEditorModePositionTop,
  value,
}: OutputEditorProps) {
  // https://github.com/vankop/jsoneditor-react/issues/3#issuecomment-726427695
  const jsonEditorRef = useRef<any>(null);

  const setRef = (instance: any) => {
    if (instance) {
      jsonEditorRef.current = instance.jsonEditor;
    } else {
      jsonEditorRef.current = null;
    }
  };

  useEffect(() => {
    if (jsonEditorRef.current) {
      jsonEditorRef.current.set(value);
    }
  }, [value]);

  const mode = Object.keys(value).length ? "code" : "tree";

  return (
    <CustomAceEditor
      {...{ controlEditorModePositionTop }}
      // key forces remount when changed so that mode can change
      // from tree/code based on existence of output
      key={mode}
      ref={setRef}
      value={value}
      // make read-only
      onEditable={(_node: { field: string; value: string; path: string[] }) => {
        return false;
      }}
      mode={mode}
    />
  );
}
