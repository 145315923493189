import * as React from "react";
import { useTheme } from "@emotion/react";

/*
  This custom SVG graphic is used for a custom point (or dot)
  on our line charts. (It's a green dot with a white border
  and drop shadow). When you pass it to its respective cartesian
  component a props object is passed along with it. The available
  properties within this props object are many, but here are a
  few relevant ones:

- cx: its x-coordinate on the chart, required to place it in the
    proper spot
- cy: its x-coordinate on the chart, required to place it in the
    proper spot
- node: the x, y, z value in object form
- payload: the values associated with the point
- <data_key>: each key from the data object is passed as a prop
    with its value as the value

  The custom marker is not automatically assigned the same location
  as the standard markers, it must be assigned to the svg's top-level
  x and y props.
*/

type CustomPointProps = {
  cx: number;
  cy: number;
  fillColor?: string;
};
const CustomPoint = ({ cx, cy, fillColor }: CustomPointProps) => {
  const theme = useTheme();
  const thisFillColor = fillColor || theme.color.green;

  return (
    <svg
      x={cx - 6}
      y={cy - 6}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d)">
        <circle cx="8" cy="6" r="4" fill={thisFillColor} />
        <circle cx="8" cy="6" r="5" stroke="white" strokeWidth="2" />
      </g>

      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="16"
          height="16"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feMorphology
            radius="1"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0614706 0 0 0 0 0.0671863 0 0 0 0 0.0679412 0 0 0 0.34 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default CustomPoint;
