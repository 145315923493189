export const getRandomIdentifier = () => {
  return Math.random().toString(36).substring(2, 12);
};

type HandleClickOutsideParams = {
  closeAction: (e: any) => void;
  e: any;
  isActive: boolean;
  targetId: string;
};

export const handleClickOutside = ({
  closeAction,
  e,
  isActive,
  targetId,
}: HandleClickOutsideParams) => {
  const target = e.target as HTMLDivElement;
  if (isActive && target.id === targetId) {
    closeAction(e);
  }
};
