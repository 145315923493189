import * as React from "react";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";

import { svgIconStyle } from "../lib/constants";

type StyledSvgProps = {
  activeColor?: string;
  focusColor?: string;
  hoverColor?: string;
};
const StyledSvg = styled.svg<StyledSvgProps>`
  ${(props) =>
    svgIconStyle({
      activeColor: props.activeColor,
      focusColor: props.focusColor,
      hoverColor: props.hoverColor,
    })}
`;

const IconVehicle = ({
  activeColor,
  focusColor,
  hoverColor,
  iconColor,
  iconSize,
}: {
  activeColor?: string;
  focusColor?: string;
  hoverColor?: string;
  iconColor?: string;
  iconSize?: number;
}) => {
  const theme = useTheme();
  const thisIconSize = iconSize || 16;
  return (
    <StyledSvg
      {...{ activeColor, focusColor, hoverColor }}
      width={thisIconSize}
      height={thisIconSize}
      viewBox="0 0 16 16"
      fill={iconColor || theme.color.gray700}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.5139 6.48333L13.6389 2.71111C13.425 1.75833 12.4722 1 11.5 1H4.5C3.52778 1 2.575 1.75833 2.36111 2.71111L1.48611 6.48333C1.19444 6.67778 1 7.02778 1 7.41667V13.8333C1 14.1639 1.25278 14.4167 1.58333 14.4167H2.75C3.08056 14.4167 3.33333 14.1639 3.33333 13.8333V12.0833H12.6667V13.8333C12.6667 14.1639 12.9194 14.4167 13.25 14.4167H14.4167C14.7472 14.4167 15 14.1639 15 13.8333V7.41667C15 7.02778 14.8056 6.67778 14.5139 6.48333ZM3.48889 2.96389C3.58611 2.55556 4.07222 2.16667 4.5 2.16667H11.5C11.9278 2.16667 12.4139 2.55556 12.5111 2.96389L13.1333 5.66667H2.86667L3.48889 2.96389ZM4.5 8.29167C4.5 8.44722 4.36389 8.58333 4.20833 8.58333H2.45833C2.30278 8.58333 2.16667 8.44722 2.16667 8.29167V7.70833C2.16667 7.55278 2.30278 7.41667 2.45833 7.41667H4.20833C4.36389 7.41667 4.5 7.55278 4.5 7.70833V8.29167ZM13.8333 8.29167C13.8333 8.44722 13.6972 8.58333 13.5417 8.58333H11.7917C11.6361 8.58333 11.5 8.44722 11.5 8.29167V7.70833C11.5 7.55278 11.6361 7.41667 11.7917 7.41667H13.5417C13.6972 7.41667 13.8333 7.55278 13.8333 7.70833V8.29167Z" />
    </StyledSvg>
  );
};

export default IconVehicle;
