import React from "react";

import {
  createIntegration,
  deleteIntegration,
  getIntegration,
  getIntegrations,
  updateIntegration,
} from "../../api/core/controlPlane";
import {
  CreateIntegrationPayload,
  Integration,
  IntegrationKind,
  IntegrationRunProfile,
  Integrations,
  IntegrationsRunProfiles,
  PutIntegrationPayload,
} from "../../api/core/controlPlane.types";
import { useUser } from "../../AuthProvider";
import { availableIntegrationConfig } from "../../pages/Integrations/Integrations.config";
import { removeById, replaceById, sortByName } from "../utils";

import {
  AnyFunction,
  IntegrationActionKind,
  IntegrationsAction,
  IntegrationsContextProps,
  IntegrationsState,
} from "./Integrations.context.types";

export const IntegrationsContext =
  React.createContext<IntegrationsContextProps>({
    filterIntegrationsByName: (s: string) => [],
    getFullIntegrationInfo: (
      partialIntegrationInfo: IntegrationsRunProfiles
    ) => [],
    getAllIntegrations: () => [],
    filterOutIntegrationsByType: (t: IntegrationKind[]) => [],
    getIntegrationById: (id: string): Integration => ({
      name: "",
      id: "",
      version: "",
      type: "onfleet",
      category: "system",
      class: "system",
    }),
    addIntegration: <T extends IntegrationKind>(
      integrationType: T,
      ip: CreateIntegrationPayload<T>
    ) => {},
    editIntegration: <T extends IntegrationKind>(
      integrationType: T,
      id: string,
      payload: PutIntegrationPayload<T>
    ) => {},
    removeIntegration: (id: string, integrationType: IntegrationKind) => {},
    fetchIntegrations: () => {},
    updateIntegrationConfiguration: (integration?: Integration) => {},
    clearIntegrationsError: () => {},
    integrationsError: "",
    integrationsLoading: true,
  });

const integrationsReducer = <T extends IntegrationKind>(
  state: IntegrationsState,
  action: IntegrationsAction<T>
): IntegrationsState => {
  switch (action.type) {
    case IntegrationActionKind.UPDATE_ALL:
      return {
        error: "",
        loading: false,
        integrations: sortByName(action.payload),
      };
    case IntegrationActionKind.UPDATE_ONE: {
      return {
        loading: false,
        error: "",
        integrations: sortByName(
          replaceById(action.payload, state.integrations)
        ),
      };
    }
    case IntegrationActionKind.ADD: {
      return {
        loading: false,
        error: "",
        integrations: sortByName(state.integrations.concat(action.payload)),
      };
    }
    case IntegrationActionKind.SET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case IntegrationActionKind.LOADING:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case IntegrationActionKind.DELETE_ONE:
      const { id } = action.payload;
      return {
        error: "",
        loading: false,
        integrations: removeById(id, state.integrations),
      };
    default:
      throw new Error("integration action not supported");
  }
};

const IntegrationsProvider = ({ children }: { children: React.ReactNode }) => {
  const [{ id: accountId }] = useUser();
  const [{ error, loading, integrations }, integrationsDispatch] =
    React.useReducer(integrationsReducer, {
      integrations: [],
      error: "",
      loading: true,
    });

  const filterIntegrationsByName = React.useCallback(
    (searchString: string): Integrations => {
      return integrations.filter((ip: Integration) =>
        ip.name.toLocaleLowerCase().includes(searchString.toLocaleLowerCase())
      );
    },
    [integrations]
  );

  const getAllIntegrations = React.useCallback(
    (): Integrations => integrations,
    [integrations]
  );

  const filterOutIntegrationsByType = React.useCallback(
    (types: IntegrationKind[]): Integrations =>
      integrations.filter((integration) => !types.includes(integration.type)),
    [integrations]
  );

  const wrapFetch = <Func extends AnyFunction>(
    fn: Func,
    action: IntegrationActionKind
  ): ((...args: Parameters<Func>) => void) => {
    const wrappedFn = async (...args: Parameters<Func>) => {
      integrationsDispatch({ type: IntegrationActionKind.LOADING });
      try {
        const resJson = await fn(...args);
        integrationsDispatch({
          type: action,
          payload: resJson,
        });
      } catch (e: any) {
        integrationsDispatch({
          type: IntegrationActionKind.SET_ERROR,
          payload: e.message,
        });
      }
    };
    return wrappedFn;
  };

  const fetchIntegrations = React.useMemo(
    () =>
      wrapFetch(
        getIntegrations(accountId || ""),
        IntegrationActionKind.UPDATE_ALL
      ),
    [accountId]
  );

  const fetchIntegration = React.useMemo(
    () =>
      wrapFetch(
        getIntegration(accountId || ""),
        IntegrationActionKind.UPDATE_ONE
      ),
    [accountId]
  );

  const addIntegration = React.useMemo(
    () =>
      wrapFetch(createIntegration(accountId || ""), IntegrationActionKind.ADD),
    [accountId]
  );

  const editIntegration = React.useMemo(
    () =>
      wrapFetch(
        updateIntegration(accountId || ""),
        IntegrationActionKind.UPDATE_ONE
      ),
    [accountId]
  );

  const removeIntegration = React.useMemo(
    () =>
      wrapFetch(
        deleteIntegration(accountId || ""),
        IntegrationActionKind.DELETE_ONE
      ),
    [accountId]
  );

  const updateIntegrationConfiguration = React.useCallback(
    (integration?: Integration) => {
      // configuration already loaded onto the integration
      if (!integration || integration.configuration) return;
      if (
        availableIntegrationConfig[integration.type].shouldLoadAdditionalConfig
      ) {
        fetchIntegration(integration.type, integration.id);
      }
    },
    [fetchIntegration]
  );

  const getIntegrationById = React.useCallback(
    (id: string) => integrations.find((ip) => ip.id === id),
    [integrations]
  );

  const getFullIntegrationInfo = React.useCallback(
    (
      partialIntegrationInfo: IntegrationsRunProfiles
    ): (Integration | IntegrationRunProfile)[] =>
      partialIntegrationInfo.map((partialInfo: IntegrationRunProfile) => {
        return (
          integrations.find(
            (integration) => integration.id === partialInfo.id
          ) || partialInfo
        );
      }),
    [integrations]
  );

  React.useEffect(() => {
    if (accountId) {
      fetchIntegrations();
    }
  }, [fetchIntegrations, accountId]);

  const clearIntegrationsError = React.useCallback(() => {
    integrationsDispatch({
      type: IntegrationActionKind.SET_ERROR,
      payload: "",
    });
  }, []);

  const value = React.useMemo(
    () => ({
      filterIntegrationsByName,
      getFullIntegrationInfo,
      getAllIntegrations,
      filterOutIntegrationsByType,
      getIntegrationById,
      editIntegration,
      fetchIntegrations,
      updateIntegrationConfiguration,
      addIntegration,
      removeIntegration,
      clearIntegrationsError,
      integrationsError: error,
      integrationsLoading: loading,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [error, loading, integrations]
  );

  return (
    <IntegrationsContext.Provider value={value}>
      {children}
    </IntegrationsContext.Provider>
  );
};

export const useIntegrations = () => React.useContext(IntegrationsContext);

export default IntegrationsProvider;
