import React, { useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { Box, Button2, Flex, Input, Loading, RowDetail } from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../../../analytics";
import { updateOrgInfo } from "../../../../api/core/controlPlane";
import {
  UserOrganization,
  UserOrganizations,
} from "../../../../api/core/dataPlane.types";
import { UserActionKind, useUser } from "../../../../AuthProvider";
import Header from "../../../../components/Header";
import { useMetaTitle } from "../../../../components/Layout";
import StandardError from "../../../../components/StandardError";
import { standardInputValueByKey } from "../../../../hooks/useStandardInputs";
import { teamTooltipCopy } from "../../data/microcopy";
import { TeamDetails } from "../../Team.types";

import { emptyTeamDetails } from "./utils/emptyTeamDetails";

const pageTitle = "Edit Details";

const updateSingleOrg = (
  id: string,
  organizations: UserOrganizations,
  newInfo: any
) => {
  return organizations.map((org) => {
    if (id === org.id) {
      return {
        ...org,
        ...newInfo,
      };
    }
    return org;
  });
};

const EditDetails = ({ team }: { team: UserOrganization | undefined }) => {
  const [{ id: accountId, organizations }, userDispatch] = useUser();
  const [, setMetaTitle] = useMetaTitle();
  const theme = useTheme();
  const { pathname } = useLocation();
  const returnPath = pathname.split("/").slice(0, -1).join("/");

  const [inputError, setInputErrors] = useState({ name: false });
  const [submitted, setSubmitted] = useState(false);
  const [pendingTeam, setPendingTeam] = useState<TeamDetails>(emptyTeamDetails);
  const [teamError, setTeamError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setMetaTitle(pageTitle);
    return team && setPendingTeam(team);
  }, [setMetaTitle, setPendingTeam, team]);

  const handleDetailsSave = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    setTeamError("");
    setSubmitted(false);

    if (!pendingTeam || !pendingTeam?.name) {
      return setInputErrors({ name: true });
    }

    setLoading(true);
    try {
      const resp = await updateOrgInfo(accountId || "", pendingTeam);
      userDispatch({
        type: UserActionKind.UPDATE_ORGS,
        payload: updateSingleOrg(accountId || "", organizations || [], resp),
      });
    } catch (e) {
      console.error(e);
      setTeamError((e as Error).message);
    }
    setSubmitted(true);
    setLoading(false);

    // on success
    trackEvent("Team", {
      view: "Edit Details",
      action: "Edit Details Saved",
    });

    return;
  };

  const handleInputChange = <T extends "name">({
    target: { name, value },
  }: {
    target: { name: T; value: TeamDetails[T] };
  }) => {
    setPendingTeam({
      ...pendingTeam,
      [name]: standardInputValueByKey[name](value || ""),
    });
  };

  if (loading)
    return <Loading type="single-page" dotColor={theme.color.orange500} />;
  if (!submitted && teamError)
    return <StandardError errorMessage={teamError} />;
  if (submitted && !teamError) return <Redirect to={returnPath} />;
  if (!team) return <Redirect to="/team" />;

  return (
    <>
      <Header
        configPageTitle={{
          label: pageTitle,
        }}
      />

      <Box mt={rem(-1)}>
        <form onSubmit={(e) => handleDetailsSave(e)}>
          <RowDetail
            property="Name"
            secondaryLabel="Your team name"
            render={
              <Box width="100%" maxWidth={rem(408)}>
                <Input
                  name="name"
                  htmlType="text"
                  data-testid="name-input"
                  isError={inputError.name}
                  errorMessage="Please enter a name"
                  placeholder="Team name"
                  value={pendingTeam?.name}
                  onChange={handleInputChange}
                />
              </Box>
            }
          />

          <RowDetail
            property="ID"
            secondaryLabel="Read-only"
            tooltipCopy={teamTooltipCopy.idEdit}
            render={
              <Box flexGrow={1} maxWidth={rem(408)}>
                <Input
                  htmlType="text"
                  isDisabled
                  placeholder="Team ID"
                  value={team.id}
                  readOnly
                />
              </Box>
            }
          />

          <Flex hasBorderTop hasBorderWidthThick pt={4} alignItems="center">
            <Button2
              size="large"
              htmlType="submit"
              label="Save"
              onClick={handleDetailsSave}
            />
            <Button2
              ml={2}
              type="text"
              size="large"
              label="Cancel"
              htmlType="button"
              to={returnPath}
              onClick={() =>
                trackEvent("Team", {
                  view: "Edit Details",
                  action: "Edit Details Canceled",
                })
              }
            />
          </Flex>
        </form>

        {teamError && <StandardError errorMessage={teamError} />}
      </Box>
    </>
  );
};

export default EditDetails;
