import { isEmpty } from "lodash";

import { getToken } from "../utils/cognito";
import { handleResponse, handleSSOResponse } from "../utils/fetch";

import { API_BASE, apiStage, NEXTMV_ACCOUNT_HEADER_KEY } from "./core.config";
import {
  ParsedDecisionCount,
  ParsedFeatureFlags,
  SSODomainInfo,
  User,
  UserDecisionCountResponse,
  UserFeatureFlag,
  UserFeatureFlagsResponse,
  UserOrganizations,
  UserOrgKey,
} from "./dataPlane.types";

export const getUser = async (
  token: string,
  account: string = ""
): Promise<User> => {
  const res = await fetch(`${API_BASE}/${apiStage}/internal/me`, {
    headers: {
      Authorization: `Bearer ${token}`,
      [NEXTMV_ACCOUNT_HEADER_KEY]: account,
    },
  });
  if (!res.ok) {
    throw new Error("Error retreiving user");
  }
  const user = await res.json();
  if (isEmpty(user)) {
    throw new Error("Invalid User");
  }

  const {
    decisionCount: returnedDecisionCount,
    featureFlags,
    ...returnedUser
  } = user;
  const decisionCount = parseDecisionCount(returnedDecisionCount);
  const features = parseUserFlags(featureFlags);

  return { ...returnedUser, decisionCount, features };
};

export const parseUserFlags = (
  flags: UserFeatureFlagsResponse
): ParsedFeatureFlags => {
  if (!flags) {
    return {};
  }
  const parsedFlags: ParsedFeatureFlags = {};
  flags.forEach(
    ({ name, value, hasValue }: UserFeatureFlag) =>
      (parsedFlags[name] = hasValue ? value : true)
  );

  return parsedFlags;
};

export const parseDecisionCount = (
  decisionData: UserDecisionCountResponse
): ParsedDecisionCount => {
  if (!decisionData) {
    return {
      current: 0,
    };
  }
  const now = new Date();
  // get month in leading zero format
  const currentMonth = ("0" + (now.getMonth() + 1)).slice(-2);
  const currentYear = now.getFullYear();

  const currentCount =
    decisionData?.metrics[`${currentYear}-${currentMonth}`]?.count;
  return {
    current: currentCount || 0,
  };
};

export const getUserWithToken = async (): Promise<User> => {
  const token = await getToken();
  if (!token) {
    return {} as User;
  }
  return getUser(token);
};

export const getUserWithAccount = async (account: string): Promise<User> => {
  const token = await getToken();
  if (!token) {
    return {} as User;
  }
  return getUser(token, account);
};

export const getUserOrgs = async (
  token: string
): Promise<UserOrganizations> => {
  const resp = await fetch(`${API_BASE}/${apiStage}/internal/me/organization`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return (await (
    await handleResponse(resp)
  ).json()) as unknown as UserOrganizations;
};

export const getOrgKeys = async (orgId: string): Promise<UserOrgKey[]> => {
  const token = await getToken();
  if (!token) {
    return [] as UserOrgKey[];
  }
  const resp = await fetch(
    `${API_BASE}/${apiStage}/internal/me/organization/${orgId}/keys`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        [NEXTMV_ACCOUNT_HEADER_KEY]: orgId,
      },
    }
  );
  return (await handleResponse(resp)).json() as unknown as UserOrgKey[];
};

export const getSSODomain = async (domain: string): Promise<SSODomainInfo> => {
  const resp = await fetch(
    `${API_BASE}/v1/enterprise/sso/domain?domain=${domain}`
  );

  return (await handleSSOResponse(resp)).json() as unknown as SSODomainInfo;
};
