import L, { PointExpression } from "leaflet";
import tinycolor from "tinycolor2";

import markerUnassignedSVG from "../../../../../assets/map-marker-unassigned.svg";
import { MarkerPointKind } from "../../../Map.types";
import {
  CreateMarkerIconProps,
  GetMarkerClassnameParams,
  GetMarkerCustomHtmlParams,
  GetPointColorProps,
} from "../types";

export const getMarkerCustomHtml = ({
  color,
  kind = "stop",
}: GetMarkerCustomHtmlParams) => {
  switch (kind) {
    case "stop":
      return `<span class="map-marker-stop" style="
          background-color: ${color};
        "></span>`;
    case "depot":
      return `<span class="map-marker-depot"></span>`;
    case "start":
      return `<div class="map-marker-vehicle-start-outer">
          <span class="map-marker-vehicle-start" style="
            background-color: ${color};
          "></span>
        </div>`;
    case "end":
      return `<span class="map-marker-vehicle-end" style="
          background-color: ${color};
        "></span>`;
    default:
      return `<span class="map-marker-stop" style="
          background-color: black;
        "></span>`;
  }
};

export const getMarkerAnchor = (kind: MarkerPointKind): PointExpression => {
  switch (kind) {
    case "stop":
      return [7, 7];
    case "depot":
      return [11, 7];
    case "start":
      return [10, 9];
    case "end":
      return [6, 7];
    // returned for intial input points, etc.
    default:
      return [7, 7];
  }
};

export const getMarkerClassname = ({
  isFocused,
  kind,
}: GetMarkerClassnameParams): string => {
  if (kind === "depot") return "map-marker-z-depot";
  if (isFocused) return "map-marker-z-focused";
  return "";
};

export const getPointColor = ({
  isFocusActive,
  isFocusedRoute,
  point,
  theme,
}: GetPointColorProps) => {
  if (isFocusActive) {
    if (isFocusedRoute) {
      return point.color ? getStopColor(point.color) : theme.color.gray700;
    } else {
      return theme.color.gray500;
    }
  } else {
    return point.color ? getStopColor(point.color) : theme.color.gray600;
  }
};

export const createMarkerIcon = ({
  isFocusActive,
  isFocusedRoute,
  point,
  theme,
}: CreateMarkerIconProps) => {
  const { isFocused, kind } = point;
  const color = getPointColor({ isFocusActive, isFocusedRoute, point, theme });

  return new L.DivIcon({
    className: getMarkerClassname({ isFocused, kind }),
    html: getMarkerCustomHtml({ color, kind }),
    iconAnchor: getMarkerAnchor(kind),
  });
};

export const markerIconUnassigned = new L.Icon({
  iconUrl: markerUnassignedSVG,
  iconAnchor: [10, 10],
});

export const getStopColor = (color: string) => {
  const colorData = tinycolor(color);
  const colorHsl = colorData.toHsl();

  return tinycolor({
    h: colorHsl.h,
    s: colorHsl.s,
    l: colorHsl.l - 0.1 < 0 ? colorHsl.l : colorHsl.l - 0.1,
  }).toHexString();
};

export const formatNumberForDisplay = (unformattedNumber: number): string =>
  Math.round(unformattedNumber).toLocaleString();
