/*
  Converts unitless (pixel) value to `rem`, assumes base is
  16 unless different value is given.
*/
export const rem = (val: number, base?: number) => `${val / (base || 16)}rem`;

/*
  Convert value to rem if number, otherwise return as is.
*/
export const remOrReturn = (val: number | string) =>
  typeof val === "number" ? rem(val) : val;

// convert 1000 to k for smaller display footprint
export const toK = (val: number) => {
  const val1000 = Math.round(Math.abs(val) / 100) / 10;
  return `${val1000.toLocaleString("EN-US")}k`;
};

// returns a string representing time elapsed, in seconds or in milliseconds
export const formatTime = (v: number): string =>
  v < 1 ? `${Math.round(v * 1000)}ms` : `${Math.round(v * 100) / 100}s`;

// https://stackoverflow.com/a/1322798/1241287
export const formatSecondsToMinsSecs = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  let minutes = Math.floor(seconds / 60);
  seconds %= 60;

  return hours
    ? `${hours}h ${minutes}m ${seconds}s`
    : `${minutes}m ${seconds}s`;
};

// convert number to happy string
// TODO: Eventually replace the toK function above with this one
// TODO: Add support for million = M
export const formatNumber = (val: number, hasNoAbbr?: boolean): string => {
  if (Math.abs(val) > 999) {
    return hasNoAbbr
      ? (Math.sign(val) * Math.round(Math.abs(val))).toLocaleString()
      : `${(Math.sign(val) * Math.round(Math.abs(val) / 100)) / 10}k`;
  }
  return `${Math.sign(val) * Math.abs(val)}`;
};

// round number to specified decimal places
export const roundNumber = (num: number, decimalPlaces?: number) => {
  const dp = decimalPlaces || 0;
  return Math.round(num * Math.pow(10, dp)) / Math.pow(10, dp);
};

// output @media queries matched to array of values
// and formatted for styled components style

type StyleValue = string | number;
type ResponsiveValueArray = [
  StyleValue,
  StyleValue?,
  StyleValue?,
  StyleValue?,
  StyleValue?,
  StyleValue?
];
type DynamicProperties = "font-size" | "line-height" | "width";

// TODO: tech debt, importing from tokens/breakpoints or style/theme
// caused rem function to break, unsure why
const breakpointsArray = [
  rem(540), //sm
  rem(960), // rg,
  rem(1168), // md,
  rem(1354), // lg,
  rem(1600), // xl,
];

export const renderDynamicProperties = (
  property: DynamicProperties,
  values: ResponsiveValueArray
) => {
  return values
    .map((value, index) => {
      return index === 0
        ? `${property}: ${value};`
        : `@media (min-width: ${breakpointsArray[index - 1]}) {
            ${property}: ${value};
          }`;
    })
    .join("");
};
