import { trackEvent } from "../../../analytics";
import { InputSetResponse } from "../../../api/core/controlPlane.types";
import { StandardInputPayloads } from "../../../hooks/useStandardInputs";

export const trackInputSetFieldChanges = (
  inputSet: InputSetResponse,
  pendingStandardInputs: StandardInputPayloads
) => {
  let inputSetKey: keyof InputSetResponse;
  for (inputSetKey in inputSet) {
    if (
      ["name", "description"].includes(inputSetKey) &&
      inputSet[inputSetKey] !==
        pendingStandardInputs[inputSetKey as "name" | "description"]
    ) {
      trackEvent("InputSets", {
        view: "Edit Input Set",
        action: "Field Changed",
        meta: {
          field: inputSetKey as "name" | "description",
        },
      });
    }
  }
};
