export const sampleInputORToolsRouting = {
  defaults: {
    vehicles: {
      start_location: {
        lon: 7.625,
        lat: 51.9622,
      },
      end_location: {
        lon: 7.625,
        lat: 51.9622,
      },
      start_time: "2024-03-19T11:00:00+00:00",
      capacity: 5,
    },
    stops: {
      quantity: 1,
    },
  },
  vehicles: [
    {
      id: "vehicle-1",
    },
    {
      id: "vehicle-2",
    },
  ],
  stops: [
    {
      id: "location-1",
      location: {
        lon: 7.6228,
        lat: 51.9624,
      },
    },
    {
      id: "location-2",
      location: {
        lon: 7.6353,
        lat: 51.9584,
      },
    },
    {
      id: "location-3",
      location: {
        lon: 7.6233,
        lat: 51.9638,
      },
    },
    {
      id: "location-4",
      location: {
        lon: 7.6163,
        lat: 51.9635,
      },
    },
    {
      id: "location-5",
      location: {
        lon: 7.6045,
        lat: 51.9509,
      },
    },
    {
      id: "location-6",
      location: {
        lon: 7.6292,
        lat: 51.9577,
      },
    },
    {
      id: "location-7",
      location: {
        lon: 7.6385,
        lat: 51.9694,
      },
    },
    {
      id: "location-8",
      location: {
        lon: 7.6628,
        lat: 51.9616,
      },
    },
    {
      id: "location-9",
      location: {
        lon: 7.6217,
        lat: 51.9667,
      },
    },
    {
      id: "location-10",
      location: {
        lon: 7.6299,
        lat: 51.9582,
      },
    },
  ],
  duration_matrix: [
    [0, 831, 968, 648, 773, 689, 872, 997, 685, 698, 613, 613, 613, 613],
    [822, 0, 415, 257, 273, 83, 152, 266, 212, 74, 262, 262, 262, 262],
    [925, 466, 0, 342, 499, 454, 447, 624, 294, 463, 372, 372, 372, 372],
    [647, 293, 456, 0, 207, 168, 275, 452, 88, 178, 101, 101, 101, 101],
    [775, 385, 584, 202, 0, 280, 387, 544, 238, 289, 229, 229, 229, 229],
    [739, 143, 455, 211, 228, 0, 191, 308, 249, 9, 179, 179, 179, 179],
    [843, 152, 415, 260, 348, 158, 0, 274, 201, 149, 297, 297, 297, 297],
    [1001, 270, 573, 418, 466, 276, 293, 0, 370, 267, 455, 455, 455, 455],
    [687, 217, 444, 89, 247, 208, 199, 375, 0, 214, 141, 141, 141, 141],
    [748, 134, 446, 203, 219, 9, 182, 299, 240, 0, 188, 188, 188, 188],
    [613, 272, 372, 102, 221, 129, 320, 437, 139, 138, 0, 0, 0, 0],
    [613, 272, 372, 102, 221, 129, 320, 437, 139, 138, 0, 0, 0, 0],
    [613, 272, 372, 102, 221, 129, 320, 437, 139, 138, 0, 0, 0, 0],
    [613, 272, 372, 102, 221, 129, 320, 437, 139, 138, 0, 0, 0, 0],
  ],
};
