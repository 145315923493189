export default class NotFoundError extends Error {
  constructor(message: string = "Error: 404 Not Found") {
    super(message);
    this.name = "NotFoundError";
  }

  userFriendlyMessage(kind?: string, property?: string) {
    if (!kind || !property) {
      return this.message;
    }
    return `An ${kind} with this ${property} could not be found.`;
  }
}
