import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Button2, Flex, Notification, Select, Text } from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";

import { getOrgInfo } from "../../api/core/controlPlane";
import {
  AppResponse,
  MarketplacePartnerApp,
  OrganizationMember,
} from "../../api/core/controlPlane.types";
import { useUser } from "../../AuthProvider";
import Layout, { useMetaTitle } from "../../components/Layout";
import useManageEntities from "../../hooks/useManageEntities";
import { getIsUserPaidPremium } from "../../utils/authProviderHelpers";
import { getAccUrl } from "../../utils/navigation";
import { userHasAccessToAction } from "../../utils/rbac_utils";
import { ActionGroups } from "../../utils/rbac_utils/types";
import MarketplaceAppPreview from "../Marketplace/components/MarketplaceAppPreview";
import { marketplaceApps } from "../Marketplace/data/marketplaceApps";
import { StyledGrid } from "../Marketplace/Marketplace.styled";
import { NEXTROUTE_SUBSCRIPTION_ID } from "../MarketplaceApp/data/marketAppNextroute";
import { ORTOOLS_SIMPLE_ROUTING_SUBSCRIPTION_ID } from "../MarketplaceApp/data/marketAppORToolsSimpleRouting";
import { PYOMO_SHIFT_ASSIGNMENT_SUBSCRIPTION_ID } from "../MarketplaceApp/data/marketAppPyomoShiftAssignment";
import { SHIFT_SCHEDULING_SUBSCRIPTION_ID } from "../MarketplaceApp/data/marketAppShiftScheduling";

import AppExperiments from "./components/AppExperiments";
import AppOverview from "./components/AppOverview";
import BlogFeed from "./components/BlogFeed";
import DashboardFooter from "./components/DashboardFooter";
import DashboardHeader from "./components/DashboardHeader";
import MonthlyUsage from "./components/MonthlyUsage";
import TeamAdmins from "./components/TeamAdmins";
import { StyledAppSelectionBox } from "./Dashboard.styled";
import { SelectOptionApp } from "./Dashboard.types";

const pageTitle = "Nextmv Platform";

const DashboardContent = () => {
  const [user] = useUser();
  const { id: accountId, roles } = user;
  const [, setMetaTitle] = useMetaTitle();
  const theme = useTheme();

  const [teamMembers, setTeamMembers] = useState<OrganizationMember[]>();
  const [teamMembersError, setTeamMembersError] = useState("");

  const isUserPaidPremium = getIsUserPaidPremium(user);

  const {
    entities: apps,
    entitiesLoadError: appsError,
    loadEntities: loadApps,
  } = useManageEntities("applications");

  const [selectedApp, setSelectedApp] = useState<AppResponse | undefined>();

  const userCanCreateApps = userHasAccessToAction(
    roles,
    ActionGroups.AppOperator,
    { locked: false }
  );

  // page meta title
  useEffect(() => {
    setMetaTitle(pageTitle);
  }, [setMetaTitle]);

  // load org information
  useEffect(() => {
    const loadOrgInfo = async () => {
      setTeamMembersError("");
      try {
        const resp = await getOrgInfo(accountId || "");
        setTeamMembers(resp.members);
      } catch (e) {
        console.error(e);
        setTeamMembersError((e as Error).message);
      }
    };

    loadOrgInfo();
  }, [accountId]);

  // manage apps
  useEffect(() => {
    if (!apps && !appsError) {
      loadApps({});
    }
    if (!selectedApp && apps) {
      if (apps.length) {
        setSelectedApp(apps[0]);
      }
    }
  }, [apps, appsError, loadApps, selectedApp]);

  const appsOptions: SelectOptionApp[] = useMemo(() => {
    if (!apps) return [];
    return apps.reduce((options: SelectOptionApp[], app) => {
      options.push({
        label: app.name,
        type: app.type,
        value: app.id,
      });
      return options;
    }, []);
  }, [apps]);

  const previewMarketplaceApps = useMemo(() => {
    return marketplaceApps.filter((marketplaceApp) =>
      [
        NEXTROUTE_SUBSCRIPTION_ID,
        SHIFT_SCHEDULING_SUBSCRIPTION_ID,
        ORTOOLS_SIMPLE_ROUTING_SUBSCRIPTION_ID,
        PYOMO_SHIFT_ASSIGNMENT_SUBSCRIPTION_ID,
      ].includes(marketplaceApp.id)
    );
  }, []);

  const teamAdmins =
    teamMembers &&
    teamMembers.filter(
      (teamMember) => teamMember.role === "admin" && !teamMember.pending_invite
    );
  const teamRoot =
    teamMembers && teamMembers.find((teamMember) => teamMember.role === "root");

  const handleSelectedApp = (selectedApp: SelectOptionApp) => {
    if (!apps) return;
    return setSelectedApp(apps.find((app) => app.id === selectedApp.value));
  };

  const renderDashboardAppsExperience = () => {
    return (
      <>
        {appsError && (
          <Notification
            mt={4}
            type="error"
            message={appsError}
            hasContactExtra
          />
        )}

        {!appsError && (
          <StyledAppSelectionBox mt={4} p={2} maxWidth={["none", rem(296)]}>
            <Select
              placeholder={
                appsOptions.length ? "Select app" : "No apps available"
              }
              type="app"
              options={appsOptions}
              defaultValue={appsOptions[0]}
              value={
                selectedApp && {
                  label: selectedApp.name,
                  type: selectedApp.type,
                  value: selectedApp.id,
                }
              }
              onChange={(selection: SelectOptionApp) => {
                handleSelectedApp(selection);
              }}
            />
          </StyledAppSelectionBox>
        )}

        {!appsOptions.length && (
          <Box mt={5}>
            <Text
              as="h2"
              styleName="body-1-bold"
              styles={{ color: theme.color.gray800 }}
            >
              {userCanCreateApps
                ? "Create your first app by cloning a community app!"
                : "Browse the community apps!"}
            </Text>
            <Text mt={1} pb={1} styleName="body-2" hasLink>
              Select from one below or{" "}
              <Link to={getAccUrl(accountId, "/marketplace")}>
                view the full selection
              </Link>{" "}
              in Nextmv Marketplace.
            </Text>

            <StyledGrid mt={4} columns={4}>
              {previewMarketplaceApps.map((marketplaceApp) => (
                <MarketplaceAppPreview
                  key={marketplaceApp.id}
                  {...{ accountId }}
                  marketplaceApp={marketplaceApp as MarketplacePartnerApp}
                  viewSource="dashboard"
                />
              ))}
            </StyledGrid>

            <Flex mt={4}>
              <Button2
                size="large"
                type="outline"
                to={getAccUrl(accountId, "/marketplace")}
                label="Browse full marketplace"
              />
            </Flex>
          </Box>
        )}

        {!appsError && selectedApp && (
          <>
            <AppOverview app={selectedApp} />
            <AppExperiments app={selectedApp} />
          </>
        )}
      </>
    );
  };

  return (
    <Flex minHeight="100%" flexDirection="column">
      <DashboardHeader {...{ accountId, isUserPaidPremium }} />

      <Flex
        flexDirection={["column", "column", "row"]}
        flexWrap={["nowrap", "nowrap", "wrap", "wrap", "nowrap"]}
        width={[
          `calc(100% + ${theme.spacing.s4} + ${theme.spacing.s4})`,
          `calc(100% + ${theme.spacing.s4} + ${theme.spacing.s4})`,
          `calc(100% + ${theme.spacing.s6} + ${theme.spacing.s7})`,
        ]}
        mt={5}
        mr={[-4, -4, -7]}
        ml={[-4, -4, -6]}
        pt={[6, 6, 7]}
        pr={[0, 0, 7]}
        pb={[6, 6, 7, 8]}
        hasBorderTop
        hasBorderBottom
        background={theme.color.gray100}
      >
        <Flex
          width={["100%", "100%", "50%", "50%", 5 / 12]}
          minWidth={[0, 0, 0, 0, rem(400)]}
          maxWidth={["none", "none", "none", "none", rem(580)]}
          pr={[6, 6, 0]}
          pl={[6, 6, 7]}
          flexShrink={0}
        >
          <MonthlyUsage />
        </Flex>

        <Flex
          width={["100%", "100%", "50%", "50%", 3 / 12]}
          minWidth={[0, 0, 0, 0, rem(280)]}
          maxWidth={["none", "none", "none", "none", rem(400)]}
          mt={[6, 6, 0]}
          pr={[6, 6, 0]}
          pl={[6, 6, 7, 7, 7, 8]}
          flexShrink={0}
        >
          <TeamAdmins
            teamAdmins={teamAdmins}
            teamMembersError={teamMembersError}
            teamRoot={teamRoot}
          />
        </Flex>

        <Box
          width={["100%", "100%", "100%", "100%", 4 / 12]}
          mt={[7, 7, 8, 8, 0]}
          pr={[6, 6, 0]}
          pl={[6, 6, 7, 7, 7, 8]}
          flexGrow={1}
        >
          <BlogFeed />
        </Box>
      </Flex>

      <Box mt={6} ml={[0, 2]}>
        <Flex alignItems="baseline">
          <Text
            as="h2"
            styleName="header-1"
            styles={{ color: theme.color.gray800 }}
          >
            Apps
          </Text>
          <Text
            ml={2}
            styleName="meta-1"
            styles={{ color: theme.color.gray600 }}
          >
            Select an app to view details
          </Text>
        </Flex>

        {renderDashboardAppsExperience()}
      </Box>

      <Box mt="auto">
        <DashboardFooter />
      </Box>
    </Flex>
  );
};

const Dashboard = () => {
  return (
    <Layout>
      <DashboardContent />
    </Layout>
  );
};

export default Dashboard;
