import * as React from "react";
import styled from "@emotion/styled";

import { svgIconStyle } from "../lib/constants";

type StyledSvgProps = {
  activeColor?: string;
  focusColor?: string;
  hoverColor?: string;
};
const StyledSvg = styled.svg<StyledSvgProps>`
  ${(props) => svgIconStyle({})}
`;

const IconDemoCars = ({ iconSize }: { iconSize?: number }) => {
  const thisIconSize = iconSize || 32;
  return (
    <StyledSvg
      width={thisIconSize}
      height={thisIconSize}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.6875 12.5V20.375C28.6875 20.6071 28.5953 20.8296 28.4312 20.9937C28.2671 21.1578 28.0446 21.25 27.8125 21.25H26.0625C25.8305 21.25 25.6079 21.1578 25.4438 20.9937C25.2797 20.8296 25.1875 20.6071 25.1875 20.375V17.75C25.1866 16.8583 24.8448 16.0007 24.2322 15.3527L23.9364 14.25H26.5C26.616 14.25 26.7273 14.2039 26.8094 14.1219C26.8914 14.0398 26.9375 13.9285 26.9375 13.8125V12.9375C26.9375 12.8215 26.8914 12.7102 26.8094 12.6281C26.7273 12.5461 26.616 12.5 26.5 12.5H23.4669L23.1085 11.164C23.0757 11.0217 23.0177 10.888 22.9713 10.75H25.8819L24.9478 7.57642C24.845 7.24817 24.6461 6.95828 24.3769 6.74413C24.1077 6.52999 23.7805 6.40141 23.4375 6.375H13.8125C13.5296 6.39798 13.2565 6.4897 13.0171 6.64219C12.7777 6.79468 12.5791 7.00332 12.4386 7.25H10.5792L10.5973 7.18271C10.7906 6.46935 11.2068 5.83643 11.7852 5.37631C12.3636 4.9162 13.0739 4.65295 13.8125 4.625H23.4375C24.1761 4.65295 24.8864 4.9162 25.4648 5.37631C26.0432 5.83643 26.4594 6.46935 26.6528 7.18271L27.6519 10.9071C27.9594 11.045 28.2205 11.2686 28.4042 11.5511C28.5878 11.8336 28.6862 12.1631 28.6875 12.5Z"
        fill="#29CC00"
        fillOpacity="0.6"
      />
      <path
        d="M23.4375 17.75V26.5C23.4375 26.7321 23.3453 26.9546 23.1812 27.1187C23.0171 27.2828 22.7946 27.375 22.5625 27.375H20.8125C20.5804 27.375 20.3579 27.2828 20.1938 27.1187C20.0297 26.9546 19.9375 26.7321 19.9375 26.5V24.75H6.8125V26.5C6.8125 26.7321 6.72031 26.9546 6.55622 27.1187C6.39212 27.2828 6.16956 27.375 5.9375 27.375H4.1875C3.95544 27.375 3.73288 27.2828 3.56878 27.1187C3.40469 26.9546 3.3125 26.7321 3.3125 26.5V17.75C3.31316 17.4666 3.38262 17.1877 3.51492 16.9371C3.64721 16.6865 3.83838 16.4717 4.072 16.3114L5.34726 11.5577C5.54059 10.8444 5.95682 10.2114 6.53522 9.75131C7.11363 9.2912 7.82393 9.02795 8.5625 9H18.1875C18.9261 9.02795 19.6364 9.2912 20.2148 9.75131C20.7932 10.2114 21.2094 10.8444 21.4027 11.5577L22.678 16.3114C22.9116 16.4717 23.1028 16.6865 23.2351 16.9371C23.3674 17.1877 23.4368 17.4666 23.4375 17.75ZM6.11812 15.125H20.6319L19.6978 11.9514C19.5949 11.6231 19.3961 11.3333 19.1269 11.1191C18.8577 10.905 18.5305 10.7764 18.1875 10.75H8.5625C8.21953 10.7764 7.89234 10.905 7.62314 11.1191C7.35394 11.3333 7.15507 11.6231 7.05219 11.9514L6.11812 15.125ZM8.5625 18.1875C8.5625 18.0715 8.51641 17.9602 8.43436 17.8781C8.35231 17.7961 8.24103 17.75 8.125 17.75H5.5C5.38397 17.75 5.27269 17.7961 5.19064 17.8781C5.10859 17.9602 5.0625 18.0715 5.0625 18.1875V19.0625C5.0625 19.1785 5.10859 19.2898 5.19064 19.3719C5.27269 19.4539 5.38397 19.5 5.5 19.5H8.125C8.24103 19.5 8.35231 19.4539 8.43436 19.3719C8.51641 19.2898 8.5625 19.1785 8.5625 19.0625V18.1875ZM21.6875 18.1875C21.6875 18.0715 21.6414 17.9602 21.5594 17.8781C21.4773 17.7961 21.366 17.75 21.25 17.75H18.625C18.509 17.75 18.3977 17.7961 18.3156 17.8781C18.2336 17.9602 18.1875 18.0715 18.1875 18.1875V19.0625C18.1875 19.1785 18.2336 19.2898 18.3156 19.3719C18.3977 19.4539 18.509 19.5 18.625 19.5H21.25C21.366 19.5 21.4773 19.4539 21.5594 19.3719C21.6414 19.2898 21.6875 19.1785 21.6875 19.0625V18.1875Z"
        fill="#29CC00"
      />
    </StyledSvg>
  );
};

export default IconDemoCars;
