import { rem } from "@console/dsc/src/lib/tools";

export const PANEL_WIDTH = rem(468);
export const PANEL_TABS_HEIGHT = rem(44);
export const DOWNLOAD_OUTPUT_HEIGHT = rem(73);

export const MILLISECOND_HOUR = 1 * 60 * 60 * 1000;
export const WORKER_AVAILABILITY_HOUR_WIDTH = 100;
export const WORKER_AVAILABILITY_ROW_HEIGHT = 36;
export const WORKER_AVAILABILITY_ROW_SPACE = 1;

export const DEV_INT_ID = "devint";
