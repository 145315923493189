import { isEmpty } from "lodash";

import {
  OrganizationRunCount,
  OrganizationRunCountMetrics,
} from "../../../../../api/core/controlPlane.types";
import {
  TeamRunCountMonthOption,
  TeamRunCountSelectOptions,
} from "../Usage.types";

import { getDateLabelFromYearMonthKey } from "./handleTimeStrings";

export const emptyRunCount = {
  metrics: {},
  updated_at: "",
};

export const isEmptyRunCount = (runCount?: OrganizationRunCount): boolean => {
  return isEmpty(runCount?.metrics);
};

export const getRunCountSelectOptions = (
  metrics?: OrganizationRunCountMetrics
) => {
  if (!metrics) return undefined;
  return Object.keys(metrics)
    .reduce(
      (
        opt: TeamRunCountSelectOptions,
        key: string
      ): TeamRunCountSelectOptions => {
        opt.push({
          label: getDateLabelFromYearMonthKey(key),
          value: key,
        });
        return opt;
      },
      [] as TeamRunCountSelectOptions
    )
    .sort(
      (a: TeamRunCountMonthOption, b: TeamRunCountMonthOption) =>
        b.value.localeCompare(a.value) // Sort descending
    );
};
