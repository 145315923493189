import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { AvatarMarketplaceApp } from "@console/dsc";

import { useUser } from "../../AuthProvider";
import Layout from "../../components/Layout";
import useMarketplacePartners from "../../contexts/marketplace/hooks/useMarketplacePartners";
import { getAccUrl } from "../../utils/navigation";
import { marketplaceApps } from "../Marketplace/data/marketplaceApps";

import { getMarketplaceAppPages } from "./data/pages";
import MarketplaceAppRoutes from "./MarketplaceApp.routes";

const App = () => {
  const [{ id: accountId }] = useUser();
  const params = useParams() as { id: string; partner: string };
  const { id: marketplaceAppId, partner: partnerId } = params;
  const [displayPages, setDisplayPages] = useState(true);

  const {
    loadMarketplacePartnerApp,
    marketplacePartnerApp,
    marketplacePartnerAppError,
  } = useMarketplacePartners();

  // load marketplace app (if applicable)
  useEffect(() => {
    if (!marketplacePartnerApp && !marketplacePartnerAppError) {
      loadMarketplacePartnerApp(`${partnerId}-${marketplaceAppId}`);
    }
  }, [
    loadMarketplacePartnerApp,
    marketplaceAppId,
    marketplacePartnerApp,
    marketplacePartnerAppError,
    partnerId,
  ]);
  if (!marketplaceAppId || !partnerId) {
    return <Redirect to={getAccUrl(accountId, "/marketplace")} />;
  }

  const marketplaceAppPages =
    displayPages &&
    accountId &&
    getMarketplaceAppPages({
      marketplaceAppId,
      partnerId,
      userId: accountId,
    });

  const marketplaceAppIcon = marketplaceApps.find(
    (app) => app.id === `${partnerId}-${marketplaceAppId}`
  )?.icon;

  return (
    <Layout
      sectionTitle={marketplacePartnerApp?.name}
      sectionIcon={
        marketplaceAppIcon ? (
          <img
            src={marketplaceAppIcon.url}
            alt={marketplaceAppIcon.alt}
            width="32"
          />
        ) : (
          <AvatarMarketplaceApp size={32} type="active" />
        )
      }
      sectionPages={marketplaceAppPages}
    >
      <MarketplaceAppRoutes
        marketplacePartnerApp={marketplacePartnerApp}
        marketplacePartnerAppError={marketplacePartnerAppError}
        {...{ setDisplayPages }}
      />
    </Layout>
  );
};

export default App;
