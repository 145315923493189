import React from "react";
import { useTheme } from "@emotion/react";

import { rem } from "../../lib/tools";

import { StyledProgressBar, StyledProgressTrack } from "./ProgressBar.styled";
import { ProgressBarProps } from "./ProgressBar.types";

const ProgressBar = ({
  barColor,
  barWidth,
  m,
  mt,
  mr,
  mb,
  ml,
}: ProgressBarProps) => {
  const theme = useTheme();

  return (
    <StyledProgressTrack {...{ m, mt, mr, mb, ml }}>
      <StyledProgressBar
        barColor={barColor || theme.color.green500}
        barWidth={barWidth || rem(0)}
      />
    </StyledProgressTrack>
  );
};

export default ProgressBar;
