export const appTooltips = {
  inputs: {
    content:
      "Inputs are individual files or a collection of files used for a single run. Inputs are stored independent of runs and can be added to an input set and used for experiments.",
  },
  instances: {
    content:
      "Instances are required for runs and define a version and (optional) configuration. When you make an application run, the instance uses the executable code from the specified version and applies any configuration defined by the instance to that run.",
    extraLinkLabel: "More",
    extraLinkUrl: "https://www.nextmv.io/docs/platform/deploy-app/instances",
  },
  newRunInput: {
    content:
      "Run input can be specified with the text editor (valid JSON only) or via file upload. Accepted file types for upload are JSON, plain text (UTF-8), or CSV.",
    extraLinkLabel: "",
    extraLinkUrl: "",
  },
  newRunInstance: {
    content: "Select the instance to use for the run (required).",
  },
  newRunInstanceMarketplaceLatestVersion: {
    content:
      "This refers to a major version. When a run is made it will use the latest minor/patch version available for that major version. For example, if the specified version is Latest v1 (or v1), and the lastest version available for the v1 series is v1.4.1, then v1.4.1 will be used.",
  },
  newRunOptions: {
    content:
      "The instance selected will apply its configuration to the run. However, you can override specific options or configure additional options using the fields below. The extra configuration set here will apply only to this run and will not change the configuration of the instance used for the run.",
    extraLinkLabel: "More",
    extraLinkUrl:
      "https://www.nextmv.io/docs/platform/deploy-app/instances#configuration",
  },
  newRunNameDescription: {
    content:
      "Provide your run with a name and description. This is for your referency only and can be changed later.",
  },
  versions: {
    content:
      "Versions represent a specific executable binary that has been pushed to your app. When you create a new version, your app’s most recently pushed executable binary is preserved in that version (app pushes do not affect existing versions).",
    extraLinkLabel: "More",
    extraLinkUrl: "https://www.nextmv.io/docs/platform/deploy-app/versions",
  },
};
