import { MarketplaceAppDemoData } from "../MarketplaceApp.types";

export const nextrouteDemoIdGeneral = "nextroute-demo-general";
export const nextrouteDemoIdGeneralAdvanced = "nextroute-demo-general-advanced";
export const nextrouteDemoIdDelivery = "nextroute-demo-delivery";
export const nextrouteDemoIdDeliveryAdvanced =
  "nextroute-demo-delivery-advanced";
export const nextrouteDemoIdDistribution = "nextroute-demo-distribution";
export const nextrouteDemoIdDistributionAdvanced =
  "nextroute-demo-distribution-advanced";
export const nextrouteDemoIdSourcing = "nextroute-demo-sourcing";
export const nextrouteDemoIdSourcingAdvanced =
  "nextroute-demo-sourcing-advanced";

export const nextrouteDemoInputOptions = [
  { label: "General", value: nextrouteDemoIdGeneral },
  { label: "General w/Constraints", value: nextrouteDemoIdGeneralAdvanced },
  { label: "Delivery", value: nextrouteDemoIdDelivery },
  { label: "Delivery w/Constraints", value: nextrouteDemoIdDeliveryAdvanced },
  { label: "Distribution", value: nextrouteDemoIdDistribution },
  {
    label: "Distribution w/Constraints",
    value: nextrouteDemoIdDistributionAdvanced,
  },
  { label: "Sourcing", value: nextrouteDemoIdSourcing },
  { label: "Sourcing w/Constraints", value: nextrouteDemoIdSourcingAdvanced },
];

export const nextrouteDemoInputGeneral: MarketplaceAppDemoData = {
  id: nextrouteDemoIdGeneral,
  description:
    "General routing example with small number of stops and vehicles. Solver duration set to 3 seconds.",
  data: {
    defaults: {
      stops: {
        duration: 120,
        unplanned_penalty: 200000,
      },
      vehicles: {
        end_location: {
          lat: 33.122746,
          lon: -96.659222,
        },
        end_time: "2021-10-17T11:00:00-06:00",
        speed: 10,
        start_location: {
          lat: 33.122746,
          lon: -96.659222,
        },
        start_time: "2021-10-17T09:00:00-06:00",
      },
    },
    stops: [
      {
        id: "location-1",
        location: {
          lat: 33.20580830033956,
          lon: -96.75038245222623,
        },
        quantity: -27,
      },
      {
        id: "location-2",
        location: {
          lat: 33.2259142720506,
          lon: -96.54613745932127,
        },
        quantity: -30,
      },
      {
        id: "location-3",
        location: {
          lat: 33.23528740544529,
          lon: -96.61059803136642,
        },
        quantity: -36,
      },
      {
        id: "location-4",
        location: {
          lat: 33.20379744909628,
          lon: -96.61356543957307,
        },
        quantity: -19,
      },
      {
        id: "location-5",
        location: {
          lat: 33.178801586789376,
          lon: -96.64137458150537,
        },
        quantity: -31,
      },
      {
        id: "location-6",
        location: {
          lat: 33.02896457334468,
          lon: -96.83157538607735,
        },
        quantity: -15,
      },
      {
        id: "location-7",
        location: {
          lat: 33.08670100884261,
          lon: -96.62951544963792,
        },
        quantity: -31,
      },
      {
        id: "location-8",
        location: {
          lat: 33.08133590083287,
          lon: -96.86007117348946,
        },
        quantity: -52,
      },
      {
        id: "location-9",
        location: {
          lat: 33.092841906114394,
          lon: -96.87346076034575,
        },
        quantity: -48,
      },
      {
        id: "location-10",
        location: {
          lat: 33.10492159118987,
          lon: -96.79586982112724,
        },
        quantity: -40,
      },
    ],
    vehicles: [
      {
        capacity: 305,
        id: "vehicle-1",
      },
      {
        capacity: 205,
        id: "vehicle-2",
      },
    ],
  },
};

export const nextrouteDemoInputGeneralAdvanced: MarketplaceAppDemoData = {
  id: nextrouteDemoIdGeneralAdvanced,
  description:
    "General routing example with more stops, vehicles, and constraint examples. Solver duration set to 3 seconds.",
  data: {
    defaults: {
      stops: {
        duration: 120,
        early_arrival_time_penalty: 2,
        late_arrival_time_penalty: 5,
        unplanned_penalty: 200000,
      },
      vehicles: {
        end_location: {
          lat: 33.122746,
          lon: -96.659222,
        },
        end_time: "2021-10-17T11:00:00-06:00",
        speed: 20,
        start_location: {
          lat: 33.122746,
          lon: -96.659222,
        },
        start_time: "2021-10-17T09:00:00-06:00",
      },
    },
    stops: [
      {
        id: "order-1-pickup-1",
        location: {
          lat: 33.004745,
          lon: -96.827094,
        },
        precedes: "order-1-dropoff",
        quantity: -27,
      },
      {
        id: "order-1-dropoff",
        location: {
          lat: 33.005741,
          lon: -96.86074,
        },
        quantity: 27,
        start_time_window: [
          "2021-10-17T09:00:00-06:00",
          "2021-10-17T10:00:00-06:00",
        ],
        target_arrival_time: "2021-10-17T09:45:00-06:00",
      },
      {
        id: "order-2-pickup-1",
        location: {
          lat: 32.969456,
          lon: -96.820915,
        },
        precedes: "order-2-dropoff",
        quantity: -56,
      },
      {
        id: "order-2-dropoff",
        location: {
          lat: 32.921629,
          lon: -96.695259,
        },
        quantity: 56,
        start_time_window: [
          "2021-10-17T09:00:00-06:00",
          "2021-10-17T10:00:00-06:00",
        ],
        target_arrival_time: "2021-10-17T10:00:00-06:00",
      },
      {
        id: "order-3-pickup-1",
        location: {
          lat: 32.875507,
          lon: -96.673973,
        },
        precedes: "order-3-dropoff",
        quantity: -71,
      },
      {
        id: "order-3-pickup-2",
        location: {
          lat: 32.810318,
          lon: -96.682899,
        },
        precedes: "order-3-dropoff",
        quantity: -85,
      },
      {
        id: "order-3-dropoff",
        location: {
          lat: 32.66026,
          lon: -96.75642,
        },
        quantity: 156,
        start_time_window: [
          "2021-10-17T09:30:00-06:00",
          "2021-10-17T11:00:00-06:00",
        ],
        target_arrival_time: "2021-10-17T09:45:00-06:00",
      },
      {
        id: "order-4-pickup-1",
        location: {
          lat: 32.673555,
          lon: -96.87521,
        },
        precedes: "order-4-dropoff",
        quantity: -62,
      },
      {
        id: "order-4-dropoff",
        location: {
          lat: 32.772914,
          lon: -96.888943,
        },
        quantity: 62,
        start_time_window: [
          "2021-10-17T09:30:00-06:00",
          "2021-10-17T11:00:00-06:00",
        ],
        target_arrival_time: "2021-10-17T09:45:00-06:00",
      },
      {
        id: "order-5-pickup-1",
        location: {
          lat: 32.862358,
          lon: -97.044811,
        },
        precedes: "order-5-dropoff",
        quantity: -15,
      },
      {
        id: "order-5-pickup-2",
        location: {
          lat: 32.933848,
          lon: -97.021465,
        },
        precedes: "order-5-dropoff",
        quantity: -19,
      },
      {
        id: "order-5-pickup-3",
        location: {
          lat: 32.84563,
          lon: -96.87521,
        },
        precedes: "order-5-dropoff",
        quantity: -29,
      },
      {
        id: "order-5-dropoff",
        location: {
          lat: 32.86691792053951,
          lon: -96.9649670057751,
        },
        quantity: 63,
        start_time_window: [
          "2021-10-17T10:00:00-06:00",
          "2021-10-17T11:00:00-06:00",
        ],
        target_arrival_time: "2021-10-17T10:00:00-06:00",
      },
      {
        id: "order-6-pickup-1",
        location: {
          lat: 32.653323,
          lon: -96.943874,
        },
        precedes: "order-6-dropoff",
        quantity: -21,
      },
      {
        id: "order-6-pickup-2",
        location: {
          lat: 32.748662,
          lon: -96.846371,
        },
        precedes: "order-6-dropoff",
        quantity: -10,
      },
      {
        id: "order-6-dropoff",
        location: {
          lat: 32.735956,
          lon: -96.842251,
        },
        quantity: 31,
        start_time_window: [
          "2021-10-17T10:00:00-06:00",
          "2021-10-17T11:00:00-06:00",
        ],
        target_arrival_time: "2021-10-17T10:00:00-06:00",
      },
      {
        id: "order-7-pickup-1",
        location: {
          lat: 32.714583,
          lon: -96.840191,
        },
        precedes: "order-7-dropoff",
        quantity: -25,
      },
      {
        id: "order-7-dropoff",
        location: {
          lat: 32.710539,
          lon: -96.808605,
        },
        quantity: 25,
        start_time_window: [
          "2021-10-17T09:00:00-06:00",
          "2021-10-17T12:30:00-06:00",
        ],
        target_arrival_time: "2021-10-17T10:00:00-06:00",
      },
      {
        id: "order-8-pickup-1",
        location: {
          lat: 32.82486,
          lon: -96.595058,
        },
        precedes: "order-8-dropoff",
        quantity: -18,
      },
      {
        id: "order-8-pickup-2",
        location: {
          lat: 32.80486,
          lon: -96.575058,
        },
        precedes: "order-8-dropoff",
        quantity: -20,
      },
      {
        id: "order-8-dropoff",
        location: {
          lat: 32.772914,
          lon: -96.64175,
        },
        quantity: 38,
        start_time_window: [
          "2021-10-17T09:00:00-06:00",
          "2021-10-17T12:30:00-06:00",
        ],
        target_arrival_time: "2021-10-17T10:00:00-06:00",
      },
      {
        id: "order-9-pickup-1",
        location: {
          lat: 32.94134,
          lon: -96.573772,
        },
        precedes: "order-9-dropoff",
        quantity: -22,
      },
      {
        id: "order-9-dropoff",
        location: {
          lat: 32.895804,
          lon: -96.87521,
        },
        quantity: 22,
        start_time_window: [
          "2021-10-17T10:00:00-06:00",
          "2021-10-17T12:00:00-06:00",
        ],
        target_arrival_time: "2021-10-17T10:15:00-06:00",
      },
      {
        id: "order-10-pickup-1",
        location: {
          lat: 32.844476,
          lon: -96.950741,
        },
        precedes: "order-10-dropoff",
        quantity: -10,
      },
      {
        id: "order-10-pickup-2",
        location: {
          lat: 32.939035,
          lon: -96.910915,
        },
        precedes: "order-10-dropoff",
        quantity: -20,
      },
      {
        id: "order-10-dropoff",
        location: {
          lat: 32.745775,
          lon: -96.592999,
        },
        quantity: 30,
        start_time_window: [
          "2021-10-17T10:00:00-06:00",
          "2021-10-17T12:00:00-06:00",
        ],
        target_arrival_time: "2021-10-17T10:15:00-06:00",
      },
      {
        id: "order-11-pickup-1",
        location: {
          lat: 32.933272,
          lon: -97.271404,
        },
        precedes: "order-11-dropoff",
        quantity: -15,
      },
      {
        id: "order-11-dropoff",
        location: {
          lat: 32.902722,
          lon: -97.20274,
        },
        quantity: 15,
        start_time_window: [
          "2021-10-17T09:00:00-06:00",
          "2021-10-17T12:30:00-06:00",
        ],
        target_arrival_time: "2021-10-17T10:15:00-06:00",
      },
      {
        id: "order-12-pickup-1",
        location: {
          lat: 32.85659,
          lon: -96.990566,
        },
        precedes: "order-12-dropoff",
        quantity: -20,
      },
      {
        id: "order-12-dropoff",
        location: {
          lat: 32.618629,
          lon: -96.831951,
        },
        quantity: 20,
        start_time_window: [
          "2021-10-17T10:00:00-06:00",
          "2021-10-17T12:00:00-06:00",
        ],
        target_arrival_time: "2021-10-17T10:15:00-06:00",
      },
      {
        id: "order-13-pickup-1",
        location: {
          lat: 32.706495,
          lon: -96.785259,
        },
        precedes: "order-13-dropoff",
        quantity: -12,
      },
      {
        id: "order-13-dropoff",
        location: {
          lat: 32.706495,
          lon: -96.874523,
        },
        quantity: 13,
        start_time_window: [
          "2021-10-17T10:00:00-06:00",
          "2021-10-17T12:00:00-06:00",
        ],
        target_arrival_time: "2021-10-17T10:15:00-06:00",
      },
      {
        id: "order-14-pickup-1",
        location: {
          lat: 32.800045,
          lon: -96.992626,
        },
        precedes: "order-14-dropoff",
        quantity: -10,
      },
      {
        id: "order-14-dropoff",
        location: {
          lat: 33.019675,
          lon: -97.012539,
        },
        quantity: 10,
        start_time_window: [
          "2021-10-17T10:00:00-06:00",
          "2021-10-17T12:00:00-06:00",
        ],
        target_arrival_time: "2021-10-17T10:35:00-06:00",
      },
      {
        id: "order-15-pickup-1",
        location: {
          lat: 32.973028,
          lon: -96.961727,
        },
        precedes: "order-15-dropoff",
        quantity: -5,
      },
      {
        id: "order-15-dropoff",
        location: {
          lat: 32.796005,
          lon: -97.002926,
        },
        quantity: 5,
        start_time_window: [
          "2021-10-17T09:00:00-06:00",
          "2021-10-17T12:30:00-06:00",
        ],
        target_arrival_time: "2021-10-17T10:35:00-06:00",
      },
      {
        id: "order-16-pickup-1",
        location: {
          lat: 32.707072,
          lon: -96.798992,
        },
        precedes: "order-16-dropoff",
        quantity: -12,
      },
      {
        id: "order-16-dropoff",
        location: {
          lat: 32.709934,
          lon: -96.796717,
        },
        quantity: 12,
        start_time_window: [
          "2021-10-17T09:30:00-06:00",
          "2021-10-17T12:00:00-06:00",
        ],
        target_arrival_time: "2021-10-17T10:35:00-06:00",
      },
      {
        id: "order-17-pickup-1",
        location: {
          lat: 32.70487,
          lon: -96.775195,
        },
        precedes: "order-17-dropoff",
        quantity: -12,
      },
      {
        id: "order-17-pickup-2",
        location: {
          lat: 32.682658,
          lon: -96.754166,
        },
        precedes: "order-17-dropoff",
        quantity: -11,
      },
      {
        id: "order-17-dropoff",
        location: {
          lat: 32.76032,
          lon: -96.698623,
        },
        quantity: 23,
        start_time_window: [
          "2021-10-17T09:30:00-06:00",
          "2021-10-17T12:00:00-06:00",
        ],
        target_arrival_time: "2021-10-17T10:35:00-06:00",
      },
      {
        id: "order-18-pickup-1",
        location: {
          lat: 32.786149,
          lon: -96.705724,
        },
        precedes: "order-18-dropoff",
        quantity: -19,
      },
      {
        id: "order-18-pickup-2",
        location: {
          lat: 32.832623,
          lon: -96.674118,
        },
        precedes: "order-18-dropoff",
        quantity: -11,
      },
      {
        id: "order-18-dropoff",
        location: {
          lat: 32.856419,
          lon: -96.659698,
        },
        quantity: 30,
        start_time_window: [
          "2021-10-17T09:30:00-06:00",
          "2021-10-17T12:00:00-06:00",
        ],
        target_arrival_time: "2021-10-17T10:35:00-06:00",
      },
      {
        id: "order-19-pickup-1",
        location: {
          lat: 32.995981,
          lon: -96.690515,
        },
        precedes: "order-19-dropoff",
        quantity: -9,
      },
      {
        id: "order-19-dropoff",
        location: {
          lat: 32.950762,
          lon: -96.783555,
        },
        quantity: 9,
        start_time_window: [
          "2021-10-17T09:30:00-06:00",
          "2021-10-17T12:00:00-06:00",
        ],
        target_arrival_time: "2021-10-17T10:45:00-06:00",
      },
      {
        id: "order-20-pickup-1",
        location: {
          lat: 32.893412,
          lon: -96.72622,
        },
        precedes: "order-20-dropoff",
        quantity: -12,
      },
      {
        id: "order-20-pickup-2",
        location: {
          lat: 32.915319,
          lon: -96.837457,
        },
        precedes: "order-20-dropoff",
        quantity: -8,
      },
      {
        id: "order-20-dropoff",
        location: {
          lat: 32.89543,
          lon: -96.845266,
        },
        quantity: 20,
        start_time_window: [
          "2021-10-17T09:30:00-06:00",
          "2021-10-17T12:00:00-06:00",
        ],
        target_arrival_time: "2021-10-17T10:45:00-06:00",
      },
      {
        id: "order-21-pickup-1",
        location: {
          lat: 32.88543,
          lon: -96.865266,
        },
        precedes: "order-21-dropoff",
        quantity: -31,
      },
      {
        id: "order-21-dropoff",
        location: {
          lat: 33.010377,
          lon: -96.909898,
        },
        quantity: 31,
        start_time_window: [
          "2021-10-17T09:30:00-06:00",
          "2021-10-17T12:00:00-06:00",
        ],
        target_arrival_time: "2021-10-17T10:45:00-06:00",
      },
      {
        id: "order-22-pickup-1",
        location: {
          lat: 33.019625,
          lon: -96.922987,
        },
        precedes: "order-22-dropoff",
        quantity: -5,
      },
      {
        id: "order-22-pickup-2",
        location: {
          lat: 32.76032,
          lon: -96.6983,
        },
        precedes: "order-22-dropoff",
        quantity: -7,
      },
      {
        id: "order-22-dropoff",
        location: {
          lat: 32.782249,
          lon: -96.705724,
        },
        quantity: 12,
        start_time_window: [
          "2021-10-17T09:30:00-06:00",
          "2021-10-17T12:00:00-06:00",
        ],
        target_arrival_time: "2021-10-17T10:45:00-06:00",
      },
      {
        id: "order-23-pickup-1",
        location: {
          lat: 32.832611,
          lon: -96.674118,
        },
        precedes: "order-23-dropoff",
        quantity: -21,
      },
      {
        id: "order-23-dropoff",
        location: {
          lat: 32.856889,
          lon: -96.623698,
        },
        quantity: 21,
        start_time_window: [
          "2021-10-17T09:30:00-06:00",
          "2021-10-17T12:00:00-06:00",
        ],
        target_arrival_time: "2021-10-17T10:45:00-06:00",
      },
      {
        id: "order-24-pickup-1",
        location: {
          lat: 32.995981,
          lon: -96.690515,
        },
        precedes: "order-24-dropoff",
        quantity: -9,
      },
      {
        id: "order-24-dropoff",
        location: {
          lat: 32.95055,
          lon: -96.783115,
        },
        quantity: 9,
        start_time_window: [
          "2021-10-17T09:30:00-06:00",
          "2021-10-17T12:00:00-06:00",
        ],
        target_arrival_time: "2021-10-17T10:55:00-06:00",
      },
      {
        id: "order-25-pickup-1",
        location: {
          lat: 32.893112,
          lon: -96.71622,
        },
        precedes: "order-25-dropoff",
        quantity: -28,
      },
      {
        id: "order-25-dropoff",
        location: {
          lat: 32.80043,
          lon: -96.865006,
        },
        quantity: 28,
        start_time_window: [
          "2021-10-17T09:30:00-06:00",
          "2021-10-17T12:00:00-06:00",
        ],
        target_arrival_time: "2021-10-17T10:55:00-06:00",
      },
    ],
    vehicles: [
      {
        capacity: 105,
        id: "vehicle-1",
      },
      {
        capacity: 95,
        id: "vehicle-2",
      },
      {
        capacity: 200,
        id: "vehicle-3",
      },
      {
        capacity: 135,
        id: "vehicle-4",
      },
      {
        capacity: 165,
        id: "vehicle-5",
      },
      {
        capacity: 150,
        id: "vehicle-6",
      },
      {
        capacity: 165,
        id: "vehicle-7",
      },
      {
        capacity: 145,
        id: "vehicle-8",
      },
      {
        capacity: 145,
        id: "vehicle-9",
      },
      {
        capacity: 125,
        id: "vehicle-10",
      },
    ],
  },
};

export const nextrouteDemoInputDelivery: MarketplaceAppDemoData = {
  id: nextrouteDemoIdDelivery,
  description:
    "Delivery routing example with small number of stops and vehicles. Solver duration set to 3 seconds.",
  data: {
    defaults: {
      stops: {
        duration: 120,
        unplanned_penalty: 100000,
      },
      vehicles: {
        end_time: "2021-06-10T14:00:00Z",
        speed: 12,
        start_time: "2021-06-10T10:45:00Z",
      },
    },
    stops: [
      {
        duration: 300,
        id: "restaurant-A-order-1-pickup",
        location: {
          lat: 32.74745,
          lon: -96.82794,
        },
        precedes: "restaurant-A-order-1-dropoff",
        quantity: -2,
      },
      {
        id: "restaurant-A-order-1-dropoff",
        location: {
          lat: 32.67741,
          lon: -96.86074,
        },
        quantity: 2,
      },
      {
        duration: 300,
        id: "restaurant-A-order-2-pickup",
        location: {
          lat: 32.74745,
          lon: -96.82794,
        },
        precedes: "restaurant-A-order-2-dropoff",
        quantity: -1,
      },
      {
        id: "restaurant-A-order-2-dropoff",
        location: {
          lat: 32.735956,
          lon: -96.842251,
        },
        quantity: 1,
      },
      {
        duration: 300,
        id: "restaurant-A-order-3-pickup",
        location: {
          lat: 32.74745,
          lon: -96.82794,
        },
        precedes: "restaurant-A-order-3-dropoff",
        quantity: -1,
      },
      {
        id: "restaurant-A-order-3-dropoff",
        location: {
          lat: 32.71039,
          lon: -96.80605,
        },
        quantity: 1,
      },
      {
        duration: 300,
        id: "restaurant-A-order-4-pickup",
        location: {
          lat: 32.74745,
          lon: -96.82794,
        },
        precedes: "restaurant-A-order-4-dropoff",
        quantity: -2,
      },
      {
        id: "restaurant-A-order-4-dropoff",
        location: {
          lat: 32.772914,
          lon: -96.888943,
        },
        quantity: 2,
      },
      {
        duration: 300,
        id: "restaurant-C-order-5-pickup",
        location: {
          lat: 32.84525,
          lon: -96.76579,
        },
        precedes: "restaurant-C-order-5-dropoff",
        quantity: -1,
      },
      {
        id: "restaurant-C-order-5-dropoff",
        location: {
          lat: 32.82722,
          lon: -96.80274,
        },
        quantity: 1,
      },
    ],
    vehicles: [
      {
        capacity: 4,
        id: "vehicle-1",
        start_location: {
          lat: 32.76631,
          lon: -96.83171,
        },
      },
      {
        capacity: 4,
        id: "vehicle-2",
        start_location: {
          lat: 32.77675,
          lon: -96.80249,
        },
      },
    ],
  },
};

export const nextrouteDemoInputDeliveryAdvanced: MarketplaceAppDemoData = {
  id: nextrouteDemoIdDeliveryAdvanced,
  description:
    "Delivery routing example with more stops, vehicles, and constraint examples. Solver duration set to 3 seconds.",
  data: {
    defaults: {
      stops: {
        duration: 120,
        early_arrival_time_penalty: 8,
        late_arrival_time_penalty: 10,
        unplanned_penalty: 100000,
      },
      vehicles: {
        end_time: "2021-06-10T14:00:00Z",
        max_stops: 12,
        speed: 12,
        start_time: "2021-06-10T09:45:00Z",
      },
    },
    stops: [
      {
        compatibility_attributes: ["frozen"],
        duration: 300,
        id: "restaurant-A-order-1-pickup",
        location: {
          lat: 32.74745,
          lon: -96.82794,
        },
        precedes: "restaurant-A-order-1-dropoff",
        quantity: -2,
        target_arrival_time: "2021-06-10T11:00:00Z",
      },
      {
        id: "restaurant-A-order-1-dropoff",
        location: {
          lat: 32.67741,
          lon: -96.86074,
        },
        quantity: 2,
        target_arrival_time: "2021-06-10T11:30:00Z",
      },
      {
        compatibility_attributes: ["frozen"],
        duration: 300,
        id: "restaurant-B-order-2-pickup",
        location: {
          lat: 32.82512,
          lon: -96.62577,
        },
        precedes: "restaurant-B-order-2-dropoff",
        quantity: -3,
        target_arrival_time: "2021-06-10T10:15:00Z",
      },
      {
        id: "restaurant-B-order-2-dropoff",
        location: {
          lat: 32.921629,
          lon: -96.695259,
        },
        quantity: 3,
        target_arrival_time: "2021-06-10T10:45:00Z",
      },
      {
        compatibility_attributes: ["frozen"],
        duration: 300,
        id: "restaurant-B-order-3-pickup",
        location: {
          lat: 32.82512,
          lon: -96.62577,
        },
        precedes: "restaurant-B-order-3-dropoff",
        quantity: -3,
        target_arrival_time: "2021-06-10T11:15:00Z",
      },
      {
        id: "restaurant-B-order-3-dropoff",
        location: {
          lat: 32.66026,
          lon: -96.75642,
        },
        quantity: 3,
        target_arrival_time: "2021-06-10T11:45:00Z",
      },
      {
        id: "restaurant-A-order-4-pickup",
        location: {
          lat: 32.74745,
          lon: -96.82794,
        },
        precedes: "restaurant-A-order-4-dropoff",
        quantity: -2,
        target_arrival_time: "2021-06-10T11:15:00Z",
      },
      {
        id: "restaurant-A-order-4-dropoff",
        location: {
          lat: 32.772914,
          lon: -96.888943,
        },
        quantity: 2,
        target_arrival_time: "2021-06-10T11:45:00Z",
      },
      {
        id: "restaurant-B-order-5-pickup",
        location: {
          lat: 32.82512,
          lon: -96.62577,
        },
        precedes: "restaurant-B-order-5-dropoff",
        quantity: -1,
        target_arrival_time: "2021-06-10T11:00:00Z",
      },
      {
        id: "restaurant-B-order-5-dropoff",
        location: {
          lat: 32.86691792053951,
          lon: -96.9649670057751,
        },
        quantity: 1,
        target_arrival_time: "2021-06-10T11:30:00Z",
      },
      {
        id: "restaurant-A-order-6-pickup",
        location: {
          lat: 32.74745,
          lon: -96.82794,
        },
        precedes: "restaurant-A-order-6-dropoff",
        quantity: -1,
        target_arrival_time: "2021-06-10T10:15:00Z",
      },
      {
        id: "restaurant-A-order-6-dropoff",
        location: {
          lat: 32.735956,
          lon: -96.842251,
        },
        quantity: 1,
        target_arrival_time: "2021-06-10T10:45:00Z",
      },
      {
        id: "restaurant-A-order-7-pickup",
        location: {
          lat: 32.74745,
          lon: -96.82794,
        },
        precedes: "restaurant-A-order-7-dropoff",
        quantity: -1,
        target_arrival_time: "2021-06-10T11:30:00Z",
      },
      {
        id: "restaurant-A-order-7-dropoff",
        location: {
          lat: 32.71039,
          lon: -96.80605,
        },
        quantity: 1,
        target_arrival_time: "2021-06-10T12:00:00Z",
      },
      {
        id: "restaurant-A-order-8-pickup",
        location: {
          lat: 32.74745,
          lon: -96.82794,
        },
        precedes: "restaurant-A-order-8-dropoff",
        quantity: -2,
        target_arrival_time: "2021-06-10T10:05:00Z",
      },
      {
        id: "restaurant-A-order-8-dropoff",
        location: {
          lat: 32.772914,
          lon: -96.64175,
        },
        quantity: 2,
        target_arrival_time: "2021-06-10T10:35:00Z",
      },
      {
        id: "restaurant-B-order-9-pickup",
        location: {
          lat: 32.82512,
          lon: -96.62577,
        },
        precedes: "restaurant-B-order-9-dropoff",
        quantity: -1,
        target_arrival_time: "2021-06-10T11:10:00Z",
      },
      {
        id: "restaurant-B-order-9-dropoff",
        location: {
          lat: 32.895804,
          lon: -96.87521,
        },
        quantity: 1,
        target_arrival_time: "2021-06-10T11:40:00Z",
      },
      {
        id: "restaurant-A-order-10-pickup",
        location: {
          lat: 32.74745,
          lon: -96.82794,
        },
        precedes: "restaurant-A-order-10-dropoff",
        quantity: -1,
        target_arrival_time: "2021-06-10T10:15:00Z",
      },
      {
        id: "restaurant-A-order-10-dropoff",
        location: {
          lat: 32.745775,
          lon: -96.592999,
        },
        quantity: 1,
        target_arrival_time: "2021-06-10T10:45:00Z",
      },
      {
        id: "restaurant-C-order-11-pickup",
        location: {
          lat: 32.84525,
          lon: -96.76579,
        },
        precedes: "restaurant-C-order-11-dropoff",
        quantity: -1,
        target_arrival_time: "2021-06-10T11:40:00Z",
      },
      {
        id: "restaurant-C-order-11-dropoff",
        location: {
          lat: 32.82722,
          lon: -96.80274,
        },
        quantity: 1,
        target_arrival_time: "2021-06-10T12:10:00Z",
      },
      {
        id: "restaurant-B-order-12-pickup",
        location: {
          lat: 32.82512,
          lon: -96.62577,
        },
        precedes: "restaurant-B-order-12-dropoff",
        quantity: -2,
        target_arrival_time: "2021-06-10T11:30:00Z",
      },
      {
        id: "restaurant-B-order-12-dropoff",
        location: {
          lat: 32.61829,
          lon: -96.83151,
        },
        quantity: 2,
        target_arrival_time: "2021-06-10T12:00:00Z",
      },
      {
        id: "restaurant-B-order-13-pickup",
        location: {
          lat: 32.82512,
          lon: -96.62577,
        },
        precedes: "restaurant-B-order-13-dropoff",
        quantity: -1,
        target_arrival_time: "2021-06-10T11:10:00Z",
      },
      {
        id: "restaurant-B-order-13-dropoff",
        location: {
          lat: 32.706495,
          lon: -96.874523,
        },
        quantity: 1,
        target_arrival_time: "2021-06-10T11:40:00Z",
      },
      {
        compatibility_attributes: ["frozen"],
        duration: 300,
        id: "restaurant-C-order-14-pickup",
        location: {
          lat: 32.84525,
          lon: -96.76579,
        },
        precedes: "restaurant-C-order-14-dropoff",
        quantity: -1,
        target_arrival_time: "2021-06-10T10:50:00Z",
      },
      {
        id: "restaurant-C-order-14-dropoff",
        location: {
          lat: 32.71675,
          lon: -96.68549,
        },
        quantity: 1,
        target_arrival_time: "2021-06-10T11:20:00Z",
      },
      {
        compatibility_attributes: ["frozen"],
        duration: 300,
        id: "restaurant-B-order-15-pickup",
        location: {
          lat: 32.82512,
          lon: -96.62577,
        },
        precedes: "restaurant-B-order-15-dropoff",
        quantity: -3,
        target_arrival_time: "2021-06-10T10:50:00Z",
      },
      {
        id: "restaurant-B-order-15-dropoff",
        location: {
          lat: 32.79605,
          lon: -96.62926,
        },
        quantity: 3,
        target_arrival_time: "2021-06-10T11:20:00Z",
      },
      {
        compatibility_attributes: ["frozen"],
        duration: 300,
        id: "restaurant-C-order-16-pickup",
        location: {
          lat: 32.84525,
          lon: -96.76579,
        },
        precedes: "restaurant-C-order-16-dropoff",
        quantity: -3,
        target_arrival_time: "2021-06-10T11:10:00Z",
      },
      {
        id: "restaurant-C-order-16-dropoff",
        location: {
          lat: 32.81156,
          lon: -96.68519,
        },
        quantity: 3,
        target_arrival_time: "2021-06-10T11:40:00Z",
      },
      {
        id: "restaurant-B-order-17-pickup",
        location: {
          lat: 32.82512,
          lon: -96.62577,
        },
        precedes: "restaurant-B-order-17-dropoff",
        quantity: -1,
        target_arrival_time: "2021-06-10T11:30:00Z",
      },
      {
        id: "restaurant-B-order-17-dropoff",
        location: {
          lat: 32.84219,
          lon: -96.87187,
        },
        quantity: 1,
        target_arrival_time: "2021-06-10T12:00:00Z",
      },
      {
        compatibility_attributes: ["frozen"],
        duration: 300,
        id: "restaurant-C-order-18-pickup",
        location: {
          lat: 32.84525,
          lon: -96.76579,
        },
        precedes: "restaurant-C-order-18-dropoff",
        quantity: -1,
        target_arrival_time: "2021-06-10T10:40:00Z",
      },
      {
        id: "restaurant-C-order-18-dropoff",
        location: {
          lat: 32.83941,
          lon: -96.87207,
        },
        quantity: 1,
        target_arrival_time: "2021-06-10T11:10:00Z",
      },
      {
        id: "restaurant-B-order-19-pickup",
        location: {
          lat: 32.82512,
          lon: -96.62577,
        },
        precedes: "restaurant-B-order-19-dropoff",
        quantity: -1,
        target_arrival_time: "2021-06-10T12:10:00Z",
      },
      {
        id: "restaurant-B-order-19-dropoff",
        location: {
          lat: 32.74813,
          lon: -96.85891,
        },
        quantity: 1,
        target_arrival_time: "2021-06-10T12:40:00Z",
      },
      {
        id: "restaurant-C-order-20-pickup",
        location: {
          lat: 32.84525,
          lon: -96.76579,
        },
        precedes: "restaurant-C-order-20-dropoff",
        quantity: -3,
        target_arrival_time: "2021-06-10T12:40:00Z",
      },
      {
        id: "restaurant-C-order-20-dropoff",
        location: {
          lat: 32.93835,
          lon: -96.91345,
        },
        quantity: 3,
        target_arrival_time: "2021-06-10T13:10:00Z",
      },
      {
        id: "restaurant-B-order-21-pickup",
        location: {
          lat: 32.82512,
          lon: -96.62577,
        },
        precedes: "restaurant-B-order-21-dropoff",
        quantity: -1,
        target_arrival_time: "2021-06-10T11:10:00Z",
      },
      {
        id: "restaurant-B-order-21-dropoff",
        location: {
          lat: 32.87393,
          lon: -96.594245,
        },
        quantity: 1,
        target_arrival_time: "2021-06-10T11:40:00Z",
      },
      {
        id: "restaurant-C-order-22-pickup",
        location: {
          lat: 32.84525,
          lon: -96.76579,
        },
        precedes: "restaurant-C-order-22-dropoff",
        quantity: -1,
        target_arrival_time: "2021-06-10T10:50:00Z",
      },
      {
        id: "restaurant-C-order-22-dropoff",
        location: {
          lat: 32.89304,
          lon: -96.86521,
        },
        quantity: 1,
        target_arrival_time: "2021-06-10T11:20:00Z",
      },
      {
        duration: 300,
        id: "restaurant-B-order-23-pickup",
        location: {
          lat: 32.82512,
          lon: -96.62577,
        },
        precedes: "restaurant-B-order-23-dropoff",
        quantity: -3,
        target_arrival_time: "2021-06-10T10:30:00Z",
      },
      {
        id: "restaurant-B-order-23-dropoff",
        location: {
          lat: 32.89776,
          lon: -96.98071,
        },
        quantity: 3,
        target_arrival_time: "2021-06-10T11:00:00Z",
      },
      {
        id: "restaurant-B-order-24-pickup",
        location: {
          lat: 32.82512,
          lon: -96.62577,
        },
        precedes: "restaurant-B-order-24-dropoff",
        quantity: -2,
        target_arrival_time: "2021-06-10T10:15:00Z",
      },
      {
        id: "restaurant-B-order-24-dropoff",
        location: {
          lat: 32.82034,
          lon: -96.96012,
        },
        quantity: 2,
        target_arrival_time: "2021-06-10T10:45:00Z",
      },
      {
        id: "restaurant-A-order-25-pickup",
        location: {
          lat: 32.74745,
          lon: -96.82794,
        },
        precedes: "restaurant-A-order-25-dropoff",
        quantity: -3,
        target_arrival_time: "2021-06-10T11:20:00Z",
      },
      {
        id: "restaurant-A-order-25-dropoff",
        location: {
          lat: 32.82575,
          lon: -96.75519,
        },
        quantity: 3,
        target_arrival_time: "2021-06-10T11:50:00Z",
      },
      {
        id: "restaurant-A-order-26-pickup",
        location: {
          lat: 32.74745,
          lon: -96.82794,
        },
        precedes: "restaurant-A-order-26-dropoff",
        quantity: -1,
        target_arrival_time: "2021-06-10T10:20:00Z",
      },
      {
        id: "restaurant-A-order-26-dropoff",
        location: {
          lat: 32.9172,
          lon: -97.01404,
        },
        quantity: 1,
        target_arrival_time: "2021-06-10T10:50:00Z",
      },
      {
        id: "restaurant-A-order-27-pickup",
        location: {
          lat: 32.74745,
          lon: -96.82794,
        },
        precedes: "restaurant-A-order-27-dropoff",
        quantity: -1,
        target_arrival_time: "2021-06-10T10:30:00Z",
      },
      {
        id: "restaurant-A-order-27-dropoff",
        location: {
          lat: 32.88722,
          lon: -96.68174,
        },
        quantity: 1,
        target_arrival_time: "2021-06-10T11:30:00Z",
      },
      {
        id: "restaurant-A-order-28-pickup",
        location: {
          lat: 32.74745,
          lon: -96.82794,
        },
        precedes: "restaurant-A-order-28-dropoff",
        quantity: -1,
        target_arrival_time: "2021-06-10T12:00:00Z",
      },
      {
        id: "restaurant-A-order-28-dropoff",
        location: {
          lat: 32.81619,
          lon: -96.94887,
        },
        quantity: 1,
        target_arrival_time: "2021-06-10T11:00:00Z",
      },
      {
        id: "restaurant-A-order-29-pickup",
        location: {
          lat: 32.74745,
          lon: -96.82794,
        },
        precedes: "restaurant-A-order-29-dropoff",
        quantity: -2,
        target_arrival_time: "2021-06-10T11:50:00Z",
      },
      {
        id: "restaurant-A-order-29-dropoff",
        location: {
          lat: 32.62059,
          lon: -96.83154,
        },
        quantity: 2,
        target_arrival_time: "2021-06-10T12:20:00Z",
      },
      {
        id: "restaurant-C-order-30-pickup",
        location: {
          lat: 32.84525,
          lon: -96.76579,
        },
        precedes: "restaurant-C-order-30-dropoff",
        quantity: -1,
        target_arrival_time: "2021-06-10T11:40:00Z",
      },
      {
        id: "restaurant-C-order-30-dropoff",
        location: {
          lat: 32.81695,
          lon: -96.85259,
        },
        quantity: 1,
        target_arrival_time: "2021-06-10T12:10:00Z",
      },
    ],
    vehicles: [
      {
        capacity: 3,
        id: "vehicle-1",
        start_location: {
          lat: 32.86707,
          lon: -96.91435,
        },
      },
      {
        capacity: 5,
        compatibility_attributes: ["frozen"],
        id: "vehicle-2",
        start_location: {
          lat: 32.89126,
          lon: -96.65922,
        },
      },
      {
        capacity: 3,
        id: "vehicle-3",
        start_location: {
          lat: 32.82611,
          lon: -96.65171,
        },
      },
      {
        capacity: 5,
        id: "vehicle-4",
        start_location: {
          lat: 32.64631,
          lon: -96.82171,
        },
      },
      {
        capacity: 5,
        compatibility_attributes: ["frozen"],
        id: "vehicle-5",
        start_location: {
          lat: 32.72675,
          lon: -96.68249,
        },
      },
      {
        capacity: 5,
        compatibility_attributes: ["frozen"],
        id: "vehicle-6",
        start_location: {
          lat: 32.84631,
          lon: -96.92171,
        },
      },
    ],
  },
};

export const nextrouteDemoInputDistribution: MarketplaceAppDemoData = {
  id: nextrouteDemoIdDistribution,
  description:
    "Distribution routing example with small number of stops and vehicles. Solver duration set to 3 seconds.",
  data: {
    defaults: {
      stops: {
        duration: 120,
        unplanned_penalty: 100000,
      },
      vehicles: {
        end_time: "2021-06-10T16:00:00Z",
        speed: 12,
        start_location: {
          lat: 32.80875,
          lon: -96.87215,
        },
        start_time: "2021-06-10T08:00:00Z",
      },
    },
    stops: [
      {
        id: "order-1-dropoff",
        location: {
          lat: 32.65764,
          lon: -96.7524,
        },
        quantity: -15,
      },
      {
        id: "order-2-dropoff",
        location: {
          lat: 32.6498,
          lon: -96.82663,
        },
        quantity: -10,
      },
      {
        id: "order-3-dropoff",
        location: {
          lat: 32.8922,
          lon: -96.9925,
        },
        quantity: -15,
      },
      {
        id: "order-4-dropoff",
        location: {
          lat: 32.89166,
          lon: -96.99433,
        },
        quantity: -5,
      },
      {
        id: "order-5-dropoff",
        location: {
          lat: 32.81559,
          lon: -96.88486,
        },
        quantity: -20,
      },
      {
        id: "order-6-dropoff",
        location: {
          lat: 32.80997,
          lon: -96.85313,
        },
        quantity: -20,
      },
      {
        id: "order-7-dropoff",
        location: {
          lat: 32.73941,
          lon: -96.58497,
        },
        quantity: -20,
      },
      {
        id: "order-8-dropoff",
        location: {
          lat: 32.7274,
          lon: -96.6335,
        },
        quantity: -20,
      },
      {
        id: "order-9-dropoff",
        location: {
          lat: 32.77418,
          lon: -96.67937,
        },
        quantity: -20,
      },
      {
        id: "order-10-dropoff",
        location: {
          lat: 32.77412,
          lon: -96.69089,
        },
        quantity: -10,
      },
    ],
    vehicles: [
      {
        capacity: 200,
        id: "vehicle-1",
      },
      {
        capacity: 200,
        id: "vehicle-2",
      },
    ],
  },
};

export const nextrouteDemoInputDistributionAdvanced: MarketplaceAppDemoData = {
  id: nextrouteDemoIdDistributionAdvanced,
  description:
    "Distribution routing example with more stops, vehicles, and constraint examples. Solver duration set to 3 seconds.",
  data: {
    defaults: {
      stops: {
        duration: 120,
        unplanned_penalty: 100000,
      },
      vehicles: {
        end_time: "2021-06-10T16:00:00Z",
        max_distance: 50000,
        speed: 12,
        start_location: {
          lat: 32.80875,
          lon: -96.87215,
        },
        start_time: "2021-06-10T08:00:00Z",
      },
    },
    stops: [
      {
        id: "order-1-dropoff-express",
        late_arrival_time_penalty: 10,
        location: {
          lat: 32.65764,
          lon: -96.7524,
        },
        quantity: {
          volume: -155,
          weight: -15,
        },
        target_arrival_time: "2021-06-10T08:30:00Z",
        unplanned_penalty: 200000,
      },
      {
        id: "order-2-dropoff",
        location: {
          lat: 32.6498,
          lon: -96.82663,
        },
        quantity: {
          volume: -220,
          weight: -10,
        },
      },
      {
        id: "order-3-dropoff",
        location: {
          lat: 32.8922,
          lon: -96.9925,
        },
        quantity: {
          volume: -195,
          weight: -15,
        },
      },
      {
        id: "order-4-dropoff",
        location: {
          lat: 32.89166,
          lon: -96.99433,
        },
        quantity: {
          volume: -175,
          weight: -5,
        },
      },
      {
        id: "order-5-dropoff-express",
        late_arrival_time_penalty: 10,
        location: {
          lat: 32.81559,
          lon: -96.88486,
        },
        quantity: {
          volume: -180,
          weight: -20,
        },
        target_arrival_time: "2021-06-10T08:30:00Z",
        unplanned_penalty: 200000,
      },
      {
        id: "order-6-dropoff",
        location: {
          lat: 32.80997,
          lon: -96.85313,
        },
        quantity: {
          volume: -225,
          weight: -20,
        },
      },
      {
        id: "order-7-dropoff",
        location: {
          lat: 32.73941,
          lon: -96.58497,
        },
        quantity: {
          volume: -245,
          weight: -20,
        },
      },
      {
        id: "order-8-dropoff",
        location: {
          lat: 32.7274,
          lon: -96.6335,
        },
        quantity: {
          volume: -170,
          weight: -20,
        },
      },
      {
        id: "order-9-dropoff",
        location: {
          lat: 32.77418,
          lon: -96.67937,
        },
        quantity: {
          volume: -90,
          weight: -20,
        },
      },
      {
        id: "order-10-dropoff",
        location: {
          lat: 32.77412,
          lon: -96.69089,
        },
        quantity: {
          volume: -185,
          weight: -10,
        },
      },
      {
        id: "order-11-dropoff",
        location: {
          lat: 32.66673,
          lon: -97.09611,
        },
        quantity: {
          volume: -155,
          weight: -15,
        },
      },
      {
        id: "order-12-dropoff",
        location: {
          lat: 32.69165,
          lon: -97.06902,
        },
        quantity: {
          volume: -180,
          weight: -15,
        },
      },
      {
        id: "order-13-dropoff-express",
        late_arrival_time_penalty: 10,
        location: {
          lat: 32.57605,
          lon: -96.9157,
        },
        quantity: {
          volume: -135,
          weight: -25,
        },
        target_arrival_time: "2021-06-10T08:30:00Z",
        unplanned_penalty: 200000,
      },
      {
        id: "order-14-dropoff-express",
        late_arrival_time_penalty: 10,
        location: {
          lat: 32.57542,
          lon: -96.83226,
        },
        quantity: {
          volume: -95,
          weight: -20,
        },
        target_arrival_time: "2021-06-10T08:30:00Z",
        unplanned_penalty: 200000,
      },
      {
        id: "order-15-dropoff",
        location: {
          lat: 32.75759,
          lon: -97.02575,
        },
        quantity: {
          volume: -80,
          weight: -10,
        },
      },
      {
        id: "order-16-dropoff",
        location: {
          lat: 32.75106,
          lon: -97.00757,
        },
        quantity: {
          volume: -145,
          weight: -20,
        },
      },
      {
        id: "order-17-dropoff",
        location: {
          lat: 32.90896,
          lon: -97.26953,
        },
        quantity: {
          volume: -70,
          weight: -5,
        },
      },
      {
        id: "order-18-dropoff",
        location: {
          lat: 32.87165,
          lon: -97.27819,
        },
        quantity: {
          volume: -110,
          weight: -25,
        },
      },
      {
        id: "order-19-dropoff",
        location: {
          lat: 32.8335,
          lon: -96.91767,
        },
        quantity: {
          volume: -180,
          weight: -15,
        },
      },
      {
        id: "order-20-dropoff",
        location: {
          lat: 32.81456,
          lon: -96.96781,
        },
        quantity: {
          volume: -60,
          weight: -15,
        },
      },
      {
        id: "order-21-dropoff",
        location: {
          lat: 32.6914,
          lon: -97.14119,
        },
        quantity: {
          volume: -230,
          weight: -10,
        },
      },
      {
        id: "order-22-dropoff-express",
        late_arrival_time_penalty: 10,
        location: {
          lat: 32.7049,
          lon: -97.14001,
        },
        quantity: {
          volume: -225,
          weight: -25,
        },
        target_arrival_time: "2021-06-10T08:30:00Z",
        unplanned_penalty: 200000,
      },
      {
        id: "order-23-dropoff-express",
        late_arrival_time_penalty: 10,
        location: {
          lat: 32.81572,
          lon: -96.91338,
        },
        quantity: {
          volume: -205,
          weight: -15,
        },
        target_arrival_time: "2021-06-10T08:30:00Z",
        unplanned_penalty: 200000,
      },
      {
        id: "order-24-dropoff",
        location: {
          lat: 32.78629,
          lon: -96.89299,
        },
        quantity: {
          volume: -125,
          weight: -10,
        },
      },
      {
        id: "order-25-dropoff",
        location: {
          lat: 32.78366,
          lon: -97.06819,
        },
        quantity: {
          volume: -185,
          weight: -10,
        },
      },
      {
        id: "order-26-dropoff",
        location: {
          lat: 32.74745,
          lon: -97.07571,
        },
        quantity: {
          volume: -65,
          weight: -20,
        },
      },
      {
        id: "order-27-dropoff",
        location: {
          lat: 32.73423,
          lon: -97.10771,
        },
        quantity: {
          volume: -50,
          weight: -10,
        },
      },
      {
        id: "order-28-dropoff",
        location: {
          lat: 32.74378,
          lon: -97.11424,
        },
        quantity: {
          volume: -80,
          weight: -25,
        },
      },
      {
        id: "order-29-dropoff",
        location: {
          lat: 32.76309,
          lon: -96.80497,
        },
        quantity: {
          volume: -145,
          weight: -15,
        },
      },
      {
        id: "order-30-dropoff-express",
        late_arrival_time_penalty: 10,
        location: {
          lat: 32.76119,
          lon: -96.76166,
        },
        quantity: {
          volume: -160,
          weight: -20,
        },
        target_arrival_time: "2021-06-10T08:30:00Z",
        unplanned_penalty: 200000,
      },
      {
        id: "order-31-dropoff",
        location: {
          lat: 32.99633,
          lon: -97.07549,
        },
        quantity: {
          volume: -80,
          weight: -20,
        },
      },
      {
        id: "order-32-dropoff",
        location: {
          lat: 32.98169,
          lon: -97.02657,
        },
        quantity: {
          volume: -60,
          weight: -20,
        },
      },
      {
        id: "order-33-dropoff",
        location: {
          lat: 32.72714,
          lon: -96.83755,
        },
        quantity: {
          volume: -90,
          weight: -5,
        },
      },
      {
        id: "order-34-dropoff",
        location: {
          lat: 32.74498,
          lon: -96.83107,
        },
        quantity: {
          volume: -230,
          weight: -25,
        },
      },
      {
        id: "order-35-dropoff-express",
        late_arrival_time_penalty: 10,
        location: {
          lat: 32.81226,
          lon: -96.75898,
        },
        quantity: {
          volume: -85,
          weight: -15,
        },
        target_arrival_time: "2021-06-10T08:30:00Z",
        unplanned_penalty: 200000,
      },
      {
        id: "order-36-dropoff",
        location: {
          lat: 32.86877,
          lon: -96.86107,
        },
        quantity: {
          volume: -180,
          weight: -25,
        },
      },
      {
        id: "order-37-dropoff",
        location: {
          lat: 32.86909,
          lon: -96.87548,
        },
        quantity: {
          volume: -125,
          weight: -25,
        },
      },
      {
        id: "order-38-dropoff",
        location: {
          lat: 32.85327,
          lon: -96.84767,
        },
        quantity: {
          volume: -100,
          weight: -15,
        },
      },
      {
        id: "order-39-dropoff-express",
        late_arrival_time_penalty: 10,
        location: {
          lat: 32.86066,
          lon: -97.11454,
        },
        quantity: {
          volume: -175,
          weight: -5,
        },
        target_arrival_time: "2021-06-10T08:30:00Z",
        unplanned_penalty: 200000,
      },
      {
        id: "order-40-dropoff",
        location: {
          lat: 32.80261,
          lon: -97.09216,
        },
        quantity: {
          volume: -210,
          weight: -25,
        },
      },
      {
        id: "order-41-dropoff-express",
        late_arrival_time_penalty: 10,
        location: {
          lat: 32.7878,
          lon: -96.98874,
        },
        quantity: {
          volume: -185,
          weight: -25,
        },
        target_arrival_time: "2021-06-10T08:30:00Z",
        unplanned_penalty: 200000,
      },
      {
        id: "order-42-dropoff",
        location: {
          lat: 32.79869,
          lon: -97.00073,
        },
        quantity: {
          volume: -245,
          weight: -25,
        },
      },
      {
        id: "order-43-dropoff",
        location: {
          lat: 32.80223,
          lon: -96.95503,
        },
        quantity: {
          volume: -250,
          weight: -10,
        },
      },
      {
        id: "order-44-dropoff",
        location: {
          lat: 32.75588,
          lon: -96.95133,
        },
        quantity: {
          volume: -80,
          weight: -10,
        },
      },
      {
        id: "order-45-dropoff-express",
        late_arrival_time_penalty: 10,
        location: {
          lat: 32.67599,
          lon: -97.24524,
        },
        quantity: {
          volume: -190,
          weight: -5,
        },
        target_arrival_time: "2021-06-10T08:30:00Z",
        unplanned_penalty: 200000,
      },
      {
        id: "order-46-dropoff",
        location: {
          lat: 32.69514,
          lon: -97.17783,
        },
        quantity: {
          volume: -65,
          weight: -15,
        },
      },
      {
        id: "order-47-dropoff",
        location: {
          lat: 32.96625,
          lon: -96.99328,
        },
        quantity: {
          volume: -65,
          weight: -15,
        },
      },
      {
        id: "order-48-dropoff",
        location: {
          lat: 32.95493,
          lon: -96.99882,
        },
        quantity: {
          volume: -175,
          weight: -10,
        },
      },
      {
        id: "order-49-dropoff",
        location: {
          lat: 32.62739,
          lon: -97.11088,
        },
        quantity: {
          volume: -215,
          weight: -5,
        },
      },
      {
        id: "order-50-dropoff",
        location: {
          lat: 32.64435,
          lon: -97.11645,
        },
        quantity: {
          volume: -120,
          weight: -20,
        },
      },
      {
        id: "order-51-dropoff",
        location: {
          lat: 32.81613,
          lon: -97.03959,
        },
        quantity: {
          volume: -245,
          weight: -25,
        },
      },
      {
        id: "order-52-dropoff",
        location: {
          lat: 32.84717,
          lon: -97.07473,
        },
        quantity: {
          volume: -220,
          weight: -20,
        },
      },
      {
        id: "order-53-dropoff",
        location: {
          lat: 32.8013,
          lon: -96.74741,
        },
        quantity: {
          volume: -140,
          weight: -25,
        },
      },
      {
        id: "order-54-dropoff-express",
        late_arrival_time_penalty: 10,
        location: {
          lat: 32.81076,
          lon: -96.72232,
        },
        quantity: {
          volume: -205,
          weight: -10,
        },
        target_arrival_time: "2021-06-10T08:30:00Z",
        unplanned_penalty: 200000,
      },
      {
        id: "order-55-dropoff",
        location: {
          lat: 32.84465,
          lon: -96.97696,
        },
        quantity: {
          volume: -200,
          weight: -25,
        },
      },
      {
        id: "order-56-dropoff",
        location: {
          lat: 32.85079,
          lon: -96.95654,
        },
        quantity: {
          volume: -190,
          weight: -5,
        },
      },
      {
        id: "order-57-dropoff",
        location: {
          lat: 32.77299,
          lon: -97.05679,
        },
        quantity: {
          volume: -190,
          weight: -20,
        },
      },
      {
        id: "order-58-dropoff",
        location: {
          lat: 32.7977,
          lon: -97.04503,
        },
        quantity: {
          volume: -175,
          weight: -5,
        },
      },
      {
        id: "order-59-dropoff-express",
        late_arrival_time_penalty: 10,
        location: {
          lat: 32.67237,
          lon: -96.88061,
        },
        quantity: {
          volume: -55,
          weight: -25,
        },
        target_arrival_time: "2021-06-10T08:30:00Z",
        unplanned_penalty: 200000,
      },
      {
        id: "order-60-dropoff",
        location: {
          lat: 32.67114,
          lon: -96.89393,
        },
        quantity: {
          volume: -240,
          weight: -5,
        },
      },
    ],
    vehicles: [
      {
        capacity: {
          volume: 1500,
          weight: 200,
        },
        id: "vehicle-1",
      },
      {
        capacity: {
          volume: 1500,
          weight: 200,
        },
        id: "vehicle-2",
      },
      {
        capacity: {
          volume: 1500,
          weight: 200,
        },
        id: "vehicle-3",
      },
      {
        capacity: {
          volume: 2500,
          weight: 350,
        },
        id: "vehicle-4",
      },
      {
        capacity: {
          volume: 2500,
          weight: 350,
        },
        id: "vehicle-5",
      },
      {
        capacity: {
          volume: 2500,
          weight: 350,
        },
        id: "vehicle-6",
      },
      {
        capacity: {
          volume: 1500,
          weight: 200,
        },
        id: "vehicle-7",
      },
    ],
  },
};

export const nextrouteDemoInputSourcing: MarketplaceAppDemoData = {
  id: nextrouteDemoIdSourcing,
  description:
    "Sourcing routing example with small number of stops and vehicles. Solver duration set to 3 seconds.",
  data: {
    defaults: {
      stops: {
        unplanned_penalty: 100000,
      },
      vehicles: {
        end_location: {
          lat: 32.80875,
          lon: -96.87215,
        },
        end_time: "2021-06-10T16:00:00Z",
        speed: 12,
        start_time: "2021-06-10T08:00:00Z",
      },
    },
    stops: [
      {
        id: "order-1-pickup",
        location: {
          lat: 32.67764,
          lon: -96.8024,
        },
        quantity: -15,
      },
      {
        id: "order-2-pickup",
        location: {
          lat: 32.7098,
          lon: -96.84663,
        },
        quantity: -10,
      },
      {
        id: "order-3-pickup",
        location: {
          lat: 32.81559,
          lon: -96.78486,
        },
        quantity: -20,
      },
      {
        id: "order-4-pickup",
        location: {
          lat: 32.83997,
          lon: -96.81313,
        },
        quantity: -20,
      },
      {
        id: "order-5-pickup",
        location: {
          lat: 32.73941,
          lon: -96.65497,
        },
        quantity: -20,
      },
      {
        id: "order-6-pickup",
        location: {
          lat: 32.7274,
          lon: -96.6335,
        },
        quantity: -20,
      },
      {
        id: "order-7-pickup",
        location: {
          lat: 32.77418,
          lon: -96.67937,
        },
        quantity: -20,
      },
      {
        id: "order-8-pickup",
        location: {
          lat: 32.7274,
          lon: -96.6335,
        },
        quantity: -20,
      },
      {
        id: "order-9-pickup",
        location: {
          lat: 32.77418,
          lon: -96.67937,
        },
        quantity: -20,
      },
      {
        id: "order-10-pickup",
        location: {
          lat: 32.77412,
          lon: -96.69089,
        },
        quantity: -10,
      },
    ],
    vehicles: [
      {
        capacity: 75,
        id: "vehicle-1",
      },
      {
        capacity: 100,
        id: "vehicle-2",
      },
    ],
  },
};

export const nextrouteDemoInputSourcingAdvanced: MarketplaceAppDemoData = {
  id: nextrouteDemoIdSourcingAdvanced,
  description:
    "Sourcing routing example with more stops, vehicles, and constraint examples. Solver duration set to 3 seconds.",
  data: {
    defaults: {
      stops: {
        duration: 120,
        unplanned_penalty: 100000,
      },
      vehicles: {
        end_location: {
          lat: 32.80875,
          lon: -96.87215,
        },
        end_time: "2021-06-10T16:00:00Z",
        max_duration: 14400,
        max_stops: 10,
        speed: 14,
        start_time: "2021-06-10T08:00:00Z",
      },
    },
    stops: [
      {
        id: "order-1-pickup",
        location: {
          lat: 32.65764,
          lon: -96.7524,
        },
        quantity: {
          volume: -155,
          weight: -15,
        },
        start_time_window: ["2021-06-10T10:00:00Z", "2021-06-10T13:00:00Z"],
      },
      {
        id: "order-2-pickup",
        location: {
          lat: 32.6498,
          lon: -96.82663,
        },
        quantity: {
          volume: -220,
          weight: -10,
        },
        start_time_window: ["2021-06-10T10:00:00Z", "2021-06-10T13:00:00Z"],
      },
      {
        id: "order-3-pickup",
        location: {
          lat: 32.8922,
          lon: -96.9925,
        },
        quantity: {
          volume: -195,
          weight: -15,
        },
      },
      {
        id: "order-4-pickup",
        location: {
          lat: 32.89166,
          lon: -96.99433,
        },
        quantity: {
          volume: -175,
          weight: -5,
        },
      },
      {
        id: "order-5-pickup",
        location: {
          lat: 32.81559,
          lon: -96.88486,
        },
        quantity: {
          volume: -180,
          weight: -20,
        },
        start_time_window: ["2021-06-10T10:00:00Z", "2021-06-10T16:00:00Z"],
      },
      {
        id: "order-6-pickup",
        location: {
          lat: 32.80997,
          lon: -96.85313,
        },
        quantity: {
          volume: -225,
          weight: -20,
        },
        start_time_window: ["2021-06-10T08:00:00Z", "2021-06-10T11:00:00Z"],
      },
      {
        id: "order-7-pickup",
        location: {
          lat: 32.73941,
          lon: -96.58497,
        },
        quantity: {
          volume: -245,
          weight: -20,
        },
      },
      {
        id: "order-8-pickup",
        location: {
          lat: 32.7274,
          lon: -96.6335,
        },
        quantity: {
          volume: -170,
          weight: -20,
        },
      },
      {
        id: "order-9-pickup",
        location: {
          lat: 32.77418,
          lon: -96.67937,
        },
        quantity: {
          volume: -90,
          weight: -20,
        },
      },
      {
        id: "order-10-pickup",
        location: {
          lat: 32.77412,
          lon: -96.69089,
        },
        quantity: {
          volume: -185,
          weight: -10,
        },
      },
      {
        id: "order-11-pickup",
        location: {
          lat: 32.66673,
          lon: -97.09611,
        },
        quantity: {
          volume: -155,
          weight: -15,
        },
      },
      {
        id: "order-12-pickup",
        location: {
          lat: 32.69165,
          lon: -97.06902,
        },
        quantity: {
          volume: -180,
          weight: -15,
        },
      },
      {
        id: "order-13-pickup",
        location: {
          lat: 32.57605,
          lon: -96.9157,
        },
        quantity: {
          volume: -135,
          weight: -25,
        },
        start_time_window: ["2021-06-10T08:30:00Z", "2021-06-10T11:30:00Z"],
      },
      {
        id: "order-14-pickup",
        location: {
          lat: 32.57542,
          lon: -96.83226,
        },
        quantity: {
          volume: -95,
          weight: -20,
        },
      },
      {
        id: "order-15-pickup",
        location: {
          lat: 32.75759,
          lon: -97.02575,
        },
        quantity: {
          volume: -80,
          weight: -10,
        },
        start_time_window: ["2021-06-10T10:00:00Z", "2021-06-10T16:00:00Z"],
      },
      {
        id: "order-16-pickup",
        location: {
          lat: 32.75106,
          lon: -97.00757,
        },
        quantity: {
          volume: -145,
          weight: -20,
        },
      },
      {
        id: "order-17-pickup",
        location: {
          lat: 32.90896,
          lon: -97.26953,
        },
        quantity: {
          volume: -70,
          weight: -5,
        },
      },
      {
        id: "order-18-pickup",
        location: {
          lat: 32.87165,
          lon: -97.27819,
        },
        quantity: {
          volume: -110,
          weight: -25,
        },
      },
      {
        id: "order-19-pickup",
        location: {
          lat: 32.8335,
          lon: -96.91767,
        },
        quantity: {
          volume: -180,
          weight: -15,
        },
      },
      {
        id: "order-20-pickup",
        location: {
          lat: 32.81456,
          lon: -96.96781,
        },
        quantity: {
          volume: -60,
          weight: -15,
        },
      },
      {
        id: "order-21-pickup",
        location: {
          lat: 32.6914,
          lon: -97.14119,
        },
        quantity: {
          volume: -230,
          weight: -10,
        },
        start_time_window: ["2021-06-10T08:00:00Z", "2021-06-10T11:00:00Z"],
      },
      {
        id: "order-22-pickup",
        location: {
          lat: 32.7049,
          lon: -97.14001,
        },
        quantity: {
          volume: -225,
          weight: -25,
        },
      },
      {
        id: "order-23-pickup",
        location: {
          lat: 32.81572,
          lon: -96.91338,
        },
        quantity: {
          volume: -205,
          weight: -15,
        },
        start_time_window: ["2021-06-10T10:00:00Z", "2021-06-10T13:00:00Z"],
      },
      {
        id: "order-24-pickup",
        location: {
          lat: 32.78629,
          lon: -96.89299,
        },
        quantity: {
          volume: -125,
          weight: -10,
        },
        start_time_window: ["2021-06-10T10:00:00Z", "2021-06-10T13:00:00Z"],
      },
      {
        id: "order-25-pickup",
        location: {
          lat: 32.78366,
          lon: -97.06819,
        },
        quantity: {
          volume: -185,
          weight: -10,
        },
      },
      {
        id: "order-26-pickup",
        location: {
          lat: 32.74745,
          lon: -97.07571,
        },
        quantity: {
          volume: -65,
          weight: -20,
        },
      },
      {
        id: "order-27-pickup",
        location: {
          lat: 32.73423,
          lon: -97.10771,
        },
        quantity: {
          volume: -50,
          weight: -10,
        },
      },
      {
        id: "order-28-pickup",
        location: {
          lat: 32.74378,
          lon: -97.11424,
        },
        quantity: {
          volume: -80,
          weight: -25,
        },
      },
      {
        id: "order-29-pickup",
        location: {
          lat: 32.76309,
          lon: -96.80497,
        },
        quantity: {
          volume: -145,
          weight: -15,
        },
        start_time_window: ["2021-06-10T09:00:00Z", "2021-06-10T12:00:00Z"],
      },
      {
        id: "order-30-pickup",
        location: {
          lat: 32.76119,
          lon: -96.76166,
        },
        quantity: {
          volume: -160,
          weight: -20,
        },
        start_time_window: ["2021-06-10T09:00:00Z", "2021-06-10T12:00:00Z"],
      },
      {
        id: "order-31-pickup",
        location: {
          lat: 32.99633,
          lon: -97.07549,
        },
        quantity: {
          volume: -80,
          weight: -20,
        },
      },
      {
        id: "order-32-pickup",
        location: {
          lat: 32.98169,
          lon: -97.02657,
        },
        quantity: {
          volume: -60,
          weight: -20,
        },
      },
      {
        id: "order-33-pickup",
        location: {
          lat: 32.72714,
          lon: -96.83755,
        },
        quantity: {
          volume: -90,
          weight: -5,
        },
      },
      {
        id: "order-34-pickup",
        location: {
          lat: 32.74498,
          lon: -96.83107,
        },
        quantity: {
          volume: -130,
          weight: -25,
        },
      },
      {
        id: "order-35-pickup",
        location: {
          lat: 32.81226,
          lon: -96.75898,
        },
        quantity: {
          volume: -85,
          weight: -15,
        },
        start_time_window: ["2021-06-10T10:00:00Z", "2021-06-10T16:00:00Z"],
      },
      {
        id: "order-36-pickup",
        location: {
          lat: 32.86877,
          lon: -96.86107,
        },
        quantity: {
          volume: -180,
          weight: -25,
        },
      },
      {
        id: "order-37-pickup",
        location: {
          lat: 32.86909,
          lon: -96.87548,
        },
        quantity: {
          volume: -125,
          weight: -25,
        },
      },
      {
        id: "order-38-pickup",
        location: {
          lat: 32.85327,
          lon: -96.84767,
        },
        quantity: {
          volume: -100,
          weight: -15,
        },
      },
      {
        id: "order-39-pickup",
        location: {
          lat: 32.86066,
          lon: -97.11454,
        },
        quantity: {
          volume: -175,
          weight: -5,
        },
        start_time_window: ["2021-06-10T10:00:00Z", "2021-06-10T13:00:00Z"],
      },
      {
        id: "order-40-pickup",
        location: {
          lat: 32.80261,
          lon: -97.09216,
        },
        quantity: {
          volume: -110,
          weight: -25,
        },
        start_time_window: ["2021-06-10T10:00:00Z", "2021-06-10T13:00:00Z"],
      },
      {
        id: "order-41-pickup",
        location: {
          lat: 32.7878,
          lon: -96.98874,
        },
        quantity: {
          volume: -105,
          weight: -25,
        },
        start_time_window: ["2021-06-10T10:00:00Z", "2021-06-10T13:00:00Z"],
      },
      {
        id: "order-42-pickup",
        location: {
          lat: 32.79869,
          lon: -97.00073,
        },
        quantity: {
          volume: -245,
          weight: -25,
        },
        start_time_window: ["2021-06-10T10:00:00Z", "2021-06-10T13:00:00Z"],
      },
      {
        id: "order-43-pickup",
        location: {
          lat: 32.80223,
          lon: -96.95503,
        },
        quantity: {
          volume: -150,
          weight: -10,
        },
      },
      {
        id: "order-44-pickup",
        location: {
          lat: 32.75588,
          lon: -96.95133,
        },
        quantity: {
          volume: -80,
          weight: -10,
        },
      },
      {
        id: "order-45-pickup",
        location: {
          lat: 32.67599,
          lon: -97.24524,
        },
        quantity: {
          volume: -130,
          weight: -5,
        },
        start_time_window: ["2021-06-10T10:00:00Z", "2021-06-10T13:00:00Z"],
      },
      {
        id: "order-46-pickup",
        location: {
          lat: 32.69514,
          lon: -97.17783,
        },
        quantity: {
          volume: -65,
          weight: -15,
        },
        start_time_window: ["2021-06-10T10:00:00Z", "2021-06-10T13:00:00Z"],
      },
      {
        id: "order-47-pickup",
        location: {
          lat: 32.96625,
          lon: -96.99328,
        },
        quantity: {
          volume: -65,
          weight: -15,
        },
      },
      {
        id: "order-48-pickup",
        location: {
          lat: 32.95493,
          lon: -96.99882,
        },
        quantity: {
          volume: -75,
          weight: -10,
        },
      },
      {
        id: "order-49-pickup",
        location: {
          lat: 32.62739,
          lon: -97.11088,
        },
        quantity: {
          volume: -115,
          weight: -5,
        },
      },
      {
        id: "order-50-pickup",
        location: {
          lat: 32.64435,
          lon: -97.11645,
        },
        quantity: {
          volume: -120,
          weight: -20,
        },
      },
      {
        id: "order-51-pickup",
        location: {
          lat: 32.81613,
          lon: -97.03959,
        },
        quantity: {
          volume: -145,
          weight: -25,
        },
      },
      {
        id: "order-52-pickup",
        location: {
          lat: 32.84717,
          lon: -97.07473,
        },
        quantity: {
          volume: -120,
          weight: -20,
        },
      },
      {
        id: "order-53-pickup",
        location: {
          lat: 32.8013,
          lon: -96.74741,
        },
        quantity: {
          volume: -140,
          weight: -25,
        },
      },
      {
        id: "order-54-pickup",
        location: {
          lat: 32.81076,
          lon: -96.72232,
        },
        quantity: {
          volume: -105,
          weight: -10,
        },
      },
      {
        id: "order-55-pickup",
        location: {
          lat: 32.84465,
          lon: -96.97696,
        },
        quantity: {
          volume: -200,
          weight: -25,
        },
      },
      {
        id: "order-56-pickup",
        location: {
          lat: 32.85079,
          lon: -96.95654,
        },
        quantity: {
          volume: -190,
          weight: -5,
        },
      },
      {
        id: "order-57-pickup",
        location: {
          lat: 32.77299,
          lon: -97.05679,
        },
        quantity: {
          volume: -190,
          weight: -20,
        },
      },
      {
        id: "order-58-pickup",
        location: {
          lat: 32.7977,
          lon: -97.04503,
        },
        quantity: {
          volume: -175,
          weight: -5,
        },
      },
      {
        id: "order-59-pickup",
        location: {
          lat: 32.67237,
          lon: -96.88061,
        },
        quantity: {
          volume: -55,
          weight: -25,
        },
        start_time_window: ["2021-06-10T10:00:00Z", "2021-06-10T13:00:00Z"],
      },
      {
        id: "order-60-pickup",
        location: {
          lat: 32.67114,
          lon: -96.89393,
        },
        quantity: {
          volume: -140,
          weight: -5,
        },
      },
      {
        id: "order-61-pickup",
        location: {
          lat: 32.88471,
          lon: -96.93992,
        },
        quantity: {
          volume: -150,
          weight: -5,
        },
      },
      {
        id: "order-62-pickup",
        location: {
          lat: 32.8722,
          lon: -96.95648,
        },
        quantity: {
          volume: -110,
          weight: -10,
        },
      },
      {
        id: "order-63-pickup",
        location: {
          lat: 32.88443,
          lon: -96.74674,
        },
        quantity: {
          volume: -115,
          weight: -20,
        },
        start_time_window: ["2021-06-10T09:00:00Z", "2021-06-10T12:00:00Z"],
      },
      {
        id: "order-64-pickup",
        location: {
          lat: 32.8504,
          lon: -96.67467,
        },
        quantity: {
          volume: -145,
          weight: -5,
        },
        start_time_window: ["2021-06-10T09:00:00Z", "2021-06-10T12:00:00Z"],
      },
      {
        id: "order-65-pickup",
        location: {
          lat: 32.81436,
          lon: -96.91845,
        },
        quantity: {
          volume: -245,
          weight: -10,
        },
      },
      {
        id: "order-66-pickup",
        location: {
          lat: 32.83185,
          lon: -96.89822,
        },
        quantity: {
          volume: -235,
          weight: -10,
        },
      },
      {
        id: "order-67-pickup",
        location: {
          lat: 32.80744,
          lon: -97.03203,
        },
        quantity: {
          volume: -60,
          weight: -20,
        },
      },
      {
        id: "order-68-pickup",
        location: {
          lat: 32.84806,
          lon: -96.99126,
        },
        quantity: {
          volume: -150,
          weight: -10,
        },
      },
      {
        id: "order-69-pickup",
        location: {
          lat: 32.74112,
          lon: -96.84762,
        },
        quantity: {
          volume: -125,
          weight: -5,
        },
        start_time_window: ["2021-06-10T09:00:00Z", "2021-06-10T12:00:00Z"],
      },
      {
        id: "order-70-pickup",
        location: {
          lat: 32.75009,
          lon: -96.83339,
        },
        quantity: {
          volume: -205,
          weight: -5,
        },
        start_time_window: ["2021-06-10T09:00:00Z", "2021-06-10T12:00:00Z"],
      },
    ],
    vehicles: [
      {
        capacity: {
          volume: 1500,
          weight: 650,
        },
        id: "vehicle-1",
      },
      {
        capacity: {
          volume: 2500,
          weight: 450,
        },
        id: "vehicle-2",
      },
      {
        capacity: {
          volume: 1500,
          weight: 500,
        },
        id: "vehicle-3",
      },
      {
        capacity: {
          volume: 2500,
          weight: 350,
        },
        id: "vehicle-4",
      },
      {
        capacity: {
          volume: 2500,
          weight: 350,
        },
        id: "vehicle-5",
      },
      {
        capacity: {
          volume: 2500,
          weight: 350,
        },
        id: "vehicle-6",
      },
      {
        capacity: {
          volume: 2500,
          weight: 800,
        },
        id: "vehicle-7",
      },
    ],
  },
};
