import { useCallback } from "react";
import { Auth } from "aws-amplify";

import { getToken } from "./cognito";

export const useFetchWithToken = (baseUrl: string, options?: object) => {
  const fetchWithToken = useCallback(
    async function (
      path: string,
      method: string = "GET",
      body?: string
    ): Promise<Response | undefined> {
      let token = await getToken();
      if (!token) {
        // use signOut to clear the userReducer in AuthProvider,
        // which triggers a redirect to the /login screen
        await Auth.signOut();
        throw new Error("User session has expired");
      }
      const resp = await fetch(`${baseUrl}${path}`, {
        method,
        body,
        headers: {
          Authorization: `Bearer ${token}`,
          ...options,
        },
      });

      if (resp?.status === 401) {
        window.location.href = "/?login";
        // This is a bit of a hack but it'll keep this from ever resolving, which feels easier
        // than returning something that forces the caller to deal with this situation
        return new Promise((): Response | undefined => {
          return undefined;
        });
      }

      return resp;
    },
    [baseUrl, options]
  );
  return fetchWithToken;
};
