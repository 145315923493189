import { SortDirection } from "@tanstack/react-table";

import { rem } from "../../../lib/tools";
import {
  GetTableContainerMaxWidthParams,
  GetTableStylesParams,
  TableLayoutSchema,
} from "../Table2.types";

export const getTableContainerMaxWidth = ({
  isWide,
  layoutSchema,
  size,
}: GetTableContainerMaxWidthParams) => {
  if (!layoutSchema || isWide) return "none";
  // must use number values and not design token spacing rem values
  const tableCellPaddingXValue = size === "small" ? 12 : 16;
  const layoutTotalWidth: number = layoutSchema.reduce((acc, curr) => {
    if (curr.width) {
      // adds table cell left/right padding and border width to total
      return (
        acc + curr.width + tableCellPaddingXValue + tableCellPaddingXValue + 2
      );
    }
    return acc;
  }, 0);

  // a hack to return 100% if layout schema widths are specified as percentages
  if (typeof layoutTotalWidth !== "number") {
    return "100%";
  }

  return rem(layoutTotalWidth);
};

export const getStyledTableMinWidth = (
  layoutSchema?: TableLayoutSchema[],
  isWide?: boolean
) => {
  if (!layoutSchema || isWide) return "100%";
  const layoutTotalWidth: number = layoutSchema.reduce((acc, curr) => {
    if (curr.width) {
      return acc + curr.width;
    }
    return acc;
  }, 0);

  // a hack to return 100% if layout schema widths are specified as percentages
  if (typeof layoutTotalWidth !== "number") {
    return "100%";
  }

  return rem(layoutTotalWidth);
};

export const getBackgroundImageXml = (sortOrder: SortDirection | false) => {
  if (!sortOrder) {
    return `url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath opacity='0.4' fill-rule='evenodd' clip-rule='evenodd' d='M5.03033 5.46964L4.5 5.99997L5.56066 7.06063L6.09099 6.5303L7.96066 4.66063L9.83033 6.5303L10.3607 7.06063L11.4213 5.99997L10.891 5.46964L8.49099 3.06964L7.96066 2.53931L7.43033 3.06964L5.03033 5.46964ZM10.891 10.5303L11.4213 10L10.3607 8.93934L9.83033 9.46967L7.96066 11.3393L6.09099 9.46967L5.56066 8.93934L4.5 10L5.03033 10.5303L7.43033 12.9303L7.96066 13.4607L8.49099 12.9303L10.891 10.5303Z' fill='%23464C4D'/%3e %3c/svg%3e");`;
  }

  if (sortOrder === "asc") {
    return `url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath opacity='0.4' fill-rule='evenodd' clip-rule='evenodd' d='M11.4213 9.99999L7.96065 13.4607L4.49999 9.99999L5.56065 8.93933L7.96065 11.3393L10.3607 8.93933L11.4213 9.99999Z' fill='%23464C4D'/%3e %3cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.5 6.00001L7.96066 2.53935L11.4213 6.00001L10.3607 7.06067L7.96066 4.66067L5.56066 7.06067L4.5 6.00001Z' fill='%23464C4D'/%3e %3c/svg%3e");`;
  }

  if (sortOrder === "desc") {
    return `url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.4213 9.99999L7.96065 13.4607L4.49999 9.99999L5.56065 8.93933L7.96065 11.3393L10.3607 8.93933L11.4213 9.99999Z' fill='%23464C4D'/%3e %3cpath opacity='0.4' fill-rule='evenodd' clip-rule='evenodd' d='M4.5 6.00001L7.96066 2.53935L11.4213 6.00001L10.3607 7.06067L7.96066 4.66067L5.56066 7.06067L4.5 6.00001Z' fill='%23464C4D'/%3e %3c/svg%3e");`;
  }
};

export const getPaddingXValue = ({ size, theme }: GetTableStylesParams) => {
  return size === "small" ? theme.spacing.s3 : theme.spacing.s4;
};
