import { TabLinks } from "@console/dsc/src/components/Tabs/Tabs.types";

import { RunInputResponse } from "../../../../../api/core/controlPlane.types";
import { PanelViewType } from "../RunDetails.types";

type GetResultTabs = {
  hasOutput: boolean;
  panelView: PanelViewType;
  setPanelView: React.Dispatch<React.SetStateAction<PanelViewType>>;
  hideVehicles?: boolean;
  hideInput?: boolean;
  runInput?: RunInputResponse;
};

export const getResultTabs = ({
  hasOutput,
  hideVehicles,
  hideInput,
  panelView,
  setPanelView,
  runInput,
}: GetResultTabs): TabLinks => {
  return [
    ...(!hideVehicles
      ? [
          {
            id: "tab-vehicles",
            label: "Vehicles",
            isActive: panelView === "vehicles",
            onClick: (e: {
              preventDefault: () => void;
              stopPropagation: () => void;
            }) => {
              e.preventDefault();
              e.stopPropagation();
              return setPanelView("vehicles");
            },
          },
        ]
      : []),
    ...(!hideInput
      ? [
          {
            id: "tab-input",
            label: "Input",
            isActive: panelView === "input",
            isDisabled: !runInput,
            onClick: (e: {
              preventDefault: () => void;
              stopPropagation: () => void;
            }) => {
              e.preventDefault();
              e.stopPropagation();
              return setPanelView("input");
            },
          },
        ]
      : []),
    {
      id: "tab-output",
      label: "Output",
      isActive: panelView === "output",
      isDisabled: !hasOutput,
      onClick: (e: {
        preventDefault: () => void;
        stopPropagation: () => void;
      }) => {
        e.preventDefault();
        e.stopPropagation();
        return setPanelView("output");
      },
    },
  ];
};
