import {
  ActionGroups,
  BaseRouteToActionGroups,
  PolicyRoleMapping,
  Roles,
  RouteToActionGroup,
} from "./types";

// maintained in this codebase for now.
export const appRoutesToActionGroup: RouteToActionGroup = {
  "/app/:id": ActionGroups.AppViewer,
  "/app/:id/edit": ActionGroups.AppOperator,
  "/app/:id/versions": ActionGroups.AppViewer,
  "/app/:id/runs": ActionGroups.RunViewer,
  "/app/:id/runs/new": ActionGroups.RunOperator,
  "/app/:id/run/:id": ActionGroups.RunViewer,
  "/app/:id/run/:id/edit": ActionGroups.RunOperator,
  "/app/:id/api-reference": ActionGroups.AppViewer,
  "/app/:id/versions/new": ActionGroups.AppVersionOperator,
  "/app/:id/version/:id": ActionGroups.AppViewer,
  "/app/:id/version/:id/edit": ActionGroups.AppVersionOperator,
  "/app/:id/instances": ActionGroups.AppViewer,
  "/app/:id/instances/new": ActionGroups.AppInstanceOperator,
  "/app/:id/instance/:id": ActionGroups.AppViewer,
  "/app/:id/instance/:id/edit": ActionGroups.AppInstanceOperator,
  "/app/:id/experiments/batch": ActionGroups.ExperimentViewer,
  "/app/:id/experiments/batch/new": ActionGroups.ExperimentOperator,
  "/app/:id/experiments/batch/:id": ActionGroups.ExperimentViewer,
  "/app/:id/experiments/shadow": ActionGroups.ExperimentViewer,
  "/app/:id/experiments/shadow/new": ActionGroups.ExperimentOperator,
  "/app/:id/experiments/shadow/:id": ActionGroups.ExperimentViewer,
  "/app/:id/experiments/switchback": ActionGroups.ExperimentViewer,
  "/app/:id/experiments/switchback/new": ActionGroups.ExperimentOperator,
  "/app/:id/experiments/switchback/:id": ActionGroups.ExperimentViewer,
  "/app/:id/experiments/acceptance": ActionGroups.ExperimentViewer,
  "/app/:id/experiments/acceptance/new": ActionGroups.ExperimentOperator,
  "/app/:id/experiments/acceptance/:id": ActionGroups.ExperimentViewer,
  "/app/:id/experiments/input-sets": ActionGroups.InputSetViewer,
  "/app/:id/experiments/input-sets/new": ActionGroups.InputSetOperator,
  "/app/:id/experiments/input-set/:id": ActionGroups.InputSetViewer,
  "/app/:id/experiments/input-set/:id/edit": ActionGroups.InputSetOperator,
  "/app/:id/experiments/inputs": ActionGroups.InputSetViewer,
  "/app/:id/experiments/inputs/new": ActionGroups.InputSetOperator,
  "/app/:id/experiments/input/:id": ActionGroups.InputSetViewer,
  "/app/:id/experiments/input/:id/edit": ActionGroups.InputSetOperator,
};

export const appsRoutesToActionGroup: RouteToActionGroup = {
  "/apps": ActionGroups.AppViewer,
  "/apps/new": ActionGroups.AppOperator,
  "/apps/subscribed/new": ActionGroups.AppOperator,
  "/apps/custom": ActionGroups.AppViewer,
  "/apps/subscription": ActionGroups.AppViewer,
};

export const marketplaceRoutesToActionGroup: RouteToActionGroup = {
  "/marketplace": ActionGroups.MarketplaceViewer,
};

export const marketplaceAppRoutesToActionGroup: RouteToActionGroup = {
  "/marketplace/app/:partner/:id": ActionGroups.MarketplaceViewer,
  "/marketplace/app/:partner/:id/demo": ActionGroups.MarketplaceViewer,
  "/marketplace/app/:partner/:id/api-reference": ActionGroups.MarketplaceViewer,
};

export const teamRoutesToActionGroup: RouteToActionGroup = {
  "/team": ActionGroups.OrgView,
  "/team/api-keys": ActionGroups.SecurityOperator,
  "/team/api-keys/new": ActionGroups.SecurityOperator,
  "/team/members": ActionGroups.OrgView,
  "/team/usage": ActionGroups.OrgView,
  "/team/plan": ActionGroups.OrgView,
};

export const baseRouteToActionGroups: BaseRouteToActionGroups = {
  "/app/:id": appRoutesToActionGroup,
  "/apps": appsRoutesToActionGroup,
  "/marketplace": marketplaceRoutesToActionGroup,
  "/marketplace/app/:partner/:id": marketplaceAppRoutesToActionGroup,
  "/team": teamRoutesToActionGroup,
};

// this exists in core deploy and should be updated accordingly
export const roleMapping: PolicyRoleMapping = {
  OrgAdmin: {
    type: "permit",
    roles: [Roles.Admin],
  },
  OrgView: {
    type: "permit",
    roles: [Roles.Viewer, Roles.Operator, Roles.Developer, Roles.Admin],
  },
  SecurityOperator: {
    type: "permit",
    roles: [Roles.Developer, Roles.Admin],
  },
  RunOperator: {
    type: "permit",
    roles: [Roles.Operator, Roles.Developer, Roles.Admin],
  },
  RunViewer: {
    type: "permit",
    roles: [Roles.Viewer, Roles.Operator, Roles.Developer, Roles.Admin],
  },
  AppOperator: {
    type: "permit",
    roles: [Roles.Developer, Roles.Admin],
  },
  AppViewer: {
    type: "permit",
    roles: [Roles.Viewer, Roles.Operator, Roles.Developer, Roles.Admin],
  },
  AppVersionOperator: {
    type: "permit",
    roles: [Roles.Developer, Roles.Admin],
  },
  AppInstanceOperator: {
    type: "permit",
    roles: [Roles.Operator, Roles.Developer, Roles.Admin],
    conditions: {
      Operator: {
        type: "when",
        condition: "!resource.locked",
      },
      Developer: {
        type: "when",
        condition: "!resource.locked",
      },
      Admin: {
        type: "when",
        condition: "!resource.locked",
      },
    },
  },
  AppProductOperator: {
    type: "permit",
    roles: [Roles.Admin],
  },
  ExperimentOperator: {
    type: "permit",
    roles: [Roles.Operator, Roles.Developer, Roles.Admin],
  },
  ExperimentViewer: {
    type: "permit",
    roles: [Roles.Viewer, Roles.Operator, Roles.Developer, Roles.Admin],
  },
  InputSetOperator: {
    type: "permit",
    roles: [Roles.Operator, Roles.Developer, Roles.Admin],
  },
  InputSetViewer: {
    type: "permit",
    roles: [Roles.Viewer, Roles.Operator, Roles.Developer, Roles.Admin],
  },
  MarketplaceViewer: {
    type: "permit",
    roles: [Roles.Viewer, Roles.Operator, Roles.Developer, Roles.Admin],
  },
  MarketplaceOperator: {
    type: "permit",
    roles: [Roles.Admin, Roles.Developer],
  },
  WebhooksOperator: {
    type: "permit",
    roles: [Roles.Developer, Roles.Admin],
  },
  WebhooksViewer: {
    type: "permit",
    roles: [Roles.Operator, Roles.Developer, Roles.Admin],
  },
};
