import { rem } from "./tools";

// default styles for text links
const linkStyle = (theme: any) => `
  margin-bottom: ${rem(-1)};
  padding-bottom: ${rem(1)};
  text-decoration: none;
  color: ${theme.color.chartBrick};
  cursor: pointer;
  border-bottom-width: ${theme.border.width};
  border-bottom-style: ${theme.border.style};
  border-bottom-color: ${theme.color.gray300T};

  strong {
    color: ${theme.color.chartBrick};
  }

  &:visited {
    color: ${theme.color.chartBrick};
  }
  &:hover, &:focus, &:active {
    color: ${theme.color.orange800};
    border-bottom-color: ${theme.color.gray400T};
  }
  &:focus {
    outline: none;
  }
  &:focus-visible {
    outline: none;
    color: ${theme.color.orange800};
    border-bottom-color: ${theme.color.gray400T};
    box-shadow: 0 0 ${theme.spacing.s1} ${rem(1)} ${theme.color.teal400};
  }
`;

const linkStyleGray = (theme: any) => `
  margin-bottom: ${rem(-1)};
  padding-bottom: ${rem(1)};
  text-decoration: none;
  color: ${theme.color.gray600};
  cursor: pointer;

  strong {
    color: ${theme.color.gray600};
  }

  &:visited {
    color: ${theme.color.gray600};
  }
  &:hover, &:focus, &:active {
    color: ${theme.color.gray700};
  }
  &:focus {
    outline: none;
  }
  &:focus-visible {
    outline: none;
    color: ${theme.color.gray700};
    box-shadow: 0 0 ${theme.spacing.s1} ${rem(1)} ${theme.color.gray400};
  }
`;

const linkStyleBlack = (theme: any) => `
  margin-bottom: ${rem(-1)};
  padding-bottom: ${rem(1)};
  text-decoration: none;
  color: ${theme.color.gray800};
  cursor: pointer;
  border-bottom-width: ${theme.border.width};
  border-bottom-style: ${theme.border.style};
  border-bottom-color: ${theme.color.gray300T};

  &:visited {
    color: ${theme.color.gray800};
  }
  &:hover, &:focus, &:active {
    color: ${theme.color.black};
    border-bottom-color: ${theme.color.gray400T};
  }
  &:focus {
    outline: none;
  }
  &:focus-visible {
    outline: none;
    color: ${theme.color.black};
    border-bottom-width: 0;
    border-radius: ${theme.border.radius};
    box-shadow:
      0 0 0 ${theme.border.widthMediumShadowOnly} ${theme.color.gray500T};
  }
`;

const linkStyleText = (theme: any) => `
  ${linkStyleBlack(theme)}

  color: ${theme.color.gray700};
  &:visited {
    color: ${theme.color.gray700};
  }
  &:hover, &:focus, &:active {
    color: ${theme.color.gray800};
  }
  &:focus-visible {
    color: ${theme.color.gray800};
  }
`;

// TODO: need to figure out how to import Theme type (see above)
export const styleLinks = (theme: any) => {
  return `
    a {
      ${linkStyle(theme)}
    }
  `;
};

export const styleAsLink = (theme: any) => {
  return `
    ${linkStyle(theme)}
  `;
};

export const styleLinksGray = (theme: any) => {
  return `
    a {
      ${linkStyleGray(theme)}
    }
  `;
};

export const styleAsLinkGray = (theme: any) => {
  return `
    ${linkStyleGray(theme)}
  `;
};

export const styleLinksBlack = (theme: any) => {
  return `
    a {
      ${linkStyleBlack(theme)}
    }
  `;
};

export const styleLinksText = (theme: any) => {
  return `
    a {
      ${linkStyleText(theme)}
    }
  `;
};
