import { useState } from "react";

import { MapDisplayMode, VisibleVehicleRouteIds } from "../Map.types";
import { MAP_DISPLAY_ROAD } from "../utils/constants";

export type UseMapStateReturnValue = ReturnType<typeof useMapState>;

export default function useMapState() {
  const [mapDisplayMode, setMapDisplayMode] =
    useState<MapDisplayMode>(MAP_DISPLAY_ROAD);
  const [displayVehiclesNoRoutes, setDisplayVehiclesNoRoutes] = useState(false);
  const [focusedRouteId, setFocusedRouteId] = useState("");
  const [visibleVehicleRoutesById, setVisibleVehicleRoutesById] =
    useState<VisibleVehicleRouteIds>({});

  // map menu controls
  const [displayUnassigned, setDisplayUnassigned] = useState(true);
  const [displayDirectionalArrows, setDisplayDirectionalArrows] =
    useState(true);
  const [displayAnnotations, setDisplayAnnotations] = useState(false);

  const resetMapState = () => {
    setMapDisplayMode(MAP_DISPLAY_ROAD);
    setDisplayUnassigned(true);
    setDisplayDirectionalArrows(true);
    setDisplayAnnotations(false);

    // reset vehicles and chart options
    setVisibleVehicleRoutesById({});
    setDisplayVehiclesNoRoutes(false);
  };

  return {
    mapDisplayMode,
    setMapDisplayMode,
    displayVehiclesNoRoutes,
    setDisplayVehiclesNoRoutes,
    focusedRouteId,
    setFocusedRouteId,
    visibleVehicleRoutesById,
    setVisibleVehicleRoutesById,
    displayUnassigned,
    setDisplayUnassigned,
    displayDirectionalArrows,
    setDisplayDirectionalArrows,
    resetMapState,
    displayAnnotations,
    setDisplayAnnotations,
  };
}
