import React, { useMemo } from "react";

import { AppResponse } from "../../../../../api/core/controlPlane.types";
import ControlPanel from "../../../../../components/ControlPanel";
import InstanceSelect from "../../../../../components/InstanceSelect";
import { InstanceSelectInstanceIds } from "../../../../../components/InstanceSelect/InstanceSelect.types";
import { useAppCollection } from "../../../../../contexts/apps/App.context";
import { appTooltips } from "../../../data/appTooltips";

import InstanceDetails from "./InstanceDetails";

interface InstanceSelectionProps {
  app: AppResponse;
  filteredPendingInstanceIds: InstanceSelectInstanceIds;
  pendingInstanceIds: InstanceSelectInstanceIds;
  refreshEditorHeight: () => void;
  setPendingInstanceIds: React.Dispatch<
    React.SetStateAction<InstanceSelectInstanceIds>
  >;
}

const InstanceSelection = ({
  app,
  filteredPendingInstanceIds,
  pendingInstanceIds,
  setPendingInstanceIds,
  refreshEditorHeight,
}: InstanceSelectionProps) => {
  const { instances, instancesError } = useAppCollection();

  const selectedInstance = useMemo(() => {
    if (!instances) return;
    const selectedInstanceId = filteredPendingInstanceIds[0];
    if (selectedInstanceId === "devint") {
      return {
        id: "devint",
        application_id: app.id,
        version_id: "",
        name: "",
        description: "",
      };
    }
    return instances.find(
      (instance) => instance.id === filteredPendingInstanceIds[0]
    );
  }, [app.id, filteredPendingInstanceIds, instances]);

  return (
    <ControlPanel
      testId="create-new-run-instance-details"
      headerTitle="Instance"
      headerTooltipContent={appTooltips.newRunInstance.content}
      isOpen
      onClick={refreshEditorHeight}
    >
      <InstanceSelect
        {...{ app, pendingInstanceIds, setPendingInstanceIds }}
        data-testid="instance-app-select"
        hasDevIntOption={!!app?.active_executable}
        isSingleSelect
      />
      {selectedInstance && !instancesError && (
        <InstanceDetails {...{ app }} instance={selectedInstance} />
      )}
    </ControlPanel>
  );
};
export default InstanceSelection;
