import React, { useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { Box, Input, RowDetail } from "@console/dsc";

import { trackEvent } from "../../../../analytics";
import {
  AppType,
  CreateAppPayload,
  MarketplacePartnerApp,
} from "../../../../api/core/controlPlane.types";
import { useUser } from "../../../../AuthProvider";
import Footer from "../../../../components/Footer";
import Header from "../../../../components/Header";
import { useMetaTitle } from "../../../../components/Layout";
import StandardError from "../../../../components/StandardError";
import { INPUT_WIDTH_STANDARD } from "../../../../config/general";
import { useAppsCollection } from "../../../../contexts/apps/Apps.context";
import useMarketplacePartners from "../../../../contexts/marketplace/hooks/useMarketplacePartners";
import { getSubscriptionId } from "../../../../contexts/marketplace/utils/subscriptionId";
import useManageEntity from "../../../../hooks/useManageEntity";
import useStandardInputs from "../../../../hooks/useStandardInputs";
import { getAccUrl } from "../../../../utils/navigation";
import MarketplaceAppPreview from "../../../App/components/MarketplaceAppPreview";
import useReturnPaths from "../../../App/hooks/useReturnPaths";
import { NewAppProps } from "../../Apps.types";
import { appTooltipCopy } from "../../data/microcopy";

const getPageTitle = (
  appType: AppType,
  marketplaceApp: MarketplacePartnerApp
) => {
  if (appType === "subscription") {
    if (marketplaceApp) {
      return `Create new subscription app (clone ${marketplaceApp?.name})`;
    }
    if (!marketplaceApp) {
      return "Invalid Subscription App";
    }
  }
  return "Create new custom app";
};

const NewApp = ({ appType }: NewAppProps) => {
  const [{ id: accountId }] = useUser();
  const [, setMetaTitle] = useMetaTitle();
  const { returnPath } = useReturnPaths();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const subscriptionId = searchParams.get("subscriptionId");

  const [isProcessing, setIsProcessing] = useState(false);

  const { loadApps } = useAppsCollection();
  const {
    addEntity: addApp,
    entityAddError: appAddError,
    isEntityAdded: isAppAdded,
  } = useManageEntity("applications");

  const {
    loadMarketplacePartnerApp,
    marketplacePartnerApp,
    marketplacePartnerAppError,
  } = useMarketplacePartners();

  const {
    getStandardInputsProps,
    pendingStandardInputs,
    standardInputsErrors,
  } = useStandardInputs();

  const pageTitle = getPageTitle(appType, marketplacePartnerApp);

  // manage page display
  useEffect(() => {
    setMetaTitle(pageTitle);
  }, [pageTitle, setMetaTitle]);

  // load subscription marketplace app preview (if applicable)
  useEffect(() => {
    if (
      !marketplacePartnerApp &&
      !marketplacePartnerAppError &&
      appType === "subscription" &&
      subscriptionId
    ) {
      loadMarketplacePartnerApp(subscriptionId);
    }
  }, [
    appType,
    loadMarketplacePartnerApp,
    marketplacePartnerApp,
    marketplacePartnerAppError,
    subscriptionId,
  ]);

  // disable loading state if add new app error
  useEffect(() => {
    if (appAddError && isProcessing) {
      setIsProcessing(false);
    }
  }, [appAddError, isProcessing]);

  const returnedSubscriptionId = getSubscriptionId(marketplacePartnerApp);

  const cancelPath = subscriptionId
    ? getAccUrl(
        accountId,
        `/marketplace/app/${subscriptionId.split("-").join("/")}`
      )
    : returnPath;

  const goToAppDetails = getAccUrl(
    accountId,
    `/app/${pendingStandardInputs.id}`
  );

  const handleAppCreate = async (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    e.preventDefault();

    setIsProcessing(true);

    let payload: CreateAppPayload = {
      ...pendingStandardInputs,
      type: appType,
      ...(marketplacePartnerApp && {
        subscription_id: returnedSubscriptionId,
      }),
    };

    await addApp("", payload);
  };

  const handleCancel = () => {
    trackEvent("Apps", {
      view: "Create App",
      action: "Create App Canceled",
      meta: {
        type: appType,
        marketplaceAppId: returnedSubscriptionId,
      },
    });
    return;
  };

  if (isAppAdded) {
    trackEvent("Apps", {
      view: "Create App",
      action: "New App Created",
      meta: {
        type: appType,
        marketplaceAppId: returnedSubscriptionId,
      },
    });

    loadApps({});

    return <Redirect to={goToAppDetails} />;
  }

  if (marketplacePartnerAppError) {
    return <StandardError errorMessage={marketplacePartnerAppError} />;
  }

  const isActionButtonDisabled =
    !pendingStandardInputs.name ||
    !pendingStandardInputs.id ||
    !!standardInputsErrors.name ||
    !!standardInputsErrors.id ||
    (appType === "subscription" && !marketplacePartnerApp);

  return (
    <>
      <Header configPageTitle={{ label: pageTitle }} />

      <form onSubmit={(e) => handleAppCreate(e)}>
        <RowDetail
          hasNoBorder
          property="Name"
          secondaryLabel="For reference only"
          render={
            <Box width="100%" maxWidth={INPUT_WIDTH_STANDARD}>
              <Input
                {...getStandardInputsProps({
                  placeholder: "App name",
                  testId: "new-app-name-input",
                  type: "name",
                  trackEventCategory: "Apps",
                  trackEventProperties: {
                    view: "Create App",
                    action: "Field Entered",
                    meta: {
                      field: "name",
                    },
                  },
                })}
              />
            </Box>
          }
        />

        <RowDetail
          property="ID"
          tooltipCopy={appTooltipCopy.id}
          render={
            <Box width="100%" maxWidth={INPUT_WIDTH_STANDARD}>
              <Input
                {...getStandardInputsProps({
                  placeholder: "App ID",
                  testId: "new-app-id-input",
                  type: "id",
                  trackEventCategory: "Apps",
                  trackEventProperties: {
                    view: "Create App",
                    action: "App ID Changed",
                  },
                })}
              />
            </Box>
          }
        />

        <RowDetail
          property="Description"
          secondaryLabel="(optional)"
          render={
            <Box width="100%" maxWidth={INPUT_WIDTH_STANDARD}>
              <Input
                {...getStandardInputsProps({
                  placeholder: "App description",
                  testId: "new-app-description-input",
                  type: "description",
                  trackEventCategory: "Apps",
                  trackEventProperties: {
                    view: "Create App",
                    action: "Field Entered",
                    meta: {
                      field: "description",
                    },
                  },
                })}
              />
            </Box>
          }
        />

        {marketplacePartnerApp && !marketplacePartnerAppError && (
          <RowDetail
            property="Marketplace App"
            tooltipCopy={appTooltipCopy.marketplaceApp}
            render={
              <MarketplaceAppPreview marketplaceApp={marketplacePartnerApp} />
            }
          />
        )}

        <Footer
          actionButtonLabel="Create app"
          endpoint="versions"
          error={appAddError}
          handleCancel={handleCancel}
          handleMainAction={handleAppCreate}
          isActionButtonLoading={isProcessing}
          isActionButtonDisabled={isActionButtonDisabled}
          returnPath={cancelPath}
          returnPathList={goToAppDetails}
          view="create"
        />
      </form>
    </>
  );
};

export default NewApp;
