import { UserOrgKey } from "../../../../api/core/dataPlane.types";

export type KeyDisplayOptions = {
  [key: string]: boolean;
};

export type HandleOptionsMenuParams = {
  e: { preventDefault: () => void };
  apiKey: string;
};

export type NewAPIKeyModalProps = {
  handleAPIKeyForm: (
    e: { preventDefault: () => void },
    keyName: string,
    keyDesc: string
  ) => void;
  addAPIKeyErrorMessage: string;
  teamName: string;
  displayNewAPIKeyModal: boolean;
  exitModal: () => void;
  newAPIKeyProcessing: boolean;
  disableAddKey: boolean;
};

export type APIKeyChangeModalProps = {
  modalInfo: APIChangeModalInfo;
  setDisplayConfirmModal: (
    e: { preventDefault: () => void },
    modalInfo: APIChangeModalInfo | undefined
  ) => void;
  changeKey: (
    e: { preventDefault: () => void },
    modalInfo: APIChangeModalInfo
  ) => void;
  error: string;
};

export enum ModalType {
  Disable = "Disable",
  Enable = "Enable",
  Delete = "Delete",
}

export type APIChangeModalInfo = {
  modalType: ModalType;
  key: UserOrgKey;
};

export type APIKeyProps = {
  keyData: UserOrgKey;
  handleOptionsMenuState: ({ e, apiKey }: HandleOptionsMenuParams) => void;
  displayOptionsMenu: boolean;
  setDisplayConfirmModal: (
    e: { preventDefault: () => void },
    modalInfo: APIChangeModalInfo
  ) => void;
};
