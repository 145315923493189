import { AcceptanceTestMetricOperator } from "../../../api/core/controlPlane.types";

export const EXP_ACC_METRIC_TYPE = "direct-comparison";
export const EXP_ACC_STATISTIC = "mean";

export const EXP_ACC_SENSE_LT = "should decrease";
export const EXP_ACC_SENSE_LE = "should decrease or be equal";
export const EXP_ACC_SENSE_GT = "should increase";
export const EXP_ACC_SENSE_GE = "should increase or be equal";
export const EXP_ACC_SENSE_EQ = "should be equal";
export const EXP_ACC_SENSE_NE = "should not be equal";

export const EMPTY_ACC_METRIC_OPERATOR: keyof AcceptanceTestMetricOperator =
  "ge";

export const BATCH_MAX_INSTANCE_LIMIT = 5;
