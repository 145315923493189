import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Button2,
  Flex,
  IconLock,
  Loading,
  Modal2,
  RowDetail,
  Tag,
  Text,
  Tooltip,
} from "@console/dsc";
import IconLockOpen from "@console/dsc/src/icons/IconLockOpen";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../../../analytics";
import { getOrgKeys } from "../../../../api/core/dataPlane";
import { useUser } from "../../../../AuthProvider";
import Header from "../../../../components/Header";
import { useMetaTitle } from "../../../../components/Layout";
import StandardError from "../../../../components/StandardError";
import Swag from "../../../../components/Swag";
import { MEASURE_ENTITY_ROW } from "../../../../config/general";
import { useAppCollection } from "../../../../contexts/apps/App.context";
import useMarketplacePartners from "../../../../contexts/marketplace/hooks/useMarketplacePartners";
import appsApiSpec from "../../../../spec/open-api-spec-apps.json";
import marketplaceAppsApiSpec from "../../../../spec/open-api-spec-marketplace-apps.json";
import { getAccUrl } from "../../../../utils/navigation";
import { userHasAccessToAction } from "../../../../utils/rbac_utils";
import { ActionGroups } from "../../../../utils/rbac_utils/types";
import { appTooltipCopy } from "../../../Apps/data/microcopy";
import { AppPageProps } from "../../App.types";
import ExecutableDetails from "../../components/ExecutableDetails";
import MarketplaceAppPreview from "../../components/MarketplaceAppPreview";

const pageTitle = "Details";

const AppDetails = ({ app }: AppPageProps) => {
  const [, setMetaTitle] = useMetaTitle();
  const { pathname } = useLocation();
  const theme = useTheme();
  const { appLoadError } = useAppCollection();
  const [{ id: accountId, roles }] = useUser();
  const [apiKey, setApiKey] = useState("");
  const [isApiKeyLoading, setIsApiKeyLoading] = useState(false);
  const [displayApiKeyModal, setDisplayApiKeyModal] = useState(false);

  const {
    loadMarketplacePartnerApp,
    marketplacePartnerApp,
    marketplacePartnerAppError,
  } = useMarketplacePartners();

  // manage page display
  useEffect(() => {
    setMetaTitle(pageTitle);
  }, [setMetaTitle]);

  // load subscription marketplace app preview (if applicable)
  useEffect(() => {
    if (
      !marketplacePartnerApp &&
      !marketplacePartnerAppError &&
      app?.type === "subscription" &&
      app.subscription_id
    ) {
      loadMarketplacePartnerApp(app.subscription_id);
    }
  }, [
    app.subscription_id,
    app?.type,
    loadMarketplacePartnerApp,
    marketplacePartnerApp,
    marketplacePartnerAppError,
  ]);

  const modifiedAppsApiSpec = JSON.parse(
    JSON.stringify(
      app.type === "subscription" ? marketplaceAppsApiSpec : appsApiSpec
    )
      .replace(/{application_id}/gm, app.id)
      .replace(/application_id/gm, app.id)
  );

  const getAPIKey = async () => {
    setIsApiKeyLoading(true);
    try {
      const apiKeys = await getOrgKeys(accountId || "");
      const activeApiKey = apiKeys.find((key) => !key.disabled);
      if (!activeApiKey) {
        setDisplayApiKeyModal(true);
      }
      setApiKey(activeApiKey?.api_key!);
      setIsApiKeyLoading(false);
    } catch (error) {}
  };

  const handleCloseModal = (e: any) => {
    e && e.preventDefault();
    e && e.stopPropagation();
    setDisplayApiKeyModal(false);
  };

  if (appLoadError) {
    return <StandardError errorMessage={appLoadError} />;
  }
  if (!app) {
    return <Loading type="single-page" dotColor={theme.color.orange500} />;
  }

  const userCanEdit = userHasAccessToAction(
    roles,
    ActionGroups.AppOperator,
    app
  );
  const userCanRun = userHasAccessToAction(
    roles,
    ActionGroups.RunOperator,
    app
  );
  const userCanViewAPI = userHasAccessToAction(
    roles,
    ActionGroups.SecurityOperator,
    app
  );

  return (
    <>
      <Header
        configPageTitle={{
          label: pageTitle,
        }}
        configActionButton={{
          label: "Edit details",
          url: `${pathname}/edit`,
          onClick: () =>
            trackEvent("Apps", {
              view: "App",
              action: "Edit Button Clicked",
            }),
          isActionAllowed: userCanEdit,
        }}
      />

      <RowDetail
        hasNoBorder
        property="Name"
        render={
          <Box maxWidth={MEASURE_ENTITY_ROW}>
            <Text styleName="body-2">{app.name}</Text>
          </Box>
        }
      />

      <RowDetail
        property="App ID"
        tooltipCopy={appTooltipCopy.id}
        render={
          <Flex>
            <Tag ml={rem(-6)} type="id" label={app.id} contentToCopy={app.id} />
          </Flex>
        }
      />

      {app?.description && (
        <RowDetail
          property="Description"
          render={
            <Box maxWidth={MEASURE_ENTITY_ROW}>
              <Text styleName="body-2">{app?.description}</Text>
            </Box>
          }
        />
      )}

      <RowDetail
        property="Default Instance ID"
        tooltipCopy={appTooltipCopy.defaultInstanceId.content}
        tooltipExtraLinkLabel={appTooltipCopy.defaultInstanceId.extraLinkLabel}
        tooltipExtraLinkUrl={appTooltipCopy.defaultInstanceId.extraLinkUrl}
        render={
          app.default_instance ? (
            <Flex>
              <Tag
                ml={rem(-6)}
                type="id"
                label={app.default_instance}
                url={getAccUrl(
                  accountId,
                  `/app/${app.id}/instance/${app.default_instance}`
                )}
                contentToCopy={app.default_instance}
              />
            </Flex>
          ) : (
            <Text styleName="body-2" styles={{ color: theme.color.gray600 }}>
              None
            </Text>
          )
        }
      />

      {marketplacePartnerApp && (
        <RowDetail
          property="Marketplace App"
          tooltipCopy={appTooltipCopy.marketplaceApp}
          render={
            <MarketplaceAppPreview marketplaceApp={marketplacePartnerApp} />
          }
        />
      )}

      {app?.type === "custom" && (
        <RowDetail
          property="Latest Pushed Executable"
          tooltipCopy={appTooltipCopy.appExecutable}
          render={<ExecutableDetails executable={app?.active_executable} />}
        />
      )}

      <Flex
        mt={3}
        ml={[-4, -4, -6]}
        mr={[-4, -4, -7]}
        pt={4}
        pr={[4, 4, 7]}
        pb={7}
        pl={[4, 4, 6]}
        background={`linear-gradient(to top, ${theme.color.gray100}, ${theme.color.white})`}
        hasBorderBottom
      >
        {userCanRun && (
          <Button2
            size="large"
            label="Run app"
            to={getAccUrl(accountId, `/app/${app.id}/runs/new`)}
          />
        )}
      </Flex>

      {userCanViewAPI && (
        <>
          <Flex
            mt={[6, 6, 8]}
            justifyContent="space-between"
            alignItems={["flex-direction", "flex-direction", "center"]}
            flexDirection={["column", "column", "row"]}
          >
            <Box>
              <Flex alignItems="center">
                <Text
                  as="h2"
                  styleName="header-1"
                  styles={{ color: theme.color.gray800 }}
                >
                  API Reference
                </Text>
                <Tooltip ml={1}>
                  Each app has its own collection of REST API endpoints that can
                  be used to manage and run the application. These endpoints and
                  their function are documented below.
                </Tooltip>
              </Flex>

              <Flex mt={2} alignItems="center">
                <Text
                  as="code"
                  pt={1}
                  pr={2}
                  pb={1}
                  pl={2}
                  styleName="code-bold"
                  styles={{
                    display: "inline-block",
                    fontSize: rem(15),
                    lineHeight: rem(18),
                    color: theme.color.purple800,
                    borderRadius: theme.border.radiusSmall,
                    backgroundColor: theme.color.purple100,
                    span: {
                      wordBreak: "break-all",
                    },
                  }}
                >
                  <span>{process.env.REACT_APP_API_BASE}</span>
                </Text>

                <Text ml={2} styleName="body-2">
                  (Base URL)
                </Text>
              </Flex>
            </Box>

            <Flex mt={[5, 5, 0]} alignItems="center">
              <Button2
                isLoading={isApiKeyLoading}
                isDisabled={!!apiKey}
                type="outline-quiet"
                label={apiKey ? "Authorized" : "Authorize"}
                loadingColor={theme.color.green500}
                onClick={getAPIKey}
                icon={apiKey ? <IconLock /> : <IconLockOpen iconSize={15} />}
                styles={{
                  minWidth: rem(111),
                  paddingLeft: rem(10),
                  fontWeight: theme.ui2Typography.fontWeightBody2,
                  color: apiKey ? theme.color.green700 : theme.color.gray700,
                  boxShadow: apiKey
                    ? `
              inset 0 0 0 ${theme.border.widthMediumShadowOnly} 
              ${theme.color.green500}
              `
                    : `
              inset 0 0 0 ${theme.border.widthMediumShadowOnly} 
              ${theme.color.gray200}
              `,
                  svg: {
                    fill: apiKey ? theme.color.green700 : theme.color.gray600,
                  },
                  "&:hover, &:focus, &:active": {
                    color: theme.color.green700,
                    boxShadow: `
                inset 0 0 0 ${theme.border.widthMediumShadowOnly} 
                ${theme.color.green500}
                `,
                    svg: {
                      fill: theme.color.green700,
                    },
                  },
                }}
              />

              <Tooltip ml={1} direction="left">
                To make live calls with the Swagger API spec below, you must
                first authorize with your API key.
              </Tooltip>
            </Flex>
          </Flex>

          <Box mt={6}>
            <Swag
              apiSpec={modifiedAppsApiSpec}
              noTracking={true}
              apiKey={apiKey}
            />
          </Box>

          {displayApiKeyModal && (
            <Modal2
              isActive={true}
              width={rem(600)}
              title="No active API key"
              summary={
                <Text mt={2} styleName="body-1">
                  You must have an active API key to make active live calls with
                  the Swagger API spec. Visit the API Keys page in the Teams
                  section to create an API key.
                </Text>
              }
              children={
                <Flex mt={6}>
                  <Button2
                    size="large"
                    type="primary"
                    label="Go to API Keys"
                    to={getAccUrl(accountId, "/account/api-keys")}
                  />
                </Flex>
              }
              closeActionOnClick={(e: any) => handleCloseModal(e)}
            />
          )}
        </>
      )}
    </>
  );
};

export default AppDetails;
