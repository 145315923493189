import * as React from "react";

import BaseIcon, { IconProps } from "./BaseIcon";

const IconSocialGitHub = ({
  iconSize,
  iconColor,
  iconColorActive,
  iconColorFocus,
  iconColorHover,
}: IconProps) => {
  return (
    <BaseIcon
      {...{
        iconSize,
        iconColor,
        iconColorActive,
        iconColorFocus,
        iconColorHover,
      }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.9993 0.906616C4.08282 0.906616 0.906616 4.0824 0.906616 8.00019C0.906616 11.1337 2.93888 13.7926 5.75758 14.731C6.11247 14.7959 6.2418 14.5769 6.2418 14.3887C6.2418 14.2202 6.23571 13.7743 6.23222 13.1825C4.25918 13.611 3.84289 12.2315 3.84289 12.2315C3.52022 11.412 3.05515 11.1938 3.05515 11.1938C2.41111 10.754 3.10392 10.7627 3.10392 10.7627C3.81589 10.8128 4.19038 11.4938 4.19038 11.4938C4.82309 12.5777 5.85077 12.2646 6.25487 12.083C6.31932 11.6249 6.50264 11.3122 6.70513 11.135C5.13009 10.9556 3.47406 10.3473 3.47406 7.62918C3.47406 6.8545 3.75057 6.22179 4.20432 5.72581C4.13116 5.5464 3.88774 4.82527 4.27399 3.84855C4.27399 3.84855 4.86925 3.65782 6.22438 4.57532C6.79004 4.41812 7.39706 4.33974 8.00017 4.33669C8.60284 4.33974 9.20943 4.41812 9.77595 4.57532C11.1302 3.65782 11.7246 3.84855 11.7246 3.84855C12.1117 4.82527 11.8683 5.5464 11.7956 5.72581C12.2502 6.22179 12.5245 6.8545 12.5245 7.62918C12.5245 10.3543 10.8659 10.9539 9.28607 11.1294C9.54037 11.3484 9.76724 11.7812 9.76724 12.4431C9.76724 13.3911 9.75853 14.1562 9.75853 14.3887C9.75853 14.5786 9.88656 14.7994 10.2462 14.7301C13.0628 13.79 15.0933 11.1333 15.0933 8.00019C15.0933 4.0824 11.9171 0.906616 7.9993 0.906616"
      />
    </BaseIcon>
  );
};

export default IconSocialGitHub;
