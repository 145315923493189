import { NewUserQuestionsByPage, UserAnswers } from "../NewUserQuestions.types";

export const getUserAnswers = (
  userAnswersByPage: NewUserQuestionsByPage
): UserAnswers => {
  const userAnswers: UserAnswers = {};
  for (let i = 0; i < userAnswersByPage.length; i++) {
    for (let j = 0; j < userAnswersByPage[i].length; j++) {
      // get the question
      const question = userAnswersByPage[i][j];
      // get the selected options
      const selectedOptions = question.options.filter(
        (option) => option.selected
      );
      // get the selected option labels
      let selectedOptionLabels: string[] | string = selectedOptions.map(
        (option) => option.label
      );

      if (question.type === "dropdown" && selectedOptions.length > 0) {
        // get the selected option label
        selectedOptionLabels = selectedOptions[0].label;
      }

      // add the question and selected option labels to the userAnswers object
      userAnswers[question.hubspotField] = {
        type: question.type,
        value: selectedOptionLabels,
      };
    }
  }

  return userAnswers;
};
