import styled from "@emotion/styled";

export const StyledOptionSetConfig = styled.pre`
  font-family: ${({ theme }) => theme.ui2Typography.fontFamilyCode};
  font-size: ${({ theme }) => theme.ui2Typography.fontSizeBody3};
  font-style: ${({ theme }) => theme.ui2Typography.fontStyleCode};
  font-weight: ${({ theme }) => theme.ui2Typography.fontWeightCode};
  line-height: ${({ theme }) => theme.ui2Typography.lineHeightCode};
  letter-spacing: ${({ theme }) => theme.ui2Typography.letterSpacingCode};
  color: ${({ theme }) => theme.color.gray700};
  white-space: pre-wrap;
  word-wrap: break-word;
`;
