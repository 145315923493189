import React from "react";

import { unzip } from "./unzip";

export default function useDownloadAndUntar() {
  return React.useCallback(async (downloadUrl, runId) => {
    // Fetch the file
    const response = await fetch(downloadUrl);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Get the file data
    const data = await response.blob();

    // Unzip the files
    const unzippedFiles = await unzip(data);
    for (const file of unzippedFiles) {
      // Create a URL representing the file
      const url = URL.createObjectURL(file.blob);

      // Create a download link
      const link = document.createElement("a");
      link.href = url;
      link.download = `${runId}-${file.name}`;

      // Append the link to the document
      document.body.appendChild(link);

      // Simulate a click on the link
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);
    }
  }, []);
}
