import { BatchExperimentSummary } from "../../../api/core/controlPlane.types";

export const batchExperimentSummaryDictionary = {
  id: {
    title: "Experiment summary ID",
    tooltipContent:
      "The experiment summary ID can be used to query these results with the REST API.",
  },
  instance_id: {
    title: "Instance ID",
    tooltipContent: "The ID of the instance used for the experiment.",
  },
  mean_last_elapsed: {
    title: "Mean elapsed",
    tooltipContent:
      "The average time elapsed to find the last solution for each run.",
  },
  mean_last_value: {
    title: "Mean value",
    tooltipContent:
      "The average value of the last solution found for each run.",
  },
  shifted_geometric_mean_last_value: (shiftParameter?: number) => ({
    title: "Geo. mean value",
    tooltipContent: `Shifted geometric mean of the value of the last solution found ${
      shiftParameter ? `(shift parameter = ${shiftParameter})` : ""
    }`,
  }),
  shift_parameter: {
    title: "Shift parameter",
    tooltipContent: "",
  },
  number_of_runs_comparison: {
    title: "Runs compared",
    tooltipContent:
      "Actual number of runs used. Runs with errors, runs with incompatible input, etc. are removed from the run set.",
  },
  number_of_runs_total: {
    title: "Total runs",
    tooltipContent:
      "The total number of runs within the date range specified (max 20).",
  },
  type: {
    title: "Type",
    tooltipContent: "",
  },
  version_id: {
    title: "Version ID",
    tooltipContent: "The ID of the version used for the experiment.",
  },
};

const runDurationTooltipContent =
  "The self-reported time that the solver ran (seconds). The run duration can be configured by different methods, see the App Configuration reference in Docs for more information (linked below).";
const solutionDurationTooltipContent =
  "The time elapsed (seconds) until the returned solution was found. Note that many solutions can be found, the solution duration returned is for the last best solution found in the run duration specified (see Run Duration below).";

export const tooltipContentDurationSummaries: {
  [key: string]: { content: string; link?: string };
} = {
  "metadata.duration": {
    content:
      "The total duration (seconds) of the run as measured by the cloud infrastructure. This time measurement includes the run duration plus the time taken to execute the run on the remote cloud infrastructure.",
  },
  "result.elapsed": { content: solutionDurationTooltipContent },
  "result.duration": { content: solutionDurationTooltipContent },
  "run.time": {
    content: runDurationTooltipContent,
    link: "https://www.nextmv.io/docs/reference/apps/configuration",
  },
  "run.duration": {
    content: runDurationTooltipContent,
    link: "https://www.nextmv.io/docs/reference/apps/configuration",
  },
};

const configSummaryTableOrder: {
  [key in keyof BatchExperimentSummary]: number;
} = {
  id: -1,
  instance_id: 0,
  mean_last_elapsed: 5,
  mean_last_value: 4,
  number_of_runs_comparison: 3,
  number_of_runs_total: 2,
  shifted_geometric_mean_last_value: 6,
  shift_parameter: -1,
  type: -1,
  version_id: 1,
};

export const sortSummaryTableColumns = (
  a: keyof BatchExperimentSummary,
  b: keyof BatchExperimentSummary
): number => {
  const sortOrderA = configSummaryTableOrder[a];
  const sortOrderB = configSummaryTableOrder[b];

  if (sortOrderA < sortOrderB) return -1;
  if (sortOrderA > sortOrderB) return 1;
  return 0;
};
