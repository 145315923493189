import React from "react";
import { Box, ProgressBar, Text } from "@console/dsc";
import { formatPercentage } from "@console/dsc/src/lib/format";
import { useTheme } from "@emotion/react";

type ExperimentProgressBarProps = {
  runsProcessed?: number;
  runsStarted?: number;
  runsTotal?: number;
};

const ExperimentProgressBar = ({
  runsProcessed,
  runsStarted,
  runsTotal,
}: ExperimentProgressBarProps) => {
  const theme = useTheme();

  const hasValidData =
    typeof runsStarted === "number" &&
    typeof runsProcessed === "number" &&
    typeof runsTotal === "number";

  if (!hasValidData) return null;

  return (
    <Box
      mt={6}
      mb={8}
      ml={[0, -6]}
      mr={[0, -7]}
      pt={7}
      pr={[0, 7]}
      pl={[0, 6]}
      hasBorderTop
    >
      <Text styleName="body-2" ml={1}>
        <Text
          as="strong"
          styleName="body-2-bold"
          styles={{ color: theme.color.gray800 }}
        >
          {runsProcessed} of {runsTotal}
        </Text>{" "}
        runs processed ({runsStarted} runs started or queued)
      </Text>
      <ProgressBar
        mt={4}
        barWidth={formatPercentage(runsProcessed, runsTotal)}
      />
    </Box>
  );
};

export default ExperimentProgressBar;
