import React from "react";
import { Pane, useMap } from "react-leaflet";

import { UseMapStateReturnValue } from "../../hooks/useMapState";
import { Coords, RouteSet, RouteSetByID } from "../../Map.types";
import {
  FIT_BOUNDS_OPTIONS,
  MAP_DISPLAY_APPROXIMATE,
} from "../../utils/constants";
import { calculateBounds } from "../../utils/mapping";

import RouteFeature from "./components/RouteFeature";
import { filterRoutesByDisplayAndFocus } from "./utils/mapRoutes";

export type MapRoutesPropType = {
  allStops: Coords[];
  mapState: UseMapStateReturnValue;
  routesbyid: RouteSetByID;
};

export default function MapRoutes({
  allStops,
  mapState,
  routesbyid,
}: MapRoutesPropType) {
  const {
    focusedRouteId,
    setFocusedRouteId,
    mapDisplayMode,
    visibleVehicleRoutesById,
    displayVehiclesNoRoutes,
    displayDirectionalArrows,
  } = mapState;
  const map = useMap();

  const routeType = MAP_DISPLAY_APPROXIMATE;
  const routes = Object.values(routesbyid);
  const allRouteCoords = routes.flatMap((r) => r[routeType]);
  const [needsBounds, setNeedsBounds] = React.useState(true);

  React.useEffect(() => {
    if (needsBounds && routes && routes?.length > 0) {
      const calculatedBounds = calculateBounds(allStops);
      map.fitBounds(calculatedBounds, FIT_BOUNDS_OPTIONS);
      setNeedsBounds(false);
    }
  }, [allRouteCoords, allStops, map, routes, needsBounds, setNeedsBounds]);

  const renderRouteFeature = (routeSet: RouteSet) => {
    if (!routeSet.hasRoute && !displayVehiclesNoRoutes) return null;
    const routeSetCoordinates = routeSet[mapDisplayMode];
    return (
      <RouteFeature
        color={routeSet.color}
        isHidden={false}
        focusedRouteId={focusedRouteId}
        coords={routeSetCoordinates}
        routeID={routeSet.id}
        key={routeSet.id}
        setFocusedRouteId={setFocusedRouteId}
        displayDirectionalArrows={displayDirectionalArrows}
      />
    );
  };

  if (routes?.length === 0) return null;

  const focusedRoute = routes.find((route) => route.id === focusedRouteId);

  return (
    <>
      {/* 
        This Pane is used to render directional arrows along route paths
        See polylineWithArrows.ts (createArrowMarker function) for where the 
        arrows are assigned to this pane
        z-index is necessary so the arrows render beneath the polylines/other 
        markers, and pointerEvents: none makes sure they do not interfere with 
        interactions ex. clicking markers/hovering routes
      */}
      <Pane
        name="directional-arrows"
        style={{ zIndex: 200, pointerEvents: "none" }}
      />
      {focusedRoute && renderRouteFeature(focusedRoute)}
      {filterRoutesByDisplayAndFocus(
        routes,
        focusedRouteId,
        visibleVehicleRoutesById
      ).map((routeSet) => renderRouteFeature(routeSet))}
    </>
  );
}
