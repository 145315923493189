import React, { useMemo } from "react";
import { useTheme } from "@emotion/react";

import { useUser } from "../../../../../AuthProvider";
import { getBannerType } from "../../../../../components/Banner/utils/getBannerType";
import OutputEditor from "../../../../../components/OutputEditor";
import {
  StyledControlPanel,
  StyledInputEditorContainer,
  StyledRunResultContainer,
} from "../RunDetails.styled";
import { RunDetailsSchedulingOutputProps } from "../RunDetails.types";
import {
  getAssignedWorkerShifts,
  getStartEndRange,
  getVizHourRange,
  getWorkerColors,
} from "../utils/getWorkerData";

import DownloadRunOutput from "./DownloadRunOutput";
import VisualizationSchedulingOutput from "./VisualizationSchedulingOutput";

const RunDetailsSchedulingOutput = ({
  appId,
  runDetails,
  runInput,
  runMetadata,
}: RunDetailsSchedulingOutputProps) => {
  const [user] = useUser();
  const theme = useTheme();
  const bannerType = getBannerType(user);

  const workers = useMemo(() => {
    return runInput?.workers || runInput?.employees;
  }, [runInput?.employees, runInput?.workers]);
  const workerColors = useMemo(() => {
    return getWorkerColors({ theme, workers });
  }, [theme, workers]);
  const runOutput = useMemo(() => {
    return runDetails?.output;
  }, [runDetails?.output]);

  const assignedWorkerShifts = useMemo(() => {
    return getAssignedWorkerShifts(runOutput);
  }, [runOutput]);

  const visualizeHoursRange = useMemo(() => {
    return getVizHourRange(
      getStartEndRange({ entities: assignedWorkerShifts })
    );
  }, [assignedWorkerShifts]);

  return (
    <StyledRunResultContainer hasBanner={!!bannerType}>
      <StyledControlPanel>
        <DownloadRunOutput {...{ appId, runMetadata }} />

        <StyledInputEditorContainer>
          <OutputEditor value={runOutput} />
        </StyledInputEditorContainer>
      </StyledControlPanel>

      <VisualizationSchedulingOutput
        {...{
          assignedWorkerShifts,
          visualizeHoursRange,
          workerColors,
          workers,
        }}
      />
    </StyledRunResultContainer>
  );
};
export default RunDetailsSchedulingOutput;
