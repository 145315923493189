import { useState } from "react";

import {
  startSwitchbackTest,
  stopSwitchbackTest,
} from "../../../../../api/core/controlPlane";
import { EntityErrorMessage } from "../../../../../api/core/controlPlane.types";
import { useUser } from "../../../../../AuthProvider";
import {
  TEST_ACTION_CANCEL,
  TEST_ACTION_COMPLETE,
} from "../../../../../config/experiments";
import { StartStopModalType } from "../../../types/Experiment.types";

const useSwitchbackTest = (appId: string, switchbackTestId: string) => {
  const [{ id: accountId }] = useUser();

  const [displayConfirmModal, setDisplayConfirmModal] =
    useState<StartStopModalType | null>(null);
  const [modalActionsError, setModalActionsError] =
    useState<EntityErrorMessage>(null);

  const handleSwitchbackTestStart = async () => {
    try {
      await startSwitchbackTest(accountId || "")(appId, switchbackTestId);
      setDisplayConfirmModal(null);
    } catch (e) {
      setModalActionsError((e as Error).message);
    }
    return;
  };

  const handleSwitchbackTestStop = async (actionType: StartStopModalType) => {
    try {
      await stopSwitchbackTest(accountId || "")(
        appId,
        switchbackTestId,
        actionType as typeof TEST_ACTION_COMPLETE | typeof TEST_ACTION_CANCEL
      );
      setDisplayConfirmModal(null);
    } catch (e) {
      setModalActionsError((e as Error).message);
    }
  };

  return {
    displayConfirmModal,
    handleSwitchbackTestStart,
    handleSwitchbackTestStop,
    modalActionsError,
    setDisplayConfirmModal,
    setModalActionsError,
  };
};

export default useSwitchbackTest;
