export interface PasswordValidationRule {
  message: string;
  isValid: boolean;
}

export const validatePasswordLength = (password: string, minLength: number) => {
  return password.length >= minLength;
};
export const validatePasswordLowerCase = (password: string) => {
  return password.toUpperCase() !== password;
};

export const validatePasswordUpperCase = (password: string) => {
  return password.toLowerCase() !== password;
};

export const validatePasswordNumber = (password: string) => {
  return /\d/.test(password);
};

export function getPasswordRules(password: string): PasswordValidationRule[] {
  return [
    {
      message: "10 characters",
      isValid: validatePasswordLength(password, 10),
    },
    {
      message: "· 1 lowercase",
      isValid: validatePasswordLowerCase(password),
    },
    {
      message: "· 1 uppercase",
      isValid: validatePasswordUpperCase(password),
    },
    {
      message: "· 1 number",
      isValid: validatePasswordNumber(password),
    },
  ];
}
