import untar from "js-untar";

import { FileData } from "./types";

export const unzip = async (fileBlob: Blob): Promise<FileData[]> => {
  // Create a DecompressionStream for 'gzip'
  const ds = new DecompressionStream("gzip");
  const decompressedStream = fileBlob.stream().pipeThrough(ds);

  // Read the decompressed data
  const reader = decompressedStream.getReader();
  const chunks = [];
  let result;
  while (!(result = await reader.read()).done) {
    chunks.push(result.value);
  }

  // Create a new Blob from the decompressed data
  const decompressedBlob = new Blob(chunks);

  // Read the Blob as an ArrayBuffer
  const arrayBuffer = await decompressedBlob.arrayBuffer();

  // Untar the decompressed file
  const untarredFiles = await untar(arrayBuffer);

  // Convert the buffers of the untarred files to Blobs
  const blobs = untarredFiles.map((file: any) => {
    return {
      name: file.name,
      blob: new Blob([file.buffer]),
    };
  });

  return blobs;
};
