import { rem } from "../lib/tools";

export type Breakpoints = "sm" | "md" | "rg" | "lg" | "xl";

/*
  breakpoints:
   < sm |     0 - 540   | (mobile)
  sm-md |   540 - 960   | (tablet)
  md-rg |   960 - 1168  | (narrow laptop screens)
  rg-lg |  1168 - 1354  | (typical laptop)
  lg-xl |  1354 - 1600  | (typical laptop/monitor)
   > xl |       > 1600  | (large, wide-screen monitors)
*/
export const sm = rem(540);
export const md = rem(960);
export const rg = rem(1168);
export const lg = rem(1354);
export const xl = rem(1600);
