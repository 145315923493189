import { RouteSetByID } from "../../../../../components/Map/Map.types";

export const getVisibleVehicleRoutesById = (routesById: RouteSetByID) => {
  return Object.keys(routesById).reduce(
    (vehicleIdMap, vehicleId) => ({
      ...vehicleIdMap,
      [vehicleId]: true,
    }),
    {}
  );
};
