import React, { useMemo } from "react";
import { useTheme } from "@emotion/react";

import { useUser } from "../../../../../AuthProvider";
import { getBannerType } from "../../../../../components/Banner/utils/getBannerType";
import InputEditor from "../../../../../components/InputEditor";
import {
  parseSchemaShiftSchedulingRequiredWorkers,
  parseSchemaShiftSchedulingWorkers,
} from "../../../utils/schemaCheckShiftScheduling";
import {
  StyledControlPanel,
  StyledInputEditorContainer,
  StyledRunResultContainer,
} from "../RunDetails.styled";
import { RunDetailsSchedulingInputProps } from "../RunDetails.types";
import {
  getStartEndRange,
  getVizHourRange,
  getWorkerColors,
  getWorkersRange,
} from "../utils/getWorkerData";

import DownloadRunInput from "./DownloadRunInput";
import VisualizationSchedulingInput from "./VisualizationSchedulingInput";

const RunDetailsSchedulingInput = ({
  appId,
  runInput,
  runMetadata,
}: RunDetailsSchedulingInputProps) => {
  const [user] = useUser();
  const theme = useTheme();
  const bannerType = getBannerType(user);

  const memoRunInput = useMemo(() => {
    return runInput;
  }, [runInput]);
  const sanitizedWorkers = useMemo(() => {
    return parseSchemaShiftSchedulingWorkers(
      memoRunInput?.workers || memoRunInput?.employees
    );
  }, [memoRunInput?.employees, memoRunInput?.workers]);
  const workerColors = useMemo(() => {
    return getWorkerColors({ theme, workers: sanitizedWorkers });
  }, [sanitizedWorkers, theme]);
  const sanitizedRequiredWorkerShifts = useMemo(() => {
    return parseSchemaShiftSchedulingRequiredWorkers(
      memoRunInput?.required_workers || memoRunInput?.shifts
    );
  }, [memoRunInput?.required_workers, memoRunInput?.shifts]);
  const visualizeHoursRange = useMemo(() => {
    return getVizHourRange(
      getWorkersRange(sanitizedWorkers),
      getStartEndRange({ entities: sanitizedRequiredWorkerShifts })
    );
  }, [sanitizedRequiredWorkerShifts, sanitizedWorkers]);

  return (
    <StyledRunResultContainer hasBanner={!!bannerType}>
      <StyledControlPanel>
        <DownloadRunInput {...{ appId, runMetadata }} />

        <StyledInputEditorContainer>
          <InputEditor input={memoRunInput} isReadOnly isCustomSchema={true} />
        </StyledInputEditorContainer>
      </StyledControlPanel>

      <VisualizationSchedulingInput
        {...{
          workerColors,
          visualizeHoursRange,
        }}
        requiredWorkerShifts={sanitizedRequiredWorkerShifts}
        workers={sanitizedWorkers}
      />
    </StyledRunResultContainer>
  );
};
export default RunDetailsSchedulingInput;
