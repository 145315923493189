import * as React from "react";
import styled from "@emotion/styled";

import { renderDynamicProperties } from "../lib/tools";

interface AvailableIllustrationProps {
  children: React.ReactNode;
  illustrationHeight?: number;
  illustrationWidth?: number;
  illustrationWidths?: [string, string?, string?, string?, string?, string?];
  viewBox: [number, number, number, number];
  illustrationColor?: string;
}

type BaseIllustrationProps = AvailableIllustrationProps;
export type IllustrationProps = Omit<AvailableIllustrationProps, "children">;

type StyledIllustrationProps = Pick<
  AvailableIllustrationProps,
  "illustrationWidths"
>;
const StyledIllustration = styled.svg<StyledIllustrationProps>`
  flex-shrink: 0;
  ${(props) =>
    props.illustrationWidths &&
    renderDynamicProperties("width", props.illustrationWidths)}
`;

const BaseIllustration = ({
  children,
  illustrationColor,
  illustrationHeight,
  illustrationWidth,
  illustrationWidths,
  viewBox,
}: BaseIllustrationProps) => {
  return (
    <StyledIllustration
      {...{ illustrationWidths }}
      width={
        illustrationWidth
          ? illustrationWidth
          : illustrationHeight
          ? undefined
          : viewBox[2] // 3rd place in SVG viewbox == width
      }
      height={illustrationHeight || undefined}
      viewBox={viewBox.join(" ")}
      fill={illustrationColor ? illustrationColor : undefined}
      xmlns="http://www.w3.org/2000/svg"
    >
      {children}
    </StyledIllustration>
  );
};

export default BaseIllustration;
