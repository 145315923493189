import { camelCase, upperFirst } from "lodash";

export const authErrorCodeToMessageMap = {
  UserNotConfirmedExceptionLogin:
    "Your account has not been confirmed, please check your email for the account confirmation email.",
  ExpiredCodeExceptionVerify:
    "Your account verification link has expired, please request a new verification link below.",
  CodeMismatchExceptionVerify:
    "Your account verification link is invalid, please request a new verification link below.",
  ExpiredCodeExceptionResetPassword:
    "Your reset password link has expired, please request a new reset password link below.",
  CodeMismatchExceptionResetPassword:
    "Your reset password link is invalid, please request a new reset password link below.",
};

export interface AmplifyAuthError {
  code: string;
  name: string;
  message: string;
  // tests only
  view?: AmplifyAuthErrorContext;
}

export type AmplifyAuthErrorContext = "login" | "reset-password" | "verify";

export const getErrorMessageByCode = (
  error: AmplifyAuthError,
  view?: AmplifyAuthErrorContext
): string => {
  let errorCode = "";

  if (view) {
    if (
      [
        "ExpiredCodeException",
        "CodeMismatchException",
        "UserNotConfirmedException",
      ].includes(error.code)
    ) {
      errorCode = `${error.code}${upperFirst(camelCase(view))}`;
    }
  }

  if (errorCode in authErrorCodeToMessageMap) {
    return authErrorCodeToMessageMap[
      errorCode as keyof typeof authErrorCodeToMessageMap
    ];
  }
  return error.message;
};
