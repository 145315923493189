import { useCallback } from "react";
import { isEmpty } from "lodash";

import { UserActionKind, useUser } from "../../AuthProvider";
import { getToken } from "../utils/cognito";
import { useFetchWithToken } from "../utils/hooks";

import { API_BASE, apiStage, NEXTMV_ACCOUNT_HEADER_KEY } from "./core.config";
import {
  getUser,
  getUserOrgs,
  getUserWithAccount,
  parseDecisionCount,
  parseUserFlags,
} from "./dataPlane";
import { User } from "./dataPlane.types";

// TODO:  we should only allow a root user to start a free trial
// and it needs to be done via Cognito token
export const useCreateFreeTrial = () => {
  const [, userDispatch] = useUser();
  const fetchWithToken = useCoreFetchWithToken();
  const startFreeTrial = useCallback(async () => {
    const resp = await fetchWithToken(
      "/internal/trial",
      "POST",
      JSON.stringify({})
    );

    if (resp && resp.status !== 200) {
      const respJson = await (resp
        ? resp.json()
        : { status: "Internal Server Error" });
      throw new Error(
        `${respJson.status}${respJson.error ? ` ${respJson.error}` : ""}`
      );
    }

    const user = await resp?.json();
    if (isEmpty(user)) {
      throw new Error("Invalid User");
    }

    const {
      decisionCount: returnedDecisionCount,
      featureFlags,
      ...returnedUser
    } = user;
    const decisionCount = parseDecisionCount(returnedDecisionCount);
    const features = parseUserFlags(featureFlags);

    userDispatch({
      type: UserActionKind.UPDATE,
      payload: { ...returnedUser, decisionCount, features },
    });
  }, [userDispatch, fetchWithToken]);
  return startFreeTrial;
};

export const useStartRunFunc = (options?: object) => {
  const fetchWithAccount = useCoreFetchWithAccount({
    "X-HOP-RUNNER-OUTPUT-SOLUTIONS": "all",
    "request-source": "console",
    ...options,
  });
  const startRun = useCallback(
    async (body: string): Promise<string> => {
      const resp = await fetchWithAccount("/run", "POST", body);

      if (!resp) {
        console.error(resp);
        throw new Error("could not communicate with server");
      }

      switch (resp.status) {
        case 202:
          break;
        case 400:
          const data = await resp.json();
          throw new Error(data.error);
        case 413:
          return Promise.reject(new Error("maximum input size is 6MB"));
        case 500:
          return Promise.reject(new Error("an internal server error occurred"));
        default:
          return Promise.reject(
            new Error(`the server responded with status code ${resp.status}`)
          );
      }
      const data = await resp.json();
      return data.runID;
    },
    [fetchWithAccount]
  );
  return startRun;
};

export const useCoreFetchWithToken = (options?: object) => {
  return useFetchWithToken(`${API_BASE}/${apiStage}`, options);
};

export const useCoreFetchWithAccount = (options?: object) => {
  const [user] = useUser();
  return useFetchWithToken(`${API_BASE}/${apiStage}`, {
    [NEXTMV_ACCOUNT_HEADER_KEY]: user.id,
    ...options,
  });
};

export type RunStatusResponse = {
  status: "failed" | "requested" | "started" | "succeeded" | "timed_out";
  error?: string;
};

export const useCheckRunStatusFunc = () => {
  const fetchWithAccount = useCoreFetchWithAccount();
  const checkRunStatus = useCallback(
    (id: string): Promise<RunStatusResponse> => {
      return fetchWithAccount(`/run/${id}/status`)?.then((resp) =>
        resp?.json()
      );
    },
    [fetchWithAccount]
  );
  return checkRunStatus;
};

export const useGetRunResultFunc = () => {
  const fetchWithAccount = useCoreFetchWithAccount();
  const getRunResult = useCallback(
    async (id: string): Promise<string> => {
      const resp = await fetchWithAccount(`/run/${id}/result`);

      if (!resp) {
        console.error(resp);
        throw new Error("could not communicate with server");
      }

      switch (resp.status) {
        case 200:
          break;
        case 500:
          throw new Error("an internal server error occurred");
        default:
          throw new Error(
            `the server responded with status code ${resp.status}`
          );
      }

      return resp.text();
    },
    [fetchWithAccount]
  );
  return getRunResult;
};

export function useFetchAndUpdateUser() {
  const [user, userDispatch] = useUser();

  const fetchAndUpdateUser = useCallback(async () => {
    try {
      const { decisionCount, features, ...returnedUser } =
        await getUserWithAccount(user.id || "");

      userDispatch({
        type: UserActionKind.UPDATE,
        payload: { ...returnedUser, decisionCount, features },
      });
    } catch (e) {
      console.error("Error updating user data");
    }
  }, [user.id, userDispatch]);

  return fetchAndUpdateUser;
}

export function useFetchAndUpdateUserAndOrgs() {
  const [, userDispatch] = useUser();

  const fetchAndUpdateUser = useCallback(async () => {
    try {
      const token = await getToken();
      if (!token) {
        return {} as User;
      }
      const { decisionCount, features, ...returnedUser } = await getUser(token);
      const orgs = await getUserOrgs(token);

      userDispatch({
        type: UserActionKind.UPDATE,
        payload: {
          ...returnedUser,
          decisionCount,
          features,
          organizations: orgs,
        },
      });
    } catch (e) {
      console.error("Error updating user data");
    }
  }, [userDispatch]);

  return fetchAndUpdateUser;
}
