import React, { useEffect, useRef } from "react";
import Ajv from "ajv";

import CustomAceEditor from "../CustomAceEditor";

import schema from "./schema.json";

type InputEditorProps = {
  input: any;
  isCustomSchema?: boolean;
  isModified?: boolean;
  isReadOnly?: boolean;
  onInputChange?: (input: any) => void;
  onValidationError?: (errors: any) => void;
  setShouldEditorRefresh?: React.Dispatch<React.SetStateAction<boolean>>;
  shouldEditorRefresh?: boolean;
  controlEditorModePositionTop?: number;
};

const ajv = new Ajv({ allErrors: true, verbose: true });

const InputEditor = ({
  input,
  isCustomSchema,
  isModified,
  isReadOnly,
  onInputChange,
  onValidationError,
  setShouldEditorRefresh,
  shouldEditorRefresh,
  controlEditorModePositionTop,
}: InputEditorProps) => {
  // https://github.com/vankop/jsoneditor-react/issues/3#issuecomment-726427695
  const jsonEditorRef = useRef<any>(null);

  const setRef = (instance: any) => {
    if (instance) {
      jsonEditorRef.current = instance.jsonEditor;
    } else {
      jsonEditorRef.current = null;
    }
  };

  useEffect(() => {
    if (jsonEditorRef.current && !isModified) {
      jsonEditorRef.current.set(input);
    }
  }, [input, isModified]);

  useEffect(() => {
    if (jsonEditorRef.current) {
      jsonEditorRef.current.options.onValidationError = onValidationError;
    }
  }, [onValidationError]);

  // editor needs help resizing if the UI changes after initial loads
  // (e.g. expanding and collapsing panels on create new run view)
  useEffect(() => {
    if (
      shouldEditorRefresh &&
      setShouldEditorRefresh &&
      jsonEditorRef.current
    ) {
      jsonEditorRef.current.resize();
      setShouldEditorRefresh(false);
    }
  }, [shouldEditorRefresh, setShouldEditorRefresh]);

  return (
    <CustomAceEditor
      {...{ controlEditorModePositionTop }}
      ref={setRef}
      value={input}
      onChange={onInputChange}
      mode="code"
      ajv={ajv}
      {...(!isCustomSchema && { schema: schema })}
      {...(isReadOnly && {
        onEditable: (_node: {
          field: string;
          value: string;
          path: string[];
        }) => {
          return false;
        },
      })}
    />
  );
};

export default React.memo(InputEditor);
