import React, { useState } from "react";
import { Box, Button2, Flex, Notification, Text } from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";
import { DateTime } from "luxon";

import { trackEvent } from "../../../../../analytics";
import { CustomerPortalSource } from "../../../../../analytics/types/billing.types";
import { useFetchPayments } from "../../../../../api/payment/paymentApi.hooks";
import {
  PLAN_TYPE_LEGACY_BASIC,
  PLAN_TYPE_LEGACY_PREMIUM,
} from "../../../../../config/plans";
import { formatPrice } from "../../../../../utils/format";
import { LegacyPlanSummaryProps } from "../../../Account.types";

const LegacyPlanSummary = ({ subscription }: LegacyPlanSummaryProps) => {
  const { planType, price, decisionsPerMonth: decisions } = subscription;
  const fetchPayments = useFetchPayments();
  const theme = useTheme();

  const [error, setError] = useState(false);

  let planNameAccentColor = theme.color.gray800;
  switch (planType) {
    case PLAN_TYPE_LEGACY_BASIC:
      planNameAccentColor = theme.color.planBasicAccent;
      break;
    case PLAN_TYPE_LEGACY_PREMIUM:
      planNameAccentColor = theme.color.planPremiumAccent;
      break;
    default:
      break;
  }

  const handleCustomerPortalLaunch = (source: CustomerPortalSource) => {
    trackEvent("Billing", {
      view: "Billing",
      action: "Customer Portal Launched",
      meta: {
        source,
      },
    });
    fetchPayments("/billing-session")
      .then((res) => res?.json())
      .then((data) => {
        if (data?.url) {
          window.location.href = data.url;
        } else {
          throw new Error("Portal Session Launch Error");
        }
      })
      .catch((err) => {
        setError(true);
        console.error(err);
      });
  };

  return (
    <Flex justifyContent="space-between">
      <Box>
        {planType && (
          <Text
            as="strong"
            styleName="body-2-bold"
            styles={{
              textTransform: "capitalize",
              color: planNameAccentColor,
            }}
          >
            {planType}
          </Text>
        )}

        <Flex mt={1}>
          <Text styleName="body-1">
            <Text
              as="strong"
              styleName="body-1-bold"
              styles={{
                color: theme.color.gray800,
              }}
            >
              {formatPrice(price, 0)}
            </Text>
            /mo.
          </Text>
          <Text
            styleName="body-1"
            styles={{ color: theme.color.gray600 }}
            mr={1}
            ml={1}
          >
            &middot;
          </Text>
          <Text styleName="body-1">
            <Text
              as="strong"
              styleName="body-1-bold"
              styles={{
                color: theme.color.gray800,
              }}
            >
              {decisions.toLocaleString()}
            </Text>{" "}
            decisions/mo.
          </Text>
        </Flex>

        <Box mt={1}>
          {subscription.pendingCancelation ? (
            <Box maxWidth={rem(720)}>
              <Text
                styleName="body-2-bold"
                styles={{ color: theme.color.red600 }}
              >
                Your plan will be canceled
                {subscription?.cancelAt
                  ? ` on ${DateTime.fromISO(
                      subscription?.cancelAt
                    ).toLocaleString(DateTime.DATE_FULL)}.`
                  : " at the end of the month."}
              </Text>
              <Text styleName="body-2" mt={1} hasLink>
                You have access to your current plan until cancellation. Your
                card will not be charged again. Reach out to{" "}
                <a href="mailto:support@nextmv.io?subject=Restart a plan">
                  support@nextmv.io
                </a>{" "}
                to discuss restarting a plan.
              </Text>
            </Box>
          ) : (
            <Text styleName="body-2">
              Next billing date:{" "}
              <Text
                as="strong"
                styleName="body-2-bold"
                styles={{
                  color: theme.color.gray800,
                }}
              >
                {DateTime.fromISO(subscription.nextBillingDate).toLocaleString(
                  DateTime.DATE_FULL
                )}
              </Text>
            </Text>
          )}
        </Box>

        <Button2
          mt={3}
          label="Manage Legacy Plan"
          onClick={() => handleCustomerPortalLaunch("customer-portal")}
        />
        {error && (
          <Notification
            mt={3}
            type="error"
            message="Unable to launch the Customer Portal. Refresh the page and try again. If this problem persists, please contact support@nextmv.io"
          />
        )}
      </Box>
    </Flex>
  );
};

export default LegacyPlanSummary;
