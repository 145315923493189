import { DateTime } from "luxon";

type ReformatTimeParams = {
  time: string | null;
  inputDateType: "rfc3339" | "iso";
  outputDateFormat: "rfc3339" | string;
};
export const reformatTime = ({
  time,
  inputDateType,
  outputDateFormat,
}: ReformatTimeParams): string => {
  if (!time) return "";

  const trimmedTime = time.trim();

  if (inputDateType === "iso") {
    if (outputDateFormat === "rfc3339") {
      return DateTime.fromISO(time).toFormat("yyyy-MM-dd'T'HH:mm:ssZZ");
    }
    return DateTime.fromISO(time).toFormat(outputDateFormat);
  }
  if (inputDateType === "rfc3339") {
    if (/Z$/g.test(trimmedTime)) {
      return DateTime.fromFormat(
        trimmedTime,
        "yyyy-MM-dd'T'HH:mm:ss'Z'"
      ).toFormat(outputDateFormat);
    }
    if (/(-|\+)\d{2}:[0|3|4][0|5]$/g.test(trimmedTime)) {
      return DateTime.fromFormat(
        trimmedTime,
        "yyyy-MM-dd'T'HH:mm:ssZZ"
      ).toFormat(outputDateFormat);
    }
    return "Invalid format";
  }
  return trimmedTime;
};

export const formatPercentage = (
  numerator: number,
  denominator: number
): string => {
  if (typeof numerator !== "number" || typeof denominator !== "number") {
    return "";
  }
  if (isNaN(numerator / denominator)) {
    return "";
  }
  return `${Math.round((numerator / denominator) * 100)}%`;
};
