import React from "react";
import { CSVLink } from "react-csv";
import { Headers } from "react-csv/components/CommonPropTypes";
import { useTheme } from "@emotion/react";

import { IconDownload } from "../../../icons";
import Button2 from "../../Button2";
import { TableData } from "../Table2.types";

type CSVTableDownloadProps = {
  headersCSV?: Headers;
  dataCSV: TableData[];
  fileNameCSV?: string;
};

const CSVTableDownload = ({
  headersCSV,
  dataCSV,
  fileNameCSV,
}: CSVTableDownloadProps) => {
  const theme = useTheme();
  const fileName = fileNameCSV ? `${fileNameCSV}.csv` : "downloaded-data.csv";

  return (
    <CSVLink
      headers={headersCSV}
      data={dataCSV}
      filename={fileName}
      style={{ textDecoration: "none" }}
    >
      <Button2
        type="text-quiet"
        label="Download CSV"
        icon={<IconDownload iconColor={theme.color.gray600} />}
      />
    </CSVLink>
  );
};

export default CSVTableDownload;
