import {
  AMPL_FACILITY_LOCATION_NAME,
  AMPL_FACILITY_LOCATION_SUBSCRIPTION_ID,
  AMPL_FACILITY_LOCATION_TEMPLATE_NAME,
} from "../data/marketAppAmplFacilityLocation";
import {
  AMPL_PRICE_OPTIMIZATION_NAME,
  AMPL_PRICE_OPTIMIZATION_SUBSCRIPTION_ID,
  AMPL_PRICE_OPTIMIZATION_TEMPLATE_NAME,
} from "../data/marketAppAmplPricing";
import {
  HIGHS_KNAPSACK_NAME,
  HIGHS_KNAPSACK_SUBSCRIPTION_ID,
  HIGHS_KNAPSACK_TEMPLATE_NAME,
} from "../data/marketAppHiGHSKnapsack";
import {
  ROUTING_LEGACY_NAME,
  ROUTING_LEGACY_SUBSCRIPTION_ID,
  ROUTING_LEGACY_TEMPLATE_NAME,
} from "../data/marketAppLegacyRouting";
import {
  KNAPSACK_NAME,
  KNAPSACK_SUBSCRIPTION_ID,
  KNAPSACK_TEMPLATE_NAME,
} from "../data/marketAppNextmvKnapsack";
import {
  NEXTROUTE_NAME,
  NEXTROUTE_SUBSCRIPTION_ID,
  NEXTROUTE_TEMPLATE_NAME,
} from "../data/marketAppNextroute";
import {
  ORDER_FULFILLMENT_NAME,
  ORDER_FULFILLMENT_SUBSCRIPTION_ID,
  ORDER_FULFILLMENT_TEMPLATE_NAME,
} from "../data/marketAppOrderFulfillment";
import {
  ORTOOLS_DEMAND_FORECASTING_NAME,
  ORTOOLS_DEMAND_FORECASTING_SUBSCRIPTION_ID,
  ORTOOLS_DEMAND_FORECASTING_TEMPLATE_NAME,
} from "../data/marketAppORToolsDemandForecasting";
import {
  ORTOOLS_KNAPSACK_NAME,
  ORTOOLS_KNAPSACK_SUBSCRIPTION_ID,
  ORTOOLS_KNAPSACK_TEMPLATE_NAME,
} from "../data/marketAppORToolsKnapsack";
import {
  ORTOOLS_SHIFT_ASSIGNMENT_NAME,
  ORTOOLS_SHIFT_ASSIGNMENT_SUBSCRIPTION_ID,
  ORTOOLS_SHIFT_ASSIGNMENT_TEMPLATE_NAME,
} from "../data/marketAppORToolsShiftAssignment";
import {
  ORTOOLS_SHIFT_PLANNING_NAME,
  ORTOOLS_SHIFT_PLANNING_SUBSCRIPTION_ID,
  ORTOOLS_SHIFT_PLANNING_TEMPLATE_NAME,
} from "../data/marketAppORToolsShiftPlanning";
import {
  ORTOOLS_SIMPLE_ROUTING_NAME,
  ORTOOLS_SIMPLE_ROUTING_SUBSCRIPTION_ID,
  ORTOOLS_SIMPLE_ROUTING_TEMPLATE_NAME,
} from "../data/marketAppORToolsSimpleRouting";
import {
  PYOMO_KNAPSACK_NAME,
  PYOMO_KNAPSACK_SUBSCRIPTION_ID,
  PYOMO_KNAPSACK_TEMPLATE_NAME,
} from "../data/marketAppPyomoKnapsack";
import {
  PYOMO_SHIFT_ASSIGNMENT_NAME,
  PYOMO_SHIFT_ASSIGNMENT_SUBSCRIPTION_ID,
  PYOMO_SHIFT_ASSIGNMENT_TEMPLATE_NAME,
} from "../data/marketAppPyomoShiftAssignment";
import {
  PYOMO_SHIFT_PLANNING_NAME,
  PYOMO_SHIFT_PLANNING_SUBSCRIPTION_ID,
  PYOMO_SHIFT_PLANNING_TEMPLATE_NAME,
} from "../data/marketAppPyomoShiftPlanning";
import {
  ROUTING_PYVROOM_NAME,
  ROUTING_PYVROOM_SUBSCRIPTION_ID,
  ROUTING_PYVROOM_TEMPLATE_NAME,
} from "../data/marketAppRoutingPyvroom";
import {
  SHIFT_SCHEDULING_NAME,
  SHIFT_SCHEDULING_SUBSCRIPTION_ID,
  SHIFT_SCHEDULING_TEMPLATE_NAME,
} from "../data/marketAppShiftScheduling";

export const getMarketplaceAppName = (subscriptionId: string): string => {
  switch (subscriptionId) {
    case NEXTROUTE_SUBSCRIPTION_ID:
      return NEXTROUTE_NAME;
    case SHIFT_SCHEDULING_SUBSCRIPTION_ID:
      return SHIFT_SCHEDULING_NAME;
    case ROUTING_LEGACY_SUBSCRIPTION_ID:
      return ROUTING_LEGACY_NAME;
    case ORDER_FULFILLMENT_SUBSCRIPTION_ID:
      return ORDER_FULFILLMENT_NAME;
    case ORTOOLS_SHIFT_PLANNING_SUBSCRIPTION_ID:
      return ORTOOLS_SHIFT_PLANNING_NAME;
    case ORTOOLS_SIMPLE_ROUTING_SUBSCRIPTION_ID:
      return ORTOOLS_SIMPLE_ROUTING_NAME;
    case ORTOOLS_SHIFT_ASSIGNMENT_SUBSCRIPTION_ID:
      return ORTOOLS_SHIFT_ASSIGNMENT_NAME;
    case PYOMO_SHIFT_ASSIGNMENT_SUBSCRIPTION_ID:
      return PYOMO_SHIFT_ASSIGNMENT_NAME;
    case PYOMO_SHIFT_PLANNING_SUBSCRIPTION_ID:
      return PYOMO_SHIFT_PLANNING_NAME;
    case KNAPSACK_SUBSCRIPTION_ID:
      return KNAPSACK_NAME;
    case ORTOOLS_KNAPSACK_SUBSCRIPTION_ID:
      return ORTOOLS_KNAPSACK_NAME;
    case PYOMO_KNAPSACK_SUBSCRIPTION_ID:
      return PYOMO_KNAPSACK_NAME;
    case ORTOOLS_DEMAND_FORECASTING_SUBSCRIPTION_ID:
      return ORTOOLS_DEMAND_FORECASTING_NAME;
    case AMPL_PRICE_OPTIMIZATION_SUBSCRIPTION_ID:
      return AMPL_PRICE_OPTIMIZATION_NAME;
    case AMPL_FACILITY_LOCATION_SUBSCRIPTION_ID:
      return AMPL_FACILITY_LOCATION_NAME;
    case ROUTING_PYVROOM_SUBSCRIPTION_ID:
      return ROUTING_PYVROOM_NAME;
    case HIGHS_KNAPSACK_SUBSCRIPTION_ID:
      return HIGHS_KNAPSACK_NAME;
    default:
      return "";
  }
};

export const getMarketplaceAppTemplateName = (
  subscriptionId: string
): string => {
  switch (subscriptionId) {
    case NEXTROUTE_SUBSCRIPTION_ID:
      return NEXTROUTE_TEMPLATE_NAME;
    case SHIFT_SCHEDULING_SUBSCRIPTION_ID:
      return SHIFT_SCHEDULING_TEMPLATE_NAME;
    case ROUTING_LEGACY_SUBSCRIPTION_ID:
      return ROUTING_LEGACY_TEMPLATE_NAME;
    case ORDER_FULFILLMENT_SUBSCRIPTION_ID:
      return ORDER_FULFILLMENT_TEMPLATE_NAME;
    case ORTOOLS_SHIFT_PLANNING_SUBSCRIPTION_ID:
      return ORTOOLS_SHIFT_PLANNING_TEMPLATE_NAME;
    case ORTOOLS_SIMPLE_ROUTING_SUBSCRIPTION_ID:
      return ORTOOLS_SIMPLE_ROUTING_TEMPLATE_NAME;
    case ORTOOLS_SHIFT_ASSIGNMENT_SUBSCRIPTION_ID:
      return ORTOOLS_SHIFT_ASSIGNMENT_TEMPLATE_NAME;
    case PYOMO_SHIFT_ASSIGNMENT_SUBSCRIPTION_ID:
      return PYOMO_SHIFT_ASSIGNMENT_TEMPLATE_NAME;
    case PYOMO_SHIFT_PLANNING_SUBSCRIPTION_ID:
      return PYOMO_SHIFT_PLANNING_TEMPLATE_NAME;
    case KNAPSACK_SUBSCRIPTION_ID:
      return KNAPSACK_TEMPLATE_NAME;
    case ORTOOLS_KNAPSACK_SUBSCRIPTION_ID:
      return ORTOOLS_KNAPSACK_TEMPLATE_NAME;
    case PYOMO_KNAPSACK_SUBSCRIPTION_ID:
      return PYOMO_KNAPSACK_TEMPLATE_NAME;
    case ORTOOLS_DEMAND_FORECASTING_SUBSCRIPTION_ID:
      return ORTOOLS_DEMAND_FORECASTING_TEMPLATE_NAME;
    case AMPL_PRICE_OPTIMIZATION_SUBSCRIPTION_ID:
      return AMPL_PRICE_OPTIMIZATION_TEMPLATE_NAME;
    case AMPL_FACILITY_LOCATION_SUBSCRIPTION_ID:
      return AMPL_FACILITY_LOCATION_TEMPLATE_NAME;
    case ROUTING_PYVROOM_SUBSCRIPTION_ID:
      return ROUTING_PYVROOM_TEMPLATE_NAME;
    case HIGHS_KNAPSACK_SUBSCRIPTION_ID:
      return HIGHS_KNAPSACK_TEMPLATE_NAME;
    default:
      return "";
  }
};
