import { NewUserQuestionsByPage } from "../NewUserQuestions.types";

export const userQuestionsByPage: NewUserQuestionsByPage = [
  [
    {
      id: "interested-in",
      type: "checkbox",
      title: "What are you most interested in with Nextmv?",
      hubspotField: "what_are_you_interested_in_",
      options: [
        {
          id: "model-customization",
          label: "Model customization",
          selected: false,
        },
        {
          id: "infra-deploy-manage",
          label: "Infrastructure (deploy, manage)",
          selected: false,
        },
        {
          id: "scaling-use-cases",
          label: "Scaling to more use cases",
          selected: false,
        },
        {
          id: "model-testing",
          label: "Model testing",
          selected: false,
        },
        {
          id: "ci-cd-workflows",
          label: "CI/CD workflows",
          selected: false,
        },
      ],
    },
    {
      id: "team-size",
      type: "dropdown",
      title: "How big is your decision science / OR team?",
      hubspotField: "how_big_is_your_decision_science_or_team_",
      options: [
        { id: "size-1", label: "1", selected: false },
        { id: "size-2-5", label: "2 - 5", selected: false },
        { id: "size-6-10", label: "6 - 10", selected: false },
        { id: "size-10-plus", label: "10+", selected: false },
      ],
    },
  ],
  [
    {
      id: "solutions-in-use",
      type: "checkbox",
      title: "What optimization solutions do you use?",
      hubspotField: "what_optimization_solutions_do_you_use_",
      options: [
        { id: "or-tools", label: "OR-Tools", selected: false },
        { id: "highs", label: "HiGHS", selected: false },
        { id: "pyomo", label: "Pyomo", selected: false },
        { id: "gurobi", label: "Gurobi", selected: false },
        { id: "ampl", label: "AMPL", selected: false },
        { id: "xpress", label: "Xpress", selected: false },
        { id: "cplex", label: "CPLEX", selected: false },
        { id: "vroom", label: "VROOM", selected: false },
        { id: "other", label: "Other", selected: false },
      ],
    },
    {
      id: "categorize-projects-as",
      type: "dropdown",
      orientation: "up",
      title: "How do you mainly characterize your project(s)?",
      hubspotField: "how_do_you_mainly_characterize_your_project_s__",
      options: [
        {
          id: "work-professional",
          label: "Work/professional",
          selected: false,
        },
        {
          id: "consultative",
          label: "Consultative",
          selected: false,
        },
        {
          id: "research",
          label: "Research",
          selected: false,
        },
        {
          id: "academic",
          label: "Academic",
          selected: false,
        },
        {
          id: "personal",
          label: "Personal",
          selected: false,
        },
        {
          id: "other",
          label: "Other",
          selected: false,
        },
      ],
    },
    {
      id: "decision-model-project-timeline",
      type: "dropdown",
      orientation: "up",
      title: "What is your decision model project timeline?",
      hubspotField: "what_is_your_decision_mode_project_timeline_",
      options: [
        { id: "right-now", label: "Right now", selected: false },
        { id: "1-month", label: "1 month from now", selected: false },
        { id: "3-months", label: "3 months from now", selected: false },
        { id: "6-months", label: "6 months from now", selected: false },
      ],
    },
  ],
  [
    {
      id: "typical-run-frequency",
      type: "dropdown",
      title: "How often do you typically run your model(s)?",
      hubspotField: "how_often_do_you_typically_run_your_model_s__",
      options: [
        { id: "by-the-minute", label: "By the minute", selected: false },
        { id: "hourly", label: "Hourly", selected: false },
        { id: "daily", label: "Daily", selected: false },
        { id: "weekly", label: "Weekly", selected: false },
        { id: "monthly", label: "Monthly", selected: false },
        { id: "other", label: "Other", selected: false },
      ],
    },
    {
      id: "typical-run-length",
      type: "dropdown",
      title: "How long do you typically run your model(s) for?",
      hubspotField: "how_long_do_you_typically_run_your_model_s__for_",
      options: [
        { id: "seconds", label: "Seconds", selected: false },
        { id: "minutes", label: "Minutes", selected: false },
        { id: "hours", label: "Hours", selected: false },
        { id: "other", label: "Other", selected: false },
      ],
    },
  ],
];
