export const getIterativeNumeral = (
  number: number,
  noun: string = "times"
): string => {
  switch (number) {
    case 1:
      return "once";
    case 2:
      return "twice";
    default:
      return `${number} ${noun}`;
  }
};
