import styled from "@emotion/styled";

import { rem } from "../../lib/tools";
import Box from "../Box";
import Flex from "../Flex";

import {
  getPointerArrow,
  getPointerContainer,
  getPositionValue,
  getTourPanelStyles,
} from "./utils/tourPanel";
import { StyledPointerProps, StyledTourPanelProps } from "./Tour.types";

export const StyledHighlightContainer = styled(Box)<StyledTourPanelProps>`
  position: fixed;
  ${(props) => getPositionValue("left", props.left, props.theme)}
  ${(props) => getPositionValue("right", props.right, props.theme)}
  ${(props) => getPositionValue("top", props.top, props.theme)}
  ${(props) => getPositionValue("bottom", props.bottom, props.theme)}
`;

export const StyledHighlightDisplayMobile = styled.div`
  display: block;

  @media (min-width: ${({ theme }) => theme.breakpoint.sm}) {
    display: none;
  }
`;

export const StyledHighlightDisplayDefault = styled.div`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoint.sm}) {
    display: block;
  }
`;

export const StyledTourPanel = styled(Box)<StyledTourPanelProps>`
  ${(props) =>
    getTourPanelStyles({
      position: props.pointerPosition,
      theme: props.theme,
    })}

  position: fixed;
  ${(props) => getPositionValue("left", props.left, props.theme)}
  ${(props) => getPositionValue("right", props.right, props.theme)}
  ${(props) => getPositionValue("top", props.top, props.theme)}
  ${(props) => getPositionValue("bottom", props.bottom, props.theme)}
  border-radius: ${({ theme }) => theme.border.radius};
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: 0 ${rem(2)} ${rem(24)} ${rem(-4)}
      ${({ theme }) => theme.color.gray100T},
    0 ${rem(2)} ${rem(11)} ${rem(-4)} ${({ theme }) => theme.color.gray200T};
`;

export const StyledFlex = styled(Flex)`
  border-bottom-right-radius: ${({ theme }) => theme.border.radius};
  border-bottom-left-radius: ${({ theme }) => theme.border.radius};
`;

export const StyledPointerContainer = styled(Flex)<StyledPointerProps>`
  position: absolute;

  ${(props) =>
    getPointerContainer({
      align: props.pointerAlign,
      position: props.pointerPosition,
      theme: props.theme,
    })}
`;

export const StyledPointerArrow = styled.span<StyledPointerProps>`
  display: block;
  width: 0;
  height: 0;
  position: relative;

  ${(props) =>
    getPointerArrow({
      align: props.pointerAlign,
      position: props.pointerPosition,
      theme: props.theme,
    })}
`;
