type GetRunHistoryEmptyMessageParams = {
  filterText?: string;
  isLoading?: boolean;
  isRunsQueue?: boolean;
};
export const getRunHistoryEmptyMessage = ({
  filterText,
  isLoading,
  isRunsQueue,
}: GetRunHistoryEmptyMessageParams) => {
  if (filterText) return "No runs match this query";
  return isLoading
    ? "Loading…"
    : isRunsQueue
    ? "No runs in queue for this application"
    : "No runs available";
};
