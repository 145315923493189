import * as React from "react";

import BaseIllustration, { IllustrationProps } from "./BaseIllustration";

const IllustrationBunnyCharged = ({
  illustrationWidth,
  illustrationHeight,
}: IllustrationProps) => {
  return (
    <BaseIllustration
      {...{ illustrationWidth, illustrationHeight }}
      viewBox={[0, 0, 80, 56]}
    >
      <path d="M66.737 51.1071H0L5.06 6.17188H66.737V51.1071Z" fill="#1B9CAA" />
      <path
        d="M77.698 55.5101H7.25601L6.25 7.24609H68.089L77.698 55.5101Z"
        fill="white"
      />
      <path
        d="M78.2879 55.9961H6.78192L5.75391 6.76562H68.4829L78.2879 55.9961ZM7.72992 55.0291H77.1099L67.6899 7.73252H6.74091L7.72992 55.0291Z"
        fill="#1B9CAA"
      />
      <path
        d="M17.619 12.3511V10.4175C19.442 10.4175 20.927 8.5153 20.927 6.1756C20.927 3.8359 19.442 1.9336 17.619 1.9336C15.795 1.9336 14.31 3.8359 14.31 6.1756H12.375C12.375 2.7699 14.726 0 17.619 0C20.511 0 22.862 2.7699 22.862 6.1756C22.862 9.5812 20.511 12.3511 17.619 12.3511Z"
        fill="#FF9773"
      />
      <path
        d="M37.6971 12.3511V10.4175C39.5201 10.4175 41.0051 8.5153 41.0051 6.1756C41.0051 3.8359 39.5201 1.9336 37.6971 1.9336C35.8731 1.9336 34.3881 3.8359 34.3881 6.1756H32.4531C32.4531 2.7699 34.8041 0 37.6971 0C40.5891 0 42.9401 2.7699 42.9401 6.1756C42.9401 9.5812 40.5871 12.3511 37.6971 12.3511Z"
        fill="#FF9773"
      />
      <path
        d="M57.7752 12.3511V10.4175C59.5992 10.4175 61.0842 8.5153 61.0842 6.1756C61.0842 3.8359 59.5992 1.9336 57.7752 1.9336C55.9512 1.9336 54.4662 3.8359 54.4662 6.1756H52.5312C52.5312 2.7699 54.8822 0 57.7752 0C60.6682 0 63.0182 2.7699 63.0182 6.1756C63.0182 9.5812 60.6652 12.3511 57.7752 12.3511Z"
        fill="#FF9773"
      />
      <path
        d="M24.6021 26.6577H14.3711V19.6289H24.6021V26.6577ZM15.3391 25.6909H23.6341V20.5957H15.3391V25.6909Z"
        fill="#1B9CAA"
      />
      <path
        d="M37.9693 26.6577H27.7383V19.6289H37.9693V26.6577ZM28.7063 25.6909H37.0023V20.5957H28.7063V25.6909Z"
        fill="#1B9CAA"
      />
      <path
        d="M50.8489 20.1055H41.5859V26.1723H50.8489V20.1055Z"
        fill="#FF9773"
      />
      <path
        d="M64.9521 26.1747H54.9531V20.1055H64.3211L64.9521 26.1747Z"
        fill="#FF9773"
      />
      <path
        d="M24.6021 48.2561H14.3711V41.2231H24.6021V48.2561ZM15.3391 47.2891H23.6341V42.1891H15.3391V47.2891Z"
        fill="#1B9CAA"
      />
      <path
        d="M24.1146 30.9961H14.8516V37.0631H24.1146V30.9961Z"
        fill="#A1DFE6"
      />
      <path
        d="M37.9693 37.5451H27.7383V30.5039H37.9693V37.5451ZM28.7063 36.5781H37.0023V31.4707H28.7063V36.5781Z"
        fill="#1B9CAA"
      />
      <path
        d="M51.3354 37.5451H41.1094V30.5039H51.3404L51.3354 37.5451ZM42.0724 36.5781H50.3684V31.4707H42.0774L42.0724 36.5781Z"
        fill="#1B9CAA"
      />
      <path
        d="M66.6627 37.5451H54.4727V30.5039H65.9317L66.6627 37.5451ZM55.4397 36.5781H65.5977L65.0567 31.4707H55.4377L55.4397 36.5781Z"
        fill="#1B9CAA"
      />
      <path
        d="M37.4818 41.7031H28.2188V47.7701H37.4818V41.7031Z"
        fill="#FFC0AB"
      />
      <path
        d="M50.8489 41.7031H41.5859V47.7701H50.8489V41.7031Z"
        fill="#FFC0AB"
      />
      <path
        d="M67.2591 47.7701H54.9531V41.7031H66.6621L67.2591 47.7701Z"
        fill="#FFFD54"
      />
    </BaseIllustration>
  );
};

export default IllustrationBunnyCharged;
