export const configMessage = {
  largeOutput:
    "Download output file to view configuration options for the run (if present).",
  pending:
    "Configuration options for the run, if present, will be displayed once the run has completed.",
  tooltip: {
    content:
      "The configuration table contains all of the configuration that was applied to the run. This includes run-defined configuration, instance-defined configuration, and the application’s default configuration.",
    moreUrl:
      "https://www.nextmv.io/docs/platform/deploy-app/instances#configuration",
  },
};

export const configRunMessage = {
  tooltip: {
    content:
      "Configuration defined on the run is shown in the table below. Run-defined configuration will override configuration defined on the instance.",
  },
};

export const configRunInstanceMessage = {
  tooltip: {
    content:
      "Configuration defined on the instance is shown in the table below. Instance-defined configuration will override default configuration defined by the application. Note that the table below includes any run-defined configuration overrides. The default configuration for the instance can be found in the details view for the instance used.",
  },
};

export const objectiveMessage = {
  largeOutput:
    "Download output file to view objectives for the run (if present).",
  pending:
    "Objectives for the run, if present, will be displayed once the run has completed.",
  tooltip: {
    content:
      "Definitions for the run’s objective are displayed in the table below.",
  },
};

export const statisticsMessage = {
  largeOutput:
    "Download output file to view statistics for the run (if present).",
  pending:
    "Statistics for the run, if present, will be displayed once the run has completed.",
  tooltip: {
    content:
      "Statistics are defined by the application and are surfaced to a run (and experiment) by following the statistics convention in the run output.",
    moreUrl:
      "https://www.nextmv.io/docs/platform/experiments-tests#statistics-convention",
  },
};
