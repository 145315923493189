import * as React from "react";
import { components } from "react-select";
import styled from "@emotion/styled";

import Flex from "../../Flex";

export const Capitalize = styled.span`
  text-transform: capitalize;
`;

// get Option base component from library
const { Option } = components;

const CustomOptionApp = (props: any) => {
  // get data to build option, this is the object from
  // the data array for this particular option
  const label = props?.data?.label;

  return (
    <Option {...props}>
      <Flex width="100%" alignItems="center">
        {label}
      </Flex>
    </Option>
  );
};

export default CustomOptionApp;
