export const EC_DEFAULT = "6c9500mb870s" as const;
export const EC_8C_16GB_120M = "8c16gb120m" as const;
export const EC_8C_16GB_12H = "8c16gb12h" as const;
export const EC_16C_60GB_30M = "16c60gb30m" as const;
export const EC_16C_60GB_12H = "16c60gb12h" as const;

export const LOGS_TAB_ID = "log-output" as const;
export const LOGS_LIVE_TAB_ID = "live" as const;

export const RUN_FORMAT_JSON = "json" as const;
export const RUN_FORMAT_TEXT = "text" as const;
export const RUN_FORMAT_CSV = "csv" as const;
export const RUN_FORMAT_CSV_ARCHIVE = "csv-archive" as const;
export const RUN_FORMAT_UNKNOWN = "unknown" as const;

export const RUN_STATUS_V2_CANCELED = "canceled" as const;
export const RUN_STATUS_V2_FAILED = "failed" as const;
export const RUN_STATUS_V2_QUEUED = "queued" as const;
export const RUN_STATUS_V2_RUNNING = "running" as const;
export const RUN_STATUS_V2_SUCCEEDED = "succeeded" as const;
