import { formatTime } from "@console/dsc/src/lib/tools";
import { DateTime } from "luxon";

const unitPortion = new RegExp(/[a-zµ]/g);
const numPortion = new RegExp(/[.0-9]/g);

export function timeStringToSeconds(s: string): number {
  const n = Number(s.replace(unitPortion, ""));
  const unit = s.replace(numPortion, "");

  switch (unit) {
    case "µs":
      return n * 0.000001;
    case "ms":
      return n * 0.001;
    case "m":
      return n * 60;
    default:
      return n;
  }
}

// converts a timeString to seconds or a larger time unit
// returns string in either milliseconds or seconds
export function roundedTimeStringWithUnits(s: string): string {
  const n = Number(s.replace(unitPortion, ""));
  let unit = s.replace(numPortion, "");
  let number: number;

  switch (unit) {
    case "µs":
      number = n * 0.000001;
      break;
    case "ms":
      number = n * 0.001;
      break;
    default:
      number = n;
  }

  return `${formatTime(number)}`;
}

export function formatDateTimeFromServer(
  rawDateString: string
): [string?, string?, string?] {
  if (!rawDateString) return [];

  const dateTime = DateTime.fromISO(rawDateString);

  return [
    dateTime.toFormat("h:mm:ss a"),
    dateTime.toFormat("yyyy-MM-dd"),
    dateTime.toFormat("ZZZZ"),
  ];
}

export const formatDateTime12Hour = (rawDateString: string): string => {
  if (!rawDateString) return "";

  return DateTime.fromISO(rawDateString).toFormat(
    "yyyy-MM-dd · h:mm:ss a ZZZZ"
  );
};
