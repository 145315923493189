import React from "react";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";

import { LocationState } from "../../utils/navigation";

import Identify from "./subpages/Identity";
import Password from "./subpages/Password";
import PasswordForgot from "./subpages/PasswordForgot";
import PasswordReset from "./subpages/PasswordReset";

const LogInRoutes = () => {
  const { path } = useRouteMatch();
  const { state } = useLocation<LocationState>();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const username = params.get("username");
  const verificationCode = params.get("verifycode");

  return (
    <Switch>
      <Route exact path={`${path}/identify`}>
        <Identify {...{ username }} />
      </Route>
      <Route exact path={`${path}/password`}>
        <Password {...{ username, verificationCode }} />
      </Route>

      <Route exact path={`${path}/password/forgot`}>
        <PasswordForgot {...{ username }} />
      </Route>
      <Route exact path={`${path}/password/reset`}>
        <PasswordReset {...{ username, verificationCode }} />
      </Route>

      <Route
        exact
        path={path}
        render={(props) => {
          if (username || verificationCode) {
            return (
              <Redirect
                to={{
                  pathname: `${path}/password`,
                  state: state,
                  search: props.location.search,
                }}
              />
            );
          }
          return (
            <Redirect
              to={{
                pathname: `${path}/identify`,
                state: state,
              }}
            />
          );
        }}
      />
    </Switch>
  );
};

export default LogInRoutes;
