import { Redirect, Route } from "react-router-dom";

import { ParsedFeatureFlags } from "../api/core/dataPlane.types";

import { AuthenticatedRoutes } from "./authenticatedConfig";

export const accPrefix = "acc";

export const getAccPrefix = (id: string) => {
  return `/${accPrefix}/${id}`;
};

export const createRoutes = (
  authenticatedRoutes: AuthenticatedRoutes,
  userFeatures: ParsedFeatureFlags | undefined,
  id: string | null | undefined
) => {
  if (!userFeatures) {
    console.error("dependencies not present for routing");
    return [];
  }

  const pathAccPrefix = getAccPrefix(id || "");

  const routes = [];
  for (const [
    path,
    { Component, redirect, exact, providers, flags, noOrgInRoute, renderFunc },
  ] of Object.entries(authenticatedRoutes)) {
    const renderAccountRedirect = !noOrgInRoute && id;

    // Check user features if flags
    if (flags && flags.length) {
      if (!userFeatures) {
        continue;
      }

      let allowed = true;
      for (const flag of flags) {
        if (!userFeatures[flag]) {
          allowed = false;
          break;
        }
      }

      if (!allowed) {
        continue;
      }
    }

    // create path with orgId
    const orgIdPath = `${pathAccPrefix}${path}`;

    if (
      (Component && redirect) ||
      (Component && !!renderFunc) ||
      (redirect && !!renderFunc)
    ) {
      console.error("config should one of Component, redirect, or renderFunc");
      continue;
    }

    if (redirect) {
      routes.push(
        <Route key={path} exact={!!exact} path={path}>
          <Redirect to={`${redirect}`} />
        </Route>
      );
      if (renderAccountRedirect) {
        routes.push(
          <Route key={orgIdPath} exact={!!exact} path={orgIdPath}>
            <Redirect to={`${redirect}`} />
          </Route>
        );
      }
      continue;
    }

    if (renderFunc) {
      routes.push(
        <Route key={path} path={path} exact={!!exact} render={renderFunc(id)} />
      );
      if (renderAccountRedirect) {
        routes.push(
          <Route
            key={orgIdPath}
            path={orgIdPath}
            exact={!!exact}
            render={renderFunc(id)}
          />
        );
      }
      continue;
    }

    const ComponentRedirectToOrgId = (
      <Route
        key={path}
        exact={!!exact}
        path={path}
        render={(props) => {
          return <Redirect to={`${pathAccPrefix}${props.location.pathname}`} />;
        }}
      />
    );

    const conditionalPath = renderAccountRedirect ? orgIdPath : path;

    if (providers && providers.length && Component) {
      const ComponentProviders = providers.reduce((Component, Provider) => {
        return <Provider>{Component}</Provider>;
      }, Component);

      routes.push(
        <Route key={conditionalPath} exact={!!exact} path={conditionalPath}>
          {ComponentProviders}
        </Route>
      );
      if (renderAccountRedirect) {
        routes.push(ComponentRedirectToOrgId);
      }
      continue;
    }

    routes.push(
      <Route key={conditionalPath} exact={!!exact} path={conditionalPath}>
        {Component}
      </Route>
    );
    if (renderAccountRedirect) {
      routes.push(ComponentRedirectToOrgId);
    }
  }
  return routes;
};
