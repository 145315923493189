import React from "react";
import { useMap } from "react-leaflet";

import {
  DemoTabs,
  DepotInfoStop,
  MarkerPoint,
  RouteSetByID,
  VisibleVehicleRouteIds,
} from "../../Map.types";
import { FIT_BOUNDS_OPTIONS } from "../../utils/constants";
import { calculateBounds } from "../../utils/mapping";

import MarkerIcon from "./components/MarkerIcon";
import PopupDepotList from "./components/PopupDepotList";
import StopAnnotations from "./components/StopAnnotations";
import useRenderMarkerHelpers from "./hooks/useRenderMarkerHelpers";

export type MapMarkerProps = {
  activeTab: DemoTabs;
  displayAnnotations: boolean;
  points: MarkerPoint[];
  displayVehiclesNoRoutes?: boolean;
  focusedRouteId?: string;
  routesById?: RouteSetByID;
  visibleVehicleRoutesById?: VisibleVehicleRouteIds;
};

export default function MapMarkers({
  activeTab,
  displayAnnotations,
  displayVehiclesNoRoutes,
  focusedRouteId,
  points,
  routesById,
  visibleVehicleRoutesById,
}: MapMarkerProps) {
  const map = useMap();
  const [needsBounds, setNeedsBounds] = React.useState(true);

  React.useEffect(() => {
    if (points.length) {
      map.fitBounds(
        calculateBounds(points.map((p) => p.position)),
        FIT_BOUNDS_OPTIONS
      );
    }
  }, [setNeedsBounds, needsBounds, map, points]);

  const { shouldRenderMarker, doesVehicleHaveRoute } = useRenderMarkerHelpers({
    routesById,
    displayVehiclesNoRoutes,
    visibleVehicleRoutesById,
  });

  const renderPopupDepotList = (
    stopKind: string,
    depotInfo: DepotInfoStop[]
  ) => {
    return (
      depotInfo.some((t) => t?.vehicleKind === stopKind) && (
        <PopupDepotList
          stopKind={stopKind}
          depotInfo={depotInfo}
          displayVehiclesNoRoutes={displayVehiclesNoRoutes}
          doesVehicleHaveRoute={doesVehicleHaveRoute}
        />
      )
    );
  };

  return (
    <>
      {displayAnnotations && visibleVehicleRoutesById && (
        <StopAnnotations
          points={points.filter((point) => point.kind === "stop")}
          visibleVehicleRoutesById={visibleVehicleRoutesById}
        />
      )}
      {points.map((point, i) => (
        <MarkerIcon
          key={`${point.vehicleId}+${i}`}
          point={point}
          activeTab={activeTab}
          shouldRenderMarker={shouldRenderMarker}
          renderPopupDepotList={renderPopupDepotList}
          routesById={routesById ? routesById : undefined}
          isFocusActive={!!focusedRouteId}
          isFocusedRoute={focusedRouteId === point.vehicleId}
        />
      ))}
    </>
  );
}
