import { Link } from "react-router-dom";
import {
  styleHideVisually,
  styleScrollbarY,
} from "@console/dsc/src/lib/constants";
import { rem } from "@console/dsc/src/lib/tools";
import styled from "@emotion/styled";

import {
  ORG_SWITCHER_MAX_HEIGHT,
  ORG_SWITCHER_WIDTH,
} from "../../utils/constants";

import {
  InvitesLinkProps,
  StyledInvitesCountProps,
  StyledOrgSwitcherProps,
  StyledTeamOptionProps,
} from "./OrgSwitcher.types";

export const StyledOrgSwitcher = styled.ul<StyledOrgSwitcherProps>`
  box-sizing: border-box;
  width: ${ORG_SWITCHER_WIDTH};
  max-height: ${ORG_SWITCHER_MAX_HEIGHT};
  padding: ${({ theme }) => theme.spacing.s3};
  padding-right: 0;
  position: absolute;
  left: ${rem(46)};
  top: calc(
    ${({ theme }) => theme.spacing.s7} + ${({ theme }) => theme.spacing.s1}
  );
  overflow-y: scroll;

  list-style: none;
  border-radius: ${({ theme }) => theme.border.radius};
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: 0 0 0 ${rem(1)} ${({ theme }) => theme.color.gray200T},
    0 ${rem(2)} ${rem(29)} ${rem(-4)} ${({ theme }) => theme.color.gray200T},
    0 ${rem(2)} ${rem(11)} ${rem(-4)} ${({ theme }) => theme.color.gray300T};

  ${(props) => styleScrollbarY(props.theme)}

  /* visually hide org switcher */
  ${(props) => !props.isActive && styleHideVisually}
`;

export const StyledTeamOption = styled.li<StyledTeamOptionProps>`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  padding: ${({ theme }) => theme.spacing.s2};
  cursor: pointer;
  border-radius: ${({ theme }) => theme.border.radius};
  background-color: ${(props) =>
    props.isCurrentTeam ? props.theme.color.sky200 : props.theme.color.white};

  &:hover,
  &:focus,
  &:active {
    h4 {
      color: ${({ theme }) => theme.color.gray800};
    }
  }
`;

export const StyledInvitesLink = styled(Link)<InvitesLinkProps>`
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    span {
      color: ${(props) =>
        props.iszero ? props.theme.color.gray600 : props.theme.color.gray800};
    }
  }
`;

export const StyledInvitesCount = styled.span<StyledInvitesCountProps>`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ theme }) => theme.spacing.s6};
  height: ${({ theme }) => theme.spacing.s6};
  margin-left: auto;
  border-radius: 100%;
  background-color: ${(props) =>
    props.isZero ? props.theme.color.gray400 : props.theme.color.red500};
`;
