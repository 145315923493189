import * as React from "react";
import { components } from "react-select";
import { useTheme } from "@emotion/react";

const { DropdownIndicator } = components;

const CustomDropdownIndicator = (props: any) => {
  const theme = useTheme();

  const { isDisabled, isFocused } = props;

  const dropdownIndicatorColor = isDisabled
    ? theme.color.gray400
    : isFocused
    ? theme.color.gray600
    : theme.color.gray500;

  return (
    <DropdownIndicator {...props}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill={dropdownIndicatorColor}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M4.26666 6.40002H11.7333L8 11.2L4.26666 6.40002Z" />
      </svg>
    </DropdownIndicator>
  );
};

export default CustomDropdownIndicator;
