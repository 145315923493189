import React from "react";

import { useUser } from "../../AuthProvider";
import Layout from "../../components/Layout";
import { checkHasTeamsAccess } from "../../utils/authProviderHelpers";

import { getTeamPages } from "./data/pages";
import { isCurrentUserRoot } from "./subpages/Members/utils/isCurrentUserRoot";
import TeamRoutes from "./Team.routes";

const Team = () => {
  const [user] = useUser();
  const { id: accountId, roles, organizations } = user;
  const isUserNonRootOnUnpaidAccount =
    !isCurrentUserRoot(organizations, roles) && !checkHasTeamsAccess(user);

  return (
    <Layout
      sectionTitle="Team"
      sectionPages={getTeamPages({
        isUserNonRootOnUnpaidAccount,
        userId: accountId,
        userRoles: roles || [],
      })}
    >
      <TeamRoutes />
    </Layout>
  );
};

export default Team;
