import { Link } from "react-router-dom";
import {
  AvatarAppInstance,
  Box,
  Flex,
  PreviewRow2,
  Tag,
  Text,
} from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";

import { trackEvent } from "../../../../../analytics";
import {
  AppInstancesData,
  AppResponse,
} from "../../../../../api/core/controlPlane.types";
import {
  ParsedFeatureFlags,
  UserRoles,
} from "../../../../../api/core/dataPlane.types";
import { getAccUrl } from "../../../../../utils/navigation";
import { userHasAccessToAction } from "../../../../../utils/rbac_utils";
import { ActionGroups } from "../../../../../utils/rbac_utils/types";
import ExecutionClass from "../../../components/ExecutionClass";
import InstanceConfig from "../../../components/InstanceConfig";
import { isInstanceDefault } from "../../../utils/instances";

type RenderInstancesListParams = {
  app: AppResponse;
  canUserCreateAndEditInstances: boolean;
  instances: AppInstancesData;
  theme: any;
  accountId?: string | null;
  features?: ParsedFeatureFlags;
  isMini?: boolean;
  limit?: number;
  userRoles?: UserRoles;
};
export const renderInstancesList = ({
  accountId,
  app,
  canUserCreateAndEditInstances,
  features = {},
  instances,
  isMini,
  limit,
  theme,
  userRoles = [],
}: RenderInstancesListParams) => {
  if (!instances) return;

  if (!instances.length) {
    return (
      <Text
        testId="instances-not-found-message"
        mt={isMini ? 3 : 5}
        styleName={isMini ? "body-3" : "body-2"}
        hasLink
      >
        No instances found.{" "}
        {isMini && canUserCreateAndEditInstances && (
          <Link to={getAccUrl(accountId, `/app/${app.id}/instances/new`)}>
            Create new instance
          </Link>
        )}
      </Text>
    );
  }

  const returnedInstances = limit ? instances.slice(0, limit) : instances;

  return returnedInstances.map((instance, index) => {
    const isDefaultInstance = isInstanceDefault({ app, instance });
    const canUserEditInstance =
      canUserCreateAndEditInstances &&
      userHasAccessToAction(
        userRoles,
        ActionGroups.AppInstanceOperator,
        instance
      );

    return (
      <PreviewRow2
        {...{ isMini }}
        key={instance.id}
        className="instance-list-view-item"
        hasNoBorder={index === 0}
        icon={
          <Box mt={rem(1)} ml={1}>
            <AvatarAppInstance size={isMini ? 20 : 24} />
          </Box>
        }
        name={instance.name}
        id={instance.id}
        lastUpdated={instance?.updated_at}
        tag={
          isDefaultInstance ? (
            <Tag
              ml={isMini ? 0 : 2}
              mr={1}
              label="default"
              size="small"
              backgroundColor={theme.color.green200}
              textColor={theme.color.green800}
              {...(isMini && {
                styles: {
                  flexShrink: 0,
                },
              })}
            />
          ) : undefined
        }
        url={getAccUrl(accountId, `/app/${app.id}/instance/${instance.id}`)}
        urlOnClick={() =>
          trackEvent("Apps", {
            view: "App Instances",
            action: "Link Clicked",
            meta: {
              type: "Instance Detail",
            },
          })
        }
        {...(!isMini && {
          description: instance?.description,
          metaSubDescription: (
            <Flex alignItems="center" mt={rem(-2)}>
              <Text
                styleName="body-3"
                mt={rem(-1)}
                styles={{ color: theme.color.gray600 }}
              >
                Version
              </Text>
              <Tag mt={rem(1)} ml={1} label={instance.version_id} />
            </Flex>
          ),
          ...(instance?.configuration?.execution_class && {
            metaExtra1: (
              <Flex mt={rem(1)}>
                <ExecutionClass
                  isAbbreviated
                  isSmall
                  executionClass={instance.configuration.execution_class}
                />
              </Flex>
            ),
          }),
          ...(instance?.configuration?.options && {
            metaExtra2: (
              <Box mt={1}>
                <InstanceConfig
                  isAbbreviated
                  options={instance.configuration.options}
                />
              </Box>
            ),
          }),
        })}
        {...(!isMini &&
          canUserEditInstance && {
            urlEdit: getAccUrl(
              accountId,
              `/app/${app.id}/instance/${instance.id}/edit?source=list`
            ),
            urlEditOnClick: () =>
              trackEvent("Apps", {
                view: "App Instances",
                action: "Link Clicked",
                meta: {
                  type: "Instance Edit",
                },
              }),
          })}
      />
    );
  });
};
