import React from "react";

const LogoIconNextmv = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6098 10C14.6098 10 18.4785 13.4291 20.0612 19.408C21.163 23.5704 20.9404 30.3986 20.9404 30.3986H18.4785C18.4785 30.3986 14.5239 24.9155 13.7306 20.8148C12.8921 16.4807 14.6098 10 14.6098 10ZM16.8571 15.3231C16.8571 15.3231 15.2789 16.7462 16.1516 20.8389C17.0244 24.9316 19.9665 29.5563 19.9665 29.5563C19.9665 29.5563 19.8212 26.7395 19.2943 22.314C18.7675 17.8886 16.8571 15.3231 16.8571 15.3231Z"
        fill="white"
        fillOpacity="0.9"
      />
      <path
        d="M21.7315 21.2544C21.3832 19.1319 20.5885 16.8581 20.5885 16.8581C20.5885 16.8581 21.2412 14.9238 22.2591 13.0774C23.277 11.2309 24.3693 10 24.3693 10C24.3693 10 26.7433 15.0117 26.4795 19.9355C26.2825 23.6114 24.8115 27.6304 24.0785 29.4314H22.0285C22.0285 29.4314 22.1709 23.9312 21.7315 21.2544Z"
        fill="#FAFBFB"
        fillOpacity="0.8"
      />
    </svg>
  );
};

export default LogoIconNextmv;
