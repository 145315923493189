import * as React from "react";

import BaseIcon, { IconProps } from "./BaseIcon";

const IconChevronRight = ({
  iconSize,
  iconColor,
  iconColorActive,
  iconColorFocus,
  iconColorHover,
}: IconProps) => {
  return (
    <BaseIcon
      {...{
        iconSize,
        iconColor,
        iconColorActive,
        iconColorFocus,
        iconColorHover,
      }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9965 7.99996L6.57052 2.94165L5.44165 3.92941L9.00339 7.99996L5.44165 12.0705L6.57052 13.0583L10.9965 7.99996Z"
      />
    </BaseIcon>
  );
};

export default IconChevronRight;
