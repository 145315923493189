import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  AvatarExperimentAcceptance,
  Box,
  Button2,
  Details,
  Flex,
  IconExternalLink,
  Loading,
  Notification,
  RowDetail,
  Table2,
  Tag,
  Text,
  Tooltip,
} from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";
import { DateTime } from "luxon";

import { trackEvent } from "../../../../analytics";
import {
  AcceptanceInstanceRef,
  BatchExperiment,
} from "../../../../api/core/controlPlane.types";
import { useUser } from "../../../../AuthProvider";
import Header from "../../../../components/Header";
import { useMetaTitle } from "../../../../components/Layout";
import StandardError from "../../../../components/StandardError";
import { MEASURE_ENTITY_ROW } from "../../../../config/general";
import useManageEntity from "../../../../hooks/useManageEntity";
import usePolling from "../../../../hooks/usePolling";
import { getIsCompletedState } from "../../../../utils/entities/getIsCompletedState";
import { getAccUrl } from "../../../../utils/navigation";
import { userHasAccessToAction } from "../../../../utils/rbac_utils";
import { ActionGroups } from "../../../../utils/rbac_utils/types";
import { AppPageProps } from "../../../App/App.types";
import useReturnPaths from "../../../App/hooks/useReturnPaths";
import { DEV_INT_ID } from "../../../App/subpages/RunDetails/utils/constants";
import ChartAreaAcceptance from "../../components/ChartAreaAcceptance";
import ExperimentProgressBar from "../../components/ExperimentProgressBar";
import { acceptanceTooltips } from "../../data/microcopy";
import { StyledOverflowXBox } from "../../Experiments.styled";
import { AcceptanceTestSummaries } from "../../Experiments.types";
import {
  getMetricOperatorSymbol,
  getSenseLabel,
} from "../../utils/acceptanceTestMetrics";
import { getIsExperimentResolved } from "../../utils/experimentStatus";
import { getAcceptanceTestSummaryData } from "../../utils/getAcceptanceTestSummaryData";
import { getResolvedVersionId } from "../../utils/getResolvedVersionId";
import { formatGroupedSummaryTableName } from "../../utils/groupedSummaryTable";

import {
  getBackgroundStatusColor,
  getStatusAvatar,
  getTextStatusColor,
} from "./utils/getAcceptanceTestStyles";

const AcceptanceTest = ({ app }: AppPageProps) => {
  const [, setMetaTitle] = useMetaTitle();
  const [{ id: accountId, roles }] = useUser();
  const { pathname } = useLocation();
  const params = useParams() as { id: string };
  const theme = useTheme();
  const { returnPathList } = useReturnPaths();

  const {
    entity: acceptanceTest,
    entityLoadError: acceptanceTestError,
    loadEntity: loadAcceptanceTest,
  } = useManageEntity("experiments/acceptance");
  const {
    entity: batchExperiment,
    entityMetadata: batchExperimentMetadata,
    entityLoadError: batchExperimentError,
    entityLoadMetadataError: batchExperimentMetadataError,
    loadEntityMetadata: loadBatchExperimentMetadata,
    loadEntity: loadBatchExperiment,
  } = usePolling(app, "experiments/batch");

  // page display
  useEffect(() => {
    if (acceptanceTest) {
      setMetaTitle(acceptanceTest.name);
    }
  }, [acceptanceTest, setMetaTitle]);

  // load acceptance test
  useEffect(() => {
    if (params.id && !acceptanceTest && !acceptanceTestError) {
      loadAcceptanceTest(app.id, params.id);
    }
  }, [
    acceptanceTest,
    acceptanceTestError,
    app.id,
    loadAcceptanceTest,
    params.id,
  ]);

  // poll batch experiment metadata
  useEffect(() => {
    if (!batchExperimentMetadata && !batchExperimentMetadataError) {
      loadBatchExperimentMetadata(app.id, params.id);
    }
  }, [
    app.id,
    batchExperimentMetadata,
    batchExperimentMetadataError,
    loadBatchExperimentMetadata,
    params.id,
  ]);

  // load full batch experiment on completion
  useEffect(() => {
    if (
      !batchExperiment &&
      batchExperimentMetadata &&
      getIsExperimentResolved(batchExperimentMetadata)
    ) {
      loadBatchExperiment(app.id, params.id);
    }
  }, [
    app.id,
    batchExperiment,
    batchExperimentMetadata,
    loadBatchExperiment,
    params.id,
  ]);

  // MARK must use batchExperiment here
  const renderVersionId = (
    instanceInfo: AcceptanceInstanceRef,
    batchExperiment: BatchExperiment
  ) => {
    const versionId = getResolvedVersionId(
      app.type,
      instanceInfo.instance_id,
      instanceInfo.version_id,
      batchExperiment
    );

    if (versionId) {
      return (
        <Flex alignItems="center">
          <Text styleName="body-2" styles={{ width: rem(100), shrink: 0 }}>
            Version ID
          </Text>
          <Tag
            ml={2}
            type="id"
            label={versionId}
            contentToCopy={versionId}
            {...(app.type &&
              app.type !== "subscription" && {
                url: getAccUrl(
                  accountId,
                  `/app/${app.id}/version/${versionId}`
                ),
              })}
          />
          {app.type === "subscription" &&
            versionId !== instanceInfo.version_id && (
              <Tooltip>
                Whenever an experiment is run with the latest instance,
                whichever version is assigned to the instance at that time is
                the version used for the experiment.
              </Tooltip>
            )}
        </Flex>
      );
    }

    return null;
  };

  const viewLoadError =
    acceptanceTestError || batchExperimentMetadataError || batchExperimentError;
  if (viewLoadError) {
    return <StandardError errorMessage={viewLoadError} />;
  }
  if (!acceptanceTest || !batchExperimentMetadata) {
    return <Loading type="full-screen" dotColor={theme.color.orange500} />;
  }

  const hasBatchExperimentGroupedSummaries =
    batchExperiment?.grouped_distributional_summaries &&
    !!batchExperiment.grouped_distributional_summaries.length;

  let acceptanceTestSummaries: AcceptanceTestSummaries = [];

  if (
    batchExperiment &&
    hasBatchExperimentGroupedSummaries &&
    acceptanceTest.id
  ) {
    acceptanceTestSummaries = getAcceptanceTestSummaryData(
      app.type,
      batchExperiment,
      acceptanceTest,
      theme
    );
  }

  const hasOneValidSummaryTable = acceptanceTestSummaries?.some(
    (acceptanceSummary: any) => {
      return !!acceptanceSummary?.summaryTable;
    }
  );

  const canUserCreateAndEditAcceptanceTest = userHasAccessToAction(
    roles,
    ActionGroups.ExperimentOperator,
    {}
  );

  const isCompletedState = getIsCompletedState(batchExperimentMetadata.status);

  return (
    <>
      <Header
        configPageTitle={{
          label: acceptanceTest.name,
          ancestorIcon: <AvatarExperimentAcceptance type="fill" size={24} />,
          ancestorLabel: "Acceptance Tests",
          ancestorUrl: returnPathList,
        }}
        configActionButton={{
          label: "Edit",
          url: `${pathname}/edit`,
          onClick: () =>
            trackEvent("AcceptanceTests", {
              view: "Acceptance Test",
              action: "Edit Button Clicked",
            }),
          isActionAllowed: canUserCreateAndEditAcceptanceTest,
        }}
        secondaryButton={[
          {
            label: "New acceptance test",
            url: getAccUrl(
              accountId,
              `app/${app.id}/experiments/acceptance/new`
            ),
            isActionAllowed: canUserCreateAndEditAcceptanceTest,
          },
          {
            label: "Clone acceptance test",
            url: getAccUrl(
              accountId,
              `app/${app.id}/experiments/acceptance/new?cloneId=${acceptanceTest.id}`
            ),
            isActionAllowed: canUserCreateAndEditAcceptanceTest,
          },
        ]}
      />

      <RowDetail
        hasNoBorder
        property="Name"
        render={
          <Box maxWidth={MEASURE_ENTITY_ROW}>
            <Text styleName="body-2">{acceptanceTest.name}</Text>
          </Box>
        }
      />

      <RowDetail
        property="Status"
        tooltipCopy={acceptanceTooltips.status.content}
        render={
          <Text styleName="body-2">{batchExperimentMetadata?.status}</Text>
        }
      />

      <RowDetail
        property="ID"
        tooltipCopy={acceptanceTooltips.id.content}
        render={
          <Flex>
            <Tag
              ml={rem(-6)}
              type="id"
              label={acceptanceTest.id}
              contentToCopy={acceptanceTest.id}
            />
          </Flex>
        }
      />

      {acceptanceTest?.description && (
        <RowDetail
          property="Description"
          render={
            <Box maxWidth={MEASURE_ENTITY_ROW}>
              <Text styleName="body-2">{acceptanceTest.description}</Text>
            </Box>
          }
        />
      )}

      <RowDetail
        property="Baseline"
        render={
          <>
            <Flex mt={-1} alignItems="center">
              <Text styleName="body-2" styles={{ width: rem(100), shrink: 0 }}>
                Instance ID
              </Text>
              <Tag
                ml={2}
                type="id"
                label={acceptanceTest.control.instance_id}
                contentToCopy={acceptanceTest.control.instance_id}
                {...(acceptanceTest.control.instance_id !== DEV_INT_ID && {
                  url: getAccUrl(
                    accountId,
                    `/app/${app.id}/instance/${acceptanceTest.control.instance_id}`
                  ),
                })}
              />
            </Flex>
            {renderVersionId(acceptanceTest.control, batchExperiment)}
          </>
        }
      />

      <RowDetail
        property="Candidate"
        render={
          <>
            <Flex mt={-1} alignItems="center">
              <Text styleName="body-2" styles={{ width: rem(100), shrink: 0 }}>
                Instance ID
              </Text>
              <Tag
                ml={2}
                type="id"
                label={acceptanceTest.candidate.instance_id}
                contentToCopy={acceptanceTest.candidate.instance_id}
                {...(acceptanceTest.candidate.instance_id !== DEV_INT_ID && {
                  url: getAccUrl(
                    accountId,
                    `/app/${app.id}/instance/${acceptanceTest.candidate.instance_id}`
                  ),
                })}
              />
            </Flex>
            {renderVersionId(acceptanceTest.candidate, batchExperiment)}
          </>
        }
      />

      {batchExperiment && batchExperiment.input_set_id && (
        <RowDetail
          property="Input set ID"
          tooltipCopy={acceptanceTooltips.inputSetId.content}
          render={
            <Flex>
              <Tag
                ml={rem(-6)}
                type="id"
                label={batchExperiment.input_set_id}
                contentToCopy={batchExperiment.input_set_id}
                url={getAccUrl(
                  accountId,
                  `/app/${app.id}/experiments/input-set/${batchExperiment.input_set_id}`
                )}
              />
            </Flex>
          }
        />
      )}

      <RowDetail
        property="Metric summary"
        tooltipCopy={acceptanceTooltips.metricsSummary.content}
        render={
          <Box>
            {acceptanceTest.metrics.map((metric, index) => (
              <Text
                key={`${metric.field}-${index}`}
                styleName="body-3"
                styles={{ color: theme.color.gray600 }}
              >
                <Text
                  as="code"
                  styleName="code"
                  styles={{ color: theme.color.gray800 }}
                >
                  {metric.field}
                </Text>{" "}
                ({getSenseLabel(metric.params.operator)})
              </Text>
            ))}
          </Box>
        }
      />

      <RowDetail
        property="Created"
        render={
          <Text
            as="time"
            styleName="body-2"
            dateTime={acceptanceTest.created_at}
            title={acceptanceTest.created_at}
          >
            {DateTime.fromISO(acceptanceTest.created_at).toFormat(
              "yyyy-MM-dd · h:mm:ss a ZZZZ"
            )}
          </Text>
        }
      />

      <RowDetail
        property="Source"
        render={
          <Text hasLinkBlack styleName="body-2">
            <a
              href={`/acc/${accountId}/app/${app.id}/experiment/batch/${acceptanceTest.experiment_id}`}
              target="_blank"
              rel="noreferrer"
            >
              {acceptanceTest.name}
            </a>{" "}
            (Batch)
          </Text>
        }
      />

      {batchExperimentMetadata.status === "started" && (
        <ExperimentProgressBar
          runsStarted={batchExperimentMetadata.number_of_runs}
          runsProcessed={batchExperimentMetadata.number_of_completed_runs}
          runsTotal={batchExperimentMetadata.number_of_requested_runs}
        />
      )}

      {!isCompletedState && (
        <Notification
          mb={[7, 7, 9]}
          type="tip"
          message="The acceptance test results will be displayed after the runs have completed and been processed."
        />
      )}

      {isCompletedState && (
        <Box
          mt={6}
          pt={7}
          ml={[0, -6]}
          mr={[0, -7]}
          mb={4}
          pr={[0, 7]}
          pl={[0, 6]}
          hasBorderTop
        >
          <StyledOverflowXBox width="100%">
            {hasOneValidSummaryTable && (
              <Flex
                justifyContent="space-between"
                minWidth={[rem(712), rem(712), rem(712), rem(712), rem(860)]}
              >
                <Flex
                  minWidth={[rem(280), rem(280), rem(280), rem(280), rem(300)]}
                >
                  <Text
                    as="h2"
                    styleName="header-1"
                    styles={{ color: theme.color.gray800 }}
                  >
                    Results
                  </Text>
                </Flex>
                <Flex
                  minWidth={[rem(400), rem(400), rem(400), rem(400), rem(560)]}
                  justifyContent="flex-end"
                >
                  <Flex
                    mr={9}
                    minWidth={[
                      rem(200),
                      rem(200),
                      rem(200),
                      rem(200),
                      rem(280),
                    ]}
                    alignItems="flex-end"
                    flexDirection="column"
                  >
                    <Text styleName="label">Candidate</Text>
                    <Text
                      styleName="body-2-bold"
                      styles={{ color: theme.color.gray800 }}
                    >
                      {acceptanceTest?.candidate?.instance_id}
                    </Text>
                  </Flex>
                  <Flex
                    pl={2}
                    minWidth={[
                      rem(200),
                      rem(200),
                      rem(200),
                      rem(200),
                      rem(280),
                    ]}
                    alignItems="flex-start"
                    flexDirection="column"
                  >
                    <Text styleName="label">Baseline</Text>
                    <Text
                      styleName="body-2-bold"
                      styles={{ color: theme.color.gray800 }}
                    >
                      {acceptanceTest?.control?.instance_id}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            )}
            {acceptanceTestSummaries &&
              acceptanceTestSummaries.map((acceptanceTestSummary, index) => {
                const acceptanceTestSummaryHtmlId =
                  acceptanceTestSummary.metricField.replace(/\./g, "-");
                return (
                  !!acceptanceTestSummary.summaryTable && (
                    <Box
                      key={`acceptance-compare-${index}`}
                      minWidth={[
                        rem(712),
                        rem(712),
                        rem(712),
                        rem(712),
                        rem(860),
                      ]}
                      mt={index === 0 ? 4 : 0}
                      hasBorderTop
                    >
                      <Details
                        key={`${acceptanceTestSummary.name}-${index}`}
                        size="large"
                        controlPosition="left"
                        htmlId={acceptanceTestSummaryHtmlId}
                        summary={
                          <Flex
                            justifyContent="space-between"
                            alignItems="center"
                            minWidth={[
                              rem(680),
                              rem(680),
                              rem(680),
                              rem(680),
                              rem(860),
                            ]}
                          >
                            <Flex
                              minWidth={[
                                rem(280),
                                rem(280),
                                rem(280),
                                rem(280),
                                rem(300),
                              ]}
                              alignItems={[
                                "flex-start",
                                "flex-start",
                                "flex-start",
                                "flex-start",
                                "center",
                              ]}
                              flexDirection={[
                                "column",
                                "column",
                                "column",
                                "column",
                                "row",
                              ]}
                            >
                              <Flex alignItems="center">
                                {getStatusAvatar(
                                  acceptanceTestSummary.status,
                                  acceptanceTestSummary.warning,
                                  theme
                                )}
                                <Text
                                  ml={2}
                                  as="h3"
                                  styleName="header-2"
                                  styles={{
                                    flexShrink: 0,
                                    color: theme.color.gray800,
                                  }}
                                >
                                  {formatGroupedSummaryTableName(
                                    acceptanceTestSummary.name
                                  )}
                                </Text>
                              </Flex>

                              <Tag
                                mt={rem(10)}
                                ml={[7, 7, 7, 7, 3]}
                                type="id"
                                size="small"
                                label={getSenseLabel(
                                  acceptanceTestSummary.sense
                                )}
                                backgroundColor={getBackgroundStatusColor(
                                  acceptanceTestSummary.status,
                                  acceptanceTestSummary.warning,
                                  theme
                                )}
                                textColor={getTextStatusColor(
                                  acceptanceTestSummary.status,
                                  acceptanceTestSummary.warning,
                                  theme
                                )}
                                styles={{ flexShrink: 0 }}
                              />
                            </Flex>

                            <Flex
                              minWidth={[
                                rem(400),
                                rem(400),
                                rem(400),
                                rem(400),
                                rem(560),
                              ]}
                              justifyContent="flex-end"
                            >
                              <Flex
                                minWidth={[
                                  rem(200),
                                  rem(200),
                                  rem(200),
                                  rem(200),
                                  rem(280),
                                ]}
                                justifyContent="flex-end"
                                alignItems="baseline"
                              >
                                <Text
                                  ml={2}
                                  as="h3"
                                  styleName="header-2"
                                  styles={{
                                    color: getTextStatusColor(
                                      acceptanceTestSummary.status,
                                      acceptanceTestSummary.warning,
                                      theme
                                    ),
                                  }}
                                >
                                  {acceptanceTestSummary.candidate.value}
                                </Text>
                                <Text
                                  ml={1}
                                  styleName="body-2"
                                  styles={{
                                    color: getTextStatusColor(
                                      acceptanceTestSummary.status,
                                      acceptanceTestSummary.warning,
                                      theme
                                    ),
                                    opacity: 0.7,
                                  }}
                                >{`(${acceptanceTestSummary.candidate.type})`}</Text>

                                <Text
                                  ml={2}
                                  as="h3"
                                  styleName="header-2"
                                  styles={{
                                    flexShrink: 0,
                                    width: theme.spacing.s8,
                                    textAlign: "center",
                                    color: getTextStatusColor(
                                      acceptanceTestSummary.status,
                                      acceptanceTestSummary.warning,
                                      theme
                                    ),
                                  }}
                                >
                                  {getMetricOperatorSymbol(
                                    acceptanceTestSummary.sense
                                  )}
                                </Text>
                              </Flex>

                              <Flex
                                minWidth={[
                                  rem(200),
                                  rem(200),
                                  rem(200),
                                  rem(200),
                                  rem(280),
                                ]}
                                justifyContent="flex-start"
                                alignItems="baseline"
                              >
                                <Text
                                  ml={2}
                                  as="h3"
                                  styleName="header-2"
                                  styles={{
                                    color: theme.color.gray800,
                                  }}
                                >
                                  {acceptanceTestSummary.control.value}
                                </Text>
                                <Text
                                  ml={1}
                                  styleName="body-2"
                                  styles={{ color: theme.color.gray600 }}
                                >{`(${acceptanceTestSummary.control.type})`}</Text>
                              </Flex>
                            </Flex>
                          </Flex>
                        }
                      >
                        <Flex
                          pl={7}
                          flexDirection="column"
                          minWidth={[
                            rem(680),
                            rem(680),
                            rem(680),
                            rem(680),
                            rem(860),
                          ]}
                        >
                          {acceptanceTestSummary.summaryTable.countWarning && (
                            <Notification
                              mt={4}
                              mb={4}
                              type="notice"
                              message={`Some runs had missing values for ${acceptanceTestSummary.name.toLowerCase()}. For example because a run failed, or a specific version did not include ${acceptanceTestSummary.name.toLowerCase()} in the statistics return. Be careful when interpreting the results.`}
                            />
                          )}
                          <Table2
                            isWide
                            canSort
                            headers={
                              acceptanceTestSummary.summaryTable?.headers || []
                            }
                            data={
                              acceptanceTestSummary.summaryTable?.data || []
                            }
                          />

                          <Flex
                            alignItems="flex-start"
                            mt={6}
                            width="100%"
                            pb={6}
                            flexDirection={[
                              "column",
                              "column",
                              "column",
                              "column",
                              "row",
                            ]}
                          >
                            {acceptanceTestSummary?.inputValues && (
                              <Box
                                width={["100%", "100%", "100%", "100%", "60%"]}
                                order={[1, 1, 1, 1, 0]}
                                mt={[6, 8, 8, 8, 0]}
                              >
                                <ChartAreaAcceptance
                                  yAxisLabel={
                                    acceptanceTestSummary?.candidate?.instanceId
                                  }
                                  xAxisLabel={
                                    acceptanceTestSummary?.control?.instanceId
                                  }
                                  data={acceptanceTestSummary.inputValues}
                                  indicator={acceptanceTestSummary.metricField}
                                  sense={acceptanceTestSummary.sense}
                                />
                              </Box>
                            )}

                            <Box
                              order={[0, 0, 0, 0, 1]}
                              pl={[0, 0, 0, 0, 6]}
                              width={["100%", "100%", "100%", "100%", "40%"]}
                            >
                              <Flex alignItems="center">
                                <Text
                                  as="h4"
                                  styleName="body-2-bold"
                                  styles={{
                                    color: theme.color.gray800,
                                  }}
                                >
                                  Statistical Results
                                </Text>
                                <Tooltip mt={rem(1)} ml={1}>
                                  Consider whether these results are practically
                                  significant based on p-value and percent
                                  change.
                                </Tooltip>
                              </Flex>

                              <Table2
                                isOverflowVisible
                                mt={2}
                                headers={
                                  acceptanceTestSummary.sigTestData.headers
                                }
                                data={
                                  acceptanceTestSummary.sigTestData.data || []
                                }
                              />

                              <Text styleName="body-3" mt={2}>
                                {
                                  acceptanceTestSummary.sigTestData
                                    .conclusionContent
                                }
                              </Text>
                            </Box>
                          </Flex>
                        </Flex>

                        {batchExperiment && batchExperiment.id && (
                          <Flex>
                            <Button2
                              mt={2}
                              mb={6}
                              ml={7}
                              type="outline-quiet"
                              label="More details"
                              data-testid="view-batch-experiment"
                              htmlType="button"
                              title="View more details in the corresponding batch experiment."
                              // this is actually false, but prop only used to open in new tab
                              isUrlExternal={true}
                              icon2={
                                <IconExternalLink
                                  iconColor={theme.color.gray600}
                                />
                              }
                              styles={{
                                "&:hover svg, &:active svg, &:focus svg, &:focus-visible svg":
                                  {
                                    fill: theme.color.gray700,
                                  },
                              }}
                              to={getAccUrl(
                                accountId,
                                `/app/${app.id}/experiment/batch/${params.id}?field=${acceptanceTestSummaryHtmlId}`
                              )}
                            />
                          </Flex>
                        )}
                      </Details>
                    </Box>
                  )
                );
              })}
          </StyledOverflowXBox>
        </Box>
      )}
    </>
  );
};

export default AcceptanceTest;
