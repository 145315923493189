import { css } from "@emotion/react";

type CSSProp = { [key: string]: string | number };
export type CSSStylesProps =
  | undefined
  | CSSProp
  | {
      [key: string]:
        | CSSProp
        | { [key: string]: CSSProp | string | number }
        | string
        | number;
    };

type RenderExtraStylesParams = { styles?: CSSStylesProps };
export const renderExtraStyles = ({ styles }: RenderExtraStylesParams) => {
  return css(styles).styles;
};
