import * as React from "react";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";

import { svgIconStyle } from "../lib/constants";

type StyledSvgProps = {
  activeColor?: string;
  focusColor?: string;
  hoverColor?: string;
};
const StyledSvg = styled.svg<StyledSvgProps>`
  ${(props) =>
    svgIconStyle({
      activeColor: props.activeColor,
      focusColor: props.focusColor,
      hoverColor: props.hoverColor,
    })}
`;

const IconCheck = ({
  activeColor,
  focusColor,
  hoverColor,
  iconColor,
  iconSize,
}: {
  activeColor?: string;
  focusColor?: string;
  hoverColor?: string;
  iconColor?: string;
  iconSize?: number;
}) => {
  const theme = useTheme();
  const thisIconSize = iconSize || 16;
  const thisIconColor = iconColor || theme.color.gray700;

  return (
    <StyledSvg
      {...{ activeColor, focusColor, hoverColor }}
      width={thisIconSize}
      height={thisIconSize}
      viewBox="0 0 16 16"
      fill={thisIconColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7419 4.01017C12.0308 4.19907 12.1119 4.5864 11.923 4.87531L7.67301 11.3753C7.57285 11.5285 7.41059 11.6301 7.22904 11.6532C7.04749 11.6764 6.86491 11.6189 6.72949 11.4957L3.97949 8.99574C3.72408 8.76355 3.70525 8.36827 3.93744 8.11285C4.16964 7.85744 4.56492 7.83862 4.82033 8.07081L7.02794 10.0777L10.8768 4.19124C11.0657 3.90234 11.453 3.82127 11.7419 4.01017Z"
      />
    </StyledSvg>
  );
};

export default IconCheck;
