import { Box, Flex } from "@console/dsc";
import { styleScrollbarX } from "@console/dsc/src/lib/constants";
import { rem } from "@console/dsc/src/lib/tools";
import styled from "@emotion/styled";

export const FlexInline = styled(Flex)`
  display: inline-flex;
  position: relative;
  top: ${rem(3)};
`;

export const StyledOverflowXBox = styled(Box)`
  overflow-x: scroll;

  ${({ theme }) => styleScrollbarX(theme)}
`;
