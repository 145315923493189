import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Flex, Loading, RowDetail, Text } from "@console/dsc";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../../../analytics";
import {
  deleteOrgKey,
  disableOrgKey,
  enableOrgKey,
} from "../../../../api/core/controlPlane";
import { getOrgKeys } from "../../../../api/core/dataPlane";
import { UserOrgKey } from "../../../../api/core/dataPlane.types";
import { useUser } from "../../../../AuthProvider";
import ContactLinkErrorMessage from "../../../../components/ContactLinkErrorMessage";
import Header from "../../../../components/Header";
import { useMetaTitle } from "../../../../components/Layout";
import { userHasAccessToAction } from "../../../../utils/rbac_utils";
import { ActionGroups } from "../../../../utils/rbac_utils/types";
import { isCurrentUserRoot } from "../Members/utils/isCurrentUserRoot";

import APIKey from "./components/APIKey";
import APIKeyChangeModal from "./components/APIKeyChangeModal";
import {
  APIChangeModalInfo,
  HandleOptionsMenuParams,
  KeyDisplayOptions,
  ModalType,
} from "./APIKeys.types";

const pageTitle = "API Keys";

const APIKeys = () => {
  const [{ id, organizations, roles }] = useUser();
  const theme = useTheme();
  const { pathname } = useLocation();
  const [, setMetaTitle] = useMetaTitle();
  const isUserRoot = isCurrentUserRoot(organizations, roles);
  const team = organizations?.find((team) => team.id === id);
  const [keys, setKeys] = useState<UserOrgKey[] | null>(null);
  const [displayConfirmModal, setDisplayConfirmModal] = useState<
    APIChangeModalInfo | undefined
  >();
  const [displayOptionsMenu, setDisplayOptionsMenu] =
    useState<KeyDisplayOptions>({});
  const [changeAPIKeyError, setChangeAPIKeyError] = useState("");
  const [keyLoadingError, setKeyLoadingError] = useState("");

  useEffect(() => {
    setMetaTitle(pageTitle);

    const getAndHandleKeys = async () => {
      setKeyLoadingError("");
      try {
        const orgKeys = await getOrgKeys(team?.id || "");
        setKeys(orgKeys);

        // set the state of each option menu to false
        setDisplayOptionsMenu(
          orgKeys.reduce((menuStateObj: KeyDisplayOptions, key: UserOrgKey) => {
            menuStateObj[key.api_key] = false;
            return menuStateObj;
          }, {})
        );
      } catch (e) {
        setKeyLoadingError((e as Error).message);
      }
    };

    if (!keys) {
      getAndHandleKeys();
    }
  }, [setMetaTitle, team?.id, keys]);

  const handleOptionsMenuState = ({ e, apiKey }: HandleOptionsMenuParams) => {
    e.preventDefault();
    setDisplayOptionsMenu((prevState) => {
      const newState: KeyDisplayOptions = {};
      Object.keys(prevState).forEach((key) => {
        if (key === apiKey) {
          newState[key] = !prevState[key];
        } else {
          newState[key] = false;
        }
      });

      return newState;
    });
  };

  const handleDisplayConfirmModal = (
    e: { preventDefault: () => void },
    modalInfo: APIChangeModalInfo | undefined
  ) => {
    e.preventDefault();
    setDisplayConfirmModal(modalInfo);
    setDisplayOptionsMenu(
      (keys || []).reduce(
        (menuStateObj: KeyDisplayOptions, key: UserOrgKey) => {
          menuStateObj[key.api_key] = false;
          return menuStateObj;
        },
        {}
      )
    );
  };

  const handleDelete = async (apiKey: string) => {
    try {
      await deleteOrgKey(team?.id || "", apiKey);
      setKeys((keys || []).filter((key) => key.api_key !== apiKey));
      setDisplayConfirmModal(undefined);
    } catch (e) {
      setChangeAPIKeyError((e as Error).message);
    }
  };

  const handleEnable = async (apiKey: string) => {
    try {
      await enableOrgKey(team?.id || "", apiKey);
      setKeys(
        (keys || []).map((key) => {
          if (key.api_key === apiKey) {
            key["disabled"] = false;
          }
          return key;
        })
      );
      setDisplayConfirmModal(undefined);
    } catch (e) {
      setChangeAPIKeyError((e as Error).message);
    }
  };

  const handleDisable = async (apiKey: string) => {
    try {
      await disableOrgKey(team?.id || "", apiKey);
      setKeys(
        (keys || []).map((key) => {
          if (key.api_key === apiKey) {
            key["disabled"] = true;
          }
          return key;
        })
      );
      setDisplayConfirmModal(undefined);
    } catch (e) {
      setChangeAPIKeyError((e as Error).message);
    }
  };

  const changeKey = async (
    e: { preventDefault: () => void },
    modalInfo: APIChangeModalInfo
  ) => {
    setChangeAPIKeyError("");

    const apiKey = modalInfo.key.api_key;
    switch (modalInfo.modalType) {
      case ModalType.Delete:
        await handleDelete(apiKey);
        break;
      case ModalType.Enable:
        await handleEnable(apiKey);
        break;
      case ModalType.Disable:
        await handleDisable(apiKey);
        break;
    }
  };

  const renderAPIKeys = () => {
    if (!keys) {
      return <Loading type="full-screen" dotColor={theme.color.orange500} />;
    }

    if (keys.length === 0) {
      return (
        <Text testId="instances-not-found-message" mt={5} styleName={"body-2"}>
          No API keys found.{" "}
        </Text>
      );
    }

    return (
      <Flex flexGrow={1} flexDirection="column">
        {keyLoadingError ? (
          <ContactLinkErrorMessage
            errorMsg={keyLoadingError}
            isEmailLink={true}
            isLoud
          />
        ) : (
          keys
            .sort((a, _b) => (a.disabled ? 0 : -1))
            .map((key, index) => (
              <RowDetail
                key={key.api_key}
                hasNoBorder={index === 0}
                render={
                  <APIKey
                    keyData={key}
                    handleOptionsMenuState={handleOptionsMenuState}
                    displayOptionsMenu={displayOptionsMenu[key.api_key]}
                    setDisplayConfirmModal={handleDisplayConfirmModal}
                  />
                }
              />
            ))
        )}
      </Flex>
    );
  };

  const teamName = team?.name || "";

  const tooltipAccessibleClause =
    "These API keys are unique keys accessible only by you. They grant read and write access to all services";
  const tooltipTeamClause = isUserRoot
    ? ` for your team${teamName ? ` (${teamName})` : ""}. `
    : `${teamName ? ` for ${teamName}` : ""}. `;
  const tooltipSecretClause =
    "Please keep them secret and use only from your backend.";

  const teamTooltip =
    tooltipAccessibleClause + tooltipTeamClause + tooltipSecretClause;

  const userCanAddAPIKey = userHasAccessToAction(
    roles,
    ActionGroups.SecurityOperator,
    {}
  );

  return (
    <>
      <Header
        configPageTitle={{
          label: pageTitle,
          tooltipContent: teamTooltip,
        }}
        configActionButton={{
          label: "New API key",
          url: `${pathname}/new`,
          onClick: () => {
            trackEvent("Team", {
              view: "API Keys",
              action: "Add New API Key Button Clicked",
            });
          },
          isActionAllowed: userCanAddAPIKey,
        }}
      />

      {renderAPIKeys()}

      {displayConfirmModal && (
        <APIKeyChangeModal
          {...{
            modalInfo: displayConfirmModal,
            setDisplayConfirmModal: handleDisplayConfirmModal,
            changeKey: changeKey,
            error: changeAPIKeyError,
          }}
        />
      )}
    </>
  );
};

export default APIKeys;
