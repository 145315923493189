import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { Box, Input, RowDetail } from "@console/dsc";

import { trackEvent } from "../../../../analytics";
import { CreateAppVersionPayload } from "../../../../api/core/controlPlane.types";
import Footer from "../../../../components/Footer";
import Header from "../../../../components/Header";
import { useMetaTitle } from "../../../../components/Layout";
import { INPUT_WIDTH_STANDARD } from "../../../../config/general";
import { useAppCollection } from "../../../../contexts/apps/App.context";
import useManageEntity from "../../../../hooks/useManageEntity";
import useStandardInputs from "../../../../hooks/useStandardInputs";
import { appTooltipCopy } from "../../../Apps/data/microcopy";
import { AppPageProps } from "../../App.types";
import ExecutableDetails from "../../components/ExecutableDetails";
import useReturnPaths from "../../hooks/useReturnPaths";

const pageTitle = "New version";

const NewVersion = ({ app }: AppPageProps) => {
  const [, setMetaTitle] = useMetaTitle();
  const { returnPath, returnPathList } = useReturnPaths();

  const [isProcessing, setIsProcessing] = useState(false);

  const { loadVersions } = useAppCollection();
  const {
    addEntity: addVersion,
    isEntityAdded: isVersionAdded,
    entityAddError: versionAddError,
  } = useManageEntity("versions");

  const {
    getStandardInputsProps,
    pendingStandardInputs,
    standardInputsErrors,
  } = useStandardInputs(app, "versions");

  // page display
  useEffect(() => {
    setMetaTitle(pageTitle);
  }, [setMetaTitle]);

  // disable loading state if add new version error
  useEffect(() => {
    if (versionAddError && isProcessing) {
      setIsProcessing(false);
    }
  }, [isProcessing, versionAddError]);

  const handleVersionCreate = async (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    e.preventDefault();
    e.stopPropagation();

    setIsProcessing(true);

    let payload: CreateAppVersionPayload = {
      ...pendingStandardInputs,
    };

    await addVersion(app.id, payload);
  };

  const handleCancel = () => {
    trackEvent("Versions", {
      view: "Create Version",
      action: "Create Version Canceled",
    });
    return;
  };

  if (isVersionAdded) {
    trackEvent("Versions", {
      view: "Create Version",
      action: "New Version Created",
    });

    loadVersions({ applicationId: app.id, shouldPaginate: true });

    return <Redirect to={returnPathList} />;
  }

  const isActionButtonDisabled =
    !app?.active_executable ||
    !pendingStandardInputs.name ||
    !pendingStandardInputs.id ||
    !!standardInputsErrors.name ||
    !!standardInputsErrors.id;

  return (
    <>
      <Header
        configPageTitle={{
          label: pageTitle,
          parentLabel: "Versions",
          parentUrl: returnPathList,
        }}
      />

      <form>
        <RowDetail
          hasNoBorder
          property="Name"
          secondaryLabel="For reference only"
          render={
            <Box width="100%" maxWidth={INPUT_WIDTH_STANDARD}>
              <Input
                {...getStandardInputsProps({
                  placeholder: "Version name",
                  testId: "new-version-name-input",
                  type: "name",
                  trackEventCategory: "Versions",
                  trackEventProperties: {
                    view: "Create Version",
                    action: "Field Entered",
                    meta: {
                      field: "name",
                    },
                  },
                })}
              />
            </Box>
          }
        />

        <RowDetail
          property="ID"
          tooltipCopy={appTooltipCopy.versionIdCreate}
          render={
            <Box width="100%" maxWidth={INPUT_WIDTH_STANDARD}>
              <Input
                {...getStandardInputsProps({
                  placeholder: "Version ID",
                  testId: "new-version-id-input",
                  type: "id",
                  trackEventCategory: "Versions",
                  trackEventProperties: {
                    view: "Create Version",
                    action: "Version ID Changed",
                  },
                })}
              />
            </Box>
          }
        />

        <RowDetail
          property="Description"
          secondaryLabel="(optional)"
          render={
            <Box width="100%" maxWidth={INPUT_WIDTH_STANDARD}>
              <Input
                {...getStandardInputsProps({
                  placeholder: "Version description",
                  testId: "new-version-description-input",
                  type: "description",
                  trackEventCategory: "Versions",
                  trackEventProperties: {
                    view: "Create Version",
                    action: "Field Entered",
                    meta: {
                      field: "description",
                    },
                  },
                })}
              />
            </Box>
          }
        />

        <RowDetail
          property="Current executable"
          tooltipCopy={appTooltipCopy.newVersionExecutable}
          render={<ExecutableDetails executable={app?.active_executable} />}
        />

        <Footer
          actionButtonLabel="Create version"
          app={app}
          endpoint="versions"
          error={versionAddError}
          handleCancel={handleCancel}
          handleMainAction={handleVersionCreate}
          isActionButtonLoading={isProcessing}
          isActionButtonDisabled={isActionButtonDisabled}
          returnPath={returnPath}
          returnPathList={returnPathList}
          view="create"
        />
      </form>
    </>
  );
};

export default NewVersion;
