import { useCallback, useState } from "react";

import { getMarketplacePartnerApp } from "../../../api/core/controlPlane";
import {
  EntityErrorMessage,
  MarketplacePartnerApp,
  MarketplacePartnerAppData,
} from "../../../api/core/controlPlane.types";
import { useUser } from "../../../AuthProvider";
import { marketplaceApps } from "../../../pages/Marketplace/data/marketplaceApps";
import {
  getMarketplaceAppId,
  getMarketplacePartnerId,
} from "../utils/subscriptionId";

// TEMPORARY! (see note below)
const loadMarketplaceAppData = (subscriptionId: string) => {
  const marketplaceAppData = marketplaceApps.find(
    (app) => app.id === subscriptionId
  );
  return marketplaceAppData || { id: "none" };
};

const useMarketplacePartners = () => {
  const [{ id: accountId }] = useUser();

  const [marketplacePartnerApp, setMarketplacePartnerApp] =
    useState<MarketplacePartnerApp>(null);
  const [marketplacePartnerAppError, setMarketplacePartnerAppError] =
    useState<EntityErrorMessage>(null);

  const loadMarketplacePartnerApp = useCallback(
    async (subscriptionId: string) => {
      const partnerId = getMarketplacePartnerId(subscriptionId);
      const applicationId = getMarketplaceAppId(subscriptionId);

      try {
        const marketplacePartnerAppRes = await getMarketplacePartnerApp(
          accountId || ""
        )(partnerId, applicationId);
        const marketplacePartnerApp =
          marketplacePartnerAppRes as MarketplacePartnerApp;

        // TEMPORARY!
        // The backend does not return all data needed by front-end
        // The work to add the missing data is on hold, so until that work is
        // complete, we augment the backend call with data stored in the front-end
        const frontEndMarketplaceAppData =
          loadMarketplaceAppData(subscriptionId);
        const { id, ...augmentedAppData } = frontEndMarketplaceAppData;
        const augmentedMarketplacePartnerApp = {
          ...marketplacePartnerApp,
          ...augmentedAppData,
        } as MarketplacePartnerAppData;

        setMarketplacePartnerApp(augmentedMarketplacePartnerApp);
        setMarketplacePartnerAppError(null);
        return marketplacePartnerApp;
      } catch (e: any) {
        console.error(e);
        setMarketplacePartnerApp(null);
        setMarketplacePartnerAppError(e.toString());
      }
    },
    [accountId]
  );

  return {
    loadMarketplacePartnerApp,
    marketplacePartnerApp,
    marketplacePartnerAppError,
    setMarketplacePartnerApp,
    setMarketplacePartnerAppError,
  };
};

export default useMarketplacePartners;
