import * as React from "react";

import BaseIcon, { IconProps } from "./BaseIcon";

const IconCaretY = ({
  iconSize,
  iconColor,
  iconColorActive,
  iconColorFocus,
  iconColorHover,
  iconOpacity,
  ...rest
}: IconProps) => {
  return (
    <BaseIcon
      {...{
        iconSize,
        iconColor,
        iconColorActive,
        iconColorFocus,
        iconColorHover,
      }}
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.2465 5.7798L4.89294 6.13335L5.60005 6.84046L5.9536 6.48691L8.00005 4.44046L10.0465 6.48691L10.4001 6.84046L11.1072 6.13335L10.7536 5.7798L8.3536 3.3798L8.00005 3.02625L7.6465 3.3798L5.2465 5.7798ZM10.7536 10.2202L11.1072 9.86669L10.4001 9.15958L10.0465 9.51313L8.00005 11.5596L5.95361 9.51313L5.60005 9.15958L4.89294 9.86669L5.2465 10.2202L7.6465 12.6202L8.00005 12.9738L8.35361 12.6202L10.7536 10.2202Z"
        fillOpacity={iconOpacity || undefined}
      />
    </BaseIcon>
  );
};

export default IconCaretY;
