import * as React from "react";
import { rankItem } from "@tanstack/match-sorter-utils";
import {
  ColumnFiltersState,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";

import Flex from "../Flex";

import CSVTableDownload from "./components/CSVTableDownload";
import Table2Header from "./components/Table2Header";
import { reformatHeaderAccessorKeysNoPeriods } from "./utils/reformatHeaderAccessorKeysNoPeriods";
import { reformatTableDataKeysNoPeriods } from "./utils/reformatTableDataKeysNoPeriods";
import {
  getFilteredandCleanedTableData,
  getHeadersCSV,
} from "./utils/tableCSVhelpers";
import { getTableContainerMaxWidth } from "./utils/tableStyleHelpers";
import {
  StyledDownloadLinkContainer,
  StyledTable,
  StyledTableContainer,
} from "./Table2.styled";
import { RenderEmptyRowParams, Table2Props } from "./Table2.types";

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value);
  addMeta({
    itemRank,
  });
  return itemRank.passed;
};

const Table2 = ({
  data,
  canFilter,
  canSort,
  csvLinkLocation,
  csvLinkRightValue,
  csvLinkTopValue,
  emptyMessage,
  fileNameCSV,
  hasBorder,
  hasNoBorderTop,
  headers,
  isOverflowVisible,
  isStickyColumn,
  isWide,
  layoutSchema,
  m,
  mt,
  mr,
  mb,
  ml,
  plRow,
  prRow,
  showCSVLink,
  size,
  testId,
  type,
  useMinWidth,
}: Table2Props) => {
  const thisSize = size || "default";
  const thisType = type || "default";

  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const renderEmptyRow = ({ emptyMessage, cols }: RenderEmptyRowParams) => (
    <tbody>
      <tr>
        <td colSpan={cols}>{emptyMessage || "No items"}</td>
      </tr>
    </tbody>
  );

  const reformattedHeaders = reformatHeaderAccessorKeysNoPeriods(headers);

  const table = useReactTable({
    data: reformatTableDataKeysNoPeriods(data),
    columns: reformattedHeaders,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      sorting,
    },
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <Flex
      flexDirection="column"
      relative
      width="100%"
      maxWidth={getTableContainerMaxWidth({
        isWide,
        layoutSchema,
        size: thisSize,
      })}
    >
      {showCSVLink && (
        <StyledDownloadLinkContainer
          {...{ csvLinkLocation }}
          right={csvLinkRightValue}
          top={csvLinkTopValue}
        >
          <CSVTableDownload
            headersCSV={getHeadersCSV(reformattedHeaders)}
            dataCSV={getFilteredandCleanedTableData(table)}
            fileNameCSV={fileNameCSV}
          />
        </StyledDownloadLinkContainer>
      )}
      <StyledTableContainer {...{ isOverflowVisible, isWide, layoutSchema }}>
        <StyledTable
          {...{
            hasBorder,
            hasNoBorderTop,
            isStickyColumn,
            isWide,
            layoutSchema,
            m,
            mt,
            mr,
            mb,
            ml,
            plRow,
            prRow,
            useMinWidth,
          }}
          size={thisSize}
          type={thisType}
          {...(testId && {
            "data-testid": testId,
          })}
        >
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header, index) => (
                  <Table2Header
                    key={header.id}
                    {...{ canFilter, canSort, header, table }}
                  />
                ))}
              </tr>
            ))}
          </thead>
          {data && data.length ? (
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell, index) => (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          ) : (
            renderEmptyRow({ emptyMessage, cols: headers && headers.length })
          )}
        </StyledTable>
      </StyledTableContainer>
    </Flex>
  );
};

export default React.memo(Table2);
