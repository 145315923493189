import React from "react";
import { Box, Flex, Text } from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";

import logoGrubhub from "../../../assets/logo-grubhub.svg";
import logoHopSkipDrive from "../../../assets/logo-hopskipdrive.svg";
import logoKaleAi from "../../../assets/logo-kaleai.svg";
import logoTaskRabbit from "../../../assets/logo-taskrabbit.svg";
import logoVeho from "../../../assets/logo-veho.svg";

const CustomerLogos = () => {
  const theme = useTheme();

  return (
    <>
      <Text
        styleName="label"
        styles={{
          color: theme.color.white,
          opacity: 0.68,
        }}
      >
        Trusted by teams the world over
      </Text>

      <Flex
        mt={4}
        alignItems="center"
        style={{ opacity: 0.7 }}
        flexWrap={["nowrap", "nowrap", "wrap", "nowrap"]}
      >
        <Box width={rem(104)} mb={4} mr={5} mt={rem(2)}>
          <img src={logoTaskRabbit} alt="Task Rabbit" />
        </Box>
        <Box width={rem(96)} mb={4} mr={5} mt={rem(-3)}>
          <img src={logoGrubhub} alt="Grubhub" />
        </Box>
        <Box width={rem(56)} mb={4} mr={5} mt={rem(-2)}>
          <img src={logoVeho} alt="Veho" />
        </Box>
        <Box width={rem(88)} mb={4} mr={5} mt={rem(3)}>
          <img src={logoKaleAi} alt="Kale AI" />
        </Box>
        <Box width={rem(42)} mb={4}>
          <img src={logoHopSkipDrive} alt="Hop Skip Drive" />
        </Box>
      </Flex>
    </>
  );
};

export default CustomerLogos;
