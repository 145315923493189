import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Box, Flex, Loading, RowDetail, Tag, Text } from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../../../analytics";
import { useUser } from "../../../../AuthProvider";
import Header from "../../../../components/Header";
import { useMetaTitle } from "../../../../components/Layout";
import StandardError from "../../../../components/StandardError";
import { MEASURE_ENTITY_ROW } from "../../../../config/general";
import useManageEntity from "../../../../hooks/useManageEntity";
import { getAccUrl } from "../../../../utils/navigation";
import { userHasAccessToAction } from "../../../../utils/rbac_utils";
import { ActionGroups } from "../../../../utils/rbac_utils/types";
import { appTooltipCopy } from "../../../Apps/data/microcopy";
import { AppPageProps } from "../../App.types";
import ExecutionClass from "../../components/ExecutionClass";
import InstanceConfig from "../../components/InstanceConfig";
import useReturnPaths from "../../hooks/useReturnPaths";

const InstanceDetails = ({ app }: AppPageProps) => {
  const [{ id: accountId, roles }] = useUser();
  const [, setMetaTitle] = useMetaTitle();
  const params = useParams() as { id: string };
  const { pathname } = useLocation();
  const { returnPathList } = useReturnPaths();
  const theme = useTheme();

  const {
    entity: instance,
    entityLoadError: instanceError,
    loadEntity: loadInstance,
  } = useManageEntity("instances");

  // page display
  useEffect(() => {
    if (instance) {
      setMetaTitle(instance.name);
    }
  }, [setMetaTitle, instance]);

  // load instance data
  useEffect(() => {
    if (!instance && !instanceError) {
      loadInstance(app.id, params.id);
    }
  }, [app.id, instance, instanceError, loadInstance, params.id]);

  if (instanceError) {
    return <StandardError errorMessage={instanceError} />;
  }
  if (!instance) {
    return <Loading type="full-screen" dotColor={theme.color.orange500} />;
  }

  const canUserEditInstance = userHasAccessToAction(
    roles,
    ActionGroups.AppInstanceOperator,
    instance
  );

  const canUserCreateInstances = userHasAccessToAction(
    roles,
    ActionGroups.AppInstanceOperator,
    { locked: false }
  );

  return (
    <>
      <Header
        configPageTitle={{
          label: instance.name,
          parentLabel: "Instances",
          parentUrl: returnPathList,
        }}
        configActionButton={{
          label: "Edit",
          url: `${pathname}/edit`,
          onClick: () =>
            trackEvent("Instances", {
              view: "Instance Detail",
              action: "Edit Button Clicked",
            }),
          isActionAllowed: canUserEditInstance,
        }}
        secondaryButton={[
          {
            label: "New instance",
            url: `${returnPathList}/new`,
            onClick: () =>
              trackEvent("Instances", {
                view: "Instance Detail",
                action: "New Instance Button Clicked",
              }),
            isActionAllowed: canUserCreateInstances,
          },
          {
            label: "Clone instance",
            url: `${returnPathList}/new?cloneId=${instance.id}`,
            testId: "clone-new-instance-button",
            isActionAllowed: canUserCreateInstances,
            onClick: () =>
              trackEvent("Instances", {
                view: "Instance Detail",
                action: "Clone Instance Button Clicked",
              }),
          },
        ]}
      />

      <RowDetail
        hasNoBorder
        property="Name"
        render={
          <Box maxWidth={MEASURE_ENTITY_ROW}>
            <Text styleName="body-2">{instance.name}</Text>
          </Box>
        }
      />

      <RowDetail
        property="ID"
        tooltipCopy={appTooltipCopy.instanceId}
        render={
          <Flex>
            <Tag
              ml={rem(-6)}
              type="id"
              label={instance.id}
              contentToCopy={instance.id}
            />
          </Flex>
        }
      />

      {instance.description && (
        <RowDetail
          property="Description"
          render={
            <Box maxWidth={MEASURE_ENTITY_ROW}>
              <Text styleName="body-2">{instance.description}</Text>
            </Box>
          }
        />
      )}

      {instance.version_id && (
        <RowDetail
          property="Version ID"
          secondaryLabel="Version assigned to the instance"
          render={
            <Flex>
              <Tag
                ml={rem(-6)}
                type="id"
                label={instance.version_id}
                contentToCopy={instance.version_id}
                {...(app.type &&
                  app.type !== "subscription" && {
                    url: getAccUrl(
                      accountId,
                      `/app/${app.id}/version/${instance.version_id}`
                    ),
                  })}
              />
            </Flex>
          }
        />
      )}

      {instance?.configuration?.execution_class && (
        <RowDetail
          property="Execution class"
          secondaryLabel="Type of machine used for remote runs"
          tooltipCopy={appTooltipCopy.instanceExecutionClass.content}
          tooltipExtraLinkLabel={
            appTooltipCopy.instanceExecutionClass.docsLinkLabel
          }
          tooltipExtraLinkUrl={appTooltipCopy.instanceExecutionClass.docsLink}
          render={
            <ExecutionClass
              executionClass={instance.configuration.execution_class}
            />
          }
        />
      )}

      {instance?.configuration?.options && (
        <RowDetail
          property="Configuration Options"
          tooltipCopy={appTooltipCopy.instanceConfig.content}
          tooltipExtraLinkLabel={appTooltipCopy.instanceConfig.docsLinkLabel}
          tooltipExtraLinkUrl={appTooltipCopy.instanceConfig.docsLink}
          render={<InstanceConfig options={instance.configuration.options} />}
        />
      )}
    </>
  );
};

export default InstanceDetails;
