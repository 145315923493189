import { TableData } from "../Table2.types";

export const reformatTableDataKeysNoPeriods = (tableData: TableData[]) => {
  // table library does not view "." as regular keys
  const changedData = tableData.map((tableObject) => {
    const emptyTableData: TableData = {};
    for (const key in tableObject) {
      emptyTableData[key.split(".").join("-")] = tableObject[key];
    }
    return emptyTableData;
  });
  return changedData;
};
