import { ExperimentStatus } from "../../../../../api/core/controlPlane.types";
import {
  StartStopModalContent,
  StartStopModalType,
} from "../../../types/Experiment.types";

export const getModalContent = (status: ExperimentStatus) => {};

export const modalContent: {
  [K in StartStopModalType]: StartStopModalContent;
} = {
  start: ({ baselineInstances, candidateInstances, testName }) => ({
    title: `Start ${testName}`,
    summary: `While the shadow test is in progress, any run made on the baseline instance ${baselineInstances} will trigger a run on the candidate instance ${candidateInstances} using the same input. Do you want to start this shadow test?`,
    extra:
      "Note: Running a shadow test does not affect the performance of instances used in the test.",
    primaryActionLabel: "Start shadow test",
  }),
  complete: ({ testName }) => ({
    title: `Complete ${testName}`,
    summary: `Marking the shadow test as complete will set the status to complete and end the test immediately, even if the end criteria has not been met yet. Do you want to mark this test complete?`,
    primaryActionLabel: "Mark as complete",
  }),
  cancel: ({ testName }) => ({
    title: `Cancel ${testName}`,
    summary: `Canceling the shadow test will mark the test status as cancelled and end the test immediately. Do you want to mark this test cancelled?`,
    primaryActionLabel: "Cancel shadow test",
    secondaryActionLabel: "Never mind",
  }),
};
