import React, { useEffect, useState } from "react";
import { Box, Flex, Loading, Notification, Tabs, Tooltip } from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";

import StandardError from "../../../../../components/StandardError";
import { LOGS_LIVE_TAB_ID, LOGS_TAB_ID } from "../../../../../config/apps";
import useLogsPolling from "../../../../../hooks/useLogsPolling";
import {
  StyledRunLogEntry,
  StyledRunViewContainer,
} from "../RunDetails.styled";
import { RunLogsProps, RunLogTabType } from "../RunDetails.types";

const runLogTabs: { id: RunLogTabType; label: string }[] = [
  { id: LOGS_TAB_ID, label: "Log output" },
  { id: LOGS_LIVE_TAB_ID, label: "Live" },
];

const RunLogs = ({ appId, isExpanded, isRunResolved, runId }: RunLogsProps) => {
  const theme = useTheme();

  const [activeView, setActiveView] = useState<RunLogTabType>(
    isRunResolved ? LOGS_TAB_ID : LOGS_LIVE_TAB_ID
  );

  const {
    loadRunLogs,
    loadRunLogsLive,
    runLogs,
    runLogsError,
    runLogsLive,
    runLogsLiveError,
  } = useLogsPolling(appId, runId);

  // initial load
  useEffect(() => {
    if (!runLogsLive && !runLogsLiveError) {
      loadRunLogsLive(appId, runId);
    }
  }, [appId, loadRunLogsLive, runId, runLogsLive, runLogsLiveError]);

  // load final logs if run resolved
  useEffect(() => {
    if (isRunResolved && !runLogs) {
      loadRunLogs(appId, runId);
    }
  }, [appId, isRunResolved, loadRunLogs, runId, runLogs]);

  const handleLogTabOnClick = (
    e: { preventDefault: () => void; stopPropagation: () => void },
    id: RunLogTabType
  ) => {
    e.preventDefault();
    e.stopPropagation();
    setActiveView(id);
    return;
  };

  const isWaitingForLogs =
    !runLogs && !runLogsLive && !runLogsError && !runLogsLiveError;

  if (isWaitingForLogs) {
    return <Loading type="full-screen" dotColor={theme.color.orange500} />;
  }

  const renderLogs = () => {
    if (!isRunResolved) {
      return (
        <Notification
          mt={1}
          type="tip"
          testId="run-logs-not-yet-available-notice"
          message="Run logs are available after the run has resolved."
        />
      );
    }

    if (!runLogs) {
      return (
        <Notification
          mt={1}
          testId="run-logs-none-notice"
          message="No logs exist for this run."
        />
      );
    }

    return (
      <Box data-testid="run-logs-data">
        <ul>
          <li>
            <StyledRunLogEntry>{runLogs.log}</StyledRunLogEntry>
          </li>
        </ul>
      </Box>
    );
  };

  const renderLogsLive = () => {
    const runLogsLiveItems = runLogsLive && runLogsLive.items;

    if (!runLogsLiveItems) {
      return (
        <Notification
          mt={1}
          testId="live-run-logs-24hr-notice"
          message="Live run logs are only available for 24 hours after a run."
        />
      );
    }

    return (
      <Box data-testid="run-logs-data">
        <ul>
          {runLogsLiveItems.map((runLog) => {
            return (
              <li key={runLog.timestamp}>
                <StyledRunLogEntry>{runLog.log}</StyledRunLogEntry>
              </li>
            );
          })}
        </ul>

        {runLogsLive.status_v2 === "running" && (
          <Box mt={3}>
            <Loading />
          </Box>
        )}
      </Box>
    );
  };

  const renderContent = () => {
    if (runLogsLiveError && !runLogs && !isRunResolved) {
      return <StandardError errorMessage={runLogsLiveError} />;
    }

    if (runLogsError && !runLogsLive) {
      return <StandardError errorMessage={runLogsError} />;
    }

    return (
      <>
        <Flex pt={4} ml={2}>
          <Tabs
            type="panel"
            tabs={runLogTabs.map((runLogTab) => {
              const runLogTabId = runLogTab.id;
              return {
                id: runLogTabId,
                isActive: activeView === runLogTabId,
                label: runLogTab.label,
                onClick: (e: {
                  preventDefault: () => void;
                  stopPropagation: () => void;
                }) => handleLogTabOnClick(e, runLogTabId),
              };
            })}
          />
          <Tooltip mt={rem(2)} ml={3}>
            Live logs record any output your application prints to stderr and
            are made available every 30 seconds. Live logs are stored on the
            server for 24 hours after a run. At the end of a run, the live logs
            and log output should be the same.
          </Tooltip>
        </Flex>

        <Box pt={4} pb={8}>
          {activeView === LOGS_TAB_ID && renderLogs()}
          {activeView === LOGS_LIVE_TAB_ID && renderLogsLive()}
        </Box>
      </>
    );
  };

  return (
    <StyledRunViewContainer {...{ isExpanded }}>
      {renderContent()}
    </StyledRunViewContainer>
  );
};

export default RunLogs;
