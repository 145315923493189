import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  IconCheck,
  IconCheckEncircledGraphic,
  Modal2,
  Notification,
  Tag,
  Text,
} from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../../../analytics";
import { getSubscription } from "../../../../api/payment/controlPlane";
import { Subscription } from "../../../../api/payment/controlPlane.types";
import { useFetchPayments } from "../../../../api/payment/paymentApi.hooks";
import { useUser } from "../../../../AuthProvider";
import Header from "../../../../components/Header";
import { useMetaTitle } from "../../../../components/Layout";
import SelfCheckout from "../../../../components/SelfCheckout";
import { PLAN_TYPE_PREMIUM } from "../../../../config/plans";
import { getIsUserPaid } from "../../../../utils/authProviderHelpers";
import {
  ModalClickEventProps,
  PlanDetails,
  TeamPageProps,
} from "../../Team.types";
import { getCurrentPlanType, getPlanCta } from "../../utils/planManagement";
import { isCurrentUserRoot } from "../Members/utils/isCurrentUserRoot";

import { getPlans } from "./data/getPlans";
import {
  StyledPlanFootnote,
  StyledPlanFootnotes,
  StyledPlanOption,
} from "./Plan.styled";

const Plan = ({ team }: TeamPageProps) => {
  const [user] = useUser();
  const { organizations, roles } = user;
  const theme = useTheme();
  const [, setMetaTitle] = useMetaTitle();

  const pageTitle = team ? `${team.name} Plan` : "Plan";
  const fetchPayments = useFetchPayments();
  const isUserRoot = isCurrentUserRoot(organizations, roles);

  const [isCheckoutModalActive, setIsCheckoutModalActive] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<PlanDetails | null>(null);
  const [userSubscription, setUserSubscription] = useState<Subscription | null>(
    null
  );
  const [isUserSubscriptionActive, setIsUserSubscriptionActive] = useState<
    boolean | null
  >(null);

  useEffect(() => {
    setMetaTitle(pageTitle);
  }, [pageTitle, setMetaTitle]);

  // check for existing subscription (only for root user)
  useEffect(() => {
    const getUserSubscription = async () => {
      return await getSubscription();
    };
    if (isUserRoot && isUserSubscriptionActive === null) {
      getUserSubscription()
        .then((subscription) => {
          // if a subscription exists, only planStatus canceled or
          // incomplete_expired is allowed to create a new subscription
          if (
            subscription &&
            !(
              subscription.planStatus === "canceled" ||
              subscription.planStatus === "incomplete_expired"
            )
          ) {
            setUserSubscription(subscription);
            setIsUserSubscriptionActive(true);
            return;
          }
          setIsUserSubscriptionActive(false);
          return;
        })
        .catch((error) => {
          console.error(error);
          setIsUserSubscriptionActive(false);
        });
    }
  }, [isUserRoot, isUserSubscriptionActive]);

  const handleSelectPlanOnClick = (
    e?: ModalClickEventProps,
    plan?: PlanDetails
  ) => {
    e && e.preventDefault();
    e && e.stopPropagation();
    if (!plan) return;

    trackEvent("Team", {
      view: "Plan",
      action: "Plan Selected",
      meta: {
        type: plan.planType,
      },
    });

    setSelectedPlan(plan);
    return setIsCheckoutModalActive(!isCheckoutModalActive);
  };

  const handleModalCloseOnClick = (e: ModalClickEventProps) => {
    e && e.preventDefault();
    e && e.stopPropagation();

    setSelectedPlan(null);
    return setIsCheckoutModalActive(false);
  };

  const handleContactNextmvOnClick = () => {
    return trackEvent("Team", {
      view: "Plan",
      action: "Contact Nextmv (Production) Button Clicked",
    });
  };

  const handleCustomerPortalLaunch = () => {
    fetchPayments("/billing-session")
      .then((res) => res?.json())
      .then((data) => {
        if (data?.url) {
          window.open(data.url, "_blank", "noopener,noreferrer");
        } else {
          throw new Error("Portal Session Launch Error");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const currentPlanType = getCurrentPlanType(user);
  const isUserPaid = getIsUserPaid(user);
  const isUserSubscriptionInError = isUserSubscriptionActive && !isUserPaid;

  const plans = getPlans({
    handleSelectPlanOnClick,
    handleContactNextmvOnClick,
    handleCustomerPortalLaunch,
    isUserSubscriptionInError,
    isUserRoot,
    subscriptionPlanType: userSubscription?.planType,
    userPlanType: currentPlanType,
  });

  const renderPlanInfoTag = (plan: PlanDetails, isCurrentPlan: boolean) => {
    if (isCurrentPlan) {
      return (
        <Tag
          mt={5}
          ml={-1}
          label={
            <Text
              as="span"
              styleName="meta-2-bold"
              styles={{
                letterSpacing: "0.05em",
                textTransform: "uppercase",
                color: theme.color.green700,
              }}
            >
              Current Plan
            </Text>
          }
          backgroundColor={theme.color.green100}
        />
      );
    }

    if (plan.planType === PLAN_TYPE_PREMIUM) {
      return (
        <Text
          mt={rem(25)}
          styleName="meta-2-bold"
          styles={{
            letterSpacing: "0.05em",
            textTransform: "uppercase",
            color: theme.color.gray600,
          }}
        >
          Starts at
        </Text>
      );
    }

    // empty space placeholder
    if (isUserPaid) {
      return <Box mt={rem(45)}></Box>;
    }

    return (
      <Tag
        mt={5}
        ml={-1}
        label={
          <Text
            as="span"
            styleName="meta-1-bold"
            styles={{ color: theme.color.sky700 }}
          >
            Includes 14-day free trial!
          </Text>
        }
        backgroundColor={theme.color.sky100}
      />
    );
  };

  return (
    <>
      <Header
        configPageTitle={{
          label: pageTitle,
        }}
      />

      {isUserRoot && isUserSubscriptionInError && (
        <Notification
          mt={4}
          testId="notification-subscription-error"
          type="error"
          message={`An error occurred while setting up your ${userSubscription?.planName} plan. Please click on the Manage button below to correct the error.`}
        />
      )}

      <Flex
        width="100%"
        justifyContent="space-between"
        alignItems="stretch"
        flexDirection={["column", "column", "row"]}
        flexWrap="wrap"
      >
        {plans.map((plan) => {
          const isCurrentPlan = plan.id === currentPlanType;
          const planCta = getPlanCta(plan);

          return (
            <StyledPlanOption
              {...{ isCurrentPlan }}
              key={plan.id}
              data-testid={isCurrentPlan ? `current-${plan.id}` : plan.id}
              width={[
                1 / 1,
                1 / 1,
                `calc(50% - ${theme.spacing.s6} / 2)`,
                `calc(33% - ${theme.spacing.s7} / 3)`,
              ]}
              mt={6}
              mb={8}
              pt={5}
              pr={4}
              pb={7}
              pl={4}
            >
              <Box mt={2} ml={5}>
                <Flex width="100%" minHeight={theme.spacing.s6}>
                  <Text
                    as="h3"
                    styleName="marketing-header-4"
                    styles={{
                      fontSize: rem(21),
                      lineHeight: rem(25),
                      color: theme.color.gray800,
                    }}
                  >
                    {plan.name}
                  </Text>
                  {isCurrentPlan && (
                    <Flex mt={rem(-2)} mr={3} ml="auto">
                      <IconCheckEncircledGraphic
                        iconSize={24}
                        iconColor={theme.color.green500}
                      />
                    </Flex>
                  )}
                </Flex>

                {renderPlanInfoTag(plan, isCurrentPlan)}

                <Text
                  mt={1}
                  as="h4"
                  styleName="header-1"
                  styles={{
                    fontSize: rem(21),
                    lineHeight: rem(25),
                    color: theme.color.gray800,
                  }}
                >
                  {plan.price}
                  <Text
                    as="span"
                    styleName="body-1"
                    styles={{ color: theme.color.gray600 }}
                  >
                    {plan.priceCadence}
                  </Text>
                </Text>
              </Box>

              {planCta && (
                <Flex
                  mt={3}
                  ml={5}
                  alignItems="center"
                  {...(!["plan-enterprise", "plan-premium"].includes(
                    currentPlanType
                  ) && {
                    height: theme.spacing.s9,
                  })}
                >
                  {planCta}
                </Flex>
              )}

              {plan?.features && (
                <Box mt={6}>
                  {plan.features.map((feature, index) => {
                    const isSpecialFormat =
                      ["plan-scale-up", "plan-premium"].includes(plan.id) &&
                      index === 0;
                    return (
                      <Flex key={`${plan.id}-feature-${index}`} mt={1}>
                        {!isSpecialFormat && (
                          <Box mt={1}>
                            <IconCheck iconColor={theme.color.green500} />
                          </Box>
                        )}
                        <Text
                          ml={isSpecialFormat ? 5 : 1}
                          styleName="body-1"
                          hasLinkBlack
                          dangerouslySetInnerHTML={{ __html: feature }}
                          styles={{
                            strong: {
                              fontWeight:
                                theme.ui2Typography.fontWeightBody1Bold,
                              color: theme.color.gray800,
                            },
                          }}
                        />
                      </Flex>
                    );
                  })}
                </Box>
              )}

              {plan?.extraText && (
                <Text
                  mt={3}
                  ml={5}
                  styleName="body-2"
                  styles={{ color: theme.color.gray600 }}
                >
                  {plan.extraText}
                </Text>
              )}

              {plan?.footNotes && (
                <StyledPlanFootnotes>
                  {plan.footNotes.map((footnote, index) => (
                    <StyledPlanFootnote key={`${plan.id}-footnote-${index}`}>
                      <Text
                        ml={2}
                        styleName="meta-1"
                        styles={{ color: theme.color.gray600 }}
                        hasLinkGray
                        dangerouslySetInnerHTML={{ __html: footnote }}
                      />
                    </StyledPlanFootnote>
                  ))}
                </StyledPlanFootnotes>
              )}
            </StyledPlanOption>
          );
        })}
      </Flex>

      {isCheckoutModalActive && selectedPlan && (
        <Modal2
          isActive={true}
          maxHeight={rem(680)}
          title={
            <Text
              styleName="meta-2-bold"
              styles={{
                letterSpacing: "0.06em",
                textTransform: "uppercase",
                color: theme.color.gray600,
              }}
            >
              Checkout
            </Text>
          }
          closeActionOnClick={(e: any) => handleModalCloseOnClick(e)}
        >
          <SelfCheckout
            selectedPlan={selectedPlan}
            setIsCheckoutModalActive={setIsCheckoutModalActive}
          />
        </Modal2>
      )}
    </>
  );
};

export default Plan;
