import * as React from "react";
import {
  TooltipContainer,
  TooltipItem,
  TooltipLabel,
  TooltipList,
} from "@console/dsc/src/components/Chart/custom/Tooltip";
import Text from "@console/dsc/src/components/Text";
import { rem } from "@console/dsc/src/lib/tools";

import { formatIndicatorValue } from "../utils/groupedSummaryTable";

import { getAcceptanceInstanceDisplayName } from "./utils";

type CustomTooltipAcceptanceChartProps = {
  data: any;
  active?: boolean;
  payload?: any[];
};

// NOTE: active and payload props are passed from parent Chart
// component, see for example:
// https://recharts.org/en-US/examples/CustomContentOfTooltip
const CustomTooltipAcceptanceChart = ({
  active,
  data,
  payload,
}: CustomTooltipAcceptanceChartProps) => {
  const tooltipTitle =
    payload && !!payload.length && payload[0].payload.tooltipTitle;
  const dataKey = payload && !!payload.length && payload[0].dataKey;
  if (dataKey === "areaLine") {
    return null;
  }
  return !!active ? (
    <TooltipContainer>
      {tooltipTitle && <TooltipLabel>{tooltipTitle}</TooltipLabel>}

      <TooltipList>
        {!!payload?.length &&
          payload.reverse().map((datum, index) => (
            <React.Fragment key={`${datum.dataKey}-${index}`}>
              <TooltipItem>
                <Text
                  styleName="meta-1"
                  styles={{
                    maxWidth: rem(156),
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {getAcceptanceInstanceDisplayName("control")} (
                  {datum?.payload["controlName"]}):
                </Text>{" "}
                <Text
                  as="strong"
                  ml={1}
                  styleName="meta-1-bold"
                  styles={{ fontFeatureSettings: "tnum" }}
                >
                  {formatIndicatorValue({
                    indicator: "acceptance-test-metric",
                    type: "chart-value",
                    value: datum?.payload["control"],
                  })}
                </Text>
              </TooltipItem>

              <TooltipItem>
                <Text
                  styleName="meta-1"
                  styles={{
                    maxWidth: rem(156),
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {getAcceptanceInstanceDisplayName("candidate")} (
                  {datum?.payload["candidateName"]}):
                </Text>{" "}
                <Text
                  as="strong"
                  ml={1}
                  styleName="meta-1-bold"
                  styles={{ fontFeatureSettings: "tnum" }}
                >
                  {formatIndicatorValue({
                    indicator: "acceptance-test-metric",
                    type: "chart-value",
                    value: datum?.payload["candidate"],
                  })}
                </Text>
              </TooltipItem>
            </React.Fragment>
          ))}
      </TooltipList>
    </TooltipContainer>
  ) : (
    <></>
  );
};

export default CustomTooltipAcceptanceChart;
