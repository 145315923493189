import React from "react";
import { Flex, Text } from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";

import { MarketplacePartnerApp } from "../../../api/core/controlPlane.types";

type MarketplaceAppPreviewProps = {
  marketplaceApp: MarketplacePartnerApp;
};

const MarketplaceAppSubscriptionPreview = ({
  marketplaceApp,
}: MarketplaceAppPreviewProps) => {
  const theme = useTheme();

  if (!marketplaceApp) return <></>;

  return (
    <Flex width="100%" maxWidth={rem(408)} alignItems="center">
      {marketplaceApp?.icon && (
        <img
          src={marketplaceApp.icon.url}
          alt={marketplaceApp.icon.alt}
          width="32"
        />
      )}

      <Flex ml={marketplaceApp?.icon ? 2 : 0} flexDirection="column">
        {marketplaceApp?.name && (
          <Text
            as="strong"
            styleName="body-1-bold"
            styles={{
              display: "block",
              color: theme.color.gray800,
            }}
          >
            {marketplaceApp.name}
          </Text>
        )}
        {marketplaceApp?.id && marketplaceApp?.partner_id && (
          <Text
            as="span"
            styleName="body-3"
            styles={{
              display: "block",
              color: theme.color.gray600,
            }}
          >
            {marketplaceApp.partner_id}-{marketplaceApp.id}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

export default MarketplaceAppSubscriptionPreview;
