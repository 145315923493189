import * as React from "react";
import { useTheme } from "@emotion/react";

import BaseIllustration, { IllustrationProps } from "./BaseIllustration";

const IllustrationBunnyCharged = ({
  illustrationWidth,
  illustrationHeight,
  illustrationColor,
}: IllustrationProps) => {
  const theme = useTheme();
  const bunnyColor = illustrationColor || theme.color.gray700;

  return (
    <BaseIllustration
      {...{ illustrationWidth, illustrationHeight }}
      viewBox={[0, 0, 210, 272]}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.7941 23.0774L40.3167 22.8277L37.458 24.5939L37.458 25.1334L37.458 25.1356C37.4795 27.5611 37.852 34.149 40.8715 43.0833C44.2793 53.1643 49.8785 62.9138 57.5059 72.066C70.5025 87.6443 84.0653 93.6444 84.3509 93.7658L84.354 93.7671L85.2317 94.1461L85.9567 92.3805L85.087 92.0051L85.0837 92.0036C84.9624 91.9503 81.6568 90.4961 76.8646 87.2256C70.1816 82.6107 64.1582 77.0958 58.9635 70.8359C57.3539 68.9046 52.5443 62.8711 48.0916 54.667C43.6572 46.4964 39.5881 36.1937 39.3687 25.657L40.3859 25.0283C56.8591 33.7535 69.2781 50.8419 76.8245 63.7226C81.7914 72.17 86.0327 81.0285 89.5016 90.2016L89.5026 90.204L89.8202 91.1011L91.6181 90.4584L91.2991 89.5578L91.2973 89.5529C87.7912 80.2779 83.5045 71.3209 78.4842 62.7791C70.7519 49.5765 57.927 31.9629 40.7941 23.0774Z"
        fill={bunnyColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M133.356 12.3805L130.067 11.7274L129.703 12.1252L129.702 12.1262C116.747 26.459 110.844 47.0984 108.17 61.8614C106.427 71.394 105.513 81.0613 105.438 90.7537V91.7175L107.346 91.6887V90.7352C107.422 81.1551 108.328 71.5991 110.052 62.1772C112.663 47.7695 118.378 27.7697 130.772 13.8166L131.945 14.048C135.401 23.9973 135.168 35.0807 133.851 44.2951C132.529 53.5475 130.117 60.8918 129.279 63.2674C122.676 81.991 112.146 93.232 111.916 93.4779L111.914 93.4792L111.912 93.4813L111.256 94.1687L112.631 95.4982L113.267 94.8234L113.271 94.8193C113.54 94.5407 124.314 83.0393 131.057 63.9257C135.026 52.6552 136.883 41.5634 136.576 30.9071C136.304 21.4756 134.365 15.1707 133.541 12.8879L133.356 12.3805Z"
        fill={bunnyColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M114.481 127.098L113 125.882L112.842 126.077C112.336 126.7 111.563 127.502 110.766 128.133C110.368 128.448 109.97 128.717 109.601 128.9C109.227 129.085 108.909 129.17 108.661 129.152C108.335 129.127 107.952 128.997 107.539 128.792C107.13 128.588 106.706 128.316 106.301 128.021C105.492 127.431 104.778 126.761 104.436 126.397L104.263 126.212L102.883 127.543L103.056 127.723C103.211 127.884 103.973 128.668 104.99 129.434C105.765 130.018 106.715 130.613 107.671 130.897L107.95 137.047C106.22 136.867 104.56 136.247 103.129 135.239L102.331 134.703L101.274 136.297L102.048 136.825C104.069 138.227 106.463 138.983 108.918 138.994L108.919 138.994C111.153 138.994 113.346 138.398 115.274 137.263L116.076 136.729L115.028 135.133L114.235 135.657C112.897 136.456 111.401 136.936 109.86 137.066L109.583 130.937C109.939 130.839 110.291 130.689 110.631 130.508C111.245 130.181 111.833 129.743 112.351 129.3C113.351 128.442 114.108 127.541 114.3 127.313L114.3 127.313L114.318 127.292L114.481 127.098Z"
        fill={bunnyColor}
      />
      <path
        d="M76.2105 166.259L75.5593 165.991C70.3026 163.661 65.923 159.704 63.06 154.695C58.741 147.214 58.1109 138.192 61.1838 127.881C64.746 115.939 69.9519 106.082 76.2316 99.3755C83.0991 92.0462 90.9943 88.5339 99.692 88.941C107.407 89.2952 115.595 95.2682 122.747 105.752C132.417 119.915 137.003 137.562 133.66 147.685C131.157 155.244 124.332 161.873 115.406 165.419L114.75 165.678L114.234 164.361L114.887 164.102C123.43 160.707 129.949 154.402 132.322 147.238C135.49 137.657 130.883 120.17 121.597 106.556C114.8 96.5961 106.813 90.6937 99.6392 90.3609C91.3674 89.9749 83.8417 93.3386 77.2664 100.353C71.1348 106.899 66.0449 116.559 62.5425 128.292C59.5822 138.206 60.1701 146.856 64.3025 153.987C67.5479 159.609 72.5357 163.193 76.1189 164.684L76.7702 164.953L76.2105 166.259Z"
        fill={bunnyColor}
        stroke={bunnyColor}
        strokeWidth="0.5"
      />
      <path
        d="M38.5812 259.128C36.7851 259.121 35.0133 258.711 33.3963 257.924C28.4261 255.538 25.3426 250.297 25.3496 244.243C25.3496 239.02 28.0002 237.024 30.2143 236.265C34.1848 234.909 39.785 236.701 41.1964 238.545L41.6259 239.105L40.5101 239.969L40.0806 239.409C39.1725 238.223 34.3467 236.35 30.6648 237.607C28.0741 238.493 26.7577 240.727 26.7541 244.246C26.7541 249.748 29.5244 254.515 33.9982 256.639C35.8106 257.523 37.8395 257.861 39.8387 257.61C41.8379 257.359 43.7219 256.529 45.2619 255.223L45.8076 254.776L46.6946 255.877L46.1491 256.324C44.025 258.111 41.3504 259.102 38.5812 259.128Z"
        fill={bunnyColor}
        stroke={bunnyColor}
        strokeWidth="0.5"
      />
      <path
        d="M127.645 262.597C118.613 262.597 99.8908 262.122 99.6726 262.119L98.9688 262.101L99.0038 260.684L99.7079 260.702C99.9613 260.702 125.09 261.347 131.082 261.141C132.018 261.113 132.931 260.841 133.733 260.354C134.533 259.866 135.197 259.179 135.657 258.359C136.1 257.612 136.341 256.762 136.359 255.893C136.377 255.024 136.169 254.166 135.756 253.402C134.234 250.568 130.409 249.422 124.379 249.967L123.858 250.012L123.155 248.243L123.563 247.913C126.731 245.354 133.837 238.269 131.513 227.845C130.687 224.138 128.978 220.689 126.534 217.793L126.072 217.254L127.128 216.33L127.589 216.868C130.181 219.941 131.993 223.601 132.869 227.533C135.22 238.074 128.646 245.385 125.065 248.483C131.239 248.069 135.245 249.492 136.98 252.732C137.504 253.711 137.768 254.809 137.748 255.92C137.727 257.03 137.423 258.118 136.865 259.078C136.286 260.106 135.456 260.967 134.45 261.58C133.445 262.192 132.302 262.534 131.127 262.572C130.292 262.582 129.095 262.597 127.645 262.597Z"
        fill={bunnyColor}
        stroke={bunnyColor}
        strokeWidth="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M122.208 161.573L121.843 160.684L120.079 161.408L120.441 162.295C120.533 162.521 130.207 186.813 126.014 214.6C124.84 222.385 121.805 231.508 117.877 238.966C112.569 249.085 105.597 256.557 97.7243 260.6L96.8763 261.035L97.7415 262.738L98.5895 262.321L98.5933 262.32C106.845 258.086 114.091 250.318 119.566 239.871C123.581 232.215 126.699 222.879 127.903 214.891C132.166 186.634 122.355 161.943 122.209 161.575L122.208 161.573Z"
        fill={bunnyColor}
      />
      <path
        d="M90.2987 271.384H72.3646C66.0945 271.392 59.968 269.497 54.786 265.946C49.595 262.415 45.6009 257.371 43.339 251.492C35.7958 231.956 40.6427 208.233 57.711 180.99C55.4477 180.325 49.2983 178.452 43.2546 176.076C32.6772 171.92 27.6507 168.372 27.89 165.228C27.978 164.069 28.594 163.075 29.7239 162.271C33.5009 159.576 42.3957 159.438 49.2139 159.817C57.3097 160.255 66.4053 161.619 72.9594 163.379L73.6387 163.562L73.2867 164.93L72.6074 164.746C59.7596 161.298 36.44 159.222 30.5512 163.421C29.7697 163.981 29.3614 164.605 29.305 165.33C28.953 169.741 47.3447 176.6 59.0098 179.885L59.9251 180.144L59.4006 180.969C42.1774 208.144 37.2249 231.708 44.652 250.997C46.8047 256.611 50.6121 261.43 55.5639 264.805C60.5127 268.196 66.3632 270.005 72.3505 269.997H90.2845C93.6919 269.997 96.0784 269.09 97.1942 267.373C98.0284 266.087 98.0883 264.395 97.3597 262.727C95.4026 258.245 87.3876 258.238 80.3196 258.234H78.6864L78.1442 256.385L78.5703 256.092C81.8473 253.83 89.3272 247.417 87.9755 236.802C86.9195 228.461 82.6428 222.756 75.2791 219.849L74.6243 219.592L75.1383 218.274L75.793 218.532C83.5614 221.599 88.2571 227.854 89.3729 236.625C90.7351 247.332 83.7762 254.018 80.0381 256.782H80.3302C87.8312 256.782 96.3354 256.782 98.6586 262.121C99.5808 264.247 99.4823 266.42 98.3805 268.112C97.4196 269.606 95.2301 271.384 90.2987 271.384Z"
        fill={bunnyColor}
        stroke={bunnyColor}
        strokeWidth="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M157.477 159.716L157.475 159.712C157.329 159.47 157.092 159.078 156.553 158.735C156.018 158.394 155.206 158.114 153.928 158.04C151.379 157.892 146.912 158.557 138.902 161.28C135.057 162.587 131.286 164.067 128.469 165.223C127.059 165.801 125.888 166.299 125.064 166.654C124.652 166.832 124.327 166.974 124.103 167.072C123.99 167.121 123.903 167.16 123.843 167.187L123.754 167.226L123.749 167.228L122.872 167.619L123.65 169.367L124.522 168.98L124.561 168.962C125.176 168.691 132.219 165.575 139.525 163.093C146.252 160.804 150.252 160.033 152.623 159.933C155.002 159.832 155.662 160.407 155.843 160.703C156.09 161.131 156.244 161.609 156.293 162.101C156.343 162.594 156.286 163.092 156.127 163.562L156.124 163.575C155.626 165.369 154.097 167.543 151.555 170.036C149.02 172.522 145.501 175.3 141.064 178.305C134.278 182.89 127.722 186.369 127.658 186.403L126.816 186.85L127.7 188.543L128.547 188.108L128.55 188.106C129.095 187.818 135.758 184.273 142.621 179.584C146.053 177.239 149.541 174.605 152.341 171.944C155.132 169.293 157.272 166.586 157.963 164.091C158.193 163.37 158.269 162.608 158.185 161.855C158.102 161.1 157.861 160.37 157.477 159.716Z"
        fill={bunnyColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M103.646 119.98C102.92 125.603 98.1411 129.946 92.3539 129.946C86.065 129.946 80.9668 124.818 80.9668 118.491C80.9668 112.166 86.065 107.038 92.3539 107.038C98.1904 107.038 103.001 111.455 103.663 117.147L112.85 116.922C113.611 111.339 118.373 107.038 124.132 107.038C130.421 107.038 135.519 112.166 135.519 118.491C135.519 124.818 130.421 129.946 124.132 129.946C118.268 129.946 113.439 125.488 112.813 119.757L103.646 119.98Z"
        fill={bunnyColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M119.716 118.271L123.162 112.688L124.358 113.434L120.912 119.017L119.716 118.271ZM90.3018 113.238L86.8563 118.821L88.0526 119.569L91.4981 113.985L90.3018 113.238ZM121.837 122.079L127.276 113.264L128.473 114.012L123.033 122.827L121.837 122.079ZM89.041 122.427L94.4803 113.612L95.6765 114.36L90.2373 123.173L89.041 122.427Z"
        fill="#FFFD54"
      />
      <path
        d="M49.6041 182.359C33.8664 178.411 22.4795 169.691 24.1655 162.885C25.8515 156.08 39.9806 153.754 55.7148 157.702L49.6041 182.359Z"
        fill="#1B9CAA"
      />
      <path
        d="M131.598 160.873C146.03 153.438 160.324 152.506 163.496 158.748C166.667 164.991 157.529 176.08 143.08 183.498L131.598 160.873Z"
        fill="#1B9CAA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.0709 143.993L41.8426 134.072L25.7988 143.457L34.6572 159.377L28.2887 183.87C28.0244 184.844 28.1553 185.882 28.6523 186.758C29.1494 187.633 29.972 188.276 30.9392 188.541C31.9058 188.806 32.9374 188.674 33.8072 188.174C34.6771 187.673 35.3142 186.847 35.5785 185.875L41.965 161.312L57.551 152.19L48.4836 135.896L51.0215 148.93L39.5921 155.489L33.0709 143.993Z"
        fill="#A1DFE6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.6308 133.407L42.9767 117.443L41.0791 117.641L42.7332 133.606L44.6308 133.407ZM57.0533 126.863L55.9035 125.333L46.6537 132.371L47.8035 133.9L57.0533 126.863ZM51.6724 119.455L49.8637 118.845L46.7837 128.089L48.5925 128.698L51.6724 119.455Z"
        fill="#FFFD54"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M145.851 143.319L152.698 131.964L138.608 144.119L150.177 158.152L148.329 183.413C148.244 184.418 148.559 185.417 149.205 186.188C149.852 186.958 150.776 187.439 151.775 187.524C152.773 187.61 153.765 187.293 154.532 186.642C155.298 185.993 155.776 185.063 155.861 184.058L157.715 158.734L171.406 146.925L159.557 132.553L164.397 144.906L154.339 153.44L145.851 143.319Z"
        fill="#A1DFE6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M159.861 114.001L157.952 113.925L157.565 123.671L159.472 123.749L159.861 114.001ZM156.188 128.935L150.101 114.097L148.338 114.83L154.424 129.668L156.188 128.935ZM166.246 119.111L164.71 117.972L157.824 127.357L159.358 128.497L166.246 119.111Z"
        fill="#FFFD54"
      />
    </BaseIllustration>
  );
};

export default IllustrationBunnyCharged;
