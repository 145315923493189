import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button2, Flex, Notification, Table2 } from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../../../analytics";
import { RunsDatum } from "../../../../api/core/controlPlane.types";
import { useUser } from "../../../../AuthProvider";
import Header from "../../../../components/Header";
import { useMetaTitle } from "../../../../components/Layout";
import PlanNotice from "../../../../components/PlanNotice";
import StandardError from "../../../../components/StandardError";
import {
  RUN_STATUS_V2_QUEUED,
  RUN_STATUS_V2_RUNNING,
} from "../../../../config/apps";
import useRunDetails from "../../../../contexts/apps/hooks/useRunDetails";
import useManageEntities from "../../../../hooks/useManageEntities";
import { userHasAccessToAction } from "../../../../utils/rbac_utils";
import { ActionGroups } from "../../../../utils/rbac_utils/types";
import { AppPageProps } from "../../App.types";
import RunsQueryDateControls from "../../components/RunsQueryDateControls";
import useRunsFilter from "../../hooks/useRunsFilter";
import { Table2HeaderObj } from "../RunHistory/RunHistory.types";
import { getFilteredRuns } from "../RunHistory/utils/getFilteredRuns";
import { getRunHistoryEmptyMessage } from "../RunHistory/utils/getRunHistoryEmptyMessage";
import { getRunHistoryItems } from "../RunHistory/utils/getRunHistoryItems";
import { getRunHistoryTableHeaders } from "../RunHistory/utils/getRunHistoryTableHeaders";
import { getTableLayoutSchemaAppRunHistory } from "../RunHistory/utils/tableLayoutSchemas";

const pageTitle = "Runs Queue";
type GroupEditItem = { id: string; isActive: boolean };

const RunsQueue = ({
  app,
  testOnlyHideDatePicker = false,
}: AppPageProps & { testOnlyHideDatePicker?: boolean }) => {
  const [{ id: accountId, roles }] = useUser();
  const [, setMetaTitle] = useMetaTitle();
  const theme = useTheme();

  const [filterText, setFilterText] = useState("");
  const [groupEditRuns, setGroupEditRuns] = useState<GroupEditItem[] | null>(
    null
  );
  const [isActiveGroupEditCheckAll, setIsActiveGroupEditCheckAll] =
    useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const {
    loadEntities: loadRuns,
    entities: runHistory,
    entitiesLoadError: runsLoadError,
    setEntities: setRunHistory,
  } = useManageEntities("runs");

  const {
    cancelGroupRuns,
    runCancelError,
    isSelectedGroupRunsCanceled,
    setIsSelectedGroupRunsCanceled,
  } = useRunDetails();

  const {
    clearSelectDateRange,
    confirmSelectDateRange,
    isActiveDatePicker,
    isDateQueriedRuns,
    queryEnd,
    queryStart,
    setIsActiveDatePicker,
    setIsDateQueriedRuns,
    setQueryEnd,
    setQueryStart,
  } = useRunsFilter();

  // manage page display
  useEffect(() => {
    setMetaTitle(pageTitle);
  }, [setMetaTitle]);

  // load runs (local, not context)
  useEffect(() => {
    if (!runHistory && !queryStart && !queryEnd && !runsLoadError) {
      loadRuns({
        applicationId: app.id,
        statusFilter: [RUN_STATUS_V2_QUEUED],
      });
    }
  }, [app.id, loadRuns, queryEnd, queryStart, runHistory, runsLoadError]);

  // load corresponding group edit data
  useEffect(() => {
    if (!groupEditRuns && runHistory) {
      const modRunHistory = runHistory.map((run) => {
        return {
          id: run.id,
          isActive: false,
        };
      });
      setGroupEditRuns(modRunHistory);
      setIsActiveGroupEditCheckAll(false);
    }
  }, [groupEditRuns, runHistory]);

  // load new runs if queried by date
  useEffect(() => {
    if (queryStart && queryEnd && !isActiveDatePicker) {
      setRunHistory(null);
      setIsDateQueriedRuns(true);
      setGroupEditRuns(null);
      setIsActiveGroupEditCheckAll(false);
      loadRuns({
        applicationId: app.id,
        queryStart,
        queryEnd,
        statusFilter: [RUN_STATUS_V2_QUEUED],
      });
    }
  }, [
    app.id,
    isActiveDatePicker,
    loadRuns,
    queryEnd,
    queryStart,
    setIsDateQueriedRuns,
    setRunHistory,
  ]);

  // disable processing state if cancel run error
  useEffect(() => {
    if (runCancelError && isProcessing) {
      setIsProcessing(false);
    }
  }, [isProcessing, runCancelError]);

  const groupActiveRunIds =
    groupEditRuns &&
    groupEditRuns.filter((run) => run.isActive).map((run) => run.id);

  const closeSelectDateRange = () => {
    setIsActiveDatePicker(false);

    if (!queryStart && !queryEnd && isDateQueriedRuns) {
      setRunHistory(null);
      setGroupEditRuns(null);
      setIsActiveGroupEditCheckAll(false);
      loadRuns({
        applicationId: app.id,
        statusFilter: [RUN_STATUS_V2_QUEUED],
      });
    }
    return;
  };

  const refreshRuns = () => {
    setGroupEditRuns(null);
    setIsActiveGroupEditCheckAll(false);
    loadRuns({
      applicationId: app.id,
      queryStart,
      queryEnd,
      statusFilter: [RUN_STATUS_V2_QUEUED],
    });
    return;
  };

  const handleGroupEditCheckAll = useCallback(() => {
    if (!groupEditRuns || !groupEditRuns.length) {
      setIsActiveGroupEditCheckAll(false);
      return;
    }

    setIsActiveGroupEditCheckAll((prevState) => {
      return !prevState;
    });
    setGroupEditRuns((prevState) => {
      if (!prevState) return null;
      const clonePrevState = JSON.parse(JSON.stringify(prevState));
      return clonePrevState.map((item: GroupEditItem) => {
        item.isActive = !isActiveGroupEditCheckAll;
        return item;
      });
    });
  }, [groupEditRuns, isActiveGroupEditCheckAll]);

  const handleGroupEditCheck = (e: { target: { value: string } }) => {
    const selectedRunId = e.target.value;
    setGroupEditRuns((prevState) => {
      if (!prevState) return null;
      const clonePrevState = JSON.parse(JSON.stringify(prevState));
      const selectedRunIndex = prevState?.findIndex(
        (item) => item.id === selectedRunId
      );
      clonePrevState[selectedRunIndex] = {
        id: selectedRunId,
        isActive: !clonePrevState[selectedRunIndex].isActive,
      };
      return clonePrevState;
    });
  };

  const handleCancelRuns = async (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    e.preventDefault();
    e.stopPropagation();

    if (!groupActiveRunIds) return;

    setIsProcessing(true);

    await cancelGroupRuns(app.id, groupActiveRunIds);
  };

  const runHistoryData = useMemo(() => {
    return getRunHistoryItems({
      hasName: true,
      hasStatistics: false,
      runHistory,
    });
  }, [runHistory]);
  const filteredRunHistory = getFilteredRuns(filterText, runHistoryData);
  const runHistoryHeaders: Table2HeaderObj[] = useMemo(() => {
    return getRunHistoryTableHeaders({
      accId: accountId,
      appId: app.id,
      groupEditHandleCheckAll: handleGroupEditCheckAll,
      groupEditHandleCheck: handleGroupEditCheck,
      groupEditIds: groupActiveRunIds,
      isGroupEdit: true,
      isActiveGroupEditCheckAll,
      runHistory: runHistory
        ? runHistory.map((run) => {
            let modRun = run as any;
            modRun["group_edit_id"] = run.id;
            return modRun as RunsDatum;
          })
        : [],
      showRunName: true,
      theme,
    });
  }, [
    accountId,
    app.id,
    groupActiveRunIds,
    handleGroupEditCheckAll,
    isActiveGroupEditCheckAll,
    runHistory,
    theme,
  ]);

  if (runsLoadError) {
    return <StandardError errorMessage={runsLoadError} />;
  }

  if (isSelectedGroupRunsCanceled && groupEditRuns) {
    trackEvent("RunHistory", {
      action: "Runs Canceled in Group",
      meta: {
        total: groupEditRuns?.filter((run) => run.isActive).length,
      },
    });

    setIsSelectedGroupRunsCanceled(false);
    setIsProcessing(false);
    setGroupEditRuns(null);
    setIsActiveGroupEditCheckAll(false);

    loadRuns({
      applicationId: app.id,
      statusFilter: [RUN_STATUS_V2_QUEUED],
    });
  }

  const canUserCancelRun = userHasAccessToAction(
    roles,
    ActionGroups.RunOperator,
    {}
  );

  return (
    <Box pb={[6, 8]}>
      <Header
        configPageTitle={{
          label: pageTitle,
          tooltipContent: `Queued runs for ${app.name} are shown below. Any run with a “${RUN_STATUS_V2_QUEUED}” status can be canceled. Once the status changes to “${RUN_STATUS_V2_RUNNING}” the run can no longer be canceled.`,
        }}
        configFilter={{
          inputText: filterText,
          testId: "filter-runs",
          setInputText: setFilterText,
        }}
      />

      <PlanNotice {...{ app }} type="no-custom-apps" />

      {runCancelError && (
        <Notification mt={3} type="error" message={runCancelError} />
      )}

      <Flex mt={3}>
        {canUserCancelRun && (
          <Button2
            mr={3}
            isLoading={isProcessing}
            label="Cancel selected runs"
            isDisabled={!groupEditRuns?.find((item) => item.isActive)}
            htmlType="button"
            onClick={handleCancelRuns}
            styles={{
              minWidth: rem(158),
            }}
          />
        )}

        {!testOnlyHideDatePicker && (
          <RunsQueryDateControls
            {...{
              clearSelectDateRange,
              closeSelectDateRange,
              confirmSelectDateRange,
              isActiveDatePicker,
              queryEnd,
              queryStart,
              refreshRuns,
              setIsActiveDatePicker,
              setQueryEnd,
              setQueryStart,
              setRunHistory,
              testOnlyHideDatePicker,
            }}
            itemsLoading={!runHistory}
          />
        )}
      </Flex>

      <Table2
        testId="app-runs-table"
        isWide
        canFilter
        canSort
        headers={runHistoryHeaders}
        isStickyColumn
        mt={3}
        layoutSchema={getTableLayoutSchemaAppRunHistory(runHistory, true)}
        emptyMessage={getRunHistoryEmptyMessage({
          filterText,
          isLoading: !runHistory,
          isRunsQueue: true,
        })}
        fileNameCSV={`run_history_${app.id}`}
        showCSVLink
        csvLinkTopValue={theme.spacing.s1}
        data={filteredRunHistory}
        initialState={{
          columnPinning: {
            left: ["id"],
          },
        }}
      />
    </Box>
  );
};

export default RunsQueue;
