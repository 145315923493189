import { Marker, Pane } from "react-leaflet";

import { MarkerPoint, VisibleVehicleRouteIds } from "../../../../Map.types";

import {
  createAnnotationIcon,
  getAnnotationZIndexOffset,
  mapPointsByVehicleId,
} from "./utils/stopAnnotations";

type StopAnnotationsProps = {
  points: MarkerPoint[];
  visibleVehicleRoutesById: VisibleVehicleRouteIds;
};

export default function StopAnnotations({
  points,
  visibleVehicleRoutesById,
}: StopAnnotationsProps) {
  const renderAnnotation = (
    point: MarkerPoint,
    stopNumber: number,
    vehicleIndex: number,
    totalVehicleStops: number
  ) => {
    return (
      <Marker
        zIndexOffset={getAnnotationZIndexOffset(
          stopNumber,
          vehicleIndex,
          totalVehicleStops
        )}
        key={point.name + "-stop-annotation"}
        position={point.position}
        icon={createAnnotationIcon(point, stopNumber)}
      />
    );
  };

  return (
    // this Pane will render above polylines/other markers, but below tooltip popups
    <Pane name="stop-annotations" style={{ zIndex: 600 }}>
      {mapPointsByVehicleId(points, visibleVehicleRoutesById, renderAnnotation)}
    </Pane>
  );
}
