import * as React from "react";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";

import { svgIconStyle } from "../lib/constants";

type StyledSvgProps = {
  activeColor?: string;
  focusColor?: string;
  hoverColor?: string;
};
const StyledSvg = styled.svg<StyledSvgProps>`
  ${(props) =>
    svgIconStyle({
      activeColor: props.activeColor,
      focusColor: props.focusColor,
      hoverColor: props.hoverColor,
    })}
`;

const IconDemoArrow = ({
  activeColor,
  focusColor,
  hoverColor,
  iconColor,
  iconSize,
}: {
  activeColor?: string;
  focusColor?: string;
  hoverColor?: string;
  iconColor?: string;
  iconSize?: number;
}) => {
  const theme = useTheme();
  const thisIconSize = iconSize || 32;
  return (
    <StyledSvg
      {...{ activeColor, focusColor, hoverColor }}
      width={thisIconSize}
      height={thisIconSize}
      viewBox="0 0 32 32"
      fill={iconColor || theme.color.gray700}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8929 8.8929C17.2834 8.50238 17.9166 8.50238 18.3071 8.8929L24.7071 15.2929C24.8946 15.4804 25 15.7348 25 16C25 16.2652 24.8946 16.5196 24.7071 16.7071L18.3071 23.1071C17.9166 23.4976 17.2834 23.4976 16.8929 23.1071C16.5024 22.7166 16.5024 22.0834 16.8929 21.6929L21.5858 17H8C7.44772 17 7 16.5523 7 16C7 15.4477 7.44772 15 8 15H21.5858L16.8929 10.3071C16.5024 9.91659 16.5024 9.28342 16.8929 8.8929Z"
      />
    </StyledSvg>
  );
};

export default IconDemoArrow;
