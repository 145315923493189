import { AuthFlowInputValue } from "../hooks/useAuthFlow";

export const HTML_TAG_REGEX = /<.+?>/g;

export const areInputsValid = (
  values: string[],
  invalidationRegex: RegExp
): boolean => {
  return !values.some((value) => invalidationRegex.test(value));
};

// generic set state wrapping function for auth flow inputs
export const handleInputChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  setValue: React.Dispatch<React.SetStateAction<AuthFlowInputValue>>
) => {
  if (!setValue) return;
  setValue(e.target.value);
  return;
};

// if state is being set with an input’s onFocus() event,
// if that input is in focus when the user clicks on a link elsewhere,
// a state-triggered re-render will occur and the click will not fire
// this function ensures the click does not get blocked by the re-render
export const handleOnFocusStateBlur = (
  e: React.FocusEvent<HTMLInputElement>,
  setFocusedFunction: React.Dispatch<React.SetStateAction<any>>,
  focusedFunctionValue: any
) => {
  if (e.relatedTarget instanceof HTMLAnchorElement) {
    return e.relatedTarget.click();
  }
  setFocusedFunction(focusedFunctionValue);
};
