import { useEffect } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

import { pageEvent } from "../../analytics";

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID || "");

export const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    // send pageview event to Segment (for Mixpanel)
    pageEvent(location.pathname);

    // new Google Analytics 4 implementation
    ReactGA.send("pageview");
  }, [location]);
};
