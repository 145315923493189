import * as React from "react";

import BaseIcon, { IconProps } from "./BaseIcon";

const IconLockOpen = ({
  iconSize,
  iconColor,
  iconColorActive,
  iconColorFocus,
  iconColorHover,
  ...rest
}: IconProps) => {
  return (
    <BaseIcon
      {...{
        iconSize,
        iconColor,
        iconColorActive,
        iconColorFocus,
        iconColorHover,
      }}
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.665 4.63603C9.665 3.79001 9.89858 3.18646 10.2619 2.79914C10.6194 2.41802 11.1784 2.16502 12.0014 2.16502C12.9112 2.16502 13.4923 2.4567 13.8418 2.90749C14.1448 3.29824 14.335 3.86967 14.335 4.63327H15.665C15.665 3.65592 15.4209 2.77348 14.8929 2.09252C14.2305 1.2383 13.2116 0.835022 12.0014 0.835022C10.8941 0.835022 9.95249 1.18491 9.29184 1.88924C8.637 2.58737 8.335 3.55182 8.335 4.63603V6.83502H2C1.35659 6.83502 0.834999 7.35661 0.834999 8.00002V14C0.834999 14.6434 1.35659 15.165 2 15.165H11C11.6434 15.165 12.165 14.6434 12.165 14V8.00002C12.165 7.35661 11.6434 6.83502 11 6.83502H9.665V4.63603ZM2.165 13.835V8.16502H10.835V13.835H2.165Z"
      />
    </BaseIcon>
  );
};

export default IconLockOpen;
