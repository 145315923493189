import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Text } from "@console/dsc";

import { useUser } from "../../AuthProvider";
import Header from "../../components/Header";
import { useMetaTitle } from "../../components/Layout";
import { getAccUrl } from "../../utils/navigation";

const pageTitle = "Access Denied";

const AccessDenied = () => {
  const [{ id: accountId }] = useUser();
  const [, setMetaTitle] = useMetaTitle();
  useEffect(() => {
    setMetaTitle(pageTitle);
  }, [setMetaTitle]);
  return (
    <>
      <Header configPageTitle={{ label: pageTitle }} />
      <Text mt={5} styleName="body-1" hasLink>
        You do not have access to this page. Try starting from{" "}
        <Link to={getAccUrl(accountId, "/dashboard")}>the dashboard</Link>.
      </Text>
    </>
  );
};

export default AccessDenied;
