import { DateTime } from "luxon";

export const getDatePastYear = (yearsAgo: number) => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - yearsAgo);
  return date;
};

export const getDatePastMonth = (monthsAgo: number) => {
  const date = new Date();
  date.setMonth(date.getMonth() - monthsAgo);
  return date;
};

export const getDatePastHour = (hoursAgo: number) => {
  const date = new Date();
  date.setHours(date.getHours() - hoursAgo);
  return date;
};

export const getCurrentDateString = () => {
  const date = DateTime.now();
  return date.toFormat("yyyy-MM-dd-Thhmm");
};

export const generateRandomId = () => {
  const randomIdentifier = Math.random().toString(36).substring(5, 10);
  return randomIdentifier;
};
