import { Auth } from "aws-amplify";

export const getToken = async (): Promise<string> => {
  try {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    return token;
  } catch (err) {
    if (err !== "No current user") {
      console.error(err);
    }
    return "";
  }
};
