import { Text } from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";
import { startCase } from "lodash";

import { DepotInfoStop } from "../../../../Map.types";
import { getStopColor } from "../../utils/mapMarkers";

export type PopupDepotListProps = {
  stopKind: string;
  depotInfo: DepotInfoStop[];
  displayVehiclesNoRoutes?: boolean;
  doesVehicleHaveRoute: (vehicleId?: string) => boolean;
};

export default function PopupDepotList({
  stopKind,
  depotInfo,
  displayVehiclesNoRoutes,
  doesVehicleHaveRoute,
}: PopupDepotListProps) {
  const theme = useTheme();

  return (
    <>
      <Text as="h5" mt={2} styleName="meta-2">
        {startCase(stopKind)}
      </Text>
      <ul>
        {depotInfo
          .filter(
            (t) =>
              t?.vehicleKind === stopKind &&
              (displayVehiclesNoRoutes || doesVehicleHaveRoute(t.vehicleId))
          )
          .map((t, i) => {
            return (
              <Text
                key={t?.vehicleId || `vehicle-${i}`}
                as="li"
                mt={i === 0 ? rem(2) : 0}
                styleName="meta-1-bold"
                styles={{
                  color: t?.vehicleTextColor
                    ? getStopColor(t.vehicleTextColor)
                    : theme.color.gray800,
                }}
              >
                {t?.vehicleId}
              </Text>
            );
          })}
      </ul>
    </>
  );
}
