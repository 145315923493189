import React from "react";

import LayoutPublic from "../../components/LayoutPublic";

import LogInRoutes from "./LogIn.routes";

const LogIn = () => {
  return (
    <LayoutPublic>
      <LogInRoutes />
    </LayoutPublic>
  );
};

export default LogIn;
