import * as React from "react";

import BaseIcon, { IconProps } from "./BaseIcon";

const IconExecutable = ({
  iconSize,
  iconColor,
  iconColorActive,
  iconColorFocus,
  iconColorHover,
  ...rest
}: IconProps) => {
  return (
    <BaseIcon
      {...{
        iconSize,
        iconColor,
        iconColorActive,
        iconColorFocus,
        iconColorHover,
      }}
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 3.5L3.99089 5.37092L8 7.07479L12.0091 5.37092L8 3.5ZM3 6.42855V11.4991L7.31953 13.5149V8.26434L3 6.42855ZM8.68047 8.26434V13.5149L13 11.4991V6.42855L8.68047 8.26434Z"
      />
    </BaseIcon>
  );
};

export default IconExecutable;
