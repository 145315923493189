import { OrganizationRole } from "../../../../../api/core/controlPlane.types";

export type RoleLabel = "Owner" | "Developer" | "Admin" | "Operator" | "Viewer";

export type TeamMemberRole = {
  label: RoleLabel;
  value: OrganizationRole;
};

const teamMemberRoles: TeamMemberRole[] = [
  {
    label: "Owner",
    value: "root",
  },
  {
    label: "Admin",
    value: "admin",
  },
];

const premiumRoles: TeamMemberRole[] = [
  {
    label: "Developer",
    value: "developer",
  },
  {
    label: "Operator",
    value: "operator",
  },
  {
    label: "Viewer",
    value: "viewer",
  },
];

// Copy the array instead of exporting the const so that logic internal to
// here does not get disrupted in the event the array returned is modified.
// The array is very small so this isn't very costly.
export function getKnownTeamMemberRoles(
  premiumRolesAccess: boolean
): TeamMemberRole[] {
  return [...teamMemberRoles, ...(premiumRolesAccess ? premiumRoles : [])];
}

export function teamMemberRoleFromOrgRole(
  role: OrganizationRole
): TeamMemberRole {
  if (role === "member")
    return {
      label: "Developer",
      value: "developer",
    };
  return teamMemberRoles.find((r) => r.value === role)!;
}
