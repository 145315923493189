import React, { useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import {
  Box,
  Button2,
  Flex,
  Input,
  Notification,
  RowDetail,
} from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { kebabCase } from "lodash";

import { trackEvent } from "../../../analytics";
import { RunProfile } from "../../../api/core/controlPlane.types";
import { useUser } from "../../../AuthProvider";
import Header from "../../../components/Header";
import { useMetaTitle } from "../../../components/Layout";
import Loading from "../../../components/Loading";
import { useRunProfiles } from "../../../contexts/runProfiles/RunProfiles.context";
import { standardInputValueByKey } from "../../../hooks/useStandardInputs";
import { ENTITY_ID_CHAR_COUNT_MAX } from "../../../utils/constants";
import { CONTENT_ID_RULES } from "../../../utils/systemIds";
import { IntegrationOption } from "../../Integrations/Integrations.types";
import IntegrationProfileSelect from "../components/Integrations.component";
import RunProfileDeprecationNotice from "../components/RunProfileDeprecationNotice";
import { emptyRp } from "../components/utils";
import { runProfileTooltipCopy } from "../data/microcopy";
import { getIdError } from "../utils/idError";

const pageTitle = "New run profile";

const NewRunProfile = () => {
  const [user] = useUser();
  const { features } = user;
  const [, setMetaTitle] = useMetaTitle();

  const { pathname } = useLocation();
  const newPathBase = pathname.split("/").slice(0, -1).join("/");

  const [pendingProfile, setPendingProfile] = useState<RunProfile>(emptyRp);
  const [runProfileIdUpdated, setRunProfileIdUpdated] = useState(false);

  const [submitted, setSubmitted] = useState(false);

  const [errors, setErrors] = useState({
    id: "",
  });

  useEffect(() => {
    setMetaTitle(pageTitle);
  }, [setMetaTitle]);

  const {
    addRunProfile,
    getProfileById,
    clearRunProfileError,
    runProfilesLoading,
    runProfilesError,
  } = useRunProfiles();

  useEffect(() => {
    return clearRunProfileError;
  }, [clearRunProfileError]);

  const idInputError = getIdError({
    id: pendingProfile.id,
    type: "run profile",
    checkIdFunc: getProfileById,
  });

  const handleInputChange = <T extends "name" | "description" | "id">({
    target: { name, value },
  }: {
    target: { name: T; value: RunProfile[T] };
  }) => {
    if (name === "id") {
      setRunProfileIdUpdated(true);
    }
    setPendingProfile({
      ...pendingProfile,
      [name]: standardInputValueByKey[name](value || ""),
      ...(!runProfileIdUpdated && name === "name"
        ? { id: kebabCase(value) }
        : {}),
    });
  };

  const handleInputTrack = <T extends "name" | "description" | "id">({
    target: { name, value },
  }: {
    target: { name: T; value: RunProfile[T] };
  }) => {
    if (value) {
      if (name === "id" && value !== kebabCase(pendingProfile.name)) {
        trackEvent("Config", {
          view: "Create Run Profile",
          action: "Run Profile ID Changed",
        });
      }
      if (["name", "description"].includes(name)) {
        trackEvent("Config", {
          view: "Create Run Profile",
          action: "Field Entered",
          meta: {
            field: name,
          },
        });
      }
    }
  };

  const handleRunProfileCreate = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    if (pendingProfile.id.length < 3) {
      setErrors({
        id: CONTENT_ID_RULES,
      });
      return;
    }

    if (pendingProfile?.integrations) {
      trackEvent("Config", {
        view: "Create Run Profile",
        action: "Integrations Added",
        meta: {
          type: pendingProfile.integrations.map(
            (integration) => integration.type
          ),
        },
      });
    }
    trackEvent("Config", {
      view: "Create Run Profile",
      action: "New Run Profile Created",
    });

    const { version, ...pendingProfileRest } = pendingProfile;
    addRunProfile(pendingProfileRest);

    setSubmitted(true);
  };

  const handleIntegrationIntegrationClick = (
    integrationSelected: IntegrationOption | undefined
  ) => {
    if (integrationSelected) {
      setPendingProfile({
        ...pendingProfile,
        integrations: (pendingProfile.integrations || []).concat({
          id: integrationSelected.id,
          type: integrationSelected.type,
          category: integrationSelected.category,
          class: integrationSelected.class,
          name: integrationSelected.name,
          description: integrationSelected.description,
        }),
      });
    }
  };

  const handleIntegrationRemoveOnClick = (integrationId: string) => {
    setPendingProfile({
      ...pendingProfile,
      integrations:
        pendingProfile.integrations?.filter(
          (selectedIntegration) => selectedIntegration.id !== integrationId
        ) || [],
    });
  };

  if (runProfilesLoading) {
    return <Loading />;
  }

  if (submitted && !runProfilesError) {
    return <Redirect to={`${newPathBase}/${pendingProfile.id}`} />;
  }

  return (
    <>
      <RunProfileDeprecationNotice />

      <Header configPageTitle={{ label: pageTitle }} />

      <Box mt={rem(-1)}>
        <form onSubmit={(e) => handleRunProfileCreate(e)}>
          <RowDetail
            property="Name"
            secondaryLabel="For reference only"
            render={
              <Box width="100%" maxWidth={rem(408)}>
                <Input
                  name="name"
                  htmlType="text"
                  placeholder="Profile name"
                  value={pendingProfile.name}
                  onChange={handleInputChange}
                  onBlur={handleInputTrack}
                />
              </Box>
            }
          />

          <RowDetail
            property="ID"
            tooltipCopy={runProfileTooltipCopy.id.content}
            tooltipExtraLinkLabel={runProfileTooltipCopy.id.extraLinkLabel}
            tooltipExtraLinkUrl={runProfileTooltipCopy.id.extraLinkUrl}
            render={
              <Box width="100%" maxWidth={rem(408)}>
                <Input
                  name="id"
                  htmlType="text"
                  isError={
                    (!!errors.id && pendingProfile.id.length < 3) ||
                    idInputError
                  }
                  errorMessage={idInputError || errors.id}
                  errorMessageTestId="run-profile-id-input"
                  placeholder="Run Profile ID"
                  maxLength={ENTITY_ID_CHAR_COUNT_MAX}
                  value={pendingProfile.id}
                  onChange={handleInputChange}
                  onBlur={handleInputTrack}
                />
              </Box>
            }
          />

          <RowDetail
            property="Description"
            secondaryLabel="(optional)"
            render={
              <Box width="100%" maxWidth={rem(408)}>
                <Input
                  name="description"
                  htmlType="text"
                  placeholder="Profile description"
                  value={pendingProfile.description}
                  onChange={handleInputChange}
                  onBlur={handleInputTrack}
                />
              </Box>
            }
          />

          {features?.onfleet && (
            <IntegrationProfileSelect
              {...{
                pendingIntegrations: pendingProfile.integrations || [],
                onAdd: handleIntegrationIntegrationClick,
                onRemove: handleIntegrationRemoveOnClick,
              }}
            />
          )}

          <Flex
            hasBorderTop
            hasBorderWidthThick
            pt={4}
            justifyContent="space-between"
          >
            <Box>
              {runProfilesError && (
                <Notification
                  data-testid="new-run-profile-error-message"
                  mt={1}
                  mb={5}
                  type="error"
                  hasContactExtra
                  message={`Error: ${runProfilesError}`}
                />
              )}
              <Button2
                isDisabled={
                  !pendingProfile.name || !pendingProfile.id || idInputError
                }
                label="Create run profile"
                size="large"
                htmlType="submit"
              />
            </Box>

            <Button2
              ml="auto"
              type="text"
              size="large"
              label="Cancel"
              to="/config/run-profiles"
              onClick={() =>
                trackEvent("Config", {
                  view: "Create Run Profile",
                  action: "Create Run Profile Canceled",
                })
              }
            />
          </Flex>
        </form>
      </Box>
    </>
  );
};

export default NewRunProfile;
