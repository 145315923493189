import { Link } from "react-router-dom";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { space } from "styled-system";

import { filterProps } from "../../lib/filterProps";

import { GetTabStylesParams, GetTabTextColor, TabProps } from "./Tabs.types";

export const getTabTextColor = ({
  isActive,
  isDisabled,
  state = "default",
  theme,
}: GetTabTextColor) => {
  if (isDisabled) {
    return theme.color.gray500;
  }
  if (state === "hover") {
    return isActive ? theme.color.gray800 : theme.color.gray700;
  }
  return isActive ? theme.color.gray800 : theme.color.gray600;
};

const getTabStyles = ({
  isActive,
  isDisabled,
  theme,
  type,
}: GetTabStylesParams) => css`
  display: block;
  margin-top: calc(${theme.spacing.s1} * -1);
  margin-right: calc(${theme.spacing.s2} * -1);
  margin-bottom: calc(${theme.spacing.s1} * -1);
  margin-left: calc(${theme.spacing.s2} * -1);
  padding-top: ${theme.spacing.s1};
  padding-right: ${theme.spacing.s2};
  padding-bottom: ${theme.spacing.s1};
  padding-left: ${theme.spacing.s2};
  text-decoration: none;
  cursor: pointer;
  color: ${getTabTextColor({ isActive, isDisabled, theme })};
  border-radius: ${theme.border.radius};

  &:visited {
    color: ${getTabTextColor({ isActive, isDisabled, theme })};
  }
  &:hover,
  &:focus,
  &:active {
    color: ${getTabTextColor({
      isActive,
      isDisabled,
      theme,
      state: "hover",
    })};
  }
  &:focus-visible {
    outline: 0;
    box-shadow: 0 0 0 ${theme.border.widthMediumShadowOnly}
      ${theme.color.gray700};
  }

  ${isDisabled &&
  `
    cursor: not-allowed;
    color: ${getTabTextColor({
      isActive,
      isDisabled,
      theme,
    })};
    &:visited {
      color: ${getTabTextColor({
        isActive,
        isDisabled,
        theme,
      })};
    }
  `}

  ${type === "panel" &&
  `
    background-color: ${isActive ? theme.color.gray200 : "transparent"};
  `}
`;

export const StyledTabs = styled("ul", {
  shouldForwardProp: (prop: any) => filterProps(prop),
})`
  ${space}

  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

export const TabA = styled.a<TabProps>`
  ${({ isActive, isDisabled, theme, thisType }) =>
    getTabStyles({
      isActive,
      isDisabled,
      theme,
      type: thisType,
    })}
`;

export const TabLink = styled(Link)<TabProps>`
  ${({ isActive, isDisabled, theme, thisType }) =>
    getTabStyles({
      isActive,
      isDisabled,
      theme,
      type: thisType,
    })}
`;
