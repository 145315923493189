import { startCase } from "lodash";

import { ENTITY_ID_CHAR_COUNT_MAX } from "../constants";

// make sure name string is no longer than ID char count max
// minus 3 characters so multiple entities can be added at once
const INDEX_TAG_CHAR_COUNT = 3;
export const getSafeNameIdForEntity = (
  prefix: string,
  id: string
): { id: string; name: string } => {
  if (!id || !prefix) return { id: "", name: "" };

  const fourDigitSlug = Date.now().toString().slice(-4);
  const safeIdMax =
    ENTITY_ID_CHAR_COUNT_MAX -
    INDEX_TAG_CHAR_COUNT -
    prefix.length -
    fourDigitSlug.length;
  let safeId = `${prefix}${fourDigitSlug}`;

  id.split("-").forEach((word) => {
    const wordCharCount = word.length;
    let safeSlug = "";

    if (wordCharCount > safeIdMax) {
      safeSlug = word.substring(0, safeIdMax - 1).toLowerCase();
    } else {
      safeSlug = word.toLowerCase();
    }

    const totalIdCharCount = safeId.length + safeSlug.length;
    if (totalIdCharCount >= safeIdMax) {
      return;
    } else {
      safeId = `${safeId}${safeId.length > 0 ? "-" : ""}${safeSlug}`;
    }
  });

  // get name based on ID
  const safeName = startCase(safeId);

  return { id: safeId, name: safeName };
};
