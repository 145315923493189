import React from "react";
import ace from "brace";
import { JsonEditor as Editor } from "jsoneditor-react";

import "brace/mode/json";
import "brace/theme/github";
import "brace/ext/searchbox";

import CustomAceEditorStyled from "./CustomAceEditor.styled";
import { JsonEditorProps } from "./types";

import "jsoneditor-react/es/editor.min.css";

const defaultProps: Partial<JsonEditorProps> = {
  allowedModes: ["code", "tree"],
  theme: "ace/theme/github",
  ace,
  search: true,
  htmlElementProps: { style: { height: "100%" } },
};

const CustomAceEditor = React.forwardRef((props: JsonEditorProps, ref) => {
  const { controlEditorModePositionTop, ...rest } = props;
  return (
    <CustomAceEditorStyled
      width="100%"
      height="100%"
      {...{ controlEditorModePositionTop }}
    >
      <Editor ref={ref} {...defaultProps} {...rest} />
    </CustomAceEditorStyled>
  );
});

export default CustomAceEditor;
