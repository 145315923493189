import * as React from "react";

import { StyledText } from "./Text.styles";
import { TextProps } from "./Text.types";

const Text = ({
  as = "p",
  children,
  className,
  htmlFor,
  htmlTitle,
  htmlDateTime,
  innerRef,
  testId,

  // style props
  hasCodeInline,
  hasLink,
  hasLinkBlack,
  hasLinkGray,
  isCodeInline,
  isLink,
  isLinkGray,
  styleName,
  styles,

  // styled-system design API
  m,
  mt,
  mr,
  mb,
  ml,
  p,
  pt,
  pr,
  pb,
  pl,

  // anything else
  ...rest
}: TextProps) => {
  // `as` prop seems to be flaky...
  // https://github.com/emotion-js/emotion/issues/2012
  // @ts-ignore
  const Tag = StyledText.withComponent(as);

  return (
    <Tag
      ref={innerRef}
      title={htmlTitle}
      datetime={htmlDateTime}
      {...(testId && {
        "data-testid": testId,
      })}
      {...{
        className,

        // style
        hasCodeInline,
        hasLink,
        hasLinkBlack,
        hasLinkGray,
        isCodeInline,
        isLink,
        isLinkGray,
        styleName,
        styles,

        // styled-system design API
        m,
        mt,
        mr,
        mb,
        ml,
        p,
        pt,
        pr,
        pb,
        pl,

        // pass through for any other attribute
        // see shouldForwardProp in Text.styles for prop filtering
        ...rest,
      }}
    >
      {children}
    </Tag>
  );
};

export default Text;
