import { rem } from "@console/dsc/src/lib/tools";

export const DATA_POLLING_INTERVALS = [
  1000, 2000, 2000, 2000, 4000, 4000, 8000, 8000, 15000, 30000,
];
export const DATA_POLLING_DEFAULT = 60000;

export const INPUT_WIDTH_STANDARD: string = rem(408);
export const INPUT_WIDTH_SMALL: string = rem(256);
export const INPUT_WIDTH_NARROW: string = rem(120);

export const MEASURE_ENTITY_ROW: string = rem(680);

export const META_LABEL_WIDTH: string = rem(120);
