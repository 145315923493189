import React, { useEffect, useMemo } from "react";
import {
  Box,
  Button2,
  Flex,
  IconX,
  Input,
  Tabs,
  Text,
  Tooltip,
} from "@console/dsc";
import { useTheme } from "@emotion/react";

import {
  AppResponse,
  EntityErrorMessage,
} from "../../../api/core/controlPlane.types";
import AddConfigOptions from "../../../components/AddConfigOptions";
import {
  ConfigOption,
  HandleConfigOptionChangeParams,
} from "../../../components/AddConfigOptions/AddConfigOptions.types";
import InstanceSelect from "../../../components/InstanceSelect";
import { PendingInstances } from "../../../components/InstanceSelect/InstanceSelect.types";
import {
  DEFAULT_OPTION_SET_ID_LABEL,
  TAB_ID_INPUT_SET,
  TAB_ID_UPLOAD_FILES,
} from "../../../config/experiments";
import { INPUT_WIDTH_STANDARD } from "../../../config/general";
import {
  getPlanSetConfigOptionValueSets,
  getPlanSetRunCount,
} from "../../../contexts/experiments/utils/planSets";
import useStandardInputs, {
  getEmptyEntityError,
} from "../../../hooks/useStandardInputs";
import {
  ENTITY_ID_CHAR_COUNT_MAX,
  ENTITY_ID_CHAR_COUNT_MIN,
} from "../../../utils/constants";
import { getSafeNameIdForEntity } from "../../../utils/entities/getSafeNameIdForEntity";
import { CONTENT_ID_RULES } from "../../../utils/systemIds";
import { getConfigForNewInputSet } from "../config/configDisplay";
import { scenarioTooltips } from "../data/microcopy";
import { PlanSet, RowInputSetTabId } from "../Experiments.types";
import NewInputSet from "../subpages/NewInputSet";

import SelectInputSet from "./SelectInputSet";
import { createOptions } from "./utils";

type AddPlanSetsProps = {
  app: AppResponse;
  pendingPlanSets: PlanSet[];
  pendingScenarioId: string;
  setPendingPlanSets: React.Dispatch<React.SetStateAction<PlanSet[]>>;
  setScenarioTestAddError: React.Dispatch<
    React.SetStateAction<EntityErrorMessage>
  >;
  shouldSubmitInputSet: boolean;
};

const getEmptyConfigOption = (): ConfigOption => {
  return { option: "", value: "" };
};

const getEmptyPlanSet = (planSetLength: number): PlanSet => {
  return {
    activeTabId: TAB_ID_INPUT_SET,
    configOptions: [getEmptyConfigOption()],
    id: `${DEFAULT_OPTION_SET_ID_LABEL}-${planSetLength + 1}`,
    idError: null,
    inputCount: 0,
    inputSetId: "",
    instances: [undefined],
    isInputSetAdded: false,
    runCount: 0,
    shouldUseFilesForInputSet: false,
  };
};

const AddPlanSets = ({
  app,
  pendingPlanSets,
  pendingScenarioId,
  setPendingPlanSets,
  setScenarioTestAddError,
  shouldSubmitInputSet,
}: AddPlanSetsProps) => {
  const theme = useTheme();
  const { getMaxLength } = useStandardInputs();

  // load initial blank option set
  useEffect(() => {
    if (!pendingPlanSets.length) {
      setPendingPlanSets([getEmptyPlanSet(0)]);
    }
  }, [pendingPlanSets.length, setPendingPlanSets]);

  const newInputSetIdAndName = useMemo(() => {
    return getSafeNameIdForEntity("inpset", pendingScenarioId);
  }, [pendingScenarioId]);

  const addEmptyPlanSet = (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    e.preventDefault();
    e.stopPropagation();

    setPendingPlanSets((prevState) => {
      return [...prevState, getEmptyPlanSet(prevState.length)];
    });

    return;
  };

  const removePendingPlanSet = (
    e: {
      preventDefault: () => void;
      stopPropagation: () => void;
    },
    index: number
  ) => {
    e.preventDefault();
    e.stopPropagation();

    setPendingPlanSets((prevState) => {
      prevState.splice(index, 1);
      return [...prevState];
    });

    return;
  };

  const addEmptyPlanSetConfigOption =
    (e: { preventDefault: () => void; stopPropagation: () => void }) =>
    (index: number) => {
      e.preventDefault();
      e.stopPropagation();

      const pendingPlanSet = pendingPlanSets[index];
      const { configOptions } = pendingPlanSet;
      pendingPlanSet.configOptions = [...configOptions, getEmptyConfigOption()];

      setPendingPlanSets((prevState) => {
        prevState[index] = pendingPlanSet;
        return [...prevState];
      });

      return;
    };

  const setPendingPlanSetInstances =
    (pendingPlanSetInstances: PendingInstances) => (index: number) => {
      const pendingPlanSet = pendingPlanSets[index];
      pendingPlanSet.instances = pendingPlanSetInstances;

      setPendingPlanSets((prevState) => {
        prevState[index] = pendingPlanSet;
        return [...prevState];
      });

      return;
    };

  const setPendingPlanSetInputSetId =
    (inputSetId: string) => (index: number) => {
      const pendingPlanSet = pendingPlanSets[index];
      pendingPlanSet.inputSetId = inputSetId;

      setPendingPlanSets((prevState) => {
        prevState[index] = pendingPlanSet;
        return [...prevState];
      });

      return;
    };

  const setPendingPlanSetInputCount =
    (inputCount: number) => (index: number) => {
      const pendingPlanSet = pendingPlanSets[index];
      pendingPlanSet.inputCount = inputCount;
      pendingPlanSet.runCount = getPlanSetRunCount(pendingPlanSet);

      setPendingPlanSets((prevState) => {
        prevState[index] = pendingPlanSet;
        return [...prevState];
      });

      return;
    };

  const setPendingPlanSetInputSetActiveTab =
    (tabId: RowInputSetTabId) => (index: number) => {
      const pendingPlanSet = pendingPlanSets[index];
      pendingPlanSet.activeTabId = tabId;

      setPendingPlanSets((prevState) => {
        prevState[index] = pendingPlanSet;
        return [...prevState];
      });

      return;
    };

  const setPendingPlanSetIsInputSetAdded =
    (isInputSetAdded: boolean) => (index: number) => {
      const pendingPlanSet = pendingPlanSets[index];
      pendingPlanSet.isInputSetAdded = isInputSetAdded;

      setPendingPlanSets((prevState) => {
        prevState[index] = pendingPlanSet;
        return [...prevState];
      });

      return;
    };

  const setPendingPlanSetShouldUseFilesForInputSet =
    (shouldUseFilesForInputSet: boolean) => (index: number) => {
      const pendingPlanSet = pendingPlanSets[index];
      pendingPlanSet.shouldUseFilesForInputSet = shouldUseFilesForInputSet;

      setPendingPlanSets((prevState) => {
        prevState[index] = pendingPlanSet;
        return [...prevState];
      });

      return;
    };

  const handlePendingPlanSetConfigOptionChange =
    ({ e, index, type }: HandleConfigOptionChangeParams) =>
    (pendingPlanSetIndex: number) => {
      const pendingPlanSet = pendingPlanSets[pendingPlanSetIndex];
      const pendingPlanSetConfigOptions = pendingPlanSet.configOptions;
      const pendingPlanSetConfigOption = pendingPlanSet.configOptions[index];

      const inputValue = e.target.value;
      pendingPlanSetConfigOption[type] =
        type === "option" ? inputValue.trim() : inputValue;

      // insert updated config option back into pendingPlanSet
      pendingPlanSetConfigOptions[index] = pendingPlanSetConfigOption;
      pendingPlanSet.configOptions = pendingPlanSetConfigOptions;

      // update run count
      pendingPlanSet.runCount = getPlanSetRunCount(pendingPlanSet);

      // update state with new pendingPlanSet
      setPendingPlanSets((prevState) => {
        prevState[pendingPlanSetIndex] = pendingPlanSet;
        return [...prevState];
      });

      return;
    };

  const removePendingPlanSetConfigOption =
    (_e: any, index: number) => (pendingPlanSetIndex: number) => {
      const pendingPlanSet = pendingPlanSets[pendingPlanSetIndex];
      const pendingPlanSetConfigOptions = pendingPlanSet.configOptions;

      const pendingPlanSetConfigOptionsModified =
        pendingPlanSetConfigOptions.filter((_pendingOption, i) => {
          return i !== index;
        });

      pendingPlanSet.configOptions = pendingPlanSetConfigOptionsModified;

      // update run count
      pendingPlanSet.runCount = getPlanSetRunCount(pendingPlanSet);

      // update state with new pendingPlanSet
      setPendingPlanSets((prevState) => {
        prevState[pendingPlanSetIndex] = pendingPlanSet;
        return [...prevState];
      });

      return;
    };

  // only check value and min length on blur (avoid immediate errors)
  const checkIdForError = (
    value: string,
    blurCheck: boolean = false
  ): EntityErrorMessage => {
    if (blurCheck && !value) {
      return getEmptyEntityError("id");
    }

    if (
      value.length > ENTITY_ID_CHAR_COUNT_MAX ||
      (blurCheck && value.length < ENTITY_ID_CHAR_COUNT_MIN)
    ) {
      return CONTENT_ID_RULES;
    }

    return null;
  };

  const getScenarioIdSlug = (id: string) => {
    return id.trimStart().replace(" ", "-");
  };
  const handlePendingPlanSetIdChange = (
    e: { target: { value: string } },
    index: number
  ) => {
    const pendingPlanSet = pendingPlanSets[index];
    if (!pendingPlanSet) return;
    const pendingPlanSetId = getScenarioIdSlug(e?.target?.value);
    pendingPlanSet.id = pendingPlanSetId;
    pendingPlanSet.idError = checkIdForError(pendingPlanSetId);

    setPendingPlanSets((prevState) => {
      prevState[index] = pendingPlanSet;
      return [...prevState];
    });

    return;
  };
  const handlePendingPlanSetIdOnBlur = (
    e: { target: { value: string } },
    index: number
  ) => {
    const pendingPlanSet = pendingPlanSets[index];
    if (!pendingPlanSet) return;
    const pendingPlanSetId = getScenarioIdSlug(e?.target?.value);
    pendingPlanSet.id = pendingPlanSetId;
    pendingPlanSet.idError = checkIdForError(pendingPlanSetId, true);

    setPendingPlanSets((prevState) => {
      prevState[index] = pendingPlanSet;
      return [...prevState];
    });

    return;
  };

  const renderPendingPlanSets = () => {
    return pendingPlanSets.map((pendingPlanSet, pendingPlanSetIndex) => {
      const isFirst = pendingPlanSetIndex === 0;

      const {
        activeTabId,
        inputSetId,
        instances,
        isInputSetAdded,
        shouldUseFilesForInputSet,
      } = pendingPlanSet;

      const pendingPlanSetId = pendingPlanSet.id;
      const pendingPlanSetIdError = pendingPlanSet.idError;
      const planSetRunCount = pendingPlanSet.runCount;

      const pendingInputSetId = `${newInputSetIdAndName.id}-${pendingPlanSetIndex}`;
      const pendingInputSetName = `${newInputSetIdAndName.name}-${pendingPlanSetIndex}`;

      return (
        <Flex
          key={`pending-option-set-${pendingPlanSetIndex}`}
          flexDirection={["column", "column", "column", "row"]}
          {...(!isFirst && {
            mt: 5,
            pt: 4,
            hasBorderTop: true,
          })}
        >
          <Box
            width={["100%", "100%", "100%", "100%"]}
            maxWidth={INPUT_WIDTH_STANDARD}
          >
            <Flex alignItems="flex-start">
              {pendingPlanSets.length > 1 && (
                <Button2
                  mt={1}
                  type="text-quiet"
                  icon={
                    <IconX
                      iconColor={theme.color.gray500}
                      iconColorHover={theme.color.gray600}
                    />
                  }
                  onClick={(e: {
                    preventDefault: () => void;
                    stopPropagation: () => void;
                  }) => removePendingPlanSet(e, pendingPlanSetIndex)}
                  styles={{ marginLeft: theme.spacing["-s8"] }}
                />
              )}

              <Box width="100%">
                <Input
                  htmlType="text"
                  name={`${pendingPlanSetId}-id`}
                  testId={`${pendingPlanSetId}-id-input`}
                  placeholder="Scenario ID"
                  maxLength={getMaxLength("id")}
                  value={pendingPlanSetId}
                  onChange={(e: { target: { value: string } }) =>
                    handlePendingPlanSetIdChange(e, pendingPlanSetIndex)
                  }
                  onBlur={(e: { target: { value: string } }) =>
                    handlePendingPlanSetIdOnBlur(e, pendingPlanSetIndex)
                  }
                  errorMessage={pendingPlanSetIdError}
                  errorMessageTestId={`${pendingPlanSetId}-id-input-error`}
                  isError={pendingPlanSetIdError}
                />
              </Box>
              <Tooltip mt={3} mr={-8} ml={2}>
                {scenarioTooltips.scenarioId.content}
              </Tooltip>
            </Flex>

            <Box>
              <Tabs
                mt={3}
                ml={2}
                mb={3}
                size="small"
                type="panel"
                tabs={[
                  {
                    id: TAB_ID_INPUT_SET,
                    isActive: activeTabId === TAB_ID_INPUT_SET,
                    label: "Existing",
                    isDisabled: shouldUseFilesForInputSet,
                    onClick: (e: {
                      preventDefault: () => void;
                      stopPropagation: () => void;
                    }) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setPendingPlanSetInputSetActiveTab(TAB_ID_INPUT_SET)(
                        pendingPlanSetIndex
                      );
                    },
                  },
                  {
                    id: TAB_ID_UPLOAD_FILES,
                    isActive: activeTabId === TAB_ID_UPLOAD_FILES,
                    label: "Upload files",
                    onClick: (e: {
                      preventDefault: () => void;
                      stopPropagation: () => void;
                    }) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setPendingPlanSetInputSetActiveTab(TAB_ID_UPLOAD_FILES)(
                        pendingPlanSetIndex
                      );
                    },
                  },
                ]}
              />

              {activeTabId === TAB_ID_INPUT_SET && (
                <SelectInputSet
                  hasAvatar
                  pendingInputSetId={inputSetId}
                  testId="new-scenario-test-input-set-select"
                  appId={app.id}
                  onAdd={(pendingInputSetId: string) =>
                    setPendingPlanSetInputSetId(pendingInputSetId)(
                      pendingPlanSetIndex
                    )
                  }
                  setInputSetInputsCount={(inputsCount) =>
                    setPendingPlanSetInputCount(inputsCount)(
                      pendingPlanSetIndex
                    )
                  }
                />
              )}

              {activeTabId === TAB_ID_UPLOAD_FILES && (
                <Box mt={-4} mb={-4}>
                  <NewInputSet
                    app={app}
                    configDisplay={getConfigForNewInputSet({
                      createTypeOption: createOptions[1],
                      entity: "scenario test",
                      id: pendingInputSetId,
                      name: pendingInputSetName,
                    })}
                    setParentError={setScenarioTestAddError}
                    setSignalHasSubmitted={(isInputSetAdded: boolean) => {
                      setPendingPlanSetInputSetId(pendingInputSetId)(
                        pendingPlanSetIndex
                      );
                      setPendingPlanSetIsInputSetAdded(isInputSetAdded)(
                        pendingPlanSetIndex
                      );
                    }}
                    setSignalIsInUse={(shouldUseFilesForInputSet: boolean) =>
                      setPendingPlanSetShouldUseFilesForInputSet(
                        shouldUseFilesForInputSet
                      )(pendingPlanSetIndex)
                    }
                    signalHasSubmitted={isInputSetAdded}
                    signalShouldSubmit={shouldSubmitInputSet}
                    signalIsInUse={shouldUseFilesForInputSet}
                    setInputSetInputsCount={(inputsCount) =>
                      setPendingPlanSetInputCount(inputsCount)(
                        pendingPlanSetIndex
                      )
                    }
                    wrapper={(children: React.ReactNode) => (
                      <div>{children}</div>
                    )}
                  />
                </Box>
              )}
            </Box>

            <Box mt={1}>
              <InstanceSelect
                {...{ app }}
                hasAvatar
                pendingInstances={instances}
                setPendingInstances={(pendingInstances: PendingInstances) =>
                  setPendingPlanSetInstances(pendingInstances)(
                    pendingPlanSetIndex
                  )
                }
                isSingleSelect
              />
            </Box>

            <Box mt={1} maxWidth={INPUT_WIDTH_STANDARD}>
              <AddConfigOptions
                addEmptyConfigOption={(e) =>
                  addEmptyPlanSetConfigOption(e)(pendingPlanSetIndex)
                }
                handleConfigOptionChange={({
                  e,
                  index,
                  pendingConfigOptions,
                  type,
                }) =>
                  handlePendingPlanSetConfigOptionChange({
                    e,
                    index,
                    pendingConfigOptions,
                    type,
                  })(pendingPlanSetIndex)
                }
                pendingConfigOptions={pendingPlanSet.configOptions}
                removeConfigOption={(e, index) =>
                  removePendingPlanSetConfigOption(
                    e,
                    index
                  )(pendingPlanSetIndex)
                }
              />
            </Box>
          </Box>

          <Box
            width={["100%", "100%", "100%", "50%"]}
            mt={[5, 5, 5, 0]}
            pl={[0, 0, 0, 9]}
          >
            <Text styleName="body-3">Scenario run count</Text>
            <Text
              styleName="body-3-bold"
              styles={{ color: theme.color.gray800 }}
            >
              {planSetRunCount}
            </Text>
            <Text mt={3} styleName="body-3">
              Configuration applied to each run in input set
            </Text>
            {getPlanSetConfigOptionValueSets(pendingPlanSet.configOptions).map(
              (valueSet, index) => (
                <Text
                  key={`${pendingPlanSetId}-value-set-${index}`}
                  styleName="body-3"
                >
                  {valueSet.join(", ")}
                </Text>
              )
            )}
          </Box>
        </Flex>
      );
    });
  };

  return (
    <>
      {renderPendingPlanSets()}

      <Box mt={5} pt={4} hasBorderTop>
        <Button2
          htmlType="button"
          type="outline"
          label="Add additional scenario"
          onClick={(e: {
            preventDefault: () => void;
            stopPropagation: () => void;
          }) => addEmptyPlanSet(e)}
        />
      </Box>
    </>
  );
};

export default AddPlanSets;
