import { useCallback, useState } from "react";

import { EntityErrorMessage } from "../../../api/core/controlPlane.types";
import useDownloadAndUntar from "../../../utils/fileHandling/useDownloadAndUntar";

const useAppInput = () => {
  const [loadGzippedAppInputError, setLoadGzippedAppInputError] =
    useState<EntityErrorMessage>(null);

  const downloadAndUntar = useDownloadAndUntar();

  const loadGzippedAppInput = useCallback(
    async (downloadUrl, inputId) => {
      try {
        await downloadAndUntar(downloadUrl, inputId);
        setLoadGzippedAppInputError(null);
      } catch (e: any) {
        console.error(e);
        setLoadGzippedAppInputError(e.toString());
      }
    },
    [downloadAndUntar]
  );

  return {
    loadGzippedAppInput,
    loadGzippedAppInputError,
  };
};

export default useAppInput;
