const nextmvBlogRSSFeedItems = [
  `<item><title>Optimizing routes for student pickup and dropoff with HopSkipDrive and Nextmv</title><link>https://www.nextmv.io/blog/optimizing-routes-for-student-pickup-and-dropoff-with-hopskipdrive-and-nextmv</link><guid>https://www.nextmv.io/blog/optimizing-routes-for-student-pickup-and-dropoff-with-hopskipdrive-and-nextmv</guid><description>To solve the complex transportation challenges of school districts nationwide, HopSkipDrive leverages Nextmv’s building blocks to develop its proprietary and customizable student routing solution.</description><pubDate>Wed, 12 Jun 2024 15:00:00 GMT</pubDate></item>`,
  `<item><title>Link and test logistics models for demand forecasting, shift scheduling, and vehicle routing</title><link>https://www.nextmv.io/blog/link-and-test-logistics-models-for-demand-forecasting-shift-scheduling-and-vehicle-routing</link><guid>https://www.nextmv.io/blog/link-and-test-logistics-models-for-demand-forecasting-shift-scheduling-and-vehicle-routing</guid><description>Follow this step-by-step tutorial to go from a forecasted demand to optimized routes for delivery and similar use cases. Create and customize decision apps using OR-Tools, HiGHS, Pyomo, and more.</description><pubDate>Thu, 23 May 2024 21:18:00 GMT</pubDate></item>`,
  `<item><title>Optimizing your AI investment with decision services</title><link>https://www.nextmv.io/blog/optimizing-your-ai-investment-with-decision-services</link><guid>https://www.nextmv.io/blog/optimizing-your-ai-investment-with-decision-services</guid><description>Identify and link operational areas that benefit from decision models such as vehicle routing, worker scheduling, and order fulfillment — empowering your teams to deliver more decision AI projects faster. </description><pubDate>Wed, 08 May 2024 15:00:00 GMT</pubDate></item>`,
  `<item><title>What is a decision model test bench? Why have one? And how to use Nextmv as one.</title><link>https://www.nextmv.io/blog/what-is-a-decision-model-test-bench-why-have-one-and-how-to-use-nextmv-as-one</link><guid>https://www.nextmv.io/blog/what-is-a-decision-model-test-bench-why-have-one-and-how-to-use-nextmv-as-one</guid><description>Model testing saves time and improves outcomes. But it’s not always possible to perform tests in the same environment as your production runs. That’s where a test bench comes in. </description><pubDate>Thu, 02 May 2024 12:00:00 GMT</pubDate></item>`,
  `<item><title>Haversine vs. OSRM: Distance and cost experiments on a vehicle routing problem (VRP)</title><link>https://www.nextmv.io/blog/haversine-vs-osrm-distance-and-cost-experiments-on-a-vehicle-routing-problem-vrp</link><guid>https://www.nextmv.io/blog/haversine-vs-osrm-distance-and-cost-experiments-on-a-vehicle-routing-problem-vrp</guid><description>How do you test if you should use map data over Haversine for a vehicle routing problem? Let’s use batch experiments with Nextmv to find out! </description><pubDate>Wed, 03 Apr 2024 12:00:00 GMT</pubDate></item>`,
];

const nextmvBlogRSSFeedHeader = `<?xml version="1.0" encoding="utf-8"?><rss version="2.0" xmlns:atom="http://www.w3.org/2005/Atom" xmlns:media="http://search.yahoo.com/mrss/"><channel><title>Nextmv Blog</title><link>https://www.nextmv.io</link><description>The latest and greatest about decision optimization from Nextmv. </description><pubDate>Fri, 08 Mar 2024 15:03:35 GMT</pubDate><generator>Webflow</generator><atom:link href="https://www.nextmv.io/blog/rss.xml" rel="self" type="application/rss+xml"/>`;
const nextmvBlogRSSFeedFooter = `</channel></rss>`;

export const nextmvBlogPosts = `${nextmvBlogRSSFeedHeader}${nextmvBlogRSSFeedItems.join(
  ""
)}${nextmvBlogRSSFeedFooter}`;
