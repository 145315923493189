import React from "react";
import { useTheme } from "@emotion/react";
import { DateTime } from "luxon";

import { rem } from "../../../lib/tools";
import Button2 from "../../Button2";
import Flex from "../../Flex";
import Text from "../../Text";
import Tooltip from "../../Tooltip";
import { StyledInputTime } from "../DatePicker.styled";
import {
  DatePickerFooterProps,
  RenderSelectedDateTimeParams,
} from "../DatePicker.types";
import {
  DATEPICKER_DEFAULT_TIME_FROM,
  DATEPICKER_DEFAULT_TIME_TO,
} from "../utils/constants";

import "react-day-picker/dist/style.css";

const renderDateTime = ({
  date,
  setTime,
  time,
}: RenderSelectedDateTimeParams) => {
  return (
    <Flex alignItems="center" flexDirection="row">
      <Text styleName="body-2">
        {DateTime.fromJSDate(date).toFormat("LLL dd, yyyy")}
      </Text>
      <StyledInputTime
        type="time"
        value={time}
        onChange={(e) => setTime(e.target.value)}
      />
    </Flex>
  );
};

const DatePickerFooter = ({
  clearAction,
  isRange,
  primaryAction,
  range,
  setRange,
  setTimeFrom,
  setTimeTo,
  timeFrom,
  timeTo,
}: DatePickerFooterProps) => {
  const theme = useTheme();

  const clearActionWrap = () => {
    setRange(undefined);
    setTimeFrom(DATEPICKER_DEFAULT_TIME_FROM);
    setTimeTo(DATEPICKER_DEFAULT_TIME_TO);

    clearAction();
    return;
  };

  const renderTimeRange = () => {
    if (range && range.from && !range.to) {
      return renderDateTime({
        date: range.from,
        setTime: setTimeFrom,
        time: timeFrom,
      });
    }
    if (range && range.from && range.to) {
      return (
        <Flex alignItems="center">
          {renderDateTime({
            date: range.from,
            setTime: setTimeFrom,
            time: timeFrom,
          })}
          <Text
            as="span"
            ml={2}
            mr={2}
            styleName="body-2"
            styles={{ color: theme.color.gray600 }}
          >
            &ndash;
          </Text>
          {renderDateTime({ date: range.to, setTime: setTimeTo, time: timeTo })}
        </Flex>
      );
    }
    return (
      <Text
        styleName="body-2"
        mt={rem(2)}
        mb={rem(3)}
        styles={{ color: theme.color.gray600 }}
      >
        {isRange ? "Select a date range" : "Select a date"}
      </Text>
    );
  };

  return (
    <Flex
      mt={1}
      mr={4}
      ml={4}
      pt={4}
      pb={3}
      hasBorderTop
      alignItems={[
        "flex-start",
        "flex-start",
        isRange ? "center" : "flex-start",
      ]}
      flexDirection={["column", "column", isRange ? "row" : "column"]}
    >
      <Button2
        mr={4}
        type="outline"
        htmlType="button"
        label={isRange ? "Set range" : "Set day/time"}
        onClick={() => primaryAction()}
        styles={{
          order: isRange ? 0 : 1,
        }}
      />

      <Flex
        mt={[2, 2, 0]}
        mb={isRange ? 0 : 2}
        alignItems="center"
        flexOrder={isRange ? 1 : 0}
      >
        {renderTimeRange()}

        {range?.from && (
          <Tooltip ml={1}>
            Times are shown in {DateTime.now().toFormat("ZZZZZ")}
          </Tooltip>
        )}
      </Flex>

      <Button2
        ml="auto"
        type="text-quiet"
        htmlType="button"
        label="Clear"
        onClick={() => clearActionWrap()}
        styles={{
          marginTop: isRange ? 0 : theme.spacing["-s7"],
          order: 2,
        }}
      />
    </Flex>
  );
};

export default DatePickerFooter;
