import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { trackEvent } from "../../../../analytics";
import { AppInputsData } from "../../../../api/core/controlPlane.types";
import { useUser } from "../../../../AuthProvider";
import Footer from "../../../../components/Footer";
import Header from "../../../../components/Header";
import { useMetaTitle } from "../../../../components/Layout";
import PlanNotice from "../../../../components/PlanNotice";
import StandardError from "../../../../components/StandardError";
import { useAppCollection } from "../../../../contexts/apps/App.context";
import { getFilteredEntities } from "../../../../utils/entities/getFilteredEntities";
import { userHasAccessToAction } from "../../../../utils/rbac_utils";
import { ActionGroups } from "../../../../utils/rbac_utils/types";
import { AppPageProps } from "../../../App/App.types";
import LoadingListView from "../../../App/components/LoadingListView";
import { appTooltips } from "../../data/appTooltips";

import { renderAppInputsList } from "./utils/renderAppInputsList";

const pageTitle = "Inputs";

const AppInputs = ({ app }: AppPageProps) => {
  const [{ id: accountId, roles }] = useUser();
  const [, setMetaTitle] = useMetaTitle();
  const { pathname } = useLocation();

  const [filterText, setFilterText] = useState("");

  const {
    additionalAppInputsLoading,
    appInputs,
    appInputsLoadError,
    appInputsNextPageToken,
    loadAppInputs,
    setAdditionalAppInputsLoading,
    setAppInputs,
  } = useAppCollection();

  // page display
  useEffect(() => {
    setMetaTitle(pageTitle);
  }, [setMetaTitle]);

  // load inputs if not in context already
  useEffect(() => {
    if (!appInputs && !appInputsLoadError) {
      loadAppInputs({
        applicationId: app.id,
        shouldPaginate: true,
      });
    }
  }, [
    app.id,
    appInputs,
    appInputsLoadError,
    appInputsNextPageToken,
    loadAppInputs,
  ]);

  if (appInputsLoadError) {
    return <StandardError errorMessage={appInputsLoadError} />;
  }

  const canUserCreateAndEditAppInputs = userHasAccessToAction(
    roles,
    ActionGroups.InputSetOperator,
    {}
  );

  const filteredAppInputs = getFilteredEntities(filterText, appInputs);
  const renderInputs = () => {
    return renderAppInputsList({
      accountId,
      app,
      appInputs: filteredAppInputs as AppInputsData,
      canUserCreateAndEditAppInputs,
    });
  };

  const handleLoadMore = (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    e.preventDefault();
    e.stopPropagation();

    setAdditionalAppInputsLoading(true);
    loadAppInputs({
      applicationId: app.id,
      nextPageToken: appInputsNextPageToken,
      shouldAppend: true,
      shouldPaginate: true,
    });
    return;
  };

  return (
    <>
      <Header
        configPageTitle={{
          label: pageTitle,
          tooltipContent: appTooltips.inputs.content,
        }}
        configActionButton={{
          label: "New input",
          url: `${pathname}/new`,
          onClick: () =>
            trackEvent("AppInputs", {
              view: "App Inputs",
              action: "Create App Input Button Clicked",
            }),
          isActionAllowed: canUserCreateAndEditAppInputs,
        }}
        configFilter={{
          inputText: filterText,
          testId: "filter-app-inputs",
          setInputText: setFilterText,
        }}
        refreshButton={{
          emptyItems: null,
          itemsLoading: !appInputs,
          loadItems: () =>
            loadAppInputs({
              applicationId: app.id,
              nextPageToken: appInputsNextPageToken,
              shouldAppend: false,
              shouldPaginate: true,
            }),
          setItems: setAppInputs,
        }}
      />

      <PlanNotice {...{ app }} type="no-custom-apps" />

      {!appInputs ? <LoadingListView /> : renderInputs()}

      {!!appInputs?.length && (
        <Footer
          app={app}
          endpoint="inputs"
          error={appInputsLoadError}
          handleMainAction={handleLoadMore}
          isActionButtonDisabled={!appInputsNextPageToken}
          isActionButtonLoading={additionalAppInputsLoading}
          view="list"
        />
      )}
    </>
  );
};

export default AppInputs;
