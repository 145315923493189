import React, { useEffect, useMemo, useState } from "react";
import { Box, Button2, Flex, Loading, Notification } from "@console/dsc";
import { useTheme } from "@emotion/react";

import {
  AcceptanceTests,
  AppResponse,
  BatchExperimentsResponse,
  ShadowTests,
  SwitchbackTestsList,
} from "../../../api/core/controlPlane.types";
import { useUser } from "../../../AuthProvider";
import {
  PAYLOAD_EXPERIMENT_TYPE_BATCH,
  PAYLOAD_EXPERIMENT_TYPE_SCENARIO,
} from "../../../config/experiments";
import useAcceptanceTests from "../../../contexts/experiments/hooks/useAcceptanceTests";
import useShadowTests from "../../../contexts/experiments/hooks/useShadowTests";
import useSwitchbackTests from "../../../contexts/experiments/hooks/useSwitchbackTests";
import useManageEntities from "../../../hooks/useManageEntities";
import { getAccUrl } from "../../../utils/navigation";
import {
  ExperimentType,
  renderExperimentsList,
} from "../../Experiments/utils/renderExperimentsList";
import { renderDashboardSectionTitle } from "../utils/renderDashboardSectionTitle";

// TODO fix types
type AppExperimentsProps = {
  app: AppResponse;
};
type CombineExperimentsParams = {
  acceptanceTests: AcceptanceTests;
  batchExperiments: BatchExperimentsResponse;
  scenarioTests: BatchExperimentsResponse;
  shadowTests: ShadowTests;
  switchbackTests: SwitchbackTestsList;
};

const addKindToExperiments = (
  experiments:
    | AcceptanceTests
    | BatchExperimentsResponse
    | ShadowTests
    | SwitchbackTestsList,
  kind: ExperimentType
) => {
  return (
    experiments &&
    experiments.map((experiment) => {
      return {
        ...experiment,
        kind,
      };
    })
  );
};
const combineExperiments = ({
  acceptanceTests,
  batchExperiments,
  scenarioTests,
  shadowTests,
  switchbackTests,
}: CombineExperimentsParams) => {
  const modifiedAcceptanceTests = addKindToExperiments(
    acceptanceTests,
    "acceptance"
  );
  const modifiedBatchExperiments = addKindToExperiments(
    batchExperiments,
    "batch"
  );
  const modifiedScenarioTests = addKindToExperiments(scenarioTests, "scenario");
  const modifiedShadowTests = addKindToExperiments(shadowTests, "shadow");
  const modifiedSwitchbackTests = addKindToExperiments(
    switchbackTests,
    "switchback"
  );

  const sortedExperiments = [
    ...(modifiedAcceptanceTests ?? []),
    ...(modifiedBatchExperiments ?? []),
    ...(modifiedScenarioTests ?? []),
    ...(modifiedShadowTests ?? []),
    ...(modifiedSwitchbackTests ?? []),
  ].sort((a, b) => {
    if (a?.updated_at && b?.updated_at) {
      return (
        new Date(b.updated_at).valueOf() - new Date(a.updated_at).valueOf()
      );
    }
    return 0;
  });

  return sortedExperiments;
};

const AppExperiments = ({ app }: AppExperimentsProps) => {
  const [{ id: accountId }] = useUser();
  const theme = useTheme();

  const [currentAppId, setCurrentAppId] = useState<string>();
  const [isPartialExperiments, setIsPartialExperiments] = useState(true);

  const {
    acceptanceTests,
    acceptanceTestsError,
    loadAcceptanceTests,
    setAcceptanceTests,
    setAcceptanceTestsError,
  } = useAcceptanceTests();

  const {
    entities: batchExperiments,
    entitiesLoadError: batchExperimentsError,
    loadEntities: loadBatchExperiments,
    setEntities: setBatchExperiments,
    setEntitiesLoadError: setBatchExperimentsError,
  } = useManageEntities("experiments/batch");

  const {
    entities: scenarioTests,
    entitiesLoadError: scenarioTestsError,
    loadEntities: loadScenarioTests,
    setEntities: setScenarioTests,
    setEntitiesLoadError: setScenarioTestsError,
  } = useManageEntities("experiments/batch");

  const {
    shadowTests,
    shadowTestsError,
    loadShadowTests,
    setShadowTests,
    setShadowTestsError,
  } = useShadowTests();

  const {
    switchbackTests: switchbackTestResponse,
    switchbackTestsError,
    loadSwitchbackTests,
    setSwitchbackTests,
    setSwitchbackTestsError,
  } = useSwitchbackTests();

  const switchbackTests = switchbackTestResponse.items;

  // data reset if app selection changes
  useEffect(() => {
    if (currentAppId !== app.id) {
      setCurrentAppId(app.id);
      setAcceptanceTests(null);
      setAcceptanceTestsError("");
      setBatchExperiments(null);
      setBatchExperimentsError(null);
      setScenarioTests(null);
      setScenarioTestsError(null);
      setShadowTests(null);
      setShadowTestsError("");
      setSwitchbackTests({ items: null, next_page_token: "" });
      setSwitchbackTestsError("");
    }
  }, [
    app.id,
    currentAppId,
    setAcceptanceTests,
    setAcceptanceTestsError,
    setBatchExperiments,
    setBatchExperimentsError,
    setScenarioTests,
    setScenarioTestsError,
    setShadowTests,
    setShadowTestsError,
    setSwitchbackTests,
    setSwitchbackTestsError,
  ]);

  // handle acceptance tests
  useEffect(() => {
    if (!acceptanceTests) {
      loadAcceptanceTests(app.id, 5);
    }
  }, [acceptanceTests, app.id, loadAcceptanceTests]);

  // handle batch experiments
  useEffect(() => {
    if (!batchExperiments) {
      loadBatchExperiments({
        applicationId: app.id,
        limit: 5,
        type: PAYLOAD_EXPERIMENT_TYPE_BATCH,
      });
    }
  }, [app.id, batchExperiments, loadBatchExperiments]);

  // handle scenario tests
  useEffect(() => {
    if (!scenarioTests) {
      loadScenarioTests({
        applicationId: app.id,
        limit: 5,
        type: PAYLOAD_EXPERIMENT_TYPE_SCENARIO,
      });
    }
  }, [app.id, loadScenarioTests, scenarioTests]);

  // handle shadow tests
  useEffect(() => {
    if (!shadowTests) {
      loadShadowTests(app.id, 5);
    }
  }, [app.id, loadShadowTests, shadowTests]);

  // handle switchback tests
  useEffect(() => {
    if (!switchbackTests) {
      loadSwitchbackTests(app.id, "", 5);
    }
  }, [app.id, loadSwitchbackTests, switchbackTests]);

  const experiments = useMemo(
    () =>
      combineExperiments({
        acceptanceTests,
        batchExperiments,
        scenarioTests,
        shadowTests,
        switchbackTests,
      }),
    [
      acceptanceTests,
      batchExperiments,
      scenarioTests,
      shadowTests,
      switchbackTests,
    ]
  );

  const renderContent = () => {
    const experimentsLoading =
      !acceptanceTests ||
      !batchExperiments ||
      !scenarioTests ||
      !shadowTests ||
      !switchbackTests;
    const experimentsError =
      acceptanceTestsError ||
      batchExperimentsError ||
      scenarioTestsError ||
      shadowTestsError ||
      switchbackTestsError;

    if (experimentsLoading) {
      return (
        <Box mt={3}>
          <Loading />
        </Box>
      );
    }
    if (experimentsError) {
      return (
        <Notification
          mt={3}
          size="small"
          message={experimentsError}
          type="error"
        />
      );
    }
    return renderExperimentsList({
      accountId,
      app,
      experiments,
      isMini: true,
      limit: isPartialExperiments ? 5 : 20,
      theme,
      urlOnClickTrackEventCategory: "Dashboard",
      urlOnClickTrackEventProperties: {
        view: "Main",
        action: "Experiment Details Link Clicked",
      },
    });
  };

  const appExperimentsUrl = getAccUrl(accountId, `/app/${app.id}/experiments`);

  return (
    <Box width="100%" mt={[7, 7, 8]}>
      {renderDashboardSectionTitle({
        title: "Recent Experiments",
        description: "View all",
        url: appExperimentsUrl,
        theme,
      })}

      {renderContent()}

      {!!experiments.length && (
        <Flex mt={2}>
          <Button2
            type="outline-quiet"
            htmlType={isPartialExperiments ? "button" : undefined}
            to={isPartialExperiments ? undefined : appExperimentsUrl}
            label={isPartialExperiments ? "More" : "Go to experiments"}
            {...(isPartialExperiments && {
              onClick: () => setIsPartialExperiments(false),
            })}
          />
        </Flex>
      )}
    </Box>
  );
};

export default AppExperiments;
