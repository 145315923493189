import { isObject } from "lodash";

import {
  CustomSeriesDataReturn,
  StatisticsSeriesData,
} from "../RunDetails.types";

export const getValueSeriesData = (seriesData: StatisticsSeriesData) => {
  // return undefined if incorrect schema
  if (
    !(isObject(seriesData) && seriesData?.value && isObject(seriesData.value))
  ) {
    return undefined;
  }
  // for typescript
  if (Array.isArray(seriesData.value)) {
    return undefined;
  }

  return {
    name: seriesData.value.name,
    data: seriesData.value.data_points.map((dp) => ({
      timeElapsed: dp.x,
      value: dp.y,
    })),
    xDataKey: "timeElapsed",
    yDataKey: "value",
  };
};

export const getCustomSeriesData = (
  seriesData: StatisticsSeriesData
): CustomSeriesDataReturn => {
  // check for valid schema
  if (!(isObject(seriesData) && seriesData?.custom)) {
    return undefined;
  }
  if (!Array.isArray(seriesData.custom)) {
    return undefined;
  }

  return seriesData.custom.map((customData) => {
    return {
      name: customData.name,
      data: customData.data_points.map((dp) => ({
        x: dp.x,
        y: dp.y,
      })),
    };
  });
};
