import styled from "@emotion/styled";

import Box from "../Box";

import { StyledProgressBarProps } from "./ProgressBar.types";

export const StyledProgressTrack = styled(Box)`
  width: 100%;
  height: ${({ theme }) => theme.spacing.s2};
  position: relative;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.spacing.s2};
  background-color: ${({ theme }) => theme.color.gray200};
`;

export const StyledProgressBar = styled(Box)<StyledProgressBarProps>`
  width: ${(props) => props.barWidth};
  height: ${({ theme }) => theme.spacing.s2};
  position: absolute;
  left: 0;
  top: 0;
  border-radius: ${({ theme }) => theme.spacing.s2};
  background-color: ${(props) => props.barColor};
  transition: width ease-out 150ms;
`;
