import React from "react";

import { useUser } from "../../AuthProvider";
import Layout from "../../components/Layout";

import { getAppsPages } from "./data/pages";
import AppsRoutes from "./Apps.routes";

const Apps = () => {
  const [{ id, features }] = useUser();
  const appPages = id && getAppsPages({ userId: id, features });

  return (
    <Layout sectionTitle="Apps" sectionPages={appPages}>
      <AppsRoutes />
    </Layout>
  );
};

export default Apps;
