/*
  NOTE:
  This is the front-end config for available add-ons.
  It is not connected programmatically to the installed add-ons
  that are stored in the backend. The naming schemes for the properties
  attempts to track with the properties in the backend for the most part.

  Available add-ons have the type: AvailableIntegration {
    // unique identifier for the available add on
    // aligns with the backend property of the same name
    type: IntegrationKind;

    // aligns with the backend property of the same name
    class: IntegrationClass;

    // optional category of certain classes
    // aligns with the backend field of the same name
    category?: IntegrationCategory;

    // add-on name
    name: string;

    // display name for the type of integration
    typeName: string;

    // used for the available add-ons preview page
    description: string;

    // optional switch to enable an "early access" tag next to the add-on
    // not currently being used, but this may change based on internal 
    // company discussions
    isEarlyAccess?: boolean;

    // denotes whether upon viewing/editing an installed integration
    // of this type, a call to GET /integration should be made to populate
    // additional data
    shouldLoadAdditionalConfig?: boolean

    // holds the information to build inputs for data to be stored 
    // in Burrow
    privateInputs?: PrivateInput[];

    // holds the information to build public inputs for data to be
    // stored in the integration.configuration object - the "type"
    // property controls what component is actually rendered
    publicInputs?: PublicInput[];
  }
*/

import { IntegrationKind } from "../../api/core/controlPlane.types";

import { AvailableIntegration } from "./Integrations.types";

export const integrationOnFleet: AvailableIntegration = {
  type: "onfleet",
  name: "Onfleet integration",
  typeName: "Onfleet",
  class: "system",
  description:
    "Add Nextmv as an optimization layer to Onfleet's dispatch and fleet management UI.",
  docsUrl: "https://www.nextmv.io/docs/legacy/cloud/integrations/onfleet",
  isEarlyAccess: false,
  shouldLoadAdditionalConfig: true,
  privateInputs: [
    {
      id: "api_key",
      isRequired: true,
      label: "API Key",
      placeholder: "Onfleet API key (encrypted end-to-end)",
      tooltipCopy: {
        content:
          "The API key is required to access Onfleet. It is stored in Nextmv's secrets manager and is encrypted end-to-end. It is never stored in plain text in any of Nextmv's data stores.",
      },
    },
  ],
  publicInputs: [
    {
      type: "onfleetFeatureCompatibility",
      id: "feature-compatibility",
      label: "Feature Compatibility",
      secondaryLabel:
        "Parse compatible Onfleet features to Nextmv functionality",
      tooltipCopy:
        "The compatibility feature allows you to specify which Onfleet task and worker attributes are parsed to Nextmv features. Any attribute that is not explicitly defined as false will be automatically parsed. All compatible features are parsed by default. ",
    },
  ],
};

export const integrationHere: AvailableIntegration = {
  type: "here",
  name: "HERE integration",
  typeName: "HERE Technologies",
  class: "extension",
  category: "measure",
  isEarlyAccess: true,
  description:
    "Use road and traffic data from HERE in your Nextmv optimization runs.",
  docsUrl: "https://www.nextmv.io/docs/legacy/cloud/integrations/here",
  privateInputs: [
    {
      id: "api_key",
      isRequired: true,
      label: "API Key",
      placeholder: "HERE API key (encrypted end-to-end)",
      tooltipCopy: {
        content:
          "The API key is required to access HERE. It is stored in Nextmv's secrets manager and is encrypted end-to-end. It is never stored in plain text in any of Nextmv's data stores.",
      },
    },
  ],
};

export const integrationStreets: AvailableIntegration = {
  type: "streets",
  name: "Streets integration",
  typeName: "Streets",
  class: "extension",
  category: "measure",
  isEarlyAccess: true,
  description:
    "Use real-road network data in your Nextmv optimization runs. No API key needed.",
  docsUrl: "https://www.nextmv.io/docs/legacy/cloud/integrations/streets",
};

export const availableIntegrations = [
  integrationOnFleet,
  integrationHere,
  integrationStreets,
];

export const availableIntegrationConfig: {
  [key in IntegrationKind]: AvailableIntegration;
} = {
  here: integrationHere,
  onfleet: integrationOnFleet,
  streets: integrationStreets,
};
