import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { getSubscription } from "../../api/payment/controlPlane";
import { Subscription } from "../../api/payment/controlPlane.types";

import useCreateSubscription from "./hooks/useCreateSubscription";
import {
  AnyFunction,
  ReceiptData,
  SubscriptionAction,
  SubscriptionActionKind,
  SubscriptionContextProps,
  SubscriptionState,
} from "./Subscription.context.types";
import { isPaymentIncomplete } from "./utils";

const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY || "";
const stripePromise = loadStripe(stripePublicKey);

export const SubscriptionContext =
  React.createContext<SubscriptionContextProps>({
    subscription: undefined,
    setSubscription: () => {},
    fetchSubscription: () => {},
    subscriptionError: "",
    setSubscriptionError: (error) => null,
    subscriptionLoading: true,
    setSubscriptionLoading: (loading) => null,
    pendingSetupIntentSecret: "",
    setPendingSetupIntentSecret: (secret) => null,
    receiptData: undefined,
    setReceiptData: (data) => null,
    createSubscription: () => null,
  });

const subscriptionReducer = (
  state: SubscriptionState,
  action: SubscriptionAction
): SubscriptionState => {
  switch (action.type) {
    case SubscriptionActionKind.UPDATE:
      return {
        ...state,
        subscription: action.payload,
      };
    case SubscriptionActionKind.CREATE:
      return {
        loading: false,
        error: "",
        subscription: action.payload,
      };
    case SubscriptionActionKind.SET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SubscriptionActionKind.LOADING:
      return {
        ...state,
        loading: action.payload,
        error: "",
      };
    default:
      throw new Error("subscription action not supported");
  }
};

const SubscriptionProvider = ({ children }: { children: React.ReactNode }) => {
  const [{ error, loading, subscription }, subscriptionDispatch] =
    React.useReducer(subscriptionReducer, {
      error: "",
      subscription: undefined,
      loading: false,
    });

  const setSubscription = React.useCallback((subscription: Subscription) => {
    subscriptionDispatch({
      type: SubscriptionActionKind.UPDATE,
      payload: subscription,
    });
  }, []);

  const setError = React.useCallback((error: string) => {
    subscriptionDispatch({
      type: SubscriptionActionKind.SET_ERROR,
      payload: error,
    });
  }, []);

  const setSubscriptionLoading = React.useCallback((loading: boolean) => {
    subscriptionDispatch({
      type: SubscriptionActionKind.LOADING,
      payload: loading,
    });
  }, []);

  const [pendingSetupIntentSecret, setPendingSetupIntentSecret] =
    React.useState("");
  const [paymentIntentSecret, setPaymentIntentSecret] = React.useState("");
  const [receiptData, setReceiptData] = React.useState<ReceiptData>();
  const createSubscription = useCreateSubscription({
    paymentIsIncomplete: isPaymentIncomplete(
      subscription,
      pendingSetupIntentSecret
    ),
    pendingSetupIntentSecret,
    setPendingSetupIntentSecret,
    setProcessing: setSubscriptionLoading,
    setReceiptData,
    setSubscriptionError: setError,
    subscriptionDispatch,
    paymentIntentSecret,
    setPaymentIntentSecret,
  });

  const wrapFetch = <Func extends AnyFunction>(
    fn: Func,
    action: SubscriptionActionKind
  ): ((...args: Parameters<Func>) => void) => {
    const wrappedFn = async (...args: Parameters<Func>) => {
      subscriptionDispatch({
        type: SubscriptionActionKind.LOADING,
        payload: true,
      });
      try {
        const resJson = await fn(...args);
        subscriptionDispatch({
          type: action,
          payload: resJson,
        });
        subscriptionDispatch({
          type: SubscriptionActionKind.LOADING,
          payload: false,
        });
      } catch (e: any) {
        subscriptionDispatch({
          type: SubscriptionActionKind.SET_ERROR,
          payload: e.message,
        });
      }
    };
    return wrappedFn;
  };

  const fetchSubscription = React.useMemo(
    () => wrapFetch(getSubscription, SubscriptionActionKind.UPDATE),
    []
  );

  const value: SubscriptionContextProps = {
    subscription,
    setSubscription,
    fetchSubscription,
    subscriptionError: error,
    setSubscriptionError: setError,
    subscriptionLoading: loading,
    setSubscriptionLoading,
    pendingSetupIntentSecret,
    setPendingSetupIntentSecret,
    receiptData,
    setReceiptData,
    createSubscription,
  };

  return (
    <SubscriptionContext.Provider value={value}>
      {children}
    </SubscriptionContext.Provider>
  );
};

export const useSubscription = () => React.useContext(SubscriptionContext);

const SubscriptionProviderWithElements = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <Elements stripe={stripePromise}>
      <SubscriptionProvider>{children}</SubscriptionProvider>
    </Elements>
  );
};

export default SubscriptionProviderWithElements;
