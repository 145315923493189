import * as React from "react";

import BaseIcon, { IconProps } from "./BaseIcon";

const IconMainNavDocs = ({
  iconColor,
  iconColorActive,
  iconColorFocus,
  iconColorHover,
  ...rest
}: IconProps) => {
  return (
    <BaseIcon
      {...{
        iconColor,
        iconColorActive,
        iconColorFocus,
        iconColorHover,
      }}
      iconSize={42}
      viewBox={[0, 0, 42, 42]}
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1683 11.6C13.1683 11.5089 13.2422 11.435 13.3333 11.435H24.0524C24.0962 11.435 24.1381 11.4524 24.1691 11.4833L28.7833 16.0976C28.8142 16.1285 28.8316 16.1705 28.8316 16.2142V30.4C28.8316 30.4911 28.7577 30.565 28.6666 30.565H13.3333C13.2422 30.565 13.1683 30.4911 13.1683 30.4V11.6ZM13.3333 9.76501C12.3198 9.76501 11.4983 10.5866 11.4983 11.6V30.4C11.4983 31.4135 12.3198 32.235 13.3333 32.235H28.6666C29.6801 32.235 30.5016 31.4135 30.5016 30.4V16.2142C30.5016 15.7276 30.3083 15.2608 29.9642 14.9167L25.35 10.3025C25.0058 9.95834 24.5391 9.76501 24.0524 9.76501H13.3333ZM15.8 15.05H15.05V16.55H15.8H21H21.75V15.05H21H15.8ZM15.8 20.25H15.05V21.75H15.8H26.2H26.95V20.25H26.2H15.8ZM15.8 25.45H15.05V26.95H15.8H26.2H26.95V25.45H26.2H15.8Z"
      />
    </BaseIcon>
  );
};

export default IconMainNavDocs;
