import React, { useCallback, useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { AvatarAppInput, Box, Input, RowDetail } from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";

import { trackEvent } from "../../../../analytics";
import { CreateAppInputPayload } from "../../../../api/core/controlPlane.types";
import Footer from "../../../../components/Footer";
import Header from "../../../../components/Header";
import { useMetaTitle } from "../../../../components/Layout";
import UploadFile from "../../../../components/UploadFile";
import useFileIntercept from "../../../../components/UploadFile/hooks/useFileIntercept";
import { AcceptedFiles } from "../../../../components/UploadFile/UploadFile.types";
import { getLocalDirectory } from "../../../../components/UploadFile/utils/getLocalDirectory";
import { useAppCollection } from "../../../../contexts/apps/App.context";
import useRunDetails from "../../../../contexts/apps/hooks/useRunDetails";
import useManageEntity from "../../../../hooks/useManageEntity";
import useStandardInputs from "../../../../hooks/useStandardInputs";
import { appTooltipCopy } from "../../../Apps/data/microcopy";
import { AppPageProps } from "../../App.types";
import useReturnPaths from "../../hooks/useReturnPaths";

const pageTitle = "New input";

const NewAppInput = ({ app }: AppPageProps) => {
  const [, setMetaTitle] = useMetaTitle();
  const { pathname } = useLocation();
  const { returnPath, returnPathList } = useReturnPaths();
  const localDirectory = getLocalDirectory(pathname);

  const [acceptedFiles, setAcceptedFiles] = useState<AcceptedFiles>(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const { loadAppInputs } = useAppCollection();
  const { createTemporaryRunUploadMetadata } = useRunDetails();

  const {
    addEntity: addAppInput,
    isEntityAdded: isAppInputAdded,
    entityAddError: appInputAddError,
  } = useManageEntity("inputs");

  const {
    getStandardInputsProps,
    pendingStandardInputs,
    standardInputsErrors,
  } = useStandardInputs(app, "inputs");

  const {
    clearWebWorkersAndLocalOPFS,
    isFileUploadProcessRunning,
    isPrecheckRunning,
    fileInterceptError,
    fileUploadMetadata,
    setIsFileUploadProcessRunning,
    setIsPrecheckRunning,
    uploadFilesViaWebWorker,
  } = useFileIntercept({
    app,
  });

  // page display
  useEffect(() => {
    setMetaTitle(pageTitle);
  }, [setMetaTitle]);

  // clear OPFS data and close web workers on exit
  useEffect(() => {
    return () => {
      clearWebWorkersAndLocalOPFS(localDirectory);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAppInputCreate = useCallback(async () => {
    const payloadFormat = {
      format: {
        input: {
          type: fileUploadMetadata ? fileUploadMetadata.contentType : "json",
        },
      },
    };

    let payload: CreateAppInputPayload = {
      ...pendingStandardInputs,
      ...payloadFormat,
    };

    payload["upload_id"] = fileUploadMetadata?.uploadId;

    await addAppInput(app.id, payload);
  }, [addAppInput, app.id, fileUploadMetadata, pendingStandardInputs]);

  const startFileUploadProcess = useCallback(async () => {
    setIsFileUploadProcessRunning(true);

    // create upload ID & URL to pass to web worker
    const inputUploadMetadata = await createTemporaryRunUploadMetadata(app.id);

    if (inputUploadMetadata.upload_url && inputUploadMetadata.upload_id) {
      // upload files in web worker
      await uploadFilesViaWebWorker(
        acceptedFiles,
        inputUploadMetadata,
        localDirectory
      );
    }

    return;
  }, [
    acceptedFiles,
    app.id,
    createTemporaryRunUploadMetadata,
    localDirectory,
    setIsFileUploadProcessRunning,
    uploadFilesViaWebWorker,
  ]);

  useEffect(() => {
    if (fileUploadMetadata && isFileUploadProcessRunning) {
      setIsFileUploadProcessRunning(false);
    }
  }, [
    fileUploadMetadata,
    isFileUploadProcessRunning,
    setIsFileUploadProcessRunning,
  ]);

  useEffect(() => {
    if (isPrecheckRunning) {
      if (acceptedFiles && acceptedFiles.length) {
        if (!fileUploadMetadata && !isFileUploadProcessRunning) {
          startFileUploadProcess();
          return;
        }
        if (fileUploadMetadata) {
          // files are uploaded, ready to start run
          setIsPrecheckRunning(false);
          handleAppInputCreate();
          return;
        }

        return;
      } else {
        setIsPrecheckRunning(false);
        handleAppInputCreate();
        return;
      }
    }
  }, [
    acceptedFiles,
    fileUploadMetadata,
    handleAppInputCreate,
    isFileUploadProcessRunning,
    isPrecheckRunning,
    setIsPrecheckRunning,
    startFileUploadProcess,
  ]);

  const handlePreUpload = async (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    e.preventDefault();
    e.stopPropagation();

    setIsProcessing(true);
    setIsPrecheckRunning(true);

    return;
  };

  const handleCancel = () => {
    trackEvent("AppInputs", {
      view: "Create App Input",
      action: "Create App Input Canceled",
    });
    return;
  };

  if (isAppInputAdded && !fileInterceptError) {
    trackEvent("AppInputs", {
      view: "Create App Input",
      action: "New App Input Created",
    });

    loadAppInputs({ applicationId: app.id });
    return <Redirect to={returnPathList} />;
  }

  const isActionButtonDisabled =
    !pendingStandardInputs.name ||
    !pendingStandardInputs.id ||
    !!standardInputsErrors.name ||
    !!standardInputsErrors.id;

  return (
    <>
      <Header
        configPageTitle={{
          label: pageTitle,
          ancestorIcon: <AvatarAppInput />,
          ancestorLabel: "Inputs",
          ancestorUrl: returnPathList,
        }}
      />

      <form>
        <RowDetail
          hasNoBorder
          property="Name"
          secondaryLabel="For reference only"
          render={
            <Box width="100%" maxWidth={rem(408)}>
              <Input
                {...getStandardInputsProps({
                  placeholder: "Input name",
                  testId: "new-app-input-name-input",
                  type: "name",
                  trackEventCategory: "AppInputs",
                  trackEventProperties: {
                    view: "Create App Input",
                    action: "Field Entered",
                    meta: {
                      field: "name",
                    },
                  },
                })}
              />
            </Box>
          }
        />

        <RowDetail
          property="ID"
          tooltipCopy={appTooltipCopy.appInputIdCreate}
          render={
            <Box width="100%" maxWidth={rem(408)}>
              <Input
                {...getStandardInputsProps({
                  placeholder: "Input ID",
                  testId: "new-app-input-id-input",
                  type: "id",
                  trackEventCategory: "AppInputs",
                  trackEventProperties: {
                    view: "Create App Input",
                    action: "App Input ID Changed",
                  },
                })}
              />
            </Box>
          }
        />

        <RowDetail
          property="Description"
          secondaryLabel="(optional)"
          render={
            <Box width="100%" maxWidth={rem(408)}>
              <Input
                {...getStandardInputsProps({
                  placeholder: "Input description",
                  testId: "new-app-input-description-input",
                  type: "description",
                  trackEventCategory: "AppInputs",
                  trackEventProperties: {
                    view: "Create App Input",
                    action: "Field Entered",
                    meta: {
                      field: "description",
                    },
                  },
                })}
              />
            </Box>
          }
        />

        <RowDetail
          property="Upload files"
          tooltipCopy={appTooltipCopy.newAppInputFileUpload}
          render={
            <Box width="100%" maxWidth={rem(408)}>
              <UploadFile
                {...{ acceptedFiles, app, setAcceptedFiles }}
                isMultiple
                maxFiles={50}
              />
            </Box>
          }
        />

        <Footer
          actionButtonLabel="Create input"
          app={app}
          endpoint="inputs"
          error={appInputAddError || fileInterceptError}
          handleCancel={handleCancel}
          handleMainAction={handlePreUpload}
          isActionButtonLoading={isProcessing}
          isActionButtonDisabled={isActionButtonDisabled}
          returnPath={returnPath}
          returnPathList={returnPathList}
          view="create"
        />
      </form>
    </>
  );
};

export default NewAppInput;
