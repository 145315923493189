import { AppType, BatchExperiment } from "../../../api/core/controlPlane.types";

export const getResolvedVersionId = (
  appType: AppType | undefined,
  instanceId: string,
  versionId: string,
  batchExperiment: BatchExperiment
) => {
  if (appType !== "subscription") {
    return versionId;
  }

  if (!batchExperiment || !batchExperiment.grouped_distributional_summaries) {
    return undefined;
  }

  const summaries = batchExperiment.grouped_distributional_summaries;
  for (let i = 0; i < summaries.length; i++) {
    const groupValues = summaries[i].group_values;
    if (groupValues.length === 2 && groupValues[0] === instanceId) {
      return groupValues[1];
    }
  }

  return undefined;
};
