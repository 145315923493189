import { RouteSet, VisibleVehicleRouteIds } from "../../../Map.types";

export const filterRoutesByDisplayAndFocus = (
  routes: RouteSet[],
  focusedRouteId: string,
  visibleVehicleRoutesById: VisibleVehicleRouteIds
) => {
  return routes?.filter((r) =>
    // if there is a focused route, it renders directly on top of this array,
    // so we don't want to render it a second time here
    focusedRouteId
      ? r.id !== focusedRouteId && visibleVehicleRoutesById[r.id]
      : visibleVehicleRoutesById[r.id]
  );
};
