import { FileRejection } from "react-dropzone";

const fixCommas = (text: string): string => {
  return text.replace(/,/g, ", ");
};

export const getRejectedFilesErrorMessage = (
  rejectedFiles: FileRejection[],
  isMultiple?: boolean
): string => {
  if (!isMultiple && rejectedFiles.length > 1) {
    return "Multiple files are not allowed.";
  }
  return rejectedFiles
    .map((rejectedFile) => {
      const rejectedFileName = rejectedFile.file.name;
      const rejectedFileErrors = rejectedFile.errors
        .map((rejectedFileError) => {
          return `(${rejectedFileError.code}): ${fixCommas(
            rejectedFileError.message
          )}`;
        })
        .join(" ");

      return `${rejectedFileName} ${rejectedFileErrors}`;
    })
    .join(" • ");
};
