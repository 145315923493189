import * as React from "react";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";

import { svgIconStyle } from "../lib/constants";

type StyledSvgProps = {
  activeColor?: string;
  focusColor?: string;
  hoverColor?: string;
};
const StyledSvg = styled.svg<StyledSvgProps>`
  ${(props) =>
    svgIconStyle({
      activeColor: props.activeColor,
      focusColor: props.focusColor,
      hoverColor: props.hoverColor,
    })}
`;

const IconValueFuncEarliness = ({
  activeColor,
  focusColor,
  hoverColor,
  iconColor,
  iconSize,
}: {
  activeColor?: string;
  focusColor?: string;
  hoverColor?: string;
  iconColor?: string;
  iconSize?: number;
}) => {
  const theme = useTheme();
  const thisIconSize = iconSize || 16;
  return (
    <StyledSvg
      {...{ activeColor, focusColor, hoverColor }}
      width={thisIconSize}
      height={thisIconSize}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 12H1V11H15V12Z"
        fill={iconColor || theme.color.gray700}
      />
      <path
        d="M13.6586 10C13.8797 9.37444 14 8.70127 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 8.70127 2.12031 9.37444 2.34141 10H13.6586Z"
        fill={iconColor || theme.color.gray700}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 14H4V13H12V14Z"
        fill={iconColor || theme.color.gray700}
        fillOpacity="0.73"
      />
    </StyledSvg>
  );
};

export default IconValueFuncEarliness;
