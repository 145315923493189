import { useCallback, useState } from "react";

import { getMarketplaceVersions } from "../../../api/core/controlPlane";
import {
  EntityErrorMessage,
  MarketplaceAppVersions,
} from "../../../api/core/controlPlane.types";
import { useUser } from "../../../AuthProvider";
import {
  getMarketplaceAppId,
  getMarketplacePartnerId,
} from "../utils/subscriptionId";

const useMarketplaceVersions = () => {
  const [{ id: accountId }] = useUser();

  const [marketplaceVersions, setMarketplaceVersions] =
    useState<MarketplaceAppVersions>(null);
  const [marketplaceVersionsError, setMarketplaceVersionsError] =
    useState<EntityErrorMessage>(null);

  const loadMarketplaceVersions = useCallback(
    async (subscriptionId: string) => {
      const partnerId = getMarketplacePartnerId(subscriptionId);
      const applicationId = getMarketplaceAppId(subscriptionId);

      try {
        const marketplaceVersionsRes = await getMarketplaceVersions(
          accountId || ""
        )(partnerId, applicationId);
        const marketplaceVersions =
          marketplaceVersionsRes as MarketplaceAppVersions;

        setMarketplaceVersions(marketplaceVersions);
        setMarketplaceVersionsError(null);
        return marketplaceVersions;
      } catch (e: any) {
        console.error(e);
        setMarketplaceVersionsError(e.toString());
      }
    },
    [accountId]
  );

  return {
    loadMarketplaceVersions,
    marketplaceVersions,
    marketplaceVersionsError,
  };
};

export default useMarketplaceVersions;
