import * as React from "react";

import BaseIcon, { IconProps } from "./BaseIcon";

const IconWarning = ({
  iconSize,
  iconColor,
  iconColorActive,
  iconColorFocus,
  iconColorHover,
  ...rest
}: IconProps) => {
  return (
    <BaseIcon
      {...{
        iconSize,
        iconColor,
        iconColorActive,
        iconColorFocus,
        iconColorHover,
      }}
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.08662 1.77428C8.59602 0.951133 7.40393 0.951135 6.91333 1.77428L0.344424 12.7959C-0.158129 13.6392 0.44945 14.7086 1.43107 14.7086H14.5689C15.5505 14.7086 16.1581 13.6392 15.6555 12.7959L9.08662 1.77428ZM1.54547 13.3786L7.99997 2.54888L14.4545 13.3786H1.54547ZM7.1725 5.77199C7.15444 5.3025 7.53017 4.91205 8 4.91205C8.46984 4.91205 8.84556 5.3025 8.82751 5.77199L8.67259 9.79974C8.65869 10.1611 8.36167 10.447 8 10.447C7.63833 10.447 7.34131 10.1611 7.32741 9.79974L7.1725 5.77199ZM7.09485 11.7963C7.09485 11.2965 7.50005 10.8913 7.99988 10.8913C8.49972 10.8913 8.90491 11.2965 8.90491 11.7963C8.90491 12.2962 8.49972 12.7014 7.99988 12.7014C7.50005 12.7014 7.09485 12.2962 7.09485 11.7963Z"
      />
    </BaseIcon>
  );
};

export default IconWarning;
