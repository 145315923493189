import * as React from "react";

import BaseIcon, { IconProps } from "./BaseIcon";

const IconMainNavApps = ({
  iconColor,
  iconColorActive,
  iconColorFocus,
  iconColorHover,
  ...rest
}: IconProps) => {
  return (
    <BaseIcon
      {...{
        iconColor,
        iconColorActive,
        iconColorFocus,
        iconColorHover,
      }}
      iconSize={42}
      viewBox={[0, 0, 42, 42]}
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.6828 9.90374C20.8839 9.80992 21.1161 9.80992 21.3172 9.90374L31.3172 14.5704C31.5812 14.6936 31.75 14.9586 31.75 15.25V26.75C31.75 27.0414 31.5812 27.3065 31.3172 27.4297L21.3172 32.0963C21.1161 32.1902 20.8839 32.1902 20.6828 32.0963L10.6828 27.4297C10.4188 27.3065 10.25 27.0414 10.25 26.75V15.25C10.25 14.9586 10.4188 14.6936 10.6828 14.5704L20.6828 9.90374ZM11.75 16.3837L20.25 19.9962V30.2391L11.75 26.2724V16.3837ZM21.75 30.2391L30.25 26.2724V16.3837L21.75 19.9962V30.2391ZM21 18.6851L29.1579 15.218L21 11.411L12.8421 15.218L21 18.6851Z"
      />
    </BaseIcon>
  );
};

export default IconMainNavApps;
