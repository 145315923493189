// generic checks
const hasId = (entity: { [key: string]: any }): boolean => {
  return typeof entity?.id === "string";
};
const hasRouteArray = (vehicle: any): boolean => {
  return Array.isArray(vehicle?.route);
};

// stop/location checks
const hasRouterPosition = (entity: any): boolean => {
  const hasLon: boolean = typeof entity?.position?.lon === "number";
  const hasLat: boolean = typeof entity?.position?.lat === "number";
  return hasLon && hasLat;
};
const hasRoutingLegacyPosition = (entity: any): boolean => {
  const hasLon: boolean = typeof entity?.lon === "number";
  const hasLat: boolean = typeof entity?.lat === "number";
  return hasLon && hasLat;
};
const hasNextrouteLocation = (entity: any): boolean => {
  const hasLon: boolean = typeof entity?.location?.lon === "number";
  const hasLat: boolean = typeof entity?.location?.lat === "number";
  return hasLon && hasLat;
};
const hasValidStop = (routePart: any): boolean => {
  const hasStopId = hasId(routePart?.stop);
  const hasLonLat = hasNextrouteLocation(routePart?.stop);
  return hasStopId && hasLonLat;
};

const hasCustomerHSDRoute = (vehicle: any): boolean => {
  return Array.isArray(vehicle?.route) || vehicle?.route === null;
};

// route part checks
const hasValidRouterRouteParts = (route: any[]): boolean => {
  for (const routePart of route) {
    if (!hasId(routePart) || !hasRouterPosition(routePart)) {
      return false;
    }
  }
  return true;
};
const hasValidCustomerHSDRouteParts = (route: any[]): boolean => {
  // HSD includes vehicles in solution with no route
  if (Array.isArray(route)) {
    for (const routePart of route) {
      if (!hasValidStop(routePart)) {
        return false;
      }
    }
  }
  return true;
};
const hasValidLegacyRoutingRouteParts = (route: any[]): boolean => {
  for (const routePart of route) {
    if (!hasId(routePart) || !hasRoutingLegacyPosition(routePart)) {
      return false;
    }
  }
  return true;
};
const hasValidNextrouteRouteParts = (route: any[]): boolean => {
  for (const routePart of route) {
    // stop includes ID and location
    if (!hasValidStop(routePart)) {
      return false;
    }
  }
  return true;
};

// vehicle checks
const isValidRouterVehicles = (vehicles: any): boolean => {
  if (!Array.isArray(vehicles)) return false;
  if (!(vehicles.length > 0)) return false;

  for (const vehicle of vehicles) {
    if (
      !hasId(vehicle) ||
      !hasRouteArray(vehicle) ||
      !hasValidRouterRouteParts(vehicle.route)
    ) {
      return false;
    }
  }
  return true;
};
const isValidCustomerHSDVehicles = (vehicles: any): boolean => {
  if (!Array.isArray(vehicles)) return false;
  if (!(vehicles.length > 0)) return false;
  for (const vehicle of vehicles) {
    if (
      !hasId(vehicle) ||
      !hasCustomerHSDRoute(vehicle) ||
      !hasValidCustomerHSDRouteParts(vehicle.route)
    ) {
      return false;
    }
  }
  return true;
};
const isValidRoutingLegacyVehicles = (vehicles: any): boolean => {
  if (!Array.isArray(vehicles)) return false;
  if (!(vehicles.length > 0)) return false;

  for (const vehicle of vehicles) {
    if (
      !hasId(vehicle) ||
      !hasRouteArray(vehicle) ||
      // will this get hit if vehicle.route doesn't exist?
      !hasValidLegacyRoutingRouteParts(vehicle.route)
    ) {
      return false;
    }
  }
  return true;
};
const isValidNextrouteVehicles = (vehicles: any): boolean => {
  if (!Array.isArray(vehicles)) return false;
  if (!(vehicles.length > 0)) return false;

  for (const vehicle of vehicles) {
    if (
      !hasId(vehicle) ||
      !hasRouteArray(vehicle) ||
      !hasValidNextrouteRouteParts(vehicle.route)
    ) {
      return false;
    }
  }
  return true;
};

// unplanned/unassigned checks
const isValidNextrouteUnplanned = (unplanned: any): boolean => {
  if (!Array.isArray(unplanned)) return false;

  for (const unp of unplanned) {
    if (!hasId(unp) || !hasNextrouteLocation(unp)) {
      return false;
    }
  }
  return true;
};
const isValidRouterUnassigned = (unassigned: any): boolean => {
  if (!Array.isArray(unassigned)) return false;

  for (const una of unassigned) {
    if (!hasId(una) || !hasRouterPosition(una)) {
      return false;
    }
  }
  return true;
};
const isValidRoutingLegacyUnassigned = (unassigned: any): boolean => {
  if (!Array.isArray(unassigned)) return false;

  for (const una of unassigned) {
    if (!hasId(una) || !hasRoutingLegacyPosition(una)) {
      return false;
    }
  }
  return true;
};

// output checks
export const isValidRouterOutput = (solutionDetails: {
  [key: string]: any;
}): boolean => {
  if (!solutionDetails?.unassigned || !solutionDetails?.vehicles) {
    return false;
  }

  if (!isValidRouterVehicles(solutionDetails.vehicles)) {
    return false;
  }

  if (!isValidRouterUnassigned(solutionDetails.unassigned)) {
    return false;
  }

  return true;
};
export const isValidCustomerHSDOutput = (solutionDetails: {
  [key: string]: any;
}): boolean => {
  if (!solutionDetails?.unassigned || !solutionDetails?.vehicles) {
    return false;
  }

  if (!isValidCustomerHSDVehicles(solutionDetails.vehicles)) {
    return false;
  }

  if (!isValidRouterUnassigned(solutionDetails.unassigned)) {
    return false;
  }

  return true;
};
export const isValidRoutingLegacyOutput = (solutionDetails: {
  [key: string]: any;
}): boolean => {
  if (!solutionDetails?.unassigned || !solutionDetails?.vehicles) {
    return false;
  }

  if (!isValidRoutingLegacyVehicles(solutionDetails.vehicles)) {
    return false;
  }

  if (!isValidRoutingLegacyUnassigned(solutionDetails.unassigned)) {
    return false;
  }

  return true;
};
export const isValidNextrouteOutput = (solutionDetails: {
  [key: string]: any;
}): boolean => {
  if (!isValidNextrouteVehicles(solutionDetails.vehicles)) {
    return false;
  }

  if (!isValidNextrouteUnplanned(solutionDetails.unplanned)) {
    return false;
  }

  return true;
};
