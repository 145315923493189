import { useState } from "react";
import { useHistory } from "react-router-dom";

import { trackEvent } from "../analytics";
import { leaveOrg } from "../api/core/controlPlane";
import { EntityErrorMessage } from "../api/core/controlPlane.types";
import { useFetchAndUpdateUserAndOrgs } from "../api/core/dataPlane.hooks";
import { UserActionKind, useUser } from "../AuthProvider";

const useTeam = () => {
  const [{ organizations }, userDispatch] = useUser();
  const history = useHistory();
  const fetchAndUpdateUserAndOrgs = useFetchAndUpdateUserAndOrgs();

  const [isLeaveTeamProcessing, setIsLeaveTeamProcessing] =
    useState<boolean>(false);
  const [leaveTeamError, setLeaveTeamError] =
    useState<EntityErrorMessage>(null);

  const leaveTeam = async (teamId: string, rootOrgId: string) => {
    setIsLeaveTeamProcessing(true);
    setLeaveTeamError(null);

    try {
      const refreshUserAfterLeave =
        (organizations || []).length <= 1 || !rootOrgId;
      await leaveOrg(teamId);

      if (refreshUserAfterLeave) {
        await fetchAndUpdateUserAndOrgs();
      } else {
        userDispatch({
          type: UserActionKind.UPDATE_ORGS,
          payload: (organizations || []).filter((org) => org.id !== teamId),
        });
      }

      trackEvent("Team", {
        view: "Details",
        action: "Left Team",
      });

      // This is a special case where re-rendering the page will result in an
      // error, so we force a redirect.
      if (rootOrgId) {
        history.push(`/acc/${rootOrgId}/dashboard`);
      } else {
        history.push(`/`);
      }
    } catch (e) {
      console.error(e);
      setLeaveTeamError((e as Error).message);
      setIsLeaveTeamProcessing(false);
    }

    return;
  };

  return {
    isLeaveTeamProcessing,
    leaveTeam,
    leaveTeamError,
  };
};

export default useTeam;
