import React, { ReactElement } from "react";
import { Redirect } from "react-router-dom";

import { ParsedFeatureFlags } from "../api/core/dataPlane.types";
import AppProvider from "../contexts/apps/App.context";
import PlansProvider from "../contexts/plans/Plans.context";
import SubscriptionProvider from "../contexts/subscription/Subscription.context";
import Account from "../pages/Account";
import APIExplorer from "../pages/API";
import App from "../pages/App";
import Apps from "../pages/Apps";
import Config from "../pages/Config";
import CustomPlan from "../pages/CustomPlan";
import Dashboard from "../pages/Dashboard";
import Install from "../pages/Install";
import Invites from "../pages/Invites";
import Marketplace from "../pages/Marketplace";
import MarketplaceApp from "../pages/MarketplaceApp";
import Team from "../pages/Team";

type RouteDirectives = {
  Component?: ReactElement;
  exact?: boolean;
  redirect?: string;
  providers?: any[];
  flags?: string[];
  noOrgInRoute?: boolean;
  renderFunc?: (id: string) => (props: any) => JSX.Element;
};

export type AuthenticatedRoutes = {
  [key: string]: RouteDirectives;
};

export const getAuthenticatedRoutes = (
  userFeatures: ParsedFeatureFlags | undefined,
  userHasApps: boolean,
  accountId: string
): AuthenticatedRoutes => {
  return {
    "/dashboard": {
      providers: [SubscriptionProvider],
      Component: <Dashboard />,
    },
    "/install": {
      Component: <Install />,
    },
    "/quick-starts/:id": {
      exact: true,
      renderFunc: (id) => (props) =>
        <Redirect to={`/install/?marketplaceAppId=${props.match.params.id}`} />,
    },
    "/quick-starts": {
      exact: true,
      redirect: "/install",
    },
    "/template/:id": {
      exact: true,
      renderFunc: (id) => (props) =>
        <Redirect to={`/install/?marketplaceAppId=${props.match.params.id}`} />,
    },
    "/apps": {
      Component: <Apps />,
    },
    "/app/:id": {
      providers: [AppProvider],
      Component: <App />,
    },
    "/app": {
      exact: true,
      redirect: "/apps",
    },
    "/config": {
      Component: <Config />,
    },
    "/account/api-keys": {
      exact: true,
      redirect: "/team/api-keys",
    },
    "/account": {
      providers: [SubscriptionProvider],
      Component: <Account />,
    },
    "/marketplace/app/:partner/:id": {
      Component: <MarketplaceApp />,
    },
    "/marketplace": {
      Component: <Marketplace />,
    },
    "/team": {
      providers: [SubscriptionProvider],
      Component: <Team />,
    },
    "/invites": {
      Component: <Invites />,
      noOrgInRoute: true,
    },
    // redirect to Nextroute demo app
    "/demo": {
      exact: true,
      redirect: "/marketplace/app/nextmv/nextroute/demo",
    },
    "/demo/routing": {
      exact: true,
      redirect: "/marketplace/app/nextmv/nextroute/demo",
    },
    "/demo/routing/general": {
      exact: true,
      redirect: "/marketplace/app/nextmv/nextroute/demo",
    },
    "/demo/routing/delivery": {
      exact: true,
      redirect: "/marketplace/app/nextmv/nextroute/demo",
    },
    "/demo/routing/distribution": {
      exact: true,
      redirect: "/marketplace/app/nextmv/nextroute/demo",
    },
    "/demo/routing/sourcing": {
      exact: true,
      redirect: "/marketplace/app/nextmv/nextroute/demo",
    },
    // end Nextroute demo app redirects
    "/connect/plan/:priceId": {
      providers: [SubscriptionProvider, PlansProvider],
      exact: true,
      Component: <CustomPlan />,
    },
    "/api-explorer": {
      Component: <APIExplorer />,
    },
    "/apps/mine": {
      exact: true,
      redirect: "apps",
    },
    "/billing": {
      redirect: "/account/profile",
    },
    "/cli": {
      redirect: "/dashboard",
    },
    "/integrations/available": {
      exact: true,
      redirect: "/config/integrations/nextmv-routing",
    },
    "/integrations/installed/:id/edit": {
      exact: true,
      renderFunc: (id) => (props) =>
        (
          <Redirect
            to={`${id}/config/integrations/installed/${props.match.params.id}/edit`}
          />
        ),
    },
    "/integrations/installed/:id": {
      exact: true,
      renderFunc: (id) => (props) =>
        (
          <Redirect
            to={`/config/integrations/installed/${props.match.params.id}`}
          />
        ),
    },
    "/integrations/installed": {
      exact: true,
      redirect: "/config/integrations/installed",
    },
    "/integrations/:integrationType/new": {
      exact: true,
      renderFunc: (id) => (props) =>
        (
          <Redirect
            to={`/config/integrations/nextmv-routing/${props.match.params.integrationType}/new`}
          />
        ),
    },
    "/integrations/:integrationType": {
      exact: true,
      redirect: "/config/integrations/installed",
    },
    "/integrations": {
      exact: true,
      redirect: "/config/integrations/installed",
    },
    "/addons/available": {
      exact: true,
      redirect: "/integrations/available",
    },
    "/addons/installed/:id/edit": {
      exact: true,
      renderFunc: (id) => (props) =>
        (
          <Redirect
            to={`/integrations/installed/${props.match.params.id}/edit`}
          />
        ),
    },
    "/addons/installed/:id": {
      exact: true,
      renderFunc: (id) => (props) =>
        <Redirect to={`/integrations/installed/${props.match.params.id}`} />,
    },
    "/addons/installed": {
      exact: true,
      redirect: "/integrations/installed",
    },
    "/addons/:integrationType/new": {
      exact: true,
      renderFunc: (id) => (props) =>
        (
          <Redirect
            to={`/integrations/${props.match.params.integrationType}/new`}
          />
        ),
    },
    "/addons/:integrationType": {
      exact: true,
      renderFunc: (id) => (props) =>
        <Redirect to={`/integrations/${props.match.params.integrationType}`} />,
    },
    "/addons": {
      exact: true,
      redirect: "/integrations",
    },
    "/settings": {
      exact: true,
      redirect: "/config/run-profiles",
    },
    "/settings/run-profiles/new": {
      exact: true,
      redirect: "/config/run-profiles/new",
    },
    "/settings/run-profiles/:id": {
      exact: true,
      renderFunc: (id) => (props) =>
        <Redirect to={`/config/run-profiles/${props.match.params.id}`} />,
    },
    "/settings/run-profiles/:id/edit": {
      exact: true,
      renderFunc: (id) => (props) =>
        <Redirect to={`/config/run-profiles/${props.match.params.id}/edit`} />,
    },
    "/settings/run-profiles": {
      exact: true,
      redirect: "/config/run-profiles",
    },
    "/": {
      redirect: !accountId
        ? "/invites"
        : userHasApps
        ? "/dashboard"
        : "/marketplace",
    },
  };
};

export const noAccessTeamsRoutes: AuthenticatedRoutes = {
  "/team": {
    providers: [SubscriptionProvider],
    Component: <Team />,
  },
  "/": {
    redirect: "/team",
  },
};
