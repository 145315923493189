import styled from "@emotion/styled";

import { styleHideVisually } from "../../lib/constants";
import { rem } from "../../lib/tools";

import {
  GetPositionValueParams,
  StyledDatePickerCanvasProps,
  StyledDatePickerProps,
  StyledNavButtonProps,
} from "./DatePicker.types";

const parseOffset = (value: string | number) => {
  return typeof value === "string" ? value : rem(value);
};

const getPositionValue = ({
  offset,
  orientation,
  property,
}: GetPositionValueParams) => {
  if (orientation === property) {
    return offset ? parseOffset(offset) : 0;
  }
  return "auto";
};

// invisible panel to provide click outside to close functionality
export const StyledDatePickerCanvas = styled.div<StyledDatePickerCanvasProps>`
  display: ${(props) => (props.isActive ? "block" : "none")};
  opacity: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: transparent;
  z-index: 11111;
`;

// top: ${(props) => (props.offsetY ? parseOffset(props.offsetY) : 0)};
export const StyledDatePicker = styled.div<StyledDatePickerProps>`
  position: absolute;
  top: ${(props) =>
    getPositionValue({
      offset: props.offsetY,
      orientation: props.orientation,
      property: "top",
    })};
  right: ${(props) =>
    getPositionValue({
      offset: props.offsetX,
      orientation: props.orientation,
      property: "right",
    })};
  bottom: ${(props) =>
    getPositionValue({
      offset: props.offsetY,
      orientation: props.orientation,
      property: "bottom",
    })};
  left: ${(props) =>
    getPositionValue({
      offset: props.offsetX,
      orientation: props.orientation,
      property: "left",
    })};
  z-index: 11112;

  ${(props) => !props.isActive && styleHideVisually}

  .rdp-head_cell {
    font-family: ${({ theme }) => theme.ui2Typography.fontFamilyLabel};
    font-size: ${({ theme }) => theme.ui2Typography.fontSizeLabel};
    font-style: ${({ theme }) => theme.ui2Typography.fontStyleLabel};
    font-weight: ${({ theme }) => theme.ui2Typography.fontWeightLabel};
    line-height: ${({ theme }) => theme.ui2Typography.lineHeightLabel};
    letter-spacing: ${({ theme }) => theme.ui2Typography.letterSpacingLabel};
    text-transform: ${({ theme }) => theme.ui2Typography.textTransformLabel};
    color: ${({ theme }) => theme.color.gray600};
  }
  .rdp-cell {
    width: ${({ theme }) => theme.spacing.s8};
    height: ${({ theme }) => theme.spacing.s8};
    font-family: ${({ theme }) => theme.ui2Typography.fontFamilyBody2};
    font-size: ${({ theme }) => theme.ui2Typography.fontSizeBody2};
    font-style: ${({ theme }) => theme.ui2Typography.fontStyleBody2};
    font-weight: ${({ theme }) => theme.ui2Typography.fontWeightBody2};
    line-height: ${({ theme }) => theme.ui2Typography.lineHeightBody2};
    letter-spacing: ${({ theme }) => theme.ui2Typography.letterSpacingBody2};
    text-transform: ${({ theme }) => theme.ui2Typography.textTransformBody2};
    color: ${({ theme }) => theme.color.gray700};
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  }
  .rdp-day {
    width: ${({ theme }) => theme.spacing.s8};
    height: ${({ theme }) => theme.spacing.s8};
  }
  .rdp-day_today {
    font-weight: ${({ theme }) => theme.ui2Typography.fontWeightBody2Bold};
    color: ${({ theme }) => theme.color.gray800};
  }
  .rdp-day_outside {
    opacity: 1;
    color: ${({ theme }) => theme.color.gray500};
  }
  .rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
    background-color: ${({ theme }) => theme.color.sky200};
  }
  .rdp-day_selected,
  .rdp-day_selected:focus-visible,
  .rdp-day_selected:hover {
    color: ${({ theme }) => theme.color.gray800};
    background-color: ${({ theme }) => theme.color.sky300};
  }
  .rdp-day_selected:focus-visible {
    outline: 0;
    border: 0;
    box-shadow: 0 0 0 ${({ theme }) => theme.border.widthThick}
      ${({ theme }) => theme.color.sky600};
  }
  .rdp-day_disabled {
    color: ${({ theme }) => theme.color.gray400};
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath d='M6.5 29L33 11' stroke='%23101111' stroke-opacity='0.34' stroke-width='1.5'/%3e %3c/svg%3e");
    background-size: ${({ theme }) => theme.spacing.s6};
  }

  .rdp-months {
    flex-wrap: wrap;
  }
  .rdp-month + .rdp-month {
    margin-top: ${({ theme }) => theme.spacing.s4};
    margin-left: 0;
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.sm}) {
    .rdp-months {
      flex-wrap: nowrap;
    }
    .rdp-month {
      flex-shrink: 1;
    }
    .rdp-month + .rdp-month {
      margin-top: 0;
      margin-left: ${({ theme }) => theme.spacing.s4};
    }

    /* only for range selection */
    .rdp-multiple_month .rdp-table {
      width: 100%;
      table-layout: fixed;
    }
  }
`;

export const StyledNavButton = styled.button<StyledNavButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ theme }) => theme.spacing.s7};
  height: ${({ theme }) => theme.spacing.s7};
  cursor: pointer;
  border-width: ${({ theme }) => theme.border.width};
  border-style: ${({ theme }) => theme.border.style};
  border-color: ${({ theme }) => theme.color.gray200};
  border-radius: ${({ theme }) => theme.border.radiusLarge};
  background-color: transparent;

  &:hover,
  &:focus,
  &:active {
    background-color: ${({ theme }) => theme.color.gray200};
  }
  &:focus-visible {
    outline: 0;
    border-color: transparent;
    box-shadow: 0 0 0 ${({ theme }) => theme.border.widthMediumShadowOnly}
      ${({ theme }) => theme.color.gray400};
  }

  ${(props) =>
    props.isDisabled &&
    `
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;

export const StyledInputTime = styled.input`
  margin-top: ${rem(1)};
  margin-left: ${({ theme }) => theme.spacing.s1};
  padding-top: ${rem(2)};
  padding-right: ${({ theme }) => theme.spacing.s1};
  padding-bottom: ${rem(2)};
  padding-left: ${({ theme }) => theme.spacing.s1};
  position: relative;
  font-family: ${({ theme }) => theme.ui2Typography.fontFamilyMeta1};
  font-size: ${({ theme }) => theme.ui2Typography.fontSizeMeta1};
  font-style: ${({ theme }) => theme.ui2Typography.fontStyleMeta1};
  font-weight: ${({ theme }) => theme.ui2Typography.fontWeightMeta1};
  line-height: ${({ theme }) => theme.ui2Typography.lineHeightMeta1};
  letter-spacing: ${({ theme }) => theme.ui2Typography.letterSpacingMeta1};
  text-transform: ${({ theme }) => theme.ui2Typography.textTransformMeta1};
  color: ${({ theme }) => theme.color.gray600T};
  border-width: ${({ theme }) => theme.border.width};
  border-style: ${({ theme }) => theme.border.style};
  border-color: transparent;
  border-radius: ${({ theme }) => theme.border.radiusLarge};
  background-color: ${({ theme }) => theme.color.gray100};

  &:hover,
  &:focus,
  &:active {
    border-color: ${({ theme }) => theme.color.gray300};
  }

  &:focus-visible {
    outline: 0;
    border-color: transparent;
    box-shadow: 0 0 0 ${({ theme }) => theme.border.widthMediumShadowOnly}
      ${({ theme }) => theme.color.gray400};
  }

  /* browser icon that activates selection menu */
  ::-webkit-calendar-picker-indicator {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
    background-image: none;
    background-color: transparent;
    &:focus-visible {
      outline: 0;
    }
  }
`;
