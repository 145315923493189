import {
  ExperimentStatus,
  RunStatusV2,
} from "../../api/core/controlPlane.types";

export const getIsCompletedState = (
  status: ExperimentStatus | RunStatusV2
): boolean => {
  if (
    status === "failed" ||
    status === "canceled" ||
    status === "cancelled" ||
    status === "completed" ||
    status === "succeeded" ||
    status === "unknown"
  ) {
    return true;
  }
  return false;
};
