import React from "react";
import { Box, Button2, Flex, IconExternalLink, Text } from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";

import { useUser } from "../../../AuthProvider";
import {
  StyledContainer,
  StyledMarketplaceAppImage,
} from "../MarketplaceApp.styled";
import { CtaMarketplaceAppProps } from "../MarketplaceApp.types";

const CustomConstraintCta = ({
  docsLink,
  marketplaceAppId,
  marketAppName,
}: CtaMarketplaceAppProps) => {
  const [{ id }] = useUser();
  const theme = useTheme();

  return (
    <StyledContainer
      pt={4}
      pr={10}
      pb={7}
      pl={7}
      alignItems={["flex-start", "flex-start", "flex-start", "center"]}
      justifyContent="space-between"
      flexDirection={["column", "column", "column", "row"]}
    >
      <Box pt={3} maxWidth={rem(720)}>
        <Text
          as="h3"
          styleName="header-1"
          styles={{ color: theme.color.gray800 }}
        >
          Need a custom constraint or custom&nbsp;objective?
        </Text>
        <Box mt={1} maxWidth={rem(620)}>
          <Text styleName="body-1">
            Customize the {marketAppName} app to your specific business needs
            with Nextmv SDK and custom apps. Download and install Nextmv CLI for
            free to get started.
          </Text>
        </Box>

        <Flex mt={4}>
          <Button2
            type="outline"
            label="Nextmv CLI & SDK install guide"
            to={`/acc/${id}/install?marketplaceAppId=${marketplaceAppId}`}
          />
          <Button2
            ml={2}
            type="text-quiet"
            icon2={<IconExternalLink iconColor={theme.color.gray500} />}
            label="Docs"
            href={docsLink || "https://www.nextmv.io/docs/core-concepts/apps"}
            isUrlExternal={true}
          />
        </Flex>
      </Box>

      <Box mt={[6, 6, 6, 4]} ml={[0, 0, 0, 6]} noShrink maxWidth={rem(320)}>
        <StyledMarketplaceAppImage
          src="/images/illustration-custom-constraint-carrots.png"
          alt=""
          aria-hidden="true"
          maxWidth={rem(320)}
        />
      </Box>
    </StyledContainer>
  );
};

export default CustomConstraintCta;
