import { CreateInputSetPayload } from "../../../../../api/core/controlPlane.types";
import {
  INPUT_SET_CREATE_TYPE_INPUTS,
  INPUT_SET_CREATE_TYPE_INSTANCE_DATE,
  INPUT_SET_CREATE_TYPE_RUN_IDS,
  INPUT_SET_CREATE_TYPE_UPLOAD_FILE,
} from "../../../../../config/experiments";
import { InputSetCreateTypeOption } from "../../../Experiments.types";

export const modifyCreateInputSetPayload = (
  payload: CreateInputSetPayload,
  createTypeSelected: InputSetCreateTypeOption
) => {
  if (createTypeSelected.value === INPUT_SET_CREATE_TYPE_INSTANCE_DATE) {
    payload.end_time = new Date();
    delete payload.inputs;
    delete payload.run_ids;
  }
  if (createTypeSelected.value === INPUT_SET_CREATE_TYPE_RUN_IDS) {
    delete payload.end_time;
    delete payload.inputs;
    delete payload.instance_id;
    delete payload.start_time;
  }
  if (
    createTypeSelected.value === INPUT_SET_CREATE_TYPE_UPLOAD_FILE ||
    createTypeSelected.value === INPUT_SET_CREATE_TYPE_INPUTS
  ) {
    delete payload.end_time;
    delete payload.instance_id;
    delete payload.run_ids;
    delete payload.start_time;
  }
  return payload;
};

export const parseRunIdsNewLine = (runIds: string) => {
  let runIdsLineBreak = "";
  runIdsLineBreak = runIds
    .split(/(\s+)/)
    .filter((e) => e.trim().length > 0)
    .join("\n");
  if (runIds[runIds.length - 1] === "\n") {
    runIdsLineBreak += "\n";
  }
  return runIdsLineBreak;
};
