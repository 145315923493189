import React from "react";
import SwaggerUI from "swagger-ui-react";

import { trackEvent } from "../../analytics";
import { API_BASE } from "../../api/core/core.config";

import { CustomSwaggerStyles } from "./Swagger.styled";

import "swagger-ui-react/swagger-ui.css";

// TODO: this is very brittle and I think there's a better way by building a plugin
const track = (url: string) => {
  let path = "unidentified path";
  if (url.match(/\/v0\/run$/)) {
    path = "/run";
  } else if (url.match(/\/v0\/run\/[0-9a-f-]+\/status$/)) {
    path = "/run/{run_id}/status";
  } else if (url.match(/\/v0\/run\/[0-9a-f-]+\/result$/)) {
    path = "/run/{run_id}/result";
  }
  trackEvent("Swagger Executed", {
    version: "v0",
    path,
  });
};

type SwagProps = { apiSpec: any; noTracking?: boolean; apiKey: string };

const Swag = ({ apiSpec, noTracking, apiKey }: SwagProps) => {
  apiSpec.servers[0].url = API_BASE;

  const swaggerUIComplete = function (swaggerUi: any) {
    if (!apiKey) {
      return;
    }
    swaggerUi.preauthorizeApiKey("apiToken", apiKey);
  };

  return (
    <CustomSwaggerStyles>
      <SwaggerUI
        {...(!noTracking && {
          requestInterceptor: (req: any): any => {
            track(req.url);
            return req;
          },
        })}
        tryItOutEnabled={true}
        spec={apiSpec}
        onComplete={swaggerUIComplete}
      />
    </CustomSwaggerStyles>
  );
};

export default Swag;
