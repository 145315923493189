import { AvatarFail, AvatarPass, AvatarWarning } from "@console/dsc";

import { AcceptanceTestStatus } from "../../../Experiments.types";

export const getBackgroundStatusColor = (
  status: AcceptanceTestStatus,
  warning: boolean,
  theme: any
): string => {
  const backgroundColorObj: { [key: string]: string } = {
    pass: theme.color.gray100,
    fail: theme.color.red200,
  };
  return warning ? theme.color.yellow200 : backgroundColorObj[status];
};

export const getTextStatusColor = (
  status: AcceptanceTestStatus,
  warning: boolean,
  theme: any
): string => {
  const textColorObj: { [key: string]: string } = {
    pass: theme.color.gray800,
    fail: theme.color.red800,
  };
  return warning ? theme.color.yellow800 : textColorObj[status];
};

export const getStatusAvatar = (
  status: AcceptanceTestStatus,
  warning: boolean,
  theme: any
) => {
  if (warning) {
    return <AvatarWarning size={24} />;
  }
  return status === "pass" ? (
    <AvatarPass size={24} />
  ) : (
    <AvatarFail size={24} />
  );
};
