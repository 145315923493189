import React from "react";

import useManageEntities from "../../hooks/useManageEntities";

import { AppsContextProps } from "./Apps.context.types";

export const AppsContext = React.createContext<AppsContextProps>({
  additionalAppsLoading: false,
  apps: null,
  appsError: null,
  appsNextPageToken: "",
  loadApps: () => new Promise((resolve) => resolve([])),
  setAdditionalAppsLoading: () => null,
  setApps: () => null,
  setAppsError: () => null,
});

const AppsProvider = ({ children }: { children: React.ReactNode }) => {
  const {
    additionalEntitiesLoading: additionalAppsLoading,
    entities: apps,
    entitiesLoadError: appsError,
    entitiesNextPageToken: appsNextPageToken,
    loadEntities: loadApps,
    setAdditionalEntitiesLoading: setAdditionalAppsLoading,
    setEntities: setApps,
    setEntitiesLoadError: setAppsError,
  } = useManageEntities("applications");

  const value: AppsContextProps = {
    additionalAppsLoading,
    apps,
    appsError,
    appsNextPageToken,
    loadApps,
    setAdditionalAppsLoading,
    setApps,
    setAppsError,
  };

  return <AppsContext.Provider value={value}>{children}</AppsContext.Provider>;
};

export const useAppsCollection = () => React.useContext(AppsContext);

export default AppsProvider;
