import { startCase } from "lodash";

import { BatchExperiment } from "../../../api/core/controlPlane.types";
import { SummaryViewOption } from "../Experiments.types";

export const defaultSummaryViewOption: SummaryViewOption = {
  value: "instanceID-versionID",
  keys: ["instanceID", "versionID"],
  label: "Instance ID, Version ID",
};

export const collectViewOptions = (
  batchExperiment: BatchExperiment
): SummaryViewOption[] => {
  const groupKeys: { [key: string]: SummaryViewOption } = {};

  (batchExperiment.grouped_distributional_summaries || []).forEach(
    (summary) => {
      // remove eventually
      if (summary.group_keys.length > 2) {
        return;
      }

      groupKeys[summary.group_keys.join("-")] = {
        label: getLabel(summary.group_keys),
        value: summary.group_keys.join("-"),
        keys: summary.group_keys,
      };
    }
  );

  return Object.values(groupKeys);
};

const getLabel = (arr: string[]) => {
  const startCased: string[] = [];
  arr.forEach((el) => startCased.push(startCase(el)));
  return startCased.join(", ");
};
