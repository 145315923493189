// This is not used at the moment, but if we ever want to auto escape
// an ensire JSON, this would do it.  Generally not needed because react
// does it by default.
const map = {
  "&": "&amp;",
  "<": "&lt;",
  ">": "&gt;",
  '"': "&quot;",
  "'": "&#x27;",
  "/": "&#x2F;",
};

const dangerousCharacters = /[&<>"'/]/gi;

export const sanitizeObject = (obj: Object) => {
  const str = JSON.stringify(obj);

  const reg = /".*?"/gi;
  const cleaned = str.replace(reg, (match) => {
    const quotesStripped = match.slice(1, match.length - 1);
    const cleanMatch = quotesStripped.replace(dangerousCharacters, (m) => {
      return map[m as keyof typeof map];
    });
    return `"${cleanMatch}"`;
  });

  return JSON.parse(cleaned);
};

export const sanitizeStr = (str: string) => {
  return str.replace(dangerousCharacters, (m) => {
    return map[m as keyof typeof map];
  });
};
