import { FitBoundsOptions } from "leaflet";

import { SolutionRoute } from "../Map.types";

export const MAP_DISPLAY_APPROXIMATE = "approximate";
export const MAP_DISPLAY_ROAD = "road";

export const LEAFLET_ZOOM_SNAP = 0.1;
export const FIT_BOUNDS_OPTIONS: FitBoundsOptions = {
  padding: [150, 150],
};

export const EMPTY_ROUTE_SET: SolutionRoute = {
  hasApproximate: false,
  hasRoads: false,
  routesById: {},
  assignedPoints: [],
  markerResultPoints: [],
  unassignedCount: 0,
};

export const ROUTE_FOCUS_TIMEOUT = 300;
