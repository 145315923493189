import { SHADOW_TEST_DEFAULT_MAX_RUNS } from "../../../../../utils/constants";
import { CONTENT_ID_RULES } from "../../../../../utils/systemIds";

export const tooltipContentNewShadowTest = {
  id: {
    content: `The ID is the unique identifier for your shadow test and can be edited before creating the shadow test. ${CONTENT_ID_RULES}`,
  },
  baselineInstance: {
    content:
      "The instance to use for the baseline instance. When the shadow test has started, any run made on the baseline instance will trigger a run on the candidate instance using the same input. NOTE: A baseline instance can only have one active shadow test running at any given time.",
  },
  candidateInstance: {
    content:
      "The instance to use for the candidate instance being compared to the basline instance.",
  },
  endCriteria: {
    content: `End criteria can be defined by a maximum number of runs, an end date, or both. The test will end once either of the criteria have been met (though you can manually complete or cancel a shadow test at any time). Note that there is a maximum run limit of ${SHADOW_TEST_DEFAULT_MAX_RUNS}.`,
  },
  startCriteria: {
    content:
      "If defined, the test will begin collecting data on this date when the test is in a started state. The start time is optional if the end criterion is number of runs. However, if an end date is chosen then the start time is required. Note that the test can be started manually before the start time, but it will not begin collecting data until this start date.",
  },
};
