import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import {
  AvatarExperimentScenario,
  Box,
  Button2,
  Flex,
  Input,
  Loading,
  RowDetail,
  Text,
} from "@console/dsc";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../../../analytics";
import { CreateBatchExperimentPayload } from "../../../../api/core/controlPlane.types";
import Footer from "../../../../components/Footer";
import Header from "../../../../components/Header";
import { useMetaTitle } from "../../../../components/Layout";
import { PAYLOAD_EXPERIMENT_TYPE_SCENARIO } from "../../../../config/experiments";
import {
  INPUT_WIDTH_NARROW,
  INPUT_WIDTH_STANDARD,
} from "../../../../config/general";
import { useExperiments } from "../../../../contexts/experiments/Experiments.context";
import useBatchExperiments from "../../../../contexts/experiments/hooks/useBatchExperiments";
import useManageEntity from "../../../../hooks/useManageEntity";
import useStandardInputs from "../../../../hooks/useStandardInputs";
import { getIterativeNumeral } from "../../../../utils/getIterativeNumeral";
import { AppPageProps } from "../../../App/App.types";
import useReturnPaths from "../../../App/hooks/useReturnPaths";
import AddPlanSets from "../../components/AddPlanSets";
import { scenarioTooltips, sharedTooltipCopy } from "../../data/microcopy";
import { PlanSet } from "../../Experiments.types";
import { getSafeCloneName } from "../../utils/getSafeCloneName";

const pageTitle = "Create new scenario plan";

const NewScenarioTest = ({ app }: AppPageProps) => {
  const theme = useTheme();
  const [, setMetaTitle] = useMetaTitle();
  const { returnPath, returnPathList } = useReturnPaths();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const cloneId = searchParams.get("cloneId");

  // scenario test management
  const [isConfirmCreate, setIsConfirmCreate] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [pendingPlanSets, setPendingPlanSets] = useState<PlanSet[]>([]);
  const [pendingRepetitions, setPendingRepetitions] = useState(0);
  const [totalRuns, setTotalRuns] = useState(0);

  // input set management
  const [isInputSetProcessing, setIsInputSetProcessing] = useState(false);
  const [shouldSubmitInputSet, setShouldSubmitInputSet] = useState(false);
  const [arePendingInputSetsComplete, setArePendingInputSetsComplete] =
    useState(false);

  const { loadScenarioTests } = useExperiments();

  const { getScenarioTestPayload } = useBatchExperiments();

  const {
    addEntity: addScenarioTest,
    entity: scenarioTest,
    entityAddError: scenarioTestAddError,
    entityLoadError: scenarioTestLoadError,
    isEntityAdded: isScenarioTestAdded,
    loadEntity: loadScenarioTest,
    setEntityAddError: setScenarioTestAddError,
  } = useManageEntity("experiments/batch");

  const {
    getStandardInputsProps,
    pendingStandardInputs,
    standardInputsErrors,
    updateStandardInputs,
  } = useStandardInputs(app, "experiments/batch");

  // page display
  useEffect(() => {
    setMetaTitle(pageTitle);
  }, [setMetaTitle]);

  // get scenario test data for cloning if cloneId present
  // TODO implement this or punt
  useEffect(() => {
    if (cloneId && !scenarioTest && !scenarioTestLoadError) {
      loadScenarioTest(app.id, cloneId);
    }
  }, [app.id, scenarioTest, scenarioTestLoadError, cloneId, loadScenarioTest]);

  // if clone data, prefill new scenario test fields
  // TODO implement this or punt
  useEffect(() => {
    if (cloneId && scenarioTest && !pendingStandardInputs.id) {
      // modify name to prevent duplicate ID error
      const modifiedScenarioTestName = `${getSafeCloneName(
        scenarioTest.name
      )} clone`;

      // ID set automatically by name field
      updateStandardInputs([
        { key: "name", value: modifiedScenarioTestName },
        { key: "description", value: scenarioTest?.description },
      ]);
    }
  }, [scenarioTest, cloneId, pendingStandardInputs.id, updateStandardInputs]);

  // disable loading state if add new scenario test error
  useEffect(() => {
    if (scenarioTestAddError && isProcessing) {
      setIsProcessing(false);
    }
  }, [scenarioTestAddError, isProcessing]);

  // track input uploading for each plan set
  useEffect(() => {
    if (
      isInputSetProcessing &&
      !scenarioTestAddError &&
      !arePendingInputSetsComplete &&
      pendingPlanSets.every((pendingPlanSet) => pendingPlanSet.inputSetId)
    ) {
      setArePendingInputSetsComplete(true);
    }
  }, [
    arePendingInputSetsComplete,
    isInputSetProcessing,
    pendingPlanSets,
    scenarioTestAddError,
  ]);

  const handleScenarioTestCreate = useCallback(async () => {
    setIsConfirmCreate(false);
    setIsProcessing(true);

    let payload: CreateBatchExperimentPayload = {
      ...pendingStandardInputs,
      type: PAYLOAD_EXPERIMENT_TYPE_SCENARIO,
    };

    const scenarioTestPayload = await getScenarioTestPayload({
      appId: app.id,
      planSets: pendingPlanSets,
      repetitions: pendingRepetitions,
    });
    payload["option_sets"] = scenarioTestPayload.option_sets;
    payload["runs"] = scenarioTestPayload.runs;

    trackEvent("Scenario", {
      view: "Create Scenario Test",
      action: "Scenario Test Submmitted",
      meta: {
        option_sets: Object.keys(scenarioTestPayload.option_sets).length,
        runs: scenarioTestPayload.runs.length,
      },
    });

    await addScenarioTest(app.id, payload);
  }, [
    addScenarioTest,
    app.id,
    getScenarioTestPayload,
    pendingPlanSets,
    pendingRepetitions,
    pendingStandardInputs,
  ]);

  // finish create process after input set created
  useEffect(() => {
    if (arePendingInputSetsComplete && isInputSetProcessing) {
      setIsInputSetProcessing(false);
      handleScenarioTestCreate();
    }
  }, [
    arePendingInputSetsComplete,
    handleScenarioTestCreate,
    isInputSetProcessing,
  ]);

  // track total run count for confirmation
  useEffect(() => {
    const updatedTotalRuns = pendingPlanSets.reduce(
      (totalCount, currentPlanSet) => {
        return totalCount + currentPlanSet.runCount;
      },
      0
    );
    setTotalRuns(updatedTotalRuns);
    return;
  }, [pendingPlanSets, setTotalRuns]);

  const handleOnChangeRepetitions = (e: { target: { value: any } }) => {
    const { value } = e.target;
    return setPendingRepetitions(parseInt(value));
  };

  const handleScenarioTestPreCreate = (e: {
    preventDefault: any;
    stopPropagation: any;
  }) => {
    e.preventDefault();
    e.stopPropagation();

    if (
      pendingPlanSets.some(
        (pendingPlanSet) => pendingPlanSet.shouldUseFilesForInputSet
      )
    ) {
      setIsInputSetProcessing(true);
      setShouldSubmitInputSet(true);
      setScenarioTestAddError(null);
    } else {
      handleScenarioTestCreate();
    }

    return;
  };

  const handleScenarioTestActionButton = (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    e.preventDefault();
    e.stopPropagation();

    setIsConfirmCreate(true);
    return;
  };

  const handleCancel = () => {
    trackEvent("Scenario", {
      view: "Create Scenario Test",
      action: "Create Scenario Test Canceled",
    });

    return;
  };

  const arePendingInputSetsDefined = useMemo(() => {
    return pendingPlanSets.every(
      (planSet) => planSet.inputSetId || planSet.shouldUseFilesForInputSet
    );
  }, [pendingPlanSets]);

  const arePendingInstancesDefined = useMemo(() => {
    return pendingPlanSets.every(
      (planSet) => planSet.instances.filter(Boolean).length
    );
  }, [pendingPlanSets]);

  if (cloneId && !scenarioTest && !scenarioTestLoadError) {
    return <Loading type="full-screen" dotColor={theme.color.orange500} />;
  }

  if (isScenarioTestAdded) {
    trackEvent("Scenario", {
      view: "Create Scenario Test",
      action: "New Scenario Test Created",
    });

    loadScenarioTests({
      applicationId: app.id,
      type: PAYLOAD_EXPERIMENT_TYPE_SCENARIO,
    });

    return <Redirect to={returnPathList} />;
  }

  const isActionButtonDisabled =
    !pendingStandardInputs.name ||
    !pendingStandardInputs.id ||
    !arePendingInputSetsDefined ||
    !arePendingInstancesDefined ||
    !!standardInputsErrors.name ||
    !!standardInputsErrors.id;

  return (
    <>
      <Header
        configPageTitle={{
          label: pageTitle,
          ancestorIcon: <AvatarExperimentScenario size={24} type="fill" />,
          ancestorLabel: "Scenario Tests",
          ancestorUrl: returnPathList,
        }}
      />

      <Box pb={[6, 6, 8]}>
        <form>
          <RowDetail
            hasNoBorder
            property="Name"
            secondaryLabel="For reference only"
            render={
              <Box width="100%" maxWidth={INPUT_WIDTH_STANDARD}>
                <Input
                  {...getStandardInputsProps({
                    placeholder: "Scenario name",
                    testId: "new-scenario-test-name-input",
                    type: "name",
                    trackEventCategory: "Scenario",
                    trackEventProperties: {
                      view: "Create Scenario Test",
                      action: "Field Entered",
                      meta: {
                        field: "name",
                      },
                    },
                  })}
                />
              </Box>
            }
          />

          <RowDetail
            property="ID"
            tooltipCopy={sharedTooltipCopy.id("scenario test").content}
            render={
              <Box width="100%" maxWidth={INPUT_WIDTH_STANDARD}>
                <Input
                  {...getStandardInputsProps({
                    placeholder: "Scenario ID",
                    testId: "new-scenario-test-id-input",
                    type: "id",
                    trackEventCategory: "Scenario",
                    trackEventProperties: {
                      view: "Create Scenario Test",
                      action: "Scenario Test ID Changed",
                    },
                  })}
                />
              </Box>
            }
          />

          <RowDetail
            property="Description"
            secondaryLabel="(optional)"
            render={
              <Box width="100%" maxWidth={INPUT_WIDTH_STANDARD}>
                <Input
                  {...getStandardInputsProps({
                    placeholder: "Scenario description",
                    testId: "new-scenario-test-description-input",
                    type: "description",
                    trackEventCategory: "Scenario",
                    trackEventProperties: {
                      view: "Create Scenario Test",
                      action: "Field Entered",
                      meta: {
                        field: "description",
                      },
                    },
                  })}
                />
              </Box>
            }
          />

          <RowDetail
            property="Scenarios"
            tooltipCopy={scenarioTooltips.scenarios.content}
            render={
              <AddPlanSets
                {...{
                  app,
                  pendingPlanSets,
                  setPendingPlanSets,
                  setScenarioTestAddError,
                  shouldSubmitInputSet,
                }}
                pendingScenarioId={pendingStandardInputs.id}
              />
            }
          />

          <RowDetail
            property="Repetitions"
            secondaryLabel="(optional)"
            tooltipCopy={scenarioTooltips.repetitions.content}
            render={
              <Box width="100%" maxWidth={INPUT_WIDTH_NARROW}>
                <Input
                  htmlType="number"
                  placeholder="Enter a number"
                  value={pendingRepetitions}
                  min={0}
                  onChange={handleOnChangeRepetitions}
                />
              </Box>
            }
          />

          <Footer
            actionButtonLabel="Create scenario test"
            app={app}
            endpoint="experiments/batch"
            error={scenarioTestAddError}
            handleCancel={handleCancel}
            handleMainAction={handleScenarioTestActionButton}
            isActionButtonLoading={isProcessing || isInputSetProcessing}
            isActionButtonDisabled={isActionButtonDisabled}
            returnPath={returnPath}
            returnPathList={returnPathList}
            view="create"
            isConfirm={isConfirmCreate}
            confirmContent={
              <Box>
                <Text styleName="body-1">
                  This scenario test will create{" "}
                  <Text
                    as="span"
                    styleName="body-1-bold"
                    styles={{ color: theme.color.gray800 }}
                  >
                    {totalRuns * (pendingRepetitions + 1)} runs
                  </Text>
                  .{" "}
                  {!!pendingRepetitions &&
                    `(${totalRuns} runs repeated 
                  ${getIterativeNumeral(pendingRepetitions)})`}
                </Text>
                <Text styleName="body-1">
                  Are you sure you want to continue?
                </Text>

                <Flex mt={3}>
                  <Button2
                    testId="confirm-create-scenario-test-submit-button"
                    isLoading={isProcessing || isInputSetProcessing}
                    label="Confirm create & run scenario test"
                    size="large"
                    htmlType="submit"
                    onClick={handleScenarioTestPreCreate}
                  />

                  <Button2
                    ml={4}
                    type="text"
                    size="large"
                    label="Cancel"
                    htmlType="button"
                    onClick={(e: {
                      preventDefault: () => void;
                      stopPropagation: () => void;
                    }) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setIsConfirmCreate(false);
                      return;
                    }}
                  />
                </Flex>
              </Box>
            }
          />
        </form>
      </Box>
    </>
  );
};

export default NewScenarioTest;
