import { chartColors } from "@console/dsc/src/tokens/color";

import {
  Coords,
  DepotInfoStop,
  MarkerPoint,
  MarkerPointKind,
  Route,
  RouteSetByID,
  RunOutput,
  RunOutputVehicle,
  RunOutputVehicleStop,
  ShapeRoute,
  SolutionRoute,
  UnassignedStop,
} from "../Map.types";

// See http://valhalla.github.io/demos/polyline/decode.js
export function decode(encoded: string, mul: number): Coords[] {
  var inv = 1.0 / mul;
  var decoded = [];
  var previous = [0, 0];
  var i = 0;
  // Walk each byte
  while (i < encoded.length) {
    // Each coord is (lat, lon)
    var ll = [0, 0];
    for (var j = 0; j < 2; j++) {
      var shift = 0;
      var byte = 0x20;
      // Decode until the full coordinate is available
      while (byte >= 0x20) {
        byte = encoded.charCodeAt(i++) - 63;
        ll[j] |= (byte & 0x1f) << shift;
        shift += 5;
      }
      // Add previous offset to get final value and remember for next one
      ll[j] = previous[j] + (ll[j] & 1 ? ~(ll[j] >> 1) : ll[j] >> 1);
      previous[j] = ll[j];
    }
    // Scale by precision and chop off long coords
    // Flip to more standard lon,lat instead of lat,lon
    // Updated: does not flip to lon, lat for our mapping purposes
    decoded.push([ll[0] * inv, ll[1] * inv]);
  }

  return decoded as Coords[];
}

export const calculateBounds = (points: Coords[]): [number, number][] => {
  return points.reduce(
    (prev, curr) => [
      [Math.min(curr[0], prev[0][0]), Math.min(curr[1], prev[0][1])],
      [Math.max(curr[0], prev[1][0]), Math.max(curr[1], prev[1][1])],
    ],
    [
      [90, 180],
      [-90, -180],
    ]
  );
};

export const isVehicleStartOrEnd = (name: string) => {
  const isStart = name.slice(-6) === "-start";
  const isEnd = name.slice(-4) === "-end";

  return isStart || isEnd;
};

export const getStopKind = (
  name: string,
  routeLength: number,
  stopIndex: number
) => {
  return name.slice(-6) === "-start" && stopIndex === 0
    ? "start"
    : name.slice(-4) === "-end" && stopIndex === routeLength - 1
    ? "end"
    : "stop";
};

export const getUnassignedPoints = (unassignedStops: UnassignedStop[]) =>
  unassignedStops.map((stop: UnassignedStop) => {
    return {
      isAssigned: false,
      name: stop.id,
      position: [stop.lat, stop.lon] as Coords,
    } as MarkerPoint;
  });

export const getVehicleStopCount = (
  id: string,
  route: RunOutputVehicleStop[]
) => {
  return route.filter((stop) => ![`${id}-start`, `${id}-end`].includes(stop.id))
    .length;
};

const addRoadToVehicleRoutes = (
  routeShapes: [],
  vehicleRoutes: RouteSetByID
) => {
  routeShapes.forEach((x: ShapeRoute) => {
    vehicleRoutes[x.id].road = x.shapes.flatMap((y: string): Coords[] => {
      return decode(y, 1000000);
    });
  });
};

const getRouteShapes = async (
  routePayload: Route[],
  fetchWithAccount: (
    path: string,
    method: string,
    body?: string
  ) => Promise<Response | undefined>
): Promise<{ routeShapes: []; didRoutesRequestSucceed: boolean }> => {
  try {
    const res = await fetchWithAccount(
      "/route",
      "POST",
      JSON.stringify(routePayload)
    );
    const routeShapes: [] = await res?.json();

    return { routeShapes, didRoutesRequestSucceed: !!res?.ok };
  } catch {
    return { routeShapes: [], didRoutesRequestSucceed: false };
  }
};

export const upsertExistingMatchDepotInfo = (
  existingMatch: MarkerPoint,
  curVehicleID: string,
  stopKind: MarkerPointKind,
  color: string
): DepotInfoStop[] => {
  let depotInfo: DepotInfoStop[] = [];

  if (existingMatch.depotInfo) {
    depotInfo = existingMatch.depotInfo.concat({
      vehicleId: curVehicleID,
      vehicleKind: stopKind,
      vehicleTextColor: color,
    });
  } else {
    depotInfo = [
      {
        vehicleId: existingMatch.vehicleId,
        vehicleKind: existingMatch.kind,
        vehicleTextColor: existingMatch.color,
      },
      {
        vehicleId: curVehicleID,
        vehicleKind: stopKind,
        vehicleTextColor: color,
      },
    ];
  }

  return depotInfo;
};

export const processExistingMatch = (
  existingMatch: MarkerPoint,
  curVehicleID: string,
  stopKind: MarkerPointKind,
  stop: RunOutputVehicleStop,
  color: string,
  startOrEndObj: MarkerPoint
) => {
  const depotInfo = upsertExistingMatchDepotInfo(
    existingMatch,
    curVehicleID,
    stopKind,
    color
  );

  startOrEndObj.kindAlso = startOrEndObj.kind;
  startOrEndObj.kind = "depot";
  startOrEndObj.depotId = `depot-${stop.lat}-${stop.lon}`;
  startOrEndObj.depotInfo = depotInfo;
};

export const processStartOrEndObj = (
  curVehicleID: string,
  stop: RunOutputVehicleStop,
  stopKind: MarkerPointKind,
  color: string,
  startAndEndPositionMap: { [key: string]: MarkerPoint }
) => {
  const startOrEndObj: MarkerPoint = {
    color,
    eta: stop.eta,
    isAssigned: true,
    name: stop.id,
    vehicleId: curVehicleID,
    position: [stop.lat, stop.lon] as Coords,
    kind: stopKind,
  };

  const existingMatch = startAndEndPositionMap[`${stop.lat}-${stop.lon}`];

  if (existingMatch) {
    processExistingMatch(
      existingMatch,
      curVehicleID,
      stopKind,
      stop,
      color,
      startOrEndObj
    );
  }

  startAndEndPositionMap[`${stop.lat}-${stop.lon}`] = startOrEndObj;
};

export const pushToRoutePayload = (
  approximateRoutes: Coords[],
  routePayload: Route[],
  curVehicleID: string
) => {
  // if a vehicle only has one location it will
  // cause the stadia call to fail
  if (approximateRoutes.length > 1) {
    routePayload.push({
      id: curVehicleID,
      coords: approximateRoutes.map((coords): Coords => [coords[1], coords[0]]),
    } as Route);
  }
};

export const processStop = (
  stop: RunOutputVehicleStop,
  currentVehicleRouteLength: number,
  i: number,
  curVehicleID: string,
  color: string,
  startAndEndPositionMap: { [key: string]: MarkerPoint },
  assignedPoints: MarkerPoint[],
  approximateRoutes: Coords[]
) => {
  const stopKind = getStopKind(stop.id, currentVehicleRouteLength, i);
  if (["start", "end"].includes(stopKind)) {
    processStartOrEndObj(
      curVehicleID,
      stop,
      stopKind,
      color,
      startAndEndPositionMap
    );
  } else {
    assignedPoints.push({
      color,
      eta: stop.eta,
      isAssigned: true,
      name: stop.id,
      vehicleId: curVehicleID,
      position: [stop.lat, stop.lon] as Coords,
      kind: stopKind,
      ...(stop.ets && {
        ets: stop.ets,
      }),
      ...(stop.etd && {
        etd: stop.etd,
      }),
      ...(stop.travel_distance && {
        travel_distance: stop.travel_distance,
      }),
      ...(stop.cumulative_travel_distance && {
        cumulative_travel_distance: stop.cumulative_travel_distance,
      }),
      ...(stop.travel_duration && {
        travel_duration: stop.travel_duration,
      }),
      ...(stop.cumulative_travel_duration && {
        cumulative_travel_duration: stop.cumulative_travel_duration,
      }),
    });
  }
  approximateRoutes.push([stop.lat, stop.lon] as Coords);
};

export const processVehicleRoutes = (
  vehicles: RunOutputVehicle[],
  startAndEndPositionMap: { [key: string]: MarkerPoint },
  assignedPoints: MarkerPoint[],
  routePayload: Route[]
) => {
  return vehicles.reduce(
    (vehicleRoutesById: RouteSetByID, curVehicle: RunOutputVehicle, i) => {
      const approximateRoutes: Coords[] = [];

      const color = chartColors[i % chartColors.length];
      const curVehicleID = curVehicle.id;

      const currentVehicleRouteLength = curVehicle.route
        ? curVehicle.route.length
        : 0;

      curVehicle.route &&
        curVehicle.route.forEach((stop: RunOutputVehicleStop, i: number) => {
          processStop(
            stop,
            currentVehicleRouteLength,
            i,
            curVehicleID,
            color,
            startAndEndPositionMap,
            assignedPoints,
            approximateRoutes
          );
        });

      vehicleRoutesById[curVehicleID] = {
        id: curVehicleID,
        color,
        route: curVehicle.route || [],
        hasRoute: !!getVehicleStopCount(curVehicleID, curVehicle.route || []),
        approximate: approximateRoutes,
        road: [],
        // route_travel_duration in nextroute
        travel_time: curVehicle.travel_time,
        // route_travel_distance in nextroute
        travel_distance: curVehicle.travel_distance,
        stops_duration: curVehicle?.stops_duration,
        waiting_duration: curVehicle?.waiting_duration,
        route_duration: curVehicle?.route_duration,
      };

      pushToRoutePayload(approximateRoutes, routePayload, curVehicleID);

      return vehicleRoutesById;
    },
    {} as RouteSetByID
  );
};

export const getSolutionRoutes = async (
  output: RunOutput,
  fetchWithAccount: (
    path: string,
    method: string,
    body?: string
  ) => Promise<Response | undefined>
) => {
  const routePayload: Route[] = [];
  const assignedPoints: MarkerPoint[] = [];
  const startAndEndPositionMap: { [key: string]: MarkerPoint } = {};
  const unassignedPoints = getUnassignedPoints(output.state.unassigned);

  const vehicleRoutes = processVehicleRoutes(
    output.state.vehicles,
    startAndEndPositionMap,
    assignedPoints,
    routePayload
  );

  const { routeShapes, didRoutesRequestSucceed } = await getRouteShapes(
    routePayload,
    fetchWithAccount
  );

  if (routeShapes.length > 0) {
    addRoadToVehicleRoutes(routeShapes, vehicleRoutes);
  }

  return {
    hasApproximate: true,
    hasRoads: routeShapes.length > 0 || didRoutesRequestSucceed,
    routesById: vehicleRoutes,
    assignedPoints: Object.values(startAndEndPositionMap).concat(
      assignedPoints
    ),
    markerResultPoints: Object.values(startAndEndPositionMap).concat(
      assignedPoints,
      unassignedPoints
    ),
    unassignedCount: unassignedPoints.length,
  } as SolutionRoute;
};
