const lastOrderValue = 99999;

export const sortByConfig = (
  a: string,
  b: string,
  configObj: { [key: string]: number }
): number => {
  const sortOrderA = configObj[a] === undefined ? lastOrderValue : configObj[a];
  const sortOrderB = configObj[b] === undefined ? lastOrderValue : configObj[b];

  if (sortOrderA < sortOrderB) return -1;
  if (sortOrderA > sortOrderB) return 1;
  return 0;
};

export const sortAlpha = (a: string, b: string) => {
  return a.localeCompare(b);
};
