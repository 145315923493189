import { RouteSet } from "../../../../../../../../../components/Map/Map.types";
import { ROUTE_FOCUS_TIMEOUT } from "../../../../../../../../../components/Map/utils/constants";
import { useDebounceCallback } from "../../../../../../../../../hooks/useDebounce";

export default function useRouteFocus(
  setFocusedRouteId: (routeId: string) => void
) {
  const [setFocusedDebounced, clearFocusedDebounceTimeout] =
    useDebounceCallback((route: RouteSet, isRouteVisible: boolean) => {
      if (isRouteVisible) {
        setFocusedRouteId(route.id);
      }
    }, ROUTE_FOCUS_TIMEOUT);

  const handleUnfocusRoute = (
    isRouteVisible: boolean,
    isRouteFocused: boolean
  ) => {
    clearFocusedDebounceTimeout();
    // make sure route becomes unfocused at the same time
    // a new route would become focused for UI timing consistency
    setTimeout(() => {
      if (isRouteVisible && isRouteFocused) {
        setFocusedRouteId("");
      }
    }, ROUTE_FOCUS_TIMEOUT);
  };

  return {
    setFocusedDebounced,
    clearFocusedDebounceTimeout,
    handleUnfocusRoute,
  };
}
