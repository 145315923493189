import React, { useEffect } from "react";
import { Box, Flex, Loading, RowDetail } from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";

import { useUser } from "../../../../AuthProvider";
import DecisionCounter from "../../../../components/DecisionCounter";
import Header from "../../../../components/Header";
import { useMetaTitle } from "../../../../components/Layout";
import { useSubscription } from "../../../../contexts/subscription/Subscription.context";
import { confirmUserSubscription } from "../../../../contexts/subscription/utils";
import { useRefreshUserDataOnMount } from "../../../../hooks/user";
import {
  getDecisionsAvailable,
  getDecisionsUsed,
  getDecisionTooltipText,
} from "../../../../utils/decisions";
import { isLegacyCustomer } from "../../functions/customerAccess";

import LegacyPlanSummary from "./components/LegacyPlanSummary";

const pageTitle = "Profile Details";

const Profile = () => {
  const [user] = useUser();
  const {
    subscription,
    fetchSubscription,
    pendingSetupIntentSecret,
    subscriptionLoading,
  } = useSubscription();
  const [, setMetaTitle] = useMetaTitle();
  const theme = useTheme();

  // update user data on load so page reflects up-to-date information
  useRefreshUserDataOnMount();

  useEffect(() => {
    fetchSubscription();
    setMetaTitle(pageTitle);
  }, [fetchSubscription, setMetaTitle]);

  const userHasConfirmedSubscription = confirmUserSubscription(
    subscription,
    pendingSetupIntentSecret
  );

  const decisionsAvailable = getDecisionsAvailable(user);
  const decisionsUsed = getDecisionsUsed(user);

  if (subscriptionLoading) {
    return <Loading type="full-screen" dotColor={theme.color.orange500} />;
  }

  return (
    <>
      <Header configPageTitle={{ label: pageTitle }} />

      <Flex flexGrow={1} flexDirection="column">
        <RowDetail hasNoBorder property="Email" render={user.email} />
        {subscription &&
          userHasConfirmedSubscription &&
          isLegacyCustomer(subscription) && (
            <>
              <RowDetail
                property="Legacy Plan"
                render={<LegacyPlanSummary {...{ subscription }} />}
              />
              <RowDetail
                property="Decision Count"
                render={
                  <Box mt={rem(2)}>
                    <DecisionCounter
                      tooltipText={getDecisionTooltipText(user)}
                      decisionsUsed={decisionsUsed}
                      decisionsAvailable={decisionsAvailable}
                    />
                  </Box>
                }
              />
            </>
          )}
      </Flex>
    </>
  );
};

export default Profile;
