import React, { useState } from "react";
import { Box, Button2, Flex, Input, Modal2, Text } from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";

import { NewInviteModalProps } from "../Members.types";

const NewInviteModal = ({
  displayNewInviteModal,
  inviteErrorMessage,
  exitModal,
  handleCreateNewInvite,
  inviteProcessing,
}: NewInviteModalProps) => {
  const [inviteEmail, setInviteEmail] = useState("");

  const renderInviteForm = () => (
    <Box mt={5}>
      <form>
        <Text as="label" htmlFor="new-invite-email" styleName="body-2-bold">
          Email
        </Text>
        <Input
          errorMessage={inviteErrorMessage}
          isError={!!inviteErrorMessage}
          mt={1}
          htmlId="new-invite-email"
          htmlType="email"
          required
          maxLength={256}
          size="large"
          placeholder="Email address"
          value={inviteEmail}
          onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
            setInviteEmail(e.target.value)
          }
        />
        <Flex mt={2} alignItems="center">
          <Button2
            htmlType="submit"
            size="large"
            label="Send invite"
            isLoading={inviteProcessing}
            isDisabled={inviteProcessing}
            onClick={(e: any) => handleCreateNewInvite({ e, inviteEmail })}
          />
          <Button2
            ml="auto"
            type="text"
            label="Cancel"
            onClick={(e: any) => exitModal({ e })}
          />
        </Flex>
      </form>
    </Box>
  );

  return (
    <Modal2
      isActive={displayNewInviteModal}
      width={rem(600)}
      title="Invite new team member"
      summary="Enter the email address of the person you want to invite to your team. The invite will appear in their Nextmv account."
      closeActionOnClick={(e: any) => exitModal({ e })}
      children={renderInviteForm()}
    />
  );
};

export default NewInviteModal;
