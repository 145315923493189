import * as React from "react";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";

import { svgIconStyle } from "../lib/constants";

type StyledSvgProps = {
  activeColor?: string;
  focusColor?: string;
  hoverColor?: string;
};
const StyledSvg = styled.svg<StyledSvgProps>`
  ${(props) =>
    svgIconStyle({
      activeColor: props.activeColor,
      focusColor: props.focusColor,
      hoverColor: props.hoverColor,
    })}
`;

const IconValueFuncLateness = ({
  activeColor,
  focusColor,
  hoverColor,
  iconColor,
  iconSize,
}: {
  activeColor?: string;
  focusColor?: string;
  hoverColor?: string;
  iconColor?: string;
  iconSize?: number;
}) => {
  const theme = useTheme();
  const thisIconSize = iconSize || 16;
  return (
    <StyledSvg
      {...{ activeColor, focusColor, hoverColor }}
      width={thisIconSize}
      height={thisIconSize}
      viewBox="0 0 16 16"
      fill={iconColor || theme.color.gray700}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M14 12H2V11H14V12Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M16 10H0V9H16V10Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M11 14H5V13H11V14Z" />
      <path d="M14.7101 10H1.28988C2.15043 7.10851 4.82898 5 8 5C11.171 5 13.8496 7.10851 14.7101 10Z" />
      <path d="M1.00001 12.0141V11.9859C1 11.9906 1 11.9953 1 12C1 12.0047 1 12.0094 1.00001 12.0141Z" />
    </StyledSvg>
  );
};

export default IconValueFuncLateness;
