import * as React from "react";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";

import { svgIconStyle } from "../lib/constants";

type StyledSvgProps = {
  activeColor?: string;
  focusColor?: string;
  hoverColor?: string;
};
const StyledSvg = styled.svg<StyledSvgProps>`
  ${(props) =>
    svgIconStyle({
      activeColor: props.activeColor,
      focusColor: props.focusColor,
      hoverColor: props.hoverColor,
    })}
`;

const IconMapMarker = ({
  activeColor,
  focusColor,
  hoverColor,
  iconColor,
}: {
  activeColor?: string;
  focusColor?: string;
  hoverColor?: string;
  iconColor?: string;
}) => {
  const theme = useTheme();

  return (
    <StyledSvg
      {...{ activeColor, focusColor, hoverColor }}
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d)">
        <path
          d="M2 7C2 10.3137 4.68629 13 8 13C11.3137 13 14 10.3137 14 7C14 3.68629 11.3137 1 8 1C4.68629 1 2 3.68629 2 7Z"
          fill={iconColor || theme.color.gray600}
        />
      </g>
      <path
        d="M1 7C1 10.866 4.13401 14 8 14C11.866 14 15 10.866 15 7C15 3.13401 11.866 0 8 0C4.13401 0 1 3.13401 1 7Z"
        fill={theme.color.white}
      />
      <path
        d="M3 7C3 9.76142 5.23858 12 8 12C10.7614 12 13 9.76142 13 7C13 4.23858 10.7614 2 8 2C5.23858 2 3 4.23858 3 7Z"
        fill={theme.color.black}
      />
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="1"
          width="16"
          height="16"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="1"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0614706 0 0 0 0 0.0671863 0 0 0 0 0.0679412 0 0 0 0.34 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </StyledSvg>
  );
};

export default IconMapMarker;
