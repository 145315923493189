export const tooltipContentSwitchback = {
  id: {
    content:
      "The ID is the unique identifier for your shadow test. Once the test has been created, the ID cannot be changed.",
  },
  status: {
    content:
      "The status of the shadow test can be: draft, started, completed, failed, or canceled. A draft shadow test can be started at any time, but note that if a specific time is defined as start criteria then the test will not begin to collect data until the start time. A started test can be marked as completed or canceled at any time. If the criteria for ending a test has been met then the test will complete automatically.",
  },
  baselineInstance: {
    content:
      "The ID of the baseline instance. When the shadow test has started (started status and past the start date criteria if present), any run made on the baseline instance will trigger a run on the candidate instance using the same input.",
  },
  candidateInstance: {
    content:
      "The ID of the candidate instance being compared to the basline instance.",
  },
  startCriteria: {
    content:
      "The test will begin collecting data on this date and time when the test is in a started state. If no time is set, data will begin being collected when the test is started. Note that the test can be started manually before this time, but it will not begin collecting data until the start date.",
  },
};
