import * as React from "react";

import Box from "../Box";

import { StyledDetails, StyledSummary } from "./Details.styled";
import { DetailsProps } from "./Details.types";

const Details = ({
  children,
  controlPosition,
  hasBorderTop,
  hasNoChildrenWrapper,
  htmlId,
  isFocusedFade,
  isOpen,
  onClick,
  onMouseOut,
  onMouseOver,
  m,
  mt,
  mr,
  mb,
  ml,
  size,
  styles,
  summary,
  testId,
  usePointerEventsNone,
}: DetailsProps) => {
  const thisSize = size || "default";
  const thisControlPosition = controlPosition || "right";

  return (
    <StyledDetails
      className="details"
      {...{
        hasBorderTop,
        isFocusedFade,
        m,
        mt,
        mr,
        mb,
        ml,
        onMouseOut,
        onMouseOver,
        styles,
        usePointerEventsNone,
      }}
      id={htmlId}
      size={thisSize}
      controlPosition={thisControlPosition}
      {...(isOpen && {
        open: true,
      })}
      {...(testId && {
        "data-testid": testId,
      })}
    >
      <StyledSummary
        className="summary"
        {...{ onClick }}
        size={thisSize}
        controlPosition={thisControlPosition}
      >
        {summary}
      </StyledSummary>

      {hasNoChildrenWrapper ? children : <Box mt={3}>{children}</Box>}
    </StyledDetails>
  );
};

export default Details;
