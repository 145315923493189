import React, { useEffect, useState } from "react";
import { Box, Button2, Flex, Input, Notification } from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../../../analytics";
import HeaderPublic from "../../../../components/HeaderPublic";
import { useMetaTitle } from "../../../../components/Layout";
import { AUTH_FLOW_CONTENT_MAX_WIDTH } from "../../../../config/public";
import useAuthFlow from "../../../../hooks/useAuthFlow";
import { handleInputChange } from "../../../../utils/inputHelpers";
import AuthFlowLink from "../../components/AuthFlowLink";
import { LoginPageProps } from "../../LogIn.types";

const pageTitle = "Log In Password";

const Password = ({ username, verificationCode }: LoginPageProps) => {
  const [, setMetaTitle] = useMetaTitle();
  const theme = useTheme();

  const [isEmailSet, setIsEmailSet] = useState(false);

  const {
    checkVerificationCode,
    email,
    error,
    errorExpiredCode,
    errorUserNotVerified,
    hasSentVerificationEmail,
    isProcessing,
    isVerificationCodeValid,
    logInEmailPassword,
    password,
    sendVerificationEmail,
    setEmail,
    setErrorExpiredCode,
    setErrorUserNotVerified,
    setPassword,
  } = useAuthFlow();

  // pre-fill email field if data exists
  useEffect(() => {
    if (username && !isEmailSet) {
      setEmail(username);
      setIsEmailSet(true);
    }
  }, [isEmailSet, setEmail, username]);

  // page display
  useEffect(() => {
    setMetaTitle(pageTitle);
  }, [setMetaTitle]);

  // remove send verification link if username updated
  useEffect(() => {
    if (errorExpiredCode || errorUserNotVerified) {
      setErrorExpiredCode(null);
      setErrorUserNotVerified(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  // check validity of verification code if it exists
  useEffect(() => {
    if (
      username &&
      verificationCode &&
      isVerificationCodeValid === null &&
      !isProcessing
    ) {
      checkVerificationCode(username, verificationCode);
    }
  }, [
    checkVerificationCode,
    isProcessing,
    isVerificationCodeValid,
    username,
    verificationCode,
  ]);

  const handleLogInFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    await logInEmailPassword();
  };

  const handleResendVerificationEmail = async (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    e.preventDefault();
    e.stopPropagation();

    trackEvent("AuthFlow", {
      view: "Login Password",
      action: "Resend Email Button Clicked",
    });

    await sendVerificationEmail(email);
  };

  const loginPasswordError = error || errorExpiredCode || errorUserNotVerified;
  const shouldShowResendVerificationEmail =
    errorExpiredCode || errorUserNotVerified;

  return (
    <>
      <HeaderPublic
        configPageTitle={{ label: "Enter your password" }}
        configMetaLink={{
          to: `/login/identify${
            email ? `?username=${encodeURIComponent(email)}` : ""
          }`,
          label: "Back",
        }}
      />

      <Box mt={6} maxWidth={AUTH_FLOW_CONTENT_MAX_WIDTH}>
        {verificationCode && isVerificationCodeValid && (
          <Notification
            testId="password-email-verified-success-notification"
            mb={2}
            type="success"
            message="Email verified. Please log in to continue."
          />
        )}

        <form onSubmit={handleLogInFormSubmit}>
          <Input
            mt={1}
            isRequired
            testId="login-email-input"
            htmlType="email"
            size="large"
            placeholder="Email address"
            value={email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e, setEmail)
            }
          />
          {!shouldShowResendVerificationEmail && (
            <Input
              mt={1}
              isRequired
              htmlType="password"
              data-testid="login-password-input"
              size="large"
              placeholder="Password"
              value={password}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e, setPassword)
              }
            />
          )}

          {loginPasswordError && (
            <Notification
              testId="password-login-error-notification"
              mt={2}
              type="error"
              message={loginPasswordError}
            />
          )}

          {hasSentVerificationEmail && (
            <Notification
              testId="password-verication-email-sent-success-notification"
              mt={1}
              type="success"
              message="Verification email sent"
            />
          )}

          <Flex mt={2}>
            <Button2
              isLoading={isProcessing}
              size="large"
              styles={{
                minWidth: rem(240),
                backgroundColor: theme.color.sky600,
                "&:hover, &:focus, &:active": {
                  backgroundColor: theme.color.sky700,
                },
                "&:focus-visible": {
                  backgroundColor: theme.color.sky700,
                  outlineWidth: theme.border.widthMediumShadowOnly,
                  outlineStyle: "solid",
                  outlineColor: theme.color.sky800,
                },
              }}
              {...(shouldShowResendVerificationEmail && {
                testId: "resend-verification-email-button",
                label: "Resend verification email",
                htmlType: "button",
                onClick: handleResendVerificationEmail,
              })}
              {...(!shouldShowResendVerificationEmail && {
                testId: "login-submit-button",
                label: "Log in",
                htmlType: "submit",
              })}
            />
          </Flex>
        </form>

        <AuthFlowLink
          to="/login/password/forgot"
          queryParams={{ username: email }}
          label="Forgot password?"
          trackEventCategory="AuthFlow"
          trackEventProperties={{
            view: "Login Password",
            action: "Forgot Password Link Clicked",
          }}
        />
      </Box>
    </>
  );
};

export default Password;
