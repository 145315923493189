import { MarketplaceAppConstraint } from "../../../api/core/controlPlane.types";

export const ROUTING_LEGACY_PARTNER_ID = "nextmv";
export const ROUTING_LEGACY_APP_ID = "routing.legacy";
export const ROUTING_LEGACY_SUBSCRIPTION_ID = `${ROUTING_LEGACY_PARTNER_ID}-${ROUTING_LEGACY_APP_ID}`;
export const ROUTING_LEGACY_ICON_URL =
  "/images/logos/nextmv-icon-flush-left.svg";
export const ROUTING_LEGACY_NAME = "Nextmv Routing Legacy";
export const ROUTING_LEGACY_TEMPLATE_NAME = "routing-legacy";
export const ROUTING_LEGACY_SHORT_DESC =
  "Route drivers to stops for delivery, distribution, and sourcing use cases. Uses an old v0 schema for input.";
export const ROUTING_LEGACY_SUMMARY =
  "This marketplace app allows you to use the Nextmv Routing app with the legacy v0/run input schema. While this app uses the same nextroute engine, switching to the new Nextmv Routing app will allow you to access more features.";
export const ROUTING_LEGACY_IMAGE_URL =
  "/images/illustration-marketplace-app-routing-legacy.png";
export const ROUTING_LEGACY_IMAGE_ALT =
  "Abstract, grayscale illustration of carrots in location markers on top of stylized map.";
export const ROUTING_LEGACY_DOCS_LINK =
  "https://www.nextmv.io/docs/vehicle-routing/legacy/routing-app-features";
export const ROUTING_LEGACY_MODEL_TYPE = ["Routing"];

export const ROUTING_LEGACY_CONSTRAINTS: MarketplaceAppConstraint[] = [
  {
    id: "compatibility-attributes",
    name: "Compatibility attributes",
    property: "compatibility_attributes",
    docsUrl:
      "https://www.nextmv.io/docs/vehicle-routing/legacy/routing-app-features#compatibility-attributes",
    summary: "Which stops are compatible with which vehicles.",
    type: "array",
  },
  {
    id: "capacity-quantity",
    name: "Capacity / Quantity",
    property: "capacity, quantity",
    docsUrl:
      "https://www.nextmv.io/docs/vehicle-routing/legacy/routing-app-features#capacity",
    summary:
      "Set capacities for vehicles and quantities (demanded or offered) at stops.",
    type: "object | integer",
  },
  {
    id: "earliness-time-penalty",
    name: "Earliness time penalty",
    docsUrl:
      "https://www.nextmv.io/docs/vehicle-routing/legacy/routing-app-features#earliness-penalties-and-lateness-penalties",
    summary:
      "Penalty added to the objective when arriving before a stop’s target arrival time.",
    type: "float",
  },
  {
    id: "lateness-time-penalty",
    name: "Lateness time penalty",
    docsUrl:
      "https://www.nextmv.io/docs/vehicle-routing/legacy/routing-app-features#earliness-penalties-and-lateness-penalties",
    summary:
      "Penalty added to the objective when arriving after a stop’s target arrival time.",
    type: "float",
  },
  {
    id: "maximum-route-distance",
    name: "Maximum route distance",
    docsUrl:
      "https://www.nextmv.io/docs/vehicle-routing/legacy/routing-app-features#max-distance",
    summary: "Set the maximum distance a vehicle can travel.",
    type: "integer",
  },
  {
    id: "maximum-route-duration",
    name: "Maximum route duration",
    docsUrl:
      "https://www.nextmv.io/docs/vehicle-routing/legacy/routing-app-features#max-duration",
    summary: "Set the maximum duration a vehicle can travel for.",
    type: "integer",
  },
  {
    id: "maximum-route-stops",
    name: "Maximum route stops",
    docsUrl:
      "https://www.nextmv.io/docs/vehicle-routing/legacy/routing-app-features#max-stops",
    summary: "Set the maximum stops a vehicle can visit.",
    type: "integer",
  },
  {
    id: "maximum-wait-time",
    name: "Maximum wait time",
    docsUrl:
      "https://www.nextmv.io/docs/vehicle-routing/legacy/routing-app-features#waiting-times",
    summary:
      "Set the maximum time a vehicle can wait on arrival before the start time window opens.",
    type: "integer",
  },
  {
    id: "precedence",
    name: "Precedence (precedes/succeeds)",
    docsUrl:
      "https://www.nextmv.io/docs/vehicle-routing/legacy/routing-app-features#precedence",
    summary:
      "Add pickups and deliveries or specify multiple pickups before deliveries and vice versa.",
    type: "array | string",
  },
  {
    id: "stop-duration",
    name: "Stop duration",
    docsUrl:
      "https://www.nextmv.io/docs/vehicle-routing/legacy/routing-app-features#stop-duration",
    summary: "Specify the time it takes to service a stop.",
    type: "integer",
  },
  {
    id: "stop-groups",
    name: "Stop groups",
    docsUrl:
      "https://www.nextmv.io/docs/vehicle-routing/legacy/routing-app-features#stop-groups",
    summary: "Stops that must be assigned together on the same route.",
    type: "array",
  },
  {
    id: "stop-time-window",
    name: "Stop time window",
    docsUrl:
      "https://www.nextmv.io/docs/vehicle-routing/legacy/routing-app-features#hard-windows",
    summary: "The time window in which a stop must start service.",
    type: "array",
  },
  {
    id: "stop-unassigned-penalty",
    name: "Stop unassigned penalty",
    docsUrl:
      "https://www.nextmv.io/docs/vehicle-routing/legacy/routing-app-features#unassigned-penalties",
    summary:
      "Penalty added for leaving a stop unassigned when all constraints cannot be fulfilled.",
    type: "integer",
  },
  {
    id: "target-arrival-time",
    name: "Target arrival time",
    docsUrl:
      "https://www.nextmv.io/docs/vehicle-routing/legacy/routing-app-features#target-times",
    summary: "Define a specific time a vehicle should service a stop.",
    type: "timestamp",
  },
  {
    id: "vehicle-initialization-penalty",
    name: "Vehicle initialization penalty",
    docsUrl:
      "https://www.nextmv.io/docs/vehicle-routing/legacy/routing-app-features#vehicle-costs",
    summary: "Penalty for initializing (using) a vehicle.",
    type: "integer",
  },
  // {
  //   id: "vehicle-initial-stops",
  //   name: "Vehicle initial stops",
  //   docsUrl: "",
  //   summary: "Specify initial stops planned on a vehicle.",
  //   type: "array",
  // },
  {
    id: "vehicle-start-end-location",
    name: "Vehicle start/end location",
    docsUrl:
      "https://www.nextmv.io/docs/vehicle-routing/legacy/routing-app-features",
    summary: "Set starting and/or ending locations for vehicles.",
    type: "lon / lat",
  },
  {
    id: "vehicle-shift-start-end-time",
    name: "Vehicle shift start/end time",
    docsUrl:
      "https://www.nextmv.io/docs/vehicle-routing/legacy/routing-app-features#shift-start-and-end",
    summary: "Specify a start and/or end time for a vehicle.",
    type: "timestamp",
  },
];
